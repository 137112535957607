import { ReactElement, SVGProps } from 'react';

export const SvgClock = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 22.75c5.385 0 9.75-4.365 9.75-9.75S18.385 3.25 13 3.25 3.25 7.615 3.25 13s4.365 9.75 9.75 9.75Z"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 7.583V13h5.417" stroke="#98B0C1" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
