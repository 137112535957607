import { Connection, TransactionSignature, VersionedTransactionResponse } from '@solana/web3.js';
import { sleep } from './sleep';

export const waitForTransaction = async (
  connection: Connection,
  sig: TransactionSignature,
  tries = 10,
  timeout = 1000
): Promise<VersionedTransactionResponse | null> => {
  let _tries = 0;
  let transactionDetails = null;

  while (_tries < tries) {
    transactionDetails = await connection.getTransaction(sig, {
      commitment: 'confirmed',
      maxSupportedTransactionVersion: 0,
    });

    if (transactionDetails) {
      break;
    }
    _tries += 1;
    await sleep(timeout);
  }
  return transactionDetails;
};
