import React, { FC } from 'react';
import s from './PositionsList.module.less';
import { LiquidityPositions } from './components/LiquidutyPositions/LiquidityPositions';
import { LendingPositions } from './components/LendingPositions/LendingPositions';
import { MultiplyPositions } from './components/MultiplyPositions/MultiplyPositions';
import { LeveragePositions } from './components/LeveragePositions/LeveragePositions';

export const PositionsList: FC = () => {
  return (
    <div className={s.root}>
      <LiquidityPositions />
      <LendingPositions />
      <MultiplyPositions />
      <LeveragePositions />
    </div>
  );
};
