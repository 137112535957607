import { useMemo } from 'react';
import { useCollateralInfos } from './useCollateralInfos';

import { usePricesQuery } from './queries/usePricesQuery';

// Use this for local debug/dev purposes
// or in case urgent hotfix is required
// do not use as a const, ideally should be empty
const hardcoded: string[] = [];
const EMPTY_MINTS: string[] = [];

/**
 * Fetches different sources to build a final list of all tokens that are used in the app
 */

export const useAvailableTokensMints = () => {
  const { mints: collateralMints, isFetched: isCollateralInfoFetched } = useCollateralInfos();
  const { data, isFetched: isFetchedKaminoTokens } = usePricesQuery();

  const isFetched = isCollateralInfoFetched && isFetchedKaminoTokens;
  // console.log('useAvailableTokensMints:data', data);

  const mints = useMemo(() => {
    return [...new Set([...collateralMints, ...hardcoded, ...Object.keys(data || {})])];
  }, [collateralMints, data]);

  return {
    mints: isFetched ? mints : EMPTY_MINTS,
    isFetched,
  };
};
