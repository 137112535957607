import { FC } from 'react';
import { withStyles } from '@bruitt/classnames';

import logo from './assets/logo.svg';
import s from './Logo.module.less';

const sx = withStyles(s);

interface LogoProps {
  className?: string;
}

export const Logo: FC<LogoProps> = ({ className }) => {
  return <img className={sx('root', className)} src={logo} alt="Kamino" />;
};
