import { FC } from 'react';
import { Tag } from '../../../uiKitV2/Tag';
import { Tooltip } from '../../../uiKitV2/Tooltip';
import { Text } from '../../../uiKitV2/Typography';

import s from './BetaBadge.module.less';

interface BetaBadgeProps {
  isHiddenTooltip?: boolean;
}

export const BetaBadge: FC<BetaBadgeProps> = ({ isHiddenTooltip = false }) => (
  <Tooltip
    hidden={isHiddenTooltip}
    render={() => (
      <Text color="white" className={s.tooltipText}>
        This Product is still in Beta
      </Text>
    )}
  >
    <Tag variant="grey" size="small" label="Beta" />
  </Tooltip>
);
