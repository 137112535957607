import { FC, useState } from 'react';

import { Text } from '../../../uiKitV2/Typography';
import { Banner } from '../Banner';
import { Link } from '../../../uiKitV2/Link';
import s from './EuBanner.module.less';

export const EuBanner: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Banner localStorageKey="kamino.isGeoBlockingWarningClosed">
      <Text color="grey-light" className={isOpen ? s.bannerTextOpened : s.bannerText}>
        Please note that you are entering the website of Kamino Finance. Kamino is not authorised in the European Union
        to provide investment services. By using this website, you agree to waive the protection afforded under European
        Union rules.
      </Text>
      {!isOpen && (
        <Link className={s.readMoreButton} onClick={() => setIsOpen(true)}>
          Read more
        </Link>
      )}
    </Banner>
  );
};
