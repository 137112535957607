/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/lines-between-class-members */

import Decimal from 'decimal.js';
import { MAX_SLIPPAGE_PCT } from '../constants/slippage';

export const JUPITER_PROGRAM_ID = 'JUP6LkbZbjS1jKKwapdHNy74zcZ3tLUZoi5QNyVTaV4';

export type CustomJupiterError =
  | EmptyRoute
  | SlippageToleranceExceeded
  | InvalidCalculation
  | MissingPlatformFeeAccount
  | InvalidSlippage
  | NotEnoughPercent
  | InvalidInputIndex
  | InvalidOutputIndex
  | NotEnoughAccountKeys
  | NonZeroMinimumOutAmountNotSupported
  | InvalidRoutePlan
  | InvalidReferralAuthority
  | LedgerTokenAccountDoesNotMatch
  | InvalidTokenLedger
  | IncorrectTokenProgramID
  | TokenProgramNotProvided
  | SwapNotSupported
  | ExactOutAmountNotMatched
  | OracleConfidence;

export class EmptyRoute extends Error {
  readonly code: 6000;
  readonly logs?: string[];
  readonly msg: 'Empty route';
  readonly name = 'EmptyRoute';

  constructor(logs?: string[]) {
    super('6000: Empty route');
    this.name = 'EmptyRoute';
    this.msg = 'Empty route';
    this.code = 6000;
    this.logs = logs;
  }
}

export class SlippageToleranceExceeded extends Error {
  readonly code: 6001;
  readonly logs?: string[];
  readonly msg: 'Slippage tolerance exceeded';
  readonly name = 'SlippageToleranceExceeded';

  constructor(logs?: string[]) {
    super('6001: Slippage tolerance exceeded');
    this.name = 'SlippageToleranceExceeded';
    this.msg = 'Slippage tolerance exceeded';
    this.code = 6001;
    this.logs = logs;
  }
}

export class InvalidCalculation extends Error {
  readonly code: 6002;
  readonly logs?: string[];
  readonly msg: 'Invalid calculation';
  readonly name = 'InvalidCalculation';

  constructor(logs?: string[]) {
    super('6002: Invalid calculation');
    this.name = 'InvalidCalculation';
    this.msg = 'Invalid calculation';
    this.code = 6002;
    this.logs = logs;
  }
}

export class MissingPlatformFeeAccount extends Error {
  readonly code: 6003;
  readonly logs?: string[];
  readonly msg: 'Missing platform fee accountt';
  readonly name = 'MissingPlatformFeeAccount';

  constructor(logs?: string[]) {
    super('6003: Missing platform fee accountt');
    this.name = 'MissingPlatformFeeAccount';
    this.msg = 'Missing platform fee accountt';
    this.code = 6003;
    this.logs = logs;
  }
}

export class InvalidSlippage extends Error {
  readonly code: 6004;
  readonly logs?: string[];
  readonly msg: 'Invalid slippage';
  readonly name = 'InvalidSlippage';

  constructor(logs?: string[]) {
    super('6004: Invalid slippage');
    this.name = 'InvalidSlippage';
    this.msg = 'Invalid slippage';
    this.code = 6004;
    this.logs = logs;
  }
}

export class NotEnoughPercent extends Error {
  readonly code: 6005;
  readonly logs?: string[];
  readonly msg: 'Not enough percent to 100';
  readonly name = 'NotEnoughPercent';

  constructor(logs?: string[]) {
    super('6005: Not enough percent to 100');
    this.name = 'NotEnoughPercent';
    this.msg = 'Not enough percent to 100';
    this.code = 6005;
    this.logs = logs;
  }
}

export class InvalidInputIndex extends Error {
  readonly code: 6006;
  readonly logs?: string[];
  readonly msg: 'Token input index is invalid';
  readonly name = 'InvalidInputIndex';

  constructor(logs?: string[]) {
    super('6006: Token input index is invalid');
    this.name = 'InvalidInputIndex';
    this.msg = 'Token input index is invalid';
    this.code = 6006;
    this.logs = logs;
  }
}

export class InvalidOutputIndex extends Error {
  readonly code: 6007;
  readonly logs?: string[];
  readonly msg: 'Token output index is invalid';
  readonly name = 'InvalidOutputIndex';

  constructor(logs?: string[]) {
    super('6007: Token output index is invalid');
    this.name = 'InvalidOutputIndex';
    this.msg = 'Token output index is invalid';
    this.code = 6007;
    this.logs = logs;
  }
}

export class NotEnoughAccountKeys extends Error {
  readonly code: 6008;
  readonly logs?: string[];
  readonly msg: 'Not Enough Account keys';
  readonly name = 'NotEnoughAccountKeys';

  constructor(logs?: string[]) {
    super('6008: Not Enough Account keys');
    this.name = 'NotEnoughAccountKeys';
    this.msg = 'Not Enough Account keys';
    this.code = 6008;
    this.logs = logs;
  }
}

export class NonZeroMinimumOutAmountNotSupported extends Error {
  readonly code: 6009;
  readonly logs?: string[];
  readonly msg: 'Non zero minimum out amount not supported';
  readonly name = 'NonZeroMinimumOutAmountNotSupported';

  constructor(logs?: string[]) {
    super('6009: Non zero minimum out amount not supported');
    this.name = 'NonZeroMinimumOutAmountNotSupported';
    this.msg = 'Non zero minimum out amount not supported';
    this.code = 6009;
    this.logs = logs;
  }
}

export class InvalidRoutePlan extends Error {
  readonly code: 6010;
  readonly logs?: string[];
  readonly msg: 'Invalid route plan';
  readonly name = 'InvalidRoutePlan';

  constructor(logs?: string[]) {
    super('6010: Invalid route plan');
    this.name = 'InvalidRoutePlan';
    this.msg = 'Invalid route plan';
    this.code = 6010;
    this.logs = logs;
  }
}

export class InvalidReferralAuthority extends Error {
  readonly code: 6011;
  readonly logs?: string[];
  readonly msg: 'Invalid referral authority';
  readonly name = 'InvalidReferralAuthority';

  constructor(logs?: string[]) {
    super('6011: Invalid referral authority');
    this.name = 'InvalidReferralAuthority';
    this.msg = 'Invalid referral authority';
    this.code = 6011;
    this.logs = logs;
  }
}

export class LedgerTokenAccountDoesNotMatch extends Error {
  readonly code: 6012;
  readonly logs?: string[];
  readonly msg: "Token account doesn't match the ledger";
  readonly name = 'LedgerTokenAccountDoesNotMatch';

  constructor(logs?: string[]) {
    super("6012: Token account doesn't match the ledger");
    this.name = 'LedgerTokenAccountDoesNotMatch';
    this.msg = "Token account doesn't match the ledger";
    this.code = 6012;
    this.logs = logs;
  }
}

export class InvalidTokenLedger extends Error {
  readonly code: 6013;
  readonly logs?: string[];
  readonly msg: 'Invalid token ledger';
  readonly name = 'InvalidTokenLedger';

  constructor(logs?: string[]) {
    super('6013: Invalid token ledger');
    this.name = 'InvalidTokenLedger';
    this.msg = 'Invalid token ledger';
    this.code = 6013;
    this.logs = logs;
  }
}

export class IncorrectTokenProgramID extends Error {
  readonly code: 6014;
  readonly logs?: string[];
  readonly msg: 'Token program ID is invalid';
  readonly name = 'IncorrectTokenProgramID';

  constructor(logs?: string[]) {
    super('6014: Token program ID is invalid');
    this.name = 'IncorrectTokenProgramID';
    this.msg = 'Token program ID is invalid';
    this.code = 6014;
    this.logs = logs;
  }
}

export class TokenProgramNotProvided extends Error {
  readonly code: 6015;
  readonly logs?: string[];
  readonly msg: 'Token program not provided';
  readonly name = 'TokenProgramNotProvided';

  constructor(logs?: string[]) {
    super('6015: Token program not provided');
    this.name = 'TokenProgramNotProvided';
    this.msg = 'Token program not provided';
    this.code = 6015;
    this.logs = logs;
  }
}

export class SwapNotSupported extends Error {
  readonly code: 6016;
  readonly logs?: string[];
  readonly msg: 'Swap not supported';
  readonly name = 'SwapNotSupported';

  constructor(logs?: string[]) {
    super('6016: Swap not supported');
    this.name = 'SwapNotSupported';
    this.msg = 'Swap not supported';
    this.code = 6016;
    this.logs = logs;
  }
}

export class ExactOutAmountNotMatched extends Error {
  readonly code: 6017;
  readonly logs?: string[];
  readonly msg: "Exact out amount doesn't match";
  readonly name = 'ExactOutAmountNotMatched';

  constructor(logs?: string[]) {
    super("6017: Exact out amount doesn't match");
    this.name = 'ExactOutAmountNotMatched';
    this.msg = "Exact out amount doesn't match";
    this.code = 6017;
    this.logs = logs;
  }
}

export class OracleConfidence extends Error {
  readonly code: 6035;
  readonly logs?: string[];
  readonly msg: 'Oracle confidence is too high';
  readonly name = 'OracleConfidence';

  constructor(logs?: string[]) {
    super('6035: Oracle confidence is too high');
    this.name = 'OracleConfidence';
    this.msg = 'Oracle confidence is too high';
    this.code = 6035;
    this.logs = logs;
  }
}

export function fromJupiterErrorCode(code: number, logs?: string[]) {
  switch (code) {
    case 6000:
      return new EmptyRoute(logs);
    case 6001:
      return new SlippageToleranceExceeded(logs);
    case 6002:
      return new InvalidCalculation(logs);
    case 6003:
      return new MissingPlatformFeeAccount(logs);
    case 6004:
      return new InvalidSlippage(logs);
    case 6005:
      return new NotEnoughPercent(logs);
    case 6006:
      return new InvalidInputIndex(logs);
    case 6007:
      return new InvalidOutputIndex(logs);
    case 6008:
      return new NotEnoughAccountKeys(logs);
    case 6009:
      return new NonZeroMinimumOutAmountNotSupported(logs);
    case 6010:
      return new InvalidRoutePlan(logs);
    case 6011:
      return new InvalidReferralAuthority(logs);
    case 6012:
      return new LedgerTokenAccountDoesNotMatch(logs);
    case 6013:
      return new InvalidTokenLedger(logs);
    case 6014:
      return new IncorrectTokenProgramID(logs);
    case 6015:
      return new TokenProgramNotProvided(logs);
    case 6016:
      return new SwapNotSupported(logs);
    case 6017:
      return new ExactOutAmountNotMatched(logs);
    case 6035:
      return new OracleConfidence(logs);
  }
  return null;
}

/**
 * Do not let slippage to go over 5% to avoid human mistake
 * @param valueBpc
 * @param isPct
 */
export function capSlippage(valueBpc: Decimal.Value, isPct?: boolean) {
  return isPct ? Decimal.min(valueBpc, MAX_SLIPPAGE_PCT) : Decimal.min(valueBpc, MAX_SLIPPAGE_PCT * 100);
}
