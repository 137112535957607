import { useMemo } from 'react';

import { useLiquidityPositionsStats } from '../../pages/PortfolioV2/hooks/useLiquidityPositionsStats';
import { useLendingPositionsStats } from '../../pages/PortfolioV2/hooks/useLendingPositionsStats';
import { useLendingMultiplyPositionsStats } from '../../pages/PortfolioV2/hooks/useLendingMultiplyPositionsStats';
import { usePrices } from './usePrices';
import { SOL_MINTS } from '../constants/tokens';
import { useLendingLeveragePositionsStats } from '../../pages/PortfolioV2/hooks/useLendingLeveragePositionsStats';

const EMPTY_DATA = {
  netValue: 0,
  pnl: 0,
  fees: 0,
  positionsCount: 0,
};

/**
 * Calculates total values for different types of positions
 * For Lending obligations calculates net value instead ( supply - borrow )
 */
export const useUserPositionsTotalStats = ({ marketAddress }: { marketAddress: string }) => {
  const { getPriceByTokenMintDecimal } = usePrices();
  const { data: lendingMultiplyPositionsStats, isReady: areLendingMultiplyStatsReady } =
    useLendingMultiplyPositionsStats();
  const { data: lendingLeveragePositionsStats, isReady: areLendingLeverageStatsReady } =
    useLendingLeveragePositionsStats({ marketAddress });
  const { data: lendingPositionsStats, isReady: areLendingStatsReady } = useLendingPositionsStats();
  const { data: liquidityStats, isLoading: areLiquidityStatsLoading } = useLiquidityPositionsStats();

  const isLoading =
    !areLendingMultiplyStatsReady || !areLendingLeverageStatsReady || !areLendingStatsReady || areLiquidityStatsLoading;

  const solPrice = getPriceByTokenMintDecimal(SOL_MINTS[1]);

  const value = useMemo(() => {
    if (isLoading) {
      return EMPTY_DATA;
    }

    return {
      netValue:
        liquidityStats.totalValue +
        lendingMultiplyPositionsStats.totalNetValue +
        lendingPositionsStats.totalNetValue +
        lendingLeveragePositionsStats.totalNetValue,
      pnl: liquidityStats.totalPnLUsd,
      fees:
        liquidityStats.totalFees +
        lendingPositionsStats.totalInterestFees +
        solPrice.mul(lendingMultiplyPositionsStats.totalSolPnl).toNumber() +
        lendingLeveragePositionsStats.totalPnLUsd,
      positionsCount: liquidityStats.positionsCount,
    };
  }, [
    isLoading,
    lendingLeveragePositionsStats.totalNetValue,
    lendingLeveragePositionsStats.totalPnLUsd,
    lendingMultiplyPositionsStats.totalNetValue,
    lendingMultiplyPositionsStats.totalSolPnl,
    lendingPositionsStats.totalInterestFees,
    lendingPositionsStats.totalNetValue,
    liquidityStats.positionsCount,
    liquidityStats.totalFees,
    liquidityStats.totalPnLUsd,
    liquidityStats.totalValue,
    solPrice,
  ]);

  return {
    data: value || EMPTY_DATA,
    isLoading,
  };
};
