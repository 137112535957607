import { ReactElement, SVGProps } from 'react';

export const SvgSocialTwitter = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm5.797-15.848a5.946 5.946 0 0 0 1.705-.468l-.002.002a6.035 6.035 0 0 1-1.48 1.536 8.2 8.2 0 0 1 .009.384c0 3.923-2.986 8.447-8.447 8.447a8.396 8.396 0 0 1-4.55-1.334 5.957 5.957 0 0 0 4.396-1.23 2.971 2.971 0 0 1-2.774-2.061c.445.085.903.067 1.34-.051a2.97 2.97 0 0 1-2.38-2.911v-.039c.411.23.872.357 1.344.372a2.97 2.97 0 0 1-.92-3.964 8.43 8.43 0 0 0 6.12 3.102 2.969 2.969 0 0 1 5.059-2.708 5.927 5.927 0 0 0 1.885-.72 2.976 2.976 0 0 1-1.305 1.643Z"
      clipRule="evenodd"
    />
  </svg>
);
