import { Wallet } from '@solana/wallet-adapter-react';
import { isIosAndRedirectable } from '@solana/wallet-adapter-base';
import {
  BINANCE_MOBILE_DEEP_LINK,
  BINANCE_WALLET,
  GOOGLE_TIPLINK_WALLET,
  IOS_DEEP_LINK,
  ME_WALLET,
  METAMASK_WALLET,
  OKX_WALLET,
  PHANTOM_DEEP_LINK,
  PHANTOM_WALLET,
  SOLFLARE_DEEP_LINK,
  SOLFLARE_WALLET,
} from '../components/WalletModal/constants';
import { notEmpty } from './notEmpty';
import { redirectInNewTab } from './redirectInNewTab';

export function addPredefinedWallets(wallets: Wallet[]) {
  const _window = window as any;

  const hasInstalledBinanceWallet = wallets.findIndex((item) => item.adapter.name === BINANCE_WALLET.adapter.name);
  const hasInstalledOkxWallet = typeof _window.okxwallet !== 'undefined';
  const hasInstalledPhantomWallet = _window.phantom?.solana?.isPhantom && !_window.phantom?.solana?.isOkxWallet;
  const hasInstalledMEWallet = typeof _window.magicEden !== 'undefined';

  const extraWallets = [
    !hasInstalledOkxWallet ? OKX_WALLET : undefined,
    hasInstalledBinanceWallet === -1 ? BINANCE_WALLET : undefined,
    !hasInstalledPhantomWallet ? PHANTOM_WALLET : undefined,
    !hasInstalledMEWallet ? ME_WALLET : undefined,
  ].filter(notEmpty);

  return [...extraWallets, ...wallets];
}

/**
 * Redirects to mobile app if needed. Returns true if redirected to prevent further code execution
 * @param wallets
 * @param selectedWallet
 */
export function redirectIfWalletIsNotInstalled(wallets: Wallet[], selectedWallet: Wallet) {
  const hasInstalledWallet = wallets.findIndex((item) => item.adapter.name === selectedWallet.adapter.name);

  if (selectedWallet.adapter.name === OKX_WALLET.adapter.name && isIosAndRedirectable()) {
    window.location.href = IOS_DEEP_LINK;
    return true;
  }

  if (selectedWallet.adapter.name === BINANCE_WALLET.adapter.name && isIosAndRedirectable()) {
    window.location.href = BINANCE_MOBILE_DEEP_LINK;
    return true;
  }

  if (selectedWallet.adapter.name === PHANTOM_WALLET.adapter.name && isIosAndRedirectable()) {
    window.location.href = PHANTOM_DEEP_LINK;
    return true;
  }

  if (selectedWallet.adapter.name === SOLFLARE_WALLET.adapter.name && isIosAndRedirectable()) {
    window.location.href = SOLFLARE_DEEP_LINK;
    return true;
  }

  const walletsAllowedToRedirect = [
    BINANCE_WALLET.adapter.name,
    OKX_WALLET.adapter.name,
    METAMASK_WALLET.adapter.name,
    GOOGLE_TIPLINK_WALLET.adapter.name,
    SOLFLARE_WALLET.adapter.name,
    PHANTOM_WALLET.adapter.name,
    ME_WALLET.adapter.name,
  ];

  if (hasInstalledWallet === -1 && walletsAllowedToRedirect.includes(selectedWallet.adapter.name)) {
    redirectInNewTab(selectedWallet.adapter.url);
    return true;
  }

  return false;
}
