import { useMemo } from 'react';
import { LeverageObligation, ObligationTypeTag } from '@kamino-finance/klend-sdk/dist/utils/ObligationType';
import { PublicKey } from '@solana/web3.js';

import { LendingLeveragePositionTableData } from '../types';
import useTokens from '../../../shared/hooks/useTokens';
import { getObligationStats } from '../../../shared/utils/lending';
import { useUserObligations } from '../../../shared/hooks/lending/useUserObligations';
import { useReserves } from '../../../shared/hooks/lending/useReserves';
import { checkIsLong } from '../../../shared/utils/lending/checkIsLong';
import useEnv from '../../../shared/hooks/useEnv';
import { useMarkets } from '../../../shared/hooks/lending/useMarkets';

const EMPTY_DATA: LendingLeveragePositionTableData[] = [];

export const useLendingLeveragePositionsTableData = ({ marketAddress }: { marketAddress: string }) => {
  const { getToken } = useTokens();
  const { getMarketByAddress } = useMarkets();
  const market = getMarketByAddress(marketAddress);
  const { data: obligations, isLoading: areUserObligationsLoading } = useUserObligations([ObligationTypeTag.Leverage]);
  const isLoading = areUserObligationsLoading;
  const { walletPublicKey, programId } = useEnv();
  const { getReservesByMarketAddress, getReserveByTokenMint } = useReserves();
  const reserves = getReservesByMarketAddress(marketAddress);

  const tableData: LendingLeveragePositionTableData[] = useMemo(() => {
    if (!marketAddress || !walletPublicKey || !obligations || !obligations.length) {
      return EMPTY_DATA;
    }

    return obligations.map((obligation) => {
      const { refreshedStats: stats, deposits, borrows, obligationAddress } = obligation;
      const { loanToValue, netAccountValue, userTotalBorrow, userTotalDeposit } = stats;
      const { liquidationLtv, maxLtv, leverage } = getObligationStats(market, obligation);
      const depositsArray = Array.from(deposits.values());
      const borrowsArray = Array.from(borrows.values());

      const collTokenMint = depositsArray[0]?.mintAddress;

      if (!collTokenMint) {
        throw new Error('collTokenMint is undefined');
      }

      const debtTokenMint =
        borrowsArray[0]?.mintAddress ||
        reserves
          .find((reserve) => {
            const obligationToCheck = new LeverageObligation(collTokenMint, reserve.getLiquidityMint(), programId);
            const derivedPda = obligationToCheck.toPda(market.getAddress(), new PublicKey(walletPublicKey));
            return derivedPda.equals(obligationAddress);
          })
          ?.getLiquidityMint();

      if (!debtTokenMint) {
        throw new Error('debtTokenMint is undefined');
      }

      const collTokenInfo = getToken(collTokenMint);
      const debtTokenInfo = getToken(debtTokenMint);

      const isLong = checkIsLong({ collTokenMint: collTokenInfo.address, debtTokenMint: debtTokenInfo.address });

      const tokensMints = isLong
        ? depositsArray.map((d) => d.mintAddress.toString())
        : borrowsArray.map((b) => b.mintAddress.toString());

      return {
        address: obligation.obligationAddress,
        ltv: loanToValue.toNumber(),
        leverage,
        netValue: netAccountValue.toNumber(),
        deposits: depositsArray,
        borrows: borrowsArray,
        interestFees: Number(0),
        positionType: isLong ? 'long' : 'short',
        tokensMints,
        maxLtv,
        liquidationLtv: liquidationLtv.toNumber(),
        totalCollateral: userTotalDeposit.toNumber(),
        totalDebt: userTotalBorrow.toNumber(),
        // we can use ! here thanks to collTokenMint and debtTokenMint checks
        // we can be sure that reserves for these tokens exists
        // otherwise we get an exception before reaching this line
        depositReserveAddress: getReserveByTokenMint(marketAddress, collTokenInfo.address)!.address.toString(),
        borrowReserveAddress: getReserveByTokenMint(marketAddress, debtTokenInfo.address)!.address.toString(),
        marketAddress,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketAddress,
    obligations,
    getReserveByTokenMint,
    walletPublicKey,
    reserves,
    market,
    getToken,
    getReserveByTokenMint,
    programId,
  ]);

  return { data: tableData || EMPTY_DATA, isLoading, obligations };
};
