import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import { useLiquidityPositionsTableData } from '../../../../../../../../pages/PortfolioV2/hooks/useLiquidityPositionsTableData';
import s from './LiquidityPositions.module.less';
import { Text } from '../../../../../../../../shared/uiKitV2/Typography';
import { VaultName } from '../../../../../../../../pages/PortfolioV2/components/TableCells';
import { buildStrategyUrl, formatNumber } from '../../../../../../../../shared/utils';
import { PnlCell } from '../../../../../../../../pages/PortfolioV2/components/LiquidityPositionsTable/TableCells';
import { PositionsAccordion } from '../../../PositionsAccordion/PositionsAccordion';
import { PublicKeyAddress } from '../../../../../../../../shared/types/strategies';
import { layoutProviderStore } from '../../../../../../../../stores/layoutProviderStore';
import { PnlTooltip } from '../../../../../../../../shared/components/v2/PnlTooltip/PnlTooltip';

const { formatUsd: fusd } = formatNumber;

export const LiquidityPositions: FC = () => {
  const { data, isLoading } = useLiquidityPositionsTableData();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { updateProviderParams } = layoutProviderStore;

  const goToStrategyPage = useCallback(
    (address: PublicKeyAddress) => {
      history.push(buildStrategyUrl(address));
      mixpanel.track('sidepanel::position_click', {
        address: address.toString(),
        type: 'liquidity',
      });
      updateProviderParams({ isSidePanelOpened: false });
    },

    [history, mixpanel, updateProviderParams]
  );

  if (!data.length && !isLoading) {
    return null;
  }

  return (
    <PositionsAccordion header="Liquidity" isLoading={isLoading}>
      <div className={s.list}>
        {data.map((strat) => (
          <div
            className={s.tokenItem}
            key={strat.strategy.toString()}
            onClick={() => goToStrategyPage(strat.strategy.toString())}
          >
            <div className={s.tokenRight}>
              <VaultName
                textProps={{ fs: 16, lh: 22 }}
                tokenAMint={strat.tokenAMint}
                tokenBMint={strat.tokenBMint}
                isDiyVault={strat.isDiyVault}
                tokensProps={{ size: 20 }}
                tagSize="middle"
              />
            </div>

            <div className={s.tokenValues}>
              <Text fs={14} lh={20}>
                {fusd(strat.totalValue)}
              </Text>
              <PnlCell address={strat.strategy} label="" textProps={{ fs: 14, lh: 20 }} />
            </div>
          </div>
        ))}
        <PnlTooltip data={data} />
      </div>
    </PositionsAccordion>
  );
};
