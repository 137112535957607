import { AddressLookupTableAccount, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { getLookupTableAccount } from '@kamino-finance/klend-sdk';

export const calculateAndLogTxSize = async (
  connection: Connection,
  ixns: TransactionInstruction[],
  lookupTables: PublicKey[],
  feePayer: PublicKey,
  lookupTablesAccounts?: Array<AddressLookupTableAccount | null>
) => {
  const keys = new Set();
  const programIds = new Set();

  ixns.forEach((ixn) => {
    ixn.keys.forEach((key) => {
      keys.add(key.pubkey.toString());
    });
    programIds.add(ixn.programId.toString());
  });

  const tableKeys = new Set();

  // if lookupTablesAccounts passed via param - do not refetch
  let lutAccounts: Array<AddressLookupTableAccount | null> | undefined = lookupTablesAccounts;

  if (!lutAccounts) {
    lutAccounts = await Promise.all(
      lookupTables.map((address) => {
        return getLookupTableAccount(connection, address);
      })
    );
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const tableAccount of lutAccounts) {
    // eslint-disable-next-line no-restricted-syntax
    if (tableAccount) {
      for (const key of tableAccount.state.addresses) {
        tableKeys.add(key.toString());
      }
    }
  }

  ixns.forEach((ixn) => {
    programIds.add(ixn.programId.toString());
  });
};
