import { useQuery } from '@tanstack/react-query';
import { QUERYKEYS } from '../../constants/queryKeys';

import { useWeb3Client } from '../useWeb3Client';
import { useCurrentSlot } from '../useCurrentSlot';

interface UseCurrentSlotQuery {
  refetchInterval?: number;
}

export const useCurrentSlotQuery = ({ refetchInterval }: UseCurrentSlotQuery) => {
  const { web3client } = useWeb3Client();
  const { getCurrentSlot } = useCurrentSlot();

  const queryFn = async () => {
    if (!web3client) {
      throw new Error('web3client is required');
    }

    return getCurrentSlot();
  };

  return useQuery(QUERYKEYS.currentSlot, queryFn, {
    enabled: Boolean(web3client),
    refetchInterval: refetchInterval || 5 * 1000, // 5 secs
    staleTime: 30 * 1000, // 30 secs
  });
};
