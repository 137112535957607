import React, { FC } from 'react';
import s from '../../MainMode.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { useUserPositionsTotalStats } from '../../../../../../shared/hooks/useUserPositionsTotalStats';
import { useMarkets } from '../../../../../../shared/hooks/lending/useMarkets';
import { formatNumber } from '../../../../../../shared/utils';
import { PortfolioValueSkeleton } from '../PortfolioValueSkeleton/PortfolioValueSkeleton';

const { formatUsd: fusd, formatTokenAllDecimals: ftad, formatPercent: fp } = formatNumber;

export const PortfolioValue: FC = () => {
  const { markets } = useMarkets();
  const { data: portfolioStats, isLoading } = useUserPositionsTotalStats({
    marketAddress: markets?.[0]?.address || '',
  });
  const { fees, netValue } = portfolioStats;

  const addPlus = (value: number) => {
    if (value > 0) {
      return '+';
    }
    return '';
  };

  const feesPercents = fees / (netValue / 100);

  if (isLoading) {
    return <PortfolioValueSkeleton />;
  }

  return (
    <div className={s.balance}>
      <Text fs={32} lh={36}>
        ${ftad(netValue, 2)}
      </Text>
      <Text fs={14} lh={20} color="green">
        {`${addPlus(fees)}${fusd(fees)}`} ({fp(feesPercents)})
      </Text>
    </div>
  );
};
