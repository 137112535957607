import { AccountInfo, ParsedAccountData, PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useWeb3Client } from '../../../shared/hooks/useWeb3Client';
import { QUERYKEYS } from '../../../shared/constants/queryKeys';
import { useWalletAddressFromParamsOrEnv } from '../../../shared/hooks/useWalletAddressFromParamsOrEnv';
import { TOKEN_2022_PROGRAM_ID } from '../constants/programId';

type AtaValueType = { pubkey: PublicKey; account: AccountInfo<ParsedAccountData> };
export const useUserAtasQuery = () => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { web3client } = useWeb3Client();

  const queryFn = async () => {
    let allAtas: AtaValueType[] = [];
    const atas = await web3client?.connection.getParsedTokenAccountsByOwner(new PublicKey(walletPublicKey || ''), {
      programId: TOKEN_PROGRAM_ID,
    });

    if (atas?.value) {
      allAtas = [...atas.value];
    }

    const atas2022 = await web3client?.connection.getParsedTokenAccountsByOwner(new PublicKey(walletPublicKey || ''), {
      programId: new PublicKey(TOKEN_2022_PROGRAM_ID),
    });

    if (atas2022?.value) {
      allAtas = [...allAtas, ...atas2022.value];
    }

    return allAtas;
  };

  const { data, ...rest } = useQuery(QUERYKEYS.userAtas(walletPublicKey || ''), queryFn, {
    enabled: Boolean(web3client),
    refetchInterval: 2 * 60 * 1000, // 2 mins
    staleTime: 3 * 60 * 1000, // 3 mins
  });

  return {
    data,
    ...rest,
  };
};
