/**
 * Prints error logs or at least message if logs are not available
 * @param err
 */
export function printError(err: any): void {
  if (!err) {
    return err;
  }

  const _err = { ...err };
  const message = _err.message || _err.msg;
  const logs = _err.logs || [];

  console.error(message);
  console.log(logs);
}
