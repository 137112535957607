import { ITooltip, Tooltip as OriginalTooltip } from 'react-tooltip';
import React, { FC, useEffect, useState } from 'react';
import { withStyles } from '@bruitt/classnames';

import { createPortal } from 'react-dom';
import s from './Tooltip.module.less';

const sx = withStyles(s);
const generateId = () => `id-${Math.random().toString(36).substring(2, 9)}`;

type TooltipProps = Omit<
  ITooltip,
  'disableStyleInjection' | 'variant' | 'anchorSelect' | 'classNameArrow' | 'className'
> & {
  uncontrolled?: boolean;
  isFluid?: boolean;
  isHidden?: boolean;
};

interface TooltipTriggerProps {
  tag?: 'div' | 'span';
  id: string;
  content?: string;
  className?: string;
  hidden?: boolean;
}

export const TooltipTrigger: FC<TooltipTriggerProps> = ({ tag = 'span', children, hidden, className, id, content }) => {
  const Tag = tag;

  return (
    <Tag
      data-tooltip-id={id}
      data-tooltip-hidden={hidden}
      data-tooltip-content={content}
      className={sx('tooltipTrigger', className)}
    >
      {children}
    </Tag>
  );
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  uncontrolled = false,
  isFluid,
  opacity = 1,
  isHidden = false,
  ...rest
}) => {
  const [id, setId] = useState('');

  useEffect(() => {
    if (!uncontrolled) {
      setId(generateId());
    }
  }, [uncontrolled]);

  return (
    <>
      {!uncontrolled && children && (
        <span className={sx('child', { fluid: isFluid })} data-tooltip-id={id}>
          {children}
        </span>
      )}

      {/* Tooltip is rendered inside a React Portal to ensure it appears above other content layers, 
  particularly for scenarios where canvas elements (such as charts) or other high z-index elements might overlap the tooltip. */}
      {createPortal(
        <OriginalTooltip
          classNameArrow={sx('arrow')}
          className={sx('tooltip')}
          disableStyleInjection
          opacity={isHidden ? 0 : opacity}
          id={`${id}`}
          {...rest}
        />,
        document.body
      )}
    </>
  );
};
