import { withStyles } from '@bruitt/classnames';
import { FC } from 'react';

import s from './Card.module.less';

const sx = withStyles(s);

export interface CardProps {
  variant?: 'primary' | 'secondary';
  className?: string;
  onClick?: () => void;
}

export const Card: FC<CardProps> = ({ variant = 'primary', className, onClick, children }) => (
  <div className={sx('root', className, { variant, clickable: Boolean(onClick) })} onClick={onClick}>
    {children}
  </div>
);
