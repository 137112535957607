// This module intentionally does not use any imports to avoid circular dependencies
// Try to keep amount of imports as less as possible here
import { isDeployPreview } from './isDeployPreview';

function isTrue(value: string | boolean | undefined): boolean {
  return (typeof value === 'string' && value?.toLowerCase() === 'true') || (typeof value === 'boolean' && value);
}

const ignoreUrlFF = ['VITE_APP_FEATURE_FLAG_ENABLE_BLOCK_OVERLAY_DISABLED'];

function isFeatureFlagEnabled(featureFlagName: string): boolean {
  const featureFlag = featureFlagName ? import.meta.env[featureFlagName] : undefined;

  return (
    // Boolean(featureFlag) checks for null and undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isTrue(featureFlag) ||
    (isDeployPreview() &&
      window.document.location.href.includes(featureFlagName) &&
      ignoreUrlFF.indexOf(featureFlagName) === -1)
  );
}

export const featureFlags = {
  isRpcPoolNodesEnabled: isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_ENABLE_RPCPOOL_NODES'),
  isMultiplyManageDebtAndCollateralEnabled: isFeatureFlagEnabled(
    'VITE_APP_FEATURE_FLAG_MULTIPLY_MANAGE_DEBT_AND_COLLATERAL'
  ),
  isBlockOverlayDisabled: isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_ENABLE_BLOCK_OVERLAY_DISABLED'),
  isFooterEnabled: isFeatureFlagEnabled('VITE_APP_IS_FOOTER_ENABLED'),
  isMockApyOnMainPage: isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_ENABLE_MAIN_PAGE_MOCK_APY'),
  isPendingRewardSectionEnabled: isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_ENABLE_PENDING_REWARDS_SECTION'),
  isRiskBlockEnabled: isFeatureFlagEnabled('VITE_APP_IS_RISK_SECTION_ENABLED'),
  isStrategyPerformanceGraphEnabled: isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_STRATEGY_PERFORMANCE_GRAPH_ENABLED'),
  isKaminoRewardTokensEnabled: isFeatureFlagEnabled('VITE_APP_IS_KAMINO_REWARD_TOKENS_ENABLED'),
  isJupiterDeposit2ApprovalsEnabled: isFeatureFlagEnabled('VITE_APP_IS_JUPITER_SWAP_DEPOSIT_2_APPROVALS_ENABLED'),
  isJupiterSwapWithdrawEnabled: isFeatureFlagEnabled('VITE_APP_IS_JUPITER_SWAP_WITHDRAW_ENABLED'),
  isNewApysSagasEnabled: isFeatureFlagEnabled('VITE_APP_IS_NEW_STRATEGIES_APYS_SAGAS_ENABLED'),
  isMockDataForPerformanceGraphEnabled: isFeatureFlagEnabled(
    'VITE_APP_FEATURE_FLAG_MOCK_DATA_FOR_PERFORMANCE_GRAPH_ENABLED'
  ),
  isKaminoConsoleEnabled:
    isFeatureFlagEnabled('VITE_APP_FEATURE_FLAG_KAMINO_CONSOLE_ENABLED') ||
    window.document.location.href.includes('VITE_APP_FEATURE_FLAG_KAMINO_CONSOLE_ENABLED'),
  isLendingPageEnabled: isFeatureFlagEnabled('VITE_APP_IS_LENDING_PAGE_ENABLED'),
  isPricingPageEnabled: isFeatureFlagEnabled('VITE_APP_IS_PRICING_PAGE_ENABLED'),
  isDepositsDisabled: isFeatureFlagEnabled('VITE_APP_IS_DEPOSITS_DISABLED'),
  isPerformanceContentBlocksEnabled: isFeatureFlagEnabled('VITE_APP_IS_PERFORMANCE_CONTENT_BLOCKS_ENABLED'),
  isVersionedTransactionsEnabled: isFeatureFlagEnabled('VITE_APP_IS_VERSIONED_TRANSACTIONS_ENABLED'),
  isTransactionHistoryEnabled: isFeatureFlagEnabled('VITE_APP_IS_TRANSACTION_HISTORY_ENABLED'),
  isMyRewardsEnabled: isFeatureFlagEnabled('VITE_APP_IS_MY_REWARDS_ENABLED'),
  isPriorityFeeForAtaTxnsEnabed: isFeatureFlagEnabled('VITE_APP_USE_PRIORITY_FEE_FOR_ATA_TXNS'),
  isStrategyDescriptionVisible: isFeatureFlagEnabled('VITE_APP_SHOW_STRATEGY_DESCRIPTION_ON_MAIN_PAGE'),
  isLendingVaultsEnabled: isFeatureFlagEnabled('VITE_APP_IS_LENDING_VAULTS_ENABLED'),
  isGeoBlockEnabled:
    isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_US_ENABLED') ||
    isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_OFAC_ENABLED') ||
    isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_OFAC_COMPREHENSIVE_ENABLED') ||
    isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_UK_ENABLED'),
  isGeoblockUsEnabled: isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_US_ENABLED'),
  isGeoblockOfacEnabled: isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_OFAC_ENABLED'),
  isGeoblockOfacComprehensiveEnabled: isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_OFAC_COMPREHENSIVE_ENABLED'),
  isGeoblockUkEnabled: isFeatureFlagEnabled('VITE_APP_IS_GEOBLOCK_UK_ENABLED'),
  isTermsSignatureEnabled: isFeatureFlagEnabled('VITE_APP_IS_TERMS_SIGNATURE_ENABLED'),
  isTradingInsightsEnabled: isFeatureFlagEnabled('VITE_APP_IS_TRADING_INSIGHTS_ENABLED'),
  isLiquidityDistributionEnabled: isFeatureFlagEnabled('VITE_APP_IS_LIQUIDITY_DISTRIBUTION_ENABLED'),
  isEstimatedEarningsEnabled: isFeatureFlagEnabled('VITE_APP_IS_ESTIMATED_EARNINGS_ENABLED'),
  isShowCalendlyPopupEnabled: isFeatureFlagEnabled('VITE_APP_SHOW_CALENDLY_POPUP'),
  isEarnEnabled: isFeatureFlagEnabled('VITE_APP_IS_EARN_ENABLED'),
  isGoToLoanButtonEnabled: isFeatureFlagEnabled('VITE_APP_IS_GO_TO_LOAN_BUTTON_ENABLED'),
  isLendingMultiplyEnabled: isFeatureFlagEnabled('VITE_IS_LENDING_MULTIPLY_ENABLED'),
  isLendingLeverageEnabled: isFeatureFlagEnabled('VITE_IS_LENDING_LEVERAGE_ENABLED'),
  isHiddenReservesEnabled: isFeatureFlagEnabled('VITE_APP_IS_HIDDEN_RESERVES_ENABLED'),
  isJtoDepositEnabled: isFeatureFlagEnabled('VITE_APP_IS_JTO_DEPOSIT_ENABLED'),
  isReferralPageEnabled: isFeatureFlagEnabled('VITE_APP_IS_REFERRAL_PAGE_ENABLED'),
  isPointsPageEnabled: isFeatureFlagEnabled('VITE_APP_IS_POINTS_PAGE_ENABLED'),
  isDepositAndStakeAtomicEnabled: isFeatureFlagEnabled('VITE_APP_DEPOSIT_AND_STAKE_ATOMIC'),
  isStrategiesWithFarmOnlyDirectRoutesSwapEnabled: isFeatureFlagEnabled(
    'VITE_APP_STRATEGIES_WITH_FARM_ONLY_DIRECT_ROUTES_SWAP'
  ),
  isRepayWithCollateralEnabled: isFeatureFlagEnabled('VITE_APP_IS_REPAY_WITH_COLLATERAL_ENABLED'),
  isMixpanelSampleRateEnabled: isFeatureFlagEnabled('VITE_APP_IS_MIXPANEL_SAMPLE_RATE_ENABLED'),
  isJlpMultiplyEnabled: isFeatureFlagEnabled('VITE_APP_IS_JLP_MULTIPLY_ENABLED'),
  isLiquidityFullApyTooltipsEnabled: isFeatureFlagEnabled('VITE_APP_IS_LIQUIDITY_FULL_APY_TOOLTIPS_ENABLED'),
  isKmnoClaimEnabled: isFeatureFlagEnabled('VITE_APP_IS_KMNO_CLAIM_ENABLED'),
  isRestrictIntermediateTokensEnabled: isFeatureFlagEnabled('VITE_APP_IS_RESTRICT_INTERMEDIATE_TOKENS_ENABLED'),
  isPyusdMultiplyEnabled: isFeatureFlagEnabled('VITE_APP_IS_PYUSD_MULTIPLY_ENABLED'),
  isEthenaMarketEnabled: isFeatureFlagEnabled('VITE_APP_IS_ETHENA_MARKET_ENABLED'),
  isGovernanceEmptyStateEnabled: isFeatureFlagEnabled('VITE_APP_IS_GOVERNANCE_EMPTY_STATE_ENABLED'),
  isGovernanceTestingEnabled: isFeatureFlagEnabled('VITE_APP_IS_GOVERNANCE_TESTING_ENABLED'),
  isGovernanceProductionEnabled: isFeatureFlagEnabled('VITE_APP_IS_GOVERNANCE_PRODUCTION_ENABLED'),
  isV3Enabled: isFeatureFlagEnabled('VITE_APP_IS_V3_ENABLED'),
  isKmnoClaimS2Enabled: isFeatureFlagEnabled('VITE_APP_IS_KMNO_CLAIM_S2_ENABLED'),
  isLimitOrdersPageEnabled: isFeatureFlagEnabled('VITE_LIMIT_ORDERS_PAGE_ENABLED'),
  areIsolatedAndCrossModesEnabled: isFeatureFlagEnabled('VITE_APP_ARE_ISOLATED_AND_CROSS_MODES_ENABLED'),
  isV3EarnEnabled: isFeatureFlagEnabled('VITE_APP_IS_V3_EARN_ENABLED'),
} as const;
