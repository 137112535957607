import React, { createContext, useCallback, useContext } from 'react';
import { notification } from '../../features/Notify';
import s from '../utils/notifications/styles.module.less';
import { NotificationTransaction, TransactionStatus } from '../types/notifications';
import { Transactions } from '../../features/Notify/components/Transactions';

import { Text } from '../uiKitV2/Typography';
import { NotificationDurations } from '../constants/notifications';

function getConfirmationMessage(transactions: NotificationTransaction[]): string {
  const confirmedTransactions = transactions.filter((transaction) => transaction.txId !== undefined);
  const transactionsCount = transactions.length;
  return `Confirming ${Math.max(1, confirmedTransactions.length)} of ${transactionsCount} transactions`;
}

interface NotificationMessage {
  id: string;
  description?: string;
  transactions: NotificationTransaction[];
}

interface NotificationContextData {
  addNotification: (notification: NotificationMessage) => void;
  updateTxStatus: (notificationKey: React.Key, transactionLabel: string, status: TransactionStatus) => void;
  updateTxByIndex: (notificationKey: React.Key, txIndex: number, fields: Partial<NotificationTransaction>) => void;
  updateTxDescriptionById: (notificationKey: React.Key, description?: string) => void;
  insertTransaction: (notificationKey: React.Key, index: number, tx: NotificationTransaction) => void;
  addTransaction: (notificationKey: React.Key, tx: NotificationTransaction) => void;
}

const NotificationContext = createContext<NotificationContextData | undefined>(undefined);

const DescriptionWithLineBreaks = ({ text }: { text?: string }) => {
  if (!text) {
    return null;
  }
  const lines = text.split('\n');

  return (
    <div className={s.descriptionText}>
      {lines.length === 1 ? (
        <Text fs={16} lh={22} color="grey" weight="regular">
          {text}
        </Text>
      ) : (
        lines.map((line, index) => (
          <React.Fragment key={index}>
            <Text fs={16} lh={22} color="grey" weight="regular">
              {line}
            </Text>
            {index !== lines.length - 1 && <br />}
          </React.Fragment>
        ))
      )}
    </div>
  );
};

function openNotification(notifMessage: NotificationMessage) {
  const allTransactionsFinished = notifMessage.transactions.every(
    (transaction) => transaction.status !== TransactionStatus.Pending
  );
  const allTransactionsFinishedSuccessfully = notifMessage.transactions.every(
    (transaction) => transaction.status === TransactionStatus.Success
  );

  notification.open({
    key: notifMessage.id,
    message: getConfirmationMessage(notifMessage.transactions),
    placement: 'bottomLeft',
    description: (
      <>
        <DescriptionWithLineBreaks text={notifMessage.description} />

        <div className={s.transactions}>
          <Transactions transactions={notifMessage.transactions} />
        </div>
      </>
    ),
    duration: allTransactionsFinishedSuccessfully
      ? NotificationDurations.success
      : allTransactionsFinished
      ? NotificationDurations.errorWithTx
      : NotificationDurations.loading,
  });
}

export const NotificationProvider: React.FC = ({ children }) => {
  const [, setNotificationMessages] = React.useState<NotificationMessage[]>([]);

  const addNotification = (notify: NotificationMessage) => {
    setNotificationMessages((prev) => {
      // remove notification with the same id if exists
      // add new one to the end of the list
      return [...prev.filter((n) => n.id !== notify.id), notify];
    });
    openNotification(notify);
  };

  const updateTxStatus = (notificationKey: React.Key, transactionLabel: string, status: TransactionStatus) => {
    setNotificationMessages((prev) => {
      const updatedMessages = prev.map((message) => {
        if (message.id === notificationKey) {
          message.transactions = message.transactions.map((tx) =>
            tx.label === transactionLabel ? { ...tx, status } : tx
          );
        }

        return message;
      });
      const updatedNotification = updatedMessages.find((msg) => msg.id === notificationKey);

      if (updatedNotification) {
        openNotification(updatedNotification);
      }

      return updatedMessages;
    });
  };

  const updateTxByIndex = (notificationKey: React.Key, txIndex: number, fields: Partial<NotificationTransaction>) => {
    setNotificationMessages((prev) => {
      const updatedMessages = prev.map((message) => {
        if (message.id === notificationKey) {
          message.transactions = message.transactions.map((tx, i) => (i === txIndex ? { ...tx, ...fields } : tx));
        }

        return message;
      });
      const updatedNotification = updatedMessages.find((msg) => msg.id === notificationKey);

      if (updatedNotification) {
        openNotification(updatedNotification);
      }

      return updatedMessages;
    });
  };

  const updateTxDescriptionById = useCallback((notificationKey: React.Key, description?: string) => {
    setNotificationMessages((prev) => {
      return prev.map((message) => {
        if (message.id === notificationKey) {
          message.description = description;
          openNotification(message);
        }

        return message;
      });
    });
  }, []);

  const addTransaction = (notificationKey: React.Key, transaction: NotificationTransaction) => {
    setNotificationMessages((prev) => {
      return prev.map((message) => {
        if (message.id === notificationKey) {
          message.transactions.push(transaction);
          openNotification(message);
        }
        return message;
      });
    });
  };

  const insertTransaction = (notificationKey: React.Key, index: number, transaction: NotificationTransaction) => {
    setNotificationMessages((prev) => {
      const updatedMessages = prev.map((message) => {
        if (message.id === notificationKey) {
          message.transactions =
            index >= message.transactions.length
              ? [...message.transactions, transaction]
              : [...message.transactions.slice(0, index), transaction, ...message.transactions.slice(index)];
        }

        return message;
      });

      const updatedNotification = updatedMessages.find((msg) => msg.id === notificationKey);

      if (updatedNotification) {
        openNotification(updatedNotification);
      }

      return updatedMessages;
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        updateTxStatus,
        updateTxByIndex,
        updateTxDescriptionById,
        addTransaction,
        insertTransaction,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (): NotificationContextData => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
