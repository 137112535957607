import cx from 'classnames';
import { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import { Icon, IconProps } from '../../../../../shared/uiKitV2/Icon';
import { Link } from '../../../../../shared/uiKitV2/Link';
import { Text } from '../../../../../shared/uiKitV2/Typography';
import { getShortAge } from '../../../../../shared/utils';

import s from './NotificationListItem.module.less';

const IconPropsByNotificationType: Record<string, Pick<IconProps, 'name' | 'color'>> = {
  'earning-updates': {
    name: 'ChartCandle',
    color: 'green-light',
  },
  'public-notifications': {
    name: 'WarningInfo',
    color: 'blue',
  },
  'position-warnings': {
    name: 'WarningTriangleLarge',
    color: 'orange',
  },
};

const NotificationTypeClasses: Record<string, string> = {
  'earning-updates': s.earningUpdates,
  'public-notifications': s.publicNotifications,
  'position-warnings': s.positionWarnings,
};

type Props = {
  isUnread: boolean;
  notificationTypeHumanReadableId?: string;
  actionLabel?: string;
  actionUrl?: string;
  title?: string;
  text: string;
  timestamp: Date;
};

export const NotificationListItem = ({
  isUnread,
  notificationTypeHumanReadableId,
  actionUrl,
  actionLabel,
  title,
  text,
  timestamp,
}: Props) => {
  const iconProps = notificationTypeHumanReadableId
    ? IconPropsByNotificationType[notificationTypeHumanReadableId]
    : undefined;
  const [isHovered, setIsHovered] = useState(false);
  const mixpanel = useMixpanel();

  const handleClick = () => {
    mixpanel.track('notification-clicked', {
      notificationLabel: title,
    });
  };

  return (
    <a
      className={cx(
        s.message,
        notificationTypeHumanReadableId && NotificationTypeClasses[notificationTypeHumanReadableId]
      )}
      target="_blank"
      rel="noreferrer"
      href={actionUrl}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => {}}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <Icon name={iconProps?.name} size={24} color={isUnread || isHovered ? iconProps?.color : 'grey'} />
      <div className={s.messageContent}>
        <Text className={s.infoText} fs={16} lh={22} color={isUnread || isHovered ? 'grey-light' : 'grey'}>
          {title}
        </Text>
        <Text
          className={s.infoText}
          fs={14}
          lh={18}
          color={isUnread || isHovered ? 'grey' : 'grey-deep'}
          weight="regular"
        >
          {text}
        </Text>
        <div className={s.linkAndAgeContainer}>
          {actionUrl && (
            <Link
              textProps={{
                color: isUnread || isHovered ? 'blue-deep' : 'grey-deep',
                fs: 14,
                lh: 18,
              }}
              target="_blank"
              rel="noreferrer"
              href={actionUrl}
              endIcon={<Icon name="ArrowRight" size={16} color={isUnread || isHovered ? 'blue-deep' : 'grey-deep'} />}
            >
              {actionLabel}
            </Link>
          )}
          <Text
            className={s.infoText}
            fs={14}
            lh={18}
            color={isUnread || isHovered ? 'white' : 'grey-deep'}
            weight="regular"
          >
            {getShortAge(timestamp)}
          </Text>
        </div>
      </div>
    </a>
  );
};
