export const getErrorMessage = (err: any): string | undefined => {
  if (!err) {
    return undefined;
  }
  return (
    err.msg ||
    err?.error?.msg ||
    err.err?.msg ||
    err.err?.error?.msg ||
    err.message ||
    err?.error?.message ||
    err.err?.message ||
    err.err?.error?.message
  );
};
