import { useQueryClient } from '@tanstack/react-query';
import { KaminoMarket } from '@kamino-finance/klend-sdk';
import { useCallback, useMemo } from 'react';
import { QUERYKEYS } from '../../constants/queryKeys';
import { notify } from '../../utils/notifications/notifications';
import { featureFlags } from '../../utils';
import { PublicKeyAddress } from '../../types/strategies';
import { captureError } from '../../utils/captureError';
import { useMarketsQuery } from './queries/useMarketsQuery';
import { getMarketAddressFromUrl } from '../../utils/lending/getMarketAddressFromUrl';
import { useMarketsFromResourcesQuery } from './queries/useMarketsFromResourcesQuery';

type MarketsMap = Record<string, KaminoMarket>;
const EMPTY_MARKETS: KaminoMarket[] = [];

export const useMarkets = (loadExactMarket?: number) => {
  useMarketsQuery(loadExactMarket); // TODO: this needs to account for exact market approach need to figure out how to do it although it wont wait, it still fetches data we dont need, a new useMarket hook might be nice as well have more and more markets
  const { data: marketsFromResources } = useMarketsFromResourcesQuery();
  const queryClient = useQueryClient();
  const marketsWitoutReserves = queryClient.getQueryData<KaminoMarket[]>(QUERYKEYS.lendingMarketsWithoutReserves);
  const marketsFull = queryClient.getQueryData<KaminoMarket[]>(QUERYKEYS.lendingMarkets);

  const exactMarketAddress =
    getMarketAddressFromUrl() ||
    (loadExactMarket !== undefined ? marketsFromResources?.[loadExactMarket].lendingMarket ?? null : null);
  const exactMarket = queryClient.getQueryData<KaminoMarket[]>(
    QUERYKEYS.exactLendingMarket(exactMarketAddress ?? undefined)
  );
  const exactMarketWithoutReserves = queryClient.getQueryData<KaminoMarket[]>(
    QUERYKEYS.exactLendingMarketWithoutReserve(exactMarketAddress ?? undefined)
  );
  const markets = exactMarketAddress
    ? exactMarket || exactMarketWithoutReserves || EMPTY_MARKETS
    : marketsFull || marketsWitoutReserves || EMPTY_MARKETS;
  const { MODE } = import.meta.env;
  const isError = !markets && featureFlags.isLendingPageEnabled;

  // for local development throw an exception
  // for prod use isError to show user-friendly error message/screen
  if (MODE === 'development' && isError) {
    notify({
      type: 'error',
      message: 'Technical error',
      description: 'Markets are not loaded',
    });
    throw new Error('Markets are not loaded. Make sure you use call useMarkets after userMarketsQuery fully loaded');
  }
  if (MODE === 'production' && isError) {
    captureError(new Error('Markets are not loaded'));
  }

  const marketsMap = useMemo(() => {
    return markets.reduce((sum, market) => {
      sum[market.address] = market;
      return sum;
    }, {} as MarketsMap);
  }, [markets]);

  const getMarketByAddress = useCallback(
    (address: PublicKeyAddress) => {
      return marketsMap[address.toString()];
    },
    [marketsMap]
  );

  const getMarketByIdx = useCallback(
    (idx: number) => {
      return markets?.[idx];
    },
    [markets]
  );

  const getMarketIdx = useCallback(
    (address: PublicKeyAddress) => {
      return markets?.findIndex((market) => market.address.toString() === address.toString());
    },
    [markets]
  );

  return {
    markets,
    isError,
    getMarketByAddress,
    getMarketByIdx,
    getMarketIdx,
    marketsMap,
  };
};
