import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import Decimal from 'decimal.js';
import { PublicKeyAddress } from '../../../shared/types/strategies';
import { QUERYKEYS } from '../../../shared/constants/queryKeys';
import { PnLForStrategyResponseWithAddress } from '../../../shared/types/strategyPnl';
import { UserFeesAndRewardsEarnings } from '../../../models/api/UserFeesAndRewardsEarnings';
import { calculateTotalEarnings } from '../../../shared/utils/kamino';
import { useWalletAddressFromParamsOrEnv } from '../../../shared/hooks/useWalletAddressFromParamsOrEnv';

export const useCacheLiquidityData = () => {
  const queryClient = useQueryClient();
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();

  const getPnLFromCache = useCallback(
    (strategyAddress: PublicKeyAddress) => {
      if (walletPublicKey) {
        const queryKey = QUERYKEYS.getUserPositionPnL(walletPublicKey, strategyAddress);
        const cachedData = queryClient.getQueryData(queryKey) as PnLForStrategyResponseWithAddress;
        return cachedData?.totalPnl;
      }
    },
    [walletPublicKey, queryClient]
  );

  const getFeesFromCache = useCallback(
    (strategyAddress: PublicKeyAddress) => {
      if (walletPublicKey) {
        const queryKey = QUERYKEYS.getUserFeesAndRewards(walletPublicKey, strategyAddress);
        const cachedData = queryClient.getQueryData(queryKey) as UserFeesAndRewardsEarnings;
        return calculateTotalEarnings(cachedData);
      }

      return new Decimal(0);
    },
    [walletPublicKey, queryClient]
  );

  return { getPnLFromCache, getFeesFromCache };
};
