import { useCallback } from 'react';
import { AddressLookupTableAccount, Connection, PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js';
import { Instruction } from '@jup-ag/api';
import { getComputeBudgetAndPriorityFeeIxns, numberToLamportsDecimal, removeBudgetIxns } from '../utils';

import useEnv from './useEnv';
import { usePriorityFee } from './usePriorityFee';
import { PriorityFeeType } from '../types/priorityFeeStats';
import { DECIMALS_SOL } from '../constants/math';
import { getSimulationComputeUnits } from '../utils/getSimulationComputeUnits';
import { useWeb3Client } from './useWeb3Client';
import {
  buildVersionedTransaction,
  convertJupiterIxnToTransactionIxn,
  isInstruction,
} from '../utils/operations/transactions';

export function useBudgetAndPriorityFeeIxns() {
  const { web3client } = useWeb3Client();
  const { walletPublicKey } = useEnv();

  const { getPriorityFeeForType, priorityFee, priorityFeeType } = usePriorityFee();

  const getIxns = useCallback(
    (units = 200000, isPriorityFeeExcluded?: boolean) => {
      if (!walletPublicKey) {
        throw new Error('Wallet is not connected');
      }

      const feeLamports = numberToLamportsDecimal(
        priorityFeeType && priorityFeeType !== PriorityFeeType.custom
          ? getPriorityFeeForType(priorityFeeType)
          : priorityFee,
        DECIMALS_SOL
      );

      return getComputeBudgetAndPriorityFeeIxns(
        new PublicKey(walletPublicKey),
        units,
        feeLamports && feeLamports.gt(0) && !isPriorityFeeExcluded ? feeLamports : undefined
      );
    },
    [walletPublicKey, priorityFeeType, getPriorityFeeForType, priorityFee]
  );

  const estimateComputeUnits = useCallback(
    ({
      ixns,
      lookupTablesAddresses,
      lookupTablesAccounts,
      isVersionedTransaction,
    }: {
      ixns: TransactionInstruction[];
      lookupTablesAddresses?: PublicKey[];
      lookupTablesAccounts?: AddressLookupTableAccount[];
      isVersionedTransaction: boolean;
    }) => {
      if (!web3client) {
        throw new Error('Failed to establish connection. Please, reload the page and try again');
      }
      if (!walletPublicKey) {
        throw new Error('Wallet is not connected. Please, connect the wallet and try again');
      }

      return getSimulationComputeUnits({
        connection: web3client.sendConnection,
        instructions: ixns,
        payer: new PublicKey(walletPublicKey),
        lookupTablesAddresses,
        lookupTablesAccounts,
        isVersionedTransaction,
      });
    },
    [walletPublicKey, web3client]
  );

  const getEstimatedComputeUnitsAndAddPriorityFeeIxns = useCallback(
    async ({
      ixns,
      lookupTablesAddresses,
      lookupTablesAccounts,
      isVersionedTransaction,
      defaultCu,
    }: {
      ixns: TransactionInstruction[];
      lookupTablesAddresses?: PublicKey[];
      lookupTablesAccounts?: AddressLookupTableAccount[];
      isVersionedTransaction: boolean;
      defaultCu: number;
    }) => {
      const cu = await estimateComputeUnits({
        ixns,
        lookupTablesAddresses,
        lookupTablesAccounts,
        isVersionedTransaction,
      });
      return getIxns(cu || defaultCu);
    },
    [estimateComputeUnits, getIxns]
  );

  /**
   * Creates new Legacy Transaction with CU and Priority Fee ixns
   */
  const createLegacyTxWithBudgetIxns = useCallback(
    async (ixns: TransactionInstruction[], defaultCu: number) => {
      const budgetIxns = await getEstimatedComputeUnitsAndAddPriorityFeeIxns({
        ixns,
        isVersionedTransaction: false,
        defaultCu,
      });
      const tx = new Transaction();
      tx.add(...budgetIxns, ...removeBudgetIxns(ixns));
      return tx;
    },
    [getEstimatedComputeUnitsAndAddPriorityFeeIxns]
  );

  /**
   * Creates new Versioned Transaction with CU and Priority Fee ixns
   */
  const createVersionedTxWithBudgetIxns = useCallback(
    async ({
      ixns,
      connection,
      walletPublicKeyParam,
      lookupTablesAddresses,
      lookupTablesAccounts,
      defaultCu,
    }: {
      ixns: Array<TransactionInstruction | Instruction>;
      defaultCu: number;
      connection?: Connection;
      walletPublicKeyParam?: PublicKey;
      lookupTablesAddresses?: PublicKey[];
      lookupTablesAccounts?: AddressLookupTableAccount[];
    }) => {
      if (!web3client) {
        throw new Error('Failed to establish connection. Please, reload the page and try again');
      }
      if (!walletPublicKey) {
        throw new Error('Wallet is not connected. Please, connect the wallet and try again');
      }

      const convertedIxns = ixns.map((ixn) => {
        if (isInstruction(ixn)) {
          return convertJupiterIxnToTransactionIxn(ixn);
        }

        return ixn;
      });

      const budgetIxns = await getEstimatedComputeUnitsAndAddPriorityFeeIxns({
        ixns: convertedIxns,
        isVersionedTransaction: true,
        lookupTablesAddresses,
        lookupTablesAccounts,
        defaultCu,
      });
      const instructions = [...budgetIxns, ...removeBudgetIxns(convertedIxns)];
      return buildVersionedTransaction(
        connection || web3client.sendConnection,
        walletPublicKeyParam || walletPublicKey,
        instructions,
        lookupTablesAddresses,
        lookupTablesAccounts
      );
    },
    [getEstimatedComputeUnitsAndAddPriorityFeeIxns, walletPublicKey, web3client]
  );

  return {
    getIxns,
    estimateComputeUnits,
    getEstimatedComputeUnitsAndAddPriorityFeeIxns,
    createLegacyTxWithBudgetIxns,
    createVersionedTxWithBudgetIxns,
  };
}
