export type PriorityFeeStats = {
  min: number;
  max: number;
  avg: number;
};

export enum PriorityFeeType {
  custom = 'custom',
  minimum = 'minimum',
  medium = 'medium',
  turbo = 'turbo',
}
