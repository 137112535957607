import React, { FC } from 'react';

import { PnLForStrategyResponse } from '../../../../types/strategyPnl';
import { HubbleIcon } from '../../../HubbleIcon/HubbleIcon';
import { PnlValue } from '../../PnlValue/PnlValue';
import { PublicKeyAddress } from '../../../../types/strategies';
import { Token } from '../../../../constants/tokens';
import useTokens from '../../../../hooks/useTokens';
import { Text } from '../../../../uiKitV2/Typography';

import s from './PnlTooltipBody.module.less';

interface PnlTooltipProps {
  data: PnLForStrategyResponse['totalPnl'];
  tokenAMint: PublicKeyAddress;
  tokenBMint: PublicKeyAddress;
}

interface ItemProps {
  value: string;
  token: Token | 'USD';
  post?: string;
  pre?: string;
}

const Item: FC<ItemProps> = ({ value, token, post, pre }) => {
  return (
    <div className={s.item}>
      <div className={s.left}>
        <HubbleIcon name={token} className={s.tokenIcon} />
        <Text color="white" className={s.uppercase}>{`${token} PNL`}</Text>
      </div>

      <PnlValue value={value} pre={pre} post={post} />
    </div>
  );
};

export const PnlTooltipBody: FC<PnlTooltipProps> = ({ data, tokenAMint, tokenBMint }) => {
  const { getToken } = useTokens();
  const tokenA = getToken(tokenAMint).symbol;
  const tokenB = getToken(tokenBMint).symbol;
  return (
    <div className={s.root}>
      <Item token="USD" pre="$" value={data.usd} />
      <Item token="SOL" post="SOL" value={data.sol} />

      {tokenA !== 'SOL' && <Item token={tokenA} post={tokenA} value={data.a} />}
      {tokenB !== 'SOL' && <Item token={tokenB} post={tokenB} value={data.b} />}
    </div>
  );
};
