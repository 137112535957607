import { makeAutoObservable } from 'mobx';
import { getAreTermsConfirmed } from '../providers/utils';

export interface TermsParams {
  areTermsConfirmed: boolean;
}

export interface TermsStore extends TermsParams {
  updateProviderParams: (params: Partial<TermsParams>) => void;
}

export const getTermsStoreDefaultValue = (): TermsParams => ({
  areTermsConfirmed: getAreTermsConfirmed(),
});

const store = makeAutoObservable<TermsStore>({
  ...getTermsStoreDefaultValue(),

  // arrow function and 'store' object are used to prevent missing "this" context
  updateProviderParams: (params: Partial<TermsParams>) => {
    Object.assign(store, params);
  },
});

export { store as termsStore };
