import { DEVNET_CHAIN_ID, MAINNET_BETA_CHAIN_ID } from '../../constants/env';

export type ENV = 'mainnet-beta' | 'devnet' | 'localnet';

export type Chain = {
  name: ENV;
  displayName: string;
  endpoint: string;
  chainID: number;
  wsEndpoint?: string;
};

export const CHAINS: Chain[] = [
  // {
  //   name: 'devnet',
  //   endpoint: 'https://hubblep-develope-bbc5.devnet.rpcpool.com/',
  //   chainID: DEVNET_CHAIN_ID,
  //   displayName: 'Devnet (Triton)',
  // },
  {
    name: 'mainnet-beta',
    endpoint: 'https://hubblep-main-0776.mainnet.rpcpool.com/',
    wsEndpoint: 'wss://hubblep-main-0776.mainnet.rpcpool.com/whirligig',
    chainID: MAINNET_BETA_CHAIN_ID,
    displayName: 'Mainnet Beta (Triton)',
  },
  {
    name: 'localnet',
    endpoint: 'http://127.0.0.1:8899',
    chainID: DEVNET_CHAIN_ID,
    displayName: 'Localnet',
  },
];
