import { FC, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import s from './Swap.module.less';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { SwapForm } from '../SwapForm/SwapForm';
import { SOL_MINT, USDC_MINT } from '../../../../shared/constants/mints';
import { usePrices } from '../../../../shared/hooks/usePrices';
import { useSwap } from '../../hooks/useSwap';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';
import { useTokensWithPrices } from '../../hooks/useTokensWithPrices';
import { useUserAtaBalances } from '../../hooks/useUserAtaBalances';

type SwapProps = {
  onBack: () => void;
};
export const SwapMode: FC<SwapProps> = ({ onBack }) => {
  const [selectedInputTokenMint, setSelectedInputTokenMint] = useState(SOL_MINT);
  const [selectedOutputTokenMint, setSelectedOutputTokenMint] = useState(USDC_MINT);
  const [inputTokenAmount, setInputTokenAmount] = useState(0);
  const [outputTokenAmount, setOutputTokenAmount] = useState(0);
  const mixpanel = useMixpanel();
  const { getPriceByTokenMintDecimal } = usePrices();
  const { tokens } = useTokensWithPrices({});
  const { data: balances } = useUserAtaBalances({});

  const handleSuccess = () => {
    setInputTokenAmount(0);
    setOutputTokenAmount(0);
  };

  const { onSwap, isLoading } = useSwap({ onSuccess: handleSuccess });
  const { isVersionedTxEnabled } = layoutProviderStore;

  const inputTokenpPrice = getPriceByTokenMintDecimal(selectedInputTokenMint);
  const outputTokenPrice = getPriceByTokenMintDecimal(selectedOutputTokenMint);

  const handleSwap = () => {
    mixpanel.track('wallet:swap:click');
    onSwap({
      inputMint: selectedInputTokenMint,
      outputMint: selectedOutputTokenMint,
      inputAmount: inputTokenAmount,
      isVersionedTransactionEnabled: isVersionedTxEnabled,
    });
  };

  const handleInputTokenAmountChange = (amount: number) => {
    setInputTokenAmount(amount);

    const tokenBinA = inputTokenpPrice.eq(0) ? 0 : inputTokenpPrice.div(outputTokenPrice).mul(amount);
    setOutputTokenAmount(Number(tokenBinA.toString()));
  };

  return (
    <div className={s.content}>
      <div className={s.title}>
        <IconButton onClick={onBack} size="small" variant="secondary" icon={<Icon name="ArrowLeft" color="white" />} />
        <Text fs={22} lh={32}>
          Swap
        </Text>
      </div>

      <SwapForm
        selectedInputTokenMint={selectedInputTokenMint}
        selectedOutputTokenMint={selectedOutputTokenMint}
        onInputTokenSelect={(mint) => setSelectedInputTokenMint(mint.toString())}
        onOutputTokenSelect={(mint) => setSelectedOutputTokenMint(mint.toString())}
        inputTokenAmount={inputTokenAmount}
        outputTokenAmount={outputTokenAmount}
        tokens={tokens}
        onInputTokenChange={handleInputTokenAmountChange}
        onOutputTokenChange={(amount) => setOutputTokenAmount(amount)}
        balances={balances}
        isProcessing={isLoading}
        onSwap={handleSwap}
      />
    </div>
  );
};
