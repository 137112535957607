import { withStyles } from '@bruitt/classnames';
import React from 'react';
import s from './Checkbox.module.less';
import { Text } from '../Typography';
import { ColorProps } from '../../types/global/colors';

const sx = withStyles(s);

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title' | 'color'>,
    ColorProps {
  type?: 'square' | 'circle';
  icon?: 'check' | 'cross';
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  suffix?: React.ReactNode;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    className,
    disabled,
    type = 'square',
    icon = 'check',
    color = 'blue',
    title,
    subtitle,
    suffix,
    ...rest
  } = props;

  return (
    <div className={sx('root', className, { disabled })}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={sx('label', { disabled })}>
        <input
          ref={ref}
          type="checkbox"
          className={sx('checkbox', {
            type,
            bgcolor: color,
            icon,
          })}
          disabled={disabled}
          {...rest}
        />

        {(title || subtitle) && (
          <div className={sx('texts')}>
            {typeof title === 'string' ? (
              <Text fs={14} lh={20}>
                {title}
              </Text>
            ) : (
              title
            )}
            {typeof subtitle === 'string' ? (
              <Text fs={12} lh={12}>
                {subtitle}
              </Text>
            ) : (
              subtitle
            )}
          </div>
        )}
      </label>
      {suffix}
    </div>
  );
});
