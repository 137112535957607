import { ReactElement, SVGProps } from 'react';

export const SvgGauntlet = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={15} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#gauntlet_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.979 12h2.989V9h-2.99V6h5.98v9H2.988v-3H0V0h11.957v3H2.99v9h2.99Z"
        fill="#7180F5"
      />
      <path d="M14.946 3h-2.99v3h2.99V3Z" fill="#FF8099" />
      <path d="M2.99 15H0v3h2.99v-3Z" fill="#FBFAFF" />
    </g>
    <defs>
      <clipPath id="gauntlet_svg__a">
        <path fill="#fff" d="M0 0h15v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
