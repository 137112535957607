import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { KaminoObligation } from '@kamino-finance/klend-sdk';
import { PublicKey } from '@solana/web3.js';

import useEnv from '../../useEnv';
import { featureFlags } from '../../../utils';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchObligationPnl } from '../../../services/lending/fetchObligationPnl';
import { ObligationPnlResponse } from '../../../types/lending';

interface UseObligationsPnlQueryProps {
  obligations: KaminoObligation[];
}

interface ObligationPnlsResponse {
  address: PublicKey;
  pnl: ObligationPnlResponse;
}

const EMPTY_ARRAY: ObligationPnlsResponse[] = [];
const EMPTY_PNL_RECORD: Record<string, ObligationPnlResponse> = {};

export const useObligationsPnlQuery = ({ obligations }: UseObligationsPnlQueryProps) => {
  const { walletPublicKey } = useEnv();
  const queryClient = useQueryClient();

  const queryKey = QUERYKEYS.getObligationsPnl(obligations.map((item) => item.obligationAddress.toString()));
  const enabled = featureFlags.isLendingPageEnabled && Boolean(obligations.length) && Boolean(walletPublicKey);

  const queryFn = async () =>
    Promise.all(
      obligations.map((item) =>
        fetchObligationPnl({ marketAddress: item.state.lendingMarket, obligationAddress: item.obligationAddress })
      )
    ).then((data) => data.map((item, index) => ({ address: obligations[index].obligationAddress, pnl: item })));

  const { data, isLoading, ...rest } = useQuery(queryKey, queryFn, {
    enabled,
    onSuccess: (pnlItems) => {
      pnlItems.forEach((item) => {
        queryClient.setQueryData(QUERYKEYS.getObligationPnl(item.address.toString()), item.pnl);
      });
    },
  });

  const pnlMap = useMemo(() => {
    if (!data) {
      return EMPTY_PNL_RECORD;
    }
    return data?.reduce((acc, item) => {
      acc[item.address.toString()] = item.pnl;
      return acc;
    }, {} as Record<string, ObligationPnlResponse>);
  }, [data]);

  return {
    data: data || EMPTY_ARRAY,
    pnlMap,
    isReady: !enabled || !isLoading,
    isLoading,
    ...rest,
  };
};
