import { ReactElement, SVGProps } from 'react';

export const SvgArchive = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5 6h-8a.667.667 0 0 0-.667.667V12c0 .368.299.667.667.667h8a.667.667 0 0 0 .667-.667V6.667A.667.667 0 0 0 12.5 6ZM7.167 9.334h2.666M13.35 4h-9.7c-.21 0-.41.1-.535.27l-.494.667A.667.667 0 0 0 3.157 6h10.686a.667.667 0 0 0 .536-1.063l-.494-.667a.667.667 0 0 0-.536-.27Z"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
