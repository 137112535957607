import { PublicKey, TransactionResponse } from '@solana/web3.js';
import Decimal from 'decimal.js';
import { Web3Client } from '../web3/client';
import { isNil } from '../../utils/isNil';
import { waitForTransaction } from '../../utils';

export class SystemService {
  private _client: Web3Client;

  constructor(client: Web3Client) {
    this._client = client;
  }

  getTokenBalanceChange = async (ataToCheckDiff: PublicKey, txid: string): Promise<Decimal | null> => {
    // @ts-ignore
    const transactionDetails: TransactionResponse | null = await waitForTransaction(this._client.connection, txid);
    if (!transactionDetails) {
      throw new Error(`Transaction ${txid} is not found`);
    }

    const txVersion = !isNil(transactionDetails.transaction?.message?.version)
      ? transactionDetails.transaction?.message?.version
      : 'legacy';

    let ataToCheckDiffIx = -1;

    if (txVersion === 'legacy') {
      for (let i = 0; i < transactionDetails.transaction.message.accountKeys.length; i++) {
        if (transactionDetails.transaction.message.accountKeys[i].toString() === ataToCheckDiff.toString()) {
          ataToCheckDiffIx = i;
          break;
        }
      }
    } else if (txVersion === 0) {
      for (let i = 0; i < transactionDetails.transaction.message.staticAccountKeys.length; i++) {
        if (transactionDetails.transaction.message.staticAccountKeys[i].toString() === ataToCheckDiff.toString()) {
          ataToCheckDiffIx = i;
          break;
        }
      }
    } else {
      throw new Error('Transaction version is not supported');
    }

    if (
      ataToCheckDiffIx == null ||
      !transactionDetails.meta ||
      !transactionDetails.meta.postTokenBalances ||
      !transactionDetails.meta.preTokenBalances
    ) {
      throw Error('Transaction was not complete, could not find ATA index');
    }

    let prevAtaBalance = new Decimal(0);
    let postAtaBalance = new Decimal(0);

    for (let i = 0; i < transactionDetails.meta.preTokenBalances.length; i++) {
      if (transactionDetails.meta.preTokenBalances[i].accountIndex === ataToCheckDiffIx) {
        prevAtaBalance = new Decimal(transactionDetails.meta.preTokenBalances[i].uiTokenAmount.amount);
        break;
      }
    }

    for (let i = 0; i < transactionDetails.meta.postTokenBalances.length; i++) {
      if (transactionDetails.meta.postTokenBalances[i].accountIndex === ataToCheckDiffIx) {
        postAtaBalance = new Decimal(transactionDetails.meta.postTokenBalances[i].uiTokenAmount.amount);
        break;
      }
    }

    return postAtaBalance.sub(prevAtaBalance);
  };
}
