import { ReactElement, SVGProps } from 'react';

export const SvgClockSmall = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#98B0C1" strokeLinecap="round" strokeLinejoin="round">
      <path d="M8 14.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" strokeMiterlimit={10} />
      <path d="M8 5.166v3.333h3.333" />
    </g>
  </svg>
);
