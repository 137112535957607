import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { layoutProviderStore } from '../../../../../stores/layoutProviderStore';

export const useInitWhatsNewIndicators = () => {
  const { location } = useHistory();

  const { updateProviderParams } = layoutProviderStore;
  useEffect(() => {
    updateProviderParams({
      areWhatsNewIndicatorsEnabled: false,
    });

    const timeoutId = setTimeout(() => {
      updateProviderParams({
        areWhatsNewIndicatorsEnabled: true,
      });
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [location.pathname]);
};
