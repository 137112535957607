import React from 'react';
import { withStyles } from '@bruitt/classnames';
import { Link, LinksList } from './components/LinksList';
import { Logo } from '../../shared/uiKitV2/Logo';
import { Text } from '../../shared/uiKitV2/Typography';
import s from './Footer.module.less';
import { FOOTER_LOGOS_V2 } from '../../shared/components/AppFooter/types';
import paths from '../../shared/routes/paths';
import { SOCIAL_LINKS } from '../../shared/constants/links';

const sx = withStyles(s);

const ABOUT_LINKS: Link[] = [
  { id: 1, label: 'Documentation', href: 'https://docs.kamino.finance/' },
  // { id: 3, label: 'Privacy', href: '' },
  { id: 4, label: 'Terms', href: 'https://app.kamino.finance/terms' },
];

// TODO: add feature flags support here
const PRODUCTS_LINKS: Link[] = [
  { id: 1, label: 'Lending', to: paths.LENDING.BASE },
  { id: 2, label: 'Liquidity', to: paths.LIQUIDITY },
  { id: 3, label: 'Multiply', to: paths.LENDING.LENDING_MULTIPLY },
  { id: 4, label: 'Leverage', to: paths.LENDING.LENDING_LEVERAGE },
  { id: 5, label: 'Creator Vaults', href: 'https://app.kamino.finance/create' },
];

// TODO: add feature flags support here
const RESOURCES_LINKS: Link[] = [
  { id: 3, label: 'Litepaper', href: 'https://docs.kamino.finance/kamino-lend-litepaper' },
  { id: 4, label: 'K-Lend Risk Dashboard', href: 'https://risk.kamino.finance/' },
  { id: 1, label: 'Leaderboard (Coming Soon)', href: '#', color: 'grey-deep' },
  { id: 2, label: 'Referrals (Coming Soon)', href: '#', color: 'grey-deep' },
];

const CONNECT_LINKS: Link[] = [
  { id: 2, label: 'Discord', href: SOCIAL_LINKS.discord },
  { id: 3, label: 'Twitter', href: SOCIAL_LINKS.twitter },
  { id: 4, label: 'Contact', href: 'mailto:support@kamino.finance' },
];

export const Footer = () => {
  return (
    <div className={sx('root')}>
      <div className={sx('grid')}>
        <div className={sx('leftCol')}>
          <div className={sx('logoAndSocials')}>
            <Logo />

            <div className={sx('socials')}>
              {FOOTER_LOGOS_V2.map((logo) => {
                return (
                  <div className={sx('icon')} key={logo.alt}>
                    <a href={logo.link} target="_blank" rel="noreferrer">
                      <img alt={logo.alt} src={logo.img} />
                    </a>
                  </div>
                );
              })}
            </div>

            <div className={sx('copyright')}>
              <Text>©{new Date().getFullYear()} Kamino. All rights reserved</Text>
            </div>
          </div>
        </div>

        <div className={sx('rightCol')}>
          <div className={sx('linksGrid')}>
            <div className={sx('cell')}>
              <LinksList title="About" links={ABOUT_LINKS} />
            </div>
            <div className={sx('cell')}>
              <LinksList title="Products" links={PRODUCTS_LINKS} />
            </div>
            <div className={sx('cell')}>
              <LinksList title="Resources" links={RESOURCES_LINKS} />
            </div>
            <div className={sx('cell')}>
              <LinksList title="Connect" links={CONNECT_LINKS} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
