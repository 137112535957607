import {
  AddressLookupTableAccount,
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';
import { getLookupTableAccount } from './operations/transactions';
import { notEmpty } from './notEmpty';

export async function getSimulationComputeUnits({
  connection,
  instructions,
  payer,
  lookupTablesAddresses,
  lookupTablesAccounts,
  computeErrorMarginPct = 0.15, // +15% extra CUs to ensue we have enough
  isVersionedTransaction,
}: {
  connection: Connection;
  instructions: TransactionInstruction[];
  payer: PublicKey;
  lookupTablesAddresses?: PublicKey[];
  lookupTablesAccounts?: AddressLookupTableAccount[];
  computeErrorMarginPct?: number;
  isVersionedTransaction: boolean;
}): Promise<number | undefined> {
  let LUTs = lookupTablesAccounts && lookupTablesAccounts.length > 0 ? lookupTablesAccounts : undefined;
  if (!LUTs && lookupTablesAddresses) {
    LUTs = (
      await Promise.all(lookupTablesAddresses.map((address) => getLookupTableAccount(connection, address)))
    ).filter(notEmpty);
  }

  if (!LUTs) {
    LUTs = [];
  }

  const txn = isVersionedTransaction
    ? new VersionedTransaction(
        new TransactionMessage({
          instructions,
          payerKey: payer,
          recentBlockhash: PublicKey.default.toString(),
        }).compileToV0Message(LUTs)
      )
    : new Transaction({ feePayer: payer }).add(...instructions);

  const simulation = isVersionedTransaction
    ? await connection.simulateTransaction(txn as VersionedTransaction, {
        replaceRecentBlockhash: true,
        sigVerify: false,
      })
    : await connection.simulateTransaction(txn as Transaction);

  if (simulation.value.err) {
    return undefined;
  }
  if (!simulation.value.unitsConsumed) {
    return 200_000;
  }
  return Math.max(Math.ceil(simulation.value.unitsConsumed * (1 + computeErrorMarginPct)), 200_000);
}
