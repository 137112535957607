import { FC } from 'react';
import Decimal from 'decimal.js';
import { Text } from '../../../../../shared/uiKitV2/Typography';

import s from '../tooltip.module.less';
import { formatNumber } from '../../../../../shared/utils';
import { CapacityLimitIcon } from '../../../../../pages/lendingV2/BorrowLendList/components/CapacityLimitIcon';
import { Divider } from '../../../../../shared/uiKitV2/Divider';
import useTokens from '../../../../../shared/hooks/useTokens';
import { PublicKeyAddress } from '../../../../../shared/types/strategies';

const { formatTokenAllDecimals: ftad, formatPercent: fp } = formatNumber;

interface PriceDivergenceTooltipProps {
  priceDivergenceBps: Decimal;
  oraclePrice: Decimal;
  marketPrice: Decimal;
  priceTokenMint: PublicKeyAddress;
}

export const PriceDivergenceTooltip: FC<PriceDivergenceTooltipProps> = ({
  priceDivergenceBps,
  oraclePrice,
  marketPrice,
  priceTokenMint,
}) => {
  const { getToken } = useTokens();
  const { symbol } = getToken(priceTokenMint);
  return (
    <div className={s.root}>
      <div className={s.item}>
        <div className={s.title}>
          <CapacityLimitIcon />
          <Text>Oracle & Market Prices Differ by {fp(priceDivergenceBps.div(100))}</Text>
        </div>
        <div className={s.row}>
          <Text fs={14} lh={18} color="grey" weight="medium">
            Oracle Price
          </Text>

          <Text fs={14} lh={18} weight="medium">
            {ftad(oraclePrice, 6)} {symbol}
          </Text>
        </div>

        <div className={s.row}>
          <Text fs={14} lh={18} color="grey" weight="medium">
            Market Price
          </Text>

          <Text fs={14} lh={18} weight="medium">
            {ftad(marketPrice, 6)} {symbol}
          </Text>
        </div>
      </div>

      <Divider className={s.divider} />

      <div className={s.item}>
        <Text fs={14} lh={18} color="grey" weight="regular">
          This can lead to price impact on your transaction. Please proceed with caution.
        </Text>
      </div>
    </div>
  );
};
