export const minBalances = {
  lending: {
    deposit: 0.05,
    withdraw: 0.01,
    borrow: 0.05,
    repay: 0.01,
  },
  liquidity: {
    deposit: 0.05,
    withdraw: 0.01,
  },
  leverage: {
    deposit: 0.05,
    withdraw: 0.05,
    adjust: 0.05,
  },
  staking: {
    stake: 0.05,
    unstake: 0.01,
  },
  earn: {
    deposit: 0.01,
    withdraw: 0.01,
  },
};
