import { useQueries } from '@tanstack/react-query';

import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchUserFeesAndRewardsEarnings } from '../../../services/strategies';
import { notify } from '../../../utils/notifications/notifications';
import { UseUserFeesAndRewardsProps } from '../../../types/userFeesAndRewards';
import { UserFeesAndRewardsEarnings } from '../../../../models/api/UserFeesAndRewardsEarnings';
import { notEmpty } from '../../../utils';
import { captureError } from '../../../utils/captureError';
import useEnv from '../../useEnv';

const EMPTY_DATA: UserFeesAndRewardsEarnings[] = [];
const EMPTY_RESPONSE: UserFeesAndRewardsEarnings = {
  feesAEarned: new Decimal('0'),
  feesBEarned: new Decimal('0'),
  feesAEarnedUsd: new Decimal('0'),
  feesBEarnedUsd: new Decimal('0'),
  rewards0Earned: new Decimal('0'),
  rewards1Earned: new Decimal('0'),
  rewards2Earned: new Decimal('0'),
  rewards0EarnedUsd: new Decimal('0'),
  rewards1EarnedUsd: new Decimal('0'),
  rewards2EarnedUsd: new Decimal('0'),
  kaminoRewards0Earned: new Decimal('0'),
  kaminoRewards1Earned: new Decimal('0'),
  kaminoRewards2Earned: new Decimal('0'),
  kaminoRewards0EarnedUsd: new Decimal('0'),
  kaminoRewards1EarnedUsd: new Decimal('0'),
  kaminoRewards2EarnedUsd: new Decimal('0'),
  lastCalculated: new Decimal(Date.now()),
};

export const useUserFeesAndRewardsQuery = ({ strategiesAddresses, walletPublicKey }: UseUserFeesAndRewardsProps) => {
  const { env } = useEnv();
  const queries = strategiesAddresses.map((strategyAddress) => ({
    queryKey: QUERYKEYS.getUserFeesAndRewards(walletPublicKey || '', strategyAddress),
    queryFn: () => {
      if (!walletPublicKey) {
        throw new Error('Wallet is not connected');
      }
      return fetchUserFeesAndRewardsEarnings({
        env,
        walletPublicKey,
        strategyAddress: String(strategyAddress),
      }).catch((err) => {
        captureError(err);

        // because of that onError never will be triggered; TODO: think about different approach to allow errors propagation
        return EMPTY_RESPONSE;
      });
    },
    onError: (err: Error) => {
      captureError(err);
      notify({
        type: 'error',
        message: `Loading error`,
        description: `Failed to load latest user fees and rewards values for strategy ${strategyAddress.toString()}`,
      });
    },
    retry: 0,
    enabled: Boolean(walletPublicKey) && Boolean(strategyAddress),
  }));

  const isEmptyParams = !strategiesAddresses || !strategiesAddresses.length;
  const results = useQueries({ queries });
  // Check if any query is still loading
  const isFetched = isEmptyParams || (results && results.every((result) => result.isFetched));
  const isLoading = results?.length ? results.some((result) => result.isLoading) : !isEmptyParams;

  // Extract data from results
  const data: UserFeesAndRewardsEarnings[] = useMemo(
    () => results?.map((result) => result.data).filter(notEmpty) || EMPTY_DATA,
    [results]
  );

  return {
    data: !isFetched || !data || isEmptyParams ? EMPTY_DATA : data,
    isLoading,
    isFetched,
  };
};
