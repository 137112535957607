import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { lazy, Suspense, useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import { AppLayout } from '../../layouts/AppLayout';
import paths from './paths';
import { RouteI } from '../types/router';
import { LABELS } from '../constants/labels';
import { ScrollToTop } from './ScrollToTop';
import { featureFlags } from '../utils';
import { useParseReferalUrl } from '../hooks/useParseReferalUrl';
import { META_DESCRIPTION } from '../constants/metaDescription';
import { usePriorityFeeStatsQuery } from '../hooks/usePriorityFeeStatsQuery';
import { useTokensInfo } from '../hooks/useTokensInfo';
import { useAccessRestrictions } from '../hooks/useAccessRestrictions';
import useTrackNavigation from '../hooks/useTrackNavigation';

const routes: RouteI[] = [
  {
    path: [paths.TRADINGVIEW],
    Component: lazy(() => import('../../pages/TradingView/index')),
    exact: true,
    title: LABELS.PAGE_TITLES.TRADINGVIEW,
    metaDescription: META_DESCRIPTION.TRADINGVIEW,
    isHidden: false,
  },
  {
    path: paths.REFERRAL,
    Component: lazy(() => import('../../pages/ReferralPage/ReferralPage')),
    exact: true,
    title: LABELS.PAGE_TITLES.LENDING,
    isHidden: !featureFlags.isReferralPageEnabled,
    metaDescription: META_DESCRIPTION.REFERRALS,
  },
  {
    path: paths.LIQUIDITY,
    Component: lazy(() => import('../../pages/LiquidityPageV2/LiquidityPage')),
    exact: true,
    title: LABELS.PAGE_TITLES.DEFAULT,
    metaDescription: META_DESCRIPTION.LIQUIDITY,
  },
  {
    path: [paths.PORTFOLIO, paths.PORTFOLIO_WITH_OWNER],
    Component: lazy(() => import('../../pages/PortfolioV2/Portfolio')),
    exact: true,
    title: LABELS.PAGE_TITLES.PORTFOLIO,
    metaDescription: META_DESCRIPTION.PORTFOLIO,
  },
  {
    path: [paths.ADVANCED_ANALYTICS_FULL, paths.ADVANCED_ANALYTICS_SHORT],
    Component: lazy(() => import('../../pages/AdvancedAnalytic/AdvancedAnalytic')),
    exact: true,
    title: LABELS.PAGE_TITLES.LENDING,
  },
  {
    path: [paths.STRATEGY_FULL, paths.STRATEGY_SHORT],
    Component: lazy(() => import('../../pages/StrategyV2/Strategy')),
    exact: true,
    title: LABELS.PAGE_TITLES.LIQUIDITY,
  },
  {
    path: [paths.STRATEGY_FULL_DEBUG, paths.STRATEGY_SHORT_DEBUG],
    Component: lazy(() => import('../../pages/debug/Debug')),
    exact: true,
    title: `Debug: ${LABELS.PAGE_TITLES.LEVERAGE}`,
  },
  {
    path: [paths.EARN],
    Component: lazy(() => import('../../pages/EarnListV2/EarnListV2')),
    exact: false,
    title: LABELS.DEFAULT_TITLE,
    isHidden: !featureFlags.isEarnEnabled,
  },
  {
    path: [paths.CREATE, paths.EDIT],
    Component: lazy(() => import('../../pages/CreateStrategy/CreateStrategy')),
    exact: false,
    title: LABELS.DEFAULT_TITLE,
  },
  {
    path: [paths.TERMS],
    Component: lazy(() => import('../../pages/terms/Terms')),
    exact: false,
    title: LABELS.DEFAULT_TITLE,
  },
  {
    path: [paths.POINTS],
    Component: lazy(() => import('../../pages/Points/Points')),
    exact: true,
    title: LABELS.PAGE_TITLES.POINTS,
    isHidden: !featureFlags.isPointsPageEnabled,
    metaDescription: META_DESCRIPTION.POINTS,
  },
  {
    path: [paths.GENESIS],
    Component: lazy(() => import('../../pages/TokenAllocation/index')),
    exact: true,
    title: LABELS.PAGE_TITLES.GENESIS,
    metaDescription: META_DESCRIPTION.GENEIS,
  },
  {
    path: [paths.S2AIRDROP],
    Component: lazy(() => import('../../pages/IgnitionAllocation')),
    exact: true,
    title: LABELS.PAGE_TITLES.S2AIRDROP,
    metaDescription: META_DESCRIPTION.S2AIRDROP,
  },
  {
    path: [paths.STAKE],
    Component: lazy(() => import('../../pages/StakePage/StakePage')),
    exact: true,
    title: LABELS.PAGE_TITLES.STAKE,
    isHidden: !featureFlags.isKmnoClaimEnabled,
    metaDescription: META_DESCRIPTION.STAKE,
  },
  {
    path: [paths.GOVERNANACE_AND_STAKING],
    Component: lazy(() => import('../../pages/StakePageNew')),
    exact: true,
    title: LABELS.PAGE_TITLES.GOVERNANCE_AND_STAKING,
    isHidden: !featureFlags.isGovernanceEmptyStateEnabled,
    metaDescription: META_DESCRIPTION.STAKE,
  },
  {
    path: [paths.PROPOSAL],
    Component: lazy(() => import('../../pages/StakePageNew/Proposals/Proposal')),
    exact: true,
    title: LABELS.PAGE_TITLES.GOVERNANCE_AND_STAKING,
  },
];

if (featureFlags.isLimitOrdersPageEnabled) {
  routes.push({
    path: [paths.LIMIT_ORDER],
    Component: lazy(() => import('../../pages/LimitOrderPage/LimitOrderPage')),
    exact: true,
    title: LABELS.PAGE_TITLES.LIMIT_ORDERS,
    metaDescription: META_DESCRIPTION.DEFAULT,
    waitForMarketsToLoad: true,
  });
}

// TODO: combine into single array after v3 release
if (featureFlags.isV3Enabled) {
  routes.push({
    path: [paths.LENDING_V3.BASE],
    Component: lazy(() => import('../../pages/lendingV3/index')),
    exact: false,
    title: LABELS.PAGE_TITLES.LENDING,
    metaDescription: META_DESCRIPTION.DEFAULT,
  });
}

routes.push({
  path: [paths.LENDING.BASE, paths.MAIN],
  Component: lazy(() => import('../../pages/lendingV2/index')),
  exact: false,
  title: LABELS.PAGE_TITLES.LENDING,
  metaDescription: META_DESCRIPTION.DEFAULT,
});

export function Router() {
  return (
    <BrowserRouter>
      <Init />
      <ScrollToTop>
        <Switch>
          {routes
            .filter((r) => !r.isHidden)
            .map(({ path, Component, exact, title, isHidden, metaDescription, isSurgeOptimised }, index) => {
              return (
                <Route
                  path={path}
                  key={`route-${index}`}
                  exact={exact}
                  render={() => {
                    if (isHidden) {
                      return <Redirect to="/404" />;
                    }

                    return (
                      <AppLayout isSurgeOptimised={isSurgeOptimised}>
                        <Suspense fallback={null}>
                          <Helmet>
                            <title>{title}</title>
                            {metaDescription && <meta name="description" content={metaDescription} />}
                          </Helmet>
                          <Component />
                        </Suspense>
                      </AppLayout>
                    );
                  }}
                />
              );
            })}

          <Redirect to="/404" />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

// Extracted route our Router comp body because history value is stale after initial load
const Init = () => {
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { pathname } = useLocation();
  useTrackNavigation();

  // shared hooks over all routes
  useTokensInfo({});
  useAccessRestrictions();
  usePriorityFeeStatsQuery({
    refetchInterval: 10000,
    enabled: true,
  });

  // Track of page views
  useEffect(() => {
    mixpanel.track('page_view', {
      page: pathname,
    });
  }, [mixpanel, pathname]);

  // Parse referal url, and set it to LS
  useParseReferalUrl(history);

  return null;
};
