import axios from 'axios';

import { API_URL } from '../constants/api';
import { captureError } from '../utils/captureError';

type GetUserRiskResponse = {
  hasRisk: boolean;
  lastScreened?: string;
};

export const fetchUserHasRisk = async (walletPublicKey: string): Promise<GetUserRiskResponse | undefined> => {
  try {
    const response = await axios.get<GetUserRiskResponse | undefined>(`${API_URL}/users/${walletPublicKey}/metadata`);

    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err?.response?.status === 404) {
        // Wallet not found in risk system
        return { hasRisk: false };
      }

      captureError(err);
      return { hasRisk: false };
    }

    captureError(err);
  }
};
