import React, { FC } from 'react';
import s from './PositionsAccordion.module.less';
import { Accordion, Panel } from '../../../../../../shared/uiKitV2/Accordion';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { Spinner } from '../../../../../../shared/uiKitV2/Spinner';

type PositionsAccordionProps = {
  header: string;
  isLoading: boolean;
};
export const PositionsAccordion: FC<PositionsAccordionProps> = ({ children, header, isLoading }) => {
  if (isLoading) {
    return (
      <div className={s.loadingWrapper}>
        <Text fs={16} lh={22}>
          {header}
        </Text>
        <Spinner />
      </div>
    );
  }
  return (
    <Accordion
      defaultActiveKeys={[header]}
      accordionClassName={s.accordion}
      panelClassName={s.accordionPanel}
      headerClassName={s.accordionHeader}
    >
      <Panel header={header}>{children}</Panel>
    </Accordion>
  );
};
