import { ReactElement, SVGProps } from 'react';

export const SvgLiquidityAnalytics = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.666 13.333H3.333a.667.667 0 0 1-.667-.667V3.333c0-.369.298-.667.667-.667h9.333c.368 0 .667.298.667.667v3.333M10.666 10.666l2 2"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11.333a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667Z"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
