import React, { FC, memo, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import s from './TableCells.module.less';

import { TokensWithText } from '../../../../shared/uiKitV2/TokensWithText';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { TokenSymbol } from '../../../../shared/uiKitV2/TokenIcon/types';
import { AmountWithTokenMint, PublicKeyAddress } from '../../../../shared/types/strategies';
import useTokens from '../../../../shared/hooks/useTokens';
import { sortTokensMintsByQuoteToken } from '../../../../shared/utils/kamino';
import { TextProps } from '../../../../shared/uiKitV2/Typography/Typography';
import { TooltipTrigger } from '../../../../shared/uiKitV2/Tooltip';
import { LTV_TOOLTIP_ID, NET_VALUE_TOOLTIP_ID } from '../../constants/tooltips';
import { LendingLeveragePositionTableData } from '../../types';
import { getLtvColor } from '../../../../shared/utils/getLtvColor';
import { formatNumber } from '../../../../shared/utils';
import { DEFAULT_TEXT_PROPS } from '../../constants/common';
import { Tag, TagProps } from '../../../../shared/uiKitV2/Tag';
import { Provider } from '../../../../shared/constants/kamino';
import { Spinner } from '../../../../shared/uiKitV2/Spinner';
import { PnlValue } from '../../../../shared/components/v2/PnlValue';
import { useObligationPnlQuery } from '../../../../shared/hooks/lending/queries/useObligationPnlQuery';
import { useObligationInterestFeesQuery } from '../../../../shared/hooks/lending/queries/useObligationInterestFeesQuery';
import { TokensProps } from '../../../../shared/uiKitV2/Tokens';

const { formatPercent: fp, formatShortName: fsn, formatUsd: fusd } = formatNumber;

interface VaultNameProps {
  tokenAMint: PublicKeyAddress;
  tokenBMint: PublicKeyAddress;
  isDiyVault?: boolean;
  textProps?: TextProps;
  tokensProps?: Omit<TokensProps, 'tokensAddresses'>;
  tagSize?: TagProps['size'];
}

export const VaultName: FC<VaultNameProps> = memo(
  ({ tokenAMint, tokenBMint, isDiyVault, textProps, tagSize = 'large', tokensProps = {} }) => {
    const { getToken } = useTokens();

    const tokensMints = sortTokensMintsByQuoteToken([tokenAMint, tokenBMint]);

    const tokens = useMemo(() => {
      const sorted = sortTokensMintsByQuoteToken([tokenAMint, tokenBMint]);
      return [getToken(sorted[0]).symbol, getToken(sorted[1]).symbol];
    }, [getToken, tokenAMint, tokenBMint]);

    return (
      <TokensWithText
        tokensProps={{ tokensAddresses: tokensMints, size: 27, ...tokensProps }}
        textProps={textProps || { fs: 20, lh: 26, tabletFs: 24, tabletLh: 32, desktopFs: 14, desktopLh: 18 }}
        text={
          !isDiyVault ? (
            tokens.join('-')
          ) : (
            <div className={s.tokensWithTag}>
              {tokens.join('-')}
              <Tag label="Creator Vault" size={tagSize} />
            </div>
          )
        }
        align="left"
        gap={12}
      />
    );
  }
);

interface DepositedColProps {
  deposited: AmountWithTokenMint[];
  label?: string;
  isReverse?: boolean;
}

export const DepositedCol: FC<DepositedColProps> = memo(({ deposited, label, isReverse }) => {
  return (
    <TextCell label={label}>
      <div className={s.deposited}>
        {deposited.map((deposit, index) => {
          const tokenMint = Object.keys(deposit)[0];
          const depositedAmount = Object.values(deposit)[0];

          return (
            <TokensWithText
              key={index}
              reverse={isReverse}
              tokensProps={{ tokensAddresses: [tokenMint], size: 20 }}
              textProps={{ fs: 20, lh: 26, tabletFs: 24, tabletLh: 32, desktopFs: 14, desktopLh: 20 }}
              text={fsn(depositedAmount)}
              align="left"
              gap={6}
              direction="horizontal"
            />
          );
        })}
      </div>
    </TextCell>
  );
});

interface LtvColProps {
  row: LendingLeveragePositionTableData;
  label?: string;
}

export const LtvCol: FC<LtvColProps> = ({ row, label }) => {
  return (
    <TooltipTrigger id={LTV_TOOLTIP_ID} content={row.address.toString()}>
      <TextCell
        label={label}
        valueTextProps={{ color: getLtvColor(row.ltv, row.maxLtv, row.liquidationLtv) }}
        underline
      >
        {fp(row.ltv * 100)}
      </TextCell>
    </TooltipTrigger>
  );
};

interface NetValueColProps {
  row: LendingLeveragePositionTableData;
  label?: string;
}

export const NetValueCol: FC<NetValueColProps> = ({ row, label }) => {
  return (
    <TooltipTrigger id={NET_VALUE_TOOLTIP_ID} content={row.address.toString()}>
      <TextCell label={label} underline>
        {fusd(row.netValue)}
      </TextCell>
    </TooltipTrigger>
  );
};

interface TextCellProps {
  label?: string;
  underline?: boolean;
  valueTextProps?: Partial<TextProps>;
}

export const TextCell: FC<TextCellProps> = memo(({ label, underline, valueTextProps, children }) => {
  return (
    <div className={s.cell}>
      {label && (
        <Text fs={14} lh={18} color="grey">
          {label}
        </Text>
      )}

      {typeof children === 'string' ? (
        <Text {...DEFAULT_TEXT_PROPS} {...valueTextProps} underline={underline} className={s.child}>
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
});

interface DexProps {
  dex: 'Orca' | 'Raydium';
  label?: string;
  text?: string;
}

export const Dex: FC<DexProps> = memo(({ dex, label, text }) => {
  return (
    <TextCell label={label}>
      <TokensWithText
        providerProps={{
          provider: dex as Provider,
          size: 24,
        }}
        textProps={{ fs: 16, lh: 22, tabletFs: 24, tabletLh: 32, desktopFs: 14, desktopLh: 18 }}
        text={text}
        align="left"
        gap={6}
      />
    </TextCell>
  );
});

interface LendingPositionNameProps {
  tokensMints: PublicKeyAddress[];
  maxTokensDisplayed?: number; // 0 -> all
  textProps?: TextProps;
  tokensDisplayCount?: number;
}

export const LendingPositionName: FC<LendingPositionNameProps> = memo(
  ({ tokensMints, maxTokensDisplayed = 0, textProps, tokensDisplayCount }) => {
    const { getToken } = useTokens();

    const tokens = useMemo(
      () => tokensMints.map((mint) => getToken(mint).symbol as TokenSymbol),
      [getToken, tokensMints]
    );
    const tokensText = useMemo(() => {
      if (maxTokensDisplayed === 0 || tokens.length <= maxTokensDisplayed) {
        return tokens.join(', ');
      }

      return `${tokens.slice(0, maxTokensDisplayed).join(', ')}...`;
    }, [maxTokensDisplayed, tokens]);

    return (
      <>
        {!tokens.length ? (
          <TextCell>-</TextCell>
        ) : (
          <TokensWithText
            tokensProps={{ tokensAddresses: tokensMints, size: 20 }}
            textProps={textProps || { fs: 16, lh: 22, tabletFs: 24, tabletLh: 32, desktopFs: 14, desktopLh: 18 }}
            text={tokensText}
            tokensDisplayCount={tokensDisplayCount}
            align="left"
            gap={12}
          />
        )}
      </>
    );
  }
);

interface MultiplyDebtTokenCellProps {
  tokensMints: PublicKeyAddress[];
  isMobileView: boolean;
  label: string;
}

export const MultiplyDebtTokenCell: FC<MultiplyDebtTokenCellProps> = ({ tokensMints, label, isMobileView }) => {
  const { getToken } = useTokens();

  const tokensInfo = tokensMints.map((mint) => getToken(mint));
  const tokenSymbols = tokensInfo.map((info) => info.symbol);
  const tokensAddresses = tokensMints;
  const textProps: TextProps = {
    fs: 20,
    lh: 26,
    tabletFs: 24,
    tabletLh: 32,
    desktopFs: 14,
    desktopLh: 18,
  };

  if (!isMobileView) {
    return (
      <TokensWithText
        tokensProps={{
          tokensAddresses,
          size: 30,
          reverseZIndex: true,
        }}
        textProps={textProps}
        text={tokenSymbols.join('-')}
        align="left"
        gap={16}
      />
    );
  }

  return (
    <TextCell label={label}>
      <TokensWithText
        tokensProps={{
          tokensAddresses,
          size: 20,
          reverseZIndex: true,
        }}
        textProps={textProps}
        text={tokenSymbols.join('-')}
        align="left"
        gap={6}
      />
    </TextCell>
  );
};

interface PnlCellProps {
  obligationAddress: PublicKeyAddress;
  marketAddress: PublicKeyAddress;
  textProps?: TextProps;
  label?: string;
  dataType: 'usd' | 'sol';
}

export const PnlCell: FC<PnlCellProps> = observer(
  ({ obligationAddress, marketAddress, textProps, label, dataType = 'sol' }) => {
    const { data: pnlData, isLoading } = useObligationPnlQuery({
      obligationAddress: obligationAddress.toString(),
      marketAddress: marketAddress.toString(),
    });

    const data = useMemo(() => {
      if (dataType === 'usd') {
        return { pre: '$', value: pnlData?.usd || 0 };
      }
      return { post: 'SOL', value: pnlData?.sol || 0 };
    }, [dataType, pnlData]);

    return <TextCell label={label}>{isLoading ? <Spinner /> : <PnlValue {...data} textProps={textProps} />}</TextCell>;
  }
);

interface InterestFeesCellProps {
  obligationAddress: PublicKeyAddress;
  marketAddress: PublicKeyAddress;
  textProps?: TextProps;
  label?: string;
}

export const InterestFeesCell: FC<InterestFeesCellProps> = observer(
  ({ obligationAddress, marketAddress, textProps, label }) => {
    const { data: interestFees, isLoading } = useObligationInterestFeesQuery({
      obligationAddress,
      marketAddress,
    });

    const data = {
      pre: '$',
      value: interestFees?.totalFeesEarnedObligation[obligationAddress.toString()].usdFees || 0,
    };

    return <TextCell label={label}>{isLoading ? <Spinner /> : <PnlValue {...data} textProps={textProps} />}</TextCell>;
  }
);
