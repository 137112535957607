import { ReactElement, SVGProps } from 'react';

export const SvgPhantom = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.122 18.122c1.966 0 3.442-1.71 4.324-3.06a2.635 2.635 0 0 0-.167.885c0 .788.453 1.35 1.346 1.35 1.227 0 2.537-1.076 3.216-2.235a1.707 1.707 0 0 0-.072.466c0 .55.31.897.941.897 1.99 0 3.99-3.526 3.99-6.61 0-2.402-1.215-4.517-4.264-4.517-5.36 0-11.136 6.55-11.136 10.78 0 1.661.893 2.044 1.822 2.044Zm7.468-8.57c0-.597.334-1.015.822-1.015.476 0 .81.418.81 1.015 0 .598-.334 1.028-.81 1.028-.488 0-.822-.43-.822-1.028Zm2.549 0c0-.597.333-1.015.822-1.015.476 0 .81.418.81 1.015 0 .598-.334 1.028-.81 1.028-.489 0-.822-.43-.822-1.028Z"
      fill="#fff"
    />
  </svg>
);
