import { useQuery } from '@tanstack/react-query';
import { KaminoPrices } from '@kamino-finance/kliquidity-sdk';
import { QUERYKEYS } from '../../constants/queryKeys';

import { useKaminoSdk } from '../useKaminoSdk';
import { useMarketsQuery } from '../lending/queries/useMarketsQuery';
import { notEmpty } from '../../utils';

const EMPTY_OBJ: KaminoPrices = { spot: {}, twap: {} };

export const useRawPricesQuery = () => {
  const { kaminoSdk } = useKaminoSdk();
  const { data: marketsData, isFetched: isMarketsFetched } = useMarketsQuery();

  const queryFn = async () => {
    const lendingPricesPromises = marketsData?.filter(notEmpty).map((market) => market.getAllScopePrices()) || [];

    const [allPrices, marketPrices] = await Promise.all([kaminoSdk?.getAllPrices(), ...lendingPricesPromises]);

    if (!allPrices || !allPrices.spot || !marketPrices || !marketPrices.spot) {
      return EMPTY_OBJ;
    }

    const pricesObj: KaminoPrices = {
      spot: { ...allPrices.spot, ...marketPrices.spot },
      twap: { ...allPrices.twap, ...marketPrices.twap },
    };

    return pricesObj;
  };

  return useQuery(QUERYKEYS.rawPrices, queryFn, {
    enabled: Boolean(kaminoSdk && isMarketsFetched),
    refetchInterval: 1 * 60 * 1000, // 1 min
    staleTime: 2 * 60 * 1000, // 2 mins
  });
};
