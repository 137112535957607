import { ReactElement, SVGProps } from 'react';

export const SvgCheckAllBig = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m5.25 9 3.712 3.712 7.955-7.955M1.538 9.038 5.25 12.75m3.978-3.977 3.977-3.978"
      stroke="#30D69A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
