import { CSSProperties, FC } from 'react';
import s from './KTokensWithText.module.less';
import { TokensWithText, TokensWithTextProps } from '../../shared/uiKitV2/TokensWithText';
import { ProviderIcon } from '../../shared/uiKitV2/ProviderIcon';
import { Provider } from '../../shared/constants/kamino';
import { getDexFromRaw, getProviderFromDex } from '../../shared/utils';
import { useKaminoResourcesStrategiesQueryCleanedUp } from '../../shared/queriesCleanedUp/useStrategiesQueryCleanedUp';

export const KTokensWithText: FC<TokensWithTextProps> = (props) => {
  const { providerProps, tokensProps, ...rest } = props;
  const { strategiesByAddress } = useKaminoResourcesStrategiesQueryCleanedUp();

  const tokenMint = tokensProps?.tokensAddresses[0];
  if (!tokenMint) {
    return null;
  }
  const tokenIconSize = tokensProps?.size || 16;
  const marginsStyles: CSSProperties = tokenIconSize
    ? {
        marginTop: tokenIconSize / 2,
        marginLeft: tokenIconSize / 2,
      }
    : {};

  let provider: Provider = 'orca';
  const strategy = strategiesByAddress?.[tokenMint.toString()];
  if (strategy) {
    const dex = getDexFromRaw(strategy.strategyDex.toString());
    provider = getProviderFromDex(dex);
  }

  return (
    <TokensWithText providerProps={providerProps} tokensProps={tokensProps} {...rest}>
      <div className={s.root} style={marginsStyles}>
        {strategy && <ProviderIcon provider={provider} size={tokenIconSize / 2} />}
      </div>
    </TokensWithText>
  );
};
