import { useQuery } from '@tanstack/react-query';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { useWeb3Client } from '../../useWeb3Client';
import { useReserves } from '../useReserves';
import { useMarkets } from '../useMarkets';
import { PYUSD_MINT } from '../../../constants/mints';
import useReserveFarmsQuery from './userReserveFarmsQuery';
import { calcTotalRewardsApy, isValidPubkey, notEmpty } from '../../../utils';
import useReserveFarms from '../useReserveFarms';

const EMPTY_VALUE = 0;

export const usePyusdFarmApyQuery = () => {
  const { web3client } = useWeb3Client();
  const { markets } = useMarkets();
  const { address: mainMarketAddress } = markets[0];
  const { getReserveByTokenMint } = useReserves();
  const { getFarmByAddress } = useReserveFarms();
  const pyusdReserve = getReserveByTokenMint(mainMarketAddress, PYUSD_MINT);
  const pyusdReserveFarms = [pyusdReserve?.state.farmCollateral, pyusdReserve?.state.farmDebt]
    .filter(notEmpty)
    .filter(isValidPubkey);
  const { isFetched: isReservesFarmsFetched } = useReserveFarmsQuery({
    marketAddress: mainMarketAddress,
    farmsMints: pyusdReserveFarms,
  });

  const queryFn = () => {
    if (!pyusdReserve || !isValidPubkey(pyusdReserve?.state.farmCollateral)) {
      return 0;
    }
    const collateralFarm = getFarmByAddress(pyusdReserve.state.farmCollateral);
    if (!collateralFarm) {
      return 0;
    }
    return calcTotalRewardsApy(collateralFarm);
  };

  const isEnabled = Boolean(web3client) && pyusdReserve && (pyusdReserveFarms.length === 0 || isReservesFarmsFetched);
  const { data, ...rest } = useQuery({
    queryFn,
    queryKey: QUERYKEYS.getPyusdReserveApy,
    enabled: isEnabled,
  });

  return { data: data || EMPTY_VALUE, ...rest };
};
