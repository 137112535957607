import { PublicKey } from '@solana/web3.js';

import { useQuery } from '@tanstack/react-query';
import { StrategyWithAddress } from '@kamino-finance/kliquidity-sdk';
import { useKaminoSdk } from '../../useKaminoSdk';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { notify } from '../../../utils/notifications/notifications';
import { captureError } from '../../../utils/captureError';

interface UseAllCreatedVaultsProps {
  isDisabled?: boolean;
  walletPublicKey: string | null;
}

const EMPTY_ARRAY: StrategyWithAddress[] = [];

export const useUserCreatedVaultsQuery = (props: UseAllCreatedVaultsProps) => {
  const { isDisabled, walletPublicKey } = props;
  const { kaminoSdk } = useKaminoSdk();

  const { data, ...rest } = useQuery<StrategyWithAddress[]>({
    queryKey: QUERYKEYS.getUserDIYStrategies(walletPublicKey),
    queryFn: async () => {
      if (!kaminoSdk) {
        throw new Error('wallet is not connected');
      }

      return kaminoSdk.getAllStrategiesWithFilters({
        owner: new PublicKey(walletPublicKey || ''),
      });
    },
    enabled: Boolean(kaminoSdk) && Boolean(walletPublicKey) && !isDisabled,
    onError: (err) => {
      captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load DIY Vaults',
      });
    },
  });

  return {
    data: data || EMPTY_ARRAY,
    ...rest,
  };
};
