'use client';

import { DialectSolanaSdk, DialectSolanaWalletAdapter } from '@dialectlabs/react-sdk-blockchain-solana';
import { Icons, NotificationsButton } from '@kamino-finance/react-ui';
import '@dialectlabs/react-ui/index.css';
import { useWallet } from '@solana/wallet-adapter-react';
import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { Icon } from '../../../../shared/uiKitV2/Icon';
import { SvgArrowLeft, SvgClose } from '../../../../shared/uiKitV2/Icons';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import { Text } from '../../../../shared/uiKitV2/Typography';

import { NotificationListItem } from './NotificationListItem';
import s from './NotificationsWidget.module.less';
import './core.less';

const DAPP_ADDRESS = 'kntffHNH3edAUo2HP6ikKhPzWCjKrGBcpiMpCmNLrEg';
const LAST_READ_TIMESTAMP_LOCAL_STORAGE_KEY = 'NOTIFICATIONS_LAST_READ_TIMESTAMP';

Icons.Close = () => {
  return null;
};

Icons.ArrowLeft = () => {
  return <IconButton variant="secondary" size="large" icon={<SvgArrowLeft />} />;
};

export const NotificationsWidget = () => {
  const [isOpen, setIsOpen] = useState<boolean>();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const path = event.composedPath && event.composedPath();
      if (path) {
        for (const element of path) {
          if (element.classList && element.classList.contains('dt-modal')) {
            return;
          }
        }
      }

      if (isOpen) {
        window.localStorage.setItem(LAST_READ_TIMESTAMP_LOCAL_STORAGE_KEY, Date.now().toString());
        setLastReadTimestamp(Date.now().toString());
        setIsOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);
  const wallet = useWallet();
  const [lastReadTimestamp, setLastReadTimestamp] = useState(
    window.localStorage.getItem(LAST_READ_TIMESTAMP_LOCAL_STORAGE_KEY)
  );

  const walletAdapter: DialectSolanaWalletAdapter = useMemo(
    () => ({
      publicKey: wallet.publicKey,
      signMessage: wallet.signMessage,
      signTransaction: wallet.signTransaction,
    }),
    [wallet]
  );

  if (wallet.publicKey && (!wallet.signMessage || !wallet.signTransaction)) {
    return <></>;
  }

  return (
    <DialectSolanaSdk dappAddress={DAPP_ADDRESS} customWalletAdapter={walletAdapter}>
      <NotificationsButton
        theme="dark"
        renderMessage={(message) => {
          return (
            <NotificationListItem
              isUnread={!lastReadTimestamp ? true : message.timestamp.getTime() > +lastReadTimestamp}
              actionLabel={message.metadata?.actions?.[0]?.label}
              actionUrl={message.metadata?.actions?.[0]?.url}
              notificationTypeHumanReadableId={message.metadata?.notificationTypeHumanReadableId}
              title={message.metadata?.title}
              text={message.text}
              timestamp={message.timestamp}
            />
          );
        }}
        renderModalComponent={({ open, setOpen, ref, children }) => {
          if (!open) {
            return null;
          }
          return (
            <div className={s.modalOverlay}>
              <div ref={ref} className="dt-modal">
                {children}
                <IconButton
                  className={s.closeIconMobile}
                  onClick={() => setOpen((prev) => !prev)}
                  variant="secondary"
                  icon={<SvgClose />}
                  shape="circle"
                  size="large"
                />
              </div>
            </div>
          );
        }}
      >
        {({ open, setOpen, unreadCount, ref }) => {
          const handleClick = () => {
            setOpen((prev) => {
              const newIsOpen = !prev;
              setIsOpen(newIsOpen);
              if (!newIsOpen) {
                window.localStorage.setItem(LAST_READ_TIMESTAMP_LOCAL_STORAGE_KEY, Date.now().toString());
                setLastReadTimestamp(Date.now().toString());
              }
              return newIsOpen;
            });
          };

          const TheIconButton = (
            <IconButton
              ref={ref}
              className={cx(s.iconButton, open && s.iconButtonClicked)}
              onClick={handleClick}
              variant="secondary"
              size="large"
              icon={<Icon name="Bell" color={open ? 'blue' : undefined} />}
            />
          );

          return unreadCount ? (
            <div className={s.iconButtonIconContainer}>
              <div className={cx(s.notificationBubble, unreadCount > 10 && s.notificationBubble10plus)}>
                <Text fs={unreadCount > 10 ? 10 : 12} lh={10}>
                  {unreadCount > 10 ? '10+' : unreadCount}
                </Text>
              </div>
              {TheIconButton}
            </div>
          ) : (
            TheIconButton
          );
        }}
      </NotificationsButton>
    </DialectSolanaSdk>
  );
};
