import { JLP_MARKET_JLP_RESERVE_ADDRESS } from '../../../constants/borrowLend/reserve';

export const isMultiplyVaultPage = (pathname: string) => {
  return pathname.includes('lending/multiply');
};

/**
 * Not very robust way to check, will not work for vaults that borrows JLP ( we have none of them right now )
 * @param pathname
 */
export const isJlpMultiplyVaultPage = (pathname: string) => {
  const isMultiplyPage = isMultiplyVaultPage(pathname);
  return isMultiplyPage && pathname.includes(JLP_MARKET_JLP_RESERVE_ADDRESS);
};
