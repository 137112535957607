import { withStyles } from '@bruitt/classnames';
import { FC } from 'react';

import s from './Tag.module.less';

const sx = withStyles(s);

export interface TagProps {
  label: string;
  size?: 'small' | 'middle' | 'large' | 'extraLarge';
  variant?:
    | 'default'
    | 'yellow'
    | 'blue'
    | 'orange'
    | 'red'
    | 'green'
    | 'pink'
    | 'purple'
    | 'darkBlue'
    | 'grey'
    | 'blueWhite';
  className?: string;
}

export const Tag: FC<TagProps> = ({ label, size = 'large', variant = 'default', className }) => {
  return <div className={sx('root', { size, variant, className })}>{label}</div>;
};
