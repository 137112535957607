import { withStyles } from '@bruitt/classnames';
import React, { ButtonHTMLAttributes } from 'react';

import s from './BaseButton.module.less';

const sx = withStyles(s);

export interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'ghost' | 'none' | 'greenGradient' | 'warning' | 'danger' | 'green' | 'purple';
  isActive?: boolean;
}

export const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const { className, disabled, isActive, variant = 'primary', type = 'button', children, ...rest } = props;

  return (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={sx('root', className, {
        variant,
        disabled,
        isActive,
      })}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
});
