import { ReactElement, SVGProps } from 'react';

export const SvgRectanglesBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.535 4.93 12.707 2.1a1 1 0 0 0-1.414 0L8.464 4.93a1 1 0 0 0 0 1.414l2.829 2.829a1 1 0 0 0 1.414 0l2.828-2.829a1 1 0 0 0 0-1.414ZM9.172 11.293 6.343 8.465a1 1 0 0 0-1.414 0L2.1 11.293a1 1 0 0 0 0 1.414l2.828 2.829a1 1 0 0 0 1.414 0l2.829-2.829a1 1 0 0 0 0-1.414ZM21.9 11.293l-2.83-2.828a1 1 0 0 0-1.413 0l-2.829 2.828a1 1 0 0 0 0 1.414l2.829 2.829a1 1 0 0 0 1.414 0l2.828-2.829a1 1 0 0 0 0-1.414ZM15.535 17.657l-2.828-2.829a1 1 0 0 0-1.414 0l-2.829 2.829a1 1 0 0 0 0 1.414l2.829 2.828a1 1 0 0 0 1.414 0l2.828-2.828a1 1 0 0 0 0-1.415Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
