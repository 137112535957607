import { FC, memo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { PnlTooltipBody } from '../Tooltips/PnlTooltipBody';
import { Tooltip } from '../../../uiKitV2/Tooltip';
import { StrategyPositionTableData } from '../../../../pages/PortfolioV2/types';
import { useCacheLiquidityData } from '../../../../pages/PortfolioV2/hooks/useCacheLiquidityData';

interface PositionPnlTooltipProps {
  data: StrategyPositionTableData[];
}

export const PnlTooltip: FC<PositionPnlTooltipProps> = memo(({ data }) => {
  const { getPnLFromCache } = useCacheLiquidityData();
  return (
    <Tooltip
      uncontrolled
      clickable
      id="position-pnl-tooltip"
      render={({ content }) => {
        const position = content ? data.find((i) => i.strategy.equals(new PublicKey(content))) : undefined;
        const totalPnl = content ? getPnLFromCache(content) : undefined;

        if (position && totalPnl) {
          return <PnlTooltipBody tokenAMint={position.tokenAMint} tokenBMint={position.tokenBMint} data={totalPnl} />;
        }

        return null;
      }}
    />
  );
});
