import { ReactElement, SVGProps } from 'react';

export const SvgPyth = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={10} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#pyth_svg__a)" fill="#E6DAFE">
      <path d="M6.333 4.8a1.2 1.2 0 0 1-1.2 1.2v1.2a2.4 2.4 0 1 0-2.4-2.4v6l1.08 1.079.12.121V4.8a1.2 1.2 0 0 1 2.4 0Z" />
      <path d="M5.133 0a4.799 4.799 0 0 0-4.8 4.8v3.6l1.2 1.2V4.8a3.592 3.592 0 0 1 2.4-3.394 3.6 3.6 0 1 1 1.2 6.994v1.2a4.8 4.8 0 1 0 0-9.6Z" />
    </g>
    <defs>
      <clipPath id="pyth_svg__a">
        <path fill="#fff" transform="translate(.333)" d="M0 0h9.6v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
