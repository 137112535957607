import { makeAutoObservable } from 'mobx';
// TODO: deepmerge might not be needed
import deepmerge from 'deepmerge';
import { AppSchema } from '../types/appSchema';
import { GetObligationStatsData } from '..';
import { ObligationPnlResponse } from '../../shared/types/lending';

export interface WorkerStoreParams extends AppSchema {
  debugData: string;
  leverageStats: Record<string, GetObligationStatsData>;
  leveragePnLs: Record<string, ObligationPnlResponse>;
}

export interface WorkerStore extends WorkerStoreParams {
  updateProviderParams: (params: Partial<WorkerStore>, updateStrategy: UpdateStrategy) => void;
  resetProviderParams: () => void;
}

export enum UpdateStrategy {
  merge = 'Merge',
  deepMerge = 'DeepMerge',
}

export const getWorkerStoreDefaultValue = (): WorkerStoreParams => ({
  debugData: 'default debug data',
  markets: {},
  prices: {},
  stakingYields: {},
  currentSlot: 0,
  tokenInfos: [],
  tokenInfoMap: {},
  longVaults: [],
  shortVaults: [],
  maxLtvMap: {},
  leverageStats: {},
  leveragePnLs: {},
});

const store: WorkerStore = makeAutoObservable<WorkerStore>({
  ...getWorkerStoreDefaultValue(),

  // arrow function and 'store' object are used to prevent missing "this" context
  updateProviderParams: (params: Partial<WorkerStoreParams>, updateStrategy: UpdateStrategy) => {
    // Object.assign(store, params);
    Object.assign(store, updateStrategy === UpdateStrategy.deepMerge ? deepmerge.all([store, params]) : params);
  },

  resetProviderParams: () => {
    Object.assign(store, { ...getWorkerStoreDefaultValue() });
  },
});

export { store as workerStore };
