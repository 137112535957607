// treat SOl and wSOL as the same 'token'
import { PublicKey } from '@solana/web3.js';
import { buildStrategySafetyContent } from '../kamino/content';
import { PublicKeyAddress } from '../../types/strategies';
import { MULTIPLY_MINTS, SOL_MINTS, Token } from '../../constants/tokens';
import { Provider, StrategyVaultType } from '../../constants/kamino';
import { KaminoResources, KaminoResourcesTokens } from '../../types/resources';

export const isSOLMint = (mint?: PublicKey | string): boolean => {
  if (!mint) {
    return false;
  }
  const _mint = new PublicKey(mint).toString();
  return SOL_MINTS.filter((m) => m === _mint).length > 0;
};

export const isWsolMint = (mint: PublicKeyAddress): boolean => {
  return new PublicKey(mint).toString() === SOL_MINTS[1].toString();
};

export const isNativeSolMint = (mint: PublicKeyAddress): boolean => {
  return new PublicKey(mint).toString() === SOL_MINTS[0].toString();
};

export const isNativeSolMintStr = (mint: string): boolean => {
  return mint === SOL_MINTS[0];
};

export const isMultiplyLstToken = (mint: PublicKeyAddress): boolean => {
  return MULTIPLY_MINTS.includes(mint.toString());
};

export const getStrategyContent = (strategy: PublicKeyAddress, resources: KaminoResources) => {
  const currentStrategyData = resources?.strategiesData[strategy.toString()];

  if (currentStrategyData) {
    return {
      strategyDescription: currentStrategyData.strategy,
      meta: currentStrategyData.meta as { title: string; description: string; provider: Provider; url: string },
    };
  }

  return {
    strategyDescription: null,
    meta: {
      title: '',
      description: '',
      provider: null,
      url: '',
    },
  };
};

export const getStrategyRisksContent = (
  strategy: PublicKey | string,
  vaultType: StrategyVaultType | null,
  tokens: Token[],
  dex: string
) => {
  const dynamicSafetyContent = buildStrategySafetyContent(vaultType, tokens, dex);
  const isStrategyHasRisksData = dynamicSafetyContent.length > 0;

  return {
    risks: dynamicSafetyContent,
    hasRisks: isStrategyHasRisksData,
  };
};

export const getTokensDetails = (tokens: Token[], resources: KaminoResourcesTokens) => {
  const sortedStrategyData = Object.entries(resources).filter((entries) => {
    return tokens.includes(entries[0] as Token);
  });

  return sortedStrategyData.length ? sortedStrategyData.map((item) => item[1]) : null;
};
