import { useQuery } from '@tanstack/react-query';

import { QUERYKEYS } from '../constants/queryKeys';
import { fetchPriorityFeeStats } from '../services/common';
import { captureError } from '../utils/captureError';
import { numberToLamportsDecimal } from '../utils';
import { PriorityFeeStats } from '../types/priorityFeeStats';
import { MAX_FEE } from '../constants/slippage';

interface UsePriorityFeeStatsQuery {
  refetchInterval: number;
  enabled?: boolean;
}

export const usePriorityFeeStatsQuery = ({ refetchInterval, enabled }: UsePriorityFeeStatsQuery) =>
  useQuery({
    refetchInterval,
    queryKey: QUERYKEYS.priorityFeeStats,
    queryFn: async () => {
      try {
        const res = await fetchPriorityFeeStats();
        return res['15'] || res[Object.keys(res)[0]];
      } catch (e) {
        return {
          min: 0,
          max: numberToLamportsDecimal(MAX_FEE, 9).toNumber(),
          avg: numberToLamportsDecimal(0.00005, 9).toNumber(),
        } as PriorityFeeStats;
      }
    },
    enabled,
    onError: (err) => {
      captureError(err);
      //
      // notify({
      //   type: 'error',
      //   message: 'Failed to load fee stats',
      // });
    },
  });
