import { CSSProperties, FC, Fragment, memo, useMemo } from 'react';
import { withStyles } from '@bruitt/classnames';

import { TokenIcon } from '../TokenIcon';

import s from './Tokens.module.less';
import { PublicKeyAddress } from '../../types/strategies';

const sx = withStyles(s);
const OFFSET_COUNT = 2.5;

export interface TokensProps {
  tokensAddresses: PublicKeyAddress[];
  size: number;
  displayCount?: number;
  reverseZIndex?: boolean;
}

const EMPTY_TOKENS: PublicKeyAddress[] = [];

const Tokens: FC<TokensProps> = memo(({ tokensAddresses = EMPTY_TOKENS, size, displayCount, reverseZIndex }) => {
  const displayCountResolved = displayCount ?? tokensAddresses.length;
  const displayedTokens = useMemo(
    () => tokensAddresses.slice(0, displayCountResolved),
    [tokensAddresses, displayCountResolved]
  );

  const showMoreCount = tokensAddresses.length - displayedTokens.length;

  const tokenStyles = useMemo(
    () =>
      displayedTokens.map((_, index) => {
        const offset = (size / OFFSET_COUNT) * index;
        const style: CSSProperties = {
          transform: `translateX(-${offset}px)`,
          marginRight: index === displayedTokens.length - 1 && showMoreCount <= 0 ? -offset : 0,
          zIndex: reverseZIndex ? displayedTokens.length - index : index,
        };
        return style;
      }),
    [displayedTokens, size, showMoreCount, reverseZIndex]
  );

  return (
    <div className={sx('root')}>
      {displayedTokens.map((tokenAddress, index) => {
        return (
          <Fragment key={index}>
            <div className={sx('tokenItem')} style={tokenStyles[index]}>
              <TokenIcon tokenMint={tokenAddress} size={size} />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
});

export { Tokens };
