import { useQuery } from '@tanstack/react-query';
import { QUERYKEYS } from '../../../constants/queryKeys';

const EMPTY_VALUE = 0;

type EthenaPoolApyResponse = {
  protocolYield: { lastUpdated: string; value: number };
  stakingYield: { lastUpdated: string; value: number };
};
export const useSUsdEPoolApyQuery = () => {
  const { data, ...rest } = useQuery(QUERYKEYS.getSUsdEPoolApy, async () => {
    const response = await fetch(`https://ethena.fi/api/yields/protocol-and-staking-yield`);
    const apyData: EthenaPoolApyResponse = await response.json();
    return apyData.stakingYield.value / 100;
  });

  return { data: data || EMPTY_VALUE, ...rest };
};
