import { withStyles } from '@bruitt/classnames';
import { FC, ReactNode } from 'react';
import { Text } from '../../../uiKitV2/Typography';

import s from './MenuDropdownItem.module.less';
import { Tag } from '../../../uiKitV2/Tag';

const sx = withStyles(s);

interface DropdownItemProps {
  title: string | ReactNode;
  description?: string;
  startIcon?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const MenuDropdownItem: FC<DropdownItemProps> = ({
  title,
  description,
  startIcon,
  isActive = false,
  isDisabled = false,
  onClick,
}) => {
  const content = (
    <>
      <div>{startIcon}</div>
      <div className={s.textWrapper}>
        <div className={s.titleWrapper}>
          <Text className={sx('title')} fs={16} lh={22} weight={description ? 'medium' : 'regular'}>
            {title}
          </Text>
          {isDisabled && <Tag variant="blue" size="large" label="Coming Soon" />}
        </div>

        {description && (
          <Text className={sx('description')} fs={14} lh={18} weight="regular">
            {description}
          </Text>
        )}
      </div>
    </>
  );

  return (
    <div className={sx('root', { withDescription: Boolean(description), isActive, isDisabled })} onClick={onClick}>
      {content}
    </div>
  );
};
