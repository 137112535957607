import React, { FC, useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { scaleDownWads, UserState } from '@hubbleprotocol/farms-sdk';
import { useMixpanel } from 'react-mixpanel-browser';
import { ConnectButton } from '../../../../shared/components/v2/ConnectButton';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import useEnv from '../../../../shared/hooks/useEnv';
import s from './MainMode.module.less';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { Button } from '../../../../shared/uiKitV2/Button';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { featureFlags, formatNumber, lamportsToNumberDecimal } from '../../../../shared/utils';
import icon from '../../../PointsButton/assests/star.svg';
import { useStore as usePointsStore } from '../../../../pages/Points/hooks/useStore';
import { K_FARM_MINT } from '../../../../pages/StakePage/constants/constants';
import { WalletItem } from '../WalletItem/WalletItem';
import paths from '../../../../shared/routes/paths';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';
import { BalancesList } from './components/BalancesList/BalancesList';
import { useUserFarmPositionQuery } from '../../../../shared/hooks/kamino/queries/useUserFarmPositionQuery';
import { MIXPANEL_PREFIX } from '../../../../shared/constants/mixpanel';
import { MainModeTab, MainModeTabs, MainModeTabType } from './constants/mainModeTabs';
import { Tabs } from '../../../../shared/uiKitV2/Tabs';
import { PositionsList } from './components/PositionsList/PositionsList';
import { useTokensWithPrices } from '../../hooks/useTokensWithPrices';
import { ActivitiesList } from './components/ActivitiesList/ActivitiesList';
import { PortfolioValue } from './components/PortfolioValue/PortfolioValue';
import { BalancesTabs, BalanceTab, BalanceTabType } from './constants/balanceTabs';
import { TotalValue } from './components/TotalValue/TotalValue';
// TODO: Move useGetVoterWeight to src/shared/hooks
import { useGetVoterWeight } from '../../../../pages/StakePageNew/components/VotingCard/hooks/useVoterWeight';

const { formatTokenAllDecimals: ftad } = formatNumber;
const kmnoFarmAddress = new PublicKey(K_FARM_MINT);

type MainType = {
  onSwapClick: () => void;
  onSettingsClick: () => void;
};
export const MainMode: FC<MainType> = observer(({ onSwapClick, onSettingsClick }) => {
  const { walletPublicKey, disconnectWallet } = useEnv();
  const mixpanel = useMixpanel();
  const { tokens, isLoading, isFetched } = useTokensWithPrices({ isSolWsolSeparated: true });
  const { totalPointsRealTimeS3 } = usePointsStore();
  const [balancesActiveTab, setBalancesActiveTab] = useState<BalanceTabType>(BalanceTab.balance);
  const [activeTab, setActiveTab] = useState<MainModeTabType>(MainModeTab.balances);
  const { data: userFarmPosition } = useUserFarmPositionQuery({ farmAddress: kmnoFarmAddress });

  const farm: UserState | undefined = Object.values(userFarmPosition)[0];
  const farmStakedAmount = farm?.activeStakeScaled
    ? lamportsToNumberDecimal(scaleDownWads(farm?.activeStakeScaled), 6).toNumber()
    : 0;

  const { updateProviderParams } = layoutProviderStore;
  const { data: voterWeightData } = useGetVoterWeight();

  const handleLinkClick = () => {
    updateProviderParams({
      isSidePanelOpened: false,
    });
  };

  const handleDisconnectClick = () => {
    disconnectWallet();
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:wallet:disconnect`);
    updateProviderParams({
      isSidePanelOpened: false,
    });
  };

  const balancesContent = {
    [BalanceTab.balance]: <TotalValue isBalanceLoading={!isFetched} tokens={tokens} />,
    [BalanceTab.netValue]: <PortfolioValue />,
  };

  const tabsContent = {
    [MainModeTab.balances]: <BalancesList tokens={tokens} isBalancesLoading={isLoading} />,
    [MainModeTab.positions]: <PositionsList />,
    [MainModeTab.activity]: <ActivitiesList />,
  };

  const lockedTokensBN = voterWeightData?.selfAmount.amountLocked;
  const lockedTokensNumber = lockedTokensBN ? lockedTokensBN.toNumber() / 1000000 : 0;

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        {!walletPublicKey ? <ConnectButton /> : <WalletItem />}

        <div className={s.headerButtons}>
          <IconButton size="medium" variant="secondary" onClick={onSettingsClick} icon={<Icon name="Settings" />} />
          {Boolean(walletPublicKey) && (
            <Button size="medium" variant="secondary" onClick={handleDisconnectClick}>
              Disconnect
            </Button>
          )}
        </div>
      </div>

      <div className={s.layout}>
        <Tabs
          tabs={BalancesTabs}
          activeKey={balancesActiveTab}
          onChange={(key) => setBalancesActiveTab(key as BalanceTabType)}
          isFullWidth
        />
      </div>
      {balancesContent[balancesActiveTab]}

      <div className={s.layout}>
        <Button variant="secondary" className={s.button} onClick={onSwapClick}>
          <div className={s.buttonContent}>
            <Icon name="Swap" size={24} fill="white" />
            <Text fs={16} lh={22}>
              Swap
            </Text>
          </div>
        </Button>
        {/* <Button variant="secondary" className={s.button} onClick={onBridgeClick}> */}
        {/*  <div className={s.buttonContent}> */}
        {/*    <Icon name="Bridge" size={24} /> */}
        {/*    <Text fs={16} lh={22}> */}
        {/*      Bridge */}
        {/*    </Text> */}
        {/*  </div> */}
        {/* </Button> */}
      </div>

      <div className={s.layout}>
        <div className={s.stat} onClick={handleLinkClick}>
          <Link to={featureFlags.isGovernanceEmptyStateEnabled ? paths.GOVERNANACE_AND_STAKING : paths.STAKE}>
            <div className={s.statTitle}>
              <Icon name="KmnoLogo" size={16} />
              <Text fs={14} lh={20} color="grey">
                KMNO Staked
              </Text>
            </div>
            <Text>{farmStakedAmount ? ftad(farmStakedAmount, 2) : lockedTokensBN && lockedTokensNumber}</Text>
          </Link>
        </div>

        <div className={s.stat} onClick={handleLinkClick}>
          <Link to={paths.POINTS}>
            <div className={s.statTitle}>
              <img className={s.starIcon} src={icon} alt="Star" />
              <Text fs={14} lh={20} color="grey">
                Points
              </Text>
            </div>
            <Text>
              {ftad(
                totalPointsRealTimeS3,
                totalPointsRealTimeS3 === 0 || totalPointsRealTimeS3 >= 1000000 ? 0 : 4,
                true
              )}
            </Text>
          </Link>
        </div>
      </div>

      <div className={s.layout}>
        <Tabs
          tabs={MainModeTabs}
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key as MainModeTabType)}
          isFullWidth
        />
      </div>

      {tabsContent[activeTab]}
    </div>
  );
});
