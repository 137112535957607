import { withStyles } from '@bruitt/classnames';
import { FC, ReactNode } from 'react';
import { Text } from '../Typography';

import s from './DropdownItem.module.less';

const sx = withStyles(s);

interface DropdownItemProps {
  title: string | ReactNode;
  link?: string;
  description?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const DropdownItem: FC<DropdownItemProps> = ({
  title,
  link,
  description,
  startIcon,
  endIcon,
  isActive = false,
  isDisabled = false,
  onClick,
}) => {
  const content = (
    <>
      {startIcon}
      <div>
        <Text className={sx('title')} fs={16} lh={22} weight={description ? 'medium' : 'regular'}>
          {title}
        </Text>
        {description && (
          <Text className={sx('description')} lh={18} weight="regular">
            {description}
          </Text>
        )}
      </div>
      {endIcon}
    </>
  );

  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        className={sx('root', { withDescription: Boolean(description), isActive })}
        onClick={onClick}
        rel="noreferrer"
      >
        {content}
      </a>
    );
  }

  return (
    <div className={sx('root', { withDescription: Boolean(description), isActive, isDisabled })} onClick={onClick}>
      {content}
    </div>
  );
};
