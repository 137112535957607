import { useQuery } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';
import { UserFarm } from '@hubbleprotocol/farms-sdk';
import { PubkeyHashMap } from '@kamino-finance/klend-sdk';
import { useWeb3Client } from '../../../shared/hooks/useWeb3Client';
import { QUERYKEYS } from '../../../shared/constants/queryKeys';
import { useKaminoFarms } from '../../../shared/hooks/useKaminoFarms';
import { useWalletAddressFromParamsOrEnv } from '../../../shared/hooks/useWalletAddressFromParamsOrEnv';

const initialValue: PubkeyHashMap<PublicKey, UserFarm> = new PubkeyHashMap<PublicKey, UserFarm>();

export const useUserFarmsQuery = () => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { web3client } = useWeb3Client();
  const { kaminoFarms } = useKaminoFarms();

  const queryFn = () => {
    if (!kaminoFarms || !walletPublicKey || !web3client) {
      throw new Error('Unexpected error');
    }

    return kaminoFarms.getAllFarmsForUser(new PublicKey(walletPublicKey));
  };

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.getFarmsRewards(walletPublicKey || ''),
    enabled: Boolean(walletPublicKey) && Boolean(web3client) && Boolean(kaminoFarms),
    queryFn,
    // onError: (err: unknown) => {
    //   captureError(err);
    //   notify({
    //     message: 'Failed to load rewards data',
    //     description: 'Rewards data is not available',
    //     type: 'error',
    //   });
    // },
    staleTime: 30 * 1000, // 30 seconds
    refetchInterval: 20 * 1000, // 20 seconds
  });

  return {
    data: data || initialValue,
    ...rest,
  };
};
