import { withStyles } from '@bruitt/classnames';
import { CSSProperties, FC, memo, useMemo } from 'react';

import { getIconClassName } from './utils';

import s from './TokenIcon.module.less';
import { PublicKeyAddress } from '../../types/strategies';
import useTokens from '../../hooks/useTokens';
import { TokenInfo } from '../../types/token-info';

const sx = withStyles(s);

interface TokenIconProps {
  tokenMint: PublicKeyAddress;
  size: number;
  className?: string;
}

export const TokenIcon: FC<TokenIconProps> = memo(({ tokenMint, size, className }) => {
  const { getToken } = useTokens();

  const iconClassname = useMemo(() => getIconClassName(tokenMint), [tokenMint]);

  const tokenInfoLogoUrl = useMemo(() => {
    if (!iconClassname) {
      return getToken(tokenMint).logoURI;
    }
  }, [getToken, iconClassname, tokenMint]);

  const style = useMemo(() => getStyle(size, tokenInfoLogoUrl), [size, tokenInfoLogoUrl]);

  return <div style={style} className={sx('root', iconClassname || '', className)} />;
});

interface TokenIconByTokenInfoProps {
  tokenInfo: TokenInfo;
  size: number;
  className?: string;
}

export const TokenIconByTokenInfo: FC<TokenIconByTokenInfoProps> = memo(({ tokenInfo, size, className }) => {
  const tokenMint = tokenInfo.address;
  const iconClassname = useMemo(() => getIconClassName(tokenMint), [tokenMint]);

  const tokenInfoLogoUrl = useMemo(() => {
    if (!iconClassname) {
      return tokenInfo.logoURI;
    }
  }, [tokenInfo, iconClassname]);

  const style = useMemo(() => getStyle(size, tokenInfoLogoUrl), [size, tokenInfoLogoUrl]);

  return <div style={style} className={sx('root', iconClassname || '', className)} />;
});

function getStyle(size: number, tokenInfoLogoUrl: string | null | undefined) {
  const baseStyle: CSSProperties = size ? { width: size, height: size } : {};

  if (tokenInfoLogoUrl) {
    baseStyle.backgroundImage = `url(${tokenInfoLogoUrl})`;
    baseStyle.backgroundRepeat = 'no-repeat';
    baseStyle.backgroundPosition = 'center';
    baseStyle.backgroundSize = 'contain';
  }

  return baseStyle;
}
