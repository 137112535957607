import { useLocation } from 'react-router-dom';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import { useObservableStoreValue } from '../../stores/useObservableStoreValue';
import { layoutProviderStore, LayoutProviderStore } from '../../stores/layoutProviderStore';
import { isJlpMultiplyVaultPage, isMultiplyVaultPage } from '../utils/lending/multiply';
import { SLIPPAGE_JLP_MULTIPLY_WARNING_PCT, SLIPPAGE_MULTIPLY_WARNING_PCT } from '../constants/slippage';
import { JLP_MULTIPLY_SLIPPAGE_IS_TOO_HIGH, MULTIPLY_SLIPPAGE_IS_TOO_HIGH } from '../constants/warnings';

/**
 * Returns the right slippage based on product
 * Validate is slippage is too high
 */
export const useSlippage = () => {
  const location = useLocation();

  const slippage = useObservableStoreValue<LayoutProviderStore, Decimal>(
    layoutProviderStore,
    (store) => store.slippage
  );
  const slippageMultiply = useObservableStoreValue<LayoutProviderStore, Decimal>(
    layoutProviderStore,
    (store) => store.slippageMultiply
  );

  const slippageJlpMultiply = useObservableStoreValue<LayoutProviderStore, Decimal>(
    layoutProviderStore,
    (store) => store.slippageJlpMultiply
  );

  // we need to show a different default slippage for JLP Multiply
  const isJlpMultiplyPage = isJlpMultiplyVaultPage(location.pathname);
  // we need to show a different default slippage for Multiply
  const isMultiplyPage = isMultiplyVaultPage(location.pathname);

  const getSlippage = useCallback(() => {
    if (isJlpMultiplyPage) {
      return slippageJlpMultiply;
    }
    if (isMultiplyPage) {
      return slippageMultiply;
    }

    return slippage;
  }, [isJlpMultiplyPage, isMultiplyPage, slippage, slippageJlpMultiply, slippageMultiply]);

  // slippage level that triggers warning for user
  const optimalSlippage = useMemo(() => {
    if (isJlpMultiplyPage) {
      return SLIPPAGE_JLP_MULTIPLY_WARNING_PCT;
    }
    if (isMultiplyPage) {
      return SLIPPAGE_MULTIPLY_WARNING_PCT;
    }
    return 10;
  }, [isJlpMultiplyPage, isMultiplyPage]);

  const isSlippageTooHigh = useCallback(() => {
    const currentSlippage = getSlippage();

    if (isJlpMultiplyPage) {
      return currentSlippage.gt(SLIPPAGE_JLP_MULTIPLY_WARNING_PCT);
    }

    if (isMultiplyPage) {
      return currentSlippage.gt(SLIPPAGE_MULTIPLY_WARNING_PCT);
    }

    return false;
  }, [getSlippage, isJlpMultiplyPage, isMultiplyPage]);

  const getSlippageWarningMessage = useCallback(() => {
    const hasWarning = isSlippageTooHigh();

    if (hasWarning && isJlpMultiplyPage) {
      return JLP_MULTIPLY_SLIPPAGE_IS_TOO_HIGH;
    }
    if (hasWarning && isMultiplyPage) {
      return MULTIPLY_SLIPPAGE_IS_TOO_HIGH;
    }
  }, [isJlpMultiplyPage, isMultiplyPage, isSlippageTooHigh]);

  return { getSlippage, isSlippageTooHigh, getSlippageWarningMessage, optimalSlippage };
};
