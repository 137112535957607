import { KaminoReserve } from '@kamino-finance/klend-sdk';
import Decimal from 'decimal.js';
import { FarmStateWithApy } from '../../../types/farms';
import { StakingYieldsTokensResponse } from '../../../types/stakingYieldsTokens';
import { PublicKeyAddress } from '../../../types/strategies';
import { calcTotalRewardsApy } from '../../../utils';

export const EMPTY_RESERVE_APY_DATA = {
  kTokenFeesApy: 0,
  isKToken: false,
  collateralFarmTotalApy: 0,
  debtFarmTotalApy: 0,
  lendingDepositApy: 0,
  lendingBorrowApy: 0,
  totalDepositApy: 0,
  totalBorrowApy: 0,
  extraSupplyApy: 0,
};

// For leverage
// getFarmByAddress -> irrelevant
// getKtokenSuplyApy -> irrelevant
export const getReserveApys = ({
  currentSlot,
  getFarmByAddress,
  getReserveByAddress,
  getStakingYieldByMint,
  getKTokenSupplyApy,
}: {
  currentSlot: number;
  getFarmByAddress: (address: PublicKeyAddress) => FarmStateWithApy | undefined;
  getKTokenSupplyApy?: (tokenMint: string) => Decimal;
  getReserveByAddress: (address: PublicKeyAddress) => KaminoReserve;
  getStakingYieldByMint: (mint: PublicKeyAddress) => StakingYieldsTokensResponse | undefined;
}) => {
  return (reserveAddress?: PublicKeyAddress) => {
    if (!reserveAddress) {
      return EMPTY_RESERVE_APY_DATA;
    }
    const reserve = getReserveByAddress(reserveAddress);

    if (!reserve) {
      return EMPTY_RESERVE_APY_DATA;
    }
    const { farmDebt: farmDebtAddress, farmCollateral: farmCollateralAddress } = reserve.state;
    const kTokenFeesApy = getKTokenSupplyApy
      ? getKTokenSupplyApy(reserve.getLiquidityMint().toString())
      : new Decimal(0);

    const collateralFarm = getFarmByAddress(farmCollateralAddress);
    const debtFarm = getFarmByAddress(farmDebtAddress);

    const collateralFarmTotalApy: number = calcTotalRewardsApy(collateralFarm);
    const debtFarmTotalApy: number = calcTotalRewardsApy(debtFarm);

    const lendingDepositApy = reserve.totalSupplyAPY(currentSlot) || 0;
    const lendingBorrowApy = reserve.totalBorrowAPY(currentSlot) || 0;
    const totalDepositApy = lendingDepositApy + collateralFarmTotalApy;
    const totalBorrowApy = lendingBorrowApy - debtFarmTotalApy;

    return {
      kTokenFeesApy,
      collateralFarmTotalApy,
      debtFarmTotalApy,
      lendingDepositApy,
      lendingBorrowApy,
      totalDepositApy,
      totalBorrowApy,
      // extra is for JLP and LSTs
      extraSupplyApy: Number(getStakingYieldByMint(reserve.getLiquidityMint())?.apy || 0),
    };
  };
};

export type ReserveApys = ReturnType<ReturnType<typeof getReserveApys>>;
