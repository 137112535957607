import { PublicKey } from '@solana/web3.js';
import { TokenInfo } from '../../types/token-info';
import { notEmpty } from '../../utils';
import { isNativeSolMint } from '../../utils/tokens';
import { PublicKeyAddress } from '../../types/strategies';
import { Token } from '../../constants/tokens';
import { DECIMALS_SOL } from '../../constants/math';

export const UNKNOWN_TOKEN: TokenInfo = {
  symbol: '...' as Token,
  name: '...',
  logoURI: '',
  address: PublicKey.default.toString(),
  decimals: 0,
};

export const getTokenInfoMaps = (tokensInfo?: TokenInfo[]) => {
  if (!tokensInfo) {
    return {};
  }
  return tokensInfo.filter(notEmpty).reduce((sum, tokenInfo) => {
    sum[tokenInfo.address] = tokenInfo;
    return sum;
  }, {} as Record<string, TokenInfo>);
};

export const getTokenInfo = (mint: PublicKeyAddress, tokensInfoMap?: Record<string, TokenInfo>): TokenInfo => {
  //  edge case for native SOL
  if (isNativeSolMint(mint)) {
    return {
      name: 'SOL',
      address: mint.toString(),
      symbol: 'SOL',
      decimals: DECIMALS_SOL,
      logoURI: null,
    };
  }
  // TODO: USDR is not returned by the API anymore, use another API or even onchain data?
  if (mint.toString() === 'USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2') {
    return {
      name: 'USDR',
      address: mint.toString(),
      symbol: 'USDR',
      decimals: 6,
      logoURI: null,
    };
  }
  const info = tokensInfoMap?.[mint.toString()];

  if (!info) {
    // throw new Error(`Token mint ${mint.toString()} is not found`);
    return { ...UNKNOWN_TOKEN, address: mint.toString() };
  }
  return info;
};

export const getTokenByMintHelper = (tokensInfo: TokenInfo[] | undefined) => (mint: string | PublicKey) => {
  const tokenInfo = tokensInfo!.find((info) => {
    return info.address.toString() === mint.toString();
  });

  if (!tokenInfo) {
    throw new Error(`Token with mint ${mint.toString()} is not found`);
  }

  return tokenInfo.symbol;
};

export const getTokenMintHelper =
  (tokensInfo: TokenInfo[] | undefined) =>
  (token: Token): string => {
    const tokenInfo = tokensInfo!.find((info) => {
      return info.symbol.toLowerCase() === token.toLowerCase();
    });

    if (!tokenInfo) {
      throw new Error(`${token.toString()} is not found`);
    }
    return tokenInfo.address;
  };

export const getTokenHelper =
  (tokensInfoMap: Record<string, TokenInfo>) =>
  (mint: PublicKeyAddress): TokenInfo =>
    getTokenInfo(mint, tokensInfoMap);
