import { BlockingOverlay } from '../BlockingOverlay/BlockingOverlay';

export const GeoblockOverlay = () => {
  return (
    <BlockingOverlay
      title="Site is unavailable"
      content="This site is not available in your region. Based on your location being a current Restricted Jurisdiction, you
  are prohibited from accessing this site."
    />
  );
};
