import { useCallback } from 'react';
import Decimal from 'decimal.js';
import { PublicKey } from '@solana/web3.js';

import { usePricesQuery } from './queries/usePricesQuery';

export function usePrices() {
  const { data, ...rest } = usePricesQuery();

  const getPriceByTokenMintDecimal: (mint: PublicKey | string) => Decimal = useCallback(
    (mint: PublicKey | string) => data?.[mint.toString()] || new Decimal(0),
    [data]
  );

  return {
    getPriceByTokenMintDecimal,
    ...rest,
  };
}
