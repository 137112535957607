import { FC, useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { observer } from 'mobx-react-lite';

import s from './TableCells.module.less';

import { TextCell } from '../../TableCells';
import { PnlValue } from '../../../../../shared/components/v2/PnlValue';
import { PublicKeyAddress } from '../../../../../shared/types/strategies';
import { DEFAULT_TEXT_PROPS } from '../../../constants/common';
import { useUserPositionsPnLQuery } from '../../../../../shared/hooks/kamino/queries/useUserPositionsPnLQuery';
import { Spinner } from '../../../../../shared/uiKitV2/Spinner';
import { useUserFeesAndRewards } from '../../../../../shared/hooks/kamino/useUserFeesAndRewards';
import { calculateTotalEarnings } from '../../../../../shared/utils/kamino';
import { useWalletAddressFromParamsOrEnv } from '../../../../../shared/hooks/useWalletAddressFromParamsOrEnv';
import { formatNumber } from '../../../../../shared/utils';
import { TextProps } from '../../../../../shared/uiKitV2/Typography/Typography';

const { formatUsd: fusd } = formatNumber;

interface PnlCellProps {
  address: PublicKeyAddress;
  label?: string;
  textProps?: TextProps;
}

export const PnlCell: FC<PnlCellProps> = observer(({ address, label, textProps }) => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { data: pnlData, isLoading: arePnlDataLoading } = useUserPositionsPnLQuery({
    strategiesAddresses: [address],
    walletPublicKey,
  });
  const pnlInfo = pnlData[0];

  const data = useMemo(() => {
    return { pre: '$', value: pnlInfo?.totalPnl.usd || 0 };
  }, [pnlInfo]);

  return (
    <span data-tooltip-id="position-pnl-tooltip" data-tooltip-content={address.toString()}>
      {arePnlDataLoading ? (
        <Spinner />
      ) : (
        <TextCell label={label}>
          <PnlValue
            {...data}
            textProps={{
              underline: true,
              tag: 'span',
              className: s.pnlCell,
              ...(textProps || DEFAULT_TEXT_PROPS),
            }}
          />
        </TextCell>
      )}
    </span>
  );
});

interface TotalFeesProps {
  address: PublicKeyAddress;
  label?: string;
}

export const TotalFees: FC<TotalFeesProps> = ({ address, label }) => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { userFeesAndRewardsStrategies: feesAndRewardsEarnings, isLoading: isFeesAndRewardsLoading } =
    useUserFeesAndRewards({ strategiesAddresses: [new PublicKey(address)], walletPublicKey });
  const feesInfo = feesAndRewardsEarnings[0];
  const totalFees = calculateTotalEarnings(feesInfo);
  return <span>{isFeesAndRewardsLoading ? <Spinner /> : <TextCell label={label}>{fusd(totalFees)}</TextCell>}</span>;
};
