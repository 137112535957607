import { ReactElement, SVGProps } from 'react';

export const SvgSwitchboardV2 = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.732 6.615C2.732 4.532 4.18 3.14 6.41 3.1h8.11c-.483 1.067-.873 1.608-1.168 1.623H6.337c-1.215.003-1.946.71-1.946 1.892 0 1.17.729 1.925 1.905 1.93l3.106-.005a.866.866 0 0 1 .838.862c0 .468-.352.739-.795.761l-3.06.004c-2.179 0-3.62-1.44-3.652-3.481v-.071Z"
      fill="#2546F4"
    />
    <path
      d="M13.187 9.384c0 2.083-1.446 3.475-3.676 3.515h-8.11c.483-1.067.873-1.608 1.168-1.623h7.014c1.215-.004 1.946-.71 1.946-1.892 0-1.17-.729-1.926-1.905-1.93l-3.106.004a.866.866 0 0 1-.838-.861c0-.468.352-.739.795-.761l3.059-.004c2.18 0 3.62 1.44 3.653 3.48v.072Z"
      fill="#2546F4"
    />
  </svg>
);
