import Decimal from 'decimal.js';
import { ApyRangeType } from '../../constants/kamino';
import { ApyMetrics, KaminoApyMetrics } from '../../types/metrics';
import { PeriodPresetType } from '../../../pages/LiquidityPageV2/types';

export interface CalcTotalApyByRangeProps {
  apyInfo: ApyMetrics | null;
  kaminoApyInfo: KaminoApyMetrics | null;
  apyRange: ApyRangeType | PeriodPresetType;
}

export const calcTotalApyByRange = ({ apyInfo, kaminoApyInfo, apyRange }: CalcTotalApyByRangeProps) => {
  const rewardsApy =
    apyInfo?.kamino.reduce((accumulator, currentValue) => {
      return new Decimal(accumulator).plus(currentValue.apy);
    }, new Decimal(0)) || new Decimal(0);

  return rewardsApy?.plus(kaminoApyInfo?.vault[apyRange] || 0) || new Decimal(kaminoApyInfo?.totalApy || 0);
};
