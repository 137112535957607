import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

/**
 * This hook listen for a single field in the store
 * Use it inside hooks to make sure hook received the updated from the store
 * Avoid to wrap entire parent component into observer and use this hook instead whenever possible
 * @param store
 * @param selector
 */
// Define the hook with generics for the store type `S` and the selector return type `T`
export function useObservableStoreValue<S, T>(store: S, selector: (store: S) => T): T {
  // Initialize state with the current value of the observable
  const [value, setValue] = useState<T>(() => selector(store));

  useEffect(() => {
    // Setup autorun to update state whenever the observed value changes
    const disposer = autorun(() => {
      const newValue: T = selector(store);
      setValue(newValue);
    });

    // Cleanup autorun when the component unmounts or the store changes
    return () => disposer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, store]);

  return value;
}
