import { KaminoObligation } from '@kamino-finance/klend-sdk';
import { useMemo } from 'react';
import { LendingPositionTableData } from '../types';
import { useLendingPositionsTableData } from './useLendingPositionsTableData';
import { useCacheLendingData } from './useCacheLendingData';
import { PublicKeyAddress } from '../../../shared/types/strategies';
import { TotalFeesEarnedObligationProps } from '../../../shared/types/lending';
import { useObligationsInterestFeesQuery } from '../../../shared/hooks/lending/queries/useObligationInterestFeesQuery';

const EMPTY_DATA = {
  totalNetValue: 0,
  totalCollateral: 0,
  totalDebt: 0,
  totalInterestFees: 0,
  totalPnl: 0,
  positionsCount: 0,
};

function calcPositionsTotalNetValue(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += position.netValue;
    return sum;
  }, 0);
}

function calcTotalCollateral(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += Array.from(position.deposits.values()).reduce((s, deposit) => {
      s += Number(deposit.marketValueRefreshed);
      return s;
    }, 0);

    return sum;
  }, 0);
}

function calcTotalDebt(data: KaminoObligation[]): number {
  return data.reduce((sum, position) => {
    sum += position.refreshedStats.userTotalBorrow.toNumber();

    return sum;
  }, 0);
}

function calcPositionsTotalInterestFees(
  data: LendingPositionTableData[],
  getInterestFeesFromCache: (obligationAddress: PublicKeyAddress) => TotalFeesEarnedObligationProps
): number {
  return data.reduce((sum, position) => {
    sum += Number(getInterestFeesFromCache(position.address)?.usdFees || 0);
    return sum;
  }, 0);
}

export const useLendingPositionsStats = () => {
  const { data: tableData, isLoading, obligations } = useLendingPositionsTableData();
  const { isFetched, isReady } = useObligationsInterestFeesQuery({ obligations });
  const { getInterestFeesFromCache } = useCacheLendingData();

  const data = useMemo(() => {
    return {
      totalNetValue: calcPositionsTotalNetValue(tableData),
      totalCollateral: calcTotalCollateral(tableData),
      totalDebt: calcTotalDebt(obligations),
      totalInterestFees: calcPositionsTotalInterestFees(tableData, getInterestFeesFromCache),
      positionsCount: tableData.length,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInterestFeesFromCache, obligations, tableData, isFetched]);

  return { data: isLoading ? EMPTY_DATA : data, isLoading, isReady };
};
