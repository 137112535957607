import { useQuery } from '@tanstack/react-query';

import { useMemo } from 'react';
import { QUERYKEYS } from '../../constants/queryKeys';
import { fetchStakingYields } from '../../services/strategies';
import { StakingYieldsTokensResponse } from '../../types/stakingYieldsTokens';
import { MULTIPLY_MINTS } from '../../constants/tokens';

const EMPTY_DATA: StakingYieldsTokensResponse[] = [];
// hardcoded values while endpoint is down
export const HARDCODED_MULTIPLY_LSTS = [
  {
    apy: '0.0746',
    token: 'mSOL',
    tokenMint: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
  },
  {
    apy: '0.0807',
    token: 'jitoSOL',
    tokenMint: 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
  },
  {
    apy: '0.0725',
    token: 'bSOL',
    tokenMint: 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
  },
  {
    apy: '0.0793',
    token: 'hSOL',
    tokenMint: 'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A',
  },
  {
    apy: '0.1135',
    token: 'jupSOL',
    tokenMint: 'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v',
  },
  {
    apy: '0.0686',
    token: 'vSOL',
    tokenMint: 'vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7',
  },
  {
    tokenMint: 'Bybit2vBJGhPF52GBdNaQfUJ6ZpThSgHBobjWZpLPb4B',
    token: 'bbSOL',
    apy: '0.166219331050009',
  },
];

export const useStakingYieldsQuery = () => {
  const {
    data = EMPTY_DATA,
    isFetched,
    isError,
    ...rest
  } = useQuery({
    queryKey: QUERYKEYS.stakingYields,
    queryFn: fetchStakingYields,
  });

  const dataProcessed = useMemo(() => {
    return getStakingYieldsProcessedData({
      data,
      isFetched,
      isError,
    });
  }, [data, isFetched, isError]);

  return { data: dataProcessed, isFetched, ...rest };
};

export const getStakingYieldsProcessedData = ({
  data,
  isFetched,
  isError,
}: {
  data: StakingYieldsTokensResponse[];
  isFetched: boolean;
  isError: boolean;
}) => {
  if (isError || (data && isFetched && data.length === 0)) {
    return HARDCODED_MULTIPLY_LSTS.filter((item) => MULTIPLY_MINTS.includes(item.tokenMint));
  }

  return data.filter((item) => MULTIPLY_MINTS.includes(item.tokenMint));
};
