import { useQuery } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { QUERYKEYS } from '../../constants/queryKeys';

import { useKaminoSdk } from '../useKaminoSdk';
import { useMarketsQuery } from '../lending/queries/useMarketsQuery';
import { useRawPricesQuery } from './useRawPricesQuery';

const EMPTY_OBJ: Record<string, Decimal> = {};

export const usePricesQuery = () => {
  const { kaminoSdk } = useKaminoSdk();
  const { isFetched: isMarketsFetched } = useMarketsQuery();
  const { data: rawPrices, isFetched: isPricesFetched } = useRawPricesQuery();

  const queryFn = () => {
    if (!rawPrices || !rawPrices.spot || !isPricesFetched) {
      return EMPTY_OBJ;
    }

    const combineAllPrices = { ...rawPrices?.spot };

    return Object.keys(combineAllPrices).reduce((acc: Record<string, any>, key) => {
      acc[key] = combineAllPrices[key].price;
      return acc;
    }, {});
  };

  return useQuery(QUERYKEYS.prices, queryFn, {
    enabled: Boolean(kaminoSdk && isMarketsFetched && isPricesFetched),
    refetchInterval: 1 * 60 * 1000, // 1 min
    staleTime: 2 * 60 * 1000, // 2 mins
  });
};
