export enum BalanceTab {
  balance = 'balance',
  netValue = 'netValue',
}
export type BalanceTabType = keyof typeof BalanceTab;

export const BalancesTabs = [
  { key: BalanceTab.balance, label: 'Wallet balance' },
  { key: BalanceTab.netValue, label: 'Kamino positions' },
];
