import React from 'react';
import { debounce } from 'lodash';

interface WindowSize {
  width: number;
  height: number;
}

type UseWindowSizeOptions = {
  isUsedDocumentWidth?: boolean;
};

export default function useWindowSize(options: UseWindowSizeOptions = {}): WindowSize {
  const { isUsedDocumentWidth = false } = options;
  const [size, setSize] = React.useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useLayoutEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        width: isUsedDocumentWidth ? document.documentElement.clientWidth : window.innerWidth,
        height: window.innerHeight,
      });
    }, 300);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isUsedDocumentWidth]);

  return size;
}
