import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { MainMode } from '../MainMode/MainMode';
import { SwapMode } from '../Swap/SwapMode';
import { Bridge } from '../Bridge/Bridge';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';
import { MIXPANEL_PREFIX } from '../../../../shared/constants/mixpanel';
import { useWalletAddressFromParamsOrEnv } from '../../../../shared/hooks/useWalletAddressFromParamsOrEnv';
import { WalletConnectMode } from '../WalletConnectMode/WalletConnectMode';
import { SettingsMode } from '../SettingsMode/SettingsMode';

export type SidePanelModeType = 'main' | 'swap' | 'bridge' | 'settings';

export const SidepanelContent: FC = () => {
  const [mode, setMode] = useState<SidePanelModeType>('main');
  const mixpanel = useMixpanel();
  const { isSidePanelRendered } = layoutProviderStore;
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();

  useEffect(() => {
    if (isSidePanelRendered) {
      mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:open`);
    }
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:close`);
  }, [isSidePanelRendered, mixpanel]);

  const onSwapClick = () => {
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:main:swap:click`);
    setMode('swap');
  };
  const onSettingsClick = () => {
    setMode('settings');
  };

  const handleBack = () => {
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:main:back:click`);
    setMode('main');
  };

  const content: Record<SidePanelModeType, ReactNode> = {
    main: <MainMode onSwapClick={onSwapClick} onSettingsClick={onSettingsClick} />,
    swap: <SwapMode onBack={handleBack} />,
    bridge: <Bridge onBack={handleBack} />,
    settings: <SettingsMode onBack={handleBack} />,
  };

  if (!walletPublicKey) {
    return <WalletConnectMode />;
  }

  return <>{content[mode]}</>;
};
