/* eslint-disable @typescript-eslint/naming-convention */

import { PublicKeyAddress } from './strategies';
import { TokenInfo } from './token-info';

export enum TransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export interface TransactionDetailItem {
  tokenMint: PublicKeyAddress;
  tokenInfo?: TokenInfo; // React fails due to query client not being available otherwise :/
  amount: number;
}

export interface TransactionDetail {
  type: string;
  amounts: TransactionDetailItem[];
}

export interface NotificationTransaction {
  status: TransactionStatus;
  txId?: string;
  label: string;
  tranactionDetail?: TransactionDetail;
}
