import { observer } from 'mobx-react-lite';

import s from './WhatsNewIndicator.module.less';

import { useWhatsNewIndicator } from '../hooks/useWhatsNewIndicator';
import { Tooltip } from '../../../../uiKitV2/Tooltip';
import { Text } from '../../../../uiKitV2/Typography';
import useEnv from '../../../../hooks/useEnv';

interface WhatsNewIndicatorProps {
  id: string;
  showFrom?: string;
  showUntil?: string;
  tooltip?: string;
}

export const WhatsNewIndicator: React.FC<WhatsNewIndicatorProps> = observer((props) => {
  const { children } = props;
  const { walletPublicKey } = useEnv();

  if (!walletPublicKey) {
    return <>{children}</>;
  }

  return <WhatsNewIndicatorWalletConnected {...props} />;
});

export const WhatsNewIndicatorWalletConnected: React.FC<WhatsNewIndicatorProps> = observer((props) => {
  const { children, id, showFrom, showUntil, tooltip } = props;
  const { isIndicatorVisible, onWrapperClick } = useWhatsNewIndicator({
    id,
    showFrom,
    showUntil,
  });

  return (
    <Tooltip
      render={() => {
        if (!isIndicatorVisible) {
          return null;
        }
        return (
          <Text weight="regular" color="grey">
            {tooltip}
          </Text>
        );
      }}
    >
      <div className={s.indicatorWrapper} onClick={onWrapperClick}>
        {isIndicatorVisible && (
          <div className={s.indicator}>
            <div className={s.outerDot} />
            <div className={s.innerDot} />
          </div>
        )}
        {children}
      </div>
    </Tooltip>
  );
});
