import { PublicKey } from '@solana/web3.js';
import { useWallet, Wallet } from '@solana/wallet-adapter-react';
import { useCallback, useMemo } from 'react';
import { WalletContextState } from '@solana/wallet-adapter-react/src/useWallet';
import { WalletName } from '@solana/wallet-adapter-base';
import { getProgramId } from '@kamino-finance/klend-sdk';
import debugWallet from '../utils/debugWallet';
import { kaminoConsole } from '../utils';
import { Chain, ENV } from '../services/web3/types';
import { networkProviderStore } from '../../stores/networkProviderStore';
import isStagingEnabled from '../utils/stagingProgram';

function useEnv(): EnvContext {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    publicKey,
    signTransaction,
    signAllTransactions,
    select,
    disconnect,
    connected,
    connecting,
    wallet,
    wallets,
  } = useWallet();

  if (isStagingEnabled) {
    localStorage.setItem('kaminoStaging', getProgramId('staging').toString());
  } else {
    localStorage.removeItem('kaminoStaging');
  }

  const { env, chain, updateProviderParams: updateNetworkProviderParams } = networkProviderStore;

  const programId = useMemo(() => {
    // as any is used to avoid typescript error
    // sdk only acceps undefined, 'staging' or 'mainnet-beta'
    // defaults to 'staging' for all non 'mainnet-beta' environments
    // this logic is provided so it doesnt clash with the setEnv logic below
    return getProgramId(isStagingEnabled ? 'staging' : (env as any));
  }, [env]);

  const setEnv = useCallback(
    (newEnv: ENV, newChain: Chain) => {
      kaminoConsole('Environment switched to', newChain.displayName);
      const isNetworkChanged = newEnv !== env || newChain.name !== chain.name || newChain.endpoint !== chain.endpoint;
      if (isNetworkChanged) {
        window.location.reload();
      }

      updateNetworkProviderParams({ env: newEnv, chain: newChain });
    },
    [chain.endpoint, chain.name, env, updateNetworkProviderParams]
  );

  const walletPublicKey = debugWallet || publicKey?.toString();

  return {
    env,
    setEnv,
    chain,
    isWalletConnected: connected,
    isWalletConnecting: connecting,
    walletPublicKey: walletPublicKey ? walletPublicKey.toString() : null,
    selectWalletAndConnect: select,
    disconnectWallet: disconnect,
    wallet,
    wallets,
    signTransaction,
    signAllTransactions,
    programId,
  };
}

type EnvContext = {
  env: ENV;
  setEnv: (newEnv: ENV, newChain: Chain) => void;
  chain: Chain;
  isWalletConnected: boolean;
  isWalletConnecting: boolean;
  walletPublicKey: string | null;
  wallet: Wallet | null;
  wallets: Wallet[];
  selectWalletAndConnect: (walletName: WalletName<string> | null) => void;
  disconnectWallet: () => Promise<void>;
  signTransaction: WalletContextState['signTransaction'];
  signAllTransactions: WalletContextState['signAllTransactions'];
  programId: PublicKey;
};

export default useEnv;
