import { useCallback, useMemo } from 'react';
import { useStakingYieldsQuery } from '../queries/useStakingYieldsQuery';
import { useJlpPoolApyQuery } from './queries/useJlpPoolApyQuery';
import { usePyusdFarmApyQuery } from './queries/usePyusdFarmApyQuery';
import { PYUSD_MINT, SUSDE_MINT } from '../../constants/mints';
import { StakingYieldsTokensResponse } from '../../types/stakingYieldsTokens';
import { PublicKeyAddress } from '../../types/strategies';
import { isPyusdToken } from '../../utils';
import { useSUsdEPoolApyQuery } from './queries/useSUsdEPoolApyQuery';

const JLP_MINT = '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4';

/**
 * Returns staking yields for LST tokens and JLP
 */
export const useStakingYield = () => {
  const { data: lstData, isLoading: isLstLoading, isFetched: isLstFetched } = useStakingYieldsQuery();
  const { data: jlpData, isLoading: isJlpLoading, isFetched: isJlpFetched } = useJlpPoolApyQuery();
  const { data: sUsdEData, isLoading: isSUsdELoading, isFetched: isSUsdEFetched } = useSUsdEPoolApyQuery();
  const { data: pyusdData, isLoading: isPyusdLoading, isFetched: isPyusdFetched } = usePyusdFarmApyQuery();

  // format jlp to match lstData format so we can easily merge it
  const formattedJlpData = useMemo(() => {
    return [
      {
        apy: jlpData.toString(),
        token: 'JLP',
        tokenMint: JLP_MINT,
      },
    ];
  }, [jlpData]);

  const formattedPyusdData = useMemo(() => {
    return [
      {
        apy: pyusdData.toString(),
        token: 'PYUSD',
        tokenMint: PYUSD_MINT,
      },
    ];
  }, [pyusdData]);

  const formattedSUsdEData = useMemo(() => {
    return [
      {
        apy: sUsdEData.toString(),
        token: 'sUSDe',
        tokenMint: SUSDE_MINT,
      },
    ];
  }, [sUsdEData]);

  const tokensMap = useMemo(() => {
    return [...lstData, ...formattedJlpData, ...formattedPyusdData, ...formattedSUsdEData].reduce((sum, res) => {
      sum[res.tokenMint] = res;
      return sum;
    }, {} as Record<string, StakingYieldsTokensResponse | undefined>);
  }, [lstData, formattedJlpData, formattedPyusdData, formattedSUsdEData]);

  const getStakingYieldByMint = useCallback(
    (mint: PublicKeyAddress, isPyusdIncluded?: boolean) => {
      /**
       * PYUSD Multiply does not have a real staking yield
       * If you need to get an anlogie of it i.e farms rewards apy pass true as the second param
       */
      if (isPyusdToken(mint) && !isPyusdIncluded) {
        return undefined;
      }
      return tokensMap[mint.toString()];
    },
    [tokensMap]
  );

  return {
    getStakingYieldByMint,
    isLoading: isLstLoading || isJlpLoading || isPyusdLoading || isSUsdELoading,
    isFetched: (isLstFetched && isJlpFetched && isSUsdEFetched) || isPyusdFetched,
  };
};
