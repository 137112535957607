import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';

import { getJLPApr } from '@kamino-finance/klend-sdk';
import Decimal from 'decimal.js';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { useWeb3Client } from '../../useWeb3Client';
import { aprToApy } from '../../../utils';

const JLP_POOL_ADDRESS = new PublicKey('5BUwFW4nRbftYTDMbgxykoFWqWHPzahFSNAaaaJtVKsq');
const COMPOUND_PERIOD_DAILY = 365;
const EMPTY_VALUE = 0;

export const useJlpPoolApyQuery = () => {
  const { web3client } = useWeb3Client();

  const { data, ...rest } = useQuery(
    QUERYKEYS.getJlpPoolApy,
    () => getJLPApr(web3client!.connection, JLP_POOL_ADDRESS),
    {
      enabled: Boolean(web3client),
      select: (apr) => aprToApy(new Decimal(apr / 100), COMPOUND_PERIOD_DAILY),
    }
  );

  return { data: data || EMPTY_VALUE, ...rest };
};
