import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { withStyles } from '@bruitt/classnames';
import { Icon } from '../../../uiKitV2/Icon';
import { Text } from '../../../uiKitV2/Typography';

import s from './TransactionSettingButton.module.less';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';
import { formatNumber } from '../../../utils';
import { StrategySettingsModal } from '../../../../features/StrategySettingsModalV2/StrategySettingsModal';
import { useSlippage } from '../../../hooks/useSlippage';

const { formatPercent: fp } = formatNumber;

const sx = withStyles(s);

type Props = { withSlippage?: boolean; variant?: 'normal' | 'minimal' };

// TODO: pass values and maxValues as array to support unlimited amount of tokens in the form
export const TransactionSettingButton: FC<Props> = observer(({ withSlippage, variant = 'normal' }) => {
  const { updateProviderParams, priorityFeeType, priorityFee } = layoutProviderStore;
  const { getSlippage } = useSlippage();

  const displayedSlippage = getSlippage();

  return (
    <div className={sx(s.root, { variant })}>
      <Text className={s.title} lh={18} color="grey">
        Transaction Settings
      </Text>
      <div
        className={s.settings}
        onClick={() => {
          updateProviderParams({ isTransactionSettingsModalVisible: true });
        }}
      >
        <Text lh={18} className={s.priorityFeeText}>
          {withSlippage && `${fp(displayedSlippage)}, `}
          <span className={s.feeTypeText}>
            {priorityFeeType === 'custom' ? `${priorityFeeType}: ${priorityFee.toString()} SOL` : priorityFeeType}
          </span>
        </Text>
        <Icon name="SettingsFilled" className={s.clickable} size={16} color="grey-deep" />
      </div>

      <StrategySettingsModal withSlippage={withSlippage} />
    </div>
  );
});
