import { ReactElement, SVGProps } from 'react';

export const SvgDoubleCheck = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.25 9.00009L8.96231 12.7124L16.9173 4.75745"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.53772 9.03781L5.25003 12.7501M9.22751 8.77264L13.205 4.79517"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
