import { useQueries } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';
import { useMemo } from 'react';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchPnLForStrategy } from '../../../services/strategies';
import useEnv from '../../useEnv';
import { notify } from '../../../utils/notifications/notifications';
import { notEmpty } from '../../../utils';
import { PnLForStrategyResponse, PnLForStrategyResponseWithAddress } from '../../../types/strategyPnl';
import { PublicKeyAddress } from '../../../types/strategies';
import { captureError } from '../../../utils/captureError';

const EMPTY_DATA: PnLForStrategyResponseWithAddress[] = [];
const EMPTY_RESPONSE: PnLForStrategyResponseWithAddress = {
  strategy: PublicKey.default,
  totalPnl: {
    sol: '0',
    usd: '0',
    a: '0',
    b: '0',
  },
  totalCostBasis: {
    sol: '0',
    usd: '0',
    a: '0',
    b: '0',
  },
};

function addStrategyAddress(
  data: PnLForStrategyResponse,
  strategyAddress: PublicKeyAddress
): PnLForStrategyResponseWithAddress {
  return { ...data, strategy: strategyAddress };
}

interface UseUserPositionsPnLQueryProps {
  strategiesAddresses: PublicKeyAddress[];
  walletPublicKey: string | null;
}

/**
 * Fetches PnL values for user positions of provided list of strategies
 * @param strategiesAddresses
 */
export const useUserPositionsPnLQuery = ({ strategiesAddresses, walletPublicKey }: UseUserPositionsPnLQueryProps) => {
  const { env } = useEnv();

  const queries = strategiesAddresses.map((strategyAddress) => ({
    queryKey: QUERYKEYS.getUserPositionPnL(walletPublicKey || '', strategyAddress),
    queryFn: () => {
      if (!walletPublicKey) {
        throw new Error('Wallet is not connected');
      }

      // queryFn: () => fetchPnLForStrategy({ env, strategyPubkey, shareholderPubkey: walletPublicKey!.toString() }),
      return fetchPnLForStrategy({
        env,
        shareholderPubkey: walletPublicKey,
        strategyPubkey: String(strategyAddress),
      })
        .then((res) => addStrategyAddress(res, strategyAddress))
        .catch((err) => {
          captureError(err);

          // because of that onError never will be triggered; TODO: think about different approach to allow errors propagation
          return EMPTY_RESPONSE;
        });
    },
    onError: (err: Error) => {
      captureError(err);
      notify({
        type: 'error',
        message: `Loading error`,
        description: `Failed to load user PnL for strategy ${strategyAddress.toString()}`,
      });
    },
    retry: 0,
    enabled: Boolean(walletPublicKey) && Boolean(strategyAddress),
  }));

  const isEmptyParams = !strategiesAddresses || !strategiesAddresses.length;
  const results = useQueries({ queries });
  // Check if any query is still loading
  const isFetched = isEmptyParams || (results && results.every((result) => result.isFetched));
  const isLoading = results?.length ? results.some((result) => result.isLoading) : !isEmptyParams;

  // Extract data from results
  const data = useMemo(() => results?.map((result) => result.data).filter(notEmpty) || EMPTY_DATA, [results]);

  return {
    data: !isFetched || !data || isEmptyParams ? EMPTY_DATA : data,
    isLoading,
    isFetched,
  };
};
