import { useQuery } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';
import { KaminoMarket, PROGRAM_ID } from '@kamino-finance/klend-sdk';

import { QUERYKEYS } from '../../../constants/queryKeys';
import { notify } from '../../../utils/notifications/notifications';
import { featureFlags } from '../../../utils/featureFlags';
import { useWeb3Client } from '../../useWeb3Client';
import { captureError } from '../../../utils/captureError';
import { useMarketsFromResourcesQuery } from './useMarketsFromResourcesQuery';
import { getMarketAddressFromUrl } from '../../../utils/lending/getMarketAddressFromUrl';

const params = new URLSearchParams(window.document.location.search);
const RECENT_SLOT_DURATION_OVERRIDE_MS =
  params.get('RECENT_SLOT_DURATION_OVERRIDE_MS') || import.meta.env.VITE_APP_RECENT_SLOT_DURATION_OVERRIDE_MS || 485;

const EMPTY_MARKETS: KaminoMarket[] = [];

export const useMarketsQuery = (marketIndex?: number) => {
  const { web3client } = useWeb3Client();
  const { data: marketsFromResources } = useMarketsFromResourcesQuery();

  const stagingProgram = localStorage.getItem('kaminoStaging');
  const programId = stagingProgram ? new PublicKey(stagingProgram) : PROGRAM_ID;

  const exactMarket =
    getMarketAddressFromUrl() ||
    (marketIndex !== undefined ? marketsFromResources?.[marketIndex].lendingMarket ?? null : null);

  // Kamino markets without reserves
  const { data: kaminoMarketsWitoutReserves } = useQuery({
    queryKey: QUERYKEYS.lendingMarketsWithoutReserves,
    enabled:
      Boolean(web3client) &&
      featureFlags.isLendingPageEnabled &&
      Boolean(marketsFromResources) &&
      marketIndex === undefined &&
      !exactMarket,
    queryFn: async () => {
      if (!marketsFromResources) {
        return EMPTY_MARKETS;
      }

      const maybeMarkets = await Promise.all(
        marketsFromResources.map(({ lendingMarket }) =>
          KaminoMarket.load(
            web3client!.connection,
            new PublicKey(lendingMarket),
            RECENT_SLOT_DURATION_OVERRIDE_MS,
            programId,
            false,
            false
          )
        )
      );

      return maybeMarkets.filter((market) => !!market);
    },
    onError: (err: Error) => {
      captureError(err);
      console.error('Failed to load lending market without reserves');
      notify({
        message: 'Failed to load lending market',
        description: 'Lending market data will not be available',
        type: 'error',
      });
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  // Kamino markets with reserves
  const { data: kaminoMarkets, ...rest } = useQuery({
    queryKey: QUERYKEYS.lendingMarkets,
    enabled:
      Boolean(web3client) &&
      featureFlags.isLendingPageEnabled &&
      Boolean(marketsFromResources) &&
      Boolean(kaminoMarketsWitoutReserves) &&
      marketIndex === undefined &&
      !exactMarket,
    queryFn: async () => {
      if (!marketsFromResources) {
        return EMPTY_MARKETS;
      }

      const maybeMarkets = await Promise.all(
        marketsFromResources.map(({ lendingMarket }) =>
          KaminoMarket.load(
            web3client!.connection,
            new PublicKey(lendingMarket),
            RECENT_SLOT_DURATION_OVERRIDE_MS,
            programId,
            false,
            true
          )
        )
      );

      return maybeMarkets.filter((market) => !!market);
    },
    onError: (err: Error) => {
      captureError(err);
      console.error('Failed to load lending market with reserves', err);
      notify({
        message: 'Failed to load lending market',
        description: 'Lending market data will not be available',
        type: 'error',
      });
    },
    cacheTime: 10 * 60 * 1000, // 10 mins
    staleTime: 5 * 60 * 1000, // 5 mins,
  });

  const { data: exactMarketsWithoutReserve } = useQuery({
    queryKey: QUERYKEYS.exactLendingMarketWithoutReserve(exactMarket ?? undefined),
    enabled:
      Boolean(web3client) && featureFlags.isLendingPageEnabled && Boolean(marketsFromResources) && Boolean(exactMarket),
    queryFn: async () => {
      if (exactMarket) {
        const resp = await KaminoMarket.load(
          web3client!.connection,
          new PublicKey(exactMarket),
          RECENT_SLOT_DURATION_OVERRIDE_MS,
          programId,
          false,
          false
        );
        return resp ? [resp] : EMPTY_MARKETS;
      }

      return EMPTY_MARKETS;
    },
    onError: (err: Error) => {
      captureError(err);
      console.error('Failed to load lending market without reserves');
      notify({
        message: 'Failed to load lending market',
        description: 'Lending market data will not be available',
        type: 'error',
      });
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  const { data: exactMarkets } = useQuery({
    queryKey: QUERYKEYS.exactLendingMarket(exactMarket ?? undefined),
    enabled:
      Boolean(web3client) &&
      featureFlags.isLendingPageEnabled &&
      Boolean(marketsFromResources) &&
      Boolean(exactMarket) &&
      Boolean(exactMarketsWithoutReserve),
    queryFn: async () => {
      if (exactMarket) {
        const resp = await KaminoMarket.load(
          web3client!.connection,
          new PublicKey(exactMarket),
          RECENT_SLOT_DURATION_OVERRIDE_MS,
          programId,
          false,
          true
        );
        return resp ? [resp] : EMPTY_MARKETS;
      }

      return EMPTY_MARKETS;
    },
    onError: (err: Error) => {
      captureError(err);
      console.error('Failed to load lending market with reserves', err);
      notify({
        message: 'Failed to load lending market',
        description: 'Lending market data will not be available',
        type: 'error',
      });
    },
    cacheTime: 10 * 60 * 1000, // 10 mins
    staleTime: 5 * 60 * 1000, // 5 mins,
  });

  const data = exactMarket
    ? exactMarkets || exactMarketsWithoutReserve || EMPTY_MARKETS
    : kaminoMarkets || kaminoMarketsWitoutReserves || EMPTY_MARKETS;

  return {
    // to prevent weird build type error
    data: data as KaminoMarket[],
    ...rest,
  };
};
