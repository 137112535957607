import { ReactElement, SVGProps } from 'react';

export const SvgRadioFill = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#49AFE9" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <path d="M2.666 8a5.333 5.333 0 1 0 10.667 0A5.333 5.333 0 0 0 2.666 8Z" />
      <path d="M6 8a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z" fill="#49AFE9" />
    </g>
  </svg>
);
