import React, { FC } from 'react';
import useEnv from '../../../../shared/hooks/useEnv';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { shorterString } from '../../../../shared/utils';
import s from './WalletItem.module.less';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { notify } from '../../../../shared/utils/notifications/notifications';

export const WalletItem: FC = () => {
  const { walletPublicKey, wallet } = useEnv();

  if (!wallet || !walletPublicKey) {
    return null;
  }

  const handleCopyToClipboard = async () => {
    if (!walletPublicKey) {
      return;
    }
    await navigator.clipboard.writeText(walletPublicKey);

    notify({
      type: 'success',
      message: 'Copied!',
    });
  };

  return (
    <div className={s.root} onClick={handleCopyToClipboard}>
      <img alt={wallet.adapter.name} className={s.icon} src={wallet.adapter.icon} />
      <Text fs={18}>{shorterString(walletPublicKey, 4)}</Text>
      <div className={s.copyIcon}>
        <Icon name="Copy" size={16} color="grey" />
      </div>
      <div className={s.copyIconHovered}>
        <Icon name="Copy" size={16} color="blue" />
      </div>
    </div>
  );
};
