import React, { FC, forwardRef, ReactNode, RefAttributes, useEffect, useRef } from 'react';
import { withStyles } from '@bruitt/classnames';
import s from './Input.module.less';
import { Text } from '../Typography';

const sx = withStyles(s);

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'size'> {
  prefix?: ReactNode;
  suffix?: ReactNode;
  label?: string | ReactNode;
  size?: 'small' | 'medium';
}

export const Input: FC<InputProps & RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { size = 'medium', className, disabled, prefix, suffix, label, onMouseDown, autoFocus, ...rest } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }
    }, [autoFocus]);

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={sx(className)}>
        {label &&
          (typeof label !== 'string' ? (
            label
          ) : (
            <Text fs={14} lh={18} className={sx('label')} color={disabled ? 'grey-deep' : 'grey'}>
              {label}
            </Text>
          ))}

        <div
          className={sx('container', { size, disabled })}
          onMouseDown={(e) => {
            e.preventDefault();
            if (ref && typeof ref === 'object' && ref.current) {
              ref.current.focus();
            }
          }}
        >
          <input
            onMouseDown={(e) => {
              e.stopPropagation();
              onMouseDown?.(e);
            }}
            // ref={ref}
            ref={inputRef}
            className={sx('input')}
            disabled={disabled}
            {...rest}
          />

          {prefix && (
            <div onMouseDown={(e) => e.preventDefault()} className={sx('icon', 'prefix')}>
              {prefix}
            </div>
          )}

          {suffix && (
            <div onMouseDown={(e) => e.preventDefault()} className={sx('icon')}>
              {suffix}
            </div>
          )}
        </div>
      </label>
    );
  }
);
