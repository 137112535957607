import { PublicKey } from '@solana/web3.js';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { KaminoPosition } from '@kamino-finance/kliquidity-sdk';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { useKaminoSdk } from '../../useKaminoSdk';
import { notify } from '../../../utils/notifications/notifications';
import { captureError } from '../../../utils/captureError';
import { useWalletAddressFromParamsOrEnv } from '../../useWalletAddressFromParamsOrEnv';
import { useStrategyAdressesWithShareMintsQuery } from '../../queries/useStrategyAdressesWithShareMintsQuery';

const EMPTY_DATA: KaminoPosition[] = [];

export const useUserPositionsQuery = () => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { kaminoSdk } = useKaminoSdk();
  const { data: strategiesAddressesWithSharemints, isLoading } = useStrategyAdressesWithShareMintsQuery();

  const queryFn: QueryFunction<KaminoPosition[]> = () => {
    if (!kaminoSdk || !walletPublicKey) {
      throw new Error('Wallet is not connected');
    }

    return kaminoSdk.getUserPositionsByStrategiesMap(
      new PublicKey(walletPublicKey),
      strategiesAddressesWithSharemints.strategyAdressesWithShareMintsMap
    );
  };

  const { data, ...rest } = useQuery<KaminoPosition[]>({
    queryKey: QUERYKEYS.getUserPositionsQuery(walletPublicKey || ''),
    queryFn,
    enabled: Boolean(walletPublicKey) && Boolean(kaminoSdk) && !isLoading,
    onError: (err) => {
      captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load user positions',
      });
    },
  });

  return {
    data: data || EMPTY_DATA,
    ...rest,
  };
};
