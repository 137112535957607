import { ReactElement, SVGProps } from 'react';

export const SvgChartLegendGlyph = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="currentColor" d="M0 7.5h4v1H0z" />
    <rect x={4.5} y={4.5} width={7} height={7} rx={3.5} fill="currentColor" fillOpacity={0.25} />
    <rect x={4.5} y={4.5} width={7} height={7} rx={3.5} stroke="currentColor" />
    <path fill="currentColor" d="M12 7.5h4v1h-4z" />
  </svg>
);
