import React, { FC } from 'react';

import s from './TransactionDetail.module.less';
import { Text } from '../../../../shared/uiKitV2/Typography';
import txDetailSvg from './tx-detail.svg';
import type { TransactionDetail as TransactionDetailType } from '../../../../shared/types/notifications';
import { TokenIconByTokenInfo } from '../../../../shared/uiKitV2/TokenIcon';
import { formatNumber } from '../../../../shared/utils';
import arrowSvg from './arrow.svg';

const { formatTokenInput: fsn } = formatNumber;

interface TransactionsProps {
  data: TransactionDetailType;
}

export const TransactionDetail: FC<TransactionsProps> = ({ data }) => {
  const a = data.amounts[0];
  const b = data.amounts[1];
  const aInfo = a?.tokenInfo;
  const bInfo = b?.tokenInfo;
  const isPlus = data.type === 'Withdraw' || data.type === 'Unstake & Withdraw';

  if (!a || !b || !aInfo || !bInfo) {
    return null;
  }

  return (
    <div className={s.root}>
      <div className={s.txDetail}>
        <img src={txDetailSvg} alt="icon" />
      </div>
      <TokenIconByTokenInfo tokenInfo={aInfo} size={16} />
      <Text fs={14} lh={18} color="grey-deep">
        {fsn(String(a.amount), aInfo.decimals)}
      </Text>

      {isPlus ? (
        <Text fs={14} lh={18} color="grey-deep">
          +
        </Text>
      ) : (
        <div className={s.arrowIcon}>
          <img src={arrowSvg} alt="icon" />
        </div>
      )}

      <Text fs={14} lh={18} color="grey-deep">
        <TokenIconByTokenInfo tokenInfo={bInfo} size={16} />
      </Text>
      <Text fs={14} lh={18} color="grey-deep">
        {fsn(String(b.amount), bInfo.decimals)}
      </Text>
    </div>
  );
};
