import { PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';
import { StrategiesFilters } from '@kamino-finance/kliquidity-sdk';
import { useMemo } from 'react';
import { useUserPositionsQuery } from './queries/useUserPositionsQuery';
import { useStrategiesSharesDataQuery } from './queries/useStrategiesSharesDataQuery';
import { strategyUserTokensDeposited } from '../../utils/finance';
import { notEmpty } from '../../utils/notEmpty';
import { UserPosition } from '../../types/UserPosition';
import { lamportsToNumberDecimal } from '../../utils/utils';
import { useWalletAddressFromParamsOrEnv } from '../useWalletAddressFromParamsOrEnv';

const EMPTY_DATA: UserPosition[] = [];

interface UseUserPositionsProp {
  filters?: StrategiesFilters;
  isDisabled?: boolean;
}

const DEFAULT_PROPS: UseUserPositionsProp = {};

/**
 * Return list of filtered user positions with tokenA and tokenB amounts
 */
export const useUserPositionsWithTokensAmount = (props: UseUserPositionsProp = DEFAULT_PROPS) => {
  const { isDisabled } = props;
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { data: userPositions, isLoading: isUserPositionsLoading } = useUserPositionsQuery();
  const addresses = userPositions.map((position) => new PublicKey(position.strategy));

  const { data: strategiesBalancesMap, isLoading: isStrategiesBalancesLoading } = useStrategiesSharesDataQuery({
    addresses,
    queryKey: `userPositions${walletPublicKey ?? ''}`,
  });
  const isLoading = (isUserPositionsLoading || isStrategiesBalancesLoading) && !isDisabled;

  const data = useMemo(() => {
    if (!walletPublicKey || isUserPositionsLoading || isStrategiesBalancesLoading || isDisabled) {
      return EMPTY_DATA;
    }

    return userPositions
      .filter((pos) => pos.sharesAmount.gt(0))
      .map((position) => {
        const strategySharesData = strategiesBalancesMap[position.strategy.toString()];
        if (!strategySharesData) {
          return null;
        }
        const tokensDeposited = strategyUserTokensDeposited({
          shares: position.sharesAmount,
          balances: {
            total: {
              a: strategySharesData.shareData.balance.computedHoldings.available.a
                .add(strategySharesData.shareData.balance.computedHoldings.invested.a)
                .toString(),
              b: strategySharesData.shareData.balance.computedHoldings.available.b
                .add(strategySharesData.shareData.balance.computedHoldings.invested.b)
                .toString(),
            },
          },
          totalIssued: lamportsToNumberDecimal(
            new Decimal(strategySharesData.strategy.sharesIssued.toString()),
            strategySharesData.strategy.sharesMintDecimals.toNumber()
          ),
        });

        return {
          ...position,
          tokenAMint: strategySharesData.strategy.tokenAMint.toString(),
          tokenBMint: strategySharesData.strategy.tokenBMint.toString(),
          tokenAAmount: tokensDeposited.tokenA,
          tokenBAmount: tokensDeposited.tokenB,
          isEmptyDiyStrategy: false,
        };
      })
      .filter(notEmpty);
  }, [
    walletPublicKey,
    isUserPositionsLoading,
    isStrategiesBalancesLoading,
    isDisabled,
    userPositions,
    strategiesBalancesMap,
  ]);

  return {
    data,
    isLoading,
  };
};
