import React, { FC } from 'react';
import { LargeInput } from '../../../../shared/uiKitV2/LargeInput';
import { formatNumber, getPriceImpactDangerLevel, PriceImpactDangerLevel } from '../../../../shared/utils';
import s from './SwapForm.module.less';
import { FormButton } from '../../../../shared/components/v2/FormButton';
import styles from '../../../ManageLiquidityFormV2/DepositForm/DepositForm.module.less';
import { TransactionSettingButton } from '../../../../shared/components/v2/TransactionSettingsButton';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { useSwapForm } from '../../hooks/useSwapForm';
import { SwapFormProps } from './types';
import { Spinner } from '../../../../shared/uiKitV2/Spinner';
import { PriceImpactNotifications } from '../../../../shared/components/PriceImpactNotifications';
import { ZERO_DECIMAL } from '../../constants/constants';
import { PriceImpactWarningModal, usePriceImpactWarningModal } from '../../../PriceImpactWarningModal';
import { Tooltip, TooltipTrigger } from '../../../../shared/uiKitV2/Tooltip';
import { Notification } from '../../../../shared/uiKitV2/Notification';

const { formatTokenAllDecimals: ftad, formatShortName: fsn, formatPercent: fp } = formatNumber;

const KAMINO_FEE_TOOLTIP_ID = 'kamino_fee_tooltip_id';

export const SwapForm: FC<SwapFormProps> = (props) => {
  const {
    inputTokenAmount,
    outputTokenAmount,
    tokens,
    onInputTokenChange,
    onOutputTokenChange,
    isButtonDisabled,
    isProcessing,
    onSwap,
  } = props;

  const { isModalVisible: isPriceImpactModalVisible, onModalVisibleChange: onPriceImpactModalVisibleChange } =
    usePriceImpactWarningModal();

  const {
    inputValueUsd,
    handleSelectTokenAChange,
    inputTokenMint,
    inputTokenInfo,
    balanceInputToken,
    balanceOutputToken,
    onMaxA,
    onHalfA,
    handleSelectTokenBChange,
    outputTokenMint,
    tokenBinOneA,
    outputTokenInfo,
    handleChangeTokens,
    errorMessages,
    warnings,
    slippage,
    isSlippageAboveSave,
    isDisabled,
    priceImpact,
    priceImpactUsd,
    isPriceImpactLoading,
    priceImpactBps,
  } = useSwapForm(props);

  const handleSubmitTransaction = () => {
    const priceImpactDangerLevel = getPriceImpactDangerLevel('swap', priceImpactBps);
    if (priceImpactDangerLevel > PriceImpactDangerLevel.safe || isSlippageAboveSave) {
      onPriceImpactModalVisibleChange(true);
      return;
    }
    onSwap();
  };

  return (
    <div className={s.formWrapper}>
      <div className={s.inputsWrapper}>
        <LargeInput
          variant="secondary"
          value={inputTokenAmount}
          label="You swap"
          topStat={inputValueUsd}
          onInputChange={onInputTokenChange}
          onSelectedTokenChange={handleSelectTokenAChange}
          defaultSelectedTokenMint={inputTokenMint}
          tokensInfos={tokens}
          bottomLeftStat={`Wallet: ${ftad(balanceInputToken.toNumber(), inputTokenInfo.decimals)} ${
            inputTokenInfo.symbol
          }`}
          onMax={onMaxA}
          onHalf={onHalfA}
          isTokenPopup
        />

        <LargeInput
          variant="secondary"
          value={outputTokenAmount}
          label="You receive"
          onInputChange={onOutputTokenChange}
          onSelectedTokenChange={handleSelectTokenBChange}
          tokensInfos={tokens}
          defaultSelectedTokenMint={outputTokenMint}
          disabled
          bottomLeftStat={`Wallet: ${ftad(balanceOutputToken.toNumber(), outputTokenInfo.decimals)} ${
            outputTokenInfo.symbol
          }`}
          bottomRightStat={
            <div className={s.bottomRightStat}>
              <Text fs={12} lh={12} color="grey-deep">{`1 ${inputTokenInfo.symbol} = ${fsn(tokenBinOneA)} ${
                outputTokenInfo.symbol
              }`}</Text>
            </div>
          }
          isTokenPopup
        />

        <IconButton
          size="small"
          variant="secondary"
          onClick={handleChangeTokens}
          className={s.changeButton}
          icon={<Icon name="Swap" color="white" className={s.changeIcon} />}
        />
      </div>

      {warnings.map((warn, index) => (
        <Notification key={`swap_warning_${index}`} variant="warning" text={warn} />
      ))}

      <PriceImpactNotifications
        type="swap"
        priceImpactBps={priceImpactBps}
        collTokenMint={inputTokenMint}
        theoreticalPriceDivergenceBps={ZERO_DECIMAL}
        theoreticalPrice={ZERO_DECIMAL}
        marketPrice={ZERO_DECIMAL}
      />

      <div className={s.buttons}>
        <FormButton
          isFullWidth
          className={styles.buttonWithTooltip}
          disabled={isButtonDisabled || isProcessing || isDisabled}
          tooltipMessages={errorMessages}
          isLoading={isProcessing}
          onClick={handleSubmitTransaction}
        >
          Swap {inputTokenInfo.symbol}
        </FormButton>

        <div className={s.itemsList}>
          <TransactionSettingButton withSlippage />
          <div className={s.statItem}>
            <Text fs={14} lh={18} color="grey">
              Price Impact
            </Text>
            <div className={s.statsRight}>
              <Text>{priceImpact * 100 > 0.01 ? fp(priceImpact * 100) : '<0.01%'}</Text>
              <Text color="grey-deep">(${ftad(priceImpactUsd, 2)})</Text>
              {isPriceImpactLoading && Boolean(inputTokenAmount) && <Spinner size={16} />}
            </div>
          </div>
          <div className={s.statItem}>
            <Text fs={14} lh={18} color="grey">
              Kamino fee
            </Text>
            <div className={s.statsRight}>
              <Tooltip
                id={KAMINO_FEE_TOOLTIP_ID}
                render={() => 'Kamino does not take any swap fees and offers the best price available from Jupiter'}
              >
                <TooltipTrigger id={KAMINO_FEE_TOOLTIP_ID}>
                  <Text underline>0.00%</Text>
                </TooltipTrigger>
              </Tooltip>
            </div>
          </div>
        </div>

        <PriceImpactWarningModal
          type={getPriceImpactDangerLevel('swap', priceImpactBps)}
          priceImpactType="leverage"
          priceImpactBps={priceImpactBps}
          theoreticalPriceDivergenceBps={ZERO_DECIMAL}
          marketPrice={ZERO_DECIMAL}
          theoreticalPrice={ZERO_DECIMAL}
          slippage={slippage}
          onOk={() => {
            onPriceImpactModalVisibleChange(false);
            onSwap();
          }}
          onCancel={() => {
            onPriceImpactModalVisibleChange(false);
          }}
          isVisible={isPriceImpactModalVisible}
          isLoading={isPriceImpactLoading}
          isOverSidepanel
        />
      </div>
    </div>
  );
};
