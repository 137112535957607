import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { KaminoObligation } from '@kamino-finance/klend-sdk';

import { PublicKeyAddress } from '../../../types/strategies';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchObligationInterestFees } from '../../../services/lending';
import { ObligationInterestFeesResponse } from '../../../types/lending';
import useEnv from '../../useEnv';

interface UseObligationInterestFeesQueryProps {
  obligationAddress: PublicKeyAddress;
  marketAddress: PublicKeyAddress;
}

export const useObligationInterestFeesQuery = ({
  obligationAddress,
  marketAddress,
}: UseObligationInterestFeesQueryProps) => {
  const queryKey = QUERYKEYS.getObligationInterestFees(obligationAddress.toString());

  const queryFn = async () => {
    if (!marketAddress || !obligationAddress) {
      return;
    }

    return fetchObligationInterestFees({
      marketAddress: marketAddress.toString(),
      obligationAddress: obligationAddress.toString(),
    });
  };

  const { data, isLoading } = useQuery(queryKey, queryFn, {
    enabled: Boolean(obligationAddress),
  });

  return { data, isLoading };
};

interface UseObligationsInterestFeesQueryProps {
  obligations: KaminoObligation[];
}

interface ObligationsInterestFeesResponse {
  address: PublicKey;
  data: ObligationInterestFeesResponse;
}

const EMPTY_ARRAY: ObligationsInterestFeesResponse[] = [];

export const useObligationsInterestFeesQuery = ({ obligations }: UseObligationsInterestFeesQueryProps) => {
  const { walletPublicKey } = useEnv();
  const queryClient = useQueryClient();
  const enabled = Boolean(obligations.length) && Boolean(walletPublicKey);
  const queryKey = useMemo(
    () =>
      QUERYKEYS.getObligationsInterestFees(obligations.map((obligation) => obligation.obligationAddress.toString())),
    [obligations]
  );

  const queryFn = async () => {
    if (!obligations) {
      return EMPTY_ARRAY;
    }

    return Promise.all(
      obligations.map((obligation) =>
        fetchObligationInterestFees({
          marketAddress: obligation.state.lendingMarket.toString(),
          obligationAddress: obligation.obligationAddress.toString(),
        })
      )
    ).then((data) => data.map((item, index) => ({ address: obligations[index].obligationAddress, data: item })));
  };

  const { data, isLoading, ...rest } = useQuery(queryKey, queryFn, {
    enabled,
    onSuccess: (intrestFeeItems) => {
      intrestFeeItems.forEach((item) => {
        queryClient.setQueryData(QUERYKEYS.getObligationInterestFees(item.address.toString()), item.data);
      });
    },
  });

  return { data: data || EMPTY_ARRAY, isLoading, isReady: !enabled || !isLoading, ...rest };
};
