import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useState } from 'react';
import { captureError } from '../utils/captureError';

interface UseTermsSignature {
  onSuccess: (res: Uint8Array) => void;
  onError: (err: any) => void;
}

/**
 * Additional way to make sure that user confirms terms
 * Sign message and saves it ether in the api or local storage
 * It waits for wallet to be connected before signing the message ( otherwise signMessage is undefined)
 * so it has a weird structure
 *
 */
export const useTermsSignature = ({ onSuccess, onError }: UseTermsSignature) => {
  const { signMessage } = useWallet();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isSigned, setIsSigned] = useState(false);

  // wait for signMessage to be initialized after wallet connect
  useEffect(() => {
    if (signMessage && !isProcessing && message && !isSigned) {
      // prevent calling it multiple times
      setIsProcessing(true);
      signMessage(new TextEncoder().encode(message))
        .then((res) => {
          onSuccess(res);
          setIsSigned(true);
        })
        .catch((err) => {
          captureError(err);
          onError(err);
        })
        .finally(() => {
          setIsSigned(true);
          setIsProcessing(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing, message, isSigned]);

  const sign = (newMessage: string) => {
    setMessage(newMessage);
    setIsSigned(false);
  };

  return {
    signMessage: sign,
  };
};
