// list of addresses that have access to hidden, unpublished strategies
export const RELEASE_WHITELIST = [
  // TODO: HUMANDONE GUYS
  'DDU3QAWWTJJBHywbbB12ERAvFJU9SQSFN5k3WXp94sT2',
  '75ntx32FATcjAmcyjZwwZfkrM3qt7ahBwsHLWX5FFxhb',
  '7zqdN3cHqaBcTqs8hKR161HchtHkVHCvaMgBt4nrBNJd',
  'A2ykVJUYoS45ktd6iCeYNZaVM6Krts8xssE15noxG6ky',
  'CsbZfCBwqkX1oPfEprNAvB1pdau2FCwPg8V6R8T1EUqN',
  '8tz9KYmEjhNxqagvJcn3F3vdM3soDL3F8C6tqeXQcCPp',
  '4ppMfheMaiVtYArXerinPLWJhNW7QG6h16vJGCGa5maa',
  '3wfxFRU4msye2W9HvR7NunabKHQvTUNQ9noKGgje9EPp',
  'w8Kzt1rKDgP33xqdXxAghP13dUNfGRajX9ZtD8eQXcb',
  // HUBBLE GUYS
  'GkeDRfHcACap2CM9oaWrb3QUMG7pk6F6EMYca8Lu52t8',
  'J3o4j8vp4Ju1xdTWMMpBudSZUb48i8kkN2uLrTLhA7fD',
  'HiKTsjBCGeZeLzbiDTgkFBGBw4t5kcR6yQpiLzLjSERh',
  'BKshcRsYC92aPXhhvYjZ5ShsdEDKqQNpCVjGJ1okJNiU',
  '8npk5rpnndz3o2xu7Mzr3AzToCbcaXBv81SJq43NGQ7Y',
  '4iSRTaE8SmpaCUwHnTVkDBpFGaqVJJXtbBvijRdFapgq',
  '5GS9bePbwrxgemqyR192MdspVRz9bS9WodHkYRTTMX42',
  'jJCXLzQFTgUCStu8g6FJTGn1RQEHLPkLwjvV2UT2qed',
  '3Dv4GUdfPbLzt5EF7WRJCqyHTmAP8Ptm9KaEmyAwJTTz',
  '8B15sodGdDomCQccvo6LS9ssjzJdQKjL76v2odUgh3rb',
  '2QNtN8CZyn1mthJqE8VGTrr73dqbMUbBN8LQv38c7zbs', // Elliot liquidator - laptop
  '3pL4JtDnzjyHn1DuAvT8pZUvRnigw8Kgd2mKs72xb1pN', // Elliot liquidator - macbook
  '8UDJ84Swr3SxvsS95dkK7zKdfZ5LaK3pubhtu97Kz88Q', // Elliot liquidator - desktop
  '883AnESJiUVzCnwowgaWCpXp4EGsK4JMVzUUUcjSSs62',
  '79nBgvQX8EdGxJbWmnVArrihTCZwSdsqLoyUzUHRJxuw',
  'EZC9wzVCvihCsCHEMGADYdsRhcpdRYWzSCZAVegSCfqY',
  'CeybTm9ZU2LXF8SSeuvuv1Y6RTT47kHC9KMtN9uoCMgm',
  '3UisWVun7EejkCQ27q8nkvvNDW3cHyBkeFrd9vi94RPf',
  'CheL7diQ3WNTUHzb72dqFAF9Dvz8ofz3q9pKqsuShJRJ',
  'HSpLeiTPLun2UxcJAEAMfjoH5DhbRNndDVAgcHVCQVsB',
  'AW1ErW4JgV6U3VsadZMLTciMxLKPxwM7cSx7SMGMoqS5', // Noah ParaFi
  'DRXH6EJBqWLdNJ42znwiwhqFEFMwrtjPkFCkjVKez5jq',
  '7UFbdFf45ULKiLdmHbcVMzckJWKFiMQhLqwgHxuWeXNU',
  'ELgAxEgaGCBxNcfLMqNSbBaYDWmHeirf6k439zrM2wmx',
  'B2LhAsAM77EkQQAjVtAyt34zd9H7QSKNQ3T585NR42ys',
  'MNmgru1D5bdyduRFiTzVZG21PpDcavnypoJYtnVt6eN',
  'ChHpRg3iN2QcEFV4zvHgqdebo5RE68SpW7Wm77r1kEu4', // NFT Quant
  '6M1ho7Xi92hMnz2L3UdMEpGxob13o8KvNoXVfwY9TgYq', // NFT Quant - liquidator
  'DQbNFD8gc8Fz9J1c2WGZ3QJ84P92kuc2pxajaErBBUDD',
  '4yK83uazibknuWrkRavNDVp8y7gDfAbsTCqAFaP471Wt',
  'B43ymL3qdX36Vsk3F37fRuwHJiEcQw6gdbEEU8ELMpPw',
  'HVjQwS4SaUgrrdyWH7JgoU9z74UC4QVNqzsCsbZ6bjhZ',
  '2VGzusQTEFJneuTWd7RQXx53vXiTi2qNYXx4ftj26Vvb',
  'sMBRjC4VXdZ9vWcGt3uJWuiq5nXeqP8UghxvwCfXD3T',
  '9y7uLMUMW6EiRwH1aJFSp9Zka7dVx2JdZKA3858u6YHT',
  'Ginbq8zgNExB97yLBhmJmyvrWkgYSYGANDcwyQfTEbGn',
  '6vU87T6LteSCgyUbpge7ZjTPfjzcXAyCZcRiX4pimPyC',
  'ARygwR6WBPvatQ2bBrSyytsyBgqrRDwxEuANWSSydfXW',
  '6e5w1eCoL6GeRK7EEtvBHVqiP4LJiRQMv6Uf6UkPtK8x',
  'Bdbvf9qSULbQTNJospcXG5jPSH2KvRxPv3cisDBPFqNf',
  '43KNLxcLu7WTNjbz6XRv8hUKmoZ6CNhQS1VpGrVU7GJZ',
  '9SVnq2sgKXyL42WUR6hFZSwD7mj1ghKEA5zhkGUmmsxU',
  '6oggkahqyzShFFLoSCPuwyPygXDKYZ2QdXQ9yiZriA8M',
  'CBd9omWgziKgBhmAqrGREDJqsSvM1HrarEMzE89zawMa',
  '2SQQ2fpf97XDbLddZhWCv55ZZnxgwmBbW5vY5oJXFPjv',
  '5EaFfu8boLJtgbR74Gs1r4KxWEgPQrLE3N5uznJvfvSS',
  '3nwKziBrJTAgftPE16j5D2fU2CHjSYBne4g7ghYRSa1n',
];

// Nothing special about this one
// It is just easier to manage by copy/paste from external list
const VIK_WHITELIST = [
  '3FQWnBwihvhe5GxboCbnAn8mxnUYnNMn7YSngUPo7N9r',
  'CzJtjAoVQzp4mpjRVY5XZEQtoQfSwB2pZvWZuDWwvNsw',
  '5FdyMymNMZxN95dKQc4R7qwtfVgxo8PmkYZhV14kRhHo',
  '5qqZcTCBQuY3ZG9ePXBLzx9gf5sAirDzPyCN8yEHxBGo',
  '6RGE9FVREJyccWYfUNJcy4uNv6tTRexgVioMmwejL3Mc',
  '7zND8YAtCYehNoa1JrfDLQZi44xJkEuPWK5b4CkiuFpo',
  'Bevy5eT6y4gss2j9ghgUnFQRU6p7BhjAMz4JvQPufSzs',
  'BKshcRsYC92aPXhhvYjZ5ShsdEDKqQNpCVjGJ1okJNiU',
  'DHHTemU7NWqNHfF9PDhKMc7JgxBt9xSRMDiiJvQNpvH',
  'EGHTxmaLopueTnPGJ5zFnNLLQRhiEDuePmU7uBeAbhLD',
  'GrxYiebeHj9Ctd9rrSyKy7Zn7drkpx1fivu97sGb7HQS',
  '27tTqhSwT4ZUXRfA2gBM5XCRSyb5CS2Q7cv7w7amdfLA',
  '2VBy86Lbs5o8rdxhDNB1CJ6bmJeoFqnnqkopx1doxK2M',
  '7divb5bxs5pEvQ1gV8etp3tvNzRvq45XpCNgwwjrmgaX',
  'F9j7kiH4gZA284ww1qgia4y1jLuZiGrQZw3popZJhvjH',
  'sMBRjC4VXdZ9vWcGt3uJWuiq5nXeqP8UghxvwCfXD3T',
  'MJ7UqHdkWZBnX81u7pn1YarZcKUCRrWJtDknRD6L3xS',
  'GbktyLu4aqxZ25nJaa3Hq4GHkS3wkBJAFWPEM8877WRy',
  '6LfRWeapnPNHUhu9ahHEU5Axg5WLApAxYAjnnzKU7TjS',
  '6EP3MYLKs16auKEayQykGAvS8cdJ2x7e4ybxrQkXrBgg',
  '2fx2EK5juKvEivVZ1aefqEMv6JQoackDWc7C17rrbjuq',
  '2P5tkJVpceLPdnejvc5DBGd4RxkmtouYfNkb9KKPsPxW',
  '4qQPhTt8JCTW38nVwB24UokYhYBNfVRy3w18NsUQpAyE',
  '5duBHexgVYDCCAusQFyfX8zugn9Ca65ud9aCf6zdZsRh',
  '5L8kWKxCgnrGgwJZsnHJZ3wCjdyoWm39NF6XfDJ38LsY',
  '7KWPGsT8LioJpuG5Lh1DbJepWTLBs4u2ezYgdxpyssLi',
  '8ddc12hR2ePg4UkkWcecd9ShcNJyHrkBpLDjd8Yjn4GG',
  '98LeePmSo6nQgcXt6KbGp1TLWmQCPbhA8pofTKFixcHZ',
  'AcNSmd5CxwLs21TYUmhWt7CW2v159TdYRkvQxb1iBYRj',
  'AGYENM9AZAW4FtySaAcbqjPxpaf5DHDjCpDYJy9UWYv',
  'CcKximff1A3apzz876y68zcPCbccYo4kvr4HRzZB3Uki',
  'CZtC56sDKdveyeYXBZfgqv79uopmUC5R2P1aF36EvQXC',
  'Eh9LSPMfCdEcehQhdJtNPA4A5zT8V1UmLq2eLPiXCvCC',
  'GtZdtmNT2gx3JeRtnemDxJL9jMyqcRgN5GTpnHdYqpRe',
  'HwX9F7RWZZmmEsGdPJGVw9GZLpcbjUayaQL5mxUt2fzh',
  '6pwQZod8spb22aMynNdMGgrrJKMeMPCqsAj96AQ96hXY',
  '9D6Lc18nQNSHcw8q9G7WDRkmDN2Ek5vvUZANy1EF7GFb',
  'AZbTm7XdXePDYCb5YiHigwfqNq62CLom4v3h1XP6Lkzk',
  '7155X9ucVE6rTWtx816NjHHEhGQUSvdQe8cAMuMfZjp9',
  '58sZ8qWbutYpiRzUb2AJ9LE7PtRewvR1zYLU21gQZea1',
  'Az4jkYSHcCskzitMzhbeJCX1F7RYCMJXpAE3daEvufm3',
  'FmP67fegJkkdMvumTJ4c9hrDBceFTBgNKYe3M2zswBcF',
  'CUZsagCbdXTQhb99hyyow3DeUBJhEX4NjrF83x6go96t',
  '6sPu5SCXLC9rQK9YzxYSr1uAwJtm9PGgMyYvTJ1zwQZ3',
  '9C2VyFTf3bKLArgNmvufcSPCVNm8joh9QZuUToG6nuBS',
  'HfcxeSnyKuFR9joE8wQaDgfh5dwXGwQRTRbN7xYfpPpB',
  '48DMo7CLbdYPiSnjQhRhLTrpGyaq7KBCX7yyXxUwu51C',
  '4AiakqgEuv7BVrEh6Vzti7dZFYjZeKu15acHvxdousvi',
  '4saGeNxL7e9VfgzA4fgTaNa4B2pRGFibNTW3RbvXq7N1',
  '5s3dtdEqJE8dtQ8JwbcD3nyPtm7irDPDF8sE2MsyDbRg',
  '7CjgkpY9Du6FsH7DbHCA55GEpqKTkUA1Vv4vD5bLGuMy',
  '8uayumj7Hj6wbk1yABmT5s9gAKSx7Vufps9DN4jaY8x2',
  'AFFrLgJ2wJwb3tTF6J3sde7b7a1Ge3oY59bWVP4ehoxH',
  'B896RFKnQ8f9SrLFC7MYZQ7z1AsXedCz6AMMvW7icvLE',
  'CiYbhpXgtWjC9SjQynoXo5oVzGoy6HMYoPt8C17xj5Aw',
  'CYEsgm3nWmgLChgvqPQZLTzr3bdbiHsDFwfTTner9sKZ',
  'DMinaS7MkJatk2A7g6m5xxrJbP3Yy8TJNaaxTZQfXLXD',
  'DTjNdfYmKpwGjnX6UPGvxwXQ7PZEGM7M43BQLtyASnvh',
  'DU4EJnA2z5HbnW8CzAuad9mVoe3cGycjU2HgAksTWVPp',
  'EtZWqqu88Z5Cf31Y8WRTbC9KZYdB3VT4bgQHzqXiJjgr',
  'FdoUL6Y5BqCpQffu27y734PsjRk1YgWkYimaRW1p7zwE',
  'GiPhxjTYvx9rYTJtdSyjUXDS1hRKT7AweYWJdzLkTsmF',
  'HhLJA5EWvJygtKksWp9xGYFYgdSdtrz4Mpd1VhPzC5Ae',
  'J5yPjUe5CJtn5QBFMR6hJomN7GCaFZhNYuUrnCsTMavX',
  '5witmNJxYCk3H1f3h2GMZ7mGgeFcj9N8Kiat8zHCWJ5e',
  '9s22VkAAtScbJtVH1vzdLumWjLNtFscrfavdUW6z3ykL',
  '2w1nNeGnWKHi9MeZFZ7TzHegRjhg1YfSVTQ4vgMZ1tH7',
  '9ZmsMUwP2go3Ue3RqxMs6CVEARaCKGMMYbyegzrVsYQJ',
  'CBR4n5hE8tfPQt2HWThHmhmrZXm5SZQHaXQi4TBfvh3v',
  '9Cw3Z8GmqaonweVtVW7hXqnhMiSt6igh8jGPEfMSMzQH',
  '6g6m7HFKNksEexTPMXfhgdbpUyM3pJPWPyPdco6CeM65',
  '7TbFd1vPtFiLUP7P9VtGRiirE4vFFLhJwJb8UZhBREzC',
  '8DwH56jhaoSPt4sLArhUUAmqcCjQfApSBGQcuajmDVEE',
  '8LFPJrFXTsKCQB5Ams9CsxPWiVMJvgGC1xj9m4FGxFuS',
  '9QXqs2v22VmTGMdNPXEEBPp3e6NwM9enGAmNhV3LDUZ1',
  'As63vJGYr8q3rZ2CrazfwMMNKHvfaosvaCmyRpAUz6KQ',
  'BoLaZg5fcBPjRKeXNmdGmdMjNwBnUGVg991rdr24U342',
  'C7h1Y2Cb6WgEvdevwtbCou84QAWAChcYQSjaYQyFGRDz',
  'F97jZyCR4Jc3ucshomsKrY6j7AH2QSm6mPTJ2qZYbs7F',
  'GuhK9c2PUT7ZmmKLoNPgmyvYSdKH49LXGXoephjdFYxr',
  'H7G6jdWDXhGfyQanrc9gCRZwHk6j1WegEP6UY9pfNjqw',
  'Hy2aHmEj34Sn6AgHRWP3Vx2Z75AfsCYuAHfVi7bmcKam',
  'LcBrLhrrJyNUGBZQ1jhPZbLYCx1CE33np8unxCkfU7m',
  '6dQabrWGcUmr1HNBxb4SMsuQV9spaPt3UxNn3acnTuD7',
  '2sAmWtV6U3SaGAjsQzQz2NCRpoNbQQFDY6xrK2Za8uZh',
  'ESeAFDVft5p7R7i1n7u75r9Bp5ESRmHwuqxE6Hz7NDu',
  'Cp8az4m263nsA1ThWXsip4P2C9g9NxxjoZMakgkWD7bt',
  'DdCzy2BSmcVrtQjBFojjPb7shMDQPX8Ziu1XE17U8aq6',
  '9bR7ZBYVupZ3NzjhjRon1zD9sJcjQpLeuVXFjTXXWUGi',
  'Bn8dXAQUZnJbuumZocg9gWWda4tFxtFCwxPghoGZV6a8',
  'ASFCGfuYZPrxFCcYRfwkQACv73dsfG9JLnA3eUuDg1bL',
  '2ikXiekQJfcbunNUickFEtgYMMpao7sy3KFtHzg3VNEE',
  '9hFYKCAUNzRkrjfgUPuTKHuxEcqE1fQkSaH3m37brSAD',
  'FWyGGtvnKcAmSwaxupWmKNc5h9Cbes1hdP9KC8Rx8EYf',
  '9hFYKCAUNzRkrjfgUPuTKHuxEcqE1fQkSaH3m37brSAD',
  'GNHuY2mCAteZ179hgpTpmEFChMuwZab2N5ud83VJYWSC',
  'F8JriLuGCpmCnGw9m9irU5Q9z7PLouiwpHcdPYbBzbua',
  '3fAtuy5faF9E1jGmLSsKG5a98abMVk5bGWQwKkansU8Z',
  '3X4hZYHdwRDbXsHyRmcFe7mFu3DKp2ikVmTLyAkcf9hi',
  '2vT5SMhN1xdB68JTVSDgJKSN6jBfAcX9FkEAESrgVSRj',
  '4MEZmed5D74jvJDayH24bgnJgFWiX35D25CbkD3sz1ny',
  'Hjbf2kgBEzHf6j4bRZ4DDaVXzRhBm8Tr82ttNRLTFuuZ',
  '75wwpGLn9CMKs6SoTBoHELE57jhthjZvwd1pRpxDhXQU',
  'B7DCv27AyHkivriWX1npVKYj8nCiCJ1hymUe93cP9Uet',
  'GRShonW8WYzkdhV328EiKHktoj6DHuACZf98kGdrRRgk',
  'Gm8QV6YoyeycPmra3NXYQ5ZqwoHwenx5CxMftMeonP4n',
  'AHsdQNkgsZpgx7XGZE9GLb9siBkE6wzC2dNU33ksKSiF',
  '9keJATrMhyYGRLWk7VGJvFmhtgvsMJCPJZt6gTdVNB66',
  'CyGRdaMjksayqV48bhX8zXqJZhovKDeQxty1Ba218E2b',
  'CM12oduZT5fgXRrk3G63HJppQhoL2YkyNbesr8D2pap7',
  '5WyPmgRHMLJzgB832LjqQogi2B5ZbGGHunHXBBpRUFZ6',
  'Ci4ZWwbc8MQ37ivMh9aNhmCvDYfufaHpEw9urMARSeee',
  'gdwMpTbj3XjfaTWJdv4nV2si6EhH3UP2JJoeBN8o4Ln',
  '6vcmz4syQFVZQcvMDtMea5UekkGichNxH8mbKaBNvv79',
  'DPdiXTP7mPQzUq4V4bktfX6Py87hbJ4aVDTeZrHGME2a',
  '9AygFkr6B6ULfKYVAou5ZC3AkoW9U75fX31NQF4VDHqr',
  'HNUDq9muq6b26tQHrwbQo9NdN1hzHKLi7ZANty5kcomk',
  'FNQVDmTdmWtTaswoL9ysoyorsHZvjRbNsbAdgmcREHVi',
  'D1bcSZjzrXBi5moVjyowsKZM12HrrnM42v2buLqofzdD',
  '2Ee3REQGjjZnpEUUDfoktwnPkfCSti4MvVeeAPmBAgFG',
  'GeQRwYLE5d3j7C8E6MgZRJmnPmuuJtADcJ2qzhqXAj6e',
  'E5Vp7NCHuZiZdLLB1ne53EYodeEZf3Qin9kzHYVMmzYm',
  '7tH1k4PsMu3sNUYJxD5ezxhAmYYXyVVZz5c3dbaxcvUV',
  '9XWGAo1zAZC1TKEy6RTYcoCZ3A9YPibMdxV8jRD6yTNB',
  '3cz6pf9KB5iqLa6aFDa4qT9K2qJ336PPbeJi4c7EYu6s',
  'GFLoA2sbJ1qtmYbtWPkKvunvyQ6HTnNxKCTSJz6iNke2',
  'CGS71hvd5UwErACJNPRgG5a4J9x1Ue5BFKe1Bz3rkxDL',
  'Entxopkn5TgtcRnjN1n2ght3cKp1DZpmEUKzRwHGuAz',
  'WUwXsyw9NtszjteGNzGh28Tyk5zPMfMFmwhayHT1XfE',
  'AnHXWDJGMUEf4m5RivAeciS3tDrP3HLNaFoTDJ9Ff2Hy',
  'DPjs8fLVPhbsb3BskcwryndbxBQcVsm4eVKTjaQw6V2h',
  '45Wbq973x7pyxzjHJfJbYCi5wFKHCEnrB5dYjTWmJvCx',
  'DbugBLjpMK2ATPdEDjRYCZSv3r5guGGqYinFQkWukTyf',
  '9iTCmvVPq5wi9pK2HxuTre2gv5ZeTewuZysbnhBRiWKL',
  '5ujxz9TS2GaqA3V7ewXyXpFxDtDTto5CALSMg1JgmhBM',
  'Aw83RbfEPig9K3QnXFxVJjzMJUf7odCnyGNDwUfYBmwY',
  'Cj1jScR4V73qLmvWJiGiWs9jtcwCXEZsmS5cevWt9jNc',
  '456BcisJF6adoTuakYHPHmFVxCQd2dcxKXBwpG8a3afL',
  'Cn3Fg8bhXoyAT8EZvuV4MxLBmCSP9rrMFiCWm9cMFTef',
  'Euq37cAK613RTVVLybAu2MCgBLZjKn3NPMyMfMp6j51i',
  'Cn3Fg8bhXoyAT8EZvuV4MxLBmCSP9rrMFiCWm9cMFTef',
  'GsK2z6T9EhmiYJc5e8LZM43f61Faqce96vfwtjepbST8',
  'FSoXJiXt1iQTUpRt81sB95zLYsPn4jsMcxtv4EH9L5PJ',
  'J2AC2uCBxfzv9geVgF15cQazxsVrsvtWnGN2ZKoFWeEd',
  '7cTHcKyxRcP6LuFUWd69JJeodXfcD17hxLR5tifNAvp3',
  'E2utSHwMPwp7eYMn8rf28L45UtiXkcdPmXECohQ6qKcr',
  'E2utSHwMPwp7eYMn8rf28L45UtiXkcdPmXECohQ6qKcr',
  'GYVdAkbUZgwAZY9woR4xqAht4QTy9AjmnL9j3WpM4sYa',
  '4yp1mzLvTzTUtUG6uCbsC2n21MseQejE6WNgRN5DrDtG',
  '2xFbZtJjR3szsVXrjVwU35uA9uk35xujNufdUzPFsqFL',
  '6vU87T6LteSCgyUbpge7ZjTPfjzcXAyCZcRiX4pimPyC',
  'EfZxgk516HywdU71Pbf7StcZehKWf9h3XTA4kdYZUduB',
  'E8TpnuYPGSoa5MVApGBDVhAReDnDZbEcorPpw7Wh5WhP',
  '8rpzEP454rK1JUnCbj7LWnT4TtxA8R7QtZq97qQTU44L',
  'Eh7sJpNQek7dhGq45KWfx1spuxVPJUyCje7NXAVVtK2S',
  'DzNn8yxDEiN7pMsyVs6wHaAkDHtUcUbemZvUBS5G5ife',
  'G4zZ6cGdzj7J9ZFnte9dzgQ4T8VWJbEFsnqkS3bCz4Uk',
  'ARygwR6WBPvatQ2bBrSyytsyBgqrRDwxEuANWSSydfXW',
  '6e5w1eCoL6GeRK7EEtvBHVqiP4LJiRQMv6Uf6UkPtK8x',
  'Bdbvf9qSULbQTNJospcXG5jPSH2KvRxPv3cisDBPFqNf',
  '43KNLxcLu7WTNjbz6XRv8hUKmoZ6CNhQS1VpGrVU7GJZ',
  '9SVnq2sgKXyL42WUR6hFZSwD7mj1ghKEA5zhkGUmmsxU',
  'HAE6F8D8QkBEj4tgT5tt6PvzFrLXUQoBnpCKu1HWMA9a',
  '3UisWVun7EejkCQ27q8nkvvNDW3cHyBkeFrd9vi94RPf',
  '5MaagVCUFgZx22EADV7iZfCGPbrNSUtK2sp3FeYuoY93',
  'Fw4p3XsYELxFyUgQRazMZmb4LXkQAi3evr4XN4jTTcPd',
  '7JfkjvMnwTvZNGNam2RgJ1BBxMpsqaQRaWmvejig7uCa',
  'CN5zRzJGNqp5GNyfWjwSKeg8BuG5XJezAHMHfV7e69TT',
  '4CtqY5cnoZWmskzgLsJDUxkhUFk3nw2BdFRviSgTWfmy',
  '9zXXhS2nvg2SbKfCodrJTvdeQcVGwncV8sC6VnnpmpF7',
  '3TUcXgJp6zXPr6rbcq37c2xwTHoAq5UyFkzBg48ng7Sf',
  '732nLhuiUYKcxRNGxpMr6CKPEBKssjkbYosZPSdz6L1K',
  'GpVtbNtHYm3VTGjn3dwNt9vm1fQsLho6QXCwukf8xYRv',
  'DoTZYM5SsJtayGszMoYfvq44sQEG1vQmk9MSF6qGYXZU',
  'EyDNHRKnCqsx52XGKSejFpWbkvuAeBDZFkJotQCbwsa3',
  'EGATQjPbbySFTiCHLeVZzt8q3FoWkNCtqBxAJSYNvRje',
  'HeYiTogWrqkCzAtGxwZSE6c4vASTEDeWekaGNw12NDf',
  '3mzLZZ4rzdJgNksMafjgYfwrAync4zBzLdqsUbx5Hxsp',
  '8ofFH87VG5L5oU4nWaCPPcCRYzn8C9k5fWaBeQ6oftp8',
  '34QhEARKnCerqJ4RWNfZZ8qoyLX9GzUthZSLKg1RHhph',
  'E4UWPC8yezHfcQfMxK2HwKnjeRjDSMZmvoNAQne1HR2j',
  '9apnHjEQ8enLaPLxP7z9VQTphWE4nWqZcyHJYocQMpSE',
  'EFbg3xmv8bsgyoskYkXfmtAbXjfDhF4pcuBkg2SujpNX',
  'rewiRMz7pmvTAPrR26ry8BQ37L38WFG1Wf2sP3CbUNu',
  '4RTfjbSWaYHa7ZfBuMWfwhdYEguGXmfZYLUaX4qcmqt5',
  'BA7GD5CwjAg2KkXJt6ipQsEvBTXQYnspRsBPu34f9zWw',
  'G8USkdS7QPHqVHRK6Y955WUCzC2mBGqUg6R2YUv1fTXQ',
  'S87Cnp4MxaMVzcTHWcrg8e9j4zN5pdbHrC4BZBuffEf',
  'GCW6eLpFUWo3JhSWyvVc1Gdiq1MGCnSKJsoCSuunUxbQ',
  '7Snt3CdQsrVFJqGdKQi2Nzf2j8oXh4JjpvjTy18b5mrD',
  '7m7SWGqqvUb9cCDJp5drYwNTfKTDYXoBvDgGaY1wqvyx',
  '51mzeBBU7u7cLAwDyZ4cKVwztq9BEPrt5bVvxxLZTp1',
  'DYnBn6fM1caBKNaKhjFYFhBuyXUAa69gyRyxBNxpM1bD',
  '7Z7ThsXsvVKKJUVMdZsP72p8UTosvEKAHx3KuGPBgpJB',
  'Hxx57Rj7k4n9zFVDKfrhbmVrsCMsPqhnn3PQsnSsE2hq',
  'BzHBm6X5HNehR5hTLjhC8f7fE9qFfbMyJvzr4Gq9jc19',
  'DrXsAqeRUBvdbbGjbJSdoqBMvoKp8A6SgF4oFS6QbRmD',
  'Dark1hPfDF7aPRJDL3KS74mnwyAxwL4zbFpEQ9EnE5gp',
  'FMpyNybU9kdX17qsiprVSDJYPM4XmZMgVDx5dhhJeMio',
  'DYnBn6fM1caBKNaKhjFYFhBuyXUAa69gyRyxBNxpM1bD',
  '34Wmv58UF8HUmjdcWdCZVU9o7h8N5h2fTozqRwh9mucM',
  'Hxx57Rj7k4n9zFVDKfrhbmVrsCMsPqhnn3PQsnSsE2hq',
  '2NQy9oQcm5XP6QEokXZnTYhsW76mpNtyzwShJ6ivWjWQ',
  '5VqZmbi5WG2E3zSKDZnnhFcTFsSE45ivqifswW46yGkL',
  '6Wr6rqZcYvtchNrZGxvsyQ89kxqJ5qRW24jnETcGnjhg',
  '5MbzYsMtzJ7i4qDrpSdjiYtQgJP6iavgzw5NQnffths5',
  'EoR3rYG8W1hnpDA2Yy7aFhXhaY9svW6QZrDF25GXUSjK',
  'GBRXZuAFwwxbLDFaqTm11qZogpyBVgp1c3P9XRzgsfLs',
  'B7qbVcBzwTMbQ3i8nVzoS3cXpryy4J6Amf61EaeGnrr7',
  'C4NdFiioFyMin7pbkw1jy9KaUwEATPPPWc4va9c2BJ7Z',
  '69jfDL3GNCk2wmiWx6wntGzUeCLB1eJDoSaEZvwddHSL',
  '3sSaxsDthaDVrUJQ6h1kVPCfvasoJFsG4Dbvi1LL8fPk',
  '3ADA19MFrXiVzeZysfy4jrHfNx3jyfTg3qqJ7yrnwLZ8',
  '8nZnWFwRmjCQvBNVrK4fohkCj9vcKixP6Wxh1NvZPxdf',
  'AhvuQAQyoBjNRwq4H1H6TyyMv8HFCtdqLeCw4AqfWB4u',
  '9XocwFbLkChJdLuj8gQsJRmEyvjp457Z7TkoZyWFK18E',
  'GCW6eLpFUWo3JhSWyvVc1Gdiq1MGCnSKJsoCSuunUxbQ',
  'G8USkdS7QPHqVHRK6Y955WUCzC2mBGqUg6R2YUv1fTXQ',
  'BA7GD5CwjAg2KkXJt6ipQsEvBTXQYnspRsBPu34f9zWw',
  '4RTfjbSWaYHa7ZfBuMWfwhdYEguGXmfZYLUaX4qcmqt5',
  'EFbg3xmv8bsgyoskYkXfmtAbXjfDhF4pcuBkg2SujpNX',
  'B3beyovNKBo4wF1uFrfGfpVeyEHjFyJPmEBciz7kpnoS',
  '3SVeFPSRrhWbZFw2vvv8hf5H5t71gL7wLRQdDnUGw4WV',
  'A6bXwNS1icE5tUQ8xWkLWZ21AkMheajZuvoAyYj6D4dc',
  'F8JzFjMACucRyPamEcPUqwj9VDYnYsjREY4TJ7RMTnyT',
  'DVUh7s6gPzLHVarFWeSL8iNv8EKs1MEaufBLKBKbku7x',
  'fnJsayNVPTcrY5anbqeLvweAWRhbKemTuN1Bavh6zAd',
  'ADH5SmGiRLdWmMSKXonXeSao3tipu3b7JwjCcFW712XB',
  '6SMtbUKkRzr9N2PUfNVy4i9GjhHu6iLHMVGP5JJLK58D',
  '4Gdq4JiLm5Gq2ucRQVyjPRwgPSTv79QrXLqwXZ4U7ppG',
  '6RAiEmKx1oEK32HjD6Ug9k8uSUPDcCZuQfHkrCRDPLtK',
  '362TvJVDBZngjD6pBccRTTcZP14tZFAiGmDrmfyy9ZbV',
  '7ScKvo62cbTrx72jpXHebqTE8EdKxazZoHZUfs5xdVEe',
  'BUdFtHtcXPoZuH1gTL5DbYw2E655cSYrDNKjSdxs6o5a',
  'Fy3nyKGXC6MegqJdGfboeSSkgwt9FpbUVB1mhTo8U6zG',
  '4Cwuscc7CbaHwxe5gemdntgjctHERSvugufq4vRUp9Vi',
  '6uRhDGsmwE1jAPuMXqFHrbxcvZExgFNhrZ9Kama95r26',
  '6d5skLdLEwNQ1j7PDH9Liemo6pohgGJDAd5fWvuX5cyL',
  'EmZHHSGHoLHibQEaCabnNn4sRofr66DMuNzPTyeMgEA8',
  'ZPDy445bUxmKcJsoKtiqfeP5ucqZ7o9aELjJYJzaMLy',
  '3or2s28yyFtCtz3zqKs5YZiaq2yWuuHu4gvWSURNHeSu',
  '924yfLMgGgJLEfm3EFHeSTvZUePwkWJX6E6sWxuwE5KN',
  '6BZrH5cqooinHjMbyWRAr6SQAQj8qezHHiaiaWCteT4i',
  '3or2s28yyFtCtz3zqKs5YZiaq2yWuuHu4gvWSURNHeSu',
  '2mJEWz6SxD4LPYb2BgBN6rzZnM49JtVTd97jsUgvRAFj',
  'FeD4hxEmcMP2uPtuHTqguXhFJn2V6r5fwrybFw8Kx2zw',
  'GpW4ppx9naVNAsFWhLxp2eocsQCHmNBSZrRf2DoXAyBQ',
  'CppiZhfp93TfmcKT3LQfKyhNmSadEdBRxgqCCQCQhuK6',
  'AiTehtQe3kM6yPs7tXDdZwhL9LuQiLcHG7p1ZsJy3LoD',
  'Hw1NmgzFvng5QVoe4VDztmDgqPmXjMJDVGSrRyJYAGgs',
  'AD8yrKjwkoJV98qCDe1HtfyXHw6XjNDLbKPffDjZMozY',
  'HDpjQy21uPpniDgBoYZjA5USQqerGKDzaxXNi4Yh2aCu',
  'E25ks58fFpPgL8oJR7jiesNZdwpmBp7rfsTfoEh11wXc',
  '4J9cM7495c9TLD5owjczPyDCMTX6r5dWzrRQwVAVGhKH',
  'X8vGAK6A9BQmpMhGt8uRAmxyX1k2AQnQR7ngDLis492',
  'GmRC6EhKtBEcKM1bjCBzamWDy3qmP5z2Kc9tYjQuc2Pn',
  'faraVvDajMs9FRwLvjRKdpfvDwetTBqgdwy95MsQ6VZ',
  '7M8HQYdgt3ux3d4w134ho9EFZq41z4xWRB53vwrVFvFW',
  'XqBb4MD41bzVMgXF4SQwELKdpTPAx5HQ8SsjmRjVNum',
  'ZsAetCXJYzLyw7pKWYWzGV22s5fNbB1rFxcg9PX6o2L',
  'ApbnRqqSaKqMgUHoUMYBqe8QGGz29hUXwHERjbxCHF5K',
  'AsRKakSwnHaX4mg96MuiB6iRRtafSJiKCxBKPKSihcJC',
  '9QB8GrNzoRhv7thcjmoQViuMY74PLKhKV172vamkwKWs',
  'G9GmkKh6eb6eWhHKZ4ugnEMmJSZ9GYSbD4xLuUSbX1G1',
  '8KzrbHiup39X7Zw9grvh7gS9ZcA1zuhLGqgYguSjwnD6',
  'Asu91funkc5aSqPizkG3gbMy1mUvff6ebsCMNfnubVSL',
  'Cdmi1oGQVjTGs5NMJ17Z9mHDdwXCzavEEQ1LJJPfRxgE',
  '4fHiVmWg8zx6SesL7SSDSBaSQ8rqrRMePM8GPkGGEmcx',
  'zHaoYF2zBq7UxvJy6WgqvTGUPi4EnQfomjrBhQBYXqV',
  '93z6LmW5K2iCwqfKGuzJo1mEXbogoUBzWRLkLnBm23Y4',
  'Df8Rmm7nPZAkENidu36LdMb7A92UBV9pGagLUftPkjnq',
  'r3cXGs7ku4Few6J1rmNwwUNQbvrSPoLAAU9C2TVKfow',
  'Ajqd2VzN9D2Gd7J93RFtQy3UFQhD2Stz3svPPtg1Ey4H',
  '92fSCwVm8mVBYc2WKsRXvSwBgHxJ2WPq2ro32DgXZodZ',
  '37AFxqiQf2ogY9KAC8Q3VboTokQR7XWkZQHBEgD2m9J3',
  '33h3TF7VqUVf1642HLgUiGBC7a9yC26NEp82Zs5teLNj',
  '39YD5rZrsz8mSdtyVCfwaG2cAh5Amq4wDAgqZEtgXLRj',
  '2sYFFzCz5sK4LEwHg2ZkNYUgqYApGAeafZZnW4UzMDRs',
  '4cHPTZaie9TL6qddaeTZEHY3nbm87Kt6UiQPxW7Go2DF',
  '6pwQZod8spb22aMynNdMGgrrJKMeMPCqsAj96AQ96hXY',
  '52Jp3kiVYQeSogDHxP55t3AGghAvK85fEAJgdCh4qLBi',
  'BihbFqVvE9FRZkpuvMujXsnTiWFJU7qbvcNAShaZZ8rF',
  'FiTRA45RC4NNCEaGqYPbLqpyLwspLsLuaJzgj3fBEQiZ',
  'C4mCjrq5oPFD7jkgyfkLG8LsG2jCb1k9gVuBGgKmC6k2',
  '4Jf7S87hbcURYqApEV2yr4cCQCJunFeeSBTLKuCtQXgD',
  '3244qz8ZWoaKhvHehXQo2nCqKozq39Zi3BsiQNoF18D9',
  'FBf1J4Ljadu7oFwcxm2EJFmJewkiLGjVFP7uh4DeuR24',
  'B6vzrpFrPvT77RLT5oTwBvqyBetcmMKW4bMNKZVpqib5',
  '7gP4RHnECWWXJL1m3XbRPpxx6uogjDBHjb3V2XabF6rm',
  '52UKKdip9hxf6g8oFHWo4L7KCzW6C4UxjMAJ8idP4fZW',
  '7KJGpvi9KdS6eFNwofcVqLQeqPTbUnGTcQShJzsqdqdv',
  '9Y2zEqcXjr5zirQmLDK6WjdNdRLxuANKYsrSZnxYRBx7',
  'GuUkrgBsfGQga65UijM9tL4U7zmJQVJu77YbK2YMvjsp',
  '21JoEZkDDsmYfvvFZDLrsscpuVD15mghddU7QW2So8yw',
  '4osxJxEawiASN2pU2eR9imuzrzXJreUjpqWpu82TuBDC',
  '8kj54eAztQ3UzYLdCDGCUjz36KNwJbzKWEj51u8Jfj5w',
  'Hgmncn9mXw4DPZWohvjWw5ytNeQnjb8Rp9QQsR6ANwDv',
  'J7c8yuqjgejB9tqLnQqftiib5PFLnox4cZcqNMrXBDiG',
  'DueyMo9sWJ9gPqyw8G78NK55CXEAM8S4cc1vpZpruukq',
  'Hw5jvWgRXD79zuTdZ19xQKRo2r61s2RLxe8SPh7KAr4Z',
  'EUt6y1XMszVcAeHrNjpB8Q7M2sXF3JJ8ogw5TyGZdqo6',
  'BtX7fbScn7AHTWXoWyMUm4ZdgxwtXhp2wxSFYdAnTCTG',
  '5SkEby2iNMNP9EcGMKQF69wfhT4N1afmWADt4Qsa5bb8',
  '9zi2Jch1NQUnfHY65464FwgSrqoaTB7amc1PUUteGuGt',
  '6SYADMjYDjodNartYK5cPdsAG6PvyC8KkiY5tkFATwNt',
  'Fvzn36f8x1BgmkqBsdjnxeiPRr786AWSU3t5jJJUMm94',
  'GjcM3sU6B3sRrcesMZGba2GBWe19ncAqbhYF4LjAStNo',
  'HsuSLwMoVT9pJVGhWyh5UbVs3Cz8KGb3aTdCag1EYRxJ',
  '6peF9TwoKV5DXyhV588vxnRf95aFMjyvtWEhAzvwVaos',
  '8QfC5uHUFaZfUDhPi1Vi8Lo2adGH2RpphLFp4DqhGiD7',
  'ABhqt7xNAPR4diAw9pMMPw31NufuRMCFkSJh9aPdqtT1',
  'GgCU9MPu7hYH2aHXFKxY4sbkXSDbXt84L7c2aZAhPPgm',
  'APj9tLMGSp9n5Ycnau66F2b5EWoNN1z8LNy2L9i5ACqw',
  '3FMyWeuoS7WU5KCKbqoXZ1KtW7YUJZ4CbzZwS1xsKm6x',
  'He1kRXCviqqXvnPNPHPQmKmMKTLXk9ZQrjHCyArAZdUN',
  '6wj7TU2HiqxFFfoZzbczUmaFHTJZmJw1gYzQteShSaXQ',
  'fzaLXQgjUvizgsStq9VNsk37zMaduZbvtyZ3Tf1wxfC',
  '8nHodResQ1WoXhFYmLss7ynTGgLSBkfeM8wMGUVYUHto',
  '67MEoC7mh5M73oyWYwsLofLTq4RKN92qMRjcqaxXa3Fv',
  'H4Hq9jpjd9uZvnib66wQDeCZzaCjjummYozc71ZNbn2j',
  '5jP8xd8n4Whkn2zNbG2G9vh8yqUyfZa2FpDjajDrbA41',
  '8bUS86FM5kkcz464x6gf1vgnk7R2VBK6ZBc2eqZnJAzQ',
  '5ayF2Du9Ju41yv6ALW6bEUH9wxFoaZ3Zzhkn3PMHfXg2',
  'DVTw6AtRtxWZqtwK33kXfs1Tnsrp85ZeJR49o8stWByZ',
  '5YXCR5zWX8Ew1uPQoa2Gr22pm1MvHViQvNerqSBwyw4U',
  '5QNr7yF4pB33SdRYdtHJXFxFE5DMN2sU7AQfAZjqpEpX',
  'J7c8yuqjgejB9tqLnQqftiib5PFLnox4cZcqNMrXBDiG',
  '6xbV17oHY3srdRb6jPmVWwUug31Nb1wC6td7XkxjcZPk',
  'Csf5TUNZxKEVnepSE8oAWqgTPi7e1AnxBBLHG5LQWQJr',
];

export const V2_WHITELIST = [
  ...RELEASE_WHITELIST,
  ...['qjNS2UVdffKKBcajV9wBJPNAm7BaNroocPZzQTyKL2q', 'DgX6JED4wPMnFV17xDPaGbLjfjTKq7vnXX25E4VAkAqM'],
  ...VIK_WHITELIST,
];

export const BLACKLIST: string[] = [
  '5pKrMXARbv6iNbtp2uEsZhyyTpR85jRrEny5fSCydvbW',
  '6r7zrNT4X446e642CES9onupCmFxqqjQyLuVcmzGkUfb',
  '4pDGSLgPkw4TYnXqnthFxgqgm6aUyw1ABYo4LeEisdAK',
  '5gFF8fdjTjTza83JWBv7qnRVvLR32yPp4Wpx3ycuxk5L',
  '84L5pKr3uCdgcNtuMB7kw7WaJJ3FkxDsD9Nfpy8L55ng',
  'ABj4b3SL6tc4FYVopsY5Ri1N38PGJgR2qTrjeH1FQLK3',
  'B6AftJvLEbpnoNH5aMAFsDfVi1A385y3hWsfx4FpZYCJ',
  'BmgFSsNKAKPgpo9U8mKZJ8kgCmHdnKX31UkpTMJaE8YB',
  'ConEPD9Rf9QCVHo3PKKD6VZnXPhFStGoodZFfiWbaXkR',
  'CtNx3aaHU89bLRvHPnjpm3HYmktuYrMYHZebywuVXwcG',
  'CyAPVb9J2bWkF3N9JgP5HLDG4qMcN442b3xuGFvsxQJo',
  'DComLTQAaXfzc75FGp85FWZLQSLiAuKF5Bz1qk1izMEc',
  'De2bQHxEgbiQCp4T8E1vrNXy8x19tMs49yWtVu11111',
  'DN6FoYTNFTsjQ6ybBVqiL6MCNcSKrww4pgt6BiDtS29G',
  'EDoEnPxmYgKv9ZE9gMYj9pQ1oWZf9sYHkZLCpmUCTprg',
  'EXE1NZ7EroF1YTtzzQFHasAKpBKtEPEQXHVDvJeCA1dx',
  'HHNy5L2XXYKPkSxfCsZh7fZqMoxmo2PB7B7GTYUuRG45',
  'HoWsYd843AGTKupY7XErpUf47XjpfpTNgxKydQMTV9LQ',
  'QxhUydAjJnyyWVPDFRoKdZKH22gtSJtBf2sijmXpK5y',
  'si1CbC2KhhzBn8KoTQFC2hpvJhGXa3qMQunmo511111',
  'TW4zdc5J3qiEogoAKTWWEGeW8vNgr7dfA2JfpY11111',
];
