import { ReactElement, SVGProps } from 'react';

export const SvgRiskDashboard = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#98B0C1" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M5.789 2h4.422c.177 0 .347.07.472.195l3.122 3.122A.666.666 0 0 1 14 5.79v4.422c0 .177-.07.347-.195.472l-3.122 3.122a.667.667 0 0 1-.472.195H5.79a.666.666 0 0 1-.472-.195l-3.122-3.122A.667.667 0 0 1 2 10.21V5.79c0-.177.07-.347.195-.472l3.122-3.122A.667.667 0 0 1 5.79 2Z"
        strokeMiterlimit={10}
      />
      <path d="M10 6.666 7.333 9.333 6 7.999" />
    </g>
  </svg>
);
