import { ReactElement, SVGProps } from 'react';

export const SvgLinkOut = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.333 4.166H5a.833.833 0 0 0-.833.833v10c0 .46.373.834.833.834h10c.46 0 .833-.373.833-.834v-3.333"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.667 7.5V3.335H12.5M10.833 9.167l5.834-5.833"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
