import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import keyBy from 'lodash/keyBy';
import { QUERYKEYS } from '../constants/queryKeys';
import { captureError } from '../utils/captureError';
import { getEnvChain } from '../../providers/utils';
import { API_URL } from '../constants/api';
import { DataMetrics } from '../types/metrics';

export function useMetricsQuery(): {
  metricsByStrategy: Record<string, DataMetrics> | undefined;
  isLoading: boolean;
} {
  const env = getEnvChain();

  const { data: metricsByStrategy, isLoading } = useQuery<Record<string, DataMetrics>>(
    QUERYKEYS.metrics,
    async () => {
      const response = await axios.get(`${API_URL}/strategies/metrics`, {
        params: {
          env,
          status: 'LIVE',
        },
      });
      const metricsArray = response.data as DataMetrics[];

      return keyBy(metricsArray, (item) => item.strategy);
    },
    {
      onError: (err) => captureError(err),
    }
  );

  return { metricsByStrategy, isLoading };
}
