import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@bruitt/classnames';
import { observer } from 'mobx-react-lite';
import { Drawer } from '../../shared/uiKitV2/Drawer';
import s from './SidePanel.module.less';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { layoutProviderStore } from '../../stores/layoutProviderStore';
import { IconButton } from '../../shared/uiKitV2/IconButton';
import { Icon } from '../../shared/uiKitV2/Icon';
import { SvgClose } from '../../shared/uiKitV2/Icons';
import { SidepanelContent } from './components/SidepanelContent/SidepanelContent';
import { SMALL_SCREEN_BP, TABLET_BP } from '../../shared/constants/breakpointsV2';

const sx = withStyles(s);

export const SidePanel = observer(() => {
  const { isSidePanelRendered, isSidePanelOpened } = layoutProviderStore;

  const { updateProviderParams } = layoutProviderStore;

  const ref = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();
  const [menuWidth, setMenuWidth] = useState(0);
  const isSmallScreen = windowWidth < SMALL_SCREEN_BP;
  const isMobile = windowWidth < TABLET_BP;

  const handleMenuClose = () => {
    updateProviderParams({
      isSidePanelOpened: false,
    });
  };

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      const initialElementWidth = element.getBoundingClientRect().width;

      setMenuWidth(initialElementWidth);

      // Check if element width is greater than window width
      if (initialElementWidth > windowWidth) {
        setMenuWidth(windowWidth);
      }
    }
  }, [ref, windowWidth]);

  // Check for window resize and adjust element width
  useEffect(() => {
    if (menuWidth > windowWidth) {
      setMenuWidth(windowWidth - 10);
    }
  }, [menuWidth, windowWidth]);

  return (
    <Drawer
      position={isMobile ? 'left' : 'right'}
      isOpen={isSidePanelRendered && isSidePanelOpened}
      onClose={handleMenuClose}
      closeOnOverlay
    >
      <div className={sx('root')} ref={ref} style={isSmallScreen && menuWidth ? { width: menuWidth } : undefined}>
        <div className={s.closeIconWrapper} onClick={handleMenuClose}>
          <IconButton size="large" variant="ghost" icon={<Icon name="ChevronRightDouble" />} />
        </div>
        <div className={sx('content')}>{isSidePanelRendered && <SidepanelContent />}</div>

        <div className={s.closeIconMobile} onClick={handleMenuClose}>
          <IconButton size="large" variant="secondary" icon={<SvgClose />} shape="circle" />
        </div>
      </div>
    </Drawer>
  );
});
