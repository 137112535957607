import { PublicKey, Transaction, TransactionInstruction, VersionedTransaction } from '@solana/web3.js';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import Decimal from 'decimal.js';
import { JupiterService } from '../../../shared/services/jupiter/JupiterService';
import { capSlippage } from '../../../shared/utils/jupiter';
import {
  noop,
  notEmpty,
  numberToLamportsDecimal,
  printError,
  removeBudgetIxns,
  sendAndConfirmRawTransactionWithRetry,
  toWsolMintIfSol,
  tryAnchorErrorMessageOr,
} from '../../../shared/utils';
import { TransactionDetailItem, TransactionStatus } from '../../../shared/types/notifications';
import { convertJupiterIxnsToTransactionIxns } from '../../../shared/utils/operations/transactions';
import { closeNotification, notify } from '../../../shared/utils/notifications/notifications';
import { captureError } from '../../../shared/utils/captureError';
import { LayoutProviderStore, layoutProviderStore } from '../../../stores/layoutProviderStore';
import { SystemService } from '../../../shared/services/solana/SystemService';
import { useWeb3Client } from '../../../shared/hooks/useWeb3Client';
import useEnv from '../../../shared/hooks/useEnv';
import { useNotifications } from '../../../shared/contexts/NotificationsContext';
import useTokens from '../../../shared/hooks/useTokens';
import { useBudgetAndPriorityFeeIxns } from '../../../shared/hooks/useBudgetAndPriorityFeeIxns';
import { useSignAllTransactions } from '../../../shared/hooks/useSignAllTransactions';
import { useLastPostionUpdate } from '../../../shared/hooks/useLastPostionUpdate';
import { useObservableStoreValue } from '../../../stores/useObservableStoreValue';

interface UseSwapProps {
  onSuccess?: () => void;
}

type OnSwapProps = {
  inputMint: string;
  outputMint: string;
  inputAmount: number;
  isVersionedTransactionEnabled?: boolean;
};
export const useSwap = ({ onSuccess = noop }: UseSwapProps) => {
  const { web3client } = useWeb3Client();
  const mixpanel = useMixpanel();
  const { walletPublicKey, wallet } = useEnv();
  const { updateProviderParams } = layoutProviderStore;
  const notificationKey = 'swap';
  const { updateTxByIndex, insertTransaction, addNotification } = useNotifications();
  const { createLegacyTxWithBudgetIxns, createVersionedTxWithBudgetIxns } = useBudgetAndPriorityFeeIxns();
  const { signAllTransactions } = useSignAllTransactions();
  const { setPositionHasUpdated } = useLastPostionUpdate();
  const { getToken } = useTokens();
  const slippage = useObservableStoreValue<LayoutProviderStore, Decimal>(
    layoutProviderStore,
    (store) => store.slippage
  );

  const systemService: SystemService | undefined = useMemo(() => {
    if (!web3client) {
      return;
    }
    return new SystemService(web3client);
  }, [web3client]);

  const mutationFn = async ({ inputMint, outputMint, inputAmount, isVersionedTransactionEnabled }: OnSwapProps) => {
    if (!web3client || !systemService) {
      throw new Error('Web3client is not loaded');
    }

    if (!walletPublicKey || !wallet) {
      throw new Error('Wallet is not connected');
    }

    const inputTokenInfo = getToken(inputMint);
    const outputTokenInfo = getToken(outputMint);

    const txIds: string[] = [];

    const txNames: string[] = ['Swap'];

    const quote = await JupiterService.getBestRoute({
      amountLamports: numberToLamportsDecimal(inputAmount, inputTokenInfo.decimals),
      inputMint: toWsolMintIfSol(inputMint),
      outputMint: toWsolMintIfSol(outputMint),
      slippage: capSlippage(slippage, true).toNumber(),
      mode: 'ExactIn',
      onlyDirectRoutes: false,
      asLegacyTransaction: !isVersionedTransactionEnabled,
    });

    const {
      setupInstructions,
      swapInstruction,
      addressLookupTableAddresses: jupiterLookupTables,
      cleanupInstruction,
    } = await JupiterService.getSwapInstructions(
      quote,
      new PublicKey(walletPublicKey),
      true,
      !isVersionedTransactionEnabled
    );

    const clearedSetupIxns = removeBudgetIxns(convertJupiterIxnsToTransactionIxns(setupInstructions));

    let clearedCleanUpIxns: TransactionInstruction[] = [];

    if (cleanupInstruction) {
      clearedCleanUpIxns = removeBudgetIxns(convertJupiterIxnsToTransactionIxns([cleanupInstruction]));
    }

    closeNotification(notificationKey);
    addNotification({
      id: notificationKey,
      transactions: txNames.map((name) => {
        return {
          label: name,
          status: TransactionStatus.Pending,
          tranactionDetail: undefined,
        };
      }),
    });

    let jupiterSwapTransactions: VersionedTransaction[] | Transaction[];

    if (isVersionedTransactionEnabled) {
      // VersionedTransactions
      const preparedIxns = [...setupInstructions, swapInstruction];
      if (cleanupInstruction) {
        preparedIxns.push(cleanupInstruction);
      }
      jupiterSwapTransactions = [
        await createVersionedTxWithBudgetIxns({
          ixns: removeBudgetIxns(convertJupiterIxnsToTransactionIxns(preparedIxns)),
          lookupTablesAddresses: jupiterLookupTables.map((lup) => new PublicKey(lup)),
          defaultCu: 1_400_000,
        }),
      ];
    } else {
      // remove budget and atas ixns from jup transaction because we manage it ourself
      let clearedSetupTx = setupInstructions && setupInstructions.length ? new Transaction() : undefined;
      // build setup transaction from creates atas and jupiter setup ixns
      if (clearedSetupTx && clearedSetupIxns && clearedSetupIxns.length) {
        clearedSetupTx = await createLegacyTxWithBudgetIxns(clearedSetupIxns, 200_000);
        // add tx before 'Swap' one
        insertTransaction(notificationKey, txNames.length - 1, {
          label: 'Create accounts',
          status: TransactionStatus.Pending,
        });
      }

      // build setup transaction from creates atas and jupiter setup ixns
      let clearedCleanUpTx = setupInstructions && setupInstructions.length ? new Transaction() : undefined;
      if (clearedCleanUpTx && clearedCleanUpIxns && clearedSetupIxns.length) {
        clearedCleanUpTx = await createLegacyTxWithBudgetIxns(clearedCleanUpIxns, 200_000);
        // add tx before 'Swap' one
        insertTransaction(notificationKey, txNames.length - 1, {
          label: 'Clean Up',
          status: TransactionStatus.Pending,
        });
      }
      jupiterSwapTransactions = [
        clearedSetupTx,
        await createLegacyTxWithBudgetIxns(
          removeBudgetIxns(convertJupiterIxnsToTransactionIxns([swapInstruction])),
          1_400_000
        ),
        clearedCleanUpTx,
      ].filter(notEmpty);
    }

    // 4.1 Perform swap
    const jupiterSigned = await signAllTransactions(jupiterSwapTransactions);

    const swapTxDetails: TransactionDetailItem[] = [
      {
        tokenMint: quote.inputMint,
        amount: Number(quote.inAmount) / 10 ** (inputTokenInfo?.decimals || 1),
        tokenInfo: inputTokenInfo,
      },
      {
        tokenMint: quote.outputMint,
        amount: Number(quote.outAmount) / 10 ** (outputTokenInfo?.decimals || 1),
        tokenInfo: outputTokenInfo,
      },
    ];
    for (let i = 0; i < jupiterSigned.length; i++) {
      try {
        updateTxByIndex(notificationKey, txIds.length, {
          status: TransactionStatus.Pending,
          tranactionDetail: { type: 'Swap', amounts: swapTxDetails },
        });

        const txId: string = await sendAndConfirmRawTransactionWithRetry({
          mainConnection: web3client.sendConnection,
          extraConnections: web3client.sendConnectionsExtra,
          signedTx: jupiterSigned[i],
          commitment: 'confirmed',
          mixpanel,
        });

        updateTxByIndex(notificationKey, txIds.length, {
          status: TransactionStatus.Success,
          txId,
          tranactionDetail: { type: 'Swap', amounts: swapTxDetails },
        });
        txIds.push(txId);
      } catch (err) {
        captureError(err);
        printError(err);
        updateTxByIndex(notificationKey, txIds.length, {
          status: TransactionStatus.Error,
          // @ts-ignore
          txId: err.txId,
        });
      }
    }

    return {
      txId: txIds[0],
    };
  };

  const swapMutation = useMutation(mutationFn, {
    onMutate: ({ inputMint, outputMint, inputAmount }) => {
      const { symbol: inputSymbol } = getToken(inputMint);
      const { symbol: outputSymbol } = getToken(outputMint);

      updateProviderParams({ isTransactionInProgress: true });
      notify({
        message: 'Swap',
        description: `Swapping ${inputAmount} ${inputSymbol} for ${outputSymbol}`,
        type: 'loading',
        key: notificationKey,
      });
    },
    onSuccess: () => {
      onSuccess();
      setPositionHasUpdated();
    },
    onError: (err) => {
      captureError(err);
      closeNotification(notificationKey);
      printError(err);
      notify({
        type: 'error',
        message: 'Swap failed',
        description: tryAnchorErrorMessageOr(err, 'Swap failed'),
        // @ts-ignore
        errorTxId: err.txId,
        walletPublicKey,
      });
    },
    onSettled: () => {
      updateProviderParams({ isTransactionInProgress: false });
    },
  });

  return {
    onSwap: swapMutation.mutate,
    isLoading: swapMutation.isLoading,
  };
};
