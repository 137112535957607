import { PublicKeyAddress } from '../../../types/strategies';

const AVAILABLE_TOKENS_MINTS = [
  // Tokens
  'So11111111111111111111111111111111111111112', // sol
  '7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT', // uxd
  '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs', // 'eth',
  'AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3', // 'ftt',
  'SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt', // 'srm',
  'HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6', // 'hbb',
  'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So', // 'msol',
  'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX', // 'usdh',
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // 'usdc',
  'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', // 'usdt',
  'HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr', // 'nana',
  'iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns', // 'iot',
  'mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6', // 'mobile',
  'hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux', // 'hnt',
  'HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK', // 'hxro',
  'CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE', // 'cgntsol',
  'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a', // 'rlb',
  'BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn', // 'hades',
  'LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X', // 'lainesol',
  'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1', // 'bsol',
  '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU', // 'samo',
  'EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o', // 'dai',
  'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn', // 'jitosol',
  'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263', // 'bonk',
  'UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M', // 'uxp',
  'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J', // 'ratio',
  'USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2', // 'usdr',
  '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn', // 'jsol',
  'DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ', // 'dust',
  '5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy', // 'hdg',
  '9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6', // 'ush',
  '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh', // 'wbtc',
  'MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey', // 'mnde',
  'HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p', // 'ldo',
  'ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo', // 'wsteth',
  '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm', // 'inf',
  'GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh', // 'daosol',
  'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT', // 'step',
  '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj', // 'stsol',
  '2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg', // euroe
  'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM', // usdcet
  '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R', // ray
  'LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp', // lst
  '3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3', // chai
  '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4', // 'JLP'
  'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A', // hsol
  '2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo', // pyusd

  // kTokens
  '5BmZgW7dk1kximGfn7MPvDigp3yRmgT64jS9Skdq4nPY', // 'kusdh-usdc',
  '4G9USgnbg6fDTQ5AUfpCjM89zqbzWj32xfqvsaAu66DM', // 'kuxd-usdc',
  '9HB4kAMLSYfGFfN142DKMyPyHyZQ8pXF8M1STbDudodY', // 'ksol-bsol',
  'Dk2X1HCbwJae44P7FpqdFoeT6LEw4JVyyHvZMHUzHWbi', // 'kjitosol-sol',
  'GYiUmJ8reqYAdTQtx6CRFawHqPXx9yzkUFvaUVE8PskP', // 'kjitosol-sol',
  '8Ak9JgLeTo6ubG5vfpuAR59ANpGjTB8HFDwYjpZbkPeB', // 'kjitosol-usdc',
  '3Fb5DMRWoBLWD36Lp4BtG41LaFjVeEJNCH9YLNPYdVqj', // 'kmsol-sol',
];

export const getIconClassName = (tokenMint: PublicKeyAddress): string | null => {
  const tokenMintStr = tokenMint.toString();

  if (AVAILABLE_TOKENS_MINTS.includes(tokenMintStr)) {
    return `icon-${tokenMintStr}`;
  }

  return null;
};
