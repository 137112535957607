import { FC } from 'react';
import { Wallet } from '@solana/wallet-adapter-react';
import { useMixpanel } from 'react-mixpanel-browser';
import s from './OnboardingList.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { IconButton } from '../../../../../../shared/uiKitV2/IconButton';
import { Icon } from '../../../../../../shared/uiKitV2/Icon';
import { Divider } from '../../../../../../shared/uiKitV2/Divider';
import { Link } from '../../../../../../shared/uiKitV2/Link';
import { noop } from '../../../../../../shared/utils';
import { captureError } from '../../../../../../shared/utils/captureError';
import { GOOGLE_TIPLINK_WALLET, METAMASK_WALLET } from '../../../../../../shared/components/WalletModal/constants';
import { MIXPANEL_PREFIX } from '../../../../../../shared/constants/mixpanel';

type OnboardingListProps = {
  onBack: () => void;
  onSelect: (wallet: Wallet) => Promise<void>;
  wallets: Wallet[];
};

const TextDivider = () => {
  return (
    <div className={s.divider}>
      <Divider />
      <Text color="grey">or</Text>
      <Divider />
    </div>
  );
};
export const OnboardingList: FC<OnboardingListProps> = ({ onBack, wallets, onSelect }) => {
  const mixpanel = useMixpanel();
  const installedMetamaskWallet = wallets?.find((wallet) => wallet.adapter.name === 'MetaMask');

  const trackClick = (walletName: string) => {
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:onboarding:wallet_select`, {
      walletName,
    });
  };

  return (
    <div className={s.content}>
      <div className={s.title}>
        <IconButton onClick={onBack} size="small" variant="secondary" icon={<Icon name="ArrowLeft" color="white" />} />
        <Text fs={22} lh={32}>
          Onboarding
        </Text>
      </div>

      <div className={s.list}>
        <div className={s.listItem}>
          <div
            className={s.wallet}
            onClick={() => {
              trackClick(GOOGLE_TIPLINK_WALLET.adapter.name);
              onSelect(GOOGLE_TIPLINK_WALLET).then(noop).catch(captureError);
            }}
          >
            <Icon name="Google" size={24} />
            <Text color="white">Google Login</Text>
          </div>
          <Text color="grey-light">
            You can use Kamino with just your Google account. Click the button above to get started.
          </Text>
        </div>
        <TextDivider />

        {Boolean(installedMetamaskWallet) && (
          <>
            <div className={s.listItem}>
              <div
                className={s.wallet}
                onClick={() => {
                  trackClick(GOOGLE_TIPLINK_WALLET.adapter.name);
                  onSelect(installedMetamaskWallet || METAMASK_WALLET)
                    .then(noop)
                    .catch(captureError);
                }}
              >
                <Icon name="Metamask" size={24} />
                <Text color="white">Metamask Login</Text>
              </div>
              <Text color="grey-light">
                EVM user? You can use Kamino via your Metamask wallet. Click the button above to get started.
              </Text>
            </div>
            <TextDivider />
          </>
        )}

        <div className={s.listItem}>
          <Link
            href="https://phantom.app/"
            target="_blank"
            className={s.link}
            startIcon={<Icon name="Phantom" size={24} />}
            onClick={() => trackClick('Phantom')}
          >
            <Text color="white">Phantom Download</Text>
          </Link>
          <Text color="grey-light">
            Want a Solana-native wallet? Click the button above to download Phantom Wallet.
          </Text>
        </div>
      </div>
    </div>
  );
};
