import { useQuery } from '@tanstack/react-query';

import { fetchTransactionsHistory } from '../../../services/transactionsHistory';
import useEnv from '../../useEnv';
import { notify } from '../../../utils/notifications/notifications';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { captureError } from '../../../utils/captureError';
import { useWalletAddressFromParamsOrEnv } from '../../useWalletAddressFromParamsOrEnv';
import { TransactionsHistoryResponse } from '../../../types/transactionsHistory';

const EMPTY_ARRAY: TransactionsHistoryResponse[] = [];

export const useTransactionsHistoryQuery = () => {
  const { env } = useEnv();
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.getTransactionsHistory(walletPublicKey || '-'),
    queryFn: () => fetchTransactionsHistory({ env, walletPublicKey }),
    enabled: Boolean(walletPublicKey),
    onError: (err: Error) => {
      captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load transactions history',
      });
    },
  });

  return { data: data || EMPTY_ARRAY, ...rest };
};
