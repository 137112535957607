import { useCallback, useMemo } from 'react';
import { useMarkets } from './useMarkets';

import {
  getAllReservesMap,
  filterActiveMarkets,
  getActiveReservesByMarketMap,
  getReserveByAddressFromAllReservesMap,
  getReservesByMarketAddressFromAllReservesMap,
  getReserveByTokenMintFromMarket,
} from './helpers/useReserves';

export const useReserves = () => {
  const { markets, getMarketByAddress } = useMarkets();

  // Search through all reserves
  const allReservesMap = useMemo(() => getAllReservesMap(markets), [markets]);

  // Only active reserves
  const activeReserves = useMemo(() => filterActiveMarkets(markets), [markets]);

  // Only active reserves by market map
  const activeReservesByMarketMap = useMemo(() => getActiveReservesByMarketMap(markets), [markets]);

  // Search for reserve through all available markets
  const getReserveByAddress = useCallback(getReserveByAddressFromAllReservesMap(allReservesMap), [allReservesMap]);

  const getReservesByMarketAddress = useCallback(
    getReservesByMarketAddressFromAllReservesMap(activeReservesByMarketMap),
    [activeReservesByMarketMap]
  );

  const getReserveByTokenMint = useCallback(getReserveByTokenMintFromMarket(getMarketByAddress), [getMarketByAddress]);

  return {
    activeReserves,
    getReserveByAddress,
    getReserveByTokenMint,
    getReservesByMarketAddress,
  };
};
