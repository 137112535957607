import BaseModal, { Props } from 'react-modal';
import { FC, useCallback, MouseEvent } from 'react';

import { withStyles } from '@bruitt/classnames';
import s from './Modal.module.less';
import { Card } from '../Card';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';

const sx = withStyles(s);

BaseModal.setAppElement('#root');

interface ModalProps extends Omit<Props, 'overlayClassName' | 'className' | 'onRequestClose'> {
  onClose?: (e: MouseEvent<HTMLElement>) => void;
  isShowCloseBtn?: boolean;
  isFluid?: boolean;
  isMaxWidth?: boolean;
  isOverSidepanel?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  onClose,
  shouldCloseOnOverlayClick, // this setting also handles ESC keyboard event
  isShowCloseBtn = true,
  isFluid = false,
  isMaxWidth = false,
  isOverSidepanel = false,
  ...rest
}) => {
  const handleClose = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onClose?.(e);
    },
    [onClose]
  );

  return (
    <BaseModal
      onRequestClose={shouldCloseOnOverlayClick ? handleClose : undefined}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={sx(s.overlay, { isOverSidepanel })}
      closeTimeoutMS={100}
      bodyOpenClassName={s.disabledScroll}
      className={sx(s.root, { isFluid, isMaxWidth })}
      onAfterOpen={(obj) => obj?.contentEl.focus()}
      {...rest}
    >
      <Card className={s.content}>
        {isShowCloseBtn && (
          <div className={s.closeBtn}>
            <IconButton
              onClick={handleClose}
              size="small"
              variant="secondary"
              shape="circle"
              icon={<Icon name="Close" color="grey" />}
            />
          </div>
        )}

        {children}
      </Card>
    </BaseModal>
  );
};
