import { useCallback, useMemo } from 'react';
import { differenceInHours, parseISO } from 'date-fns';

import { layoutProviderStore } from '../../stores/layoutProviderStore';
import useEnv from './useEnv';

const getCacheKey = (walletPublicKey: string) => `wallet::${walletPublicKey}::lastPositionUpdateAt`;

export const useLastPostionUpdate = () => {
  const { updateProviderParams } = layoutProviderStore;
  const { walletPublicKey } = useEnv();

  const setPositionHasUpdated = useCallback(() => {
    if (walletPublicKey) {
      const dateIsoString = new Date().toISOString();
      updateProviderParams({ lastPositionUpdateAt: dateIsoString }, true);
      localStorage.setItem(getCacheKey(walletPublicKey), dateIsoString);
    }
  }, [walletPublicKey]);

  return { setPositionHasUpdated };
};

export const useGetLastPostionUpdate = () => {
  const { walletPublicKey } = useEnv();
  const { lastPositionUpdateAt } = layoutProviderStore;

  const isLastPositionTxWithin2Hours = useMemo(() => {
    if (!walletPublicKey) {
      return false;
    }

    const cachedDateIsoString = localStorage.getItem(getCacheKey(walletPublicKey));

    if (!cachedDateIsoString) {
      return false;
    }

    const lastPositionTxDate = parseISO(cachedDateIsoString);
    const diffInhours = differenceInHours(new Date(), lastPositionTxDate);
    return diffInhours <= 2;
  }, [lastPositionUpdateAt, walletPublicKey]);

  return { isLastPositionTxWithin2Hours };
};
