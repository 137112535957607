import { Keypair, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';

import { Meteora, Orca, Raydium } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/types/DEX';
import BN from 'bn.js';
import { Dex } from '@kamino-finance/kliquidity-sdk/dist/utils';
import { BLACKLIST, V2_WHITELIST } from '../constants/whitelists';
import { TokenInfo } from '../types/token-info';
import { PublicKeyAddress } from '../types/strategies';
import { isNil } from './isNil';
import { SOL_MINTS, StablesTokens, Token } from '../constants/tokens';
import { Provider } from '../constants/kamino';
import { ENV } from '../services/web3/types';
import { BPS_TO_PCT_FACTOR } from '../constants/finance';

export const ACCOUNT_BATCH_QUERY_SIZE = 99;

// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
}

export const isSmallNumber = (val: Decimal.Value) => {
  const value = new Decimal(val);
  return value.abs().lt(0.000001) && !value.eq(0);
};

export function isStablecoin(asset?: string): boolean {
  if (!asset) {
    return false;
  }
  return (StablesTokens as string[]).includes(asset.toUpperCase());
}

export function chunks<T>(array: T[], size: number): T[][] {
  return Array.apply<number, T[], T[][]>(0, new Array(Math.ceil(array.length / size))).map((_, index) =>
    array.slice(index * size, (index + 1) * size)
  );
}

export const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberFormatterWithoutDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numberAmountFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const TEMPLATES = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 'T' },
];

export const formatNumber = {
  format: (val?: Decimal.Value): string => {
    const valDecimal = new Decimal(val || '0');
    if (!val || valDecimal.eq(0)) {
      return '0.00';
    }

    return numberFormatter.format(valDecimal.toNumber());
  },

  formatUsdFull: (val?: Decimal.Value): string => {
    const valDecimal = new Decimal(val || '0');
    const isNegative = valDecimal.isNegative();
    const absoluteValDecimal = valDecimal.abs();

    if (!val || valDecimal.eq(0)) {
      return '$0.00';
    }

    return `${isNegative ? '-' : ''}$${formatNumber.format(Number(absoluteValDecimal))}`;
  },

  formatUsd: (val?: Decimal.Value): string => {
    return formatNumber.formatCcy('$', val);
  },

  formatSol: (val?: Decimal.Value): string => {
    return formatNumber.formatCcy('◎', val);
  },

  formatCcy: (ccy: string, val?: Decimal.Value): string => {
    const valDecimal = new Decimal(val || '0');
    const isNegative = valDecimal.isNegative();
    const absoluteValDecimal = valDecimal.abs();

    if (!val || valDecimal.eq(0)) {
      return `${ccy}0.00`;
    }

    let formattedValue = absoluteValDecimal.toString();
    const isNotSmall = absoluteValDecimal.gt(1);

    if (!isNotSmall) {
      return `${isNegative ? '-' : ''}${ccy}${formatNumber.format(Number(absoluteValDecimal))}`;
    }

    const item = TEMPLATES.slice()
      .reverse()
      .find((it) => {
        return absoluteValDecimal.gte(it.value);
      });

    formattedValue = item
      ? `${ccy}${formatNumber.format(new Decimal(formattedValue).div(item.value))}${item.symbol}`
      : `${ccy}0.00`;

    return `${isNegative ? '-' : ''}${formattedValue}`;
  },

  /**
   * works like formatNumber.format but do not round number
   * formatRoundDown(66.6666) => 66.66
   * format(66.6666) => 66.67
   * @param value
   * @param decimals Significant decimals
   */
  formatRoundDown: (value: Decimal.Value, decimals = 3): string => {
    const valueDecimal = new Decimal(value || '0');
    if (!value || valueDecimal.eq(0)) {
      return '0.00';
    }
    const significantDigits = parseInt(valueDecimal.toNumber().toExponential().split('e-')[1]) || 0;
    const decimalsUpdated = (decimals || 0) + significantDigits - 1;
    decimals = Math.min(decimalsUpdated, value.toString().length);

    return numberFormatter.format(Math.floor(valueDecimal.toNumber() * 10 ** decimals) / 10 ** decimals);
  },

  roundDown: (value: Decimal.Value, decimals = 3): Decimal => {
    const valueDecimal = new Decimal(value || '0');
    if (!value || valueDecimal.eq(0)) {
      return new Decimal(0);
    }
    const significantDigits = parseInt(valueDecimal.toNumber().toExponential().split('e-')[1]) || 0;
    const decimalsUpdated = (decimals || 0) + significantDigits - 1;
    decimals = Math.min(decimalsUpdated, value.toString().length);

    return new Decimal(Math.floor(valueDecimal.toNumber() * 10 ** decimals) / 10 ** decimals);
  },

  formatWithoutDecimals: (val?: Decimal.Value): string => {
    const valDecimal = new Decimal(val || '0');
    if (!val || valDecimal.eq(0)) {
      return '0';
    }

    return numberFormatterWithoutDecimals.format(valDecimal.toNumber());
  },

  formatNumberWithoutDecimals: (num: number, suffix = ''): string => {
    return `${num.toFixed()}${suffix}`;
  },

  formatTokenAllDecimals: (
    amount: Decimal.Value | undefined,
    decimals: number,
    forceNonSignificantDecimals = false
  ) => {
    const amountDecimal = new Decimal(amount || '0');

    if (isSmallNumber(amountDecimal)) {
      return formatNumber.formatToFirstNonNullDecimal(amountDecimal);
    }

    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: forceNonSignificantDecimals ? decimals : 0,
      maximumFractionDigits: decimals,
      compactDisplay: 'long',
    }).format(Number(amountDecimal));
  },

  formatTokenAllDecimalsIntoForms: (
    amount: Decimal.Value | undefined,
    decimals: number,
    token: string,
    forceNonSignificantDecimals = false
  ) => {
    if (isStablecoin(token)) {
      return formatNumber.formatTokenAllDecimals(amount, 2, forceNonSignificantDecimals);
    }

    return formatNumber.formatTokenAllDecimals(amount, decimals, forceNonSignificantDecimals);
  },

  /**
   * Converts 1000 into 1K, 1 000 000 to 1M, 1 000 000 000 to 1B
   * @param value
   * @param decimals
   */
  formatShortName: (value: Decimal.Value): string => {
    const valueDecimal = new Decimal(value);
    if (valueDecimal.lt(1000)) {
      return formatNumber.format(value);
    }

    const item = TEMPLATES.slice()
      .reverse()
      .find((it) => {
        return valueDecimal.gte(it.value);
      });
    return item ? formatNumber.formatRoundDown(valueDecimal.div(item.value), 3) + item.symbol : '0';
  },
  formatShortNameCustomDecimals: (value: Decimal.Value, decimals: number): string => {
    const valueDecimal = new Decimal(value);
    if (valueDecimal.lt(1000)) {
      return formatNumber.formatTokenAllDecimals(value, decimals);
    }

    const item = TEMPLATES.slice()
      .reverse()
      .find((it) => {
        return valueDecimal.gte(it.value);
      });
    return item ? formatNumber.formatRoundDown(valueDecimal.div(item.value), 3) + item.symbol : '0';
  },
  formatTokenInput: (input: string, decimals: number) => {
    // const fractionDigits = token === 'USDH' ? 2 : _decimals;
    const fractionDigits = decimals;
    const [integersValue, decimalsValue] = input.split('.');

    if (!decimalsValue || decimalsValue.length === 0) {
      return input;
    }

    return `${integersValue}.${decimalsValue.slice(0, fractionDigits)}`;
  },

  formatPercent: (val: Decimal.Value, decimals?: number, roundUp?: boolean) => {
    const valDecimal = new Decimal(val);
    if (roundUp) {
      return `${numberPercentFormatter.format(valDecimal.toNumber())}%`;
    }
    return decimals !== undefined
      ? `${numberAmountFormatter.format(valDecimal.toNumber())}%`
      : `${formatNumber.format(val)}%`;
  },

  formatApyWithCap: (val: Decimal.Value, decimals?: number, roundUp?: boolean, upperCap = 100, lowerCap = 0) => {
    const valDecimal = new Decimal(val);
    if (roundUp) {
      return `${numberPercentFormatter.format(valDecimal.toNumber())}%`;
    }

    if (valDecimal.gt(upperCap)) {
      return `>${upperCap - 1}%`;
    }

    if (valDecimal.lt(lowerCap)) {
      return `<${lowerCap}%`;
    }

    return decimals ? `${numberAmountFormatter.format(valDecimal.toNumber())}%` : `${formatNumber.format(val)}%`;
  },

  formatPercentScientific: (val: Decimal.Value, decimals?: number) => {
    const valDecimal = new Decimal(val);

    if (valDecimal.gt(1_000_000_000_000)) {
      return `${valDecimal.toExponential(decimals || 2)}%`;
    }

    return decimals ? `${numberAmountFormatter.format(valDecimal.toNumber())}%` : `${formatNumber.format(val)}%`;
  },

  formatAmount: (val: Decimal.Value, useSmall?: boolean) => {
    const valDecimal = new Decimal(val || '0');
    if (!val || valDecimal.lte(0)) {
      return 0;
    }
    if (useSmall && isSmallNumber(valDecimal.toNumber())) {
      return 0.001;
    }

    return numberAmountFormatter.format(valDecimal.toNumber());
  },

  formatToFirstNonNullDecimal: (val: Decimal.Value) => {
    if (!val) {
      return '0';
    }

    if (isSmallNumber(val)) {
      return ' < 0.000001';
    }

    const valueStr = val.toString();
    const formatValue = numberFormatter.format(Number(val));

    const decimalIndex = valueStr.indexOf('.');
    if (decimalIndex === -1 || formatValue !== '0.00') {
      return formatValue;
    }

    let nonZeroFound = false;
    let i;
    for (i = decimalIndex + 1; i < valueStr.length; i++) {
      if (valueStr[i] !== '0') {
        nonZeroFound = true;
        break;
      }
    }

    return nonZeroFound ? valueStr.slice(0, i + 1) : valueStr.slice(0, decimalIndex);
  },

  formatNoTrailingZeroes: (val?: Decimal.Value) => {
    return formatNumber.formatTokenAllDecimals(val, 1).replace('.00', '').replace(/\.0$/, '');
  },

  formatLeverageSlider: (val?: Decimal.Value) => {
    return formatNumber.formatTokenAllDecimals(val, 1).replace('.00', '');
  },

  formatLeverageSliderV2: (val: number, decimals = 1) => {
    return `${val.toFixed(decimals)}x`;
  },

  valueOr: (a: Decimal, or: Decimal) => {
    if (a.isNaN()) {
      return or;
    }
    return a;
  },

  valueOrZero: (a: Decimal) => {
    if (a.isNaN()) {
      return new Decimal(0);
    }
    return a;
  },
};

// ONLY FOR DEV PURPOSES, ATM Airdrop
// NEVER USE ON PROD, SECURITY VIOLATION
// THIS PRIVATE KEY NEVER HAS TO BE USED IN MAINNET
// To make Airdrop page works on localnet replace this private key with
// your local /keys/localnet/owner.json from BE repo

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const devnetAdmin: Uint8Array = Uint8Array.from([
  98, 157, 54, 163, 228, 183, 32, 193, 174, 7, 128, 150, 93, 73, 242, 252, 124, 153, 165, 138, 77, 9, 110, 244, 236,
  162, 40, 175, 194, 163, 241, 106, 234, 13, 91, 96, 213, 4, 158, 80, 118, 20, 71, 211, 13, 91, 91, 79, 6, 101, 80, 189,
  214, 172, 183, 77, 64, 158, 72, 230, 58, 13, 154, 185,
]);

const devnetMintAuthority: Uint8Array = Uint8Array.from([
  119, 130, 209, 188, 166, 110, 27, 17, 58, 244, 220, 246, 123, 189, 102, 116, 62, 214, 214, 109, 210, 8, 224, 63, 144,
  57, 61, 211, 98, 211, 197, 71, 254, 241, 246, 68, 194, 51, 98, 146, 71, 106, 25, 59, 73, 208, 158, 110, 58, 25, 220,
  71, 203, 30, 52, 18, 226, 162, 109, 161, 222, 15, 100, 29,
]);

const devnetFaucet: Uint8Array = Uint8Array.from([
  195, 194, 52, 25, 37, 176, 27, 206, 86, 82, 153, 4, 218, 222, 77, 44, 79, 180, 90, 212, 137, 250, 223, 238, 53, 182,
  122, 24, 209, 242, 176, 211, 142, 129, 141, 195, 217, 75, 196, 215, 183, 32, 174, 20, 213, 135, 128, 118, 43, 137,
  135, 250, 236, 91, 111, 155, 203, 191, 61, 66, 29, 255, 171, 134,
]);

const localnetAdmin: Uint8Array = Uint8Array.from([
  210, 63, 231, 175, 25, 187, 126, 39, 147, 51, 107, 111, 159, 132, 50, 12, 159, 9, 78, 102, 14, 252, 179, 27, 186, 131,
  194, 112, 123, 221, 167, 198, 88, 76, 106, 44, 23, 205, 126, 222, 203, 155, 72, 115, 84, 235, 176, 212, 111, 226, 29,
  35, 211, 192, 111, 97, 155, 223, 225, 33, 65, 202, 144, 218,
]);

export const getDevFaucetAddress = (): Keypair => {
  const admin_: Uint8Array = devnetFaucet;
  const admin = Keypair.fromSecretKey(admin_);
  return admin;
};

export const getDevMintAuthority = (): Keypair => {
  const admin_: Uint8Array = devnetMintAuthority;
  const admin = Keypair.fromSecretKey(admin_);
  return admin;
};

export const getLocalMintAuthority = (): Keypair => {
  const admin_: Uint8Array = localnetAdmin;
  const admin = Keypair.fromSecretKey(admin_);
  return admin;
};

export const getLocalUsdhHbbMintAuthority = (): Keypair => {
  const admin_: Uint8Array = localnetAdmin;
  const admin = Keypair.fromSecretKey(admin_);
  return admin;
};

export const getMintAuthority = (env: ENV): Keypair | undefined => {
  if (env === 'devnet') {
    return getDevMintAuthority();
  }
  if (env === 'localnet') {
    return getLocalMintAuthority();
  }
};

export function numberToLamportsDecimal(amount: Decimal.Value, decimals: number): Decimal {
  const factor = 10 ** decimals;
  return new Decimal(amount).mul(factor);
}

export function lamportsToNumberDecimal(amount: Decimal.Value, decimals: number): Decimal {
  const factor = 10 ** decimals;
  return new Decimal(amount).div(factor);
}

export const isValidNumericInput = (value: string) => {
  return Number.isFinite(Number(value));
};

export const getIconClassName = (name: Token | Provider | string): string => {
  return `icon-${name.toString().toLowerCase()}`;
};

export const getDexFromRaw = (dexRaw: number | string | undefined): Dex => {
  if (!dexRaw) {
    return 'ORCA'; // I don't know if I should return a default value or panic on this
  }
  const dex = Number.parseInt(dexRaw.toString());
  if (dex === Orca.discriminator) {
    return 'ORCA';
  }
  if (dex === Raydium.discriminator) {
    return 'RAYDIUM';
  }
  if (dex === Meteora.discriminator) {
    return 'METEORA';
  }
  throw new Error(`Invalid dex number ${dex}`);
};

export type DexString = 'Orca' | 'Raydium' | 'Meteora';

export function dexToDexString(dex: Dex): DexString {
  if (dex === 'ORCA') {
    return 'Orca';
  }
  if (dex === 'RAYDIUM') {
    return 'Raydium';
  }
  if (dex === 'METEORA') {
    return 'Meteora';
  }
  throw new Error(`Invalid dex`);
}

export const getProviderFromDex = (dex: Dex): Provider => {
  if (dex === 'ORCA') {
    return 'orca';
  }
  if (dex === 'RAYDIUM') {
    return 'raydium';
  }
  if (dex === 'METEORA') {
    return 'meteora';
  }
  throw new Error(`Invalid dex`);
};

export const printObject = (obj: any): string[] => {
  const keys = Object.keys(obj);
  return keys.reduce((sum, k) => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(k)) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      sum.push(`${k}: ${obj[k].toString()}`);
    }
    return sum;
  }, [] as string[]);
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const aprToApy = (apr: Decimal.Value, compoundPeriods = 1) => {
  // if periods = 365 => daily compound
  // periods = 1 => yearly compound
  const aprD = new Decimal(apr);
  // (1 + apr / periods) ** periods - 1;
  return new Decimal(1).add(aprD.div(compoundPeriods)).pow(compoundPeriods).sub(1).toNumber();
};

export function getTokenAmount(collateral: Array<{ ticker: Token; amount: Decimal }>, token: Token): Decimal | null {
  const element = collateral.find((x) => x.ticker === token);
  if (element) {
    return element.amount;
  }
  return null;
}

export const bpsToPercent = (val: BN | number | string | Decimal) => {
  return new Decimal(val.toString()).div(BPS_TO_PCT_FACTOR).toNumber();
};

export const printPercent = (val: Decimal.Value | undefined) => {
  if (isNil(val)) {
    return formatNumber.formatPercent(0);
  }

  return formatNumber.formatPercent(val);
};

/**
 * Calculates apy for speciific range
 * @param apy
 * @param period, 365 by default = daily apy;
 */
export const callPeriodApy = (apy: Decimal | number, period = 365): Decimal => {
  // periodApy = (1 + apy) ** (1/period) - 1;

  const p = new Decimal(1).div(period);
  return new Decimal(apy).add(1).pow(p).sub(1);
};

export const toWsolMintIfSol = (mint: PublicKeyAddress) => {
  if (SOL_MINTS.includes(mint.toString())) {
    return new PublicKey(SOL_MINTS[1]);
  }

  return new PublicKey(mint);
};

export const isWhitelistedWallet = (wallet: PublicKey | string | null): boolean => {
  if (!wallet) {
    return false;
  }

  return V2_WHITELIST.indexOf(wallet.toString()) > -1;
};

export const isBlacklistedWallet = (wallet: PublicKey | string | null): boolean => {
  if (!wallet) {
    return false;
  }

  return BLACKLIST.indexOf(wallet.toString()) > -1;
};

// eslint-disable-next-line no-unused-vars
export function kaminoConsole(..._args: any) {
  // eslint-disable-next-line prefer-spread
}

export const objectToString = (obj: any) => {
  return obj ? JSON.stringify(obj) : '{}';
};

export const tokensInfoToToken = (tokens: TokenInfo[]) => {
  return tokens.map((t) => t.symbol);
};

/**
 * Converts milliseconds to minutes
 * @param val
 */
export const msToMins = (val: Decimal.Value): Decimal => {
  return new Decimal(val).div(1000).div(60);
};
/**
 * Calculates duration until the epoch reset
 * @param epochIntervalLengthSeconds
 * @param lastIntervalStartTimestamp
 */
export const calcEpochReset = (
  epochIntervalLengthSeconds: Decimal.Value,
  lastIntervalStartTimestamp: Decimal.Value
) => {
  const intervalLength = new Decimal(epochIntervalLengthSeconds).mul(1000);
  const intervalStart = new Decimal(lastIntervalStartTimestamp).mul(1000);
  const alreadyPassed = new Decimal(Date.now()).sub(intervalStart);
  return msToMins(intervalLength.sub(alreadyPassed)).round();
};

export const toJson = (object: any): string => {
  return JSON.stringify(object, null, 2);
};

export const max = (a: Decimal.Value, b: Decimal.Value) => {
  return new Decimal(a).gt(b) ? a : b;
};

export const fuzzyEq = (a: Decimal.Value, b: Decimal.Value, epsilon = 0.0001) => {
  return new Decimal(a).sub(b).abs().lte(epsilon);
};

export function capitalizeFirstLetter(inputString: string): string {
  if (inputString.length === 0) {
    // Return an empty string if input is empty
    return '';
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfString = inputString.slice(1).toLowerCase();

  return firstLetter + restOfString;
}
