// All values are in %
export const MAX_SLIPPAGE_PCT = 5; // 5%
export const MAX_FEE = 0.005;
export const DEFAULT_SLIPPAGE = 0.5; // 0.5%
export const DEFAULT_SLIPPAGE_MULTIPLY_PCT = 0.02; // 0.02%
export const SLIPPAGE_MULTIPLY_WARNING_PCT = 0.1; // 0.1%
export const DEFAULT_SLIPPAGE_JLP_MULTIPLY_PCT = 0.2; // 0.2%
export const SLIPPAGE_JLP_MULTIPLY_WARNING_PCT = 0.5; // 0.5%
export const MAX_SAFE_SLIPPAGE = 1;

// 0.5%
export const MAX_PRICE_IMPACT_PCT = 0.005;
