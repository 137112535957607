import { ReactElement, SVGProps } from 'react';

export const SvgKmnoStake = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={31} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.2}
      d="M15.878 30.532c8.198 0 14.844-6.646 14.844-14.844S24.076.844 15.878.844C7.679.844 1.033 7.49 1.033 15.688S7.68 30.532 15.878 30.532Z"
      fill="url(#kmno-stake_svg__a)"
    />
    <path
      d="M15.878 1.001c8.113 0 14.688 6.576 14.688 14.689 0 8.112-6.575 14.688-14.688 14.688S1.19 23.802 1.19 15.69C1.19 7.577 7.765 1 15.878 1Zm0-.312c-8.272 0-15 6.729-15 15 0 8.272 6.728 15 15 15s15-6.728 15-15c0-8.271-6.728-15-15-15Z"
      fill="#212C42"
    />
    <path
      d="M16 28.778c7.057 0 12.778-5.72 12.778-12.778 0-7.057-5.721-12.777-12.778-12.777S3.222 8.943 3.222 16c0 7.057 5.721 12.778 12.778 12.778Z"
      fill="url(#kmno-stake_svg__b)"
    />
    <path
      opacity={0.3}
      d="M16 25.037a9.037 9.037 0 1 0 0-18.074 9.037 9.037 0 0 0 0 18.074Z"
      fill="url(#kmno-stake_svg__c)"
      stroke="#98B0C1"
      strokeMiterlimit={10}
    />
    <g clipPath="url(#kmno-stake_svg__d)">
      <rect x={8.426} y={17.926} width={7} height={2} rx={0.5} fill="#98B0C1" />
      <rect x={16.426} y={17.926} width={7} height={2} rx={0.5} fill="#98B0C1" />
      <rect x={16.426} y={14.926} width={7} height={2} rx={0.5} fill="#98B0C1" />
      <rect x={8.426} y={14.926} width={7} height={2} rx={0.5} fill="url(#kmno-stake_svg__e)" />
      <rect x={16.426} y={11.926} width={7} height={2} rx={0.5} fill="url(#kmno-stake_svg__f)" />
    </g>
    <defs>
      <linearGradient
        id="kmno-stake_svg__a"
        x1={21.558}
        y1={1.975}
        x2={10.197}
        y2={29.404}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="kmno-stake_svg__b" x1={16} y1={3.223} x2={16} y2={28.778} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="kmno-stake_svg__c" x1={16} y1={25.037} x2={16} y2={6.963} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient
        id="kmno-stake_svg__e"
        x1={12.148}
        y1={14.526}
        x2={12.043}
        y2={19.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212C42" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient
        id="kmno-stake_svg__f"
        x1={20.148}
        y1={11.526}
        x2={20.043}
        y2={16.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212C42" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <clipPath id="kmno-stake_svg__d">
        <path fill="#fff" transform="translate(7.037 7.037)" d="M0 0h17.778v17.778H0z" />
      </clipPath>
    </defs>
  </svg>
);
