import { PublicKey } from '@solana/web3.js';

// Program ID passed with the cli --program-id flag when running the code generator. Do not edit, it will get overwritten.
export const PROGRAM_ID_CLI = new PublicKey('6LtLpnUFNByNXLyCoK9wA2MykKAmQNZKBdY8s47dehDc');

// TODO: make a function and add more conditions toe choose right programId
// TODO: webworker change, undo if reverted
const env = localStorage.getItem('web3.env') || 'mainnet-beta';
export const PROGRAM_ID: PublicKey =
  env === 'devnet' ? new PublicKey('E6qbhrt4pFmCotNUSSEh6E5cRQCEJpMcd79Z56EG9KY') : PROGRAM_ID_CLI;
