import { useParams } from 'react-router-dom';
import useEnv from './useEnv';

/**
 * Return wallet address from URL params or connected wallet address
 * Mostly used by Portfolio page to preview other users portfolios
 */
export const useWalletAddressFromParamsOrEnv = () => {
  const { owner } = useParams<{ owner?: string }>();
  const { walletPublicKey } = useEnv();

  const isOwner = Boolean(owner === walletPublicKey || (!owner && walletPublicKey));

  return { walletPublicKey: owner || walletPublicKey, isOwner };
};
