import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@bruitt/classnames';
import { useMixpanel } from 'react-mixpanel-browser';
import { observer } from 'mobx-react-lite';
import { MENU_ITEMS } from '../../shared/constants/menuItems';
import { MenuItem } from '../../shared/components/MenuItem';
import { Drawer } from '../../shared/uiKitV2/Drawer';
import { IconButton } from '../../shared/uiKitV2/IconButton';
import { Logo } from '../../shared/uiKitV2/Logo';
import paths from '../../shared/routes/paths';
import s from './MobileMenu.module.less';
import { SvgClose } from '../../shared/uiKitV2/Icons';
import { FOOTER_LOGOS_V2 } from '../../shared/components/AppFooter/types';
import { Divider } from '../../shared/uiKitV2/Divider';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { removeLinks } from '../MenuV2/utils';

import { layoutProviderStore } from '../../stores/layoutProviderStore';
import { SMALL_SCREEN_BP } from '../../shared/constants/breakpointsV2';

const sx = withStyles(s);

export const MobileMenu = observer(() => {
  const mixpanel = useMixpanel();

  const { isMobileMenuOpened, updateProviderParams } = layoutProviderStore;

  const ref = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();
  const [menuWidth, setMenuWidth] = useState(0);
  const isSmallScreen = windowWidth < SMALL_SCREEN_BP;

  const handleMenuClose = () => {
    updateProviderParams({
      isMobileMenuOpened: false,
    });
  };

  const handleMenuItemClick = (menuItem: typeof MENU_ITEMS[0]) => {
    mixpanel.track(`header:menu:${menuItem.label.toString()}`);
    handleMenuClose();
  };

  const handleLogoClick = () => {
    mixpanel.track('header:main');
    handleMenuClose();
  };

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      const initialElementWidth = element.getBoundingClientRect().width;

      setMenuWidth(initialElementWidth);

      // Check if element width is greater than window width
      if (initialElementWidth > windowWidth) {
        setMenuWidth(windowWidth);
      }
    }
  }, [ref, windowWidth]);

  // Check for window resize and adjust element width
  useEffect(() => {
    if (menuWidth > windowWidth) {
      setMenuWidth(windowWidth - 10);
    }
  }, [menuWidth, windowWidth]);

  return (
    <Drawer isOpen={isMobileMenuOpened} onClose={handleMenuClose} closeOnOverlay>
      <div className={sx('root')} ref={ref} style={isSmallScreen && menuWidth ? { width: menuWidth } : undefined}>
        <div className={sx('content')}>
          <div className={sx('header')}>
            <NavLink to={paths.MAIN} onClick={handleLogoClick}>
              <Logo />
            </NavLink>
          </div>

          <nav className={sx('nav')}>
            {MENU_ITEMS.filter(removeLinks).map((menuItem) => (
              <div key={menuItem.id} className={sx('navItem')}>
                <MenuItem isMobile {...menuItem} onClick={() => handleMenuItemClick(menuItem)} />
              </div>
            ))}
          </nav>

          <Divider />

          <div className={sx('footer')}>
            <div className={sx('block')}>
              {FOOTER_LOGOS_V2.map(({ img, link, alt }) => {
                return (
                  <a href={link} target="_blank" rel="noreferrer" key={alt}>
                    <img src={img} className={sx('icon')} alt={alt} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <div className={sx('closeIcon')} onClick={handleMenuClose}>
          <IconButton size="large" variant="secondary" icon={<SvgClose />} shape="circle" />
        </div>
      </div>
    </Drawer>
  );
});
