import { useQuery } from '@tanstack/react-query';
import { KaminoMarket, KaminoObligation } from '@kamino-finance/klend-sdk';
import { PublicKey } from '@solana/web3.js';
import { ObligationTypeTag } from '@kamino-finance/klend-sdk/dist/utils/ObligationType';
import { useMarkets } from './useMarkets';

import { QUERYKEYS } from '../../constants/queryKeys';
import { featureFlags } from '../../utils/featureFlags';
import { notEmpty } from '../../utils/notEmpty';
import { useWalletAddressFromParamsOrEnv } from '../useWalletAddressFromParamsOrEnv';
import { useMarketsQuery } from './queries/useMarketsQuery';

const INIT_DATA: KaminoObligation[] = [];

export const useUserObligations = (
  filteredTags = [
    ObligationTypeTag.Vanilla,
    ObligationTypeTag.Lending,
    ObligationTypeTag.Multiply,
    ObligationTypeTag.Leverage,
  ]
) => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { markets } = useMarkets();
  const { isSuccess } = useMarketsQuery();

  const getObligationsByWallet = async (market: KaminoMarket) => {
    if (!walletPublicKey) {
      throw new Error('Wallet is not found');
    }
    return market.getAllUserObligations(new PublicKey(walletPublicKey));
  };

  const queryFn = async () => {
    const obligationsRequests = await Promise.all(markets.map((market) => getObligationsByWallet(market)));

    const obligations = obligationsRequests.reduce((sum, data) => {
      sum = sum.concat(data);
      return sum;
    }, []);

    return obligations.filter((obligation) => notEmpty(obligation) && filteredTags.includes(obligation.obligationTag));
  };

  const { data, isLoading, isFetched, ...rest } = useQuery({
    queryKey: QUERYKEYS.userLendingObligations(walletPublicKey || '', filteredTags),
    enabled: Boolean(markets.length && walletPublicKey) && isSuccess && featureFlags.isLendingPageEnabled,
    queryFn,
    cacheTime: 10 * 60 * 1000, // 10 mins
    staleTime: 5 * 60 * 1000, // 5 mins
  });

  return {
    data: data || INIT_DATA,
    isLoading: featureFlags.isLendingPageEnabled && isLoading,
    isFetched,
    ...rest,
  };
};
