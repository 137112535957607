// If you update any keys here make sure to check if it does not messed up invalidate i.e search for invalidateQueries usage
// some of the query keys use predicate to invalidate so make sure it still works as expected after the update

import { PublicKey } from '@solana/web3.js';
import { Dex, StrategiesFilters } from '@kamino-finance/kliquidity-sdk';
import { ObligationTypeTag } from '@kamino-finance/klend-sdk/dist/utils/ObligationType';
import Decimal from 'decimal.js';
import { QuoteGetRequest } from '@jup-ag/api';
import { PublicKeyAddress } from '../types/strategies';
import { FetchFeesAndRewardsStrategiesProps } from '../services/strategies';
import { UsePoolSimulatedValuesQuery } from '../../pages/CreateStrategy/types';
import { convertFiltersToQueryKeys } from '../utils/queries/convertFiltersToQueryKeys';
import { GetPointsBoosts } from '../services/points';
import { ENV } from '../services/web3/types';
import { LeverageVaultType } from '../types/multipleVaults';
import { SeasonType } from '../../pages/Points/constants/pointsTabs';

export const QUERYKEYS = {
  // LENDING
  lendingMarkets: ['lending', 'markets'],
  lendingMarketsWithoutReserves: ['lending', 'markets', 'withoutResources'],
  exactLendingMarket: (marketIdFromUrl?: string) =>
    marketIdFromUrl ? ['lending', 'exactMarket', marketIdFromUrl] : ['lending', 'exactMarket'],
  exactLendingMarketWithoutReserve: (marketIdFromUrl?: string) =>
    marketIdFromUrl
      ? ['lending', 'exactMarket', 'withoutResources', marketIdFromUrl]
      : ['lending', 'exactMarket', 'withoutResources'],
  lendingMarketsFromResources: ['lending', 'marketsFromResources'],
  multiplyVaults: (marketAddress: string, hasReserves: boolean) => ['multiplyVaults', marketAddress, hasReserves],
  multiplyVaultsExtraData: (marketAddress: string, hasReserves: boolean, token?: string) => [
    'lending',
    'multiply',
    token?.toString() || '',
    'extraData',
    marketAddress,
    hasReserves,
  ],
  jlpMultiplyVaults: (marketAddress: string) => ['jlpMultiplyVaults', marketAddress],
  pyusdMultiplyVaults: (marketAddress: string) => ['lending', 'multiply', 'pyusd', marketAddress],
  longLeverageVaults: ['longLeverageVaults'],
  shortLeverageVaults: ['shortLeverageVaults'],
  userLendingObligations: (walletPublicKey: PublicKeyAddress, filters: ObligationTypeTag[] = []) => {
    return ['user', walletPublicKey.toString(), 'lending', 'obligations', filters.sort().join(',')];
  },
  userAtas: (walletPublicKey: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'atas'];
  },
  getLeverageMultiplyMetrics: (marketAddress: PublicKeyAddress) => {
    return ['lending', marketAddress.toString(), 'leverage', 'metrics'];
  },
  getLendingObligation: (obligationAddress?: PublicKeyAddress) =>
    obligationAddress ? (['obligation', obligationAddress.toString()] as const) : (['obligation'] as const),
  getLendingObligations: (obligationAddresses: PublicKey[]) =>
    ['obligations', 'batch'].concat(obligationAddresses.map((address) => address.toString()).sort()),
  getObligationPnl: (obligationAddress: string) => ['obligation', 'pnl', obligationAddress] as const,
  getEpochData: () => ['epoch'] as const,
  getObligationsPnl: (obligationAddresses: string[]) =>
    ['obligations', 'pnl', obligationAddresses.map((address) => address.toString()).join('|')] as const,
  getObligationInterestFees: (obligationAddress: string) => ['obligation', 'interestFees', obligationAddress] as const,
  getObligationsInterestFees: (obligationAddresses: string[]) =>
    ['obligations', 'interestFees', obligationAddresses.map((address) => address.toString()).join('|')] as const,
  getObligationTransactionsHistory: (obligationAddress: string) =>
    ['obligation', obligationAddress, 'transactions'] as const,
  getUserObligationsTransactionsHistory: (walletPublicKey?: string | null) =>
    ['user', 'obligations', 'transactions', walletPublicKey] as const,
  geteGeericPoolInfo: (poolAddress: string) => ['poolInfo', poolAddress],
  // PRICES
  prices: ['prices'],
  rawPrices: ['rawPrices'],
  hermesPrices: ['hermes', 'prices'],
  currentSlot: ['currentSlot'],
  oraclePrices: (scopePricePubkey: PublicKey) => ['oraclePrices', scopePricePubkey.toString()] as const,
  getLSTStakeRates: () => {
    return ['stakeRates'] as const;
  },
  getJupiterPrice: (inputMint: PublicKeyAddress, outputMint: PublicKeyAddress) => {
    return ['prices', 'jupiter', inputMint.toString(), outputMint.toString()] as const;
  },
  getJupiterPriceFromQuote: (
    inputMint: PublicKeyAddress,
    outputMint: PublicKeyAddress,
    onlyDirectRoutes: boolean,
    maxAccounts?: number,
    excludeDexes?: QuoteGetRequest['excludeDexes']
  ) => {
    return [
      'prices',
      'jupiter',
      'quote',
      inputMint.toString(),
      outputMint.toString(),
      onlyDirectRoutes,
      maxAccounts || '-',
      (excludeDexes || []).join(','),
    ] as const;
  },
  getJupiterQuote: (
    amountLamports: Decimal,
    inputMint: PublicKeyAddress,
    outputMint: PublicKeyAddress,
    slippage: number,
    onlyDirectRoutes: boolean,
    maxAccounts?: number
  ) => {
    return [
      'jupiter',
      'quote',
      amountLamports.toString(),
      inputMint.toString(),
      outputMint.toString(),
      slippage,
      onlyDirectRoutes,
      maxAccounts,
    ] as const;
  },

  getLiquiditySingleSideDepositPriceImpactRequiredData: (
    strategyAddress: string,
    tokenAAmount: Decimal,
    tokenBAmount: Decimal,
    tokenAMint: PublicKeyAddress,
    tokenBMint: PublicKeyAddress,
    isVersionedTransactionEnabled: boolean,
    onlyDirectRoutes: boolean,
    slippage: Decimal,
    maxAccounts?: number,
    excludeDexes?: QuoteGetRequest['excludeDexes']
  ) => {
    return [
      'liquidity',
      'deposit',
      'required',
      strategyAddress.toString(),
      tokenAAmount.toString(),
      tokenBAmount.toString(),
      tokenAMint.toString(),
      tokenBMint.toString(),
      isVersionedTransactionEnabled,
      onlyDirectRoutes,
      slippage.toString(),
      maxAccounts?.toString() || '-',
      (excludeDexes || []).join(','),
    ] as const;
  },
  getLiquiditySingleSideWithdrawPriceImpactRequiredData: (
    strategyAddress: string,
    swapAmount: Decimal,
    tokenToSellMint: PublicKeyAddress,
    tokenToBuyMint: PublicKeyAddress,
    isVersionedTransactionEnabled: boolean,
    onlyDirectRoutes: boolean,
    slippage: Decimal,
    maxAccounts?: number,
    excludeDexes?: QuoteGetRequest['excludeDexes']
  ) => {
    return [
      'liquidity',
      'withdraw',
      'required',
      strategyAddress.toString(),
      swapAmount.toFixed(),
      tokenToSellMint.toString(),
      tokenToBuyMint.toString(),
      isVersionedTransactionEnabled,
      onlyDirectRoutes,
      slippage.toString(),
      maxAccounts?.toString() || '-',
      (excludeDexes || []).join(','),
    ] as const;
  },

  getRepayWithCollateralRequiredData: (
    amount: number,
    obligationAddress: string,
    selectedTokenMint: PublicKeyAddress,
    debtTokenMint: PublicKeyAddress,
    onlyDirectRoutes: boolean,
    slippage: Decimal,
    maxAccounts?: number,
    excludeDexes?: QuoteGetRequest['excludeDexes']
  ) => {
    return [
      'lending',
      'repayWithCollateral',
      amount.toString(),
      obligationAddress,
      selectedTokenMint.toString(),
      debtTokenMint.toString(),
      onlyDirectRoutes,
      slippage.toString(),
      maxAccounts?.toString() || '-',
      (excludeDexes || []).join(','),
    ] as const;
  },
  tokenPriceHistory: ['tokenPriceHistory'],
  // METRICS
  metrics: ['metrics'],
  // If you change this queryKey, don't forget to change the global balance invalidation logic for web3client.connection.onLogs in App.tsx
  getTokensBalances: (walletPublicKey: PublicKeyAddress, mints: PublicKeyAddress[]) => {
    const sortedMints = [...mints]
      .map((mint) => mint.toString())
      .sort()
      .join(',');
    return ['user', walletPublicKey.toString(), 'tokens', sortedMints, 'balances'];
  },
  // If you change this queryKey, don't forget to change the global balance invalidation logic for web3client.connection.onLogs in App.tsx
  getTokenBalance: (walletPublicKey: PublicKeyAddress, mint: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'tokens', new PublicKey(mint).toString(), 'balance'] as const;
  },
  getNativeSolBalance: (walletPublicKey: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'solNativeBalance'] as const;
  },
  getSharesBalance: (walletPublicKey: PublicKeyAddress, sharesMint: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'shares', sharesMint.toString(), 'balance'] as const;
  },
  getTopupVaultBalance: (walletPublicKey: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'topup', 'vault', 'balance'] as const;
  },
  getStrategyDetails: (address: PublicKeyAddress) => {
    return ['strategies', address.toString(), 'details'] as const;
  },
  stakingYields: ['stakingYields'],
  getFeesAndRewardsStrategies: (period: FetchFeesAndRewardsStrategiesProps['period']) => [
    'feesAndRewardsStrategies',
    ...period,
  ],
  getUserFeesAndRewards: (walletPublicKey: PublicKeyAddress, strategyAddress: PublicKeyAddress) => [
    'user',
    walletPublicKey.toString(),
    'strategy',
    strategyAddress.toString(),
    'fees',
  ],
  getUserPositionPnL: (walletPublicKey: PublicKeyAddress, strategyAddress: PublicKeyAddress) => [
    'user',
    walletPublicKey.toString(),
    'strategy',
    strategyAddress.toString(),
    'pnl',
  ],
  getStrategyPerformanceFees: (strategyAddress: string) => ['strategy', strategyAddress, 'performanceFees'],
  getTotalFeesAndRewardsStrategies: ['totalFeesAndRewardsStrategies'],
  getTotalKaminoVolume: ['totalKaminoVolume'],
  getTvl: ['tvl'],
  geoBlocking: ['geoBlocking'],
  risk: (walletPublicKey?: string | null) => ['risk', walletPublicKey],
  getAta: (walletPublicKey: PublicKeyAddress, mint: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'ata', mint.toString()] as const;
  },
  strategiesVolume: ['strategiesVolume'],
  getLiquidityDistribution: (poolAddress: PublicKeyAddress) => ['liquidityDistribution', poolAddress] as const,
  depositableTokens: ['depositableTokens'],
  // MultiplyVaults
  // TODO: rename to getLeverageVault as it is more correct name
  // used both for Leverage and Multiply vaults
  getMultiplyVault: (depositReserveAddress: PublicKeyAddress, borrowReserveAddress: PublicKeyAddress) => {
    return [
      'lending',
      'multiply',
      'vault',
      depositReserveAddress.toString(),
      borrowReserveAddress ? borrowReserveAddress.toString() : '',
    ];
  },
  getUserLutAndSetupIxns: (
    walletPublicKey: string,
    vaultType: LeverageVaultType,
    collTokenMint: string,
    debtTokenMint: string
  ) => {
    return [
      'user',
      walletPublicKey.toString(),
      vaultType.toString(),
      collTokenMint,
      debtTokenMint,
      'userLutAddress',
      'setupIxns',
    ];
  },
  getPriceImpactRequiredData: ({
    operation,
    amount,
    marketAddress,
    obligationAddress,
    selectedTokenMint,
    debtTokenMint,
    collTokenMint,
    leverage,
    leverageType,
    slippagePct,
  }: {
    operation: 'deposit' | 'withdraw' | 'adjust';
    amount: number;
    marketAddress: PublicKeyAddress;
    obligationAddress?: PublicKeyAddress;
    selectedTokenMint: PublicKeyAddress;
    debtTokenMint: PublicKeyAddress;
    collTokenMint: PublicKeyAddress;
    leverageType: LeverageVaultType;
    leverage: number;
    slippagePct: Decimal;
  }) => {
    return [
      'lending',
      'leverage',
      operation.toString(),
      amount.toString(),
      marketAddress.toString(),
      obligationAddress?.toString() || '-',
      selectedTokenMint.toString(),
      debtTokenMint.toString(),
      collTokenMint.toString(),
      leverage.toString(),
      leverageType.toString(),
      slippagePct.toString(),
    ] as const;
  },
  getUserLutAndSetupIxnsRepayWithCollateral: (walletPublicKey: string, obligationAddress: string) => {
    return ['user', walletPublicKey.toString(), obligationAddress.toString(), 'userLutAddress', 'setupIxns'];
  },
  getStrategies: (strategiesAddresses: PublicKey[]) => ['strategiesData', strategiesAddresses],
  getStrategyByAddress: (strategyPubkey: string) => ['strategy', strategyPubkey],

  getStrategiesCleanedUp: (subKey: string) => ['kaminoResourcesStrategiesCleanedUp', subKey],
  getOrcaWhirlpools: (orcaWhirlpoolsPubKeys: PublicKey[]) => ['orcaWhirlpools', orcaWhirlpoolsPubKeys],
  getRaydiumWhirlpools: (raydiumWhirlpoolsPubKeys: PublicKey[]) => ['raydiumWhirlpools', raydiumWhirlpoolsPubKeys],
  getMeteoraPools: (meteoraPoolsPubKeys: PublicKey[]) => ['meteoraPools', meteoraPoolsPubKeys],
  getRaydiumFeesRates: (raydiumWhirlpoolsPubKeys: PublicKey[]) => ['raydiumFeesRates', raydiumWhirlpoolsPubKeys],
  getFarmsRewards: (walletPublicKey: PublicKeyAddress) => {
    return ['farms', walletPublicKey.toString()] as const;
  },
  getPoolSimulatedValuesKeys: (
    props: Omit<UsePoolSimulatedValuesQuery, 'startDate' | 'endDate'> & { startDate: string; endDate: string }
  ) => [
    'pool',
    props.poolAddress,
    props.priceLower,
    props.priceUpper,
    props.rebalanceMethod,
    props.startDate,
    props.endDate,
  ],
  priorityFeeStats: ['priorityFeeStats'],
  getStrategyRangeHistory: (strategyPubkey: string, start: string, end: string) => [
    'strategy',
    strategyPubkey,
    'chart',
    'rangeHistory',
    start,
    end,
  ],
  getStrategyMetricsHistory: (strategyPubkey: string, start: string, end: string) => [
    'strategy',
    strategyPubkey,
    'chart',
    'metricsHistory',
    start,
    end,
  ],
  getStrategyShareholdersHistory: (strategyPubkey: string, shareholder: string, start: string, end: string) => [
    'strategy',
    strategyPubkey,
    'chart',
    'history',
    shareholder,
    start,
    end,
  ],
  getUserPositionsQuery: (walletPublicKey: PublicKeyAddress, filters: StrategiesFilters = {}) => {
    const filtersKeys = convertFiltersToQueryKeys(filters);
    return ['positions', walletPublicKey.toString(), 'query'].concat(...filtersKeys);
  },
  getDIYStrategies: () => {
    return ['strategies', 'diy', 'count'];
  },
  getUserDIYStrategies: (walletPublicKey: string | null) => {
    return ['strategies', 'diy', 'count', walletPublicKey?.toString() || ''];
  },
  getStrategiesShareData: (queryKey: string, strategiesUpdatedAt: number) => {
    return ['strategiesShareData', queryKey, strategiesUpdatedAt];
  },
  getStrategyPerformance: (strategyPubkey: PublicKeyAddress, depositDate: string) => [
    'strategy',
    strategyPubkey.toString(),
    'chart',
    'performance',
    depositDate,
  ],
  getStrategyAdressesWithShareMints: () => {
    return ['strategies', 'with', 'shareMints'];
  },
  getStrategyRebalanceParams: (strategy: PublicKeyAddress) => ['strategy', strategy.toString(), 'params'],
  getStrategyRebalanceState: (strategy: PublicKeyAddress) => ['strategy', strategy.toString(), 'state'],
  getStrategyReferencePriceType: (strategy: PublicKeyAddress) => [
    'strategy',
    strategy.toString(),
    'referencePriceType',
  ],
  getTransactionsHistory: (walletPublicKey: string) => ['transactionsHistory', walletPublicKey],

  getPoolByAddress: (poolAddress: string, strategyDex: string) => ['whirlpool', strategyDex, poolAddress],
  getPositionRangeQuery: (dex: Dex | string, position: string) => ['position', position, dex, 'range'],
  getLendingLoanHistory: (obligationAddress: string, startDate: string, endDate: string) => [
    'lending',
    'chart',
    'obligation',
    obligationAddress,
    'history',
    startDate,
    endDate,
  ],
  lendingReserveHistory: (
    startDate: string,
    endDate: string,
    reserveAddress: PublicKeyAddress,
    frequency?: 'hour' | 'day'
  ) => ['lending', 'chart', 'reserve', reserveAddress.toString(), 'reserveHistory', startDate, endDate, frequency],
  lendingReserveBorrowAndStakingHistory: (startDate: string, endDate: string, reserveAddress: PublicKeyAddress) => [
    'lending',
    'chart',
    'reserve',
    reserveAddress.toString(),
    'reserveHistory',
    'borrowAndStaking',
    startDate,
    endDate,
  ],
  // FARMS
  getFarmsQuery: (addresses: PublicKeyAddress[]) => {
    return ['farms', 'all', addresses.map((mint) => mint.toString()).join('|')] as const;
  },
  getFarm: (address: PublicKeyAddress) => {
    return ['farms', address.toString()] as const;
  },
  getFarmUserStates: (walletPublicKey: PublicKeyAddress, farmAddress: PublicKeyAddress) =>
    ['user', walletPublicKey.toString(), 'farms', farmAddress.toString()] as const,
  getFarmsByAddresses: (farmAddresses: PublicKeyAddress[]) =>
    ['farms', farmAddresses.map((address) => address.toString()).join('|')] as const,
  getUserFarmRemainingLockingPeriod: (walletPublicKey: PublicKeyAddress, farmAddress: PublicKeyAddress) =>
    ['user', walletPublicKey.toString(), 'farms', farmAddress.toString(), 'locking', 'remaining'] as const,
  getUndelegatedFarmUserPosition: (walletPublicKey: PublicKeyAddress, farmAddress: PublicKeyAddress) =>
    ['user', walletPublicKey.toString(), 'farms', farmAddress.toString(), 'position'] as const,
  // Referal
  getTotalUsersReferred: (referrer: string) => ['referrer', referrer, 'getTotalUsersReferred'],
  getAllReferrerFeesCumulative: (referrer: string) => ['referrer', referrer, 'getAllReferrerFeesCumulative'],
  getAllReferrerFeesUnclaimed: (referrer: string) => ['referrer', referrer, 'getAllReferrerFeesUnclaimed'] as const,
  getReferrerByUrl: (url: string) => ['referralUrl', url],
  getReferrerUrl: (walletPublicKey: PublicKeyAddress) => ['referrer', walletPublicKey.toString(), 'url'] as const,
  // Content
  getJlpPoolApy: ['getJlpPoolApy'],
  getSUsdEPoolApy: ['getSUsdEPoolApy'],
  getPyusdReserveApy: ['getPyusdReserveApy'],
  getKaminoResourcesQuery: ['getKaminoResourcesQuery'],
  getKaminoPointsQuery: ['getKaminoPointsQuery'],
  // GLOBAL CONFIG AND INFO
  globalConfig: ['globalConfig'],
  collateralInfos: ['collateralInfos'],
  tokensInfosApi: ['tokensInfosApi'],
  tokensInfosFallback: ['tokensInfosFallback'],
  getKaminoStatusQuery: ['getKaminoStatusQuery'],
  // POINTS
  getPointsLeaderboard: (offset: number, limit: number, season: string) => {
    return ['points', 'leaderboard', season, offset, limit];
  },
  getPointsUserBreakdown: (env: ENV, walletPublicKey: PublicKeyAddress, season: SeasonType) => {
    return ['user', walletPublicKey.toString(), 'points', season, 'breakdown', env.toString()] as const;
  },
  getPointsS2UserBreakdown: (env: ENV, walletPublicKey: PublicKeyAddress) => {
    return ['user', walletPublicKey.toString(), 'points', 's2', 'breakdown', env.toString()] as const;
  },
  getAllPointsData: ['points'] as const,
  getPointsBoosts: ({ env }: GetPointsBoosts) => {
    return ['points', 'boosts', env.toString()] as const;
  },
  getPointsMetrics: (season: SeasonType) => {
    return ['points', 'metrics', season] as const;
  },
  getKmnoAllocation: (walletAddress: string, season: SeasonType) => {
    return ['user', walletAddress, 'kmnoAllocation', season] as const;
  },
  getKmnoClaimState: (walletPublicKey: string, merkleDistributorAddress: string) => {
    return ['user', walletPublicKey, 'kmnoKmnoClaimState', merkleDistributorAddress] as const;
  },
  getDistributor: (walletPublicKey: string) => {
    return ['user', walletPublicKey, 'distributor'] as const;
  },
  getLendingStakingYields: (mintAdress: string, startDate: string, endDate: string) => {
    return ['lendingStakingYields', mintAdress, startDate, endDate] as const;
  },
  lendingPoolYieldHistory: (poolAddress: string) => {
    return ['lendingPoolYieldHistory', poolAddress] as const;
  },
  getPairLimitOrders: (inputMint: PublicKeyAddress, outputMint: PublicKeyAddress) => {
    return ['limitOrders', inputMint, outputMint] as const;
  },
  getOrderbookData: () => {
    return ['limitOrders', 'orderbookData'] as const;
  },
  getLatestFilledOrders: (inputMint: PublicKeyAddress, outputMint: PublicKeyAddress) => {
    return ['lastFilledOrders', inputMint, outputMint] as const;
  },
  getMakerOpenOrders: (walletPublicKey: PublicKeyAddress) => {
    return ['makerOpenOrders', walletPublicKey] as const;
  },
  getEarnVaultByAddress: (address: string) => ['getEarnVaultByAddress', address],
  getAllEarnVaults: ['getAllEarnVaults'],
  getEarnVaultsTokensPerShareMap: ['getEarnVaultsTokensPerShareMap'],
  getEarnVaultsPricePerShareMap: ['getEarnVaultsPricePerShareMap'],
  getEarnVaultsInterestEarnedMap: ['getEarnVaultsInterestEarnedMap'],
  getEarnVaultsSupplyAPYMap: ['getEarnVaultsSupplyAPYMap'],
  getEarnVaultsCollateralsMap: ['getEarnVaultsCollateralsMap'],
  getUserSharesBalanceAllVaults: (wallet: string | null) => ['getUserSharesBalanceAllVaults', wallet],
  getEarnVaultUserShares: (userAddress: string, vaultAddress: PublicKey) => [
    'getEarnVaultUserShares',
    vaultAddress,
    userAddress,
  ],
};
