import { useQuery } from '@tanstack/react-query';
import { VsrClient } from '../plugin/VoterStakeRegistry/client';
import { registrarKey } from '../plugin/VoterStakeRegistry/utils';
import { useWeb3Client } from '../../../../../shared/hooks/useWeb3Client';
import { communityTokenMint, realmPublicKey, voterWeightAddin } from '../../../utils';

export function useGetRegistrar() {
  const { web3client } = useWeb3Client();

  return useQuery({
    queryKey: ['get-registrar', { realmId: realmPublicKey, tokenMint: communityTokenMint }],
    queryFn: async () => {
      const connection = web3client?.connection;
      if (!connection) {
        return null;
      }
      if (!realmPublicKey) {
        return null;
      }

      const vsrClient = VsrClient(connection, voterWeightAddin);

      const registrar = registrarKey(realmPublicKey, communityTokenMint, voterWeightAddin);

      try {
        const registrarData = await vsrClient.account.registrar.fetch(registrar);

        return {
          data: registrarData,
          publicKey: registrar,
          programId: voterWeightAddin,
        };
      } catch (err) {
        return null;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
