import { ReactElement, SVGProps } from 'react';

export const SvgOrderSell = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={13} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.834 4.5 6.334 8l-3.5-3.5"
      stroke="#E84860"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
