export function isDeployPreview() {
  // TODO: webworker change, undo if reverted
  if (!window?.document?.location?.href?.search) {
    return false;
  }
  return (
    window.document.location.href.search('kamino-webapp.pages.dev') > -1 ||
    window.document.location.href.search('.humandonedev.com') > -1 ||
    window.document.location.href.search('localhost') > -1
  );
}
