import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PublicKeyAddress } from '../../../shared/types/strategies';
import { QUERYKEYS } from '../../../shared/constants/queryKeys';
import { ObligationInterestFeesResponse, ObligationPnlResponse } from '../../../shared/types/lending';

export const useCacheLendingData = () => {
  const queryClient = useQueryClient();

  const getPnLFromCache = useCallback(
    (obligationAddress: PublicKeyAddress) => {
      const queryKey = QUERYKEYS.getObligationPnl(obligationAddress.toString());
      return queryClient.getQueryData(queryKey) as ObligationPnlResponse;
    },
    [queryClient]
  );

  const getInterestFeesFromCache = useCallback(
    (obligationAddress: PublicKeyAddress) => {
      const queryKey = QUERYKEYS.getObligationInterestFees(obligationAddress.toString());
      const cachedData = queryClient.getQueryData(queryKey) as ObligationInterestFeesResponse;
      return cachedData?.totalFeesEarnedObligation[obligationAddress.toString()];
    },
    [queryClient]
  );

  return { getPnLFromCache, getInterestFeesFromCache };
};
