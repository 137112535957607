import React, { FC } from 'react';
import { withStyles } from '@bruitt/classnames';
import { Button } from '../../../../shared/uiKitV2/Button';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { shorterString } from '../../../../shared/utils';
import useEnv from '../../../../shared/hooks/useEnv';
import s from './ConnectedWalletButton.module.less';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';

const sx = withStyles(s);

interface ConnectedWalletButtonProps {
  variant?: 'mobile';
}

export const ConnectedWalletButton: FC<ConnectedWalletButtonProps> = ({ variant }) => {
  const { walletPublicKey, wallet } = useEnv();
  const { updateProviderParams } = layoutProviderStore;

  if (!wallet || !walletPublicKey) {
    return null;
  }

  const handleClick = () => {
    updateProviderParams({ isSidePanelRendered: true, isSidePanelOpened: true });
  };

  return (
    <div className={sx('root', { variant })}>
      <Button
        startIcon={<img alt={wallet.adapter.name} className={sx('icon')} src={wallet.adapter.icon} />}
        className={sx('button')}
        variant="secondary"
        size="large"
        onClick={handleClick}
      >
        <>
          <Text className={sx('walletAddress')} fs={14}>
            {shorterString(walletPublicKey, 4)}
          </Text>
        </>
      </Button>
    </div>
  );
};
