import { ReactElement, SVGProps } from 'react';

export const SvgSettingsFilled = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17" {...props}>
    <path
      fill="currentColor"
      d="M13.4 6.15c-1.207 0-1.7-.853-1.1-1.9.347-.606.14-1.38-.467-1.726l-1.153-.66a1.113 1.113 0 0 0-1.52.4l-.073.126c-.6 1.047-1.587 1.047-2.194 0l-.073-.126c-.3-.527-.98-.714-1.507-.4l-1.153.66a1.272 1.272 0 0 0-.467 1.733C4.3 5.297 3.807 6.15 2.6 6.15a1.27 1.27 0 0 0-1.267 1.267V8.59A1.27 1.27 0 0 0 2.6 9.857c1.207 0 1.7.853 1.093 1.9-.346.607-.14 1.38.467 1.726l1.153.66a1.113 1.113 0 0 0 1.52-.4l.074-.126c.6-1.047 1.586-1.047 2.193 0l.073.126c.314.527.994.714 1.52.4l1.154-.66a1.266 1.266 0 0 0 .466-1.726c-.606-1.047-.113-1.9 1.094-1.9a1.27 1.27 0 0 0 1.266-1.267V7.417A1.28 1.28 0 0 0 13.4 6.15ZM8 10.17a2.17 2.17 0 0 1-2.167-2.166A2.17 2.17 0 0 1 8 5.837a2.17 2.17 0 0 1 2.167 2.167A2.17 2.17 0 0 1 8 10.17Z"
    />
  </svg>
);
