import { ObligationTypeTag } from '@kamino-finance/klend-sdk';

import { PublicKeyAddress } from '../../shared/types/strategies';
import { Token } from '../../shared/constants/tokens';
import { MarketMetadata } from '../../shared/utils/lending/getMarketMetadata';

export interface BaseTransactionHistoryTableData {
  id: string;
  date: string;
  usdValue: string;
  transactionViewHref: string;
  tokens: Token[];
  name: string;
  transactionName: string;
  transactions: TransactionItem[];
  timestamp: number;
}

export interface LendingTransaction extends BaseTransactionHistoryTableData {
  type: 'lending';
  lendingMetadata: {
    tag: ObligationTypeTag;
    marketMetadata?: MarketMetadata;
    marketAddress: string;
    obligationAddress: string;
  };
}

interface LiquidityTransaction extends BaseTransactionHistoryTableData {
  type: 'liquidity';
}

export type TransactionHistoryTableData = LendingTransaction | LiquidityTransaction;

export interface TransactionHistoryTableDataUnion
  extends BaseTransactionHistoryTableData,
    Omit<LendingTransaction, 'type'>,
    Omit<LiquidityTransaction, 'type'> {
  type: 'lending' | 'liquidity';
}

export interface TransactionItem {
  token: Token;
  tokenMint: PublicKeyAddress;
  price: string;
  tooltipAmount: string;
}

export const TRANSACTIONS_TYPE: { [key: string]: string } = {
  deposit: 'Deposit',
  depositAndInvest: 'Deposit',
  withdraw: 'Withdraw',
};

export enum ObligationTransactionType {
  create = 'Create',
  withdraw = 'Withdraw',
  supply = 'Deposit',
  borrow = 'Borrow',
  repay = 'Repay',
  liquidate = 'Liquidation',
}
