import { useMemo } from 'react';
import { BlacklistOverlay } from '../shared/components/BlacklistOverlay/BlacklistOverlay';
import useEnv from '../shared/hooks/useEnv';
import { isBlacklistedWallet } from '../shared/utils';

export const BlacklistOverlayProvider = ({ children }: { children: JSX.Element }) => {
  const { walletPublicKey } = useEnv();

  const isBlacklisted = useMemo(() => {
    return isBlacklistedWallet(walletPublicKey);
  }, [walletPublicKey]);

  if (isBlacklisted && walletPublicKey) {
    return <BlacklistOverlay walletAddress={walletPublicKey} />;
  }

  return <>{children}</>;
};
