export const META_DESCRIPTION = {
  DEFAULT:
    "Solana's Borrowing, Lending, Market Making, and Leverage venue. Earn yield on SOL, USDC, JUP, JLP, JitoSOL, and more",
  DEFAULT_LONG: `Kamino is a Solana DeFi protocol that unifies Lending, Liquidity, and Leverage into a single, secure DeFi product suite.

	You can use Kamino to borrow and lend crypto assets; leverage your SOL staking yield, provide leveraged liquidity to DEXs, and earn automated market making yields. Deploy any Solana assets for yield, including SOL, USDC, USDT, JUP, JLP and more.
	
	Kamino's product suite is packaged into an industry-leading UX that offers transparent analytics, detailed performance data, and extensive position info.`,
  LIQUIDITY:
    "Provide liquidity into Kamino's automated liquidity vaults on Solana. Earn market making yield on SOL, USDC, JUP, JLP, JitoSOL, and more",
  MULTIPLY:
    'Boost your SOL staking yield with leveraged liquid staking on Kamino Multiply. Deposit SOL, mSOL, JitoSOL, or bSOL',
  EARN: 'TODO: Add meta description',
  LEVERAGE:
    'Leverage your trading exposure on Solana using Kamino Lend. Take leveraged long or short positions on SOL, JitoSOL, mSOL and more',
  PORTFOLIO:
    'Track your Lending, Leverage and Liquidity positions on Kamino. Claim rewards, view PnL and browse your Solana transaction history',
  POINTS:
    'Use Kamino products to earn points and rewards. View the points leaderboard and track your point earnings via the Kamino Points Hub',
  REFERRALS: 'Refer new users via Kamino Referrals to earn protocol revenue and referral points',
  GENEIS: 'Giving you ownership of the Kamino Ecosystem',
  STAKE: 'Stake your KMNO to boost your points',
  S2AIRDROP: 'KMNO Season 2 Allocation',
  GOVERNANCE_AND_STAKING: 'Stake your KMNO to boost points, vote on proposals, and earn rewards',
  TRADINGVIEW:
    'Our charts are fueled by TradingView, a worldwide platform for investors to monitor prices of top stocks and assets, access news, and discover trading ideas',
};
