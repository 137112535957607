import { ReactElement, SVGProps } from 'react';

export const SvgSocialDiscord = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm2.145-17.998c1.072.181 2.096.5 3.053.931.008.003.015.01.019.018 1.694 2.45 2.53 5.217 2.218 8.4a.048.048 0 0 1-.02.034 12.477 12.477 0 0 1-3.751 1.863.049.049 0 0 1-.03 0 .049.049 0 0 1-.023-.018 9.803 9.803 0 0 1-.765-1.226.047.047 0 0 1 .025-.065 7.714 7.714 0 0 0 1.171-.548.047.047 0 0 0 .018-.06.046.046 0 0 0-.014-.018 5.518 5.518 0 0 1-.233-.18.046.046 0 0 0-.049-.006c-2.425 1.102-5.083 1.102-7.538 0a.047.047 0 0 0-.048.006 6.61 6.61 0 0 1-.233.18.047.047 0 0 0 .005.078 8.25 8.25 0 0 0 1.17.548c.026.01.039.04.026.065a8.6 8.6 0 0 1-.767 1.227.049.049 0 0 1-.052.016 12.519 12.519 0 0 1-3.745-1.862.05.05 0 0 1-.02-.034c-.262-2.754.271-5.543 2.216-8.402a.044.044 0 0 1 .02-.017c.957-.431 1.982-.75 3.053-.931a.048.048 0 0 1 .05.023c.142.25.271.506.385.769 1.137-.17 2.293-.17 3.43 0 .102-.237.248-.538.38-.769a.047.047 0 0 1 .049-.022Zm-5.98 6.184c0 .82.609 1.488 1.347 1.488.751 0 1.348-.668 1.348-1.488.012-.814-.591-1.487-1.348-1.487-.75 0-1.348.668-1.348 1.487Zm4.984 0c0 .82.608 1.488 1.348 1.488.756 0 1.348-.668 1.348-1.488.011-.814-.592-1.487-1.349-1.487-.75 0-1.348.668-1.348 1.487Z"
      clipRule="evenodd"
    />
  </svg>
);
