import * as Sentry from '@sentry/react';

export const captureDetailedError = (notification: {
  title: string;
  description: string;
  transactionId?: string;
  userWallet?: string;
}) => {
  Sentry.withScope((scope) => {
    // Set a tag for filtering these specific error logs
    scope.setTag('notification_error', 'true');

    if (notification.transactionId) {
      scope.setContext('transaction', { id: notification.transactionId });
    }

    scope.setUser({ id: notification.userWallet });

    Sentry.captureMessage(`Notification: ${notification.title} - ${notification.description}`);
  });
};
