import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Modal } from '../../../Modal';
import s from './TokensPopup.module.less';
import { Text } from '../../../Typography';
import { Input } from '../../../Input';
import { Divider } from '../../../../components/Divider';
import { Icon } from '../../../Icon';
import { TokenItem } from './components/TokenItem/TokenItem';
import { TokenWithPrice } from '../../../../../features/SidePanel/types/tokenWithPrice';
import { TOKENS_HIGHLIGHTS } from './constants/tokensHighlights';
import useTokens from '../../../../hooks/useTokens';
import { TokenIcon } from '../../../TokenIcon';

type TokensPopupProps = {
  selectedToken: string;
  tokens: TokenWithPrice[];
  isVisible: boolean;
  onSelect: (address: string) => void;
  onVisibleChange: (isVisible: boolean) => void;
};

export const TokensPopup: FC<TokensPopupProps> = ({ selectedToken, tokens, isVisible, onSelect, onVisibleChange }) => {
  const [filteredTokens, setFilteredTokens] = useState(tokens);
  const [searchQuery, setSearchQuery] = useState('');
  const parentRef = useRef(null);
  const { getToken } = useTokens();

  const handleClose = () => {
    onVisibleChange(false);
  };

  const debounceFilter = useCallback(
    debounce((value, tokensList: TokenWithPrice[]) => {
      if (value) {
        setFilteredTokens(tokensList.filter((token) => token.symbol.toLowerCase().includes(value.toLowerCase())));
      } else {
        setFilteredTokens(tokensList);
      }
    }, 500),
    []
  );

  useEffect(() => {
    debounceFilter(searchQuery, tokens);
  }, [debounceFilter, searchQuery, tokens]);

  const handleChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Modal isOpen={isVisible} onClose={handleClose} isFluid isOverSidepanel>
      <div className={s.content}>
        <div className={s.header}>
          <Text fs={18} lh={22}>
            Select Tokens
          </Text>
          <Input
            defaultValue={searchQuery}
            placeholder="Search..."
            size="small"
            onChange={handleChange}
            prefix={<Icon name="Search" size={16} />}
            autoFocus
          />
        </div>
        <Divider />
        <div className={s.highlights}>
          {TOKENS_HIGHLIGHTS.map((tokenMint) => {
            const token = getToken(tokenMint);
            return (
              <div className={s.highlightItem} onClick={() => onSelect(tokenMint)}>
                <TokenIcon tokenMint={tokenMint} size={16} />{' '}
                <Text fs={14} lh={20}>
                  {token.symbol}
                </Text>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className={s.tokensHeader}>
          <Text fs={12} lh={16} weight="bold" color="grey-deep">
            Asset
          </Text>
          <Text fs={12} lh={16} weight="bold" color="grey-deep">
            Wallet Balance
          </Text>
        </div>
        <div className={s.tokensList} ref={parentRef}>
          {filteredTokens.map((token) => (
            <TokenItem
              key={token.address}
              onSelect={onSelect}
              token={token}
              isActive={selectedToken === token.address}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
