import { ReactElement, SVGProps } from 'react';

export const SvgDownload = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4 14h8M8 2v9.333M11.333 8l-3.334 3.333L4.666 8"
    />
  </svg>
);
