import React, { FC } from 'react';
import { withStyles } from '@bruitt/classnames';

import style from './Typography.module.less';

import { ColorProps } from '../../types/global/colors';

const sx = withStyles(style);

type Size = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32;
type LineHeight = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36;

export type CssTextAlign = 'left' | 'center' | 'right';

interface CommonProps {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  weight?: 'medium' | 'regular' | 'bold';
  uppercase?: boolean;
  underline?: boolean;
  textAlign?: CssTextAlign;
}

type MediaType = 'tablet' | 'desktop' | 'large';

type MediaProps = {
  [K in MediaType as `${K}Fs`]?: Size;
} & {
  [K in MediaType as `${K}Lh`]?: LineHeight;
};

export interface TextProps extends CommonProps, MediaProps, ColorProps {
  fs?: Size;
  lh?: LineHeight;
}

export const Text: FC<TextProps> = ({
  children,
  tag = 'p',
  fs = 14,
  lh = 20,
  weight = 'medium',
  underline = false,
  textAlign = 'left',
  className,
  color,
  ...rest
}) => {
  const Tag = tag;

  return (
    <Tag className={sx('root', className, { fs, lh, weight, underline, textAlign, color, ...rest })}>{children}</Tag>
  );
};
