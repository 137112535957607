import { useState, useEffect } from 'react';

interface UseLocalStorageStateProps<T> {
  key: string;
  defaultValue?: T;
}

export const useLocalStorageState = <T>({
  key,
  defaultValue,
}: UseLocalStorageStateProps<T>): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
  const [state, setState] = useState<T | undefined>(() => {
    return getItemFromLocalStrorage(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export const getItemFromLocalStrorage = <T>(key: string, defaultValue: T) => {
  const storedValue = localStorage.getItem(key);
  let value = defaultValue;

  try {
    value = storedValue !== null ? (JSON.parse(storedValue) as T) : defaultValue;
  } catch (e) {
    // eslint-disable-next-line
  }

  return value;
};
