/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useHotjar from 'react-use-hotjar';
import * as Sentry from '@sentry/react';
import { MixpanelProvider, useMixpanel } from 'react-mixpanel-browser';
import Cookies from 'js-cookie';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';

import { registerMoonGateWallet } from '@moongate/moongate-adapter';
import { isMobile, osName } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import Decimal from 'decimal.js';
import { Router as NewRouter } from './shared/routes';
import { featureFlags, noop } from './shared/utils';
import useEnv from './shared/hooks/useEnv';
import { useWeb3Client } from './shared/hooks/useWeb3Client';
import { NotificationProvider } from './shared/contexts/NotificationsContext';

import './App.less';
import { captureError } from './shared/utils/captureError';

import debugWallet from './shared/utils/debugWallet';
import { SvgGradients } from './shared/uiKitV2/Icon/SvgGradients';
import { isBalanceQueryKey } from './shared/utils/queries/isBalanceQueryKey';
import { TRANSACTION_CANCELED, USER_REJECTED_REQUEST_MESSAGE } from './shared/constants/errors';
import { QUERYKEYS } from './shared/constants/queryKeys';
import { BlacklistOverlayProvider } from './providers/BlacklistOverlayProvider';
import { getErrorMessage } from './shared/utils/getErrorMessage';
import { WalletsProvider } from './providers/WalletsProvider/WalletsProvider';
// TODO: webworker change, undo if reverted
import { WorkerProvider } from './workers/provider';
import { KaminoSdkContextProvider } from './shared/hooks/useKaminoSdk';

Sentry.init({
  enabled: import.meta.env.VITE_APP_ENV !== 'development',
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, hint) => {
    const exception = hint.originalException;

    // don't send error to Sentry if user reject transaction
    // @ts-ignore
    const exceptionMsg = getErrorMessage(exception.err) || getErrorMessage(exception.error);
    if (
      String(exceptionMsg).toLowerCase().includes(USER_REJECTED_REQUEST_MESSAGE.toLowerCase()) ||
      String(exceptionMsg).toLowerCase().includes(TRANSACTION_CANCELED.toLowerCase())
    ) {
      return null;
    }

    return event;
  },
});

const myCustomLogger = console.info;

const KaminoAnalyticsConsentProvider = ({ children }: { children: JSX.Element }) => {
  const mixpanel = useMixpanel();
  const { initHotjar } = useHotjar();

  useEffect(() => {
    window._enzuzocb = window._enzuzocb || [];
    window._enzuzocb.push(() => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mixpanel.set_config({ ip: true, disable_persistence: false });
    });

    // enable hotjar tracking if user gave a concent for it
    window._enzuzocb.push(() => {
      initHotjar(3263975, 6, false, myCustomLogger);
    });

    window._haveEnzuzocbSet = true;
  }, [initHotjar, mixpanel]);

  return <>{children}</>;
};

// Utility function to convert UUID to number
const uuidToBigInt = (uuid: string) => {
  const hexString = uuid.replace(/-/g, '');
  return BigInt(`0x${hexString}`);
};

const MixpanelEventsMonitor = () => {
  // store mixpanel uuid
  const lcIdKey = 'mixpanelTrackingId';
  // save if mixpanel enabled for current user to avoid the same check again and again
  const lcIsEnabledKey = 'mixpanelTrackingId';
  const { wallet } = useWallet();
  const { walletPublicKey } = useEnv();
  const mixpanel = useMixpanel();
  // track every 50th user
  const mixpanelSampleRate = 50;

  // implement custom mixpanel sample rate
  // track every 50th user
  const handleMixpanelTracking = useCallback(() => {
    // disabled mixpanel tracking
    if (debugWallet) {
      mixpanel.opt_out_tracking();
      return;
    }

    const isMixpanelEnabled =
      !featureFlags.isMixpanelSampleRateEnabled || Boolean(localStorage.getItem(lcIsEnabledKey));
    // small optimization to avoid the same checks every time user opens the app
    if (isMixpanelEnabled) {
      mixpanel.opt_in_tracking();
      return;
    }
    const lcUserId = localStorage.getItem(lcIdKey);
    let userId = lcUserId;

    if (!lcUserId) {
      userId = uuidv4();
      localStorage.setItem(lcIdKey, userId?.toString() || '');
    }

    const userIdDecimal = userId ? new Decimal(uuidToBigInt(userId).toString()) : new Decimal(0);
    if (userIdDecimal && userIdDecimal.gt(0) && userIdDecimal.mod(mixpanelSampleRate).eq(0)) {
      // enable mixpanel tracking
      mixpanel.opt_in_tracking();
      // save the state into LC so we do not need to check again
      localStorage.setItem(lcIsEnabledKey, 'true');
    } else {
      mixpanel.opt_out_tracking();
      localStorage.removeItem(lcIsEnabledKey);
    }
  }, [mixpanel]);

  useEffect(() => {
    handleMixpanelTracking();
  }, [handleMixpanelTracking, mixpanel]);

  useEffect(() => {
    if (walletPublicKey && wallet) {
      mixpanel.track('wallet:connected', {
        walletName: wallet.adapter.name,
        walletPublicKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletPublicKey, wallet]);

  useEffect(() => {
    mixpanel.people.set({
      walletAddress: walletPublicKey,
      os: osName,
      isMobile,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletPublicKey]);

  useEffect(() => {
    if (walletPublicKey) {
      mixpanel.people.append({ walletAddress: walletPublicKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletPublicKey]);

  return null;
};

/**
 * Invalidate entire cache every time user send transaction
 * Not the most effecient wayt to invalidate
 * Temp solution while we are working on refactring and adding more precise invalidate logic
 * @param children
 * @constructor
 */
const InvalidateCacheSubscription = () => {
  const { web3client } = useWeb3Client();
  const { walletPublicKey } = useEnv();
  const queryClient = useQueryClient();
  const subId = useRef<number>();

  useEffect(() => {
    if (!web3client || subId.current || !walletPublicKey) {
      return;
    }

    const { getTokensBalances, getTokenBalance, getNativeSolBalance, userAtas } = QUERYKEYS;

    //  List of query keys that should be invalidated after every transaction
    const includeKeys = [
      getTokensBalances(walletPublicKey, []),
      userAtas(walletPublicKey),
      getTokenBalance(walletPublicKey, PublicKey.default),
      getNativeSolBalance(walletPublicKey),
    ];

    // Listen for transactions and invalidate cache when confirmed
    subId.current = web3client.connection.onLogs(
      new PublicKey(walletPublicKey),
      () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return includeKeys.some((key) => {
              const { queryKey } = query;

              if (isBalanceQueryKey(queryKey, walletPublicKey)) {
                return true;
              }
              return queryKey.length >= key.length && key.every((part, index) => queryKey[index] === part);
            });
          },
        });
      },
      'confirmed'
    );

    const cleanUp = async () => {
      if (subId.current) {
        await web3client.connection.removeOnLogsListener(subId.current);
        subId.current = undefined;
      }
    };

    return () => {
      (async () => {
        await cleanUp();
      })().catch(captureError);
    };
  }, [queryClient, walletPublicKey, web3client]);

  // Remove and invalidate user related data if wallet is disconnected
  useEffect(() => {
    const queries = [['user']];
    queryClient.invalidateQueries(queries).then(noop).catch(captureError);
    queryClient.removeQueries(queries);
  }, [queryClient, walletPublicKey]);

  return null;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 10 * 60 * 1000, // 10 minutes
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 3, // max 3 retries of failed requests (defaults to 3)
    },
  },
});

registerMoonGateWallet({ authMode: 'Google', position: 'bottom-right' });
registerMoonGateWallet({ authMode: 'Ethereum', position: 'bottom-right' });
registerMoonGateWallet({ authMode: 'Apple', position: 'bottom-right' });

function App() {
  // this cookie is set by enzuzo banner
  // make sure to change if another consent service is used
  const consetCookieName = 'cookies-analytics';
  const isCookiesConsentGiven = Cookies.get(consetCookieName) === 'true';
  const [mixpanelConfig] = useState({
    ip: isCookiesConsentGiven,
    disable_persistence: !isCookiesConsentGiven,
    debug: import.meta.env.VITE_APP_ENV === 'development',
    opt_out_tracking_by_default: debugWallet,
  });

  // TODO: webworker change, undo if reverted
  return (
    <WalletsProvider>
      <WorkerProvider>
        <MixpanelProvider token="dc9274dcb5624489018e815b0f026abe" config={mixpanelConfig}>
          <KaminoAnalyticsConsentProvider>
            <BlacklistOverlayProvider>
              <QueryClientProvider client={queryClient}>
                <NotificationProvider>
                  <KaminoSdkContextProvider>
                    <MixpanelEventsMonitor />
                    <InvalidateCacheSubscription />
                    <NewRouter />
                  </KaminoSdkContextProvider>
                </NotificationProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </BlacklistOverlayProvider>
          </KaminoAnalyticsConsentProvider>
        </MixpanelProvider>
        <SvgGradients />
      </WorkerProvider>
    </WalletsProvider>
  );
}

export default App;
