import { useMemo } from 'react';
import { LendingPositionTableData } from '../types';
import { useCacheLendingData } from './useCacheLendingData';
import { useLendingLeveragePositionsTableData } from './useLendingLeveragePositionsTableData';
import { useObligationsPnlQuery } from '../../../shared/hooks/lending/queries/useObligationsPnlQuery';

const EMPTY_DATA = {
  totalNetValue: 0,
  totalCollateral: 0,
  totalDebt: 0,
  totalPnLSol: 0,
  totalPnLUsd: 0,
  positionsCount: 0,
};

function calcPositionsTotalNetValue(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += position.netValue;
    return sum;
  }, 0);
}

function calcPositionsTotalPnL(
  data: LendingPositionTableData[],
  field: 'sol' | 'usd',
  getPnLFromCache: ReturnType<typeof useCacheLendingData>['getPnLFromCache']
): number {
  return data.reduce((sum, position) => {
    sum += Number(getPnLFromCache(position.address)?.[field] || 0);
    return sum;
  }, 0);
}

export const useLendingLeveragePositionsStats = ({ marketAddress }: { marketAddress: string }) => {
  const { data: tableData, isLoading, obligations } = useLendingLeveragePositionsTableData({ marketAddress });
  const { isFetched, isReady } = useObligationsPnlQuery({ obligations });
  const { getPnLFromCache } = useCacheLendingData();

  const data = useMemo(() => {
    return {
      totalNetValue: calcPositionsTotalNetValue(tableData),
      totalPnLSol: calcPositionsTotalPnL(tableData, 'sol', getPnLFromCache),
      totalPnLUsd: calcPositionsTotalPnL(tableData, 'usd', getPnLFromCache),
    };
  }, [getPnLFromCache, tableData, isFetched]);

  return { data: isLoading ? EMPTY_DATA : data, isLoading, isReady };
};
