import { ReactElement, SVGProps } from 'react';

export const SvgVoting = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={38} height={38} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 19C.5 29.2 8.8 37.5 19 37.5S37.5 29.2 37.5 19 29.2.5 19 .5.5 8.8.5 19Z"
      fill="url(#voting_svg__a)"
      fillOpacity={0.2}
      stroke="#212C42"
    />
    <path
      d="M19 34c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
      fill="url(#voting_svg__b)"
    />
    <path
      opacity={0.3}
      d="M19 29.605c5.858 0 10.606-4.748 10.606-10.606S24.858 8.393 19 8.393 8.394 13.14 8.394 18.999 13.142 29.605 19 29.605Z"
      fill="url(#voting_svg__c)"
      stroke="#98B0C1"
      strokeMiterlimit={10}
    />
    <path
      d="M21.571 13H19v1.667m0 0c1.895 0 3.429 1.495 3.429 3.333H15.57c0-1.845 1.534-3.333 3.429-3.333ZM13.857 18v5m3.429-5v5m3.428-5v5m3.429-5v5M13 23h12m-12-5h12"
      stroke="url(#voting_svg__d)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="voting_svg__a" x1={19} y1={1} x2={19} y2={37} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="voting_svg__b" x1={19} y1={4} x2={19} y2={34} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="voting_svg__c" x1={19} y1={29.605} x2={19} y2={8.393} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="voting_svg__d" x1={19} y1={12} x2={19} y2={25.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#212C42" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
    </defs>
  </svg>
);
