import { ObligationStats, ObligationTypeTag, Position } from '@kamino-finance/klend-sdk';

// TODO: V3, remove this comment is all is good
export interface MarketFromResourcesResponse {
  isPrimary: boolean;
  lendingMarket: string;
  lookupTable: string;
  name: string;
  description: string;
}

export interface MarketResponse {
  isPrimary: boolean;
  lendingMarket: string;
  lookupTable: string;
  lendingMarketAuthority: string;
  reserves: ReserveResponse[];
}

export interface ReserveResponse {
  liquidityToken: {
    decimals: number;
    symbol: string;
    mint: string;
  };
  address: string;
  collateralMint: string;
  collateralSupply: string;
  liquiditySupply: string;
  liquidityFeeReceiverAddress: string;
  pythOracle: string;
  switchboardOracle: string;
}

// ===============

export interface ObligationHistoryResponse {
  market: string;
  obligation: string;
  history: History[];
}

export type ObligationSolValues = {
  netValueSol: number;
  debtValueSol: number;
  collateralValueSol: number;
  solPrice: number;
  timestampSolPrice: Date;
  timestampObligation: Date;
};

export interface History {
  timestamp: string;
  refreshedStats: ObligationStats;
  deposits: Position[];
  borrows: Position[];
  tag: ObligationTypeTag;
  obligationSolValues: ObligationSolValues;
}

export interface ObligationPnlResponse {
  usd: string;
  sol: string;
  invested: {
    usd: string;
    sol: string;
  };
}

// ===============

export interface Transaction {
  createdOn: string;
  timestamp: number;
  transactionSignature: string;
  transactionName: string;
  transactionDisplayName: string;
  reserve: string;
  kTokenAmount: string;
  liquidityToken: string;
  liquidityTokenMint: string;
  liquidityTokenAmount: string;
  liquidityTokenPrice: string;
  solPrice: string;
  liquidityUsdValue: string;
  isLiquidationWithdrawal: boolean;
}

export interface ObligationTransactionHistoryResponse {
  transactions: Transaction[];
}

export interface ObligationUserTransactionHistoryResponse {
  [key: string]: Transaction[];
}

// ===============

export type HistoricalFeesObligationProps = {
  ativeFees: string;
  solFees: string;
  ts: number;
  usdFees: string;
};

export type TotalFeesEarnedObligationProps = {
  solFees: string;
  ts: number;
  usdFees: string;
};

export interface ObligationInterestFeesResponse {
  historicalFeesObligation: {
    [address: string]: HistoricalFeesObligationProps[];
  };
  totalFeesEarnedObligation: {
    [address: string]: TotalFeesEarnedObligationProps;
  };
}

export enum MarketsFilters {
  all = 'all',
  main = 'main',
  jlp = 'jlp',
  altcoins = 'altcoins',
  ethena = 'ethena',
  eModeSol = 'eModeSol',
  eModeUsd = 'eModeUsd',
  cTokens = 'cTokens',
  jito = 'jito',
}

export interface PoolYieldHistory {
  createdOn: string;
  apr: string;
  apy: string;
}
