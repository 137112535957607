import Notification from 'rc-notification';
import type { NoticeContent, NotificationInstance as RCNotificationInstance } from 'rc-notification/lib/Notification';
import * as React from 'react';
import { withStyles } from '@bruitt/classnames';

import createUseNotification from './hooks/useNotification';
import { ArgsProps, ConfigProps, NotificationApi, NotificationPlacement } from './types';

import './core.css';

import { Notice } from './components/Notice';

import s from './Notify.module.less';

const sx = withStyles(s);

const notificationInstance: {
  [key: string]: Promise<RCNotificationInstance>;
} = {};

let defaultDuration = 4.5;
let defaultTop = 0;
let defaultBottom = 0;
let defaultPlacement: NotificationPlacement = 'topRight';
let defaultGetContainer: () => HTMLElement;
let maxCount: number;

function setNotificationConfig(options: ConfigProps) {
  const { duration, placement, bottom, top, getContainer } = options;

  if (duration !== undefined) {
    defaultDuration = duration;
  }
  if (placement !== undefined) {
    defaultPlacement = placement;
  }

  if (bottom !== undefined) {
    defaultBottom = bottom;
  }

  if (top !== undefined) {
    defaultTop = top;
  }

  if (getContainer !== undefined) {
    defaultGetContainer = getContainer;
  }

  if (options.maxCount !== undefined) {
    maxCount = options.maxCount;
  }
}

function getPlacementStyle(placement: NotificationPlacement, top: number = defaultTop, bottom: number = defaultBottom) {
  let style;

  switch (placement) {
    case 'top':
      style = {
        left: '50%',
        transform: 'translateX(-50%)',
        right: 'auto',
        top,
        bottom: 'auto',
      };
      break;
    case 'topLeft':
      style = {
        left: 0,
        top,
        bottom: 'auto',
      };
      break;
    case 'topRight':
      style = {
        right: 0,
        top,
        bottom: 'auto',
      };
      break;
    case 'bottom':
      style = {
        left: '50%',
        transform: 'translateX(-50%)',
        right: 'auto',
        top: 'auto',
        bottom,
      };
      break;
    case 'bottomLeft':
      style = {
        left: 0,
        top: 'auto',
        bottom,
      };
      break;
    default:
      style = {
        right: 0,
        top: 'auto',
        bottom,
      };
      break;
  }
  return style;
}

function getNotificationInstance(args: ArgsProps, callback: (info: { instance: RCNotificationInstance }) => void) {
  const { placement = defaultPlacement, top, bottom, getContainer = defaultGetContainer } = args;

  const cacheKey = `kamino-${placement}`;

  const cacheInstance = notificationInstance[cacheKey];

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  if (cacheInstance) {
    Promise.resolve(cacheInstance)
      .then((instance) => {
        callback({ instance });
      })
      .catch();

    return;
  }

  notificationInstance[cacheKey] = new Promise((resolve) => {
    Notification.newInstance(
      {
        prefixCls: 'kamino',
        className: [`kamino-${placement}`, s.notifyList].join(' '),
        style: getPlacementStyle(placement, top, bottom),
        getContainer,
        maxCount,
      },
      (notification) => {
        resolve(notification);
        callback({
          instance: notification,
        });
      }
    );
  });
}

function getRCNoticeProps(args: ArgsProps, instance: RCNotificationInstance): NoticeContent {
  const { duration: durationArg, onClose, onClick, key = Date.now(), style, className, props } = args;

  const durationInSeconds = durationArg === undefined ? defaultDuration : durationArg;
  const durationInMilliseconds = durationInSeconds ? durationInSeconds * 1000 : null;

  let timer: NodeJS.Timeout;

  const onNotificationMouseEnter = () => clearTimeout(timer);
  const onNotificationMouseLeave = () => {
    if (durationInMilliseconds) {
      timer = setTimeout(() => {
        instance.removeNotice(key);
      }, durationInMilliseconds);
    }
  };

  // start timer
  if (durationInMilliseconds) {
    timer = setTimeout(() => {
      instance.removeNotice(key);
    }, durationInMilliseconds);
  }

  return {
    content: (
      <div onMouseEnter={onNotificationMouseEnter} onMouseLeave={onNotificationMouseLeave}>
        <Notice args={args} instance={instance} classNames={s.wrapper} />
      </div>
    ),
    duration: null,
    closable: false,
    onClose,
    onClick,
    key,
    style: style || {},
    className: sx(className, s.root, {
      // [`${prefixCls}-${type}`]: !!type,
    }),
    props,
  };
}

function notice(args: ArgsProps) {
  getNotificationInstance(args, ({ instance }) => {
    instance.notice(getRCNoticeProps(args, instance));
  });
}

const api: any = {
  open: notice,
  close(key: string) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Object.keys(notificationInstance).forEach((cacheKey) =>
      Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
        instance.removeNotice(key);
      })
    );
  },
  config: setNotificationConfig,
  destroy() {
    Object.keys(notificationInstance).forEach((cacheKey) => {
      Promise.resolve(notificationInstance[cacheKey])
        .then((instance) => {
          instance.destroy();
        })
        .catch();
      delete notificationInstance[cacheKey]; // lgtm[js/missing-await]
    });
  },
};

['success', 'info', 'warning', 'error', 'special'].forEach((type) => {
  api[type] = (args: ArgsProps) =>
    api.open({
      ...args,
      type,
    });
});

api.warn = api.warning;
api.useNotification = createUseNotification(getNotificationInstance, getRCNoticeProps);

export default api as NotificationApi;
