import { useMemo } from 'react';
import { UseUserFeesAndRewardsProps } from '../../types/userFeesAndRewards';
import { useUserFeesAndRewardsQuery } from './queries/useUserFeesAndRewardsQuery';
import { UserFeesAndRewardsEarnings } from '../../../models/api/UserFeesAndRewardsEarnings';

const EMPTY_DATA: UserFeesAndRewardsEarnings[] = [];

export const useUserFeesAndRewards = ({ strategiesAddresses, walletPublicKey }: UseUserFeesAndRewardsProps) => {
  const { data, isLoading, isFetched } = useUserFeesAndRewardsQuery({ strategiesAddresses, walletPublicKey });

  const formattedFeesAndRewards = useMemo(
    () =>
      data?.map((feesAndRewards, index) => ({
        strategyAddress: strategiesAddresses[index].toString(),
        ...feesAndRewards,
      })) || EMPTY_DATA,
    [data, strategiesAddresses]
  );

  return {
    userFeesAndRewardsStrategies: formattedFeesAndRewards,
    isLoading,
    haveFeesAndRewardsLoaded: isFetched,
    isFetched,
  };
};
