import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';

import { QUERYKEYS } from '../constants/queryKeys';
import { useWeb3Client } from './useWeb3Client';
import { notify } from '../utils/notifications/notifications';
import { TokenInfo } from '../types/token-info';
import { captureError } from '../utils/captureError';
import { useAvailableTokensMints } from './useAvailableTokensMints';
import { CUSTOM_SYMBOLS } from '../constants/tokens';
import { HARDCODED_TOKEN_INFOS } from '../constants/tokenInfo';
import { fetchTokenInfos } from '../services/tokens';

const EMPTY_TOKEN_INFOS: TokenInfo[] = [];

export const useTokensInfo = ({ isEnabled = true }: { isEnabled?: boolean }) => {
  const { web3client } = useWeb3Client();
  const { mints } = useAvailableTokensMints();
  const queryClient = useQueryClient();

  const {
    data: apiTokenInfos,
    isSuccess: isApiTokenInfosFetched,
    isError: isApiTokenInfosError,
  } = useQuery({
    queryKey: QUERYKEYS.tokensInfosApi,
    enabled: isEnabled,
    queryFn: fetchTokenInfos,
    onSuccess: () => invalidateOtherQueries(queryClient),
    cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
    retry: 0,
    onError: (err) => captureError(err),
  });

  const queryFn = async () => {
    const utlModule = await import('@solflare-wallet/utl-sdk');
    const { Client, UtlConfig } = utlModule;

    if (!web3client) {
      return undefined;
    }
    const config = new UtlConfig({
      chainId: web3client.chain.chainID,
      timeout: 2000,
      connection: web3client.connection,
      apiUrl: 'https://token-list-api.solana.cloud',
      cdnUrl: 'https://raw.githubusercontent.com/solflare-wallet/token-list/refs/heads/master/solana-tokenlist.json',
    });
    const utlClient = new Client(config);
    console.error('Failed to load tokens info from Hubble API');
    return (await utlClient.fetchMints(mints.map((mint) => new PublicKey(mint))))
      .concat(HARDCODED_TOKEN_INFOS)
      .map((i) => {
        return { ...i, symbol: CUSTOM_SYMBOLS[i.address] || i.symbol };
      }) as TokenInfo[];
  };

  const { data: fallbackTokenInfos, isSuccess: isFallbackTokenInfosFetched } = useQuery({
    queryKey: QUERYKEYS.tokensInfosFallback,
    enabled: isEnabled && isApiTokenInfosError && Boolean(mints) && mints.length > 0,
    // enabled: isEnabled && Boolean(mints) && mints.length > 0,
    queryFn,
    cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
    onSuccess: () => invalidateOtherQueries(queryClient),
    onError: (err) => {
      captureError(err);
      notify({
        message: 'Failed to load tokens info',
        type: 'error',
      });
    },
  });

  return {
    data: apiTokenInfos || fallbackTokenInfos || EMPTY_TOKEN_INFOS,
    isFetched: isApiTokenInfosFetched || isFallbackTokenInfosFetched,
    // data: fallbackTokenInfos || EMPTY_TOKEN_INFOS,
    // isFetched: isFallbackTokenInfosFetched,
  };
};

let hasInvalidationAfterTokenInfoRun = false;

const QUERYHASHES_TO_EXCLUDE = [
  JSON.stringify(QUERYKEYS.tokensInfosApi),
  JSON.stringify(QUERYKEYS.tokensInfosFallback),
];

const invalidateOtherQueries = (queryClient: QueryClient) => {
  if (!hasInvalidationAfterTokenInfoRun) {
    hasInvalidationAfterTokenInfoRun = true;
    const queries = queryClient.getQueryCache();

    queries.getAll().forEach(({ queryKey, queryHash }) => {
      if (!QUERYHASHES_TO_EXCLUDE.includes(queryHash)) {
        queryClient.invalidateQueries(queryKey);
      }
    });
  }
};
