import { MenuItemParams } from '../../../shared/constants/menuItems';
import paths from '../../../shared/routes/paths';
import { featureFlags } from '../../../shared/utils';

const removeEarnLinks = (menuItem: MenuItemParams) => {
  if (featureFlags.isEarnEnabled) {
    return true;
  }

  return menuItem.path !== paths.EARN;
};

const removeMultiplyLinks = (menuItem: MenuItemParams) => {
  if (featureFlags.isLendingMultiplyEnabled) {
    return true;
  }

  return menuItem.path !== paths.LENDING.LENDING_MULTIPLY && menuItem.path !== paths.LENDING.LENDING_MULTIPLY_VAULT;
};

const removeLeverageLinks = (menuItem: MenuItemParams) => {
  if (featureFlags.isLendingLeverageEnabled) {
    return true;
  }

  return menuItem.path !== paths.LENDING.LENDING_LEVERAGE && menuItem.path !== paths.LENDING.LENDING_LEVERAGE_VAULT;
};

export const removeLinks = (menuItem: MenuItemParams) => {
  return removeEarnLinks(menuItem) && removeMultiplyLinks(menuItem) && removeLeverageLinks(menuItem);
};
