import { StrategyVaultType } from '../../constants/kamino';
import { Token } from '../../constants/tokens';
import { getDexFromRaw } from '../utils';

const CRYPTO_BACKED_STABLES = ['USDH'];

export const buildStrategySafetyContent = (vaultType: StrategyVaultType | null, tokens: Token[], dex: string) => {
  return [...buildContentForVaultType(vaultType, tokens), ...getAuditContent(dex)];
};

const buildContentForVaultType = (vaultType: StrategyVaultType | null, tokens: Token[]) => {
  const result = [];

  if (vaultType === 'Stable') {
    result.push(...buildStableStrategyContent());
  } else if (vaultType === 'VolatileStable') {
    result.push(...buildVolatileStableContent(tokens));
  } else if (vaultType === 'VolatileVolatile') {
    result.push(...buildVolatileVolatileContent());
  } else if (vaultType === 'Pegged') {
    result.push(...buildPeggedStrategyContent(tokens));
  }

  result.push({
    title: 'Divergence Risk',
    description:
      'Assets may experience significant divergent price movements during volatile conditions. This means your deposit is exposed to the price movements of both tokens in the pair, and your exposure to each token can also change. Volatile conditions may lead to increased impermanent/divergence loss.',
  });

  return result;
};

const buildStableStrategyContent = () => {
  return [
    {
      title: 'Low Volatility Assets',
      description:
        'Stablecoins should experience almost no price movements, beyond small fluctuations above and below the US Dollar peg. This means your deposit should have minimal fluctuations in value in typical market conditions.',
    },
    {
      title: 'Minimal Impermanent Loss',
      description:
        'Because both assets in this strategy are pegged to the US Dollar, in normal market conditions, there should be very little risk of price divergences causing impermanent loss (IL).',
    },
  ];
};

const buildPeggedStrategyContent = (tokens: Token[]) => {
  return [
    {
      title: 'Established assets',
      description:
        'SOL is a widely traded crypto, and generally follows the price movements of BTC and the greater crypto market.',
    },
    {
      title: 'Minimal Impermanent loss',
      description: `${tokens[0]} and ${tokens[1]} are both tied to the SOL price itself. This means that users can expect less divergence in the prices of the two assets.`,
    },
  ];
};

const buildVolatileVolatileContent = () => {
  return [
    {
      title: 'Non-correlated assets',
      description:
        'The assets in this strategy have no guarantee of following the same price patterns. The degree of correlation between the two assets can vary depending on market conditions.',
    },
  ];
};

const buildVolatileStableContent = (tokens: Token[]) => {
  const isCryptoBacked = CRYPTO_BACKED_STABLES.includes(tokens[1]);
  return [
    {
      title: 'Non-correlated assets',
      description: `The assets in this strategy are not pegged to each other, and can have differing price movements. ${
        tokens[0]
      } is pegged to the SOL price, whereas ${tokens[1]} is a ${
        isCryptoBacked ? 'crypto-backed' : 'fiat-backed'
      } stablecoin.`,
    },
  ];
};

// eslint-disable-next-line no-unused-vars
// const buildBlueCheapContent = () => {
//   return [
//     {
//       title: 'Non-correlated assets',
//       description: `The assets in this strategy generally follow the price movements of BTC and the wider crypto market. However, the degree of correlation between the two assets can vary depending on market conditions.`,
//     },
//   ];
// };

const getAuditContent = (dexRaw: string) => {
  const dex = getDexFromRaw(dexRaw);
  if (dex === 'ORCA') {
    return [
      {
        title: 'Platform audited by established firm',
        description:
          'Kamino takes every care to ensure that the platforms we integrate take a conservative approach to security. Though audits are not a 100% guarantee of safety, Orca, Raydium and Meteora have been audited by multiple reputable auditing companies.',
      },
    ];
  }

  if (dex === 'RAYDIUM') {
    return [
      {
        title: 'Platform audited by established firm',
        description:
          'Kamino takes care to ensure that platforms we integrate take a conservative approach to security. Raydium CLMM has been audited by Ottersec',
      },
    ];
  }
  if (dex === 'METEORA') {
    return [
      {
        title: 'Platform audited by established firm',
        description:
          'Kamino takes care to ensure that platforms we integrate take a conservative approach to security. Meteora DLMM has been audited by <TODO>',
      },
    ];
  }

  return [];
};
