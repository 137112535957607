import { useMemo } from 'react';
import { MultiplyObligation, ObligationTypeTag } from '@kamino-finance/klend-sdk';
import { PublicKey } from '@solana/web3.js';

import { LendingMultiplyPositionTableData } from '../types';
import { useUserObligations } from '../../../shared/hooks/lending/useUserObligations';
import { getNetApy, getReserveStats } from '../../../shared/utils/lending';
import { useReserves } from '../../../shared/hooks/lending/useReserves';
import { useStakingYield } from '../../../shared/hooks/lending/useStakingYield';
import { useReserveApys } from '../../../shared/hooks/lending/useReserveApys';
import { useMarkets } from '../../../shared/hooks/lending/useMarkets';
import useEnv from '../../../shared/hooks/useEnv';
import useReserveFarms from '../../../shared/hooks/lending/useReserveFarms';
import { getValidFarmRewards } from '../../lendingV2/BorrowLendList/utils/getValidFarmRewards';
import { PYUSD_MINT } from '../../../shared/constants/mints';
import { isNil } from '../../../shared/utils';

const EMPTY_DATA: LendingMultiplyPositionTableData[] = [];

export const useLendingMultiplyPositionsTableData = () => {
  const { getReservesByMarketAddress, getReserveByTokenMint } = useReserves();
  const { getMarketByAddress } = useMarkets();
  const { walletPublicKey, programId } = useEnv();
  const { data: obligations, isLoading: areUserObligationsLoading } = useUserObligations([ObligationTypeTag.Multiply]);
  const { getReserveApys } = useReserveApys();
  const { getFarmByAddress } = useReserveFarms();
  const isLoading = areUserObligationsLoading;

  const { getStakingYieldByMint } = useStakingYield();

  const tableData = useMemo(() => {
    if (!walletPublicKey || !obligations || !obligations.length) {
      return EMPTY_DATA;
    }

    return obligations.map((obligation) => {
      const { refreshedStats: stats, obligationAddress } = obligation;
      const { address: marketAddress } = getMarketByAddress(obligation.state.lendingMarket);
      const reserves = getReservesByMarketAddress(marketAddress);
      const { loanToValue, netAccountValue, leverage } = stats;
      const deposits = Array.from(obligation.deposits.values());
      const borrows = Array.from(obligation.borrows.values());
      const market = getMarketByAddress(marketAddress);

      const collTokenMint = deposits[0].mintAddress;
      const debtTokenMint =
        borrows[0]?.mintAddress ||
        reserves
          .find((reserve) => {
            const obligationToCheck = new MultiplyObligation(collTokenMint, reserve.getLiquidityMint(), programId);
            const derivedPda = obligationToCheck.toPda(market.getAddress(), new PublicKey(walletPublicKey));
            return derivedPda.equals(obligationAddress);
          })
          ?.getLiquidityMint();

      const depositReserve = getReserveByTokenMint(marketAddress, collTokenMint);
      const borrowReserve = getReserveByTokenMint(marketAddress, debtTokenMint);

      const { supplyApy, mintAddress, depositApy } = getReserveStats({
        reserve: depositReserve,
        obligation,
        getReserveApys,
      });
      const { borrowApy } = getReserveStats({ reserve: borrowReserve, obligation, getReserveApys });
      const pyusdFarm =
        depositReserve && mintAddress === PYUSD_MINT
          ? getFarmByAddress(depositReserve.state.farmCollateral)
          : undefined;
      const pyusdRewards = getValidFarmRewards(pyusdFarm);
      const pyusdApy = pyusdRewards.reduce((acc, reward) => acc + (!isNil(reward.apy) ? reward.apy : 0), 0);

      const stakingYieldInfo = getStakingYieldByMint(mintAddress);
      const stakingApy = Number(stakingYieldInfo?.apy || 0);
      const supplyTotalApy = mintAddress === PYUSD_MINT ? depositApy : supplyApy;

      return {
        address: obligation.obligationAddress,
        marketAddress,
        ltv: loanToValue.toNumber(),
        netValue: netAccountValue.toNumber(),
        netApy: getNetApy({
          deposits,
          borrows,
          reserves,
          extraSupplyApy: stakingApy,
          getReserveApys,
        }).netApy,
        deposits,
        borrows,
        interestFees: Number(0),
        multiplier: leverage.toNumber(),
        reserveAddress: depositReserve?.address,
        borrowReserveAddress: borrowReserve?.address,
        borrowApy,
        supplyApy: supplyTotalApy,
        stakingApy,
        rewardApy: pyusdApy,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obligations, getReservesByMarketAddress, getMarketByAddress, walletPublicKey, programId]);

  return { data: tableData || EMPTY_DATA, isLoading, obligations };
};
