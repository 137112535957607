import { ReactElement, SVGProps } from 'react';

export const SvgCopy = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 10h3.333A.667.667 0 0 0 14 9.333V2.667A.667.667 0 0 0 13.333 2H6.667A.667.667 0 0 0 6 2.667V6"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.667 14h6.666a.667.667 0 0 0 .667-.667V6.667A.667.667 0 0 0 9.333 6H2.667A.667.667 0 0 0 2 6.667v6.666c0 .368.298.667.667.667Z"
    />
  </svg>
);
