import { TokenInfo } from '../types/token-info';
import { KMNO_MINT } from './mints';

const kmnoHardcoded = [
  {
    name: 'Kamino USDH-USDC (Orca)',
    symbol: 'kUSDH-USDC',
    logoURI: 'https://api.kamino.finance/ktokens/5BmZgW7dk1kximGfn7MPvDigp3yRmgT64jS9Skdq4nPY/metadata/image.svg',
    address: '5BmZgW7dk1kximGfn7MPvDigp3yRmgT64jS9Skdq4nPY',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino UXD-USDC (Orca)',
    symbol: 'kUXD-USDC',
    logoURI: 'https://api.kamino.finance/ktokens/4G9USgnbg6fDTQ5AUfpCjM89zqbzWj32xfqvsaAu66DM/metadata/image.svg',
    address: '4G9USgnbg6fDTQ5AUfpCjM89zqbzWj32xfqvsaAu66DM',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino SOL-jSOL (Orca)',
    symbol: 'kSOL-JSOL',
    logoURI: 'https://api.kamino.finance/ktokens/Dk2X1HCbwJae44P7FpqdFoeT6LEw4JVyyHvZMHUzHWbi/metadata/image.svg',
    address: 'Dk2X1HCbwJae44P7FpqdFoeT6LEw4JVyyHvZMHUzHWbi',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino SOL-mSOL (Raydium)',
    symbol: 'kSOL-MSOL',
    logoURI: 'https://api.kamino.finance/ktokens/3Fb5DMRWoBLWD36Lp4BtG41LaFjVeEJNCH9YLNPYdVqj/metadata/image.svg',
    address: '3Fb5DMRWoBLWD36Lp4BtG41LaFjVeEJNCH9YLNPYdVqj',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino SOL-JITO (Raydium)',
    symbol: 'kSOL-JITO',
    logoURI: 'https://api.kamino.finance/ktokens/GYiUmJ8reqYAdTQtx6CRFawHqPXx9yzkUFvaUVE8PskP/metadata/image.svg',
    address: 'GYiUmJ8reqYAdTQtx6CRFawHqPXx9yzkUFvaUVE8PskP',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino SOL-bSOL (Orca)',
    symbol: 'kSOL-BSOL',
    logoURI: 'https://api.kamino.finance/ktokens/9HB4kAMLSYfGFfN142DKMyPyHyZQ8pXF8M1STbDudodY/metadata/image.svg',
    address: '9HB4kAMLSYfGFfN142DKMyPyHyZQ8pXF8M1STbDudodY',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
  {
    name: 'Kamino USDC-jSOL (Orca)',
    symbol: 'kUSDC-JSOL',
    logoURI: 'https://api.kamino.finance/ktokens/8Ak9JgLeTo6ubG5vfpuAR59ANpGjTB8HFDwYjpZbkPeB/metadata/image.svg',
    address: '8Ak9JgLeTo6ubG5vfpuAR59ANpGjTB8HFDwYjpZbkPeB',
    decimals: 6,
    chainId: 101,
    verified: false,
    source: 'METAPLEX',
  },
];

const filteredHardcodedData = [
  {
    chainId: 101,
    name: 'Generational Wealth',
    symbol: 'WEALTH',
    address: '2YuSzANgyU9rkFJn5aiAPJqN1kHgtZVQb4nWs1JLjLCw',
    decimals: 5,
    logoURI: 'https://nftstorage.link/ipfs/bafkreih7zwqvzj7jluvefedefdsf2kkeo327f7wkczthzt6t54spc6a5b4/',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
    extensions: {
      coingeckoId: 'generational-wealth-2',
    },
  },
  {
    chainId: 101,
    name: 'Chai (Wormhole)',
    symbol: 'CHAI',
    address: '3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3',
    decimals: 8,
    logoURI: null,
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
  },
  {
    chainId: 101,
    name: 'zorksees',
    symbol: 'ZORKSEES',
    address: '9kq1VuGCCKeTgtRmzUTjLVi6LpmBD38im2hPRaqusPkE',
    decimals: 8,
    logoURI: 'https://bafkreiatbvfgifxwk43lkzv7ima3p6wcmscwhisv4x222ivbcalxsfhyrq.ipfs.nftstorage.link/',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
    extensions: {
      coingeckoId: 'zorksees',
    },
  },
  {
    chainId: 101,
    name: 'upsidedowncat',
    symbol: 'USDCAT',
    address: 'F23fFqpRNsmWjuUrFpfM1pvoVvMSpLuN6hY978Y1JXLt',
    decimals: 6,
    logoURI: 'https://bafybeiee2vqjxij7rssa2ymh4ku6dchopowgzmdlmbf5lcbahp7k5hzatu.ipfs.nftstorage.link/',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'upsidedowncat-2',
    },
  },
  {
    chainId: 101,
    name: 'Staked USDe',
    symbol: 'SUSDE',
    address: 'Eh6XEPhSwoLv5wFApukmnaVSHQ6sAnoD9BmgmwQoN2sN',
    decimals: 9,
    logoURI: 'https://arweave.net/iY7Aj5iwHKDIDL0m7QxeQN2URRrphWRpdKPJjE8fcaQ',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'OneFinity',
    symbol: 'ONE',
    address: 'CEm2Fg6k1o9GKFnfzXqZyTE1UcfUw6dGt3Dsn37UHBwJ',
    decimals: 9,
    logoURI: 'https://arweave.net/-uLRP0KOPXtqBhmjBVxf1fL-pfft5-lxTsN9wrY-Q9M',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
    extensions: {
      coingeckoId: 'onedex',
    },
  },
  {
    chainId: 101,
    name: 'Binance Staked SOL',
    symbol: 'BNSOL',
    address: 'BNso1VUJnh4zcfpZa6986Ea66P6TCp59hvtNJ8b1X85',
    decimals: 9,
    logoURI: 'https://arweave.net/LepI-hea-JrbLMb4L_HpMzaq9k0aHY2-lVSGiBtwvFM?ext=png',
    tags: ['lst', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'binance-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Helium Mobile',
    symbol: 'MOBILE',
    address: 'mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6',
    decimals: 6,
    logoURI: 'https://shdw-drive.genesysgo.net/6tcnBSybPG7piEDShBcrVtYJDPSvGrDbVvXmXKpzBvWP/mobile.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'helium-mobile',
    },
  },
  {
    chainId: 101,
    name: 'Anita Max Wynn',
    symbol: 'WYNN',
    address: '4vqYQTjmKjxrWGtbL2tVkbAU1EVAz9JwcYtd2VE3PbVU',
    decimals: 6,
    logoURI: 'https://bafybeiepbscydfgvbfqq4augkwub2tsvbsm3e6uau3he2mmmbj4ebuyyym.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'anita-max-wynn',
    },
  },
  {
    chainId: 101,
    name: 'Threshold Bitcoin',
    symbol: 'TBTC',
    address: '6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU',
    decimals: 8,
    logoURI: 'https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/TBTC_wh.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'tbtc',
    },
  },
  {
    chainId: 101,
    name: 'Hedge USD',
    symbol: 'USH',
    address: '9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'hedge-usd',
    },
  },
  {
    chainId: 101,
    name: 'Aurory',
    symbol: 'AURY',
    address: 'AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP',
    decimals: 9,
    logoURI: 'https://images.cdn.aurory.io/logo/ico_blanc_small.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'aurory',
    },
  },
  {
    chainId: 101,
    name: 'Solayer SOL',
    symbol: 'SSOL',
    address: 'sSo14endRuUbvQaJS3dq36Q829a3A6BEfoeeRGJywEh',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/solayer-labs/token-metadata/main/logo.jpg',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'solayer-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Myro',
    symbol: '$MYRO',
    address: 'HhJpBhRRn4g56VsyLuT8DL5Bv31HkXqsrahTTUCZeZg4',
    decimals: 9,
    logoURI: 'https://i.ibb.co/9nr3xFp/MYRO-200x200.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'myro',
    },
  },
  {
    chainId: 101,
    name: 'Claynosaurz',
    symbol: 'CLAY',
    address: 'AYtg18TFRQs1mCQ29zWY3aSwxwTcW1uTdZ65dL3WiBQu',
    decimals: 2,
    logoURI: 'https://i.imgur.com/dd9tpCS.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'GIGACHAD',
    symbol: 'GIGA',
    address: '63LfDmNb3MQ8mw9MtZ2To9bEA2M71kZUUGq5tiJxcqj9',
    decimals: 5,
    logoURI: 'https://bafybeifiyvpbr3kd6wepax4qxdlxbjrpz2de4lqsuwwuihirvaal6kqwba.ipfs.nftstorage.link/',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'gigachad-2',
    },
  },
  {
    chainId: 101,
    name: 'PUFF',
    symbol: 'PUFF',
    address: 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'puff',
    },
  },
  {
    chainId: 101,
    name: 'MMOSH: The Stoked Token',
    symbol: 'MMOSH',
    address: 'FwfrwnNVLGyS8ucVjWvyoRdFDpTY8w6ACMAxJ4rqGUSS',
    decimals: 9,
    logoURI: 'https://shdw-drive.genesysgo.net/7nPP797RprCMJaSXsyoTiFvMZVQ6y1dUgobvczdWGd35/MMoshCoin.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'cat in a dogs world',
    symbol: 'MEW',
    address: 'MEW1gQWJ3nEXg2qgERiKu7FAFj79PHvQVREQUzScPP5',
    decimals: 5,
    logoURI: 'https://bafkreidlwyr565dxtao2ipsze6bmzpszqzybz7sqi2zaet5fs7k53henju.ipfs.nftstorage.link/',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'cat-in-a-dogs-world',
    },
  },
  {
    chainId: 101,
    name: 'Shadow Token',
    symbol: 'SHDW',
    address: 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
    decimals: 9,
    logoURI: 'https://shdw-drive.genesysgo.net/FDcC9gn12fFkSU2KuQYH4TUjihrZxiTodFRWNF4ns9Kt/250x250_with_padding.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'genesysgo-shadow',
    },
  },
  {
    chainId: 101,
    name: 'PONKE',
    symbol: 'PONKE',
    address: '5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC',
    decimals: 9,
    logoURI: 'https://gateway.irys.xyz/YBMDVBvnfgO1gXCSmmc8p0RQhc69WWe8_kOkoT8w7nE',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ponke',
    },
  },
  {
    chainId: 101,
    name: 'XBorg',
    symbol: 'XBG',
    address: 'XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY',
    decimals: 9,
    logoURI: 'https://bafybeiemq2qpk3fahebltwzbdsugrzhxuono3ugxa25imywc7mbfhwl2lm.ipfs.dweb.link/',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'xborg',
    },
  },
  {
    chainId: 101,
    name: 'Gecko',
    symbol: 'GECKO',
    address: '6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK',
    decimals: 6,
    logoURI: 'https://gateway.irys.xyz/2MOw1dmYBMp7XL-JylfJBKFsDZid_1xyK4cRjGYkzUY',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'gecko-meme',
    },
  },
  {
    chainId: 101,
    name: 'MS PAINT',
    symbol: 'PAINT',
    address: '8x9c5qa4nvakKo5wHPbPa5xvTVMKmS26w4DRpCQLCLk3',
    decimals: 9,
    logoURI: 'https://bafkreif5icmnpmzg5rmktjkrtt7nxy5gobnpiytc5rqhyy4m2ybd57axbm.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ms-paint',
    },
  },
  {
    chainId: 101,
    name: 'DOBI',
    symbol: 'DOBI',
    address: 'GzQzkt2B4Jr6whWVBF7XqkzWvoUy1jEd5z9tczzGg1rH',
    decimals: 6,
    logoURI: 'https://gateway.irys.xyz/099elCExuWO7iBY2_E-4mZVNLL21Hmj_nxHTGTdUxx0',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'dobi',
    },
  },
  {
    chainId: 101,
    name: 'Silly Dragon',
    symbol: 'SILLY',
    address: '7EYnhQoR9YM3N7UoaKRoA44Uy8JeaZV3qyouov87awMs',
    decimals: 9,
    logoURI: 'https://gateway.irys.xyz/quusRf1O-_WcCbrFwHkbIZ26vxQ1xJZeQdsaKRITVNQ',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'silly-dragon',
    },
  },
  {
    chainId: 101,
    name: 'GMT',
    symbol: 'GMT',
    address: '7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'stepn',
    },
  },
  {
    chainId: 101,
    name: 'Antara Token',
    symbol: 'ANTT',
    address: 'ANTT2Ve8c3JC6fAksFxUGtByzEHmJs49EupP7htA5W7j',
    decimals: 9,
    logoURI: 'https://i.postimg.cc/fbgZQjsR/Antara-Token.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'antara-raiders-royals',
    },
  },
  {
    chainId: 101,
    name: 'Drift',
    symbol: 'DRIFT',
    address: 'DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7',
    decimals: 6,
    logoURI: 'https://metadata.drift.foundation/drift.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'drift-protocol',
    },
  },
  {
    chainId: 101,
    name: '21.co Wrapped Bitcoin',
    symbol: '21BTC',
    address: '21BTCo9hWHjGYYUQQLqjLgDBxjcn8vDt4Zic7TB3UbNE',
    decimals: 8,
    logoURI: 'https://cdn.onyx.21.co/images/tokens/21BTC.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wrapped-btc-21-co',
    },
  },
  {
    chainId: 101,
    name: 'Jupiter',
    symbol: 'JUP',
    address: 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN',
    decimals: 6,
    logoURI: 'https://static.jup.ag/jup/icon.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jupiter-exchange-solana',
    },
  },
  {
    chainId: 101,
    name: 'jeo boden',
    symbol: 'BODEN',
    address: '3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o',
    decimals: 9,
    logoURI: 'https://imagedelivery.net/VDLBqelSa0VYu9zPusMDJA/ba945723-b909-4396-0fc0-668c70fd0d00/public',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jeo-boden',
    },
  },
  {
    chainId: 101,
    name: 'Raydium',
    symbol: 'RAY',
    address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'raydium',
    },
  },
  {
    chainId: 101,
    name: 'Pyth Network',
    symbol: 'PYTH',
    address: 'HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3',
    decimals: 6,
    logoURI: 'https://pyth.network/token.svg',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'pyth-network',
    },
  },
  {
    chainId: 101,
    name: 'CROWN Token',
    symbol: 'CROWN',
    address: 'GDfnEsia2WLAW5t8yx2X5j2mkfA74i5kwGdDuZHt7XmG',
    decimals: 9,
    logoURI: 'https://shdw-drive.genesysgo.net/AwJ6W2rRaYCGXimceFuLm5td14fhN1VFEfSYg566RxMD/image.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'crown-by-third-time-games',
    },
  },
  {
    chainId: 101,
    name: 'USDH Hubble Stablecoin',
    symbol: 'USDH',
    address: 'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX/usdh.svg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'usdh',
    },
  },
  {
    chainId: 101,
    name: 'CHONKY',
    symbol: 'CHONKY',
    address: 'H7ed7UgcLp3ax4X1CQ5WuWDn6d1pprfMMYiv5ejwLWWU',
    decimals: 6,
    logoURI: 'https://nftstorage.link/ipfs/bafkreih7mcfxvavd7lag63iqptoaqh3fdtddbyxzkubmvxvlfankemegha',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'chonky',
    },
  },
  {
    chainId: 101,
    name: 'Zippy Staked SOL',
    symbol: 'ZIPPYSOL',
    address: 'Zippybh3S5xYYam2nvL6hVJKz1got6ShgV4DyD1XQYF',
    decimals: 9,
    logoURI: 'https://www.zippystake.org/mint.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'zippy-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Hubble Protocol Token',
    symbol: 'HBB',
    address: 'HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6/logo.svg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'hubble',
    },
  },
  {
    chainId: 101,
    name: 'Metaplex Token',
    symbol: 'MPLX',
    address: 'METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m',
    decimals: 6,
    logoURI: 'https://arweave.net/VRKOcXIvCxqp35RZ9I0-bDGk5qNfT46OTho-2oP9iGc',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'metaplex',
    },
  },
  {
    chainId: 101,
    name: 'BlinkyInkyPinkyClydeInu',
    symbol: '$BIPCI',
    address: '2g4o5LLBFr2JHjwLveRFA8aeaHiX4qTjCmmcSBZCqEGa',
    decimals: 9,
    logoURI: 'https://bafkreib2treograiylbwrmrhmqeuebik5ozgstdteuoc6d7ruc2aebcvde.ipfs.nftstorage.link/',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
  },
  {
    chainId: 101,
    name: 'VNX Swiss Franc',
    symbol: 'VCHF',
    address: 'AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch',
    decimals: 9,
    logoURI:
      'https://4183046207-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FsAJThQfNCJePE5h9iplX%2Fuploads%2F2fiefVjQxDNLja1fPN7g%2FVCHF%20256x256.png?alt=media&token=b990889a-841a-49f8-9d54-cbe2bb83b7f7',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'vnx-swiss-franc',
    },
  },
  {
    chainId: 101,
    name: 'Kamino',
    symbol: 'KMNO',
    address: 'KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS',
    decimals: 6,
    logoURI: 'https://cdn.kamino.finance/kamino.svg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'kamino',
    },
  },
  {
    chainId: 101,
    name: 'Flowmatic',
    symbol: 'FM',
    address: 'Eh1fXbAipe4k7CYR9UMb2bbWmBcpU3HcyX3LWuRVFBLz',
    decimals: 4,
    logoURI:
      'https://nftstorage.link/ipfs/bafybeighw6z7wdbk73tezruawliwt622xic6r43wmzgpk22rsnmqvjvkrm/Brando_logo_of_F_on_a_black_background_in_the_style_of_light_te_42b8cbea-cc40-4c9f-a098-62c381d624df.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'flowmatic',
    },
  },
  {
    chainId: 101,
    name: 'PUPS',
    symbol: 'PUPS',
    address: 'PUPS8ZgJ5po4UmNDfqtDMCPP6M1KP3EEzG9Zufcwzrg',
    decimals: 9,
    logoURI: 'https://token-ndllmwrhm.pages.dev/pups/icon.png',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
    extensions: {
      coingeckoId: 'pups-ordinals',
    },
  },
  {
    chainId: 101,
    name: 'ANALOS',
    symbol: 'ANALOS',
    address: '7iT1GRYYhEop2nV1dyCwK2MGyLmPHq47WhPGSwiqcUg5',
    decimals: 8,
    logoURI: 'https://arweave.net/Xz4j6YRjmjA-HM-T4648jcytqnprUAZyvMmLj9lmkzI',
    tags: ['unknown', 'jupiter'],
    verified: false,
    holders: null,
    extensions: {
      coingeckoId: 'analos',
    },
  },
  {
    chainId: 101,
    name: 'Parcl',
    symbol: 'PRCL',
    address: '4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs',
    decimals: 6,
    logoURI: 'https://ipfs.filebase.io/ipfs/QmVDpnYjKMCBdmqGddQNyW8cc3tBU5cKZFiSV5y18J5YnK',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'parcl',
    },
  },
  {
    chainId: 101,
    name: 'Jupiter Perps',
    symbol: 'JLP',
    address: '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4',
    decimals: 6,
    logoURI: 'https://static.jup.ag/jlp/icon.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jupiter-perpetuals-liquidity-provider-token',
    },
  },
  {
    chainId: 101,
    name: 'BlazeStake Staked SOL (bSOL)',
    symbol: 'BSOL',
    address: 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1/logo.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'blazestake-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Basket',
    symbol: 'BSKT',
    address: '6gnCPhXtLnUD76HjQuSYPENLSZdG8RvDB1pTLM5aLSJA',
    decimals: 5,
    logoURI: 'https://gateway.irys.xyz/69o2b3_ekRo4nosnyyXc9piS7d7-8u-h7MNviNzXFYA',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'KIWI Token',
    symbol: 'KIWI',
    address: '66Qq2qS67K4L5xQ3xUTinCyxzdPeZQG1R1ipK8jrY7gc',
    decimals: 5,
    logoURI: 'https://bafkreibcamcjwo5z3itvybznrdtb3fgeiplfy36izu75jygxkt7jzoq4ju.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'kiwi-token-2',
    },
  },
  {
    chainId: 101,
    name: 'Marinade',
    symbol: 'MNDE',
    address: 'MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'marinade',
    },
  },
  {
    chainId: 101,
    name: 'Mad Lads',
    symbol: 'MADX',
    address: 'Cj2NAxiDDVvY79PLBdVQK3F3bjoHp7hvZv4kLL1vgtV3',
    decimals: 2,
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/mad_lads_pfp_1681951896287.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Dark Protocol',
    symbol: 'DARK',
    address: 'FmQ7v2QUqXVVtAXkngBh3Mwx7s3mKT55nQ5Z673dURYS',
    decimals: 9,
    logoURI: 'https://www.arweave.net/3VPYgJz-wlRAm1H5_4zrsAckyz55qa5ILyk3Uq6l4Ms?ext=png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'dark-protocol',
    },
  },
  {
    chainId: 101,
    name: 'Honeyland',
    symbol: 'HXD',
    address: '3dgCCb15HMQSA4Pn3Tfii5vRk7aRqTH95LJjxzsG2Mug',
    decimals: 9,
    logoURI: 'https://arweave.net/NHZyHFvooDi_LKgs1BaHVPMsuRSOoHpE-J26fp1uHGQ',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'honeyland-honey',
    },
  },
  {
    chainId: 101,
    name: 'Cogent SOL',
    symbol: 'CGNTSOL',
    address: 'CgnTSoL3DgY9SFHxcLj6CgCgKKoTBr6tp4CPAEWy25DE',
    decimals: 9,
    logoURI: 'https://cogent-cogs.s3.us-west-2.amazonaws.com/cgntSOL.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'cogent-sol',
    },
  },
  {
    chainId: 101,
    name: 'UPROCK',
    symbol: 'UPT',
    address: 'UPTx1d24aBWuRgwxVnFmX4gNraj3QGFzL3QqBgxtWQG',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/uprockcom/token/main/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'uprock',
    },
  },
  {
    chainId: 101,
    name: 'JITO',
    symbol: 'JTO',
    address: 'jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL',
    decimals: 9,
    logoURI: 'https://metadata.jito.network/token/jto/image',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jito-governance-token',
    },
  },
  {
    chainId: 101,
    name: 'Hxro (Portal)',
    symbol: 'HXRO',
    address: 'HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'hxro',
    },
  },
  {
    chainId: 101,
    name: 'Wrapped Bitcoin (Sollet)',
    symbol: 'SOBTC',
    address: '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wrapped-bitcoin-sollet',
    },
  },
  {
    chainId: 101,
    name: 'Helium IOT',
    symbol: 'IOT',
    address: 'iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns',
    decimals: 6,
    logoURI: 'https://shdw-drive.genesysgo.net/6tcnBSybPG7piEDShBcrVtYJDPSvGrDbVvXmXKpzBvWP/iot.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'helium-iot',
    },
  },
  {
    chainId: 101,
    name: 'SpiderSwap',
    symbol: 'SPDR',
    address: 'AT79ReYU9XtHUTF5vM6Q4oa9K8w7918Fp5SU7G1MDMQY',
    decimals: 9,
    logoURI: 'https://arweave.net/PAVQPYRQzq2g4O2X7hDzOniW4aBE9PJ19U56MRVpIfA',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'spiderswap',
    },
  },
  {
    chainId: 101,
    name: 'Wrapped SOL',
    symbol: 'SOL',
    address: 'So11111111111111111111111111111111111111112',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wrapped-solana',
    },
  },
  {
    chainId: 101,
    name: 'Lido Staked SOL',
    symbol: 'STSOL',
    address: '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'lido-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'STYLE',
    symbol: 'STYLE',
    address: '3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF/style.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'style',
    },
  },
  {
    chainId: 101,
    name: 'SolanaHub staked SOL',
    symbol: 'HUBSOL',
    address: 'HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX',
    decimals: 9,
    logoURI: 'https://arweave.net/RI0OfNg4Ldn5RRdOp9lE60NqUmweGtJxF5N8JjU_Y0k',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'solanahub-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'EURC',
    symbol: 'EURC',
    address: 'HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr',
    decimals: 6,
    logoURI: 'https://www.circle.com/hubfs/Brand/EURC/EURC-icon_128x128.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'euro-coin',
    },
  },
  {
    chainId: 101,
    name: 'International Stable Currency',
    symbol: 'ISC',
    address: 'J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/theISCTeam/isc_meta/master/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'international-stable-currency',
    },
  },
  {
    chainId: 101,
    name: 'VNX Euro',
    symbol: 'VEUR',
    address: 'C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519',
    decimals: 9,
    logoURI:
      'https://4183046207-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FsAJThQfNCJePE5h9iplX%2Fuploads%2FaZb0qZfNGNXHulNZRIko%2FVEUR%20256x256.png?alt=media&token=b6bad541-cee9-427d-8498-d4a4ea423c79',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'vnx-euro',
    },
  },
  {
    chainId: 101,
    name: 'LessFnGas',
    symbol: 'LFG',
    address: 'LFG1ezantSY2LPX8jRz2qa31pPEhpwN9msFDzZw4T9Q',
    decimals: 7,
    logoURI: 'https://arweave.net/Y4zpfek0KhLvP1ZRd9ffpdplRQUZXDHOedTp7FnnVvc?ext=png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'lessfngas',
    },
  },
  {
    chainId: 101,
    name: 'Jupiter Staked SOL',
    symbol: 'JUPSOL',
    address: 'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v',
    decimals: 9,
    logoURI: 'https://static.jup.ag/jupSOL/icon.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jupiter-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Kamala Horris',
    symbol: 'KAMA',
    address: 'HnKkzR1YtFbUUxM6g3iVRS2RY68KHhGV7bNdfF1GCsJB',
    decimals: 9,
    logoURI: 'https://ipfs.io/ipfs/QmdJNxUnG6CoavHwCeVHLgCyzLnunL2ChVKr6GqkZoRhbT',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'kamala-horris',
    },
  },
  {
    chainId: 101,
    name: 'stepSOL',
    symbol: 'STEPSOL',
    address: 'StPsoHokZryePePFV8N7iXvfEmgUoJ87rivABX7gaW6',
    decimals: 9,
    logoURI: 'https://step-public.s3.eu-north-1.amazonaws.com/StepSOL.svg',
    tags: ['lst', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'step-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Wen',
    symbol: 'WEN',
    address: 'WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk',
    decimals: 5,
    logoURI: 'https://shdw-drive.genesysgo.net/GwJapVHVvfM4Mw4sWszkzywncUWuxxPd6s9VuFfXRgie/wen_logo.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wen-4',
    },
  },
  {
    chainId: 101,
    name: 'Bonk',
    symbol: 'BONK',
    address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    decimals: 5,
    logoURI: 'https://arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'bonk',
    },
  },
  {
    chainId: 101,
    name: 'Ether (Portal)',
    symbol: 'ETH',
    address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ethereum-wormhole',
    },
  },
  {
    chainId: 101,
    name: 'Render Token',
    symbol: 'RENDER',
    address: 'rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof',
    decimals: 8,
    logoURI: 'https://shdw-drive.genesysgo.net/5zseP54TGrcz9C8HdjZwJJsZ6f3VbP11p1abwKWGykZH/rndr.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'render-token',
    },
  },
  {
    chainId: 101,
    name: 'UXP Governance Token',
    symbol: 'UXP',
    address: 'UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M/uxp-icon-black.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'uxd-protocol-token',
    },
  },
  {
    chainId: 101,
    name: 'mockJUP',
    symbol: 'MOCKJUP',
    address: 'JxxWsvm9jHt4ah7DT9NuLyVLYZcZLUdPD93PcPQ71Ka',
    decimals: 9,
    logoURI: 'https://i.pinimg.com/originals/cb/87/f6/cb87f6e8152961be45a5642ef72c391f.jpg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'mockjup',
    },
  },
  {
    chainId: 101,
    name: 'NANA Token',
    symbol: 'NANA',
    address: 'HxRELUQfvvjToVbacjr9YECdfQMUqGgPYB68jVDYxkbr',
    decimals: 9,
    logoURI: 'https://shdw-drive.genesysgo.net/EV1ARo89dwRzR1kv7JMr7V97qrcXjffkcwEuNHMJfJmz/Banan.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'nana-token',
    },
  },
  {
    chainId: 101,
    name: 'Rollbit Coin',
    symbol: 'RLB',
    address: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    decimals: 2,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Ondo US Dollar Yield',
    symbol: 'USDY',
    address: 'A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6',
    decimals: 6,
    logoURI:
      'https://hq3wjgefwtje2kue7bvqlevhns2udaharnibucrgui3lhgy4aniq.arweave.net/PDdkmIW00k0qhPhrBZKnbLVBgOCLUBoKJqI2s5scA1E',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ondo-us-dollar-yield',
    },
  },
  {
    chainId: 101,
    name: 'HolyGrails',
    symbol: 'HOLY',
    address: 'HezGWsxSVMqEZy7HJf7TtXzQRLiDruYsheYWqoUVnWQo',
    decimals: 9,
    logoURI: 'https://arweave.net/TDvr4xgh3VxmHG8GglxruoSwm41wkYOLPF7EBrhBiBY',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'holygrails-io',
    },
  },
  {
    chainId: 101,
    name: 'ARB Protocol',
    symbol: 'ARB',
    address: '9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/ARBProtocol/arbprotocol/main/IMG_3600.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'arb-protocol',
    },
  },
  {
    chainId: 101,
    name: 'BILLY',
    symbol: 'BILLY',
    address: '3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump',
    decimals: 6,
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmdRU5EkGt1VsgLsXJdHK5kjojQqggQHjhyqeJwmApR53R',
    tags: ['pump', 'verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'billy',
    },
  },
  {
    chainId: 101,
    name: 'FWOG',
    symbol: 'FWOG',
    address: 'A8C3xuqscfmyLrte3VmTqrAq8kgMASius9AFNANwpump',
    decimals: 6,
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmQ4H6Y23dSEjn9LKB85M7KpVFiDu6KfDNZAcrqiCwFQQH',
    tags: ['pump', 'birdeye-trending', 'community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'fwog',
    },
  },
  {
    chainId: 101,
    name: 'Nosana',
    symbol: 'NOS',
    address: 'nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'nosana',
    },
  },
  {
    chainId: 101,
    name: 'Staked Step',
    symbol: 'XSTEP',
    address: 'xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Whales Market',
    symbol: 'WHALES',
    address: 'GTH3wG3NErjwcf7VGCoXEXkgXSHvYhx5gtATeeM5JAS1',
    decimals: 6,
    logoURI: 'https://nftstorage.link/ipfs/bafkreic6gboxk4nlhhzzuoqakj55s23y37qzwb7yzykczfxbwj7kafy75i',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'whales-market',
    },
  },
  {
    chainId: 101,
    name: 'BURRRD',
    symbol: 'BURRRD',
    address: 'F8qtcT3qnwQ24CHksuRrSELtm5k9ob8j64xAzj3JjsMs',
    decimals: 4,
    logoURI: 'https://arweave.net/5qQw9rLaDDQeFJ6-384p8yRmnl_8zK47JBwl3QYmD60?ext=png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'burrrd',
    },
  },
  {
    chainId: 101,
    name: 'Hades',
    symbol: 'HADES',
    address: 'BWXrrYFhT7bMHmNBFoQFWdsSgA3yXoAnMhDK6Fn1eSEn',
    decimals: 9,
    logoURI: 'https://arweave.net/dvKu5BgpSo6j-iGzQOyVXYZ8OU7iyfhHNpkkJ_8qkkQ',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'hades',
    },
  },
  {
    chainId: 101,
    name: 'MOTHER IGGY',
    symbol: 'MOTHER',
    address: '3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN',
    decimals: 6,
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmUFTFWsJiceS99iDMDm2NYuhvHXJVXTgmsDeR28X8njSn',
    tags: ['pump', 'verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'mother-iggy',
    },
  },
  {
    chainId: 101,
    name: 'ORE',
    symbol: 'ORE',
    address: 'oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp',
    decimals: 11,
    logoURI: 'https://ore.supply/icon.png',
    tags: ['birdeye-trending', 'community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ore',
    },
  },
  {
    chainId: 101,
    name: 'Orca',
    symbol: 'ORCA',
    address: 'orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'orca',
    },
  },
  {
    chainId: 101,
    name: 'Step',
    symbol: 'STEP',
    address: 'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'step-finance',
    },
  },
  {
    chainId: 101,
    name: 'Infinity',
    symbol: 'INF',
    address: '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
    decimals: 9,
    logoURI: 'https://bafkreiflz2xxkfn33qjch2wj55bvbn33q3s4mmb6bye5pt3mpgy4t2wg4e.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'socean-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Genopets',
    symbol: 'GENE',
    address: 'GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'genopets',
    },
  },
  {
    chainId: 101,
    name: 'Phteven',
    symbol: 'PHTEVE',
    address: 'DhRQWsiP53gVXnG8KQwq63Uo1FvouRpVNL8536tsVD5H',
    decimals: 8,
    logoURI: 'https://i.imgur.com/vgoQpr6.jpeg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'phteven',
    },
  },
  {
    chainId: 101,
    name: 'Tensor',
    symbol: 'TNSR',
    address: 'TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6',
    decimals: 9,
    logoURI: 'https://arweave.net/beGAyeIzjV_UkyjFtxbkZyi_YqfOBWayiQ0B6wqWygY',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'tensor',
    },
  },
  {
    chainId: 101,
    name: 'Sharky',
    symbol: 'SHARK',
    address: 'SHARKSYJjqaNyxVfrpnBN9pjgkhwDhatnMyicWPnr1s',
    decimals: 6,
    logoURI: 'https://shdw-drive.genesysgo.net/2F9tju1WHwocrTHj2iRoLhhVqbgQJusdUgkYNL84wKf4/$SHARK_logo_round.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'sharky-fi',
    },
  },
  {
    chainId: 101,
    name: 'SwissBorg Token',
    symbol: 'BORG',
    address: '3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z',
    decimals: 9,
    logoURI: 'https://bafybeiaxwonn35jbb7xexmlmguibskmbg5vlv7wzabht3prfespwde6e6u.ipfs.dweb.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'swissborg',
    },
  },
  {
    chainId: 101,
    name: 'Jito Staked SOL',
    symbol: 'JITOSOL',
    address: 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
    decimals: 9,
    logoURI: 'https://storage.googleapis.com/token-metadata/JitoSOL-256.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jito-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'FOMO',
    symbol: 'FOMO',
    address: 'Cx9oLynYgC3RrgXzin7U417hNY9D6YB1eMGw4ZMbWJgw',
    decimals: 9,
    logoURI: 'https://arweave.net/DPVMg1fFjs1NnVJOzfbrueYoB2smv6KiP4vahLyhk1c',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'fomo-2',
    },
  },
  {
    chainId: 101,
    name: 'GameStop',
    symbol: 'GME',
    address: '8wXtPeU6557ETkp9WHFY1n1EcU6NxDvbAggHGsMYiHsB',
    decimals: 9,
    logoURI:
      'https://nftstorage.link/ipfs/bafybeiddrzeyne4n2hi7qkhyhqorc4jbs4565pset7fc3ifwvhvxfdrt7q/gamestop-logo.jpeg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'gme',
    },
  },
  {
    chainId: 101,
    name: 'Bork',
    symbol: 'BORK',
    address: '4jZXkSNgTQKCDb36ECZ6a2aNzcUniGcDeXgTdtM2HxAX',
    decimals: 9,
    logoURI: 'https://gateway.irys.xyz/v7mjymWz2rjAHe1Sn3rUECDdjWjYx_MZ_zi5hhQsgOU',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'bork-2',
    },
  },
  {
    chainId: 101,
    name: 'JPOOL Solana Token',
    symbol: 'JSOL',
    address: '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn/logo.svg',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'jpool',
    },
  },
  {
    chainId: 101,
    name: 'The Vault',
    symbol: 'VSOL',
    address: 'vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7',
    decimals: 9,
    logoURI: 'https://bafkreig55mf3lazzbgndiqyqvdmchdsykvvebww7cqlws6ywgog5xfdzta.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'the-vault-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'USDT',
    symbol: 'USDT',
    address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'tether',
    },
  },
  {
    chainId: 101,
    name: 'dogwifhat',
    symbol: '$WIF',
    address: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm',
    decimals: 6,
    logoURI: 'https://bafkreibk3covs5ltyqxa272uodhculbr6kea6betidfwy3ajsav2vjzyum.ipfs.nftstorage.link/',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'dogwifcoin',
    },
  },
  {
    chainId: 101,
    name: 'Marinade staked SOL (mSOL)',
    symbol: 'MSOL',
    address: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'msol',
    },
  },
  {
    chainId: 101,
    name: 'Helium Network Token',
    symbol: 'HNT',
    address: 'hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux',
    decimals: 8,
    logoURI: 'https://shdw-drive.genesysgo.net/6tcnBSybPG7piEDShBcrVtYJDPSvGrDbVvXmXKpzBvWP/hnt.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'helium',
    },
  },
  {
    chainId: 101,
    name: 'BOZO',
    symbol: '$BOZO',
    address: 'BoZoQQRAmYkr5iJhqo7DChAs7DPDwEZ5cv1vkYC9yzJG',
    decimals: 5,
    logoURI: 'https://arweave.net/QN7RFzy_OqPO_cAbBtBUzcUwObkDuvkRLS16oxJI-s0',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'bozo-collective',
    },
  },
  {
    chainId: 101,
    name: 'Blockasset',
    symbol: 'BLOCK',
    address: 'NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'blockasset',
    },
  },
  {
    chainId: 101,
    name: 'Guacamole',
    symbol: 'GUAC',
    address: 'AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR',
    decimals: 5,
    logoURI: 'https://bafkreiajgw65o4ryczf7cadb43rzptvb62z4msoqr3c4625hdbdbntcydq.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'guacamole',
    },
  },
  {
    chainId: 101,
    name: 'Yaku',
    symbol: 'YAKU',
    address: 'AqEHVh8J2nXH9saV2ciZyYwPpqWFRfD2ffcq5Z8xxqm5',
    decimals: 9,
    logoURI: 'https://arweave.net/vBuoW86uFsjvQjilInux-5bL8rNI5sbehVBY16KmU5I',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'yaku',
    },
  },
  {
    chainId: 101,
    name: 'FORGE',
    symbol: 'FORGE',
    address: 'FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds',
    decimals: 9,
    logoURI: 'https://assets.blocksmithlabs.io/forge_200x200.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'blocksmith-labs-forge',
    },
  },
  {
    chainId: 101,
    name: 'Liquid Staking Token',
    symbol: 'LST',
    address: 'LSTxxxnJzKDFSLr4dUkPcmCf5VyryEqzPLz5j4bpxFp',
    decimals: 9,
    logoURI: 'https://storage.googleapis.com/static-marginfi/lst.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'liquid-staking-token',
    },
  },
  {
    chainId: 101,
    name: 'HONEY',
    symbol: 'HONEY',
    address: '4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy',
    decimals: 9,
    logoURI: 'https://hivemapper-marketing-public.s3.us-west-2.amazonaws.com/Hivemapper_HONEY_token.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'hivemapper',
    },
  },
  {
    chainId: 101,
    name: 'pre',
    symbol: 'PRE',
    address: 'G6mc7tiVSym3zrmmxekF3HYSc9c2hiKnGk7idoHqHTUK',
    decimals: 9,
    logoURI: 'https://arweave.net/pLKQdikCbGGkmHQ2y4ePS3M2czrLNJB2OgSRg8K71TQ',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'pre',
    },
  },
  {
    chainId: 101,
    name: 'WUT',
    symbol: 'WUT',
    address: 'DG5bH1BnfjB5YL7Vt3GjykkUKf6maDUW3jYvdNa9eEVa',
    decimals: 6,
    logoURI: 'https://bafkreiarmzcuum23j6onxnzszjtc62zn2ektrcudbou5srxshgzq25dlyy.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wut',
    },
  },
  {
    chainId: 101,
    name: 'Lido Wrapped Staked ETH',
    symbol: 'WSTETH',
    address: 'ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Samoyed Coin',
    symbol: 'SAMO',
    address: '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'samoyedcoin',
    },
  },
  {
    chainId: 101,
    name: 'michi',
    symbol: '$MICHI',
    address: '5mbK36SZ7J19An8jFochhQS4of8g6BwUjbeCSxBSoWdp',
    decimals: 6,
    logoURI:
      'https://image-cdn.solana.fm/images/?imageUrl=https://cf-ipfs.com/ipfs/QmTQrP6R7ieRSbKzwzLAy1i8c2U66b7LM6bSUmK1dfYc5b',
    tags: ['pump', 'verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'michicoin',
    },
  },
  {
    chainId: 101,
    name: 'MANEKI',
    symbol: 'MANEKI',
    address: '25hAyBQfoDhfWx9ay6rarbgvWGwDdNqcHsXS3jQ3mTDJ',
    decimals: 5,
    logoURI: 'https://i.ibb.co/Jnz56gp/Maneki-PFP.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'maneki',
    },
  },
  {
    chainId: 101,
    name: 'degod',
    symbol: 'DEGOD',
    address: 'degod39zqQWzpG6h4b7SJLLTCFE6FeZnZD8BwHBFxaN',
    decimals: 9,
    logoURI: 'https://arweave.net/OBR6-KUTIPPZRGf9mQupi-vCie8hB5Gw_3hYRs5Jtjc',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'degod',
    },
  },
  {
    chainId: 101,
    name: 'Popcat',
    symbol: 'POPCAT',
    address: '7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr',
    decimals: 9,
    logoURI: 'https://arweave.net/A1etRNMKxhlNGTf-gNBtJ75QJJ4NJtbKh_UXQTlLXzI',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'popcat',
    },
  },
  {
    chainId: 101,
    name: 'Wrapped BTC (Portal)',
    symbol: 'WBTC',
    address: '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wrapped-btc-wormhole',
    },
  },
  {
    chainId: 101,
    name: 'DUST Protocol',
    symbol: 'DUST',
    address: 'DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ',
    decimals: 9,
    logoURI: 'https://gateway.pinata.cloud/ipfs/Qmb5qNLPhR8fJaz5MN1W55iSCXdNgMMSdWn94Z9oiFjw3o',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'dust-protocol',
    },
  },
  {
    chainId: 101,
    name: 'Drako',
    symbol: 'DRAKO',
    address: 'drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53',
    decimals: 5,
    logoURI: 'https://shdw-drive.genesysgo.net/BKajV6Kxs53iWNuBZW23CXKv2TWwHMtYYRLrTgUVXL6U/drako.jpg',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'drako',
    },
  },
  {
    chainId: 101,
    name: 'Arab Cat',
    symbol: 'ARAB',
    address: 'BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm',
    decimals: 9,
    logoURI: 'https://bafkreicw7j4ouygobhuouwecyqtlq3o6wwpmwng6pmplinypxxbxakhbka.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'arab-cat',
    },
  },
  {
    chainId: 101,
    name: 'PELFORT',
    symbol: '$PELF',
    address: 'BgJW7U1u2RY5XJk9uYb5AqFRzjMtqE7pw3kaf9iw9Ntz',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/yamitora0/pelfort/main/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'pelfort',
    },
  },
  {
    chainId: 101,
    name: 'A Gently Used 2001 Honda Civic',
    symbol: 'USEDCAR',
    address: '9gwTegFJJErDpWJKjPfLr2g2zrE3nL1v5zpwbtsk3c6P',
    decimals: 9,
    logoURI: 'https://bafkreigfuq6m47yvyysphjuzziegrxaxeeyfm2bv25tsrxqddreenfss44.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'a-gently-used-2001-honda',
    },
  },
  {
    chainId: 101,
    name: 'Graphite',
    symbol: 'GP',
    address: '31k88G5Mq7ptbRDf3AM13HAq6wRQHXHikR8hik7wPygk',
    decimals: 9,
    logoURI: 'https://arweave.net/ALLzymnuIihFPhoNUJpPxQzbGI8LodXKhNzXzbRMPbA',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'graphite-protocol',
    },
  },
  {
    chainId: 101,
    name: 'CATWIFHAT',
    symbol: 'CIF',
    address: 'G3vWvAaXPHCnncnyAbq6yBRXqfRtEV3h7vExzasZeT6g',
    decimals: 6,
    logoURI: 'https://bafkreiaoe254ylnedydxvdifftxcss2riztmsn6xu42vxu5hizwi6ss4de.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'catwifhat',
    },
  },
  {
    chainId: 101,
    name: 'Octokn',
    symbol: 'OTK',
    address: 'octo82drBEdm8CSDaEKBymVn86TBtgmPnDdmE64PTqJ',
    decimals: 9,
    logoURI: 'https://storage.googleapis.com/the-octos-nfts/icon-otk-big-filled.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'octo-gaming',
    },
  },
  {
    chainId: 101,
    name: 'ZEUS',
    symbol: 'ZEUS',
    address: 'ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/ZeusNetworkHQ/zeus-metadata/master/logo-v2.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'zeus-network',
    },
  },
  {
    chainId: 101,
    name: 'Helius Staked SOL',
    symbol: 'HSOL',
    address: 'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/igneous-labs/lst-offchain-metadata/master/hSOL/hSOL.png',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'helius-staked-sol',
    },
  },
  {
    chainId: 101,
    name: 'Moo Deng',
    symbol: 'MOODENG',
    address: 'ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY',
    decimals: 6,
    logoURI: 'https://ipfs.io/ipfs/Qmf1g7dJZNDJHRQru7E7ENwDjcvu7swMUB6x9ZqPXr4RV2',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'moo-deng',
    },
  },
  {
    chainId: 101,
    name: 'BOOK OF MEME',
    symbol: 'BOME',
    address: 'ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82',
    decimals: 6,
    logoURI: 'https://imagedelivery.net/VDLBqelSa0VYu9zPusMDJA/461f132a-5f76-4571-24fd-a5f6fcb62100/public',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'book-of-meme',
    },
  },
  {
    chainId: 101,
    name: 'doland tremp',
    symbol: 'TREMP',
    address: 'FU1q8vJpZNUrmqsciSjp8bAKKidGsLmouB8CBdf8TKQv',
    decimals: 9,
    logoURI: 'https://bafkreia5oynseyky72us6y7uzbas2uxoosujxvbnnuvscozetprbdinmey.ipfs.nftstorage.link/',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'donald-tremp',
    },
  },
  {
    chainId: 101,
    name: 'Star Atlas',
    symbol: 'ATLAS',
    address: 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'star-atlas',
    },
  },
  {
    chainId: 101,
    name: 'Peepo',
    symbol: 'PEEP',
    address: 'n54ZwXEcLnc3o7zK48nhrLV4KTU5wWD4iq7Gvdt5tik',
    decimals: 6,
    logoURI: 'https://arweave.net/QA7H--VOnRLJnj96IleDWsYJht_RRiA6SWommUMroJ4',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'peepo-sol',
    },
  },
  {
    chainId: 101,
    name: 'MEAN',
    symbol: 'MEAN',
    address: 'MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD',
    decimals: 6,
    logoURI: 'https://arweave.net/9xDXnq_MaWUMnZbfDcuYmxxS154pt7wGJrD7juLRb54',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'meanfi',
    },
  },
  {
    chainId: 101,
    name: 'Famous Fox Federation',
    symbol: 'FOXY',
    address: 'FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq',
    decimals: 0,
    logoURI: 'https://dens.famousfoxes.com/foxy.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'famous-fox-federation',
    },
  },
  {
    chainId: 101,
    name: 'Solcasino Token',
    symbol: 'SCS',
    address: 'SCSuPPNUSypLBsV4darsrYNg4ANPgaGhKhsA3GmMyjz',
    decimals: 5,
    logoURI: 'https://files.solcasino.io/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'solcasino-token',
    },
  },
  {
    chainId: 101,
    name: 'USD Coin (Portal from Ethereum)',
    symbol: 'USDCET',
    address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'usd-coin-wormhole-from-ethereum',
    },
  },
  {
    chainId: 101,
    name: 'DED',
    symbol: 'DED',
    address: '7raHqUrZXAqtxFJ2wcmtpH7SQYLeN9447vD4KhZM7tcP',
    decimals: 2,
    logoURI: 'https://www.arweave.net/nE_JLSoMnDq5bOuQLO7-UhTvlOmC6264v90IVB7ntjQ?ext=gif',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Famous Fox Federation',
    symbol: 'FOXES',
    address: '3RCVCywxSs8pDRU1hw8fo7xTUCpNuX86w8cKTM6fgWBY',
    decimals: 2,
    logoURI:
      'https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeiglipep6klutky4atw7jhf7lknpgzbfqlmqmamqh5s6lwih6uopma.ipfs.dweb.link/',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'famous-fox-federation-floor-index',
    },
  },
  {
    chainId: 101,
    name: 'Saros',
    symbol: 'SAROS',
    address: 'SarosY6Vscao718M4A778z4CGtvcwcGef5M9MEH1LGL',
    decimals: 6,
    logoURI: 'https://rapid.coin98.com/Currency/saros.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'saros-finance',
    },
  },
  {
    chainId: 101,
    name: 'Star Atlas DAO',
    symbol: 'POLIS',
    address: 'poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'star-atlas-dao',
    },
  },
  {
    chainId: 101,
    name: 'USD Coin',
    symbol: 'USDC',
    address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'usd-coin',
    },
  },
  {
    chainId: 101,
    name: 'RETARDIO',
    symbol: 'RETARDIO',
    address: '6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx',
    decimals: 6,
    logoURI: 'https://ipfs.filebase.io/ipfs/QmRT39uwenXSNpWEstYGjTFaF48YWshawLnPEV81bSpmaM',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'retardio',
    },
  },
  {
    chainId: 101,
    name: 'UXD Stablecoin',
    symbol: 'UXD',
    address: '7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT/uxd-icon-black.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'uxd-stablecoin',
    },
  },
  {
    chainId: 101,
    name: 'Blaze',
    symbol: 'BLZE',
    address: 'BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA',
    decimals: 9,
    logoURI: 'https://solblaze.org/assets/blze.png?v=2',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'solblaze',
    },
  },
  {
    chainId: 101,
    name: 'Ovols',
    symbol: 'OVOL',
    address: '4v3UTV9jibkhPfHi5amevropw6vFKVWo7BmxwQzwEwq6',
    decimals: 2,
    logoURI:
      'https://s52lhdnekgzezod5ob2xlfpzhicodm6esmbkvlbzoe4xskamvvnq.arweave.net/l3SzjaRRsky4fXB1dZX5OgThs8STAqqsOXE5eSgMrVs',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ovols-floor-index',
    },
  },
  {
    chainId: 101,
    name: 'SynesisOne',
    symbol: 'SNS',
    address: 'SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/Synesis-One/spl-token/main/icon.png',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'synesis-one',
    },
  },
  {
    chainId: 101,
    name: 'Wrapped DAI (Allbridge from Ethereum)',
    symbol: 'AEDAI',
    address: '9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
  },
  {
    chainId: 101,
    name: 'Laine Stake',
    symbol: 'LAINESOL',
    address: 'LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X',
    decimals: 9,
    logoURI:
      'https://vvmbtadq63n53sdsqhlzbnzhvj5tdfp7moqixyv7rgkk6frqhqqq.arweave.net/rVgZgHD2293IcoHXkLcnqnsxlf9joIviv4mUrxYwPCE',
    tags: ['verified', 'community', 'strict', 'lst', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'laine-stake',
    },
  },
  {
    chainId: 101,
    name: 'EUROe Stablecoin',
    symbol: 'EUROE',
    address: '2VhjJ9WxaGC3EZFwJG9BDUs9KxKCAjQY4vgd1qxgYWVg',
    decimals: 6,
    logoURI: 'https://dev.euroe.com/persistent/token-icon/png/256x256.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'euroe-stablecoin',
    },
  },
  {
    chainId: 101,
    name: 'DeFi Land',
    symbol: 'DFL',
    address: 'DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh/logo.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'defi-land',
    },
  },
  {
    chainId: 101,
    name: 'Zeta',
    symbol: 'ZEX',
    address: 'ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/zetamarkets/brand/master/assets/zeta.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'zeta',
    },
  },
  {
    chainId: 101,
    name: 'Cloud',
    symbol: 'CLOUD',
    address: 'CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu',
    decimals: 9,
    logoURI: 'https://arweave.net/N7vCgQdgQ-fab28zEB4m8QRLMwI91_KcXI-Gtr151gg',
    tags: ['verified', 'birdeye-trending', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'sanctum-2',
    },
  },
  {
    chainId: 101,
    name: 'catwifhat',
    symbol: '$CWIF',
    address: '7atgF8KQo4wJrD5ATGX7t1V2zVvykPJbFfNeVf1icFv1',
    decimals: 2,
    logoURI: 'https://bafkreigmg552j5o5qv5ra6hvg4by4qzjjichzsyaljhmyvwe5qkjphgxoi.ipfs.nftstorage.link/',
    tags: ['verified', 'community', 'strict', 'token-2022', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'catwifhat-2',
    },
  },
  {
    chainId: 101,
    name: 'Wormhole Token',
    symbol: 'W',
    address: '85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ',
    decimals: 6,
    logoURI: 'https://wormhole.com/token.png',
    tags: ['verified', 'community', 'strict', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'wormhole',
    },
  },
  {
    chainId: 101,
    name: 'PayPal USD',
    symbol: 'PYUSD',
    address: '2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo',
    decimals: 6,
    logoURI: 'https://424565.fs1.hubspotusercontent-na1.net/hubfs/424565/PYUSDLOGO.png',
    tags: ['verified', 'community', 'strict', 'token-2022', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'paypal-usd',
    },
  },
  {
    chainId: 101,
    name: 'USDe',
    symbol: 'USDE',
    address: 'DEkqHyPN7GMRJ5cArtQFAWefqbZb33Hyf6s5iCwjEonT',
    decimals: 9,
    logoURI: 'https://arweave.net/qeSnRm_FIyp_khPfmg8o1zQeGO4AczDaEKe8jEUOzL4',
    tags: ['community', 'verified', 'jupiter'],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ethena-usde',
    },
  },
  {
    chainId: 101,
    name: 'Elementum',
    symbol: 'ELE',
    address: '8A9HYfj9WAMgjxARWVCJHAeq9i8vdN9cerBmqUamDj7U',
    decimals: 9,
    logoURI: 'https://coin-images.coingecko.com/coins/images/34744/large/Elementum.png?1705949535',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'elementum',
    },
  },
  {
    chainId: 101,
    name: 'Lido DAO (Wormhole)',
    symbol: 'LDO',
    address: 'HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p',
    decimals: 8,
    logoURI: 'https://coin-images.coingecko.com/coins/images/22995/large/LDO_wh_small.png?1696522291',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'lido-dao-wormhole',
    },
  },
  {
    chainId: 101,
    name: 'Racket',
    symbol: '$RKT',
    address: 'RKT69NZHN5uovcan3q5hRbZzfJuXiGEuPmGANoBJLLz',
    decimals: 5,
    logoURI: 'https://coin-images.coingecko.com/coins/images/33387/large/IMG_6443.jpeg?1701675238',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'racket',
    },
  },
  {
    chainId: 101,
    name: 'Ratio Protocol',
    symbol: 'RATIO',
    address: 'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J',
    decimals: 6,
    logoURI: 'https://coin-images.coingecko.com/coins/images/24543/large/ratio.png?1696523720',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'ratio-finance',
    },
  },
  {
    chainId: 101,
    name: 'SolMash',
    symbol: 'MASH',
    address: 'A1AaPuUirJaCkidfntEPBSgYzg91mPBFeKjrrx342Yux',
    decimals: 9,
    logoURI: 'https://coin-images.coingecko.com/coins/images/34500/large/photo_2023-12-28_14-43-06.png?1705267779',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'solmash',
    },
  },
  {
    chainId: 101,
    name: 'Venture Coin',
    symbol: 'VC',
    address: 'AebrVZPfSH7KPAxPwnuqTZB9QNepdktk7HSSY4SNj7BM',
    decimals: 9,
    logoURI:
      'https://coin-images.coingecko.com/coins/images/30944/large/6HvW8RLbLhBbw09QDRpPd6HX-N3Sk9kF2vvdaZGHExU.png?1696529783',
    tags: [],
    verified: true,
    holders: null,
    extensions: {
      coingeckoId: 'venture-coin-2',
    },
  },
];

export const HARDCODED_TOKEN_INFOS: TokenInfo[] = [
  // TODO: Solflare does not support JLP token yet in the API, hardcoding it here
  {
    address: '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4',
    decimals: 6,
    name: 'JLP',
    symbol: 'JLP',
    logoURI: null,
  } as unknown as TokenInfo,
  {
    address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    decimals: 5,
    name: 'Bonk',
    symbol: 'BONK',
    logoURI: null,
  },
  {
    address: KMNO_MINT,
    decimals: 6,
    name: 'Kamino',
    symbol: 'KMNO',
    logoURI: 'https://cdn.kamino.finance/kamino.svg',
  } as unknown as TokenInfo,
  {
    address: 'vPtS4ywrbEuufwPkBXsCYkeTBfpzCd6hF52p8kJGt9b',
    name: 'vSOL points',
    symbol: 'vPts',
    decimals: 6,
    logoURI: 'https://coin-images.coingecko.com/coins/images/37499/large/vsol.png?1714537525',
  },
  {
    address: 'Bybit2vBJGhPF52GBdNaQfUJ6ZpThSgHBobjWZpLPb4B',
    name: 'BybitSOL',
    symbol: 'bbSOL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/40095/standard/400x400.png?1725628094',
  },
  {
    address: 'ezSoL6fY1PVdJcJsUpe5CM3xkfmy3zoVCABybm5WtiC',
    name: 'ezSOL',
    symbol: 'ezSOL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/39705/standard/ezsol-logo.png?1723706854',
  },
  {
    address: 'kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B',
    name: 'kySOL',
    symbol: 'kySOL',
    decimals: 9,
    logoURI: 'https://img-v1.raydium.io/icon/kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B.png',
  },
  {
    address: '3DK98MXPz8TRuim7rfQnebSLpA7VSoc79Bgiee1m4Zw5',
    name: 'RENZO',
    symbol: 'REZ',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/37327/standard/renzo_200x200.png?1714025012',
  },
  {
    address: 'oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp',
    name: 'ORE',
    symbol: 'ORE',
    decimals: 11,
    logoURI: 'https://assets.coingecko.com/coins/images/36854/standard/IMG_7340.png?1712633659',
  },
  {
    address: 'HeJUFDxfJSzYFUuHLxkMqCgytU31G6mjP4wKviwqpump',
    name: 'GNON',
    symbol: 'GNON',
    decimals: 6,
    logoURI: 'https://ipfs.io/ipfs/QmSdsBzucP6LmmdN7z6Bo6bV69874sgqBWBuanxR4p3hk9',
  },
  {
    address: 'Grass7B4RdKfBCjTKgSqnXkqjwiGvQyFbuSCUJr3XXjs',
    name: 'GRASS',
    symbol: 'GRASS',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/40094/standard/Grass.jpg?1725697048',
  },
  {
    address: '7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3',
    name: 'SLERF',
    symbol: 'SLERF',
    decimals: 9,
    logoURI: 'https://bafkreih44n5jgqpwuvimsxzroyebjunnm47jttqusb4ivagw3vsidil43y.ipfs.nftstorage.link/',
  },
  {
    address: '5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA',
    name: 'MUMU',
    symbol: 'MUMU',
    decimals: 6,
    logoURI: 'https://bafkreihszutctvdmdlyjtzfmj7rgvdorpc7jchj2td3feypc7veidbkpsu.ipfs.nftstorage.link/',
  },
  {
    address: '8Ki8DpuWNxu9VsS3kQbarsCWMcFGWkzzA8pUPto9zBd5',
    name: 'LOCKIN',
    symbol: 'LOCKIN',
    decimals: 9,
    logoURI: 'https://ipfs.io/ipfs/Qmc2SJQW4K7UYYVLdoKSf4cGVZbuFGTF4dZiAdRtivNkpX',
  },
  {
    address: 'ED5nyyWEzpPPiWimP8vYm7sD7TD3LAt3Q3gRTWHzPJBY',
    name: 'MOODENG',
    symbol: 'MOODENG',
    decimals: 11,
    logoURI: 'https://ipfs.io/ipfs/Qmf1g7dJZNDJHRQru7E7ENwDjcvu7swMUB6x9ZqPXr4RV2',
  },
  {
    address: 'A8C3xuqscfmyLrte3VmTqrAq8kgMASius9AFNANwpump',
    name: 'FWOG',
    symbol: 'FWOG',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/39453/standard/fwog.png?1722318442',
  },
  {
    address: 'CzLSujWBLFsSjncfkh59rUFqvafWcY5tzedWJSuypump',
    name: 'GOAT',
    symbol: 'GOAT',
    decimals: 6,
    logoURI: 'https://ipfs.io/ipfs/QmapAq9WtNrtyaDtjZPAHHNYmpSZAQU6HywwvfSWq4dQVV',
  },
  {
    address: 'GiG7Hr61RVm4CSUxJmgiCoySFQtdiwxtqf64MsRppump',
    name: 'SCF',
    symbol: 'SCF',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/39351/standard/SCF_meme.png?1727101840',
  },
  {
    address: 'DVYcTNFVGxePLgK8rUjViJvurRmTnD1FZUBR7puADymT',
    name: 'DVY',
    symbol: 'DVY',
    decimals: 9,
    logoURI: 'https://arweave.net/HQvPCXQ6uUtRT0h7Ln5E3mVDK9hUZE76O9apGJ9qcx0',
  },
  {
    address: 'cbbtcf3aa214zXHbiAZQwf4122FBYbraNdFqgw4iMij',
    name: 'cbBTC',
    symbol: 'cbBTC',
    decimals: 8,
    logoURI: 'https://ipfs.io/ipfs/QmZ7L8yd5j36oXXydUiYFiFsRHbi3EdgC4RuFwvM7dcqge',
  },
  {
    address: '2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump',
    name: 'PNUT',
    symbol: 'PNUT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/51301/standard/pnut.jpg?1730602041',
  },
  ...(filteredHardcodedData as unknown as TokenInfo[]),
  ...(kmnoHardcoded as unknown as TokenInfo[]),

  // {
  //   name: 'PayPal USD',
  //   symbol: 'PYUSD',
  //   address: '2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo',
  //   decimals: 6,
  //   logoURI: 'https://coin-images.coingecko.com/coins/images/31212/large/PYUSD_Logo_%282%29.png?1696530039',
  // },
];
