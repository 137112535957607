import { useMemo } from 'react';
import { ObligationTypeTag } from '@kamino-finance/klend-sdk';

import { BorrowLendPositionTableData } from '../types';
import { useUserObligations } from '../../../shared/hooks/lending/useUserObligations';
import { getObligationStats } from '../../../shared/utils/lending';
import { useMarkets } from '../../../shared/hooks/lending/useMarkets';

const EMPTY_DATA: BorrowLendPositionTableData[] = [];

export const useLendingPositionsTableData = () => {
  const { getMarketByAddress } = useMarkets();
  const { data: obligations, isLoading: areUserObligationsLoading } = useUserObligations([
    ObligationTypeTag.Vanilla,
    ObligationTypeTag.Lending,
  ]);

  const isLoading = areUserObligationsLoading;

  const tableData = useMemo(() => {
    if (!obligations[0]) {
      return EMPTY_DATA;
    }

    return obligations.map((obligation) => {
      const { refreshedStats } = obligation;
      const { loanToValue, netAccountValue } = refreshedStats;
      const market = getMarketByAddress(obligation.state.lendingMarket);
      const { maxLtv, liquidationLtv } = getObligationStats(market, obligation);

      return {
        address: obligation.obligationAddress,
        ltv: loanToValue.toNumber(),
        maxLtv,
        liquidationLtv: liquidationLtv.toNumber(),
        netValue: netAccountValue.toNumber(),
        deposits: Array.from(obligation.deposits.values()),
        borrows: Array.from(obligation.borrows.values()),
        pnl: Number(0),
        marketAddress: market.address,
      };
    });
  }, [obligations, getMarketByAddress]);

  return { data: tableData || EMPTY_DATA, isLoading, obligations };
};
