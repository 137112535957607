import { ReactElement, SVGProps } from 'react';

export const SvgCapacityLimitIcon = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.8 14a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      stroke="#FFA318"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.028 6.666a4 4 0 1 0 7.545 0H5.028Z" fill="#FFA318" />
  </svg>
);
