import { ReactElement, SVGProps } from 'react';

export const SvgBridge = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <path d="m18 20 3-3-3-3M18 4l3 3-3 3" />
      <path d="M21 7h-4a4.978 4.978 0 0 0-3 1M3 17h4a4.978 4.978 0 0 0 3-1M3 7h4a5 5 0 0 1 5 5 5 5 0 0 0 5 5h4" />
    </g>
  </svg>
);
