import React, { FC, useEffect, useMemo } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import Decimal from 'decimal.js';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { featureFlags } from '../../shared/utils';
import { usePriorityFee } from '../../shared/hooks/usePriorityFee';
import { PriorityFeeType } from '../../shared/types/priorityFeeStats';
import { useVersionedTransactions } from '../../shared/hooks/useVersionedTransactions';
import { DECIMALS_SOL } from '../../shared/constants/math';
import { MAX_SLIPPAGE_PCT } from '../../shared/constants/slippage';

import { Modal } from '../../shared/uiKitV2/Modal';
import { Tooltip, TooltipTrigger } from '../../shared/uiKitV2/Tooltip';
import { Tabs } from '../../shared/uiKitV2/Tabs';
import { Input } from '../../shared/uiKitV2/Input';
import { Checkbox } from '../../shared/uiKitV2/Checkbox';
import { Text } from '../../shared/uiKitV2/Typography';
import { Button } from '../../shared/uiKitV2/Button';

import s from './StrategySettingsModal.module.less';
import { LayoutProviderParams, layoutProviderStore } from '../../stores/layoutProviderStore';
import { isJlpMultiplyVaultPage, isMultiplyVaultPage } from '../../shared/utils/lending/multiply/isMultiplyVaultPage';

const SLIPPAGE_TABS = ['0.1', '0.5', '1.0'].map((v) => ({
  key: v,
  label: `${v}%`,
}));

interface FormProps {
  feeType: string;
  fee: string;
  slippage: string;
  isVersionedTxEnabled: boolean;
}

type Props = { withSlippage?: boolean };

export const StrategySettingsModal: FC<Props> = observer(({ withSlippage }) => {
  const { getPriorityFeeForType } = usePriorityFee();
  const location = useLocation();

  // we need to show a different default slippage for JLP Multiply
  const isJlpMultiplyPage = isJlpMultiplyVaultPage(location.pathname);
  // we need to show a different default slippage for Multiply
  const isMultiplyPage = isMultiplyVaultPage(location.pathname);

  const FEE_TYPE_TABS = useMemo(
    () => [
      {
        key: PriorityFeeType.minimum,
        label: 'Minimum',
      },
      {
        key: PriorityFeeType.medium,
        label: 'Medium',
      },
      {
        key: PriorityFeeType.turbo,
        label: 'Turbo',
      },
    ],
    []
  );

  const {
    slippage,
    slippageMultiply,
    slippageJlpMultiply,
    priorityFee,
    priorityFeeType,
    isVersionedTxEnabled,
    isTransactionSettingsModalVisible,
    updateProviderParams,
  } = layoutProviderStore;

  const getSlippage = () => {
    if (isJlpMultiplyPage) {
      return slippageJlpMultiply;
    }
    if (isMultiplyPage) {
      return slippageMultiply;
    }

    return slippage;
  };

  const formValueFromProvider = {
    slippage: getSlippage().toString(),
    feeType: priorityFeeType.toString(),
    fee: priorityFee.toString(),
    isVersionedTxEnabled,
  };

  const { reset, watch, handleSubmit, control, register, setValue } = useForm<FormProps>({
    defaultValues: formValueFromProvider,
  });

  useEffect(() => {
    if (isTransactionSettingsModalVisible) {
      reset(formValueFromProvider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionSettingsModalVisible, reset]);

  const selectedFeePreset = FEE_TYPE_TABS.find((i) => i.key === watch('feeType'));

  const onSubmit = (data: FormProps) => {
    const { slippage: slippageForm, fee: feeForm, isVersionedTxEnabled: isVersionedTxEnabledForm } = data;

    const resultFee =
      selectedFeePreset && selectedFeePreset.key !== PriorityFeeType.custom
        ? getPriorityFeeForType(selectedFeePreset.key)
        : feeForm;

    const params: Partial<LayoutProviderParams> = {
      priorityFeeType: (selectedFeePreset?.key as PriorityFeeType) || PriorityFeeType.custom,
      priorityFee: new Decimal(resultFee),
      isVersionedTxEnabled: isVersionedTxEnabledForm,
      isTransactionSettingsModalVisible: false,
    };

    if (isJlpMultiplyPage) {
      params.slippageJlpMultiply = new Decimal(slippageForm);
    } else if (isMultiplyPage) {
      params.slippageMultiply = new Decimal(slippageForm);
    } else {
      params.slippage = new Decimal(slippageForm);
    }

    updateProviderParams(params, true);
  };

  const { checkIfVersionedTransactionsSupported } = useVersionedTransactions();

  const mixpanel = useMixpanel();

  const handleCancel = () => {
    updateProviderParams({ isTransactionSettingsModalVisible: false });

    reset();

    mixpanel.track('kamino:liquidity:strategy-settings-modal:click-cancel');
  };

  const versionedTxTooltipText = !checkIfVersionedTransactionsSupported()
    ? 'Your current wallet does not support Versioned Transactions'
    : 'Older versions of Ledger do not support versioned txns. Uncheck this box if transactions are not executing';

  return (
    <Modal isOpen={isTransactionSettingsModalVisible} onClose={handleCancel} isOverSidepanel>
      <form onSubmit={handleSubmit(onSubmit)} className={s.content}>
        <Text fs={20} lh={26}>
          Settings
        </Text>

        {withSlippage && (
          <div>
            <Text fs={12} lh={16} color="grey" weight="bold" className={s.label}>
              Slippage Tolerance
            </Text>

            <div className={s.slippage}>
              <Controller
                name="slippage"
                control={control}
                render={({ field }) => {
                  return (
                    <Tabs
                      isFullWidth
                      onChange={field.onChange}
                      tabs={SLIPPAGE_TABS}
                      activeKey={field.value === '1' ? '1.0' : field.value}
                    />
                  );
                }}
              />

              <Controller
                name="slippage"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    value={field.value}
                    onValueChange={({ value }) => {
                      field.onChange(value);
                    }}
                    isAllowed={({ floatValue = 0 }) => floatValue <= MAX_SLIPPAGE_PCT}
                    suffix="%"
                    className={s.slippageInput}
                    customInput={Input}
                    placeholder="0.00%"
                    maxLength={5}
                    decimalScale={2}
                    size="small"
                    allowNegative={false}
                  />
                )}
              />
            </div>

            <Text fs={12} lh={16} color="grey" weight="regular" className={s.note}>
              Your transaction will revert if the price changes unfavorably by more than this percentage.
            </Text>
          </div>
        )}

        <div className={s.settingBlock}>
          <Text fs={12} lh={16} color="grey" weight="bold" className={s.label}>
            Priority fee
          </Text>

          <div className={s.fee}>
            <Controller
              name="feeType"
              control={control}
              render={({ field }) => (
                <Tabs isFullWidth onChange={field.onChange} tabs={FEE_TYPE_TABS} activeKey={field.value} />
              )}
            />

            <Controller
              name="fee"
              control={control}
              render={({ field }) => (
                <div className={s.feeInputWrapper}>
                  <Text fs={14} lh={20} color="grey" className={s.feeInputPrefix}>
                    Custom max priority fee (SOL)
                  </Text>

                  <NumericFormat
                    value={
                      selectedFeePreset && selectedFeePreset.key !== PriorityFeeType.custom
                        ? getPriorityFeeForType(selectedFeePreset.key).toNumber()
                        : field.value
                    }
                    onValueChange={({ value }) => {
                      field.onChange(value);
                      setValue('feeType', PriorityFeeType.custom);
                    }}
                    className={s.feeInput}
                    customInput={Input}
                    placeholder="0.000%"
                    maxLength={8}
                    isAllowed={({ floatValue = 0 }) => floatValue <= 1}
                    decimalScale={DECIMALS_SOL}
                    size="small"
                    allowNegative={false}
                  />
                </div>
              )}
            />
          </div>

          <Text fs={12} lh={16} weight="regular" color="grey" className={s.note}>
            The priority fee is paid to the Solana network. This additional fee helps boost how a transaction is
            prioritized against others, resulting in faster transaction execution times.
          </Text>
        </div>

        {featureFlags.isVersionedTransactionsEnabled && (
          <div className={s.checkboxWrapper}>
            <Checkbox
              {...register('isVersionedTxEnabled')}
              title={
                <TooltipTrigger id="versioned-transaction-tooltip">
                  <Text>Versioned Transaction</Text>
                </TooltipTrigger>
              }
              subtitle={
                <Text fs={12} lh={12} color="grey">
                  (Please, uncheck if using Ledger or WalletConnect)
                </Text>
              }
              disabled={!checkIfVersionedTransactionsSupported()}
            />
          </div>
        )}

        <div className={s.buttons}>
          <Button isFullWidth type="submit">
            Save
          </Button>
          <Button isFullWidth variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
      <Tooltip id="versioned-transaction-tooltip" content={versionedTxTooltipText} />
    </Modal>
  );
});
