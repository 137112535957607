import { PublicKey } from '@solana/web3.js';

const LST_TOKEN_MINTS = [
  'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
  'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
  'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
  'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A',
  'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v',
  'vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7',
];

export const isLST = (mint: PublicKey) => LST_TOKEN_MINTS.includes(mint.toString());
