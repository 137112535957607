import { useQuery } from '@tanstack/react-query';

import axios from 'axios';
import { QUERYKEYS } from '../constants/queryKeys';
import { fetchUserHasRisk } from '../services/users';

import useEnv from './useEnv';

type Output = {
  hasWalletRestriction?: boolean;
};

export const useWalletRestriction = (): Output => {
  const { walletPublicKey } = useEnv();

  const { data, error } = useQuery({
    queryKey: QUERYKEYS.risk(walletPublicKey),
    enabled: !!walletPublicKey,
    queryFn: () => {
      if (!walletPublicKey) {
        return { hasRisk: false };
      }

      return fetchUserHasRisk(walletPublicKey);
    },
    retry: 0,
  });

  if (!walletPublicKey || axios.isAxiosError(error)) {
    return {
      hasWalletRestriction: false,
    };
  }

  return { hasWalletRestriction: data?.hasRisk };
};
