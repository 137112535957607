import { useQuery } from '@tanstack/react-query';
import { notify } from '../../../utils/notifications/notifications';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { captureError } from '../../../utils/captureError';
import { fetchKaminoResources } from '../../../services/resources';
import { KaminoResources } from '../../../types/resources';
import isStagingEnabled from '../../../utils/stagingProgram';
import { getEnvChain } from '../../../../providers/utils';

const queryFn = async (): Promise<KaminoResources> => {
  const env = getEnvChain();
  const resources = await fetchKaminoResources();
  let result = resources.devnet;
  if (env === 'mainnet-beta') {
    result = resources['mainnet-beta'];
  }
  if (isStagingEnabled) {
    // TODO: maybe add some strategies to resources API
    result.strategiesData = {};
  }
  return result;
};

export const useKaminoResourcesQuery = () => {
  const { data, ...rest } = useQuery<KaminoResources>({
    queryKey: QUERYKEYS.getKaminoResourcesQuery,
    queryFn,
    refetchInterval: 10 * 60 * 1000, // 10mins, this won't be updated often, so it's fine
    onError: (err) => {
      captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load Kamino resources',
      });
    },
  });

  return { data, ...rest };
};
