import { useMemo } from 'react';
import { Farms } from '@hubbleprotocol/farms-sdk';
import { useWeb3Client } from './useWeb3Client';

export const useKaminoFarms = () => {
  const { web3client } = useWeb3Client();
  const kaminoFarms = useMemo(() => (web3client ? new Farms(web3client.connection) : undefined), [web3client]);

  return {
    kaminoFarms,
  };
};
