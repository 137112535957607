import React, { FC } from 'react';
import Decimal from 'decimal.js';
import { formatNumber, getPriceImpactDangerLevel, PriceImpactDangerLevel, PriceImpactType } from '../../utils';
import { Notification } from '../../uiKitV2/Notification';
import s from './PriceImpactNotifications.module.less';
import { PublicKeyAddress } from '../../types/strategies';
import useTokens from '../../hooks/useTokens';
import { checkPriceDivergenceThreshold } from '../../../features/LeverageForms/utils';
import { WarningTag } from '../../uiKitV2/WarningTag';
import { MaxPriceImpactTooltip } from '../../../features/LeverageForms/components/Tooltips/MaxPriceImpactTooltip';
import { PriceDivergenceTooltip } from '../../../features/LeverageForms/components/Tooltips/PriceDivergenceTooltip';

const { formatPercent: fp, formatTokenAllDecimals: ftad } = formatNumber;

interface PriceImpactNotificationsProps {
  type: PriceImpactType;
  priceImpactBps: Decimal;
  collTokenMint: PublicKeyAddress;
  theoreticalPriceDivergenceBps: Decimal;
  theoreticalPrice: Decimal;
  marketPrice: Decimal;
  variant?: 'notification' | 'tag';
}

function getNotificationType(
  notificationDangerLevel: PriceImpactDangerLevel,
  theoreticalPriceDivergenceBps: Decimal,
  leverageType: PriceImpactType
) {
  if (notificationDangerLevel === PriceImpactDangerLevel.danger) {
    return 'danger';
  }

  if (
    notificationDangerLevel === PriceImpactDangerLevel.warning ||
    checkPriceDivergenceThreshold(leverageType, theoreticalPriceDivergenceBps)
  ) {
    return 'warning';
  }

  return 'info';
}

const getTagTypeByPriceImpact = (notificationType: 'danger' | 'warning' | 'info') => {
  switch (notificationType) {
    case 'danger':
      return 'danger';
    case 'warning':
      return 'warning';
    case 'info':
      return 'primary';
  }
};

export const PriceImpactNotifications: FC<PriceImpactNotificationsProps> = (props: PriceImpactNotificationsProps) => {
  const { getToken } = useTokens();
  const {
    priceImpactBps,
    collTokenMint,
    theoreticalPriceDivergenceBps,
    theoreticalPrice,
    marketPrice,
    type,
    variant = 'notification',
  } = props;
  const notificationDangerLevel = getPriceImpactDangerLevel(type, priceImpactBps);
  const notificationType = getNotificationType(notificationDangerLevel, theoreticalPriceDivergenceBps, type);
  const collTokenInfo = getToken(collTokenMint);
  const hasPriceImpactNotification = notificationDangerLevel > PriceImpactDangerLevel.safe;
  const hasPriceDivergenceNotification = checkPriceDivergenceThreshold(type, theoreticalPriceDivergenceBps);
  const hasNotifications = hasPriceImpactNotification || hasPriceDivergenceNotification;

  if (!hasNotifications) {
    return null;
  }

  return (
    <>
      {variant === 'notification' ? (
        <Notification
          className={s.notification}
          variant={notificationType}
          text={
            <div className={s.notificationBody}>
              {hasPriceImpactNotification ? (
                <div>
                  Max price impact is <b>{fp(priceImpactBps.div(100))}</b> due to swap size or current{' '}
                  {collTokenInfo.symbol} market price or your slippage settings. Carefully review the wallet values
                  before confirming and consider breaking up the trade into smaller amounts.
                </div>
              ) : null}

              {hasPriceDivergenceNotification ? (
                <div>
                  Market price <b>({ftad(marketPrice, 9)})</b> and oracle price <b>({ftad(theoreticalPrice, 9)})</b> are
                  different by <b>{fp(theoreticalPriceDivergenceBps.div(100))}</b>.
                </div>
              ) : null}
            </div>
          }
        />
      ) : (
        <>
          {hasPriceImpactNotification && (
            <WarningTag
              text={`Price Impact ${fp(priceImpactBps.div(100))}`}
              hasIcon={false}
              tooltip={
                <MaxPriceImpactTooltip
                  priceImpactBps={priceImpactBps.toNumber()}
                  dangerLevel={notificationDangerLevel}
                />
              }
              variant={getTagTypeByPriceImpact(notificationType)}
            />
          )}

          {hasPriceDivergenceNotification && (
            <WarningTag
              text={`Price Difference ${fp(theoreticalPriceDivergenceBps.div(100))}`}
              hasIcon={false}
              tooltip={
                <PriceDivergenceTooltip
                  priceDivergenceBps={theoreticalPriceDivergenceBps}
                  marketPrice={marketPrice}
                  oraclePrice={theoreticalPrice}
                  priceTokenMint={collTokenMint}
                />
              }
              variant="warning"
            />
          )}
        </>
      )}
    </>
  );
};
