import { Suspense, lazy, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMixpanel } from 'react-mixpanel-browser';

import { Logo } from '../../shared/uiKitV2/Logo';
import { IconButton } from '../../shared/uiKitV2/IconButton';
import { SvgBurger } from '../../shared/uiKitV2/Icons';

import { Menu } from '../MenuV2';
import paths from '../../shared/routes/paths';
import { ConnectButton } from '../../shared/components/v2/ConnectButton';
import useEnv from '../../shared/hooks/useEnv';
import s from './Header.module.less';
import { ConnectedWalletButton } from './components/ConnectedWalletButton';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { DESKTOP_BP } from '../../shared/constants/breakpointsV2';
import { layoutProviderStore } from '../../stores/layoutProviderStore';
import { NotificationsWidget } from './components/NotificationsWidget';
import { WhatsNewIndicator } from '../../shared/components/v2/WhatsNewIndicator';

const PointsButton = lazy(() => import('../PointsButton/PointsButton'));

// eslint-disable-next-line
export const Header = observer(({ isSurgeOptimised = false }: { isSurgeOptimised?: boolean }) => {
  const { walletPublicKey } = useEnv();
  const mixpanel = useMixpanel();
  const { updateProviderParams } = layoutProviderStore;
  const [isAfterInitialRenderTimeout, setIsAfterInitialRenderTimeout] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsAfterInitialRenderTimeout(true), 0);
  }, []);

  const { width } = useWindowSize();
  const isMobile = width < DESKTOP_BP;

  const handleLogoClick = () => {
    mixpanel.track('header:main');
  };

  const handleSettingsClick = () => {
    if (isMobile) {
      updateProviderParams({
        isMobileMenuOpened: true,
      });
      mixpanel.track('header:mobile_menu');
    } else {
      mixpanel.track('header:settings');
    }
  };

  return (
    <div className={s.root}>
      <div className={s.navLink}>
        <NavLink to={paths.MAIN} onClick={handleLogoClick}>
          <Logo />
        </NavLink>
      </div>

      <div className={s.menuWrapper}>
        <Menu />
      </div>

      <div className={s.controls}>
        {isAfterInitialRenderTimeout && (
          <Suspense fallback={null}>
            <PointsButton />
          </Suspense>
        )}

        <WhatsNewIndicator id="whats:new:notification-icon" tooltip="Click to setup notifications">
          <NotificationsWidget />
        </WhatsNewIndicator>

        {!walletPublicKey ? (
          <ConnectButton />
        ) : (
          <WhatsNewIndicator id="whats:new:wallet" tooltip="Click to open new wallet panel">
            <ConnectedWalletButton variant="mobile" />
          </WhatsNewIndicator>
        )}

        {/* {walletPublicKey && !isMobile && ( */}
        {/*  <IconButton onClick={handleSettingsClick} variant="secondary" size="large" icon={<SvgSettings />} /> */}
        {/* )} */}

        {isMobile && <IconButton onClick={handleSettingsClick} variant="secondary" size="large" icon={<SvgBurger />} />}
      </div>
    </div>
  );
});
