import { PublicKey } from '@solana/web3.js';
import { chunk } from 'lodash';
import { Decimal } from 'decimal.js';
import { createJupiterApiClient, QuoteGetRequest, QuoteGetSwapModeEnum, QuoteResponse } from '@jup-ag/api';
import axios from 'axios';
import { PublicKeyAddress } from '../../types/strategies';
import { featureFlags } from '../../utils';
import { capSlippage } from '../../utils/jupiter';
import { TokenInfo } from '../../types/token-info';
import { USDC_MINT } from '../../constants/mints';

const apiClient = createJupiterApiClient();

const JUPITER_PRICE_API = 'https://api.jup.ag/price/v2';

interface JupiterPriceResponse {
  data: {
    [key: string]: {
      id: string;
      mintSymbol: string;
      vsToken: string;
      vsTokenSymbol: string;
      price: number;
    };
  };
  timeTaken: number;
}

export class JupiterService {
  /**
   * Returns only swap instructions, no atas, no clean up
   * @param quote
   * @param walletPublicKey
   * @param wrapUnwrapSOL
   * @param asLegacyTransaction
   */
  static getSwapInstructions = async (
    quote: QuoteResponse,
    walletPublicKey: PublicKey,
    wrapUnwrapSOL = false,
    asLegacyTransaction?: boolean
  ) => {
    return apiClient.swapInstructionsPost({
      swapRequest: {
        quoteResponse: quote,
        // user public key to be used for the swap
        userPublicKey: walletPublicKey.toString(),
        // auto wrap and unwrap SOL. default is true
        wrapAndUnwrapSol: wrapUnwrapSOL,
        asLegacyTransaction,
      },
    });
  };

  static getBestRouteImpl = async ({
    amountLamports,
    inputMint,
    outputMint,
    slippage,
    mode = QuoteGetSwapModeEnum.ExactIn,
    // eslint-disable-next-line no-unused-vars
    onlyDirectRoutes,
    maxAccounts,
    asLegacyTransaction,
    excludeDexes,
    restrictIntermediateTokens,
  }: {
    amountLamports: Decimal;
    inputMint: PublicKeyAddress;
    outputMint: PublicKeyAddress;
    slippage: number;
    mode: QuoteGetSwapModeEnum;
    onlyDirectRoutes?: boolean;
    maxAccounts?: number;
    asLegacyTransaction?: boolean;
    excludeDexes?: QuoteGetRequest['excludeDexes'];
    getToken?: (address: PublicKeyAddress) => TokenInfo;
    restrictIntermediateTokens?: boolean;
  }) => {
    const params: QuoteGetRequest = {
      inputMint: inputMint.toString(),
      outputMint: outputMint.toString(),
      amount: amountLamports.ceil().toNumber(),
      slippageBps: capSlippage(slippage * 100).toNumber(),
      onlyDirectRoutes,
      maxAccounts,
      asLegacyTransaction,
      swapMode: mode,
    };
    // make sure not to pass excludeDexes: undefined into params otherwise jup produces broken params for api call
    if (excludeDexes && excludeDexes.length > 0) {
      params.excludeDexes = excludeDexes;
    }

    const quote = await apiClient.quoteGet({
      restrictIntermediateTokens: restrictIntermediateTokens && featureFlags.isRestrictIntermediateTokensEnabled,
      ...params,
    });

    return quote;
  };

  static getBestRoute = async ({
    amountLamports,
    inputMint,
    outputMint,
    slippage,
    mode = QuoteGetSwapModeEnum.ExactIn,
    onlyDirectRoutes,
    maxAccounts,
    asLegacyTransaction,
    excludeDexes,
    getToken,
    restrictIntermediateTokens,
  }: {
    amountLamports: Decimal;
    inputMint: PublicKeyAddress;
    outputMint: PublicKeyAddress;
    slippage: number;
    mode: QuoteGetSwapModeEnum;
    onlyDirectRoutes?: boolean;
    maxAccounts?: number;
    asLegacyTransaction?: boolean;
    excludeDexes?: QuoteGetRequest['excludeDexes'];
    getToken?: (address: PublicKeyAddress) => TokenInfo;
    restrictIntermediateTokens?: boolean;
  }): Promise<QuoteResponse> => {
    return this.getBestRouteImpl({
      amountLamports,
      inputMint,
      outputMint,
      slippage,
      mode,
      onlyDirectRoutes,
      maxAccounts,
      asLegacyTransaction,
      excludeDexes,
      getToken,
      restrictIntermediateTokens,
    });
  };

  static getPrice = async (inputMint: PublicKey | string, outputMint: PublicKey | string): Promise<number> => {
    const params = {
      ids: inputMint.toString(),
      vsToken: outputMint.toString(),
    };

    const res = await axios.get(JUPITER_PRICE_API, { params });
    return res.data.data[inputMint.toString()]?.price || 0;
  };

  static getPricesVsToken = async (inputMints: string[], vsToken = USDC_MINT): Promise<Record<string, number>> => {
    const chunks = chunk(inputMints, 100);
    const prices: Record<string, number> = {};

    for (const item of chunks) {
      const ids = item.join(',');

      const res = await axios.get<JupiterPriceResponse>(JUPITER_PRICE_API, {
        params: { ids, vsToken },
      });

      for (const [key, value] of Object.entries(res.data.data)) {
        prices[`${key}-${vsToken}`] = value.price;
        prices[`${vsToken}-${key}`] = 1 / value.price;
      }
    }

    return prices;
  };
}
