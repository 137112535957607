import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import { some } from 'lodash';
import s from './MultiplyPositions.module.less';
import { Text } from '../../../../../../../../shared/uiKitV2/Typography';
import { LendingPositionName, PnlCell } from '../../../../../../../../pages/PortfolioV2/components/TableCells';
import { formatNumber, isJlpTokenMint } from '../../../../../../../../shared/utils';
import { useLendingMultiplyPositionsTableData } from '../../../../../../../../pages/PortfolioV2/hooks/useLendingMultiplyPositionsTableData';
import { PositionsAccordion } from '../../../PositionsAccordion/PositionsAccordion';
import { PublicKeyAddress } from '../../../../../../../../shared/types/strategies';
import { buildMultiplyVaultUrl } from '../../../../../../../../shared/utils/lending/routes';
import { layoutProviderStore } from '../../../../../../../../stores/layoutProviderStore';

const { formatUsd: fusd } = formatNumber;

export const MultiplyPositions: FC = () => {
  const { data, isLoading } = useLendingMultiplyPositionsTableData();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { updateProviderParams } = layoutProviderStore;

  const goToVaultPage = useCallback(
    (
      depositReserveAddress?: PublicKeyAddress,
      borrowReserveAddress?: PublicKeyAddress,
      marketAddress?: PublicKeyAddress
    ) => {
      if (depositReserveAddress && borrowReserveAddress && marketAddress) {
        history.push(
          buildMultiplyVaultUrl({
            depositReserveAddress,
            borrowReserveAddress,
            marketAddress: marketAddress.toString(),
          })
        );
        mixpanel.track('dashboard:position_click', {
          address: `${depositReserveAddress.toString()}-${borrowReserveAddress.toString()}`,
          type: 'multiply',
        });
      }
      updateProviderParams({ isSidePanelOpened: false });
    },
    [history, mixpanel, updateProviderParams]
  );

  if (!data.length && !isLoading) {
    return null;
  }

  return (
    <PositionsAccordion header="Multiply" isLoading={isLoading}>
      <div className={s.list}>
        {data.map((position) => {
          const depositMints = position.deposits.map((d) => d.mintAddress);

          return (
            <div
              className={s.tokenItem}
              key={position.address.toString()}
              onClick={() =>
                goToVaultPage(position.reserveAddress, position.borrowReserveAddress, position.marketAddress)
              }
            >
              <div className={s.tokenRight}>
                <LendingPositionName textProps={{ fs: 16, lh: 22 }} tokensMints={depositMints} maxTokensDisplayed={3} />
              </div>

              <div className={s.tokenValues}>
                <Text fs={14} lh={20}>
                  {fusd(position.netValue)}
                </Text>
                <PnlCell
                  dataType={some(position.deposits, (d) => isJlpTokenMint(d.mintAddress)) ? 'usd' : 'sol'}
                  obligationAddress={position.address}
                  marketAddress={position.marketAddress}
                  textProps={{ fs: 14, lh: 20 }}
                  label=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </PositionsAccordion>
  );
};
