import { PublicKey } from '@solana/web3.js';
import { PublicKeyAddress } from '../types/strategies';

export function isValidPubkey(address?: PublicKeyAddress): address is PublicKeyAddress {
  if (!address) {
    return false;
  }

  return !new PublicKey(address).equals(PublicKey.default);
}
