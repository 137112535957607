import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { withStyles } from '@bruitt/classnames';
import { Dropdown } from '../../uiKitV2/Dropdown';
import { Text } from '../../uiKitV2/Typography';
import { CommonProps } from '../../constants/menuItems';

import s from './MenuItem.module.less';
import { SvgChevronDown, SvgArrowTopRight } from '../../uiKitV2/Icons';
import { BetaBadge } from '../v2/BetaBadge';
import { Icon } from '../../uiKitV2/Icon';
import { Tag } from '../../uiKitV2/Tag';
import { MenuDropdownItem } from './MenuDropdownItem';
import { DropdownItem } from '../../uiKitV2/DropdownItem';

const sx = withStyles(s);

interface ItemProps extends CommonProps {
  onClick?: () => void;
  path?: string;
  link?: string;
  isMobile?: boolean;
  isNewOption?: boolean;
}

interface SubmenuItemProps extends ItemProps {
  isDisabled?: boolean;
}

interface MenuItemProps extends ItemProps {
  submenu?: SubmenuItemProps[];
  subMenuPlace?: 'bottomLeft' | 'bottomRight';
}

interface LabelProps {
  label: string;
  isLink?: boolean;
}

const Label: FC<LabelProps> = ({ label, isLink }) => (
  <Text fs={14} lh={18} tag="span">
    {label}

    {isLink && <SvgArrowTopRight />}
  </Text>
);

const checkIsActive = (paths: string[] | undefined, currentPath: string) => {
  if (!paths) {
    return false;
  }

  return paths.some((p) => {
    const regex = new RegExp(`^${p.replace(/:\w+/g, '\\w+')}$`);
    return regex.test(currentPath);
  });
};

const InternalLinkMenuItem: FC<{
  path: string;
  onClick?: () => void;
  activePaths?: string[];
  hideOnTablet?: boolean;
  isMobile?: boolean;
  isDisabled?: boolean;
}> = ({ path, onClick, children, activePaths, hideOnTablet, isMobile, isDisabled }) => {
  const location = useLocation();

  const handleClick = (e: any) => {
    if (isDisabled) {
      e.preventDefault();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <NavLink
      isActive={() => checkIsActive(activePaths, location.pathname)}
      exact
      to={isDisabled ? '' : path}
      className={sx(s.menuItem, { hideOnTablet, isMobile })}
      activeClassName={s.isActive}
      onClick={handleClick}
    >
      {children}
    </NavLink>
  );
};

const ExternalLinkMenuItem: FC<{
  link?: string;
  onClick?: () => void;
  hideOnTablet?: boolean;
  isMobile?: boolean;
}> = ({ link, onClick, children, hideOnTablet, isMobile }) => {
  return (
    <a
      href={link}
      className={sx(s.menuItem, { hideOnTablet, isMobile })}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const SubmenuMenuItem: FC<{
  label: string;
  submenu?: SubmenuItemProps[];
  subMenuPlace: MenuItemProps['subMenuPlace'];
  isMobile?: boolean;
  activePaths?: string[];
}> = ({ label, submenu, activePaths, subMenuPlace, isMobile }) => {
  const location = useLocation();
  const isActiveGeneral = checkIsActive(activePaths, location.pathname);
  const hasHideOnTablet = submenu?.find((i) => i.hideOnTablet && i.path === location.pathname);

  return (
    <Dropdown
      trigger={['hover']}
      placement={subMenuPlace}
      overlay={
        <div className={s.submenuList}>
          {submenu
            ?.filter((i) => !i.isHidden)
            .map((item) => {
              if (item.path) {
                const isActive = checkIsActive(item.activePaths, location.pathname);

                return (
                  <InternalLinkMenuItem
                    key={item.id}
                    path={item.path}
                    onClick={item?.onClick}
                    isMobile={isMobile}
                    hideOnTablet={item.hideOnTablet}
                    isDisabled={item.isDisabled}
                  >
                    {label === 'KMNO' ? (
                      <MenuDropdownItem
                        isActive={isActive}
                        isDisabled={item.isDisabled}
                        description={item.description}
                        startIcon={<Icon size={30} name={item.icon} />}
                        title={item.label}
                      />
                    ) : (
                      <DropdownItem
                        isActive={isActive}
                        isDisabled={item.isDisabled}
                        startIcon={<Icon size={16} name={item.icon} />}
                        title={item.label}
                      />
                    )}
                  </InternalLinkMenuItem>
                );
              }

              return (
                <ExternalLinkMenuItem
                  key={item.id}
                  link={item.link}
                  isMobile={isMobile}
                  onClick={item?.onClick}
                  hideOnTablet={item.hideOnTablet}
                >
                  <DropdownItem
                    key={item.id}
                    title={item.label}
                    isDisabled={item.isDisabled}
                    startIcon={<Icon size={16} name={item.icon} />}
                    endIcon={<SvgArrowTopRight width={16} height={16} />}
                  />
                </ExternalLinkMenuItem>
              );
            })}
        </div>
      }
    >
      <a
        className={sx(s.menuItem, { isActive: isActiveGeneral, hasHideOnTablet, isMobile })}
        href="#"
        onClick={(e) => e.preventDefault()}
      >
        {label === 'KMNO' && (
          <>
            <Icon className={s.menuIcon} name="KmnoLogo" size={14} />
            <Icon className={s.menuIconHovered} name="KmnoLogoHovered" size={14} />
          </>
        )}
        <Label label={label} />
        <SvgChevronDown width={16} height={16} />
      </a>
    </Dropdown>
  );
};

export const MenuItem: FC<MenuItemProps> = (props) => {
  const {
    path,
    activePaths,
    link,
    label,
    submenu,
    subMenuPlace = 'bottomRight',
    onClick,
    hideOnTablet,
    isMobile,
    isBetaOption,
    isNewOption,
  } = props;

  if (path) {
    return (
      <InternalLinkMenuItem
        path={path}
        activePaths={activePaths}
        onClick={onClick}
        hideOnTablet={hideOnTablet}
        isMobile={isMobile}
      >
        {isBetaOption && (
          <div className={s.betaBadge}>
            <BetaBadge isHiddenTooltip />
          </div>
        )}
        {isNewOption && (
          <div className={s.betaBadge}>
            <Tag variant="blue" size="small" label="New" />
          </div>
        )}
        <Label label={label} />
      </InternalLinkMenuItem>
    );
  }

  if (link) {
    return (
      <ExternalLinkMenuItem link={link} onClick={onClick} hideOnTablet={hideOnTablet} isMobile={isMobile}>
        <Label label={label} isLink />
      </ExternalLinkMenuItem>
    );
  }

  if (submenu?.length) {
    return (
      <SubmenuMenuItem
        label={label}
        submenu={submenu}
        subMenuPlace={subMenuPlace}
        activePaths={activePaths}
        isMobile={isMobile}
      />
    );
  }

  return null;
};
