export enum SeasonTab {
  season1 = 'season1',
  season2 = 'season2',
  season3 = 'season3',
}

export enum PointsTab {
  leaderboard = 'leaderboard',
  breakdown = 'breakdown',
  boost = 'boost',
  info = 'info',
}

export const PointsTabs = [
  { key: PointsTab.leaderboard, label: 'Leaderboard' },
  { key: PointsTab.breakdown, label: 'My Breakdown' },
  { key: PointsTab.boost, label: 'Rates & Boosts' },
  { key: PointsTab.info, label: 'How it Works' },
];

export const SeasonTabs = [
  { key: SeasonTab.season1, label: 'Season 1' },
  { key: SeasonTab.season2, label: 'Season 2' },
  { key: SeasonTab.season3, label: 'Season 3' },
];
export type SeasonType = keyof typeof SeasonTab;
