import { FC, ReactNode, useState } from 'react';
import { Wallet } from '@solana/wallet-adapter-react';
import { useMixpanel } from 'react-mixpanel-browser';
import { observer } from 'mobx-react-lite';
import { WalletsList } from './components/WalletsList/WalletsList';
import { OnboardingList } from './components/OnboardingList/OnboardingList';
import useEnv from '../../../../shared/hooks/useEnv';
import { redirectIfWalletIsNotInstalled } from '../../../../shared/utils';
import { notify } from '../../../../shared/utils/notifications/notifications';
import { captureError } from '../../../../shared/utils/captureError';
import { useLastSelectedWallet } from './hooks/useLastSelectedWallet';
import { MIXPANEL_PREFIX } from '../../../../shared/constants/mixpanel';
import s from './WalletConectMode.module.less';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';

type ConnectMode = 'list' | 'onboarding';
export const WalletConnectMode: FC = observer(() => {
  const [connectMode, setConnectMode] = useState<ConnectMode>('list');
  const { wallets, wallet, disconnectWallet, selectWalletAndConnect } = useEnv();

  const { setLastSelectedWalletName } = useLastSelectedWallet();
  const mixpanel = useMixpanel();
  const { updateProviderParams } = layoutProviderStore;
  const handleBack = () => {
    setConnectMode('list');
  };

  const handleOnOnboarding = () => {
    mixpanel.track(`${MIXPANEL_PREFIX.SIDEPANEL.BASE}:onboarding:click`);
    setConnectMode('onboarding');
  };

  const handleSelectedWalletChange = async (selectedWallet: Wallet) => {
    if (redirectIfWalletIsNotInstalled(wallets, selectedWallet)) {
      return;
    }
    try {
      selectWalletAndConnect(selectedWallet.adapter.name);
      updateProviderParams({ isSidePanelOpened: false });
      setLastSelectedWalletName(selectedWallet.adapter.name);
    } catch (e) {
      notify({
        type: 'error',
        message: 'Failed to connect wallet. Please, try again',
      });
      setLastSelectedWalletName();
      disconnectWallet();
      await captureError(e);
    } finally {
      // onCancel();
      // updateProviderParams({ isSidePanelRendered: false });
    }
  };

  const content: Record<ConnectMode, ReactNode> = {
    list: (
      <WalletsList
        onOnboarding={handleOnOnboarding}
        onSelect={handleSelectedWalletChange}
        wallets={wallets}
        selectedWallet={wallet}
      />
    ),
    onboarding: <OnboardingList onBack={handleBack} onSelect={handleSelectedWalletChange} wallets={wallets} />,
  };

  return <div className={s.content}>{content[connectMode]}</div>;
});
