import { useCallback } from 'react';

import { KaminoReserve } from '@kamino-finance/klend-sdk';
import { useReserves } from './useReserves';
import useReserveFarms from './useReserveFarms';
import { useStakingYield } from './useStakingYield';
import { useCurrentSlotQuery } from '../queries/useCurrentSlotQuery';
import { getReserveApys } from './helpers';
import { getKTokenSuplyApy } from './useKtokenSuplyApy';
import { useMetricsQuery } from '../../queriesCleanedUp/useMetricsQuery';
import { useKaminoResourcesStrategiesQueryCleanedUp } from '../../queriesCleanedUp/useStrategiesQueryCleanedUp';

export const useReserveApys = () => {
  const { getReserveByAddress } = useReserves();
  const { getFarmByAddress } = useReserveFarms();
  const { data: currentSlot = 0 } = useCurrentSlotQuery({ refetchInterval: 15 * 1000 }); // 15 sec
  const { metricsByStrategy } = useMetricsQuery();
  const { strategiesByAddress } = useKaminoResourcesStrategiesQueryCleanedUp();

  const { getStakingYieldByMint } = useStakingYield();
  const getKTokenSupplyApyLocal = useCallback(
    (tokenMint: string) =>
      getKTokenSuplyApy({
        metricsByStrategy,
        strategiesByAddress,
        tokenMint,
      }),
    [metricsByStrategy, strategiesByAddress]
  );

  const getReserveApysCb = useCallback(
    getReserveApys({
      currentSlot,
      getFarmByAddress,
      getKTokenSupplyApy: getKTokenSupplyApyLocal,
      getReserveByAddress,
      getStakingYieldByMint,
    }),
    [currentSlot, getFarmByAddress, getReserveByAddress, getStakingYieldByMint]
  );

  return {
    getReserveApys: getReserveApysCb,
  };
};

export const useLendingBorrowApy = (reserve?: KaminoReserve) => {
  const { data: currentSlot = 0 } = useCurrentSlotQuery({ refetchInterval: 15 * 1000 }); // 15 sec
  return reserve?.totalBorrowAPY(currentSlot) || 0;
};
