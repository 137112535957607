import { ReactElement, SVGProps } from 'react';

export const SvgJitoSol = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={250} cy={250} r={234} fill="url(#jito_sol_svg__a)" />
    <path
      d="M173.73 304.577a7.46 7.46 0 0 1 5.288-2.194h182.443c3.334 0 5.001 4.042 2.644 6.41l-36.04 36.208a7.46 7.46 0 0 1-5.288 2.194H140.334c-3.334 0-5.001-4.042-2.644-6.41l36.04-36.208ZM173.73 169.391c1.437-1.386 3.334-2.195 5.288-2.195h182.443c3.334 0 5.001 4.043 2.644 6.41l-36.04 36.208a7.46 7.46 0 0 1-5.288 2.195H140.334c-3.334 0-5.001-4.043-2.644-6.41l36.04-36.208ZM328.065 236.553a7.46 7.46 0 0 0-5.288-2.194H140.334c-3.334 0-5.001 4.042-2.644 6.41l36.04 36.208a7.46 7.46 0 0 0 5.288 2.194h182.443c3.334 0 5.001-4.042 2.644-6.41l-36.04-36.208Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M250 430c99.411 0 180-80.589 180-180S349.411 70 250 70 70 150.589 70 250s80.589 180 180 180Zm0 18c109.352 0 198-88.648 198-198S359.352 52 250 52 52 140.648 52 250s88.648 198 198 198Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="jito_sol_svg__a" x1={115} y1={61} x2={425.5} y2={371.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#7DD18A" />
        <stop offset={1} stopColor="#3B9A7D" />
      </linearGradient>
    </defs>
  </svg>
);
