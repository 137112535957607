export const TERMS =
  "By connecting a wallet, you acknowledge that you've read and that you agree to Kamino’s Terms of Service (https://app.kamino.finance/terms):\n" +
  '\n' +
  'You are not a person or representing a legal entity who resides in, is a citizen of, or is incorporated in, or has a registered office in the United States of America or any Prohibited Localities, as defined in the Terms and Conditions\n' +
  'You will not in the future access this site or use the Kamino dApp while located within the United States of America or any Prohibited Localities, as defined in the Terms and Conditions\n' +
  'You are not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.\n' +
  'You are lawfully permitted to access this site and use the Kamino dApp under the laws of the jurisdiction in which you reside and am located\n' +
  'You understand the risks associated the use of the Kamino Protocol, including the potential loss of funds\n' +
  'You acknowledge and agree that the access to the functionalities of the Solana blockchain described on the Site requires additional software of third-party providers (such as an appropriate wallet) and that the Site is merely one of many sites that provides a means to access and interact with the Solana blockchain.\n' +
  'You acknowledge and agree that the Site Operator is not responsible for and has no control over any of my interactions with the Solana blockchain or any of its functionalities, and that the Site Operator has no custody over your funds, ability or duty to transact on your behalf or power to reverse your transactions. The Site Operator does not endorse or provide any warranty with respect to any tokens.\n' +
  'You understand that the interaction with the functionalities of the Solana blockchain involves many risks, including full loss of funds and regulatory sanctions in certain jurisdictions. Youacknowledge and agree that it is your sole responsibility to thoroughly research and understand such risks.';
