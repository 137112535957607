import { useMemo } from 'react';
import { Web3Client } from '../services/web3/client';
import { getChain } from '../../providers/utils';

export const useWeb3Client = () => {
  const chain = getChain();
  const web3client = useMemo(() => (chain ? new Web3Client(chain) : undefined), [chain]);

  return {
    web3client,
  };
};
