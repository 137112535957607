import { FC } from 'react';
import DropdownBase from 'rc-dropdown';
import { withStyles } from '@bruitt/classnames';

import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import { Card } from '../Card';

import s from './Dropdown.module.less';

const sx = withStyles(s);

interface Props extends Omit<DropdownProps, 'visible'> {
  isVisible?: boolean;
}

export const Dropdown: FC<Props> = ({
  overlay,
  overlayClassName,
  placement = 'bottom',
  trigger = 'click',
  isVisible,
  children,
  ...rest
}) => {
  return (
    <DropdownBase
      placement={placement}
      trigger={trigger}
      overlay={<Card className={sx('panel')}>{overlay}</Card>}
      overlayClassName={sx(overlayClassName)}
      visible={isVisible}
      {...rest}
    >
      {children}
    </DropdownBase>
  );
};
