import { useMemo } from 'react';
import { startCase } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ObligationTypeTag } from '@kamino-finance/klend-sdk';

import { UseUserObligationsTransactionsHistoryResponse } from '../../../shared/hooks/lending/queries/useObligationTransactionsHistoryQuery';
import { LendingTransaction } from '../types';
import useEnv from '../../../shared/hooks/useEnv';
import { formatNumber } from '../../../shared/utils';
import { formatDate } from '../../../pages/StrategyV2/utils';
import useTokens from '../../../shared/hooks/useTokens';
import { useValueTokenSwitch } from '../../ValueTokenSwitcherContext';
import { getMarketMetadata } from '../../../shared/utils/lending/getMarketMetadata';
import { useMapObligationAddressToType } from '../../../shared/hooks/lending/useMapObligationAddressToType';

const { formatShortName: fsn, format: f } = formatNumber;

interface UseObligationsTransactionsHistoryProps {
  userObligationsTransactions: UseUserObligationsTransactionsHistoryResponse[];
  sorted?: boolean;
}

export const useObligationsTransactionsHistory = ({
  userObligationsTransactions,
  sorted = true,
}: UseObligationsTransactionsHistoryProps) => {
  const { env } = useEnv();
  const { getToken } = useTokens();
  const { formatValueInToken } = useValueTokenSwitch();
  const { obligationAddressToTypeMap } = useMapObligationAddressToType();

  return useMemo(() => {
    const dataObligationMap: Map<string, LendingTransaction[]> = new Map();
    const dataObligationTagMap: Map<ObligationTypeTag, LendingTransaction[]> = new Map();
    const data: LendingTransaction[] = [];

    if (obligationAddressToTypeMap.size === 0) {
      return {
        data,
        dataObligationMap,
        dataObligationTagMap,
      };
    }

    for (const obligationTransaction of userObligationsTransactions) {
      const { marketAddress, data: txPerObligation } = obligationTransaction;
      const dataPerObligation: LendingTransaction[] = [];
      for (const [obligationAddress, transactions] of Object.entries(txPerObligation)) {
        for (const transaction of transactions) {
          const {
            // createdOn,
            // isLiquidationWithdrawal,
            // liquidityToken,
            liquidityTokenAmount,
            liquidityTokenMint,
            // liquidityTokenPrice,
            liquidityUsdValue,
            // solPrice,
            timestamp,
            transactionDisplayName,
            // transactionName,
            transactionSignature,
          } = transaction;

          if (transactionDisplayName === 'create') {
            // eslint-disable-next-line no-continue
            continue;
          }

          const tokenInfo = getToken(liquidityTokenMint);
          const tag = obligationAddressToTypeMap.get(obligationAddress)!;

          const item: LendingTransaction = {
            id: uuidv4(),
            date: formatDate(Number(timestamp)),
            usdValue: formatValueInToken(liquidityUsdValue),
            transactionViewHref: `https://solscan.io/tx/${transactionSignature}${
              !env || env === 'mainnet-beta' ? '' : `?cluster=${env}`
            }`,
            tokens: [tokenInfo.symbol],
            name: tokenInfo.symbol,
            transactionName: startCase(transactionDisplayName),
            transactions: [
              {
                token: tokenInfo.symbol,
                tokenMint: liquidityTokenMint,
                price: fsn(liquidityTokenAmount),
                tooltipAmount: f(liquidityTokenAmount),
              },
            ],
            timestamp,
            type: 'lending',
            lendingMetadata: {
              tag,
              marketMetadata: getMarketMetadata(marketAddress),
              marketAddress,
              obligationAddress,
            },
          };

          data.push(item);
          dataPerObligation.push(item);
          const dataPerObligationTag = dataObligationTagMap.get(tag) || [];
          dataPerObligationTag.push(item);
          dataObligationTagMap.set(tag, dataPerObligationTag);
          dataObligationMap.set(obligationAddress, dataPerObligation);
        }
      }
    }

    return {
      data: sorted ? data.sort((a, b) => b.timestamp - a.timestamp) : data,
      dataObligationMap,
      dataObligationTagMap,
    };
  }, [obligationAddressToTypeMap, sorted, userObligationsTransactions, getToken, formatValueInToken, env]);
};
