import { Tab } from '../types';

import { featureFlags } from '../../../shared/utils/featureFlags';
import { TextProps } from '../../../shared/uiKitV2/Typography/Typography';

export const PIE_GRAPH_COLOR_SET = [
  '#49afe9',
  '#4F84FF',
  '#5650FF',
  '#9050FF',
  '#E850FF',
  '#FF5067',
  '#68EBBC',
  '#3A3DFF',
  '#3C04E8',
  '#A603FF',
  '#FA03FF',
  '#FF0371',
  '#E6F9EB',
  '#72B2FF',
  '#00A2E8',
  '#0060FF',
  '#4D00FF',
  '#A200FF',
  '#FE00FF',
  '#FF0054',
  '#AEFFCA',
];

export const TABS: Tab[] = [{ key: 'positions', title: 'My Positions' }];

if (featureFlags.isLendingPageEnabled) {
  TABS.push({
    key: 'kamino-rewards',
    title: 'Kamino Farms',
  });
}

if (featureFlags.isMyRewardsEnabled) {
  TABS.push({ key: 'rewards', title: 'My Rewards' });
}

if (featureFlags.isTransactionHistoryEnabled) {
  TABS.push({
    key: 'history',
    title: 'Transaction History',
    tooltip: 'Transaction history starts from November 15th 2022',
  });
}

export const TRANSACTIONS_TYPE: { [key: string]: string } = {
  deposit: 'Deposit',
  depositAndInvest: 'Deposit',
  withdraw: 'Withdraw',
};

export const DEFAULT_TEXT_PROPS: Partial<TextProps> = {
  fs: 18,
  lh: 22,
  tabletFs: 20,
  tabletLh: 26,
  desktopFs: 14,
  desktopLh: 18,
};
