import { PublicKeyAddress } from '../../types/strategies';
import paths from '../../routes/paths';

export const buildDashboardUrl = ({
  obligationAddress,
  marketAddress,
}: {
  obligationAddress: PublicKeyAddress;
  marketAddress: string;
}) => {
  return paths.LENDING.LENDING_DASHBOARD.replace(':obligationAddress', obligationAddress.toString()).replace(
    ':marketAddress',
    marketAddress
  );
};

export const buildReserveUrl = ({
  reserveAddress,
  marketAddress,
}: {
  reserveAddress: PublicKeyAddress;
  marketAddress: PublicKeyAddress;
}) => {
  return paths.LENDING.LENDING_RESERVE.replace(':reserveAddress', reserveAddress.toString()).replace(
    ':marketAddress',
    marketAddress.toString()
  );
};

export const buildMultiplyVaultUrl = ({
  depositReserveAddress,
  borrowReserveAddress,
  marketAddress,
}: {
  depositReserveAddress: PublicKeyAddress;
  borrowReserveAddress: PublicKeyAddress;
  marketAddress: string;
}) => {
  // const route = featureFlags.isJlpMultiplyEnabled
  //   ? paths.LENDING.LENDING_MULTIPLY_VAULT
  //   : paths.LENDING.LENDING_MULTIPLY_VAULT_SINGLE;

  // always use long route to support NON-SOL borrow token for multiply
  const route = paths.LENDING.LENDING_MULTIPLY_VAULT;
  return route
    .replace(':depositReserveAddress', depositReserveAddress.toString())
    .replace(':borrowReserveAddress', borrowReserveAddress.toString())
    .replace(':marketAddress', marketAddress);
};

export const buildLeverageVaultUrl = ({
  depositReserveAddress,
  borrowReserveAddress,
  marketAddress,
}: {
  depositReserveAddress: PublicKeyAddress;
  borrowReserveAddress: PublicKeyAddress;
  marketAddress: string;
}) => {
  return paths.LENDING.LENDING_LEVERAGE_VAULT.replace(':marketAddress', marketAddress)
    .replace(':depositReserveAddress', depositReserveAddress.toString())
    .replace(':borrowReserveAddress', borrowReserveAddress.toString())
    .replace(':marketAddress', marketAddress.toString());
};

export const buildLeverageDashboardUrl = ({
  obligationAddress,
  marketAddress,
}: {
  obligationAddress: PublicKeyAddress;
  marketAddress: string;
}) => {
  return paths.LENDING.LENDING_DASHBOARD_LEVERAGE.replace(':marketAddress', marketAddress).replace(
    ':obligationAddress',
    obligationAddress.toString()
  );
};

export const buildObligationDashboardLink = (obligationAddress: PublicKeyAddress) => {
  return paths.LENDING.LENDING_OBLIGATION.replace(':obligationAddress', obligationAddress.toString());
};
