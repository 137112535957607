import axios from 'axios';
import { ObligationPnlResponse } from '../../types/lending';
import { PublicKeyAddress } from '../../types/strategies';
import { KAMINO_LENDING_API_V2 } from './constants';

export const fetchObligationPnl = ({
  marketAddress,
  obligationAddress,
  pnlMode = 'current_obligation',
}: {
  marketAddress: PublicKeyAddress;
  obligationAddress: PublicKeyAddress;
  pnlMode?: 'current_obligation' | 'obligation_all_time';
}): Promise<ObligationPnlResponse> => {
  const url = `${KAMINO_LENDING_API_V2}/${marketAddress.toString()}/obligations/${obligationAddress.toString()}/pnl/`;
  return axios.get(url, { params: { pnlMode } }).then((res) => res.data);
};

export const EMPTY_PNL: ObligationPnlResponse = {
  usd: '0',
  sol: '0',
  invested: {
    usd: '0',
    sol: '0',
  },
};
