const LIQUIDITY_BASE = 'liquidity';
const LENDING_BASE = 'lending';
const MULTIPLY_BASE = `${LENDING_BASE}:multiply`;
const LEVERAGE_BASE = `${LENDING_BASE}:leverage`;
const BORROWLEND_BASE = `${LENDING_BASE}:borrowlend`;
const LENDING_DASHBOARD_BASE = `${LENDING_BASE}:dashboard`;
const EARN_BASE = 'earn';
const POINTS_BASE = 'points';
const SIDEPANEL_BASE = 'sidepanel';
const LIMIT_ORDER_BASE = 'sidepanel';

export const MIXPANEL_PREFIX = {
  LIQUIDITY: {
    LIST: `${LIQUIDITY_BASE}:list`,
    VAULT: `${LIQUIDITY_BASE}:vault`,
  },
  LENDING: {
    BORROWLEND: {
      BASE: BORROWLEND_BASE,
      LIST: `${BORROWLEND_BASE}:list`,
      VAULT: `${BORROWLEND_BASE}:vault`,
    },
    MULTIPLY: {
      BASE: MULTIPLY_BASE,
      LIST: `${MULTIPLY_BASE}:list`,
      VAULT: `${MULTIPLY_BASE}:vault`,
    },
    EARN: {
      BASE: EARN_BASE,
    },
    LEVERAGE: {
      BASE: LEVERAGE_BASE,
      LIST: `${LEVERAGE_BASE}:list`,
      VAULT: `${LEVERAGE_BASE}:vault`,
    },
    DASHBOARD: {
      BASE: LENDING_DASHBOARD_BASE,
    },
  },
  EARN: {
    BASE: EARN_BASE,
    LIST: `${EARN_BASE}:list`,
    VAULT: `${EARN_BASE}:vault`,
  },
  POINTS: {
    BASE: POINTS_BASE,
  },
  SIDEPANEL: {
    BASE: SIDEPANEL_BASE,
  },
  LIMIT_ORDER: {
    BASE: LIMIT_ORDER_BASE,
  },
};
