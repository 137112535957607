import { ReactElement, SVGProps } from 'react';

export const SvgMetamask = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g strokeWidth={0.303} strokeLinecap="round" strokeLinejoin="round">
      <path d="m19.062 1-7.426 5.626 1.385-3.317L19.06 1Z" fill="#E17726" stroke="#E17726" />
      <path
        d="m1.938 1 7.358 5.672L7.98 3.315 1.94 1Zm14.45 13.04-1.977 3.09 4.233 1.193 1.21-4.214-3.465-.07Zm-15.235.07 1.203 4.213 4.222-1.192L4.61 14.04l-3.458.07Z"
        fill="#E27625"
        stroke="#E27625"
      />
      <path
        d="m6.352 8.82-1.176 1.817 4.182.196-.135-4.613-2.871 2.605v-.006Zm8.296.005-2.916-2.662-.096 4.67 4.182-.196-1.17-1.812Zm-8.07 8.306 2.537-1.25-2.181-1.737-.356 2.987Zm5.312-1.257 2.52 1.257-.338-2.993-2.182 1.736Z"
        fill="#E27625"
        stroke="#E27625"
      />
      <path
        d="m14.41 17.13-2.52-1.25.204 1.679-.023.712 2.34-1.14Zm-7.832 0 2.35 1.14-.016-.711.203-1.679-2.537 1.25Z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
      />
      <path
        d="m8.968 13.027-2.09-.625 1.48-.695.616 1.32h-.006Zm3.058 0 .622-1.325 1.486.694-2.108.637v-.006Z"
        fill="#233447"
        stroke="#233447"
      />
      <path
        d="m6.578 17.13.367-3.09-2.334.07 1.967 3.02Zm7.477-3.09.356 3.09 1.978-3.02-2.334-.07Zm1.769-3.403-4.182.197.384 2.199.622-1.331 1.486.694 1.69-1.76Zm-8.947 1.765 1.481-.694.616 1.32.39-2.2-4.182-.191 1.695 1.765Z"
        fill="#CC6228"
        stroke="#CC6228"
      />
      <path
        d="m5.182 10.637 1.752 3.501-.057-1.736-1.695-1.765Zm8.952 1.765-.068 1.736 1.752-3.501-1.684 1.765Zm-4.77-1.568-.396 2.199.498 2.593.113-3.42-.215-1.372Zm2.272 0-.204 1.365.102 3.427.492-2.599-.39-2.2v.007Z"
        fill="#E27525"
        stroke="#E27525"
      />
      <path
        d="m12.026 13.027-.492 2.599.356.254 2.176-1.736.068-1.742-2.108.625Zm-5.149-.625.057 1.736 2.181 1.736.35-.248-.49-2.6-2.103-.624h.005Z"
        fill="#F5841F"
        stroke="#F5841F"
      />
      <path
        d="m12.077 18.27.017-.711-.192-.162H9.098l-.186.162.017.712-2.351-1.14.825.694L9.07 19h2.854l1.673-1.18.814-.69-2.34 1.14h.006Z"
        fill="#C0AC9D"
        stroke="#C0AC9D"
      />
      <path
        d="m11.89 15.875-.356-.25H9.466l-.35.255-.204 1.679.186-.162h2.804l.192.162-.204-1.679v-.005Z"
        fill="#161616"
        stroke="#161616"
      />
      <path
        d="M19.378 6.99 20 3.894 19.062 1l-7.177 5.44 2.763 2.38 3.9 1.163.859-1.025-.373-.278.593-.555-.452-.36.593-.462-.395-.313h.005ZM1 3.888l.633 3.102-.407.307.605.463-.452.365.587.555-.373.278.86 1.025 3.899-1.158 2.763-2.39L1.938 1 1 3.888Z"
        fill="#763E1A"
        stroke="#763E1A"
      />
      <path
        d="m18.548 9.983-3.9-1.158 1.176 1.812-1.752 3.501 2.317-.029h3.464l-1.305-4.126ZM6.352 8.819l-3.9 1.164-1.3 4.126h3.465l2.317.03-1.752-3.502 1.176-1.818h-.006Zm5.284 2.014.254-4.398 1.13-3.126H7.98l1.13 3.126.254 4.399.096 1.377v3.415h2.074l.011-3.415.09-1.377Z"
        fill="#F5841F"
        stroke="#F5841F"
      />
    </g>
  </svg>
);
