import { FC, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Text } from '../../../uiKitV2/Typography';
import { Link } from '../../../uiKitV2/Link';
import paths from '../../../routes/paths';
import { FOOTER_LOGOS_V2 } from '../../AppFooter/types';
import { Banner } from '../Banner';
import s from './RestrictedAccessBanner.module.less';

export const RectrictedAccesssBanner: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleTermsClick = () => {
    history.push(paths.TERMS);
  };
  const discordLink = FOOTER_LOGOS_V2.find((item) => item.alt === 'discord')?.link;

  return (
    <Banner localStorageKey="kamino.isGeoBlockingWarningClosed">
      <Text color="grey-light" className={isOpen ? s.bannerTextOpened : s.bannerText}>
        Kamino Finance is not available in your region. Based on your location being a current Restricted Jurisdiction,
        you are prohibited from accessing or using the Kamino Finance app, platform or smart contracts due to local laws
        and regulations, as per our <Link onClick={handleTermsClick}> Terms and Conditions</Link>. For more information,
        please contact us on{' '}
        <Link href={discordLink} target="_blank">
          Discord
        </Link>
        .
      </Text>
      {!isOpen && (
        <Link className={s.readMoreButton} onClick={() => setIsOpen(true)}>
          Read more
        </Link>
      )}
    </Banner>
  );
};
