import { ReactElement, SVGProps } from 'react';

export const SvgBolt = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.217 7.716 8.16 13.173l.3-3.069.054-.548H4.56c.035-.068.083-.153.15-.272l3.056-5.457-.3 3.069-.054.548h3.954a11.43 11.43 0 0 1-.15.272Z"
      fill="#98B0C1"
      stroke="#98B0C1"
    />
  </svg>
);
