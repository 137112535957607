import { ReactElement, SVGProps } from 'react';

export const SvgWarningInfo = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18ZM12 11v5" />
      <path d="M11.95 8h.1v.1h-.1V8Z" />
    </g>
  </svg>
);
