import { FC, useState } from 'react';
import { Wallet } from '@solana/wallet-adapter-react';
import c from 'classnames';
import { WalletName } from '@solana/wallet-adapter-base';
import s from './WalletsList.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { addPredefinedWallets, noop } from '../../../../../../shared/utils';
import { captureError } from '../../../../../../shared/utils/captureError';
import { sortByMostPopularWallet } from '../../../../../../shared/utils/kamino/sortByMostPopularWallet';
import { Icon } from '../../../../../../shared/uiKitV2/Icon';
import { Divider } from '../../../../../../shared/uiKitV2/Divider';
import { useLastSelectedWallet } from '../../hooks/useLastSelectedWallet';
import { Tag } from '../../../../../../shared/uiKitV2/Tag';
import { GOOGLE_TIPLINK_WALLET } from '../../../../../../shared/components/WalletModal/constants';
import googleTiplinkIcon from '../../../../../../assetsV2/icons/google.svg';

type WalletsListProps = {
  onOnboarding: () => void;
  onSelect: (wallet: Wallet) => Promise<void>;
  wallets: Wallet[];
  selectedWallet: Wallet | null;
};

type WalletItemProps = {
  wallet: Wallet;
  onSelect: (wallet: Wallet) => Promise<void>;
  isActive: boolean;
  isRecent: boolean;
};
const WalletItem: FC<WalletItemProps> = ({ wallet, onSelect, isActive, isRecent }) => {
  let walletName = wallet.adapter.name;
  let walletIcon = wallet.adapter.icon;

  if (walletName === GOOGLE_TIPLINK_WALLET.adapter.name) {
    walletName = 'Google Login' as WalletName<string>;
    walletIcon = googleTiplinkIcon;
  }
  if (walletName === 'Sign in with Google') {
    walletName = 'Google Login (Powered by Moongate)' as WalletName<string>;
  }
  if (walletName === 'Sign in with Apple') {
    walletName = 'Apple Login (Powered by Moongate)' as WalletName<string>;
  }
  return (
    <div
      key={walletName}
      className={c({ [s.activeOption]: isActive })}
      onClick={() => {
        onSelect(wallet).then(noop).catch(captureError);
      }}
    >
      <div className={s.iconContainer}>
        <div className={s.walletLeft}>
          <img className={s.walletIcon} src={walletIcon} alt={walletName} />
          <Text className={s.walletName}>{walletName}</Text>
        </div>
        {isRecent && <Tag label="Recent" variant="blue" />}
      </div>
    </div>
  );
};
export const WalletsList: FC<WalletsListProps> = ({ onOnboarding, onSelect, wallets, selectedWallet }) => {
  const [isShowAllWallets, setIsShowAllWallets] = useState(false);
  const { getLastSelectedWalletName } = useLastSelectedWallet();
  const selectedWalletName = selectedWallet?.adapter.name;
  const lastSelectedWalletName = getLastSelectedWalletName();

  const walletsWithPredefinedWallets = addPredefinedWallets(wallets);

  const sortedWallets = sortByMostPopularWallet(walletsWithPredefinedWallets);

  return (
    <div className={s.content}>
      <Text fs={22} lh={32}>
        Connect Wallet
      </Text>

      <div className={s.wallets}>
        <div className={s.list}>
          {(isShowAllWallets ? sortedWallets : sortedWallets.slice(0, 5)).map((option) => (
            <WalletItem
              key={option.adapter.name}
              wallet={option}
              onSelect={onSelect}
              isActive={selectedWalletName === option.adapter.name}
              isRecent={option.adapter.name === lastSelectedWalletName}
            />
          ))}
          <div className={s.allWallets} onClick={() => setIsShowAllWallets((prev) => !prev)}>
            {isShowAllWallets ? (
              <Icon size={24} name="ChevronSmallUp" color="blue" />
            ) : (
              <Icon size={24} color="blue" name="ChevronSmallDown" />
            )}
            <Text color="blue">All Wallets</Text>
          </div>
        </div>
        <Divider className={s.divider} />
        <div className={s.iconContainer} onClick={onOnboarding}>
          <div className={s.walletLeft}>
            <Icon name="Sparkles" size={24} />
            <Text className={s.walletName}>I don’t have a Solana Wallet</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
