const tokenErrorRe = /Program log: Price is too old token=(\w+)_/;

export const getTokenPriceTooOld = (logs?: string[]): string | null => {
  let firstMatch: RegExpExecArray | null = null;

  if (!logs) {
    return null;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const logLine of logs) {
    firstMatch = tokenErrorRe.exec(logLine);
    if (firstMatch !== null) {
      break;
    }
  }

  if (!firstMatch) {
    return null;
  }

  const [token] = firstMatch.slice(1);
  return token || null;
};
