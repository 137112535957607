import { subDays, subMonths } from 'date-fns';
import { PeriodNameConst } from '../types/graphs';

export const PERIOD: PeriodNameConst = {
  month: 'month',
  quarter: 'quarter',
  year: 'year',
};

export type PeriodName = 'year' | 'month' | 'quarter';

export type PeriodFormat = 'day' | 'month' | 'all';
type PeriodFormatConst = Record<string, PeriodFormat>;
export const PERIOD_FORMAT: PeriodFormatConst = {
  day: 'day',
  month: 'month',
};

export const HOUR = 1000 * 60 * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const QUARTER = MONTH * 3;
export const YEAR = MONTH * 12;

export const POINTS_FOR_HOUR = 12;
export const POINTS_FOR_DAY = 12;
export const POINTS_FOR_MONTH = 12;

export const TOOLTIP_WARNING_TITLE = 'Only 2 types of data can be selected at the same time';

export const CHART_DAYS_PRESETS = [
  { label: '7 Days', shortLabel: '7D', date: subDays(new Date(), 7) },
  { label: '30 Days', shortLabel: '30D', date: subDays(new Date(), 30) },
  { label: '90 Days', shortLabel: '90D', date: subDays(new Date(), 90) },
  { label: '6 Months', shortLabel: '6M', date: subMonths(new Date(), 6) },
];

export const CHART_HEIGHT = 310;
