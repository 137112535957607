import { makeAutoObservable } from 'mobx';

const store = makeAutoObservable({
  tokenMint: 'usd',

  setTokenMint: (tokenMint: string) => {
    store.tokenMint = tokenMint;
  },
});

export { store as valueTokenSwitcherStore };
