import {
  USDC_MINT,
  BSOL_MINT,
  SOL_MINT,
  JITOSOL_MINT,
  MSOL_MINT,
  USDT_MINT,
  ENT_MINT,
  WBTC_MINT,
  TBTC_MINT,
} from '../mints';

export const SHORT_LEVERAGE_PAIRS = [
  // Collateral USDC
  [USDC_MINT, SOL_MINT],
  [USDC_MINT, BSOL_MINT],
  [USDC_MINT, JITOSOL_MINT],
  [USDC_MINT, MSOL_MINT],
  // [USDC_MINT, 'STSOL'],
  [USDC_MINT, USDT_MINT],
  [USDC_MINT, WBTC_MINT],
  // [USDC_MINT, 'WSTETH'],
  [USDC_MINT, ENT_MINT],

  // Collateral USDH
  // ['USDH', SOL_MINT],
  // ['USDH', BSOL_MINT],
  // ['USDH', JITOSOL_MINT],
  // ['USDH', MSOL_MINT],
  // ['USDH', 'STSOL'],
  // ['USDH', USDT_MINT],
];

export const LONG_LEVERAGE_PAIRS = [
  // Borrow USDC
  [SOL_MINT, USDC_MINT],
  [BSOL_MINT, USDC_MINT],
  [JITOSOL_MINT, USDC_MINT],
  [MSOL_MINT, USDC_MINT],
  // ['STSOL', USDC_MINT],
  [USDT_MINT, USDC_MINT],
  [WBTC_MINT, USDC_MINT],
  // ['WSTETH', USDC_MINT],
  [ENT_MINT, USDC_MINT],

  // Borrow USDH
  // [SOL_MINT, 'USDH'],
  // [BSOL_MINT, 'USDH'],
  // [JITOSOL_MINT, 'USDH'],
  // [MSOL_MINT, 'USDH'],
  // ['STSOL', 'USDH'],
  // [USDT_MINT, 'USDH'],
];

// TODO: v3, remove v3 part in naming after release
// In v3 leverage pairs are just the long pairs and short pairs are the inverse
export const LEVERAGE_PAIRS_V3 = [...LONG_LEVERAGE_PAIRS, ...SHORT_LEVERAGE_PAIRS];

export const DEPRECATED_SHORT_LEVERAGE_PAIRS = [[USDC_MINT, TBTC_MINT]];
export const DEPRECATED_LONG_LEVERAGE_PAIRS = [[TBTC_MINT, USDC_MINT]];
