import paths from '../routes/paths';
import { featureFlags } from '../utils';
import { IconName } from '../uiKitV2/Icon';

// import { LINKS } from './links';

export interface CommonProps {
  id: number;
  label: string;
  description?: string;
  isShownInLiquiditySelector?: boolean;
  activePaths?: string[];
  hideOnTablet?: boolean;
  isBetaOption?: boolean;
  icon?: IconName;
  isHidden?: boolean;
}

interface MenuItemNavParams extends CommonProps {
  path: string;
  link?: never;
  isDisabled?: boolean;
}

interface MenuItemLinkParams extends CommonProps {
  path?: never;
  link?: string;
  isDisabled?: boolean;
}

export interface MenuItemWithSubmenuParams extends CommonProps {
  path?: never;
  link?: never;
  isDisabled?: boolean;
  submenu: (MenuItemNavParams | MenuItemLinkParams)[];
}

export type MenuItemParams = (MenuItemNavParams | MenuItemLinkParams | MenuItemWithSubmenuParams) & {
  isNewOption?: boolean;
};

const GENERAL_ITEMS = [
  {
    id: 1,
    label: 'Borrow / Lend',
    path: paths.MAIN,
    description: 'Supply assets to earn yield and borrow against collateral',
    isShownInLiquiditySelector: true,
    activePaths: [paths.MAIN, paths.LENDING.BASE, paths.LENDING.LENDING_RESERVE],
  },
  {
    id: 2,
    label: 'Liquidity',
    path: paths.LIQUIDITY,
    description: 'Earn automated fees & rewards by deploying assets into concentrated liquidity DEXs',
    isShownInLiquiditySelector: true,
    activePaths: [paths.LIQUIDITY, paths.STRATEGY_FULL, paths.STRATEGY_SHORT],
  },
  {
    id: 3,
    label: 'Multiply',
    path: paths.LENDING.LENDING_MULTIPLY,
    description: 'Boost your SOL staking yield up to 10x with a single click',
    isShownInLiquiditySelector: featureFlags.isLendingMultiplyEnabled,
    isNewOption: false,
    activePaths: [
      paths.LENDING.LENDING_MULTIPLY,
      paths.LENDING.LENDING_MULTIPLY_VAULT,
      paths.LENDING.LENDING_MULTIPLY_VAULT_SINGLE,
    ],
  },
  {
    id: 4,
    label: 'Long / Short',
    path: paths.LENDING.LENDING_LEVERAGE,
    description: 'Increase your exposure to any crypto asset with one-click leverage',
    isShownInLiquiditySelector: featureFlags.isLendingLeverageEnabled,
    activePaths: [paths.LENDING.LENDING_LEVERAGE, paths.LENDING.LENDING_LEVERAGE_VAULT],
  },
  {
    id: 5,
    label: 'Earn',
    path: paths.EARN,
    description: 'Long-term yield strategies with single asset exposure and no liquidation risk',
    isShownInLiquiditySelector: featureFlags.isEarnEnabled,
    activePaths: [paths.EARN, paths.LENDING.LENDING_VAULT],
  },
  {
    id: 6,
    label: 'Portfolio',
    path: paths.PORTFOLIO,
    activePaths: [paths.PORTFOLIO],
    hideOnTablet: true,
  },
];

const submenusWithGovernanceEnabled: (MenuItemNavParams | MenuItemLinkParams)[] = [
  {
    id: 1,
    label: 'Governance & Staking',
    description: 'Stake your KMNO to vote on proposals, and boost your points',
    path: paths.GOVERNANACE_AND_STAKING,
    activePaths: [paths.GOVERNANACE_AND_STAKING],
    icon: 'KmnoGenesis',
  },
  {
    id: 2,
    label: 'KMNO Season 2 Allocation',
    path: paths.S2AIRDROP,
    activePaths: [paths.S2AIRDROP],
    description: 'View/claim your Season 2 KMNO allocation',
    icon: 'KmnoStake',
  },
];

const submenusWithGovernanceNotEnabled: (MenuItemNavParams | MenuItemLinkParams)[] = [
  {
    id: 1,
    label: 'KMNO Season 2 Allocation',
    description: 'View/claim your Season 2 KMNO allocation',
    path: paths.S2AIRDROP,
    activePaths: [paths.S2AIRDROP],
    icon: 'KmnoGenesis',
  },
  {
    id: 2,
    label: 'Stake KMNO',
    path: paths.STAKE,
    activePaths: [paths.STAKE],
    description: 'Stake your KMNO to accumulate points boosts',
    icon: 'KmnoStake',
    isDisabled: !featureFlags.isKmnoClaimEnabled,
  },
];

export const MENU_ITEMS: MenuItemParams[] = [
  ...GENERAL_ITEMS,
  {
    id: 7,
    label: 'KMNO',
    icon: 'KmnoLogo',
    submenu: [
      ...GENERAL_ITEMS.filter((item) => item.hideOnTablet),
      ...(featureFlags.isGovernanceEmptyStateEnabled
        ? submenusWithGovernanceEnabled
        : submenusWithGovernanceNotEnabled),
    ],
  },
  // {
  //     id: 7,
  //     label: 'More',
  //     activePaths: [paths.PORTFOLIO, paths.ADVANCED_ANALYTICS_FULL, paths.REFERRAL],
  //     submenu: [
  //       ...GENERAL_ITEMS.filter((item) => item.hideOnTablet),
  //       {
  //         id: 1,
  //         label: 'Referral',
  //         path: paths.REFERRAL,
  //         activePaths: [paths.REFERRAL],
  //         isHidden: !featureFlags.isReferralPageEnabled,
  //       },
  //       {
  //         id: 2,
  //         label: 'Liquidity Analytics',
  //         path: paths.ADVANCED_ANALYTICS_SHORT,
  //         activePaths: [paths.ADVANCED_ANALYTICS_FULL],
  //         icon: 'NoteSearch',
  //       },
  //       {
  //         id: 3,
  //         label: 'Leaderboards (Soon™)',
  //         icon: 'Leaderboards',
  //         isDisabled: true,
  //       },
  //       {
  //         id: 3,
  //         label: 'Risk Dashboard',
  //         link: LINKS.riskDashboard,
  //         icon: 'RiskDashboard',
  //       },
  //       {
  //         id: 4,
  //         label: 'Documentation',
  //         link: LINKS.kaminoDocs,
  //         icon: 'Documentation',
  //       },
  //       {
  //         id: 5,
  //         label: 'Github',
  //         link: LINKS.github,
  //         icon: 'Github',
  //       },
  //       {
  //         id: 7,
  //         label: 'Risk Framework',
  //         link: LINKS.riskFramework,
  //         icon: 'RiskFramework',
  //       },
  //       {
  //         id: 8,
  //         label: 'Litepaper',
  //         link: LINKS.litepaper,
  //         icon: 'Litepaper',
  //       },
  //       {
  //         id: 9,
  //         label: 'Buy Cover',
  //         link: LINKS.buyCover,
  //         icon: 'BuyCover',
  //       },
  //     ],
  //   },
];
