let mainWorkerInstance: Worker | null = null;

export const getMainWorker = (): Worker => {
  if (!mainWorkerInstance) {
    mainWorkerInstance = new Worker(new URL('../main', import.meta.url), { type: 'module' });
  }
  return mainWorkerInstance;
};

// ---------------------------------------------------------------------------

let dataWorkerInstance: Worker | null = null;

export const getDataWorker = (): Worker => {
  if (!dataWorkerInstance) {
    dataWorkerInstance = new Worker(new URL('../data', import.meta.url), { type: 'module' });
  }
  return dataWorkerInstance;
};
// ---------------------------------------------------------------------------

let formStatsWorkerInstance: Worker | null = null;

export const getFormStatsWorker = (): Worker => {
  if (!formStatsWorkerInstance) {
    formStatsWorkerInstance = new Worker(new URL('../formStats', import.meta.url), { type: 'module' });
  }
  return formStatsWorkerInstance;
};

// ---------------------------------------------------------------------------

let leverageWorkerInstance: Worker | null = null;

export const getLeverageWorker = (): Worker => {
  if (!leverageWorkerInstance) {
    leverageWorkerInstance = new Worker(new URL('../leverage', import.meta.url), { type: 'module' });
  }
  return leverageWorkerInstance;
};

// ---------------------------------------------------------------------------

let testWorkerInstance: Worker | null = null;

export const getTestWorker = (): Worker => {
  if (!testWorkerInstance) {
    testWorkerInstance = new Worker(new URL('../testWorker', import.meta.url), { type: 'module' });
  }
  return testWorkerInstance;
};
