import React, { CSSProperties } from 'react';
import { Text } from '../../uiKitV2/Typography';
import styles from './PageTitle.module.less';

interface PageTitleProps {
  title: string;
  style?: CSSProperties;
}

function PageTitle({ title, style }: PageTitleProps) {
  return (
    <div className={styles.pageTitleContainer} style={style}>
      <Text fs={32} weight="bold" className={styles.pageTitle}>
        {title}
      </Text>
    </div>
  );
}

export default PageTitle;
