import { ComputeBudgetProgram, PublicKey, Transaction, TransactionInstruction } from '@solana/web3.js';
import Decimal from 'decimal.js';

export const getComputeBudgetAndPriorityFeeIxns = (
  owner: PublicKey,
  units: number,
  priorityFeeLamports?: Decimal
): TransactionInstruction[] => {
  const ixns = [];
  ixns.push(ComputeBudgetProgram.setComputeUnitLimit({ units }));

  if (priorityFeeLamports && priorityFeeLamports.gt(0)) {
    const unitPrice = priorityFeeLamports.mul(10 ** 6).div(units);
    ixns.push(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: BigInt(unitPrice.floor().toString()) }));
  }

  return ixns;
};

export const getPriorityFeeIxn = (
  owner: PublicKey,
  units: number,
  priorityFeeLamports?: Decimal
): TransactionInstruction[] => {
  const ixns = [];
  if (priorityFeeLamports && priorityFeeLamports.gt(0)) {
    const unitPrice = priorityFeeLamports.mul(10 ** 6).div(units);
    ixns.push(ComputeBudgetProgram.setComputeUnitPrice({ microLamports: BigInt(unitPrice.floor().toString()) }));
  }

  return ixns;
};

export const addComputeBudgetAndPriorityFeeIxns = ({
  owner,
  tx,
  units,
  priorityFeeLamports,
}: {
  owner: PublicKey;
  tx: Transaction | undefined;
  units?: number;
  priorityFeeLamports?: Decimal;
}) => {
  if (!tx || (!units && !priorityFeeLamports) || (priorityFeeLamports && priorityFeeLamports.equals(0))) {
    return tx;
  }
  const ixns = getComputeBudgetAndPriorityFeeIxns(owner, units || 200000, priorityFeeLamports);
  tx.instructions.unshift(...ixns);

  return tx;
};

export const addPriorityFeeIxn = ({
  owner,
  tx,
  units,
  priorityFeeLamports,
}: {
  owner: PublicKey;
  tx: Transaction | undefined;
  units?: number;
  priorityFeeLamports?: Decimal;
}) => {
  if (!tx || (!units && !priorityFeeLamports) || (priorityFeeLamports && priorityFeeLamports.equals(0))) {
    return tx;
  }
  const ixns = getPriorityFeeIxn(owner, units || 200000, priorityFeeLamports);
  tx.instructions.unshift(...ixns);

  return tx;
};
