import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { UserAndKey, UserState } from '@hubbleprotocol/farms-sdk';
import { PublicKeyAddress } from '../../../types/strategies';
import { useKaminoFarms } from '../../useKaminoFarms';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { captureError } from '../../../utils/captureError';
import { useWeb3Client } from '../../useWeb3Client';
import { isValidPubkey } from '../../../utils';
import { useWalletAddressFromParamsOrEnv } from '../../useWalletAddressFromParamsOrEnv';

const EMPTY_DATA: Record<string, UserState> = {};

export const useUserFarmPositionQuery = ({ farmAddress }: { farmAddress?: PublicKeyAddress }) => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { kaminoFarms } = useKaminoFarms();
  const { web3client } = useWeb3Client();

  const queryFn = async () => {
    if (!kaminoFarms) {
      throw new Error('Kamino is not loaded');
    }

    if (!web3client) {
      throw new Error('Web3client is not loaded');
    }

    if (!walletPublicKey) {
      throw new Error('walletPublicKey is not connected');
    }

    if (!isValidPubkey(farmAddress)) {
      return;
    }

    let userStateAndPubkey: UserAndKey;
    try {
      userStateAndPubkey = await kaminoFarms.getUserStateKeyForUndelegatedFarm(
        new PublicKey(walletPublicKey),
        new PublicKey(farmAddress)
      );
    } catch (err) {
      return {};
    }

    if (!userStateAndPubkey) {
      return {};
    }

    return { [userStateAndPubkey.key.toString()]: userStateAndPubkey.userState } as Record<string, UserState>;
  };

  const isEnabled =
    Boolean(walletPublicKey) && isValidPubkey(farmAddress) && Boolean(kaminoFarms) && Boolean(web3client);

  const {
    data: userFarmStates,
    isFetched,
    ...rest
  } = useQuery({
    queryKey: QUERYKEYS.getFarmUserStates(walletPublicKey || '', farmAddress || ''),
    queryFn,
    enabled: isEnabled,
    onError: (err: Error) => {
      captureError(err);
    },
  });

  return {
    data: userFarmStates || EMPTY_DATA,
    isFetched: isFetched || !isEnabled,
    ...rest,
  };
};
