import { withStyles } from '@bruitt/classnames';
import React, { ReactNode } from 'react';

import { BaseButton, BaseButtonProps } from '../BaseButton';

import s from './Button.module.less';
import { Spinner } from '../Spinner';

const sx = withStyles(s);

export type ButtonSize = 'large' | 'medium' | 'small';

function getSpinnerSize(size: ButtonSize) {
  if (size === 'large') {
    return 20;
  }
  if (size === 'medium') {
    return 18;
  }
  return 18;
}

export interface ButtonProps extends BaseButtonProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  isFullWidth?: boolean;
  size?: ButtonSize;
  upperCase?: boolean;
  noSidePadding?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    startIcon,
    endIcon,
    size = 'medium',
    isFullWidth,
    children,
    upperCase,
    noSidePadding,
    isLoading,
    loadingLabel,
    disabled,
    ...rest
  } = props;

  return (
    <BaseButton
      className={sx('root', className, { size, fullWidth: isFullWidth, upperCase, noSidePadding })}
      disabled={disabled || isLoading}
      {...rest}
      ref={ref}
    >
      {isLoading ? (
        <>
          <Spinner size={getSpinnerSize(size)} />
          {loadingLabel}
        </>
      ) : (
        <>
          {startIcon}
          {children}
          {endIcon}
        </>
      )}
    </BaseButton>
  );
});
