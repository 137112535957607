import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

interface QueryObject {
  [key: string]: any;
}

export const useUrlQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo((): QueryObject => {
    return qs.parse(location.search, { ignoreQueryPrefix: true }) as QueryObject;
  }, [location.search]);

  const setQueryFromObject = useCallback(
    (object: QueryObject) => {
      const newQuery: QueryObject = { ...query, ...object };

      Object.keys(newQuery).forEach((key) => {
        if (newQuery[key] === undefined || (typeof newQuery[key] === 'string' && !newQuery[key].length)) {
          delete newQuery[key];
        }
      });

      const newSearch = qs.stringify(newQuery);
      const currentSearchDecoded = decodeURIComponent(location.search.slice(1));

      if (currentSearchDecoded !== newSearch) {
        history.replace({
          pathname: location.pathname,
          search: newSearch,
        });
      }
    },
    [query, location.search, location.pathname, history]
  );

  return { query, setQueryFromObject };
};
