import { FC, useState } from 'react';

import { Text } from '../../../uiKitV2/Typography';
import { Banner } from '../Banner';
import { Link } from '../../../uiKitV2/Link';
import s from './UkBanner.module.less';

export const UkBanner: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Banner localStorageKey="kamino.isGeoBlockingWarningClosed">
        <Text color="grey-light" className={isOpen ? s.bannerTextOpened : s.bannerText}>
          ACCESS TO THIS WEBSITE IS NOT PERMITTED IN THE UK, OR BY USERS FROM THE UK. This web application is provided
          as a tool for users to interact with the Kamino Finance Protocol on their own initiative, with no endorsement
          or recommendation of cryptocurrency trading activities. In doing so, Kamino is not recommending that users or
          potential users engage in cryptoasset trading activity, and users or potential users of the web application
          should not regard this webpage or its contents as involving any form of recommendation, invitation or
          inducement to deal in cryptoassets.
        </Text>
        {!isOpen && (
          <Link className={s.readMoreButton} onClick={() => setIsOpen(true)}>
            Read more
          </Link>
        )}
      </Banner>
    </>
  );
};
