import { ReactElement, SVGProps } from 'react';

export const SvgChevronRightDouble = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13 8 4 4-4 4M7 8l4 4-4 4"
    />
  </svg>
);
