import { ReactElement, SVGProps } from 'react';

export const SvgEthena = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#ethena_svg__a)">
      <rect width={24} height={24} rx={12} fill="#000" />
      <g clipPath="url(#ethena_svg__b)">
        <path
          d="M12.006.217c6.478 0 11.729 5.264 11.729 11.758 0 6.495-5.251 11.76-11.729 11.76-6.478 0-11.729-5.265-11.729-11.76C.277 5.481 5.528.217 12.006.217Z"
          fill="#111"
          stroke="#111"
          strokeWidth={7.057}
        />
        <path
          d="M12.006 0C5.409 0 .06 5.361.06 11.975S5.41 23.952 12.006 23.952s11.945-5.362 11.945-11.975C23.95 5.36 18.603 0 12.006 0Z"
          fill="url(#ethena_svg__c)"
          fillOpacity={0.7}
        />
        <path
          d="M12.006.22c6.476 0 11.726 5.263 11.726 11.755 0 6.493-5.25 11.756-11.726 11.756C5.53 23.731.28 18.468.28 11.976.28 5.482 5.53.218 12.006.218Z"
          stroke="url(#ethena_svg__d)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.392 6.406h-.254l-.165.194-4.26 5.017-.305.358.304.358 4.26 5.017.166.194h8.475v-3.742h-1.102v2.637h-5.914l3.54-4.103.311-.36-.311-.362-3.54-4.103h5.914v2.637h1.102V6.406h-8.22Zm.003 1.4-3.54 4.17 3.54 4.167 3.596-4.168-3.596-4.168Z"
          fill="#fff"
        />
      </g>
    </g>
    <defs>
      <clipPath id="ethena_svg__a">
        <rect width={24} height={24} rx={12} fill="#fff" />
      </clipPath>
      <clipPath id="ethena_svg__b">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <radialGradient
        id="ethena_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 25.2492 -17.7276 0 12.031 3.497)"
      >
        <stop offset={0.031} stopColor="#3A3A3A" />
        <stop offset={1} stopColor="#1C1C1C" />
      </radialGradient>
      <linearGradient id="ethena_svg__d" x1={12.006} y1={-0.226} x2={12.006} y2={24.177} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#111" />
      </linearGradient>
    </defs>
  </svg>
);
