import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
} from 'date-fns';

/**
 * Returns a formatted string representing a duration since a date time.
 *
 * @param {Date | number} date - The date to compare to.
 * @returns {string} - The duration since the timestamp.
 */
export const getShortAge = (date: Date): string => {
  const now = new Date();

  const minutes = differenceInMinutes(now, date);
  if (minutes === 0) {
    return 'now';
  }
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = differenceInHours(now, date);
  if (hours < 24) {
    return `${Math.floor(hours)}h`;
  }

  const days = differenceInDays(now, date);
  if (days < 7) {
    return `${Math.floor(days)}d`;
  }

  const weeks = differenceInWeeks(now, date);
  if (days < 31) {
    return `${Math.floor(weeks)}w`;
  }

  const months = differenceInMonths(now, date);
  return `${Math.floor(months)}M`;
};
