import { useQuery } from '@tanstack/react-query';
import { KaminoStrategyWithShareMint } from '@kamino-finance/kliquidity-sdk';
import { PublicKey } from '@solana/web3.js';
import { PubkeyHashMap } from '@kamino-finance/kliquidity-sdk/dist/utils/pubkey';
// import { PubkeyHashMap } from '@kamino-finance/klend-sdk';
import { QUERYKEYS } from '../../constants/queryKeys';
import { useWeb3Client } from '../useWeb3Client';
import { API_URL } from '../../constants/api';

const EMPTY_DATA: KaminoStrategyWithShareMint[] = [];
const EMPTY_MAP: PubkeyHashMap<PublicKey, KaminoStrategyWithShareMint> = new PubkeyHashMap();

export const useStrategyAdressesWithShareMintsQuery = () => {
  const { web3client } = useWeb3Client();
  const queryFn = async () => {
    if (!web3client) {
      throw new Error('Wallet is not connected');
    }

    const response = await fetch(`${API_URL}/strategies`);
    const data: KaminoStrategyWithShareMint[] = await response.json();

    const mapByMint: PubkeyHashMap<PublicKey, KaminoStrategyWithShareMint> = new PubkeyHashMap();

    data.forEach((item) => {
      mapByMint.set(new PublicKey(item.shareMint), item);
    });

    return { data, mapByMint };
  };

  const { data, ...rest } = useQuery(QUERYKEYS.getStrategyAdressesWithShareMints(), queryFn, {
    enabled: true,
    cacheTime: 2 * 60 * 60 * 1000, // 2 hours
    refetchInterval: 1 * 60 * 60 * 1000, // 1 hour
    staleTime: 1.2 * 60 * 60 * 1000, // 1.2 hours
  });

  return {
    data: {
      strategyAdressesWithShareMints: data?.data || EMPTY_DATA,
      strategyAdressesWithShareMintsMap: data?.mapByMint || EMPTY_MAP,
    },
    ...rest,
  };
};
