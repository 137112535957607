import { ReactElement, SVGProps } from 'react';

export const SvgChips = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M21 12a9 9 0 0 1-9 9m9-9a9 9 0 0 0-9-9m9 9h-4m-5 9a9 9 0 0 1-9-9m9 9v-4m-9-5a9 9 0 0 1 9-9m-9 9h4m5-9v4m5 5a5 5 0 0 1-5 5m5-5a5 5 0 0 0-5-5m0 10a5 5 0 0 1-5-5m0 0a5 5 0 0 1 5-5M6 6l2.475 2.475m10-2.475L16 8.475M16 16l2.345 2.383M8.475 16 6 18.475"
      stroke="#fff"
    />
  </svg>
);
