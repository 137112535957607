import { ReactElement, SVGProps } from 'react';

export const SvgUsersGroup = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.167 13.334c0-1.105 1.492-2 3.333-2 1.841 0 3.334.895 3.334 2M12.5 9.5c1.177.309 2 1.014 2 1.834M4.5 9.5c-1.177.309-2 1.014-2 1.834M8.5 9.334a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12.5 6.825a2 2 0 0 0-2.667-2.981M4.5 6.825a2 2 0 1 1 2.667-2.981"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
