import { FC } from 'react';

import s from './MaintenanceBanner.module.less';

import { Text } from '../../../uiKitV2/Typography';
import { Link } from '../../../uiKitV2/Link';
import { FOOTER_LOGOS_V2 } from '../../AppFooter/types';
import { Banner } from '../Banner';

export const MaintenanceBanner: FC = () => {
  const discordLink = FOOTER_LOGOS_V2.find((item) => item.alt === 'discord')?.link;

  return (
    <Banner localStorageKey="kamino.isMaintenanceActive" alwaysShowAtInit>
      <Text color="grey-light" className={s.bannerText}>
        The app may experience up to 3 hours of slowness due to a backend upgrade to more powerful infrastructure. For
        more information, please contact us on{' '}
        <Link href={discordLink} target="_blank">
          Discord
        </Link>
        .
      </Text>
    </Banner>
  );
};
