import React, { useEffect } from 'react';
import s from './BlockingOverlay.module.less';
import { Text } from '../../uiKitV2/Typography';

type Props = {
  title: string;
  content: string;
};

export const BlockingOverlay = ({ title, content }: Props) => {
  useEffect(() => {
    // Disable scrolling on component mount
    document.body.style.overflow = 'hidden';

    // Enable scrolling again when the component is unmounted
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={s.root}>
      <div className={s.geoblock}>
        <Text fs={22} lh={32} className={s.geoblockTitle}>
          {title}
        </Text>
        <Text fs={14} lh={18} color="grey" className={s.geoblockText}>
          {content}
        </Text>
      </div>
    </div>
  );
};
