import { withStyles } from '@bruitt/classnames';
import React, { ReactNode } from 'react';

import { BaseButton, BaseButtonProps } from '../BaseButton';

import s from './IconButton.module.less';

const sx = withStyles(s);

type ButtonSize = 'large' | 'medium' | 'small';

export interface ButtonProps extends BaseButtonProps {
  icon?: ReactNode;
  size?: ButtonSize;
  shape?: 'circle';
}

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { className, icon, size = 'medium', shape, ...rest } = props;

  return (
    <BaseButton className={sx('root', className, { size, shape })} {...rest} ref={ref}>
      {icon}
    </BaseButton>
  );
});
