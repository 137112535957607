import React, { FC } from 'react';
import s from './BalancesList.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { TokenIcon } from '../../../../../../shared/uiKitV2/TokenIcon';
import { formatNumber } from '../../../../../../shared/utils';
import { TokenWithPrice } from '../../../../types/tokenWithPrice';
import { Spinner } from '../../../../../../shared/uiKitV2/Spinner';

const { formatTokenAllDecimals: ftad } = formatNumber;

type BalancesListProps = {
  tokens: TokenWithPrice[];
  isBalancesLoading: boolean;
};
export const BalancesList: FC<BalancesListProps> = ({ tokens, isBalancesLoading }) => {
  if (isBalancesLoading) {
    return (
      <div className={s.root}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={s.root}>
      {tokens
        .filter((token) => token.value.greaterThan(0))
        .map((token) => (
          <div className={s.tokenItem} key={token.isNativeSolana ? 'solana' : token.address}>
            <div className={s.tokenRight}>
              <TokenIcon tokenMint={token.address} size={24} />
              <div className={s.tokenNames}>
                <Text fs={14} lh={20}>
                  {token.symbol}
                </Text>
                <Text fs={14} lh={20} color="grey">
                  {token.name}
                </Text>
              </div>
            </div>

            <div className={s.tokenValues}>
              <Text fs={14} lh={20}>
                {ftad(token.amount.toNumber(), token.decimals)}
              </Text>
              <Text fs={14} lh={20} color="grey">
                ${ftad(token.value.toNumber(), 2)}
              </Text>
            </div>
          </div>
        ))}
    </div>
  );
};
