import { numberToDex, StrategyWithAddress } from '@kamino-finance/kliquidity-sdk';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { UserPosition } from '../../types/UserPosition';
import { useUserPositionsWithTokensAmount } from './useUserPositionsWithTokensAmount';
import { useUserDiyVaults } from './queries/useUserDiyVaults';
import { isValidPubkey, lamportsToNumberDecimal, strategyUserTokensDeposited } from '../../utils';
import { useUserFarmsQuery } from '../../../features/FarmsRewardsTable/hooks/useUserFarmsQuery';
import { useStrategiesSharesDataQuery } from './queries/useStrategiesSharesDataQuery';
import { useWalletAddressFromParamsOrEnv } from '../useWalletAddressFromParamsOrEnv';
import { useStrategiesQueryCleanedUp } from '../../queriesCleanedUp/useStrategiesQueryCleanedUp';

interface UserFarmPosition {
  strategy: PublicKey;
  shareMint: PublicKey;
  sharesAmount: Decimal;
  strategyDex: 'ORCA' | 'RAYDIUM' | 'METEORA';
  tokenAMint: string;
  tokenBMint: string;
  tokenAAmount: Decimal;
  tokenBAmount: Decimal;
  isEmptyDiyStrategy: boolean;
}

const EMPTY_DATA: UserPosition[] = [];
const EMPTY_F_DATA: UserFarmPosition[] = [];

function buildEmptyUserPositionFromDiyVault(strategy: StrategyWithAddress): UserPosition {
  const buildedEmptyUserPositionFromDiyVault = {
    strategy: strategy.address,
    shareMint: strategy.strategy.sharesMint,
    sharesAmount: new Decimal(0),
    strategyDex: numberToDex(strategy.strategy.strategyDex.toNumber()),
    tokenAMint: strategy.strategy.tokenAMint.toString(),
    tokenBMint: strategy.strategy.tokenBMint.toString(),
    tokenAAmount: new Decimal(0),
    tokenBAmount: new Decimal(0),
    isEmptyDiyStrategy: true,
  };
  return addIsDiyVaultKey(buildedEmptyUserPositionFromDiyVault);
}

function addIsDiyVaultKey(strategy: UserPosition) {
  return {
    ...strategy,
    isDiyVault: true,
  };
}

/**
 * Combines positions of Liquidity strategies, DYI strategies and strategies farms positions
 */
export const useUserPositions = () => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { data: userPositionsLive, isLoading: isUserPositionsLiveLoading } = useUserPositionsWithTokensAmount();
  const { data: userFarms, isLoading: areUserFarmLoading, dataUpdatedAt: farmsUpdatedAt } = useUserFarmsQuery();

  // fetch DIY vaults to show them in Portfolio positions table
  // even if user do not have a position there
  const { data: userDiyVaults, isLoading: areDiyVaultsLoading } = useUserDiyVaults(); // TODO: @egor this calls kaminoSdk.getAllStrategiesWithFilters directly

  const farmsStrategiesAddresses = useMemo(() => {
    return Array.from(userFarms.values())
      .filter((farm) => isValidPubkey(farm.strategyId))
      .map((farm) => farm.strategyId);
  }, [userFarms]);

  // The query in useStrategiesQueryCleanedUp is disabled while the strategiesAddresses is empty, but to make the
  // query dependent on the previous one, we can just pass the farmsUpdatedAt in the query key.
  const queryKey = `farmsStrategies-${walletPublicKey ?? ''}-${farmsUpdatedAt.toString()}`;
  const { strategiesByAddress } = useStrategiesQueryCleanedUp({
    queryKey,
    strategiesAddresses: farmsStrategiesAddresses,
  });

  const { data: strategiesBalancesMap } = useStrategiesSharesDataQuery({
    queryKey,
    addresses: farmsStrategiesAddresses,
  });

  const isLoading = isUserPositionsLiveLoading && areDiyVaultsLoading && areUserFarmLoading;

  // positions that are staked into farms
  const userFarmPositions = useMemo(() => {
    if (!walletPublicKey || !strategiesByAddress) {
      return EMPTY_F_DATA;
    }
    return Array.from(userFarms.values())
      .filter((position) => isValidPubkey(position.strategyId))
      .map((farm) => {
        const strategyAddress = farm.strategyId;
        const { strategy } = strategiesByAddress[strategyAddress.toString()];
        const sharesAmount = farm.activeStakeByDelegatee.get(new PublicKey(walletPublicKey)) || new Decimal(0);
        const strategySharesData = strategiesBalancesMap[strategyAddress.toString()];

        const tokensAmounts = strategySharesData
          ? strategyUserTokensDeposited({
              shares: sharesAmount,
              balances: {
                total: {
                  a: strategySharesData.shareData.balance.computedHoldings.available.a
                    .add(strategySharesData.shareData.balance.computedHoldings.invested.a)
                    .toString(),
                  b: strategySharesData.shareData.balance.computedHoldings.available.b
                    .add(strategySharesData.shareData.balance.computedHoldings.invested.b)
                    .toString(),
                },
              },
              totalIssued: lamportsToNumberDecimal(
                new Decimal(strategySharesData.strategy.sharesIssued.toString()),
                strategySharesData.strategy.sharesMintDecimals.toNumber()
              ),
            })
          : { tokenA: new Decimal(0), tokenB: new Decimal(0) };

        return {
          strategy: strategyAddress,
          shareMint: new PublicKey(strategy.sharesMint),
          sharesAmount,
          strategyDex: numberToDex(Number(strategy.strategyDex)),
          tokenAMint: strategy.tokenAMint.toString(),
          tokenBMint: strategy.tokenBMint.toString(),
          tokenAAmount: tokensAmounts.tokenA,
          tokenBAmount: tokensAmounts.tokenB,
          isEmptyDiyStrategy: false,
        };
      });
  }, [strategiesBalancesMap, strategiesByAddress, userFarms, walletPublicKey]);

  const data = useMemo(() => {
    if (isLoading) {
      return EMPTY_DATA;
    }

    return [...userPositionsLive, ...userDiyVaults.map(buildEmptyUserPositionFromDiyVault), ...userFarmPositions];
  }, [isLoading, userPositionsLive, userDiyVaults, userFarmPositions]);

  return {
    data,
    isLoading,
  };
};
