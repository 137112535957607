import { ReactElement, SVGProps } from 'react';

export const SvgNoPointsStar = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      d="m12.531 8 1.37-1.27a.31.31 0 0 0-.174-.535l-3.703-.44a.309.309 0 0 1-.244-.178L8.218 2.18a.308.308 0 0 0-.56 0L6.096 5.577a.309.309 0 0 1-.244.178L4 5.975M11.402 11.17l.592 3.397c.052.303-.226.537-.457.386l-3.284-2.136a.274.274 0 0 0-.304 0l-3.283 2.136c-.232.15-.51-.083-.457-.386l.733-4.29a.399.399 0 0 0-.094-.335L3 9"
      stroke="#5F7183"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m1 6.896 14 3.5" stroke="#5F7183" strokeWidth={1.5} strokeLinecap="round" />
  </svg>
);
