import React, { FC } from 'react';

import { Link } from '../../../../shared/uiKitV2/Link';
import { Text } from '../../../../shared/uiKitV2/Typography';

import s from './Transactions.module.less';
import { Color } from '../../../../shared/types/global/colors';
import { Icon, IconName } from '../../../../shared/uiKitV2/Icon';
import { Spinner } from '../../../../shared/uiKitV2/Spinner';
import { NotificationTransaction, TransactionStatus } from '../../../../shared/types/notifications';
import { TransactionDetail } from '../TransactionDetail';

interface TransactionsProps {
  transactions: NotificationTransaction[];
}

type TransactionColor = {
  [key in TransactionStatus]: Color;
};

type TransactionIcons = {
  [key in Exclude<TransactionStatus, 'pending'>]: IconName;
};

const TRANSACTION_COLOR: TransactionColor = {
  success: 'green',
  error: 'red',
  pending: 'grey-deep',
};

const TRANSACTION_ICON: TransactionIcons = {
  success: 'Check',
  error: 'Close',
};

export const Transactions: FC<TransactionsProps> = ({ transactions }) => {
  return (
    <div className={s.root}>
      {transactions.map((tx, index) => {
        const linkToTransaction = tx.txId ? `https://solscan.io/tx/${tx.txId}` : null;

        const label = tx.status === 'error' ? `${tx.label} failed` : tx.label;

        return (
          <div key={tx.txId}>
            <div className={s.item}>
              {tx.status === TransactionStatus.Pending ? (
                <Spinner size={20} />
              ) : (
                <Icon size={20} color={TRANSACTION_COLOR[tx.status]} name={TRANSACTION_ICON[tx.status]} />
              )}

              <Text fs={14} lh={18} weight="regular" color={TRANSACTION_COLOR[tx.status]}>
                Transaction {index + 1}:
              </Text>

              {linkToTransaction ? (
                <Link
                  textProps={{ fs: 14, lh: 18, weight: 'regular' }}
                  target="_blank"
                  rel="noreferrer"
                  endIcon={<Icon size={13} name="ArrowTopRight" />}
                  href={linkToTransaction}
                >
                  {label}
                </Link>
              ) : (
                <Text fs={14} lh={18} weight="regular" color="grey" underline>
                  {label}
                </Text>
              )}
            </div>
            {tx.tranactionDetail && <TransactionDetail data={tx.tranactionDetail} />}
          </div>
        );
      })}
    </div>
  );
};
