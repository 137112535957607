import { PublicKey, Transaction, VersionedTransaction } from '@solana/web3.js';
import { useCallback } from 'react';
import { useWeb3Client } from './useWeb3Client';
import useEnv from './useEnv';

function isTransactionArray(arr: any[]): arr is Transaction[] {
  return arr.every((item) => item instanceof Transaction);
}

function isVersionedTransactionArray(arr: any[]): arr is VersionedTransaction[] {
  return arr.every((item) => item instanceof VersionedTransaction);
}

export type ProviderReturnType = {
  signAllTransactions: (
    txns: Transaction[] | VersionedTransaction[]
  ) => Promise<Transaction[] | VersionedTransaction[]>;
};

export const useSignAllTransactions = (): ProviderReturnType => {
  const { web3client } = useWeb3Client();
  const { walletPublicKey, signAllTransactions: signAllTransactionProvider } = useEnv();

  const signAllTransactions: ProviderReturnType['signAllTransactions'] = useCallback(
    async (txns) => {
      if (!web3client || !walletPublicKey || !signAllTransactionProvider) {
        throw new Error('wallet is not connected');
      }
      const blockhash = await web3client.sendConnection.getLatestBlockhash('confirmed');
      let preparedTxns: Transaction[] | VersionedTransaction[] = [];

      if (isTransactionArray(txns)) {
        // const { tx } = r;
        // let { signers } = r;

        // if (signers === undefined) {
        //   signers = [];
        // }
        preparedTxns = txns.map((tx) => {
          tx.feePayer = new PublicKey(walletPublicKey);
          tx.recentBlockhash = blockhash.blockhash;
          return tx;
        });
        return signAllTransactionProvider<Transaction>(preparedTxns);

        // signers
        //   .filter((s: any): s is Signer => s !== undefined)
        //   .forEach((kp: any) => {
        //     tx.partialSign(kp);
        //   });
      }
      if (isVersionedTransactionArray(txns)) {
        preparedTxns = txns;
        return signAllTransactionProvider<VersionedTransaction>(preparedTxns);
      }
      throw new Error('Invalid transaction type');
    },
    [signAllTransactionProvider, walletPublicKey, web3client]
  );

  return {
    signAllTransactions,
  };
};
