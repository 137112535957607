import React, { FC, ReactNode, useEffect, useState } from 'react';
import { withStyles } from '@bruitt/classnames';

import s from './Tabs.module.less';
import { Text } from '../Typography';
import { Tooltip } from '../Tooltip';

const sx = withStyles(s);

export type Key = string;

export interface Tab {
  key: string;
  label: string;
  isNew?: boolean;
  tooltip?: string | ReactNode;
}

interface TabsProps {
  activeKey: Key;
  variant?: 'normal' | 'minimal' | 'golden' | 'secondary' | 'green' | 'red';
  onChange?: (key: Key) => void;
  tabs: Tab[];
  isFullWidth?: boolean;
}

const EMPTY_TABS: Tab[] = [];

export const Tabs: FC<TabsProps> = ({
  activeKey,
  onChange,
  variant = 'normal',
  tabs = EMPTY_TABS,
  isFullWidth = false,
}) => {
  return (
    <div className={sx('root', { variant, isFullWidth })}>
      {tabs.map((tab) => (
        <button
          key={tab.key}
          type="button"
          className={sx('tabItem', {
            isActive: tab.key === activeKey,
            tabItemVariant: variant,
            isNew: tab.isNew,
          })}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const target = event.target as HTMLElement;
            if (target.tagName !== 'A' && !target.closest('a')) {
              event.preventDefault();
              onChange?.(tab.key);
            }
          }}
        >
          {tab.tooltip ? (
            <Tooltip clickable render={() => <div className={s.tooltipOverlay}>{tab.tooltip}</div>}>
              <Text
                className={sx({
                  isJup: tab.key === 'jup' || tab.key === 'jlp',
                  isKMNO: tab.key === 'kmno',
                })}
                underline={Boolean(tab.tooltip)}
              >
                {tab.label}
              </Text>
            </Tooltip>
          ) : (
            <span
              className={sx({
                isJup: tab.key === 'jup' || tab.key === 'jlp',
                isKMNO: tab.key === 'kmno',
              })}
            >
              {tab.label}
            </span>
          )}
        </button>
      ))}
    </div>
  );
};

interface ContentProps {
  content: Array<{
    key: Key;
    component: React.ReactNode;
  }>;
  activeKey: Key;
}

export const TabsContent: FC<ContentProps> = ({ content, activeKey }) => {
  const [activatedKeys, setActivatedKeys] = useState<Key[]>([activeKey]);

  useEffect(() => {
    if (!activatedKeys.includes(activeKey)) {
      setActivatedKeys([...activatedKeys, activeKey]);
    }
  }, [activeKey, activatedKeys]);

  return (
    <>
      {content.map((item) => {
        if (item.key === activeKey || activatedKeys.includes(item.key)) {
          return (
            <div key={item.key} style={{ display: item.key === activeKey ? 'block' : 'none' }}>
              {item.component}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};
