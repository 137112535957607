import { Chain } from '../shared/services/web3/types';
import { WEB3_CUSTOM_CHAIN_KEY, WEB3_ENDPOINT_KEY, WEB3_ENV_KEY } from './constants';

export const saveCustomRPC = (chain: Chain) => {
  localStorage.setItem(WEB3_ENV_KEY, chain.name);
  localStorage.setItem(WEB3_ENDPOINT_KEY, chain.endpoint);
  localStorage.setItem(WEB3_CUSTOM_CHAIN_KEY, JSON.stringify(chain));
};

export const removeCustomRPC = () => {
  localStorage.removeItem(WEB3_CUSTOM_CHAIN_KEY);
};

export const getSavedCustomRpc = (): Chain | null => {
  const customLsChain = localStorage.getItem(WEB3_CUSTOM_CHAIN_KEY);
  if (customLsChain) {
    let preparedChain;
    try {
      const parsedChain = JSON.parse(customLsChain);
      preparedChain = parsedChain as Chain;
    } catch {
      preparedChain = null;
    }
    return preparedChain;
  }
  return null;
};
