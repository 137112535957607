export function shorterString(value: string, numChars = 4): string {
  // Check the input to avoid errors
  if (value.length <= 2 * numChars) {
    return value;
  }
  // Extract the beginning and ending
  const start = value.substr(0, numChars);
  const end = value.substr(value.length - numChars, numChars);

  return `${start}...${end}`;
}
