import { ReactElement, SVGProps } from 'react';

export const SvgPortfolio = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.782 11.69c-.77-.26-1.6-.39-2.46-.39-3.15 0-5.86 1.85-7.11 4.53-.72 1.54.49 3.31 2.19 3.31h6.14m5.27-8.99v6.25c1.07 1.63 2.13 3.26 3.19 4.9m-3.19-4.89h6.03m0-.22a6.03 6.03 0 1 1-12.06 0 6.03 6.03 0 0 1 12.06 0Zm-8.95-11.63a3.56 3.56 0 1 1-7.12 0 3.56 3.56 0 0 1 7.12 0Z"
    />
  </svg>
);
