import { ReactElement, SVGProps } from 'react';

export const SvgCompare = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 5.651h6.14v12.834H12M12 3.29V20.85M5.86 7.63v-1.98h1.784M5.86 16.51v1.976h1.785M5.86 11.083v1.975m4.939-7.475h1.78m-1.78 12.902h1.78"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
