import { Color } from '../types/global/colors';

export const getLtvColor = (ltv: number, maxLtv: number, liquidationLtv: number): Color => {
  if (ltv >= liquidationLtv) {
    return 'red';
  }
  if (ltv >= maxLtv) {
    return 'orange';
  }
  return 'green';
};

/**
 * Separate color scheme for Multiply as it has less risk to get liquidated
 * @param ltv
 * @param maxLtv
 * @param liquidationLtv
 */
export const getMultiplyLtvColor = (ltv: number, maxLtv: number, liquidationLtv: number): Color => {
  const redThreshold = liquidationLtv - 0.05;
  const orangeThreshold = maxLtv;

  if (ltv >= redThreshold) {
    return 'red';
  }
  if (ltv >= orangeThreshold) {
    return 'orange';
  }
  return 'green';
};
