export const USDC_MINT = `EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v`;
export const SOL_MINT = `So11111111111111111111111111111111111111112`;
export const JITOSOL_MINT = 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn';
export const MSOL_MINT = `mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So`;
export const MNDE_MINT = `MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey`;
export const ENT_MINT = '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs';
export const USDT_MINT = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB';
export const BSOL_MINT = 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1';
export const JUP_MINT = 'JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN';
export const BONK_MINT = 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263';
export const JLP_MINT = '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4';
export const KMNO_MINT = 'KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS';
export const JUPSOL_MINT = 'jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v';
export const HSOL_MINT = 'he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A';
export const VSOL_MINT = 'vSoLxydx6akxyMD9XEcPvGYNGq6Nn66oqVb3UkGkei7';
export const PYUSD_MINT = '2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo';
export const WBTC_MINT = '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh';
export const TBTC_MINT = `6DNSN2BJsaPFdFFc1zP37kkeNe4Usc1Sqkzr9C9vPWcU`;
export const SUSDE_MINT = `Eh6XEPhSwoLv5wFApukmnaVSHQ6sAnoD9BmgmwQoN2sN`;
export const BBSOL_MINT = 'Bybit2vBJGhPF52GBdNaQfUJ6ZpThSgHBobjWZpLPb4B';
export const WIF_MINT = 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm';

// for testing
export const FAKE_USDC = 'USDCHDcjejXG1tqnrX4SfvsB2TGp8xGgTHXqxcoSeF2';
export const FAKE_USDT = 'USDT4qHsqCqLsExrrzccS9u3XTiGdMYCX3bgphxLt7m';
export const FAKE_WBTC = 'WBTCzxUrrGDFehkBmJZtMnWoBAjmbFy1K8Y4gmNvSNQ';
export const FAKE_WSOL = 'WSoLZvwZh5mQDEpHUsvxwbnM1yGbW3Qd9rEya7GJCPX';
export const FAKE_ETH = 'WETHziE47NH4Rhea1PYYGeo9U4UuT4JdRVdzwyQA8Up';
