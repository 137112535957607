import React, { FC, useCallback } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory } from 'react-router-dom';
import s from './LendingPositions.module.less';
import { Text } from '../../../../../../../../shared/uiKitV2/Typography';
import { InterestFeesCell, LendingPositionName } from '../../../../../../../../pages/PortfolioV2/components/TableCells';
import { buildLoanDashboardUrl, formatNumber } from '../../../../../../../../shared/utils';
import { useLendingPositionsTableData } from '../../../../../../../../pages/PortfolioV2/hooks/useLendingPositionsTableData';
import { PositionsAccordion } from '../../../PositionsAccordion/PositionsAccordion';
import { PublicKeyAddress } from '../../../../../../../../shared/types/strategies';
import { layoutProviderStore } from '../../../../../../../../stores/layoutProviderStore';

const { formatUsd: fusd } = formatNumber;

export const LendingPositions: FC = () => {
  const { data, isLoading } = useLendingPositionsTableData();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const { updateProviderParams } = layoutProviderStore;

  const goToObligationDashboard = useCallback(
    (marketAddress: string, address?: PublicKeyAddress) => {
      if (address) {
        history.push(buildLoanDashboardUrl(address, marketAddress));
        mixpanel.track('sidepanel:position_click', {
          address: address.toString(),
          type: 'borrowlend',
        });
      }
      updateProviderParams({ isSidePanelOpened: false });
    },
    [history, mixpanel, updateProviderParams]
  );

  if (!data.length && !isLoading) {
    return null;
  }

  return (
    <PositionsAccordion header="Borrow/Lend" isLoading={isLoading}>
      <div className={s.list}>
        {data.map((position) => {
          const depositMints = position.deposits.map((d) => d.mintAddress);

          return (
            <div
              className={s.tokenItem}
              key={position.address.toString()}
              onClick={() => goToObligationDashboard(position.marketAddress.toString(), position.address)}
            >
              <div className={s.tokenRight}>
                <LendingPositionName
                  tokensDisplayCount={3}
                  textProps={{ fs: 16, lh: 22 }}
                  tokensMints={depositMints}
                  maxTokensDisplayed={2}
                />
              </div>

              <div className={s.tokenValues}>
                <Text fs={14} lh={20}>
                  {fusd(position.netValue)}
                </Text>

                <InterestFeesCell
                  obligationAddress={position.address}
                  marketAddress={position.marketAddress}
                  label=""
                  textProps={{
                    fs: 14,
                    lh: 20,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </PositionsAccordion>
  );
};
