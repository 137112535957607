import { withStyles } from '@bruitt/classnames';
import { CSSProperties, FC } from 'react';

import { getProviderIconClassName } from './utils';

import s from './ProviderIcon.module.less';
import { Provider } from '../../constants/kamino';

const sx = withStyles(s);

export interface ProviderIconProps {
  provider: Provider;
  size: number;
  className?: string;
}

export const ProviderIcon: FC<ProviderIconProps> = ({ provider, size, className }) => {
  const iconClassname = getProviderIconClassName(provider);

  const style: CSSProperties = size ? { width: size, height: size } : {};

  return <div style={style} className={sx('root', iconClassname || '', className)} />;
};
