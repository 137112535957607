import { WhirlpoolStrategyJSON } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import { getDexFromRaw } from '../utils';
import { Token } from '../../constants/tokens';
import { PublicKeyAddress, StrategyUi } from '../../types/strategies';
import { ApyMetrics } from '../../types/metrics';

const QUOTE_TOKENS_ORDER_PRIORITY: Token[] = ['USDH', 'USDC', 'USDT', 'SOL', 'bSOL'];
const QUOTE_TOKENS_ORDER_PRIORITY_MINTS: string[] = [
  'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX',
  'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  'So11111111111111111111111111111111111111112',
  'So11111111111111111111111111111111111111111',
  'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
];
// TODO: move to shared constants
export const LIQUIDITY_STAKING_TOKENS = ['cgntSOL', 'stSOL', 'mSOL', 'JitoSOL', 'bSOL', 'LAINESOL'];

/**
 * Built vault name according to tokens sorted by QUOTE_TOKENS
 * @param tokens[]
 */
export const sortTokensByQuoteToken = (tokens: Token[]): Token[] => {
  const [token1, token2] = tokens;
  // token with lowest index is quote token
  // we set big value for -1 because -1 means token is not in QUOTE_TOKENS_ORDER _PRIORITYlist so it is the lowest priority
  const token1Index =
    QUOTE_TOKENS_ORDER_PRIORITY.indexOf(token1) <= -1 ? 999 : QUOTE_TOKENS_ORDER_PRIORITY.indexOf(token1);
  const token2Index =
    QUOTE_TOKENS_ORDER_PRIORITY.indexOf(token2) <= -1 ? 999 : QUOTE_TOKENS_ORDER_PRIORITY.indexOf(token2);

  if (token2Index <= token1Index) {
    return [token1, token2];
  }
  return [token2, token1];
};

export const sortTokensMintsByQuoteToken = (mints: PublicKeyAddress[]): string[] => {
  const [mint1, mint2] = mints;
  // return [mint1.toString(), mint2.toString()];
  // token with lowest index is quote token
  // we set big value for -1 because -1 means token is not in QUOTE_TOKENS_ORDER _PRIORITYlist so it is the lowest priority
  const token1Index =
    QUOTE_TOKENS_ORDER_PRIORITY_MINTS.indexOf(mint1.toString()) <= -1
      ? 999
      : QUOTE_TOKENS_ORDER_PRIORITY_MINTS.indexOf(mint1.toString());
  const token2Index =
    QUOTE_TOKENS_ORDER_PRIORITY_MINTS.indexOf(mint2.toString()) <= -1
      ? 999
      : QUOTE_TOKENS_ORDER_PRIORITY_MINTS.indexOf(mint2.toString());

  if (token2Index <= token1Index) {
    return [mint1.toString(), mint2.toString()];
  }
  return [mint2.toString(), mint1.toString()];
};

/**
 * Sort tokens to set quote token according to QUOTE_TOKENS priority
 * @param tokenA
 * @param tokenB
 */
export const getVaultNameQuoteSort = (tokenA: Token, tokenB: Token): string =>
  sortTokensByQuoteToken([tokenA, tokenB]).join('-');

export const checkIsSolsStrategy = (tokens: Token[]) =>
  LIQUIDITY_STAKING_TOKENS.includes(tokens[0]) || LIQUIDITY_STAKING_TOKENS.includes(tokens[1]);

export const checkIsShowLightningBolt = (tokens: Token[], apyInfo: ApyMetrics | null) => {
  const isSolStrategy = checkIsSolsStrategy(tokens);
  const hasKaminoRewards = Boolean(apyInfo?.kamino.length);
  return isSolStrategy || hasKaminoRewards;
};

export const addTokenAmountsToStrategies = async (strategies: WhirlpoolStrategyJSON[]) => {
  const strategiesWithTokenAmountsRequests = strategies.map((strategy) => {
    return strategy;
  });

  return Promise.all(strategiesWithTokenAmountsRequests);
};

export const makeStrategyUrl = ({
  tokens,
  strategyDex,
  address,
  type = 'liquidity',
}: {
  tokens: Token[];
  strategyDex: StrategyUi['strategyDex'];
  address: string;
  type?: 'liquidity' | 'performance' | 'advanced-analytics';
}) => {
  const tokensString = sortTokensByQuoteToken(tokens).join('-').toLowerCase();
  const provider = getDexFromRaw(strategyDex).toLowerCase();

  return `/${type}/${tokensString}-${provider}/${address}`;
};
