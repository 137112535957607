/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { withStyles } from '@bruitt/classnames';
import React, { Children, FC, ReactElement, ReactNode, useState } from 'react';
import { Text } from '../Typography';

import { Icon } from '../Icon';

import s from './Accordion.module.less';

const sx = withStyles(s);

export type AccordionProps = {
  defaultActiveKeys?: string[];
  children: ReactNode;
  multiple?: boolean;
  onChange?: (keys: string[]) => void;
  isTightFit?: boolean;
  accordionClassName?: string;
  panelClassName?: string;
  headerClassName?: string;
};

type PanelProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  header: string;
  children: ReactNode;
};

const EMPTY_DEFAULT_ACTIVE_KEYS: string[] = [];

export const Panel: FC<PanelProps> = ({ children }) => <div>{children}</div>;

export const Accordion: FC<AccordionProps> = ({
  defaultActiveKeys = EMPTY_DEFAULT_ACTIVE_KEYS,
  children,
  multiple = false,
  isTightFit = false,
  onChange,
  accordionClassName,
  panelClassName,
  headerClassName,
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>(defaultActiveKeys);

  const togglePanel = (key: string) => {
    let keys = [];

    const isKeyInActiveKeys = activeKeys.includes(key);

    if (multiple) {
      keys = isKeyInActiveKeys ? activeKeys.filter((activeKey) => activeKey !== key) : [...activeKeys, key];
    } else {
      keys = isKeyInActiveKeys ? [] : [key];
    }

    setActiveKeys(keys);

    onChange?.(keys);
  };

  return (
    <>
      {Children.map(children, (child) => {
        const panel = child as ReactElement<PanelProps>;
        const { header, children: panelChildrenContent } = panel.props;
        const isOpen = activeKeys.includes(header);

        return (
          <div className={sx('panel', { isOpen, isTightFit }, accordionClassName)}>
            <div className={sx('panelHeader', headerClassName)} onClick={() => togglePanel(header)}>
              <Icon
                name="ChevronDown"
                size={12}
                className={sx('chevron')}
                rotationStyle={isOpen ? { transform: 'rotate(180deg)' } : undefined}
              />
              <Text fs={16} lh={22}>
                {header}
              </Text>
            </div>
            {isOpen && <div className={sx('panelContent', panelClassName)}>{panelChildrenContent}</div>}
          </div>
        );
      })}
    </>
  );
};
