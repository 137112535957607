import * as raydiumCustom from '@kamino-finance/kliquidity-sdk/dist/raydium_client/errors/custom';
import { PROGRAM_ID as RAYDIUM_PROGRAM_ID } from '@kamino-finance/kliquidity-sdk/dist/raydium_client/programId';
import * as raydiumAnchor from '@kamino-finance/kliquidity-sdk/dist/raydium_client/errors/anchor';
import * as custom from '@kamino-finance/kliquidity-sdk/dist/kamino-client/errors/custom';
import * as orcaCustom from '@kamino-finance/kliquidity-sdk/dist/whirlpools-client/errors/custom';
import * as farmsCustom from '@hubbleprotocol/farms-sdk/dist/rpc_client/errors/custom';
import * as lendingCustom from '@kamino-finance/klend-sdk/dist/idl_codegen/errors/custom';
import { WHIRLPOOL_PROGRAM_ID as ORCA_PROGRAM_ID } from '@kamino-finance/kliquidity-sdk/dist/whirlpools-client/programId';
import * as anchor from '@kamino-finance/kliquidity-sdk/dist/kamino-client/errors/anchor';
import * as farmsAnchor from '@hubbleprotocol/farms-sdk/dist/rpc_client/errors/anchor';
import * as orcaAnchor from '@kamino-finance/kliquidity-sdk/dist/whirlpools-client/errors/anchor';
import * as lendingAnchor from '@kamino-finance/klend-sdk/dist/idl_codegen/errors/anchor';
import {
  PROGRAM_ID as KAMINO_LENDING_PROGRAM_ID,
  STAGING_PROGRAM_ID as KAMINO_STAGING_LENDING_PROGRAM_ID,
} from '@kamino-finance/klend-sdk';
import { PROGRAM_ID as FARMS_PROGRAM_ID } from '@hubbleprotocol/farms-sdk/dist/rpc_client/programId';
import { PROGRAM_ID } from '../../codegen/rpc_yvaults_client/programId';
import { getTokenPriceTooOld } from './getTokenPriceTooOld';
import { isNil } from './isNil';
import { PriceTooOldExtended } from '../../codegen_extended/PriceTooOldExtened';
import { CustomJupiterError, fromJupiterErrorCode, JUPITER_PROGRAM_ID } from './jupiter';
import { captureError } from './captureError';
import { KAMINO_YVAULTS_STAGING_PROGRAM_ID } from '../constants/programs';
import { getErrorMessage } from './getErrorMessage';

type ParsedError = {
  code?: number;
  programId?: string;
};

const getCustomError = (programId: string, code: number, logs?: string[]) => {
  if (programId === PROGRAM_ID.toString()) {
    if (code === PriceTooOldExtended.code) {
      return new PriceTooOldExtended(logs);
    }
    return custom.fromCode(code, logs);
  }

  if (programId === ORCA_PROGRAM_ID.toString()) {
    return orcaCustom.fromCode(code, logs);
  }

  if (programId === RAYDIUM_PROGRAM_ID.toString()) {
    return raydiumCustom.fromCode(code, logs);
  }

  if (programId === KAMINO_LENDING_PROGRAM_ID.toString()) {
    return lendingCustom.fromCode(code, logs);
  }

  if (programId === KAMINO_STAGING_LENDING_PROGRAM_ID.toString()) {
    return lendingCustom.fromCode(code, logs);
  }

  if (programId === JUPITER_PROGRAM_ID) {
    return fromJupiterErrorCode(code, logs);
  }

  if (programId === FARMS_PROGRAM_ID.toString()) {
    return farmsCustom.fromCode(code, logs);
  }

  throw new Error(`Unknown program id ${programId.toString()}`);
};

const getAnchorError = (programId: string, code: number, logs?: string[]) => {
  if (programId === PROGRAM_ID.toString()) {
    if (code === PriceTooOldExtended.code) {
      return new PriceTooOldExtended(logs);
    }
    return anchor.fromCode(code, logs);
  }

  if (programId === ORCA_PROGRAM_ID.toString()) {
    return orcaAnchor.fromCode(code, logs);
  }

  if (programId === RAYDIUM_PROGRAM_ID.toString()) {
    return raydiumAnchor.fromCode(code, logs);
  }

  if (programId === KAMINO_LENDING_PROGRAM_ID.toString()) {
    return lendingAnchor.fromCode(code, logs);
  }

  if (programId === FARMS_PROGRAM_ID.toString()) {
    return farmsAnchor.fromCode(code, logs);
  }

  throw new Error(`Unknown program id ${programId.toString()}`);
};

export function fromCode({
  programId,
  code,
  logs = [],
}: {
  programId?: string;
  code: number;
  logs: string[];
}):
  | custom.CustomError
  | orcaCustom.CustomError
  | PriceTooOldExtended
  | raydiumCustom.CustomError
  | raydiumAnchor.AnchorError
  | anchor.AnchorError
  | lendingCustom.CustomError
  | lendingAnchor.AnchorError
  | CustomJupiterError
  | farmsAnchor.AnchorError
  | farmsCustom.CustomError
  | null {
  if (!programId) {
    return null;
  }
  if (code >= 6000) {
    return getCustomError(programId, code, logs);
  }
  return getAnchorError(programId, code, logs);
}

const errorRe = /Program (\w+) failed: custom program error: (\w+)/;

function parseErrorLogs(err: { msg?: string; message?: string; logs: string[] }): ParsedError {
  let firstMatch: RegExpExecArray | null = null;

  // eslint-disable-next-line no-restricted-syntax
  for (const logLine of err.logs) {
    firstMatch = errorRe.exec(logLine);
    if (firstMatch !== null) {
      break;
    }
  }

  if (firstMatch === null) {
    return {};
  }

  const [programIdRaw, codeRaw] = firstMatch.slice(1);
  if (
    programIdRaw !== PROGRAM_ID.toString() &&
    programIdRaw !== RAYDIUM_PROGRAM_ID.toString() &&
    programIdRaw !== ORCA_PROGRAM_ID.toString() &&
    programIdRaw !== KAMINO_LENDING_PROGRAM_ID.toString() &&
    programIdRaw !== KAMINO_YVAULTS_STAGING_PROGRAM_ID.toString() &&
    programIdRaw !== KAMINO_STAGING_LENDING_PROGRAM_ID.toString() &&
    programIdRaw !== JUPITER_PROGRAM_ID &&
    programIdRaw !== FARMS_PROGRAM_ID.toString()
  ) {
    return {};
  }

  let code = null;
  try {
    code = parseInt(codeRaw, 16);
    return { code, programId: programIdRaw };
  } catch (parseErr) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    captureError(parseErr);
  }
  return { code: undefined, programId: programIdRaw };
}

type ParsedFromMessageError = { code: number | null; instructionInd: number | null };

function parseErrorCodeFromMessage(err: string): ParsedFromMessageError {
  const result: ParsedFromMessageError = { code: null, instructionInd: null };
  // extract everyting within {}
  // const r = /\{.+\}/im;
  const rErrorId = /\"Custom\":(\d+)/im;
  const rSolflareErrorId = /(?:custom program error: )((?:0x)?[a-fA-F0-9]+)/;
  const errorIdMatched = rErrorId.exec(err) || rSolflareErrorId.exec(err);

  if (errorIdMatched) {
    try {
      result.code = parseInt(errorIdMatched[1]);
    } catch (e) {
      result.code = null;
    }
  }

  const rErrorInstructionId = /Error processing Instruction (\d+):/;
  const instructionIndMatched = rErrorInstructionId.exec(err);

  if (instructionIndMatched) {
    try {
      result.instructionInd = parseInt(instructionIndMatched[1]);
    } catch (e) {
      result.instructionInd = null;
    }
  }

  return result;
}

const getProgramIdFromTxn = (tx: any, instructionInd: number) => {
  let programId;
  // versioned transaction
  if (tx.version === 0 || tx.message.compiledInstructions) {
    const programIdAddressIndex = tx.message.compiledInstructions[instructionInd].programIdIndex;
    programId = tx.message.staticAccountKeys[programIdAddressIndex].toString();
    // legacy transaction
  } else if (tx.instructions) {
    programId = tx.instructions[instructionInd].programId;
  }
  return programId;
};

const getInstructionErrorFromError = (err: any) => {
  return (
    err.InstructionError ||
    err?.error?.InstructionError ||
    err.err?.InstructionError ||
    err.err?.error?.InstructionError
  );
};

export function fromTxError(err: {
  msg?: string;
  message?: string;
  err?: any;
  tx: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  InstructionError?: [string, any];
  logs: string[];
}):
  | custom.CustomError
  | PriceTooOldExtended
  | raydiumCustom.CustomError
  | raydiumAnchor.AnchorError
  | orcaCustom.CustomError
  | orcaAnchor.AnchorError
  | anchor.AnchorError
  | lendingCustom.CustomError
  | lendingAnchor.AnchorError
  | CustomJupiterError
  | farmsAnchor.AnchorError
  | farmsCustom.CustomError
  | null {
  if (typeof err !== 'object' || err === null) {
    return null;
  }
  let errorCode = null;
  let programId;
  let parsedError: ParsedError = {};

  const instructionError = getInstructionErrorFromError(err);

  if (err && err.logs) {
    parsedError = parseErrorLogs(err);
    errorCode = parsedError.code;
    programId = parsedError.programId;

    if ((!programId || !errorCode) && instructionError?.length) {
      errorCode = instructionError[1]?.Custom || null;

      const instructionInd = instructionError[0];

      if (instructionInd) {
        programId = getProgramIdFromTxn(err.tx, Number(instructionInd));
      }
    }
  } else if (err && instructionError) {
    errorCode = instructionError[1]?.Custom || null;
    const instructionInd = instructionError[0];
    if (instructionInd) {
      programId = getProgramIdFromTxn(err.tx, Number(instructionInd));
    }
  } else if (err.err) {
    const { code, instructionInd } = parseErrorCodeFromMessage(err.err.toString());
    errorCode = code;
    if (instructionInd) {
      programId = getProgramIdFromTxn(err.tx, Number(instructionInd));
    }
  }
  return errorCode ? fromCode({ programId, code: errorCode, logs: err.logs }) : null;
}

const TRANSACTION_TOO_LARGE_ERROR_UINT8 = 'encoding overruns Uint8Array';
const TRANSACTION_TOO_LARGE_ERROR_VERSIONED = 'VersionedTransaction too large';
const TRANSACTION_TOO_LARGE_MESSAGE = 'Oops, the transaction could not be built, please try again';

const errorsMessages: Record<string, string> = {
  [TRANSACTION_TOO_LARGE_ERROR_UINT8]: TRANSACTION_TOO_LARGE_MESSAGE,
  [TRANSACTION_TOO_LARGE_ERROR_VERSIONED]: TRANSACTION_TOO_LARGE_MESSAGE,
  'block height exceeded': 'It seems like the network is busy. Please try again in one minute.',
  'state needs to be refreshed':
    'It seems like the network is busy and oracle prices are too old. Please try again in a minute.',
  'slippage tolerance exceeded':
    'Slippage tolerance exceeded. Please try again (recommended) or slightly increase slippage tolerance.',
  'Invalid tick array sequence provided for instruction': 'Jupiter swap failed, please try again.',
};

interface PotentialError {
  msg?: string;
  message?: string | null;
}

/**
 * Search for specific substrings in the error message and return user friendly error message
 * @param err
 */
function findHumanReadableMessage(err: PotentialError & { err?: PotentialError }) {
  const msg = String(getErrorMessage(err));
  for (const key in errorsMessages) {
    if (msg.includes(key)) {
      return errorsMessages[key];
    }
  }
  return null; // or any default value you'd like to return if no match is found
}

/**
 *
 * Maps the private Anchor type ProgramError to a normal Error.
 * Pass ProgramErr.msg as the Error message so that it can be used with chai matchers
 *
 * @param error
 * @param or
 */
export function tryAnchorErrorMessageOr(error: any, or?: string): any | string | null {
  const err = error;
  try {
    const message = findHumanReadableMessage(err);
    if (message !== null) {
      return message;
    }
  } catch (e) {
    // encoding doesn't overruns Uint8Array
  }

  try {
    const programError = fromTxError(err);
    if (programError !== null) {
      const token = getTokenPriceTooOld(err?.logs);
      const userFriendlyMessage = findHumanReadableMessage(programError);
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const userMsg = !isNil(token) ? `${programError.userMsg} (${token})` : programError.userMsg;
      return userMsg || userFriendlyMessage || programError.msg;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    const msg = getErrorMessage(err);
    if (msg) {
      return msg;
    }
  } catch (e) {
    // Not a parsed anchor error
  }

  return or || 'Unexpected error';
}
