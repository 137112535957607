import { Wallet } from '@solana/wallet-adapter-react';

const popularWallets: string[] = ['Phantom', 'Google via TipLink', 'Backpack', 'Solflare', 'OKX Wallet', 'Magic Eden'];

export function sortByMostPopularWallet(wallets: Wallet[]) {
  if (!wallets) {
    return [];
  }

  return wallets.sort((a, b) => {
    const indexA = popularWallets.indexOf(a.adapter.name.toString());
    const indexB = popularWallets.indexOf(b.adapter.name.toString());

    // If both names are in the order list, sort them by the order
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only a's name is in the order list, a comes first
    if (indexA !== -1) {
      return -1;
    }

    // If only b's name is in the order list, b comes first
    if (indexB !== -1) {
      return 1;
    }

    // If neither name is in the order list, keep their original order
    return 0;
  });
}
