import { Commitment, Connection, Transaction, VersionedTransaction } from '@solana/web3.js';
import { isVersionedTransaction, SendTransactionOptions } from '@solana/wallet-adapter-base';
import bs58 from 'bs58';
import { WalletContextState } from '@solana/wallet-adapter-react/src/useWallet';
import { captureError } from './captureError';
import { getErrorMessage } from './getErrorMessage';
import { waitForTransaction } from './waitForTransaction';

// TODO: remove after sendAndConfirmRawTransactionWithRetry will be released

// export async function sendAndConfirmTransaction(
//   wallet: Adapter,
//   connection: Connection,
//   tx: Transaction | VersionedTransaction,
//   commitment: Commitment = 'confirmed',
//   sendTransactionOptions: SendTransactionOptions = {}
// ) {
//   const defaultOptions: SendTransactionOptions = {
//     skipPreflight: true,
//     maxRetries: 0,
//     preflightCommitment: commitment,
//   };
//   console.log('Before send');
//
//   let txId;
//   const latestBlockHash = await connection.getLatestBlockhashAndContext(commitment);
//   try {
//     txId = await wallet.sendTransaction(tx, connection, {
//       ...defaultOptions,
//       ...sendTransactionOptions,
//       minContextSlot: latestBlockHash.context.slot,
//     });
//   } catch (err) {
//     // eslint-disable-next-line @typescript-eslint/no-throw-literal
//     throw { err, tx };
//   }
//
//   console.log('After send');
//   console.log('Txn', txId.toString());
//
//   const t = await connection.confirmTransaction(
//     {
//       blockhash: latestBlockHash.value.blockhash,
//       lastValidBlockHeight: latestBlockHash.value.lastValidBlockHeight,
//       minContextSlot: latestBlockHash.context.slot,
//       signature: txId,
//     },
//     commitment
//   );
//   if (t.value && t.value.err) {
//     const txDetails = await connection.getTransaction(txId, {
//       maxSupportedTransactionVersion: 0,
//       commitment: 'confirmed',
//     });
//     if (txDetails) {
//       // eslint-disable-next-line @typescript-eslint/no-throw-literal
//       throw { err: txDetails.meta?.err, logs: txDetails.meta?.logMessages || [], txId, tx };
//     }
//     // eslint-disable-next-line @typescript-eslint/no-throw-literal
//     throw { err: t.value.err, msg: t.value.err, txId, tx };
//   }
//   return txId;
// }

// TODO: remove after sendAndConfirmRawTransactionWithRetry will be released

// export async function sendAndConfirmRawTransaction(
//   wallet: Adapter,
//   connection: Connection,
//   tx: Buffer,
//   commitment: Commitment = 'confirmed',
//   sendTransactionOptions: SendTransactionOptions = {},
//   recentBlockhash?: string
// ) {
//   const defaultOptions: SendTransactionOptions = {
//     skipPreflight: true,
//     maxRetries: 0,
//     preflightCommitment: commitment,
//   };
//   let txId;
//   const latestBlockHash = await connection.getLatestBlockhashAndContext(commitment);
//   try {
//     txId = await connection.sendRawTransaction(tx, {
//       ...defaultOptions,
//       ...sendTransactionOptions,
//       minContextSlot: latestBlockHash.context.slot,
//     });
//   } catch (err) {
//     // eslint-disable-next-line @typescript-eslint/no-throw-literal
//     throw { err, tx };
//   }
//
//   const t = await connection.confirmTransaction(
//     {
//       blockhash: recentBlockhash || latestBlockHash.value.blockhash,
//       lastValidBlockHeight: latestBlockHash.value.lastValidBlockHeight,
//       minContextSlot: latestBlockHash.context.slot,
//       signature: txId,
//     },
//     commitment
//   );
//   if (t.value && t.value.err) {
//     const txDetails = await connection.getTransaction(txId, {
//       maxSupportedTransactionVersion: 0,
//       commitment: 'confirmed',
//     });
//     if (txDetails) {
//       // eslint-disable-next-line @typescript-eslint/no-throw-literal
//       throw { err: txDetails.meta?.err, logs: txDetails.meta?.logMessages || [], txId, tx };
//     }
//     // eslint-disable-next-line @typescript-eslint/no-throw-literal
//     throw { err: t.value.err, msg: t.value.err, txId, tx };
//   }
//   return txId;
// }

const RETRY_INTERVAL = 2000;

// export async function signAllAndSendAndConfirmRawTransactionsWithRetry({
//   connection,
//   txns,
//   commitment = 'confirmed',
//   sendTransactionOptions = {},
//   signAllTransactions,
// }: // latestBlockHashAndContext,
// {
//   wallet: Adapter;
//   connection: Connection;
//   txns: Transaction[] | VersionedTransaction[];
//   commitment?: Commitment;
//   sendTransactionOptions?: SendTransactionOptions;
//   signAllTransactions: ProviderReturnType['signAllTransactions'];
//   // latestBlockHashAndContext: RpcResponseAndContext<BlockhashWithExpiryBlockHeight>;
// }) {
//   const signedTxns = await signAllTransactions([tx]);
// }

/**
 *
 * @param mainConnection Connection that is used to confirm transaction and send transaction
 * @param extraConnections Additional connections to send tx only to increase landing chance. Usually uses different RPCs from the main connection
 * @param signedTx
 * @param commitment
 * @param sendTransactionOptions
 */
export async function signSendAndConfirmRawTransactionWithRetry({
  mainConnection,
  extraConnections = [],
  tx,
  commitment = 'confirmed',
  sendTransactionOptions = {},
  signTransaction,
  mixpanel,
}: {
  mainConnection: Connection;
  extraConnections?: Connection[];
  tx: Transaction | VersionedTransaction;
  commitment?: Commitment;
  sendTransactionOptions?: SendTransactionOptions;
  signTransaction: WalletContextState['signTransaction'];
  mixpanel: any;
}) {
  if (!signTransaction) {
    throw new Error('signTransaction is not found. Seems like wallet is not connected');
  }
  const signedTx = await signTransaction(tx);
  return sendAndConfirmRawTransactionWithRetry({
    mainConnection,
    extraConnections,
    signedTx,
    commitment,
    sendTransactionOptions,
    mixpanel,
  });
}

/**
 *
 * @param mainConnection Connection that is used to confirm transaction and send transaction
 * @param extraConnections Additional connections to send tx only to increase landing chance. Usually uses different RPCs from the main connection
 * @param signedTx
 * @param commitment
 * @param sendTransactionOptions
 */
export async function sendAndConfirmRawTransactionWithRetry({
  mainConnection,
  extraConnections = [],
  signedTx,
  commitment = 'confirmed',
  sendTransactionOptions = {},
  mixpanel,
}: {
  mainConnection: Connection;
  extraConnections?: Connection[];
  signedTx: Transaction | VersionedTransaction;
  commitment?: Commitment;
  sendTransactionOptions?: SendTransactionOptions;
  mixpanel: any;
}) {
  const rawSignature = isVersionedTransaction(signedTx) ? signedTx.signatures[0] : signedTx.signature;

  if (!rawSignature) {
    throw new Error('Transaction is not signed. Refresh the page and try again');
  }

  const signature = isVersionedTransaction(signedTx)
    ? bs58.encode(rawSignature)
    : bs58.encode(Buffer.from(rawSignature));

  let intervalId: NodeJS.Timer;
  let confirmed = false;
  const serialized = Buffer.from(signedTx.serialize());
  const latestBlockHashAndContext = await mainConnection.getLatestBlockhashAndContext(commitment);
  const defaultOptions: SendTransactionOptions = {
    skipPreflight: true,
    maxRetries: 0,
    preflightCommitment: commitment,
  };

  if (!signature) {
    throw new Error('Transaction is not signed. Refresh the page and try again');
  }

  mixpanel.track('transaction:landing-rate', {
    txId: signature,
    status: 'initiated',
  });

  // Stop retrying
  const stopSendingTx = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  // Listen for transaction confirmation
  const waitForConfirmation = async (sig: string) => {
    try {
      const res = await mainConnection.confirmTransaction(
        {
          blockhash: latestBlockHashAndContext.value.blockhash,
          lastValidBlockHeight: latestBlockHashAndContext.value.lastValidBlockHeight,
          // minContextSlot: latestBlockHashAndContext.context.slot,
          signature: sig,
        },
        commitment
      );
      confirmed = true;
      stopSendingTx();

      return res;
    } catch (error) {
      captureError(error);
      const errorMessage = getErrorMessage(error);
      // failed while waiting for confirmation => likely timeout expired
      mixpanel.track('transaction:landing-rate', {
        txId: signature,
        status: 'dropped',
        cause: 'error',
        error: errorMessage,
      });
      stopSendingTx();
    } finally {
      stopSendingTx();
    }
  };

  // Send transaction and set interval to resend every X seconds
  const sendTransaction = () => {
    if (confirmed) {
      return;
    }
    try {
      mainConnection.sendRawTransaction(serialized, {
        ...defaultOptions,
        ...sendTransactionOptions,
        minContextSlot: latestBlockHashAndContext.context.slot,
      });

      extraConnections.forEach((conn) => {
        conn.sendRawTransaction(serialized, {
          ...defaultOptions,
          ...sendTransactionOptions,
          minContextSlot: latestBlockHashAndContext.context.slot,
        });
      });
    } catch (error) {
      captureError(error);
    }
  };

  sendTransaction();
  intervalId = setInterval(() => {
    sendTransaction();
  }, RETRY_INTERVAL);

  const res = await waitForConfirmation(signature);
  stopSendingTx();

  if (res && res.value && res.value.err) {
    const txDetails = await waitForTransaction(mainConnection, signature);

    if (txDetails) {
      // we are able to fetch transaction details => transaction landed but failed
      mixpanel.track('transaction:landing-rate', {
        txId: signature,
        status: 'success',
        cause: 'error',
      });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw { err: txDetails.meta?.err, logs: txDetails.meta?.logMessages || [], txId: signature, tx: signedTx };
    }

    // no transaction details => we assume that transaction has not landed
    mixpanel.track('transaction:landing-rate', {
      txId: signature,
      status: 'error',
      cause: 'error',
    });
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw { err: res.value.err, msg: res.value.err, txId: signature, tx: signedTx };
  }

  // all good => transaction has landed
  mixpanel.track('transaction:landing-rate', {
    txId: signature,
    status: 'success',
    cause: 'success',
  });

  return signature;
}
