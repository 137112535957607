import { some } from 'lodash';
import { DEPRECATED_LONG_LEVERAGE_PAIRS, LONG_LEVERAGE_PAIRS } from '../../constants/leverage/vaults';
import { PublicKeyAddress } from '../../types/strategies';

interface CheckIsLong {
  collTokenMint: PublicKeyAddress;
  debtTokenMint: PublicKeyAddress;
}

export const checkIsLong = ({ collTokenMint, debtTokenMint }: CheckIsLong) => {
  if (
    some([...LONG_LEVERAGE_PAIRS, ...DEPRECATED_LONG_LEVERAGE_PAIRS], (item) => {
      return collTokenMint.toString() === item[0].toString() && debtTokenMint.toString() === item[1].toString();
    })
  ) {
    return true;
  }

  return false;
};
