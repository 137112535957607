import { FC } from 'react';
import s from './SkeletonParagraph.module.less';

interface SkeletonParagraphProps {
  width?: string | number;
  height?: string | number;
}

export const SkeletonParagraph: FC<SkeletonParagraphProps> = ({ width, height }) => {
  return (
    <div style={{ width, height }} className={s.skeletonOuter}>
      <div className={s.skeleton} />
    </div>
  );
};
