import { ObligationTypeTag } from '@kamino-finance/klend-sdk';

export const getObligationTypeName = (tag: ObligationTypeTag) => {
  switch (tag) {
    case ObligationTypeTag.Vanilla:
      return 'Borrow/Lend';
    case ObligationTypeTag.Multiply:
      return 'Multiply';
    case ObligationTypeTag.Leverage:
      return 'Leverage';
    case ObligationTypeTag.Lending:
      return 'Borrow/Lend';
    default:
      return '';
  }
};
