import { ReactElement, SVGProps } from 'react';

export const SvgRibbonBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 4H7a1 1 0 0 0-1 1v15.131a1 1 0 0 0 1.555.832l3.89-2.593a1 1 0 0 1 1.11 0l3.89 2.593A1 1 0 0 0 18 20.131V5a1 1 0 0 0-1-1Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
