import Decimal from 'decimal.js';
import {
  formatNumber,
  PRICE_DIVERGENCE_JLP_MULTIPLY_THRESHOLD_BPS,
  PRICE_DIVERGENCE_LEVERAGE_THRESHOLD_BPS,
  PRICE_DIVERGENCE_MULTIPLY_THRESHOLD_BPS,
  PriceImpactType,
} from '../../../shared/utils';

const { formatTokenAllDecimals: ftad } = formatNumber;

export const checkPriceDivergenceThreshold = (priceImpactType: PriceImpactType, value: Decimal) => {
  if (priceImpactType === 'jlpMultiply') {
    return value.gte(PRICE_DIVERGENCE_JLP_MULTIPLY_THRESHOLD_BPS);
  }

  return priceImpactType === 'multiply'
    ? value.gte(PRICE_DIVERGENCE_MULTIPLY_THRESHOLD_BPS)
    : value.gte(PRICE_DIVERGENCE_LEVERAGE_THRESHOLD_BPS);
};

export const getBorrowCapacityError = ({
  token,
  borrowAmount,
  borrowingCapacity,
  decimals,
}: {
  token: string;
  borrowAmount: Decimal.Value;
  borrowingCapacity: Decimal.Value;
  decimals: number;
}) => {
  if (new Decimal(borrowingCapacity).lte(0)) {
    return `This action requires borrowing of ${ftad(
      borrowAmount,
      decimals
    )} ${token}, but there is no ${token} liquidity available. More ${token} supply is necessary in the Kamino Lend market to complete this action.`;
  }

  return `This action requires borrowing of ${ftad(borrowAmount, decimals)} ${token}, but there is only ${ftad(
    borrowingCapacity,
    decimals
  )} ${token} liquidity available in the Kamino Lend market. Reduce your transaction value to complete the action.`;
};
