import c from 'classnames';
import React from 'react';
import { notification } from '../../../features/Notify';

import styles from './styles.module.less';
import { NotificationDescription } from '../../components/HubbleNotification';
import { NotifyArgs } from './types';
import { ENV } from '../../services/web3/types';

import { Text } from '../../uiKitV2/Typography';
import { captureDetailedError } from '../captureDetailedError';
import { TRANSACTION_CANCELED, USER_REJECTED_REQUEST_MESSAGE } from '../../constants/errors';
import { IconType } from '../../../features/Notify/types';
import { NotificationDurations } from '../../constants/notifications';

const getDurationByNotificationType = (type: NotifyArgs['type'], errorWithTransaction = false): number | null => {
  switch (type) {
    case 'success': {
      return NotificationDurations.success;
    }
    case 'loading': {
      return NotificationDurations.loading;
    }
    case 'info': {
      return NotificationDurations.info;
    }
    case 'error': {
      if (errorWithTransaction) {
        return NotificationDurations.errorWithTx;
      }

      return NotificationDurations.errorWithNoTx;
    }
    default: {
      return NotificationDurations.info;
    }
  }
};

// duration = undefined means default auto hide duration
// duration = null means no hiding
// error notifications without transaction reference will always use default auto hide duration
export function notify({
  message = '',
  description = undefined,
  txids = [],
  env = '',
  type = 'info',
  placement = 'bottomLeft',
  multipleTransactions = [],
  key,
  titleIcon,
  className = '',
  errorTxId,
  walletPublicKey,
}: NotifyArgs) {
  const renderDescription = () => {
    if (Array.isArray(description)) {
      return description.map((d) => (
        <Text fs={16} lh={22} color="grey" weight="regular">
          {d}
        </Text>
      ));
    }

    return (
      <Text fs={16} lh={22} color="grey" weight="regular">
        {description}
      </Text>
    );
  };

  // don't show notification error if user reject transaction
  if (
    type === 'error' &&
    typeof description === 'string' &&
    (description.toLowerCase().includes(USER_REJECTED_REQUEST_MESSAGE.toLowerCase()) ||
      description.toLowerCase().includes(TRANSACTION_CANCELED.toLowerCase()))
  ) {
    return null;
  }

  if (type === 'error' && walletPublicKey) {
    let descriptionText = '';

    if (typeof description === 'string') {
      descriptionText = description;
    } else if (Array.isArray(description)) {
      descriptionText = description.join(' ');
    } else if (React.isValidElement(description)) {
      descriptionText = '';
    }
    if (errorTxId) {
      txids?.push(errorTxId);
    }

    captureDetailedError({
      title: message,
      description: descriptionText,
      transactionId: errorTxId,
      userWallet: walletPublicKey,
    });
  }

  const duration = getDurationByNotificationType(type, !!errorTxId);

  notification.open({
    key: key || message,
    type: type as IconType,
    message,
    icon: titleIcon,
    description: (
      <NotificationDescription type={type} txids={txids} multipleTransactions={multipleTransactions} env={env as ENV}>
        {renderDescription()}
      </NotificationDescription>
    ),
    placement,
    className: c(styles.notification, className),
    duration,
  });
}

export function closeNotification(key: string) {
  notification.close(key);
}
