import { ReactElement, SVGProps } from 'react';

export const SvgChartBarVertical = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 11H4a1 1 0 0 0-1 1v8h6v-9Zm11-3h-5v12h6V9a1 1 0 0 0-1-1Zm-6-4h-4a1 1 0 0 0-1 1v15h6V5a1 1 0 0 0-1-1Z"
    />
  </svg>
);
