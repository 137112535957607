import { useCallback } from 'react';
import { useWeb3Client } from './useWeb3Client';

export const useCurrentSlot = () => {
  const { web3client } = useWeb3Client();

  const getCurrentSlot = useCallback(async () => {
    if (!web3client) {
      throw new Error('web3client is required');
    }

    return web3client.connection.getSlot();
  }, [web3client]);

  return {
    getCurrentSlot,
  };
};
