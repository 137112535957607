import { ReactElement, SVGProps } from 'react';

export const SvgEdit = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.667 13.334h10.667M2.667 13.334h2.667l7.528-7.529a.667.667 0 0 0 0-.943L11.138 3.14a.667.667 0 0 0-.942 0l-7.529 7.528v2.667Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
