import { ReactElement, SVGProps } from 'react';

export const SvgWarningTriangleLarge = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
      <path d="m21.526 18.5-8.66-15a1 1 0 0 0-1.732 0l-8.66 15A1 1 0 0 0 3.34 20h17.32a1 1 0 0 0 .866-1.5Z" />
      <path d="M11.95 16h.1v.1h-.1V16ZM12 9v4" />
    </g>
  </svg>
);
