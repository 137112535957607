import { ReactElement, SVGProps } from 'react';

export const SvgReferrals = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.666 13.334c0-1.105 1.492-2 3.333-2 1.841 0 3.334.895 3.334 2M12 9.5c1.177.309 2 1.014 2 1.834M4 9.5c-1.177.309-2 1.014-2 1.834M8 9.334a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 6.825a2 2 0 1 0-2.667-2.981M4 6.825a2 2 0 1 1 2.667-2.981"
      stroke="#49AFE9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
