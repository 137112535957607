import { useQuery } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';
import { PROGRAM_ID } from '@kamino-finance/klend-sdk';
import { fetchMarkets, fetchMarketsFromResources } from '../../../services/lending';
import { QUERYKEYS } from '../../../constants/queryKeys';

import { notify } from '../../../utils/notifications/notifications';
import { featureFlags } from '../../../utils/featureFlags';
import { useWeb3Client } from '../../useWeb3Client';
import { captureError } from '../../../utils/captureError';
// TODO: webworker change, undo if reverted
import { MarketFromResourcesResponse, MarketResponse } from '../../../types/lending';

export const useMarketsFromResourcesQuery = () => {
  const { web3client } = useWeb3Client();

  const stagingProgram = localStorage.getItem('kaminoStaging');
  const programId = stagingProgram ? new PublicKey(stagingProgram) : PROGRAM_ID;

  const queryFn = async () => {
    // TODO: webworker change, undo if reverted
    let res: Array<MarketFromResourcesResponse | MarketResponse> = [];

    try {
      res = await fetchMarketsFromResources({ programId });
    } catch (error) {
      console.error('Loading market through CDN failed');
      res = await fetchMarkets({ programId });
    }

    return res;
  };

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.lendingMarketsFromResources,
    enabled: Boolean(web3client) && featureFlags.isLendingPageEnabled,
    queryFn,
    onError: (err: Error) => {
      captureError(err);
      notify({
        message: 'Failed to load lending market',
        description: 'Lending market data will not be available',
        type: 'error',
      });
    },
    cacheTime: 10 * 60 * 1000, // 10 mins
    staleTime: 5 * 60 * 1000, // 5 mins,
  });

  return {
    data,
    ...rest,
  };
};
