import { FC, useState } from 'react';

import { ReactComponent as RestrictAccessIcon } from './assets/restrict-access-icon.svg';
import { IconButton } from '../../../uiKitV2/IconButton';
import { Icon } from '../../../uiKitV2/Icon';
import s from './Banner.module.less';

const DEFAULT_BANNER = 'kamino.defaultBanner';

interface BannerProps {
  localStorageKey?: string;
  alwaysShowAtInit?: boolean;
}

export const Banner: FC<BannerProps> = ({ children, localStorageKey = DEFAULT_BANNER, alwaysShowAtInit = false }) => {
  const [isBannerClosed, setIsBannerClosed] = useState<boolean>(
    alwaysShowAtInit ? false : window.localStorage.getItem(localStorageKey) === 'true'
  );

  const handleClose = () => {
    setIsBannerClosed(true);
    if (!alwaysShowAtInit) {
      window.localStorage.setItem(localStorageKey, 'true');
    }
  };

  if (isBannerClosed) {
    return null;
  }

  return (
    <div className={s.root}>
      <div className={s.container}>
        <RestrictAccessIcon className={s.icon} />

        {children}

        <IconButton
          onClick={handleClose}
          variant="ghost"
          icon={<Icon name="Close" className={s.icon} color="grey-deep" size={24} />}
        />
      </div>
    </div>
  );
};
