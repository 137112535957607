import { KaminoObligation, KaminoReserve } from '@kamino-finance/klend-sdk';
import Decimal from 'decimal.js';

import { AssetTier } from '@kamino-finance/klend-sdk/dist/idl_codegen/types';
import { ReserveUI, UserTokenAmount } from '../../types/reserves';
import { calcEpochReset, lamportsToNumberDecimal } from '../utils';
import { Token } from '../../constants/tokens';
import { useReserveApys } from '../../hooks/lending/useReserveApys';

const INIT_DATA: ReserveUI = {
  address: '',
  asset: '' as Token,
  borrowApy: 0,
  supplyApy: 0,
  price: 0,
  decimals: 0,
  totalBorrowAmount: new Decimal(0),
  totalSupplyAmount: new Decimal(0),
  maxLtv: 0,
  ltv: new Decimal(0),
  liquidationLtv: 0,
  userAmount: {} as UserTokenAmount,
  borrowFee: new Decimal(0),
  borrowCap: new Decimal(0),
  supplyCap: new Decimal(0),
  mintAddress: '',
  tvl: new Decimal(0),
  depositTvl: 0,
  maxLiquidationBonus: 0,
  minLiquidationBonus: 0,
  utilizationRatio: 0,
  totalLiquidityAmount: new Decimal(0),
  borrowFactor: 0,
  assetTier: '',
  dailyDebtCapLimit: new Decimal(0),
  dailyDebtCapFilled: new Decimal(0),
  debtEpochReset: new Decimal(0),
  dailyWithdrawCapLimit: new Decimal(0),
  dailyWithdrawCapFilled: new Decimal(0),
  withdrawEpochReset: new Decimal(0),
  getEstimatedUR: () => 0,
  getEstimatedSupplyAPR: () => 0,
  getEstimatedBorrowAPR: () => 0,
  flashLoanFee: new Decimal(0),
  utilizationLimitBlockBorrowingAbove: 0,
  depositApy: 0,
};

interface GetReserveStatsParams {
  reserve?: KaminoReserve;
  obligation?: KaminoObligation;
  getReserveApys: ReturnType<typeof useReserveApys>['getReserveApys'];
}

export const getReserveStats = ({ reserve, obligation, getReserveApys }: GetReserveStatsParams): ReserveUI => {
  if (!reserve || !reserve.stats) {
    return INIT_DATA;
  }

  const asset: Token = reserve.stats.symbol as Token;

  const {
    decimals,
    loanToValue,
    reserveBorrowLimit,
    reserveDepositLimit,
    liquidationThreshold,
    maxLiquidationBonus,
    minLiquidationBonus,
    borrowFactor,
  } = reserve.stats;

  // use old names after refactoring to make sure there is no regressions
  const assetOraclePriceUSD = reserve.getOracleMarketPrice();
  const totalBorrows = reserve.getBorrowedAmount();
  const totalSupply = reserve.getTotalSupply();
  const totalLiquidity = reserve.getLiquidityAvailableAmount();
  const borrowFeePercentage = reserve.getBorrowFee();
  const utilizationRatio = reserve.calculateUtilizationRatio();
  const depositTvl = reserve.getDepositTvl();

  const reserveAddress = reserve.address;
  const mintAddress = reserve.getLiquidityMint();

  const reserveApys = getReserveApys(reserve.address);
  // TODO: remove * 100 and keep raw value
  const borrowApy = reserveApys.totalBorrowApy * 100;
  const supplyApy = reserveApys.totalDepositApy * 100;
  const depositApy = reserveApys.lendingDepositApy * 100;
  const totalBorrowAmount = lamportsToNumberDecimal(totalBorrows, decimals);
  const totalSupplyAmount = lamportsToNumberDecimal(totalSupply, decimals);
  const totalLiquidityAmount = lamportsToNumberDecimal(totalLiquidity, decimals);
  const ltv = totalBorrowAmount.div(totalSupplyAmount).mul(100);
  const borrowCap = lamportsToNumberDecimal(reserveBorrowLimit, decimals);

  // daily capacities
  const { debtWithdrawalCap, depositWithdrawalCap, utilizationLimitBlockBorrowingAbove } = reserve.state.config;
  const mintFactor = reserve.getMintFactor();

  const supplyCap = lamportsToNumberDecimal(reserveDepositLimit.toString(), decimals);

  const tvl = totalLiquidityAmount.mul(assetOraclePriceUSD);

  // debt
  const dailyDebtCapLimit = new Decimal(debtWithdrawalCap.configCapacity.toString()).div(mintFactor);
  const dailyDebtCapFilled = new Decimal(debtWithdrawalCap.currentTotal.toString()).div(mintFactor);
  const debtEpochReset = Decimal.max(
    calcEpochReset(
      new Decimal(debtWithdrawalCap.configIntervalLengthSeconds.toString()),
      new Decimal(debtWithdrawalCap.lastIntervalStartTimestamp.toString())
    ),
    0
  );

  // withdraw
  const dailyWithdrawCapLimit = new Decimal(depositWithdrawalCap.configCapacity.toString()).div(mintFactor);
  const dailytWithdrawCapFilled = new Decimal(depositWithdrawalCap.currentTotal.toString()).div(mintFactor);
  const withdrawEpochReset = Decimal.max(
    calcEpochReset(
      new Decimal(depositWithdrawalCap.configIntervalLengthSeconds.toString()),
      new Decimal(depositWithdrawalCap.lastIntervalStartTimestamp.toString())
    ),
    0
  );

  // TODO: Why use this?
  const userReserveAmounts: () => UserTokenAmount = () => {
    if (obligation && Object.keys(obligation).length > 0) {
      const deposits = new Decimal(obligation.deposits ? obligation.deposits.get(reserveAddress)?.amount || 0 : 0);

      const borrows = new Decimal(obligation.borrows ? obligation.borrows.get(reserveAddress)?.amount || 0 : 0);

      const depositDecimal = lamportsToNumberDecimal(deposits.toString(), decimals);
      const borrowDecimal = lamportsToNumberDecimal(borrows.toString(), decimals);

      return {
        deposits: depositDecimal.toNumber(),
        borrows: borrowDecimal.toNumber(),
        ltvRatio: borrowDecimal.div(depositDecimal).mul(100).toNumber(),
      };
    }

    return {
      deposits: 0,
      borrows: 0,
      ltvRatio: 0,
    };
  };

  return {
    address: reserve.address.toString(),
    asset,
    mintAddress: mintAddress.toString(),
    borrowApy,
    supplyApy,
    decimals,
    price: assetOraclePriceUSD.toNumber(),
    totalBorrowAmount,
    totalSupplyAmount,
    maxLtv: loanToValue,
    ltv,
    userAmount: userReserveAmounts(),
    borrowFee: borrowFeePercentage,
    // reserve borrow cap
    borrowCap,
    supplyCap,
    tvl,
    depositTvl: depositTvl.toNumber(),
    liquidationLtv: liquidationThreshold,
    maxLiquidationBonus,
    minLiquidationBonus,
    utilizationRatio,
    totalLiquidityAmount,
    borrowFactor,
    getEstimatedUR: reserve.calcSimulatedUtilizationRatio.bind(reserve),
    getEstimatedBorrowAPR: reserve.calcSimulatedBorrowAPR.bind(reserve),
    getEstimatedSupplyAPR: reserve.calcSimulatedSupplyAPR.bind(reserve),
    assetTier: getAssetTier(reserve.state.config.assetTier),
    dailyDebtCapLimit,
    dailyDebtCapFilled,
    debtEpochReset,
    dailyWithdrawCapLimit,
    dailyWithdrawCapFilled: dailytWithdrawCapFilled,
    withdrawEpochReset,
    flashLoanFee: reserve.getFlashLoanFee(),
    utilizationLimitBlockBorrowingAbove: utilizationLimitBlockBorrowingAbove / 100,
    depositApy,
  };
};

interface GetReservesStatsParams {
  reserves: KaminoReserve[];
  obligation?: KaminoObligation;
  getReserveApys: ReturnType<typeof useReserveApys>['getReserveApys'];
}

export const getReservesStats = ({ reserves, obligation, getReserveApys }: GetReservesStatsParams): ReserveUI[] =>
  reserves.map((reserve) => getReserveStats({ reserve, obligation, getReserveApys }));

const getAssetTier = (assetTier: number): string => {
  switch (assetTier) {
    case AssetTier.Regular.discriminator:
      return 'Regular';
    case AssetTier.IsolatedCollateral.discriminator:
      return 'Isolated Collateral';
    case AssetTier.IsolatedDebt.discriminator:
      return 'Isolated Debt';
    default:
      return '';
  }
};
