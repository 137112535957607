import { ReactElement, SVGProps } from 'react';

export const SvgMoreGrid = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM8 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM14 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM8 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
