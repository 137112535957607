import { FC, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { withStyles } from '@bruitt/classnames';
import s from './Drawer.module.less';
import { noop } from '../../utils';

const sx = withStyles(s);

interface DrawerProps {
  isOpen: boolean;
  position?: 'left' | 'right';
  onClose: () => void;
  elementId?: string;
  closeOnOverlay?: boolean;
  withOverlay?: boolean;
}

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

export const Drawer: FC<DrawerProps> = ({
  isOpen,
  position = 'left',
  onClose,
  children,
  elementId = 'root',
  closeOnOverlay = false,
  withOverlay = true,
}) => {
  const portalElement = document.getElementById(elementId);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);

      if (withOverlay) {
        document.body.style.overflow = 'hidden';
      }

      document.body.style.marginRight = `${getScrollbarWidth()}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.marginRight = '';
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.marginRight = '';
    };
  }, [handleKeyDown, isOpen, withOverlay]);

  if (!portalElement) {
    return null;
  }

  return createPortal(
    <>
      {withOverlay && (
        <div
          className={sx('overlay', {
            overlayOpen: isOpen,
            overlayHidden: !isOpen,
          })}
          onClick={closeOnOverlay ? onClose : noop}
          aria-hidden="true"
        />
      )}
      <div
        className={sx('drawer', {
          animate: isOpen,
          hidden: !isOpen,
          position,
        })}
        tabIndex={-1}
      >
        {children}
      </div>
    </>,
    portalElement
  );
};
