import { ReactElement, SVGProps } from 'react';

export const SvgLitepaper = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#98B0C1" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 14H4a.667.667 0 0 1-.666-.667V2.667c0-.369.298-.667.667-.667h5.042c.185 0 .363.077.489.214l2.958 3.194a.667.667 0 0 1 .177.453v7.472a.667.667 0 0 1-.666.667ZM6 11.334h4M6 9.334h4" />
      <path d="M12.666 6H9.333a.667.667 0 0 1-.667-.667V2" />
    </g>
  </svg>
);
