import React, { FC } from 'react';
import s from '../../MainMode.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { formatNumber } from '../../../../../../shared/utils';
import { SkeletonParagraph } from '../../../../../../shared/uiKit/SkeletonParagraph';
import { TokenWithPrice } from '../../../../types/tokenWithPrice';

const { formatTokenAllDecimals: ftad } = formatNumber;

type BalancesVauleProps = {
  tokens: TokenWithPrice[];
  isBalanceLoading: boolean;
};

export const TotalValue: FC<BalancesVauleProps> = ({ tokens, isBalanceLoading }) => {
  const totalBalance = tokens.reduce((partialSum, a) => partialSum + a.value.toNumber(), 0);
  return (
    <div className={s.balance}>
      {isBalanceLoading ? (
        <SkeletonParagraph width="70%" height={32} />
      ) : (
        <Text fs={32} lh={36}>
          ${ftad(totalBalance, 2)}
        </Text>
      )}
    </div>
  );
};
