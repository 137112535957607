import Decimal from 'decimal.js';
import { PriorityFeeStats, PriorityFeeType } from '../types/priorityFeeStats';
import { MAX_FEE } from '../constants/slippage';
import { lamportsToNumberDecimal } from './utils';
import { DECIMALS_SOL } from '../constants/math';

export const getPriorityFeeForType = (priorityFeeStats: PriorityFeeStats | null | undefined, type: PriorityFeeType) => {
  if (!priorityFeeStats) {
    return new Decimal('0');
  }

  let fee = new Decimal(lamportsToNumberDecimal('10', DECIMALS_SOL));

  if (type === PriorityFeeType.medium) {
    fee = lamportsToNumberDecimal(new Decimal(priorityFeeStats.avg || '0'), DECIMALS_SOL);
  }
  if (type === PriorityFeeType.turbo) {
    fee = lamportsToNumberDecimal(new Decimal(priorityFeeStats.max || '0'), DECIMALS_SOL);
  }

  return Decimal.min(fee, MAX_FEE);
};
