import { WhirlpoolStrategy } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import Decimal from 'decimal.js';
import { calcTotalApyByRange } from '../../utils/kamino';
import { DataMetrics } from '../../types/metrics';

export function getKTokenSuplyApy({
  metricsByStrategy,
  strategiesByAddress,
  tokenMint,
}: {
  metricsByStrategy: Record<string, DataMetrics> | undefined;
  strategiesByAddress: Record<string, WhirlpoolStrategy> | undefined;
  tokenMint: string;
}): Decimal {
  if (!metricsByStrategy || !strategiesByAddress) {
    return new Decimal(0);
  }
  const strategyDataKeyValue = Object.entries(strategiesByAddress).find(
    ([, value]) => value.sharesMint.toString() === tokenMint
  );
  const strategyMetric = strategyDataKeyValue ? metricsByStrategy[strategyDataKeyValue[0]] : undefined;
  return calcTotalApyByRange({
    apyInfo: strategyMetric?.apy ?? null,
    kaminoApyInfo: strategyMetric?.kaminoApy ?? null,
    apyRange: 'apy7d',
  });
}
