import { NightlyConnectAdapter } from '@nightlylabs/wallet-selector-solana';
import { WalletAdapter, WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import {
  Coin98WalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  SolongWalletAdapter,
  TorusWalletAdapter,
  WalletConnectWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { SquadsEmbeddedWalletAdapter, detectEmbeddedInSquadsIframe } from '@sqds/iframe-adapter';
import { TipLinkWalletAdapter } from '@tiplink/wallet-adapter';
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ENV } from '../../shared/constants/tokens';
import { termsStore } from '../../stores/termsStore';

export const WalletsProvider: FC = observer(({ children }) => {
  const { areTermsConfirmed } = termsStore;

  const wallets = useMemo(() => {
    const enabledWallets: WalletAdapter[] = [
      new SolflareWalletAdapter(), // Info: Removing this will disable metamask wallet adapter
      new LedgerWalletAdapter(),
      NightlyConnectAdapter.buildLazy(
        {
          appMetadata: {
            name: 'Kamino',
            description: 'Kamino Finance',
            icon: 'https://app.kamino.finance/mstile-150x150.png',
            additionalInfo: 'Liquidity strategies on Solana',
          },
        },
        true
      ),
      new WalletConnectWalletAdapter({
        network: ENV === 'mainnet-beta' ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet,
        options: {
          relayUrl: 'wss://relay.walletconnect.com',
          // example WC dapp project ID
          projectId: '08850fd42f293570b4ba239c488332ab',
          metadata: {
            name: 'Kamino Finance',
            description: 'Kamino Finance',
            url: 'https://app.kamino.finance',
            icons: ['https://app.kamino.finance/mstile-150x150.png'],
          },
        },
      }),
      new Coin98WalletAdapter(),
      new TorusWalletAdapter({
        params: {
          apiKey: 'BOM5Cl7PXgE9Ylq1Z1tqzhpydY0RVr8k90QQ85N7AKI5QGSrr9iDC-3rvmy0K_hF0JfpLMiXoDhta68JwcxS1LQ',
        },
      }),
      new SolongWalletAdapter(),
      new MathWalletAdapter(),
      new TipLinkWalletAdapter({
        title: 'Kamino Finance',
        clientId: '6f2ba36f-fdae-4db1-9d1e-9c102f10b249',
        theme: 'dark',
      }),
    ];

    return detectEmbeddedInSquadsIframe()
      ? [new SquadsEmbeddedWalletAdapter() as unknown as WalletAdapter]
      : enabledWallets;
  }, []);

  return (
    <WalletProvider wallets={wallets} autoConnect={areTermsConfirmed}>
      {children}
    </WalletProvider>
  );
});
