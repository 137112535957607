import { observer } from 'mobx-react-lite';
import { useWorkers } from '../hooks/useWorkers';

export const WorkerProvider: React.FC = observer(({ children }) => {
  // webworkers initialization
  // TODO: remove this at v3 release
  useWorkers();

  return <>{children}</>;
});
