import { useQuery } from '@tanstack/react-query';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { WhirlpoolStrategy } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import { PublicKey } from '@solana/web3.js';
import { StrategyWithAddress } from '@kamino-finance/kliquidity-sdk';
import { useKaminoSdk } from '../hooks/useKaminoSdk';
import { QUERYKEYS } from '../constants/queryKeys';
import { captureError } from '../utils/captureError';
import { notify } from '../utils/notifications/notifications';
import { useKaminoResourcesQuery } from '../hooks/kamino/queries/useKaminoResourcesQuery';

// this function could reuse the one below and just pass the kaminoResources addresses,
// but that would require storing those addresses in an additional list, and even if we useMemo,
// we'd still need to store one list in every place where this function is called
export function useKaminoResourcesStrategiesQueryCleanedUp(): {
  strategiesByAddress: Record<string, WhirlpoolStrategy> | undefined;
  isLoading: boolean;
} {
  const { kaminoSdk } = useKaminoSdk();
  const { data: kaminoResources } = useKaminoResourcesQuery();

  const { data, isLoading } = useQuery({
    queryKey: QUERYKEYS.getStrategiesCleanedUp('kaminoResources'),
    queryFn: async () => {
      const strategies = Object.keys(kaminoResources?.strategiesData ?? {}).map((key) => new PublicKey(key));
      const response = await kaminoSdk?.getStrategiesWithAddresses(strategies);
      const responseByAddress = keyBy(response, (strategyWithAddress) => strategyWithAddress.address.toString());
      return mapValues(responseByAddress, (strategyWithAddress) => strategyWithAddress.strategy);
    },
    enabled: !!kaminoSdk && !!kaminoResources,
    onError: async (err: Error) => {
      await captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load strategies',
      });
    },
  });
  return { strategiesByAddress: data, isLoading };
}

export function useStrategiesQueryCleanedUp({
  queryKey,
  strategiesAddresses,
}: {
  queryKey: string;
  strategiesAddresses: PublicKey[];
}): {
  strategiesByAddress: Record<string, StrategyWithAddress> | undefined;
  isLoading: boolean;
  dataUpdatedAt: number;
} {
  const { kaminoSdk } = useKaminoSdk();

  const { data, isLoading, dataUpdatedAt } = useQuery({
    queryKey: QUERYKEYS.getStrategiesCleanedUp(queryKey),
    queryFn: async () => {
      const response = await kaminoSdk?.getStrategiesWithAddresses(strategiesAddresses);
      return keyBy(response, (strategyWithAddress) => strategyWithAddress.address.toString());
    },
    enabled: !!kaminoSdk && !!strategiesAddresses.length,
    onError: async (err: Error) => {
      await captureError(err);
      notify({
        type: 'error',
        message: 'Failed to load strategies',
      });
    },
  });
  return { strategiesByAddress: data, isLoading, dataUpdatedAt };
}
