import { useQuery } from '@tanstack/react-query';

import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchObligationTransactionsHistory, fetchUserObligationTransactionsHistory } from '../../../services/lending';
import { ObligationTransactionHistoryResponse, Transaction } from '../../../types/lending';
import useEnv from '../../useEnv';
import { useMarkets } from '../useMarkets';

interface UseObligationTransactionsHistoryQueryProps {
  marketAddress?: string;
  obligationAddress?: string;
  useLogPrices?: boolean;
}

const EMPTY_RES: ObligationTransactionHistoryResponse = {
  transactions: [],
};

export const useObligationTransactionsHistoryQuery = ({
  marketAddress,
  obligationAddress,
  useLogPrices,
}: UseObligationTransactionsHistoryQueryProps) => {
  const queryFn = async () => {
    if (!marketAddress || !obligationAddress) {
      return;
    }

    return fetchObligationTransactionsHistory({ marketAddress, obligationAddress, useLogPrices });
  };

  const { data, ...rest } = useQuery(QUERYKEYS.getObligationTransactionsHistory(obligationAddress || '-'), queryFn, {
    enabled: Boolean(marketAddress && obligationAddress),
  });

  return { data: data || EMPTY_RES, ...rest };
};

export interface UseUserObligationsTransactionsHistoryResponse {
  marketAddress: string;
  data: {
    [key: string]: Transaction[];
  };
}

const EMPTY_DATA: UseUserObligationsTransactionsHistoryResponse[] = [];

export const useUserObligationsTransactionsHistoryQuery = () => {
  const { walletPublicKey } = useEnv();
  const { markets } = useMarkets();
  const enabled = Boolean(walletPublicKey);

  const queryFn = async () => {
    if (!walletPublicKey) {
      return EMPTY_DATA;
    }

    return Promise.all(
      markets.map(({ address }) =>
        fetchUserObligationTransactionsHistory({
          marketAddress: address,
          userAddress: walletPublicKey,
        })
      )
    ).then((results) =>
      results.map((data, index) => {
        return { marketAddress: markets[index].address, data };
      })
    );
  };

  const { data, ...rest } = useQuery(QUERYKEYS.getUserObligationsTransactionsHistory(walletPublicKey), queryFn, {
    enabled,
  });

  return { data: data || EMPTY_DATA, ...rest };
};
