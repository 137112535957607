import { FC, createContext, useContext, useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { Kamino } from '@kamino-finance/kliquidity-sdk';
import useEnv from './useEnv';
import { useWeb3Client } from './useWeb3Client';
import isStagingEnabled from '../utils/stagingProgram';

const KaminoSdkContext = createContext<{ getKaminoSdk: () => Kamino | undefined }>({
  getKaminoSdk: () => {
    throw new Error('KaminoSdkContext not initialized');
  },
});

export const KAMINO_YVAULTS_STAGING_PROGRAM_ID = new PublicKey('SKY3EZaE5p8iXG1ed4kiandK1wnwwqxmWBhjEFykaHB');

export const KaminoSdkContextProvider: FC = ({ children }) => {
  const { env } = useEnv();
  const { web3client } = useWeb3Client();

  const kamino = useMemo(() => {
    if (!web3client) {
      return undefined;
    }
    if (isStagingEnabled) {
      return new Kamino(env, web3client.sendConnection, undefined, KAMINO_YVAULTS_STAGING_PROGRAM_ID);
    }
    return new Kamino(env, web3client.sendConnection);
  }, [env, web3client]);

  const getKaminoSdk = () => kamino;

  return <KaminoSdkContext.Provider value={{ getKaminoSdk }}>{children}</KaminoSdkContext.Provider>;
};

export const useKaminoSdk = () => {
  const { getKaminoSdk } = useContext(KaminoSdkContext);
  return { kaminoSdk: getKaminoSdk() };
};
