/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Wallet } from '@solana/wallet-adapter-react';

import { FC } from 'react';
import c from 'classnames';
import useEnv from '../../hooks/useEnv';

import s from './WalletModal.module.less';
import { addPredefinedWallets, noop, redirectIfWalletIsNotInstalled } from '../../utils';
import { notify } from '../../utils/notifications/notifications';
import { sortByMostPopularWallet } from '../../utils/kamino/sortByMostPopularWallet';
import { Modal } from '../../uiKitV2/Modal';
import { Text } from '../../uiKitV2/Typography';
import { captureError } from '../../utils/captureError';

interface SelectWalletModalProps {
  isVisible: boolean;
  onCancel: () => void;
}

export const SelectWalletModal: FC<SelectWalletModalProps> = ({ isVisible, onCancel }) => {
  const { wallets, wallet, selectWalletAndConnect, disconnectWallet } = useEnv();
  const selectedWalletName = wallet?.adapter.name;

  const walletsWithPredefinedWallets = addPredefinedWallets(wallets);

  const handleSelectedWalletChange = async (selectedWallet: Wallet) => {
    if (redirectIfWalletIsNotInstalled(wallets, selectedWallet)) {
      return;
    }

    try {
      selectWalletAndConnect(selectedWallet.adapter.name);
    } catch (e) {
      notify({
        type: 'error',
        message: 'Failed to connect wallet. Please, try again',
      });
      await disconnectWallet();

      captureError(e);
    } finally {
      onCancel();
    }
  };

  return (
    <Modal isOpen={isVisible} shouldCloseOnOverlayClick onClose={onCancel}>
      <div className={s.container}>
        <div className={s.modalHeader}>
          <Text className={s.textHeader}>Select your wallet</Text>
        </div>

        <div className={s.wallets}>
          {sortByMostPopularWallet(walletsWithPredefinedWallets).map((option) => {
            const walletName = option.adapter.name;
            const walletIcon = option.adapter.icon;
            return (
              <div
                key={walletName}
                className={c({ [s.activeOption]: selectedWalletName === walletName })}
                onClick={() => {
                  handleSelectedWalletChange(option).then(noop).catch(captureError);
                }}
              >
                <div className={s.iconContainer}>
                  <img className={s.walletIcon} src={walletIcon} alt={walletName} />
                  <Text className={s.walletName}>{walletName}</Text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
