import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import navigationHistoryStore from '../../stores/navigationHistoryStore';

/**
 * Make this hook called only once
 */
const useTrackNavigation = () => {
  const history = useHistory();

  useEffect(() => {
    // Add the initial path to the navigation history
    navigationHistoryStore.addPath(history.location.pathname);

    const unlisten = history.listen((location) => {
      navigationHistoryStore.addPath(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);
};

export default useTrackNavigation;
