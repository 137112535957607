import { FC } from 'react';
import s from './Bridge.module.less';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { Text } from '../../../../shared/uiKitV2/Typography';

type BridgeProps = {
  onBack: () => void;
};

export const Bridge: FC<BridgeProps> = ({ onBack }) => {
  return (
    <div className={s.content}>
      <div className={s.title}>
        <IconButton onClick={onBack} size="small" variant="secondary" icon={<Icon name="ArrowLeft" color="white" />} />
        <Text fs={22} lh={32}>
          Bridge
        </Text>
      </div>
    </div>
  );
};
