import { Connection } from '@solana/web3.js';
import { Chain, CHAINS, ENV } from './types';
import { featureFlags, isDeployPreview, isHubbleDeployPreview } from '../../utils';
import { MAINNET_BETA_CHAIN_ID } from '../../constants/env';
import { getSavedCustomRpc } from '../../../providers/customRpc';

function getEnabledChains() {
  let enabledChains = CHAINS;

  if (import.meta.env.VITE_APP_ENV === 'production' && !isDeployPreview()) {
    enabledChains = CHAINS.filter((chain) => chain.name === 'mainnet-beta');
  }

  if (document.location.hostname.includes('devnet')) {
    enabledChains = enabledChains.filter((chain) => chain.endpoint.toLowerCase().includes('rpcpool'));
  }

  if (!featureFlags.isRpcPoolNodesEnabled && !document.location.hostname.includes('kamino.finance')) {
    enabledChains = enabledChains.filter((chain) => !chain.endpoint.toLowerCase().includes('rpcpool'));
  }

  if (isDeployPreview() && !isHubbleDeployPreview()) {
    enabledChains = enabledChains.filter((chain) => !chain.displayName.toLowerCase().includes('triton'));
  }

  const customChain = getSavedCustomRpc();
  if (customChain) {
    enabledChains.push(customChain);
  }

  if (import.meta.env.VITE_APP_CUSTOM_RPC) {
    enabledChains.unshift({
      name: 'mainnet-beta',
      endpoint: import.meta.env.VITE_APP_CUSTOM_RPC,
      wsEndpoint: `${<string>import.meta.env.VITE_APP_CUSTOM_RPC.toString().replace('https://', 'wss://')}/whirligig`,
      chainID: MAINNET_BETA_CHAIN_ID,
      displayName: 'Custom Mainnet',
    });
  }

  return enabledChains;
}

export const ENABLED_CHAINS = getEnabledChains();

export class Web3Client {
  private readonly _connection: Connection;

  private readonly _sendConnection: Connection;

  private readonly _sendConnectionsExtra: Connection[];

  private readonly _endpoint: string;

  private readonly _env: ENV;

  private readonly _chain: Chain;

  constructor(endpoint: Chain | string) {
    let chain: Chain | undefined;
    if (typeof endpoint === 'string') {
      chain = ENABLED_CHAINS.find((value) => value.name === endpoint);
      if (chain === undefined) {
        throw Error(`Invalid environment - ${endpoint}`);
      }
    } else {
      chain = endpoint;
    }

    const sendConnectionsExtra = [];

    if (import.meta.env.VITE_APP_IRONFORGE_RPC) {
      sendConnectionsExtra.push(
        new Connection(import.meta.env.VITE_APP_IRONFORGE_RPC, {
          commitment: 'confirmed',
          wsEndpoint: chain.wsEndpoint,
          confirmTransactionInitialTimeout: 120 * 1000,
        })
      );
    }

    this._chain = chain;
    this._endpoint = chain.endpoint;
    this._env = chain.name;
    // use this connection to get data
    this._connection = new Connection(this._endpoint, {
      commitment: 'processed',
      wsEndpoint: chain.wsEndpoint,
      confirmTransactionInitialTimeout: 120 * 1000,
    });

    // use this one to submit transactions
    this._sendConnection = new Connection(this._endpoint, {
      commitment: 'confirmed',
      wsEndpoint: chain.wsEndpoint,
      confirmTransactionInitialTimeout: 120 * 1000,
    });

    this._sendConnectionsExtra = sendConnectionsExtra;
  }

  get endpoint(): string {
    return this._endpoint;
  }

  get chain(): Chain {
    return this._chain;
  }

  get env(): ENV {
    return this._env;
  }

  get connection(): Connection {
    return this._connection;
  }

  get sendConnection(): Connection {
    return this._sendConnection;
  }

  get sendConnectionsExtra(): Connection[] {
    return this._sendConnectionsExtra;
  }
}
