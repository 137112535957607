export const LABELS = {
  CONNECT_LABEL: 'Connect',
  CREATE_VAULT_TITLE: 'Create New Vault',
  CREATE_VAULT_ACTION: 'Create',
  BORROWING_POWER_USED: 'Borrowing Power Used',
  BORROWING_POWER_VALUE: 'Borrowing Power',
  BORROWED_VALUE: 'You borrowed',
  LIQUIDATION_INFO:
    'This view displays all loans that can be liquidated. A liquidation is a process where borrower collateral does not cover value of the loan. It is represented by health factor falling below 1.0. When a loan is liquidated, an liquidator can purchase collateral at a discount by repaing the portio of the loan. ',
  ACCOUNT_FUNDED: 'Account funded.',
  AIRDROP_FAILED_MESSAGE: 'Airdrop request failed',
  AIRDROP_FAILED_DESCRIPTION: 'Airdrop failed! Check you are not on mainnet or your localnet is running.',
  AIRDROP_REQUESTED_MESSAGE: 'Airdrop Requested',
  AIRDROP_REQUESTED_DESCRIPTION: 'Airdrop request successfully submitted!',
  REPAY_QUESTION: 'How much would you like to repay?',
  REPAY_ACTION: 'Repay',
  RESERVE_STATUS_TITLE: 'Reserve Status & Configuration',
  AUDIT_WARNING:
    'Oyster is an unaudited software project used for internal purposes at the Solana Foundation. This app is not for public use.',
  MENU_HOME: 'Home',
  MENU_LOANS: 'Loans',
  LOANS_INFO: 'Connect to a wallet to view your deposits/loans.',
  TRANSACTIONS_INFO: 'Connect to a wallet to view your transactions.',
  NO_LOANS_NO_DEPOSITS: 'No loans or deposits.',
  MENU_DEPOSIT: 'Deposit',
  DEPOSITS: 'Deposits',
  BORROWS: 'Borrows',
  LOAN_TO_VALUE: 'Loan to value',
  MENU_AIRDROP: 'Airdrop',
  MENU_BORROW: 'Borrow',
  MENU_FARMS: 'Farms',
  MENU_LIQUIDATIONS: 'Liquidate',
  MENU_LEADERBOARD: 'Leaderboard',
  MENU_TRANSACTIONS_HISTORY: 'Transactions history',
  MENU_FEEDBACK: 'Feedback',
  MENU_EARN: 'Earn',
  MENU_STABILITY: 'Stability',
  MENU_STAKE: 'Stake',
  MENU_STATS: 'Stats',
  MARGIN_TRADING: 'Margin Trading',
  PAGE_AIRDROP: 'Airdrop',
  PAGE_LOANS: 'Loans',
  PAGE_LOAN: 'Loan',
  PAGE_STABILITY: 'Stability',
  PAGE_STAKE: 'Stake',
  PAGE_EARN: 'Earn',
  PAGE_FARMS: 'Farms',
  PAGE_AIRDROP_ACTION: 'Airdrop',
  PAGE_AIRDROP_TITLE: 'Airdrop',
  PAGE_AIRDROP_EMOJI: '🎁',
  PAGE_AIRDROP_INFO: 'Airdrop SOL to your non-mainnet account',
  PAGE_BORROW: 'Borrow',
  PAGE_LIQUIDATIONS: 'Liquidations',
  PAGE_LEADERBOARD: 'Leaderboard',
  PAGE_TRASACTIONS_HISTORY: 'Transactions history',
  PAGE_HOME: 'Home',
  PAGE_NOT_FOUND: 'Not Found',
  PAGE_NOT_FOUND_TITLE: 'Page not found',
  PAGE_NOT_FOUND_TEXT: 'This page is deprecated, deleted, or does not exist',
  PAGE_NOT_FOUND_ACTION: 'Back',
  APP_TITLE: 'Kamino Finance',
  CONNECT_BUTTON: 'Connect',
  WALLET_TOOLTIP: 'Wallet public key',
  WALLET_BALANCE: 'Wallet balance',
  SETTINGS_TOOLTIP: 'Settings',
  SELECT_COLLATERAL: 'Select collateral',
  COLLATERAL: 'Collateral',
  BORROW_QUESTION: 'How much would you like to main?',
  BORROW_ACTION: 'Borrow',
  NO_COLLATERAL: 'No collateral',
  NO_DEPOSITS: 'No deposits',
  NO_LOANS: 'No loans',
  NOTHING_BORROWED: 'Nothing borrowed yet',
  NOTHING_BORROWED_MESSAGE:
    "There will be a list of all the assets you have borrowed. For now, it's empty since you have not borrowed.",
  BORROW_NOW: 'Lending now',
  LIQUIDATE_QUESTION: 'How much would you like to liquidate?',
  LIQUIDATE_ACTION: 'Liquidate',
  LIQUIDATE_NO_LOANS: 'There are no loans to liquidate.',
  TABLE_TITLE_ASSET: 'Asset',
  TABLE_TITLE_YOUR_LOAN_BALANCE: 'Loan balance',
  TABLE_TITLE_LOAN_BALANCE: 'Loan balance',
  TABLE_TITLE_COLLATERAL_BALANCE: 'Collateral',
  TABLE_TITLE_DEPOSIT_BALANCE: 'Your deposits',
  TABLE_TITLE_APY: 'APY',
  TABLE_TITLE_LTV: 'LTV',
  TABLE_TITLE_HEALTH: 'Health Factor',
  TABLE_TITLE_BORROW_APY: 'Lending APY',
  TABLE_TITLE_DEPOSIT_APY: 'Deposit APY',
  TABLE_TITLE_TOTAL_BORROWED: 'Total Borrowed',
  TABLE_TITLE_MARKET_SIZE: 'Market Size',
  TABLE_TITLE_ACTION: 'Action',
  TABLE_TITLE_DEPOSITS: 'Deposits',
  TABLE_TITLE_WITHDRAWS: 'Withdraws',
  TABLE_TITLE_BORROWS: 'Borrows',
  TABLE_TITLE_REPAY_OBLIGATIONS: 'Repay Obligations',
  TABLE_TITLE_LIQUIDATE_OBLIGATIONS: 'Liquidate Obligations',
  MAX_LTV: 'Maximum LTV',
  LIQUIDATION_THRESHOLD: 'Liquidation threshold',
  LIQUIDATION_PENALTY: 'Liquidation penalty',
  TABLE_TITLE_MAX_BORROW: 'Available for you',
  LOANS_TITLE_LOANS: 'Loans',
  LOANS_TITLE_DEPOSITS: 'Deposits',
  DEPOSIT_QUESTION: 'How much would you like to deposit?',
  WITHDRAW_ACTION: 'Withdraw',
  WITHDRAW_QUESTION: 'How much would you like to withdraw?',
  LOANS_ACTION: 'Go to loans',
  GO_BACK_ACTION: 'Go back',
  DEPOSIT_ACTION: 'Deposit',
  TOTAL_TITLE: 'Total',
  TRADING_TABLE_TITLE_MY_COLLATERAL: 'Chosen Collateral',
  TRADING_TABLE_TITLE_DESIRED_ASSET: 'Desired Asset',
  TRADING_TABLE_TITLE_MULTIPLIER: 'Leverage',
  TRADING_TABLE_TITLE_ASSET_PRICE: 'Asset Price',
  TRADING_TABLE_TITLE_LIQUIDATION_PRICE: 'Liquidation Price',
  TRADING_TABLE_TITLE_APY: 'APY',
  TRADING_TABLE_TITLE_ACTIONS: 'Action',
  TRADING_ADD_POSITION: 'Add Position',
  MARGIN_TRADE_ACTION: 'Margin Trade',
  MARGIN_TRADE_CHOOSE_COLLATERAL_AND_LEVERAGE: 'Please choose your collateral and leverage.',
  MARGIN_TRADE_QUESTION: 'Please choose how much of this asset you wish to purchase.',
  TABLE_TITLE_BUYING_POWER: 'Total Buying Power',
  NOT_ENOUGH_MARGIN_MESSAGE: 'Not enough buying power in oyster to make this trade at this leverage.',
  SET_MORE_MARGIN_MESSAGE: 'You need more margin to match this leverage amount to make this trade.',
  LEVERAGE_LIMIT_MESSAGE: 'You will need more margin to make this trade.',
  NO_DEPOSIT_MESSAGE: 'You need to deposit coin of this type into oyster before trading with it on margin.',
  NO_COLL_TYPE_MESSAGE: 'Choose Collateral CCY',
  SAFER: 'Safer',
  RISKIER: 'Riskier',
  STAKING_PAGE: 'Staking',
  APPROVE_TROVE_FAILED_MESSAGE: 'ApproveTrove request failed',
  APPROVE_TROVE_FAILED_DESCRIPTION: 'Airdrop failed! Validate exact input variables or your localnet is running.',
  BORROW_STABLECOIN_FAILED_MESSAGE: 'Failed to main USDH!',
  BORROW_STABLECOIN_FAILED_DESCRIPTION: 'Could not loan requested amount',
  CREATE_LOAN_FAILED_MESSAGE: 'Failed to create loan',
  CREATE_LOAN_FAILED_DESCRIPTION: 'Loan creation failed!',
  WITHDRAW_COLLATERAL_FAILED_MESSAGE: 'Failed to withdraw collateral',
  WITHDRAW_COLLATERAL_FAILED_DESCRIPTION: 'Withdrawing collateral failed!',
  DEPOSIT_COLLATERAL_FAILED_MESSAGE: 'Failed to deposit collateral',
  DEPOSIT_COLLATERAL_FAILED_DESCRIPTION: 'Depositing collateral failed!',
  DEPOSIT_LOAN_FAILED_MESSAGE: 'Deposit request failed',
  DEPOSIT_LOAN_FAILED_DESCRIPTION: 'Deposit failed! Validate exact input variables or your localnet is running.',
  STAKING_DEPOSIT_FAILED_MESSAGE: 'Staking deposit failed',
  APPROVE_STAKING_FAILED_MESSAGE: 'ApproveStaking request failed',
  APPROVE_STAKING_FAILED_DESCRIPTION:
    'Approve Staking failed! Validate exact input variables or your localnet is running.',
  STAKING_DEPOSIT_FAILED_DESCRIPTION: 'Staking failed! Validate exact input variables or your localnet is running.',
  UNSTAKING_WITHDRAW_FAILED_MESSAGE: 'Unstaking withdraw failed',
  UNSTAKING_WITHDRAW_FAILED_DESCRIPTION:
    'Unstaking failed! Validate exact input variables or your localnet is running.',
  HARVEST_FAILED_MESSAGE: 'Harvest rewards failed',
  HARVEST_FAILED_DESCRIPTION: 'Harvest rewards failed! Validate exact input variables or your localnet is running.',
  GENERAL_USER_DATA_FAILED_MESSAGE: 'Error when fetching user data',
  GENERAL_USER_DATA_FAILED_DESCRIPTION: 'There was an unexpected error when loading your user data',
  GENERAL_PROGRAM_DATA_FAILED_MESSAGE: 'Error when fetching program data',
  GENERAL_PROGRAM_DATA_FAILED_DESCRIPTION: 'There was an unexpected error when loading your program data',
  REPAY_LOAN_FAILED_MESSAGE: 'Repay loan failed',
  REPAY_LOAN_FAILED_DESCRIPTION: 'Repay loan failed! Validate exact input variables or your localnet is running.',
  TERMS_PAGE: 'Terms Page',
  STATS_PAGE: 'Stats',
  MENU_SWAP: 'Swap',
  PAGE_SWAP: 'Swap',
  MENU_AUTOMINT: 'Auto Mint',
  PAGE_AUTOMINT: 'Auto Mint',
  NOTIFI_POPOVER_TITLE: 'Manage Alerts',
  PAGE_DEFITREASURY: 'Defi Treasury',
  DEFAULT_TITLE: 'Solana Concentrated Liquidity Layer',
  PORTFOLIO_PAGE_TITLE: 'My Portfolio',
  PAGE_TITLES: {
    DEFAULT: 'Solana Concentrated Liquidity Layer | Kamino Finance',
    LENDING: 'Lending | Kamino Finance',
    PORTFOLIO: 'My Portfolio | Kamino Finance',
    MULTIPLY: 'Multiply | Kamino Finance',
    EARN: 'Earn | Kamino Finance',
    LIQUIDITY: 'Liquidity | Kamino Finance',
    LEVERAGE: 'Leverage | Kamino Finance',
    LENDING_DASHBOARD: 'Lending Dashboard | Kamino Finance',
    POINTS: 'Points | Kamino Finance',
    GENESIS: 'KMNO Genesis | Kamino Finance',
    STAKE: 'KMNO Staking | Kamino Finance',
    S2AIRDROP: 'KMNO Season 2 Allocation | Kamino Finance',
    GOVERNANCE_AND_STAKING: 'Governance & Staking | Kamino Finance',
    TRADINGVIEW: 'TradingView | Kamino Finance',
    LIMIT_ORDERS: 'Limit Orders | Kamino Finance',
  },
} as const;

export type LabelsKeys = keyof typeof LABELS;
