import { PriceTooOld } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/errors/custom';

export class PriceTooOldExtended extends PriceTooOld {
  readonly userMsg =
    'If you cannot wait, you can fully close your position by repaying your debt fully and then withdrawing all collateral. This action does not require oracles to be fresh';

  constructor(readonly logs?: string[]) {
    super(logs);
  }
}
