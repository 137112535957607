import { ReactElement, SVGProps } from 'react';

export const SvgHouse = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19.659 9.701-7-6.125a1 1 0 0 0-1.318 0l-7 6.125a1 1 0 0 0-.341.753V19a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-8.546a1 1 0 0 0-.341-.753Z"
    />
  </svg>
);
