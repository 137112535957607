import { ReactElement, SVGProps } from 'react';

export const SvgWarningTriangle = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" {...props}>
    <path
      d="m12.955 10.792-5.052-8.75a.583.583 0 0 0-1.01 0l-5.052 8.75a.583.583 0 0 0 .505.875H12.45c.449 0 .73-.486.505-.875Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.37 9.334h.057v.058H7.37v-.058ZM7.398 5.25v2.333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
