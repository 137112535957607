import { WalletName } from '@solana/wallet-adapter-base';

const LAST_SELECTED_WALLET_KEY = 'kamino.lastSelectedWallet';

export const useLastSelectedWallet = () => {
  const getLastSelectedWalletName = () => {
    return localStorage.getItem(LAST_SELECTED_WALLET_KEY);
  };

  const setLastSelectedWalletName = (name?: WalletName<string> | null) => {
    if (name) {
      localStorage.setItem(LAST_SELECTED_WALLET_KEY, name);
    } else {
      localStorage.removeItem(LAST_SELECTED_WALLET_KEY);
    }
  };

  return {
    setLastSelectedWalletName,
    getLastSelectedWalletName,
  };
};
