import { useCallback } from 'react';
import Decimal from 'decimal.js';
import { usePrices } from '../../../shared/hooks/usePrices';
import useTokens from '../../../shared/hooks/useTokens';
import { formatNumber } from '../../../shared/utils';
import { valueTokenSwitcherStore } from '../../../stores/valueTokenSwitcherStore';

const { format: f, formatUsd: fusd } = formatNumber;

export const useValueTokenSwitch = () => {
  const { tokenMint } = valueTokenSwitcherStore;

  const { getPriceByTokenMintDecimal } = usePrices();
  const { getToken } = useTokens();

  const formatValueInToken = useCallback(
    (amount: number | string | Decimal) => {
      const amountDecimal = new Decimal(amount);

      if (tokenMint === 'usd') {
        return fusd(amountDecimal.toNumber());
      }

      const tokenInfo = getToken(tokenMint);
      const price = getPriceByTokenMintDecimal(tokenMint);

      return `${f(amountDecimal.div(price).toNumber())} ${tokenInfo.symbol}`;
    },
    [getPriceByTokenMintDecimal, getToken, tokenMint]
  );

  return {
    formatValueInToken,
    tokenMint,
  };
};
