import { Decimal } from 'decimal.js';
import { SLOTS_PER_YEAR } from '@kamino-finance/klend-sdk';
import { StrategyBalances } from '../types/balances';

export const calcPercentageOf = (amount: Decimal.Value, percentage: number, percentageFactor = 100): number => {
  // (amount * percentage) / percentageFactor;
  return new Decimal(amount).mul(percentage).div(percentageFactor).toNumber();
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function calculateApy(annualizedReturns: number, investment: number): number {
  const numberOfPeriods = 365;
  const apr = annualizedReturns / investment;
  const apy = ((1.0 + apr / numberOfPeriods) ** numberOfPeriods - 1) * 100;
  return apy;
}

export function calculateApr(annualizedReturns: number, investment: number): number {
  const apr = (annualizedReturns / investment) * 100;
  return apr;
}

export function calculateAPYFromAPR(apr: number) {
  const apy = new Decimal(1).plus(new Decimal(apr).dividedBy(SLOTS_PER_YEAR)).toNumber() ** SLOTS_PER_YEAR - 1;
  return apy;
}

export const strategyUserTokensDeposited = ({
  balances,
  shares,
  totalIssued,
}: {
  balances: Pick<StrategyBalances, 'total'>;
  shares: Decimal;
  totalIssued: Decimal;
}) => {
  const userPartCoef = shares.div(totalIssued);

  return {
    tokenA: new Decimal(balances.total.a).mul(userPartCoef),
    tokenB: new Decimal(balances.total.b).mul(userPartCoef),
  };
};

export const isZeroOrNegativeDecimal = (number: Decimal) => {
  return number.eq(0) || number.lt(0);
};
