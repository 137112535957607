import { ReactElement, SVGProps } from 'react';

export const SvgUnfoldMore = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m16 15.5-4 4-4-4M8 9.5l4-4 4 4"
      stroke="#5F7183"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
