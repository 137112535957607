import { ReactElement, SVGProps } from 'react';

export const SvgArrowRightBig = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.666 4.667 13.999 8l-3.333 3.333"
    />
  </svg>
);
