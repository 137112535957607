import { PublicKey } from '@solana/web3.js';
import { QueryFunction, useQuery, useQueryClient } from '@tanstack/react-query';
import { ShareDataWithAddress } from '@kamino-finance/kliquidity-sdk';
import { CollateralInfos } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import keyBy from 'lodash/keyBy';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { useKaminoSdk } from '../../useKaminoSdk';
import { notify } from '../../../utils/notifications/notifications';
import { captureError } from '../../../utils/captureError';
import { useStrategiesQueryCleanedUp } from '../../../queriesCleanedUp/useStrategiesQueryCleanedUp';

const EMPTY_DATA_MAP: Record<string, ShareDataWithAddress> = {};
const EMPTY_ADDRESSES: PublicKey[] = [];

export const useStrategiesSharesDataQuery = ({
  queryKey,
  addresses = EMPTY_ADDRESSES,
}: {
  addresses?: PublicKey[];
  queryKey: string;
}) => {
  const { kaminoSdk } = useKaminoSdk();
  const queryClient = useQueryClient();
  const {
    strategiesByAddress,
    isLoading: isLoadingStrategies,
    dataUpdatedAt: strategiesUpdatedAt,
  } = useStrategiesQueryCleanedUp({
    queryKey,
    strategiesAddresses: addresses,
  });

  const queryFn: QueryFunction<Record<string, ShareDataWithAddress>> = async () => {
    if (!kaminoSdk) {
      throw new Error('Wallet is not connected');
    }

    if (!strategiesByAddress) {
      return EMPTY_DATA_MAP;
    }

    const collateralInfos: CollateralInfos | undefined = queryClient.getQueryData(QUERYKEYS.collateralInfos);

    const strategiesShareData = await kaminoSdk.getStrategiesShareData(
      addresses,
      Object.values(strategiesByAddress),
      collateralInfos?.infos
    );
    return keyBy(strategiesShareData, (item) => item.address.toString());
  };

  const { data, isLoading: sharesDataIsLoading } = useQuery<Record<string, ShareDataWithAddress>>({
    // use strategiesUpdatedAt to make this query dependent on the first one
    queryKey: QUERYKEYS.getStrategiesShareData(queryKey, strategiesUpdatedAt),
    queryFn,
    enabled: Boolean(kaminoSdk) && !isLoadingStrategies,
    refetchInterval: 60_000,
    onError: (e) => {
      notify({
        type: 'error',
        message: 'Failed to load strategies balances',
      });
      captureError(e);
    },
  });

  return {
    data: data || EMPTY_DATA_MAP,
    isLoading: isLoadingStrategies || sharesDataIsLoading,
  };
};
