import { ReactElement, SVGProps } from 'react';

export const SvgMenu = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11 18a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0-6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0-6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
    />
  </svg>
);
