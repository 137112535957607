import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FarmState } from '@hubbleprotocol/farms-sdk/src/rpc_client/accounts';
import { PublicKey } from '@solana/web3.js';
import { KaminoReserve, ReserveRewardYield } from '@kamino-finance/klend-sdk';
import { PublicKeyAddress } from '../../../types/strategies';
import { useWeb3Client } from '../../useWeb3Client';
import { captureError } from '../../../utils/captureError';
import { notify } from '../../../utils/notifications/notifications';
import { isValidPubkey, notEmpty } from '../../../utils';
import { useReserves } from '../useReserves';
import { FarmStateWithApy } from '../../../types/farms';
import { usePrices } from '../../usePrices';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { useRawPricesQuery } from '../../queries/useRawPricesQuery';

interface UseReserveRewardsQueryProps {
  farmsMints?: PublicKeyAddress[];
  marketAddress: string;
}

const cacheParams = {
  cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
  staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
};

type ReserveAddressToRewardsMap = Record<string, ReserveRewardYield[]>;

const EMPTY_FARMS_MINTS: PublicKeyAddress[] = [];

const useReserveFarmsQuery = ({ farmsMints = EMPTY_FARMS_MINTS, marketAddress }: UseReserveRewardsQueryProps) => {
  const { web3client } = useWeb3Client();
  const { isSuccess } = usePrices();
  const { data: prices } = useRawPricesQuery();
  const { getReservesByMarketAddress } = useReserves();
  const reserves = getReservesByMarketAddress(marketAddress);
  const queryClient = useQueryClient();

  const filteredMints = farmsMints
    .filter((pubkey) => pubkey && pubkey.toString() !== PublicKey.default.toString())
    .map((pubkey) => new PublicKey(pubkey));

  const query = useQuery({
    queryKey: QUERYKEYS.getFarmsQuery(filteredMints),
    queryFn: async () => {
      if (!web3client) {
        throw new Error('Wallet is not connected');
      }

      const farmStates = (await FarmState.fetchMultiple(web3client.connection, filteredMints)).filter(notEmpty);

      const reservesLookup = reserves.reduce((sum, reserve) => {
        const { farmDebt, farmCollateral } = reserve.state;

        if (isValidPubkey(farmDebt)) {
          sum[farmDebt.toString()] = reserve;
        }

        if (isValidPubkey(farmCollateral)) {
          sum[farmCollateral.toString()] = reserve;
        }
        return sum;
      }, {} as Record<string, KaminoReserve>);

      let allMarketReservesRewards: ReserveRewardYield[][] = [];

      if (prices) {
        const allReservesRewardsPromises = reserves.map((reserve) => reserve.getRewardYields(prices));
        allMarketReservesRewards = await Promise.all(allReservesRewardsPromises);
      }

      const reserveToRewards: ReserveAddressToRewardsMap = {};
      reserves.forEach((reserve, index) => {
        reserveToRewards[reserve.address.toString()] = allMarketReservesRewards[index];
      });

      farmStates.forEach((farm, index) => {
        const address = filteredMints[index];
        const reserve = reservesLookup[address.toString()];

        const farmWithApy: FarmStateWithApy = farm;
        // calc and fee APY for each reward token
        if (reserve) {
          farmWithApy.rewardInfos.forEach((reward) => {
            const rewardWithYield = reserveToRewards[reserve.address.toString()].find((queryReward) =>
              queryReward.rewardInfo.rewardsVault.equals(reward.rewardsVault)
            );
            if (rewardWithYield) {
              reward.apy = rewardWithYield?.apy.toNumber();
            }
          });
        }

        const queryKey = QUERYKEYS.getFarm(address);
        queryClient.setQueryDefaults(queryKey, { ...cacheParams });
        queryClient.setQueryData(queryKey, farmWithApy);
      });

      return farmStates;
    },
    enabled: Boolean(web3client) && Boolean(farmsMints) && Boolean(farmsMints.length) && isSuccess,
    ...cacheParams,
    onError: (err) => {
      captureError(err);

      notify({
        message: 'Failed to load reserves rewards',
        type: 'error',
      });
    },
  });

  return { ...query };
};

export default useReserveFarmsQuery;
