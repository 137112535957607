import { useMemo } from 'react';
import Decimal from 'decimal.js';
import { TokenWithPrice } from '../types/tokenWithPrice';
import { ZERO_DECIMAL } from '../constants/constants';
import useTokens from '../../../shared/hooks/useTokens';
import { usePrices } from '../../../shared/hooks/usePrices';
import { useUserAtaBalances } from './useUserAtaBalances';
import { useNativeBalanceQuery } from '../../UnwrapSolNotification/hooks/useNativeBalanceQuery';
import { lamportsToNumberDecimal } from '../../../shared/utils';
import { DECIMALS_SOL } from '../../../shared/constants/math';
import { SOL_MINT } from '../../../shared/constants/mints';

type UseTokensWithPricesProps = {
  isSolWsolSeparated?: boolean;
};
export const useTokensWithPrices = ({ isSolWsolSeparated }: UseTokensWithPricesProps) => {
  const { data: balances, isLoading, isFetched } = useUserAtaBalances({ isSolWsolSeparated });
  const { tokensInfoMap } = useTokens();
  const { getPriceByTokenMintDecimal } = usePrices();
  const tokens = Object.values(tokensInfoMap) || [];
  const { data: nativeSolBalanceLamports } = useNativeBalanceQuery();
  const nativeSolBalance = lamportsToNumberDecimal(nativeSolBalanceLamports, DECIMALS_SOL);
  const { getToken } = useTokens();
  const wsol = getToken(SOL_MINT);
  const wsolPrice = getPriceByTokenMintDecimal(SOL_MINT);

  const mints = Object.keys(balances);

  const tokensWithPrices = useMemo(() => {
    const preparedTokens: TokenWithPrice[] = [];
    tokens.forEach((token) => {
      if (!mints.includes(token.address)) {
        preparedTokens.push({
          ...token,
          amount: ZERO_DECIMAL,
          price: ZERO_DECIMAL,
          value: ZERO_DECIMAL,
        });
      } else {
        const amount = balances[token.address];
        const price = getPriceByTokenMintDecimal(token.address);
        const value = getPriceByTokenMintDecimal(token.address).mul(balances[token.address]);

        preparedTokens.push({
          ...token,
          amount,
          price,
          value,
        });
      }
    });
    // We should add native solana as token if we separated its balance from wsol
    if (isSolWsolSeparated) {
      preparedTokens.push({
        ...wsol,
        name: 'Solana',
        amount: nativeSolBalance,
        value: wsolPrice.mul(new Decimal(nativeSolBalance)),
        price: wsolPrice,
        isNativeSolana: true,
      });
    }
    preparedTokens.sort((tokenA, tokenB) => {
      return tokenB.price.mul(tokenB.amount).sub(tokenA.price.mul(tokenA.amount)).toNumber();
    });
    return preparedTokens;
  }, [balances, getPriceByTokenMintDecimal, isSolWsolSeparated, mints, nativeSolBalance, tokens, wsol, wsolPrice]);
  return { tokens: tokensWithPrices, isLoading, isFetched };
};
