import { FC } from 'react';
import { withStyles } from '@bruitt/classnames';
import spinIcon from './assets/ico.svg';

import s from './Spinner.module.less';

const sx = withStyles(s);

export interface SpinnerProps {
  size?: number;
  className?: string;
  isSpinDisabled?: boolean;
}

export const Spinner: FC<SpinnerProps> = ({ size = 24, className, isSpinDisabled }) => (
  <img
    className={sx(s.root, className, { spinDisabled: isSpinDisabled })}
    src={spinIcon}
    width={size}
    height={size}
    alt="spinner"
  />
);
