import { ReactElement, SVGProps } from 'react';

export const SvgDollarCircleList = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.5 5.833H3.167M4.5 11.167H3.167M4.5 8.5h-2m2.667-4.99a6 6 0 1 1 0 9.98m4.666-6.657c-.333-.082-.876-.085-1.333-.082m0 0c-.153 0-.06-.006-.267 0-.538.016-1.065.24-1.066.874-.002.675.666.875 1.333.875.667 0 1.333.154 1.333.875 0 .542-.538.779-1.209.858H8.5m0-3.482v-.918m0 4.4c-.453.002-.72.01-1.333-.066m1.333.066v.934"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
