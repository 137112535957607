import { ReactElement, SVGProps } from 'react';

export const SvgKmnoLogo = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={13} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m9.046 8.901-3.227-2.15V5.005a.262.262 0 0 0-.26-.26c-.14 0-.258.118-.258.26v4.103c0 .142.118.26.259.26.14 0 .259-.118.259-.26V7.373l2.939 1.96c.118.078.278.05.359-.072l.016-.024V9.23a.254.254 0 0 0-.087-.328Z"
      fill="#FFF"
      stroke="#FFF"
      strokeWidth={0.25}
    />
    <path
      d="m6.784 6.362.001.002.002.002.037.056a.226.226 0 0 0 .309.065L9.041 5.21a.227.227 0 0 0 .064-.31l-.038-.057a.226.226 0 0 0-.308-.064L6.85 6.056a.226.226 0 0 0-.067.306Z"
      fill="#FFF"
    />
    <path
      d="M9.04 5.211 7.134 6.487a.226.226 0 0 1-.309-.065l-.037-.056-.002-.002v-.002a.226.226 0 0 1 .066-.306m2.19-.845Zm0 0a.227.227 0 0 0 .064-.31m0 0-.08.053m.08-.053-.038-.057a.226.226 0 0 0-.308-.064L6.85 6.056m0 0Z"
      stroke="#FFF"
      strokeWidth={0.25}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.974.336a6.688 6.688 0 0 1 5.574 2.737l.004.006.004.006a.362.362 0 0 1 .021.352.347.347 0 0 1-.582.056l-.003-.005-.004-.005a6.007 6.007 0 0 0-4.826-2.455 5.928 5.928 0 0 0-4.306 1.837 5.912 5.912 0 0 0-1.665 4.37M6.974.336a6.62 6.62 0 0 0-4.554 1.99 6.655 6.655 0 0 0 0 9.352 6.629 6.629 0 0 0 4.748 1.99c2.114 0 4.13-1.02 5.385-2.737a.347.347 0 0 0 .03-.364l-.002-.004-.002-.004a.35.35 0 0 0-.584-.054l-.003.004-.004.005a5.971 5.971 0 0 1-5.071 2.449c-3.093-.123-5.61-2.64-5.726-5.728"
      fill="#FFF"
    />
  </svg>
);
