import { useState } from 'react';

export const usePriceImpactWarningModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleModalVisibleChange = (isVisible: boolean) => {
    setModalVisible(isVisible);
  };

  return {
    isModalVisible,
    onModalVisibleChange: handleModalVisibleChange,
  };
};
