import axios from 'axios';
import { TokenInfo, TokenPriceHistoryResponse } from '../types/token-info';
import { ENV } from './web3/types';
import { PublicKeyAddress } from '../types/strategies';
import { captureError } from '../utils/captureError';
import { API_URL } from '../constants/api';
import { CUSTOM_SYMBOLS } from '../constants/tokens';
import { HARDCODED_TOKEN_INFOS } from '../constants/tokenInfo';

export interface FetchTokenPriceHistoryProps {
  env: ENV;
  tokenMint: PublicKeyAddress;
  start: Date;
  end: Date;
  frequency?: 'minute' | 'hour' | 'day';
}

export const fetchTokenPriceHistory = ({
  env,
  tokenMint,
  start,
  end,
  frequency,
}: FetchTokenPriceHistoryProps): Promise<TokenPriceHistoryResponse> => {
  const startDateFormat = start.toISOString();
  const endDateFormat = end.toISOString();

  return axios
    .get(`${API_URL}/prices/history`, {
      params: { env, token: tokenMint.toString(), start: startDateFormat, end: endDateFormat, frequency },
    })
    .then((res) => res.data)
    .catch(captureError);
};

// export const fetchTokenInfos = async () => {
//   const { data } = await axios.get<TokenInfosApiResponse[]>('https://api.kamino.finance/tokens');
//   return data
//     .map((item) => {
//       return {
//         ...item,
//         tag: new Set(item.tags),
//         symbol: CUSTOM_SYMBOLS[item.address] || item.symbol,
//       } as unknown as TokenInfo;
//     })
//     .concat(HARDCODED_TOKEN_INFOS);
// };

export const fetchTokenInfos = async () => {
  const data: TokenInfo[] = [];
  const combinedData = data
    .map((item) => {
      return {
        ...item,
        tag: new Set(item.tags),
        symbol: CUSTOM_SYMBOLS[item.address] || item.symbol,
      } as unknown as TokenInfo;
    })
    .concat(HARDCODED_TOKEN_INFOS);
  return Promise.resolve(combinedData);
};
