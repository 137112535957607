import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { PriorityFeeType } from '../shared/types/priorityFeeStats';
import {
  getlastPositionUpdateAtIfSet,
  getUserPriorityFeeIfSet,
  getUserPriorityFeeTypeIfSet,
  getUserSlippageIfSet,
} from '../shared/utils/transactionSettings';
import { getItemFromLocalStrorage } from '../shared/hooks/useLocalStorageState';
import { DEFAULT_SLIPPAGE_JLP_MULTIPLY_PCT, DEFAULT_SLIPPAGE_MULTIPLY_PCT } from '../shared/constants/slippage';
import {
  LAST_POSITION_UPDATE_AT,
  USER_TRANSACTION_PRIORITY_FEE,
  USER_TRANSACTION_PRIORITY_FEE_TYPE,
  USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT,
  USER_TRANSACTION_SLIPPAGE,
  USER_TRANSACTION_SLIPPAGE_UPDATED_AT,
} from '../shared/constants/settings';

export interface LayoutProviderParams {
  isMobileMenuOpened: boolean;
  isSidePanelRendered: boolean;
  isSidePanelOpened: boolean;
  priorityFeeType: PriorityFeeType;
  priorityFee: Decimal;
  isTransactionSettingsModalVisible: boolean;
  slippage: Decimal;
  // separate slippage value for Multiply
  // we do not save in LC but if we change it in the settings
  // the new settings is applied for Multiply Slippage as well
  slippageMultiply: Decimal;
  slippageJlpMultiply: Decimal;
  isSpecialTransactionSettingsApplied: boolean;
  isVersionedTxEnabled: boolean;
  isNotificationBannerVisible: boolean;
  isNotificationModalVisible: boolean;
  isTransactionInProgress: boolean;
  lastPositionUpdateAt?: string;
  isMultiplyLtvInfoModalOpen: boolean;
  isMultiplyNetApyInfoModalOpen: boolean;
  areWhatsNewIndicatorsEnabled: boolean;
}

export interface LayoutProviderStore extends LayoutProviderParams {
  updateProviderParams: (params: Partial<LayoutProviderStore>, isUpdateLocalStorage?: boolean) => void;
  resetProviderParams: () => void;
}

export const getLayoutProviderDefaultValue = (): LayoutProviderParams => ({
  isMobileMenuOpened: false,
  isSidePanelRendered: false,
  isSidePanelOpened: false,
  isTransactionSettingsModalVisible: false,
  priorityFeeType: getUserPriorityFeeTypeIfSet(),
  priorityFee: getUserPriorityFeeIfSet(),
  slippage: getUserSlippageIfSet(),
  slippageMultiply: new Decimal(DEFAULT_SLIPPAGE_MULTIPLY_PCT),
  slippageJlpMultiply: new Decimal(DEFAULT_SLIPPAGE_JLP_MULTIPLY_PCT),
  isVersionedTxEnabled: true,
  isNotificationBannerVisible: getItemFromLocalStrorage('isNotificationBannerVisible', true),
  isNotificationModalVisible: false,
  isSpecialTransactionSettingsApplied: false,
  isTransactionInProgress: false,
  lastPositionUpdateAt: getlastPositionUpdateAtIfSet(),
  isMultiplyLtvInfoModalOpen: false,
  isMultiplyNetApyInfoModalOpen: false,
  areWhatsNewIndicatorsEnabled: false,
});

const store = makeAutoObservable<LayoutProviderStore>({
  ...getLayoutProviderDefaultValue(),

  // arrow function and 'store' object are used to prevent missing "this" context
  updateProviderParams: (params: Partial<LayoutProviderParams>, isUpdateLocalStorage = false) => {
    if (isUpdateLocalStorage) {
      if (params.slippage) {
        localStorage.setItem(USER_TRANSACTION_SLIPPAGE, params.slippage.toString());
        localStorage.setItem(USER_TRANSACTION_SLIPPAGE_UPDATED_AT, Date.now().toString());
        params.isSpecialTransactionSettingsApplied = false;
      }
      if (params.priorityFeeType) {
        localStorage.setItem(USER_TRANSACTION_PRIORITY_FEE_TYPE, params.priorityFeeType.toString());
        params.isSpecialTransactionSettingsApplied = false;
      }
      if (params.priorityFee) {
        localStorage.setItem(USER_TRANSACTION_PRIORITY_FEE, params.priorityFee.toString());
        localStorage.setItem(USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT, Date.now().toString());
        params.isSpecialTransactionSettingsApplied = false;
      }
      if (params.lastPositionUpdateAt) {
        localStorage.setItem(LAST_POSITION_UPDATE_AT, params.lastPositionUpdateAt.toString());
      }
    }

    Object.assign(store, params);
  },

  resetProviderParams: () => {
    Object.assign(store, { ...getLayoutProviderDefaultValue(), isSidePanelRendered: store.isSidePanelRendered });
  },
});

export { store as layoutProviderStore };
