import { useEffect } from 'react';
import { History } from 'history';
import { useGetReferrerByReferalUrl } from './useGetReferrerByReferalUrl';
import paths from '../routes/paths';

export const REFERRER_LS_KEY = 'referrerWallet';

export const useParseReferalUrl = (history: History) => {
  const queryParams = new URLSearchParams(window.location.search);
  const referralId = queryParams.get('ref');

  const { data: referalWallet } = useGetReferrerByReferalUrl(referralId || undefined);

  useEffect(() => {
    if (referalWallet) {
      localStorage.setItem(REFERRER_LS_KEY, referalWallet.toString());
      history.replace(paths.MAIN);
    }
  }, [history, referalWallet]);
};
