import { ReactElement, SVGProps } from 'react';

export const SvgVote = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 21C.5 32.303 9.697 41.5 21 41.5S41.5 32.303 41.5 21 32.303.5 21 .5.5 9.697.5 21Z"
      fill="url(#vote_svg__a)"
      fillOpacity={0.2}
      stroke="#212C42"
    />
    <path
      d="M21 36c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
      fill="url(#vote_svg__b)"
    />
    <path
      opacity={0.3}
      d="M21 31.605c5.858 0 10.606-4.748 10.606-10.606S26.858 10.393 21 10.393 10.394 15.14 10.394 20.999 15.142 31.605 21 31.605Z"
      fill="url(#vote_svg__c)"
      stroke="#98B0C1"
      strokeMiterlimit={10}
    />
    <path d="m15.667 21 3.3 3.3 7.07-7.072" stroke="url(#vote_svg__d)" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="vote_svg__a" x1={21} y1={1} x2={21} y2={41} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="vote_svg__b" x1={21} y1={6} x2={21} y2={36} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="vote_svg__c" x1={21} y1={31.605} x2={21} y2={10.393} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="vote_svg__d" x1={20.852} y1={16.521} x2={20.852} y2={26.067} gradientUnits="userSpaceOnUse">
        <stop stopColor="#212C42" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
    </defs>
  </svg>
);
