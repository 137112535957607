import { ReactElement, SVGProps } from 'react';

export const SvgCheckBigGroup = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={38} height={39} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.5 19.5C.5 29.7 8.8 38 19 38s18.5-8.3 18.5-18.5S29.2 1 19 1 .5 9.3.5 19.5Z"
      fill="url(#check_big_group_svg__a)"
      fillOpacity={0.2}
      stroke="#212C42"
    />
    <path
      d="M19 34.5c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Z"
      fill="url(#check_big_group_svg__b)"
    />
    <path
      opacity={0.3}
      d="M19 30.105c5.858 0 10.606-4.748 10.606-10.606S24.858 8.893 19 8.893 8.394 13.64 8.394 19.499 13.142 30.105 19 30.105Z"
      fill="url(#check_big_group_svg__c)"
      stroke="#98B0C1"
      strokeMiterlimit={10}
    />
    <path
      d="m13.666 19.5 3.3 3.3 7.071-7.072"
      stroke="url(#check_big_group_svg__d)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient id="check_big_group_svg__a" x1={19} y1={1.5} x2={19} y2={37.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="check_big_group_svg__b" x1={19} y1={4.5} x2={19} y2={34.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient id="check_big_group_svg__c" x1={19} y1={30.105} x2={19} y2={8.893} gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#98B0C1" />
      </linearGradient>
      <linearGradient
        id="check_big_group_svg__d"
        x1={13.809}
        y1={15.363}
        x2={25.555}
        y2={15.363}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212C42" />
        <stop offset={1} stopColor="#8A98A3" />
      </linearGradient>
    </defs>
  </svg>
);
