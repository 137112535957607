import { useMemo } from 'react';
import { useValueTokenSwitch } from '../../ValueTokenSwitcherContext';
import { useTransactionsHistoryQuery } from '../../../shared/hooks/kamino/queries/useTransactionsHistoryQuery';
import useEnv from '../../../shared/hooks/useEnv';
import { getVaultNameQuoteSort } from '../../../shared/utils/kamino';
import { formatNumber } from '../../../shared/utils';
import { TransactionHistoryTableData, TRANSACTIONS_TYPE } from '../types';
import { formatDate } from '../../../pages/StrategyV2/utils';
import { PublicKeyAddress } from '../../../shared/types/strategies';
import useTokens from '../../../shared/hooks/useTokens';

const { formatShortName: fsn, format: f } = formatNumber;

interface UseTransactionsHistory {
  strategyAddress?: PublicKeyAddress;
  sorted?: boolean;
}

const EMPTY_DATA: TransactionHistoryTableData[] = [];

// Loads user transactions history and filters by strategy address
// If strategy address is not provied returns all transactions
export const useTransactionsHistory = ({ strategyAddress, sorted = true }: UseTransactionsHistory) => {
  const { env } = useEnv();
  const { getToken } = useTokens();
  const { formatValueInToken } = useValueTokenSwitch();

  const { data, isLoading } = useTransactionsHistoryQuery();

  const currentStrategyTransactions = useMemo(() => {
    return strategyAddress ? data?.filter((t) => t.strategy === strategyAddress) : data;
  }, [strategyAddress, data]);

  const tableData: TransactionHistoryTableData[] = useMemo(() => {
    const sortedTransactions = sorted
      ? currentStrategyTransactions?.sort((a, b) => b.timestamp - a.timestamp)
      : currentStrategyTransactions;

    return sortedTransactions.map((t) => {
      const {
        tokenAMint,
        tokenBMint,
        timestamp,
        transactionSignature,
        transactionName,
        usdValue,
        tokenAAmount,
        tokenBAmount,
      } = t;

      const tokenAInfo = getToken(tokenAMint);
      const tokenBInfo = getToken(tokenBMint);

      return {
        id: transactionSignature,
        date: formatDate(timestamp),
        timestamp,
        usdValue: formatValueInToken(usdValue),
        transactionViewHref: `https://solscan.io/tx/${transactionSignature}${
          !env || env === 'mainnet-beta' ? '' : `?cluster=${env}`
        }`,
        tokens: [tokenAInfo.symbol, tokenBInfo.symbol],
        name: getVaultNameQuoteSort(tokenAInfo.symbol, tokenBInfo.symbol),
        transactionName: TRANSACTIONS_TYPE[transactionName],
        type: 'liquidity',
        transactions: [
          {
            token: tokenAInfo.symbol,
            tokenMint: tokenAMint,
            price: fsn(tokenAAmount),
            tooltipAmount: f(tokenAAmount),
          },
          {
            token: tokenBInfo.symbol,
            tokenMint: tokenBMint,
            price: fsn(tokenBAmount),
            tooltipAmount: f(tokenBAmount),
          },
        ],
      };
    });
  }, [currentStrategyTransactions, env, formatValueInToken, getToken, sorted]);

  return {
    data: tableData || EMPTY_DATA,
    isLoading,
  };
};
