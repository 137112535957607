import { makeAutoObservable } from 'mobx';
import { SortingState } from '@tanstack/react-table';
import { DateRange } from 'react-day-picker';
import { PointsTab, SeasonTab } from './constants/pointsTabs';
import { CHART_DAYS_PRESETS } from '../../shared/constants/graphs';

const getDefaultParams = () => {
  const params = new URLSearchParams(document.location.search);
  const tab = params.get('tab') as PointsTab;
  const seasonTab = params.get('season_tab') as SeasonTab;

  const breakdownSort = params.get('breakdownSortBy') || [{ id: 'totalPoints', desc: true }];
  const leaderboardSort = params.get('leaderboardSortBy') || [{ id: 'liquidityPoints', desc: true }];
  const currentPointBoostsSort = params.get('currentPointBoostsSortBy') || [{ id: 'boost', desc: true }];
  const pointsAllocationSort = params.get('pointsAllocationSortBy') || [{ id: 'points', desc: true }];

  return {
    tab: Object.values(PointsTab).includes(tab) ? tab : PointsTab.leaderboard,
    seasonTab: Object.values(SeasonTab).includes(seasonTab) ? seasonTab : SeasonTab.season3,
    breakdownSortBy: breakdownSort as SortingState,
    leaderboardSortBy: leaderboardSort as SortingState,
    currentPointBoostsSortBy: currentPointBoostsSort as SortingState,
    pointsAllocationSortBy: pointsAllocationSort as SortingState,
    period: {
      from: CHART_DAYS_PRESETS[0].date,
      to: new Date(),
    },
    page: 1,
    pageSize: 50,
    totalPointsRealTime: 0,
    globalPointsRealTime: 0,
    totalPointsPortfolioPreviewRealTime: 0,
    totalPointsRealTimeS2: 0,
    globalPointsRealTimeS2: 0,
    totalPointsPortfolioPreviewRealTimeS2: 0,
    totalPointsRealTimeS3: 0,
    globalPointsRealTimeS3: 0,
    totalPointsPortfolioPreviewRealTimeS3: 0,
  };
};

interface Params {
  // TODO: update type when table data type will be ready
  breakdownSortBy: SortingState;
  leaderboardSortBy: SortingState;
  currentPointBoostsSortBy: SortingState;
  pointsAllocationSortBy: SortingState;
  seasonTab: SeasonTab;
  tab: PointsTab;
  period: DateRange;
  page: number;
  pageSize: number;
  // dynamically calculated amount of total points
  // based on init value and pointsPerSecond
  totalPointsRealTime: number;
  // global amount of points
  globalPointsRealTime: number;
  // Total points for user in preview Portfolio mode
  totalPointsPortfolioPreviewRealTime: number;
  totalPointsRealTimeS2: number;
  globalPointsRealTimeS2: number;
  totalPointsPortfolioPreviewRealTimeS2: number;
  totalPointsRealTimeS3: number;
  globalPointsRealTimeS3: number;
  totalPointsPortfolioPreviewRealTimeS3: number;
}

export interface Store extends Params {
  updateStoreParams: (params: Partial<Params>) => void;
}

/**
 * In the majority of the cases you need to use useStore hook instead
 * If you import it directly URL params will not be updated if filters or sorting will be updated
 */
const store = makeAutoObservable<Store>({
  ...getDefaultParams(),

  // arrow function and 'store' object are used to prevent missing "this" context
  updateStoreParams: (params: Partial<Params>) => {
    Object.assign(store, params);
  },
});

export { store as pointsPageStore };
