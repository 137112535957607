import qs from 'qs';
import { addDays } from 'date-fns';
import { StrategyCreationStatus } from '@kamino-finance/kliquidity-sdk';
import axios from 'axios';
import { StakingYieldsTokensResponse } from '../types/stakingYieldsTokens';
import { StrategiesVolumeResponse } from '../types/trategiesVolume';
import {
  FeesAndRewardsStrategiesResponse,
  KaminoTvlResponse,
  TotalFeesAndRewardsStrategiesResponse,
  TotalKaminoVolumeResponse,
} from '../types/metrics';
import { PnLForStrategyResponse } from '../types/strategyPnl';
import { UserFeesAndRewardsEarnings } from '../../models/api/UserFeesAndRewardsEarnings';
import { FetchStrategyPerformanceHistory } from '../../models/api/FetchStrategyPerformanceHistory';
import {
  MetricsHistoryResponse,
  RangesHistoryResponse,
  ShareholdersHistoryResponse,
  StrategyPerformanceResponse,
} from '../types/strategyAnalytics';
import { ENV } from './web3/types';
import { captureError } from '../utils/captureError';
import { KAMINO_LIQUIDITY_API, KAMINO_LIQUIDITY_API_DEV } from './liquidity/constants';
import isStagingEnabled from '../utils/stagingProgram';

const API_URL = !localStorage.getItem('kaminoStaging') ? KAMINO_LIQUIDITY_API : KAMINO_LIQUIDITY_API_DEV;

export interface FetchFeesAndRewardsStrategiesProps {
  env: ENV;
  period: ('24h' | '7d' | '30d')[];
  status: StrategyCreationStatus[];
}

export const fetchFeesAndRewardsStrategies = ({
  env,
  period,
  status,
}: FetchFeesAndRewardsStrategiesProps): Promise<FeesAndRewardsStrategiesResponse[]> =>
  axios
    .get(`${API_URL}/strategies/fees-and-rewards`, {
      params: { env, period, status },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data)
    .catch(captureError);

export interface FetchTotalFeesAndRewardsProps {
  env: ENV;
}

export const fetchTotalFeesAndRewards = ({
  env,
}: FetchTotalFeesAndRewardsProps): Promise<TotalFeesAndRewardsStrategiesResponse> =>
  axios
    .get(`${API_URL}/strategies/all-time-fees-and-rewards`, {
      params: { env },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data)
    .catch(captureError);

export interface FetchTotalKaminoVolumeProps {
  env: ENV;
}

export const fetchTotalKaminoVolume = ({ env }: FetchTotalKaminoVolumeProps): Promise<TotalKaminoVolumeResponse> =>
  axios
    .get(`${API_URL}/strategies/all-time-volume`, {
      params: { env },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data)
    .catch(captureError);

export const fetchTvl = ({ env }: FetchTotalKaminoVolumeProps): Promise<KaminoTvlResponse> =>
  axios
    .get(`${API_URL}/strategies/tvl`, {
      params: { env },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data)
    .catch(captureError);

export const fetchStakingYields = (): Promise<StakingYieldsTokensResponse[]> => {
  return axios.get(`${API_URL}/v2/staking-yields/median`).then((res) => res.data);
};

export interface FetchStrategiesVolumeProps {
  env: ENV;
  status: StrategyCreationStatus[];
}

export const fetchStrategiesVolume = ({
  env,
  status,
}: FetchStrategiesVolumeProps): Promise<StrategiesVolumeResponse[]> =>
  axios
    .get(`${API_URL}/v2/strategies/volume`, {
      params: { env, status },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((res) => res.data)
    .catch(captureError);

export interface FetchPnLForStrategyProps {
  env: ENV;
  strategyPubkey: string;
  shareholderPubkey: string;
}

export const fetchPnLForStrategy = ({
  strategyPubkey,
  shareholderPubkey,
  env,
}: FetchPnLForStrategyProps): Promise<PnLForStrategyResponse> =>
  axios
    .get(`${API_URL}/v2/strategies/${strategyPubkey}/shareholders/${shareholderPubkey}/pnl`, {
      params: { env },
    })
    .then((res) => res.data)
    .catch(captureError);

export interface FetchUserFeesAndRewardsEarningsProps {
  env: ENV;
  strategyAddress: string;
  walletPublicKey: string;
}

export const fetchUserFeesAndRewardsEarnings = async ({
  strategyAddress,
  walletPublicKey,
  env,
}: FetchUserFeesAndRewardsEarningsProps): Promise<UserFeesAndRewardsEarnings> => {
  return axios
    .get(
      `${API_URL}/v2/strategies/${strategyAddress}/shareholders/${walletPublicKey}/fees-and-rewards/latest-position`,
      {
        params: { env },
      }
    )
    .then((res) => res.data);
};

export interface FetchStrategyPerformanceHistoryProps {
  strategyAddress: string;
  env: string;
  year: number;
}

export const fetchStrategyPerformanceHistory = async ({
  strategyAddress,
  env,
  year,
}: FetchStrategyPerformanceHistoryProps): Promise<FetchStrategyPerformanceHistory[]> => {
  return axios
    .get(`${API_URL}/strategies/${strategyAddress}/history`, {
      params: { env, year },
    })
    .then((res) => res.data);
};

interface FetchRangesHistory {
  strategyPubkey: string;
  start: Date;
  end: Date;
  env: ENV;
}

export const fetchRangesHistory = ({
  strategyPubkey,
  start,
  end,
  env,
}: FetchRangesHistory): Promise<RangesHistoryResponse[]> =>
  axios
    .get(`${API_URL}/strategies/${strategyPubkey}/ranges/history`, {
      params: {
        env,
        start: start.toISOString(),
        end: end.toISOString(),
      },
    })
    .then((res) => res.data)
    .catch(captureError);

interface FetchMetricsHistory {
  strategyPubkey: string;
  start: Date;
  end: Date;
  env: ENV;
}

export const fetchMetricsHistory = ({
  strategyPubkey,
  start,
  end,
  env,
}: FetchMetricsHistory): Promise<MetricsHistoryResponse[]> =>
  axios
    .get(`${API_URL}/strategies/${strategyPubkey}/metrics/history`, {
      params: {
        start: start.toISOString(),
        end: end.toISOString(),
        env,
      },
    })
    .then((res) => res.data)
    .catch(captureError);

interface FetchShareholdersHistory {
  strategyPubkey: string;
  shareholder: string;
  start: Date;
  end: Date;
  env: ENV;
}

export const fetchShareholdersHistory = ({
  strategyPubkey,
  shareholder,
  start,
  end,
  env,
}: FetchShareholdersHistory): Promise<ShareholdersHistoryResponse> =>
  axios
    .get(`${API_URL}/v2/strategies/${strategyPubkey}/shareholders/${shareholder}/pnl/history`, {
      params: {
        start: start.toISOString(),
        end: addDays(end, 1).toISOString(),
        env,
      },
    })
    .then((res) => res.data)
    .catch(captureError);

export interface FetchStrategyPerformance {
  strategyAddress: string;
  depositDate: Date;
}

function formatData(data: any) {
  return Object.keys(data.timestamp).map((key) => ({
    timestamp: data.timestamp[key],
    hodlTokenAValueUsd: data.hodl_tokena_value_usd[key],
    hodlTokenAValueSol: data.hodl_tokena_value_sol[key],
    hodlTokenBValueUsd: data.hodl_tokenb_value_usd[key],
    hodlTokenBValueSol: data.hodl_tokenb_value_sol[key],
    hodlValueUsd: data.hodl_value_usd[key],
    hodlValueSol: data.hodl_value_sol[key],
    investmentValueUsd: data.investment_value_usd[key],
    investmentValueSol: data.investment_value_sol[key],
  }));
}

export const fetchStrategyPerformance = ({
  strategyAddress,
  depositDate,
}: FetchStrategyPerformance): Promise<StrategyPerformanceResponse[]> =>
  axios
    .get(`${API_URL}/performance/${strategyAddress}/vs_usd_ui`, {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        deposit_date: depositDate.toISOString(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        initial_deposit: 1000,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        deposit_token: 'USD',
        staging: isStagingEnabled,
      },
    })
    .then((res) => res.data)
    .then((data) => formatData(data))
    .catch((error) => {
      captureError(error);
      throw error;
    });
