import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import s from './LeveragePositions.module.less';
import { Text } from '../../../../../../../../shared/uiKitV2/Typography';
import { LendingPositionName, PnlCell } from '../../../../../../../../pages/PortfolioV2/components/TableCells';
import { formatNumber } from '../../../../../../../../shared/utils';
import { useLendingLeveragePositionsTableData } from '../../../../../../../../pages/PortfolioV2/hooks/useLendingLeveragePositionsTableData';
import { useMarkets } from '../../../../../../../../shared/hooks/lending/useMarkets';
import { PositionsAccordion } from '../../../PositionsAccordion/PositionsAccordion';
import { PublicKeyAddress } from '../../../../../../../../shared/types/strategies';
import { buildLeverageVaultUrl } from '../../../../../../../../shared/utils/lending/routes';
import { layoutProviderStore } from '../../../../../../../../stores/layoutProviderStore';

const { formatUsd: fusd } = formatNumber;

export const LeveragePositions: FC = () => {
  const { markets } = useMarkets();
  const { address: marketAddress } = markets[0];
  const { data, isLoading } = useLendingLeveragePositionsTableData({
    marketAddress,
  });
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { updateProviderParams } = layoutProviderStore;

  const handleGoToVaultPage = useCallback(
    (depositReserveAddress: PublicKeyAddress, borrowReserveAddress: PublicKeyAddress) => {
      history.push(buildLeverageVaultUrl({ depositReserveAddress, borrowReserveAddress, marketAddress }));
      mixpanel.track('dashboard:position_click', {
        address: `${depositReserveAddress.toString()} - ${borrowReserveAddress.toString()}`,
        type: 'leverage',
      });
      updateProviderParams({ isSidePanelOpened: false });
    },
    [history, marketAddress, mixpanel, updateProviderParams]
  );

  if (!data.length && !isLoading) {
    return null;
  }

  return (
    <PositionsAccordion header="Long/Short" isLoading={isLoading}>
      <div className={s.list}>
        {data.map((position) => {
          const depositMints = position.deposits.map((d) => d.mintAddress);

          return (
            <div
              className={s.tokenItem}
              key={position.address.toString()}
              onClick={() => handleGoToVaultPage(position.depositReserveAddress, position.borrowReserveAddress)}
            >
              <div className={s.tokenRight}>
                <LendingPositionName textProps={{ fs: 16, lh: 22 }} tokensMints={depositMints} maxTokensDisplayed={3} />
              </div>

              <div className={s.tokenValues}>
                <Text fs={14} lh={20}>
                  {fusd(position.netValue)}
                </Text>

                <PnlCell
                  dataType="usd"
                  obligationAddress={position.address}
                  marketAddress={marketAddress}
                  textProps={{ fs: 14, lh: 20 }}
                  label=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </PositionsAccordion>
  );
};
