import { FC } from 'react';

import { Text } from '../../../uiKitV2/Typography';
import { Banner } from '../Banner';

export const WithdrawOnlyBanner: FC = () => {
  return (
    <Banner localStorageKey="kamino.isGeoBlockingWarningClosed">
      <Text color="grey-light">You are currently accessing the frontend in Withdraw only mode.</Text>
    </Banner>
  );
};
