import Decimal from 'decimal.js';
import { DEFAULT_SLIPPAGE } from '../constants/slippage';
import { PriorityFeeType } from '../types/priorityFeeStats';
import {
  LAST_POSITION_UPDATE_AT,
  SETTING_TTL,
  USER_TRANSACTION_PRIORITY_FEE,
  USER_TRANSACTION_PRIORITY_FEE_TYPE,
  USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT,
  USER_TRANSACTION_SLIPPAGE,
  USER_TRANSACTION_SLIPPAGE_UPDATED_AT,
} from '../constants/settings';

const tokenTransactionSettingsMap: Record<string, any> = {};

const TOKENS_MINTS_WITH_TRANSACTION_SETTINGS = Object.keys(tokenTransactionSettingsMap);

export const getUserSlippageIfSet = () => {
  const userSlippage = localStorage.getItem(USER_TRANSACTION_SLIPPAGE);
  const userSlippageUpdatedAt = localStorage.getItem(USER_TRANSACTION_SLIPPAGE_UPDATED_AT);
  const isSettingStale = userSlippageUpdatedAt ? Date.now() - Number(userSlippageUpdatedAt) > SETTING_TTL : true;

  if (userSlippage && !isSettingStale) {
    return new Decimal(userSlippage);
  }
  if (isSettingStale) {
    localStorage.removeItem(USER_TRANSACTION_SLIPPAGE_UPDATED_AT);
    localStorage.removeItem(USER_TRANSACTION_SLIPPAGE);
  }
  return new Decimal(DEFAULT_SLIPPAGE);
};

export const getUserPriorityFeeTypeIfSet = () => {
  const userPriorityFeeType = localStorage.getItem(USER_TRANSACTION_PRIORITY_FEE_TYPE);
  const priorityFeeTypeUpdatedAt = localStorage.getItem(USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT);
  const isSettingStale = priorityFeeTypeUpdatedAt ? Date.now() - Number(priorityFeeTypeUpdatedAt) > SETTING_TTL : true;

  if (userPriorityFeeType && !isSettingStale) {
    return userPriorityFeeType as PriorityFeeType;
  }

  if (isSettingStale) {
    localStorage.removeItem(USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT);
    localStorage.removeItem(USER_TRANSACTION_PRIORITY_FEE_TYPE);
  }

  return PriorityFeeType.medium;
};

export const getUserPriorityFeeIfSet = () => {
  const userPriorityFee = localStorage.getItem(USER_TRANSACTION_PRIORITY_FEE);
  const priorityFeeUpdatedAt = localStorage.getItem(USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT);
  const isSettingStale = priorityFeeUpdatedAt ? Date.now() - Number(priorityFeeUpdatedAt) > SETTING_TTL : true;

  if (userPriorityFee && !isSettingStale) {
    return new Decimal(userPriorityFee);
  }

  if (isSettingStale) {
    localStorage.removeItem(USER_TRANSACTION_PRIORITY_FEE_UPDATED_AT);
    localStorage.removeItem(USER_TRANSACTION_PRIORITY_FEE);
  }

  return new Decimal(0);
};

export const getIsSpecialTransactionSettingsApplied = (tokens: string[] = []) => {
  const userPriorityFeeType = localStorage.getItem(USER_TRANSACTION_PRIORITY_FEE_TYPE);
  const userSlippage = localStorage.getItem(USER_TRANSACTION_SLIPPAGE);
  const hasTokenTransactionSettings = tokens.some((token) => TOKENS_MINTS_WITH_TRANSACTION_SETTINGS.includes(token));
  return hasTokenTransactionSettings && (!userSlippage || !userPriorityFeeType);
};

export const getUserSlippageOverride = (tokens?: string[]) => {
  let overrideSlippage = getUserSlippageIfSet();

  const tokenWithTransactionSettings = TOKENS_MINTS_WITH_TRANSACTION_SETTINGS.find((token) => tokens?.includes(token));
  if (tokenWithTransactionSettings && tokenTransactionSettingsMap[tokenWithTransactionSettings].slippage) {
    overrideSlippage = tokenTransactionSettingsMap[tokenWithTransactionSettings].slippage;
  }
  return overrideSlippage;
};

export const getUserPriorityFeeTypeOverride = (tokens?: string[]) => {
  let overridePriorityFeeType = getUserPriorityFeeTypeIfSet();

  const tokenWithTransactionSettings = TOKENS_MINTS_WITH_TRANSACTION_SETTINGS.find((token) => tokens?.includes(token));
  if (tokenWithTransactionSettings && tokenTransactionSettingsMap[tokenWithTransactionSettings].priorityFeeType) {
    overridePriorityFeeType = tokenTransactionSettingsMap[tokenWithTransactionSettings].priorityFeeType;
  }

  return overridePriorityFeeType;
};

export const getlastPositionUpdateAtIfSet = () => {
  const lastPositionUpdateAt = localStorage.getItem(LAST_POSITION_UPDATE_AT);
  return lastPositionUpdateAt || undefined;
};
