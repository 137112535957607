import Decimal from 'decimal.js';
import { UserFeesAndRewardsEarnings } from '../../../models/api/UserFeesAndRewardsEarnings';

export const calculateTotalEarnings = (feesAndRewardsEarnings?: UserFeesAndRewardsEarnings): Decimal => {
  return feesAndRewardsEarnings
    ? new Decimal(feesAndRewardsEarnings.feesAEarnedUsd)
        .plus(feesAndRewardsEarnings.feesBEarnedUsd)
        .plus(feesAndRewardsEarnings.rewards0EarnedUsd)
        .plus(feesAndRewardsEarnings.rewards1EarnedUsd)
        .plus(feesAndRewardsEarnings.rewards2EarnedUsd)
        .plus(feesAndRewardsEarnings.kaminoRewards0EarnedUsd)
        .plus(feesAndRewardsEarnings.kaminoRewards1EarnedUsd)
        .plus(feesAndRewardsEarnings.kaminoRewards2EarnedUsd)
    : new Decimal(0);
};
