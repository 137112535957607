import { Chain, ENV } from '../shared/services/web3/types';
import { ENABLED_CHAINS } from '../shared/services/web3/client';
import { getSavedCustomRpc } from './customRpc';
import { WEB3_ENDPOINT_KEY, WEB3_ENV_KEY } from './constants';

export const saveSelectedRPC = (chain: Chain) => {
  localStorage.setItem(WEB3_ENV_KEY, chain.name);
  localStorage.setItem(WEB3_ENDPOINT_KEY, chain.endpoint);
};

export function getEnvChain(): ENV {
  const customEnv = getSavedCustomRpc();
  if (customEnv) {
    return customEnv.name;
  }
  const lcEnv = localStorage.getItem(WEB3_ENV_KEY) as ENV;
  const isEnabledChain = lcEnv ? ENABLED_CHAINS.filter((chain) => chain.name === lcEnv).length > 0 : false;
  return isEnabledChain ? lcEnv : ENABLED_CHAINS[0].name;
}

export function getChain() {
  // const customEnv = getSavedCustomRpc();
  // if (customEnv) {
  //   return customEnv;
  // }

  const endpoint = localStorage.getItem(WEB3_ENDPOINT_KEY);
  return ENABLED_CHAINS.find((chain) => chain.endpoint === endpoint) || ENABLED_CHAINS[0];
}

export const getAreTermsConfirmed = () => {
  return localStorage.getItem('kamino.areTermsConfirmed') === 'true';
};
