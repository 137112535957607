import { FC, ReactNode } from 'react';
import { withStyles } from '@bruitt/classnames';
import { Tooltip } from '../Tooltip';
import { Tag } from '../Tag';

import s from './WarningTag.module.less';
import { Text } from '../Typography';
import { Icon } from '../Icon';

const sx = withStyles(s);

export interface WarningTagData {
  variant: 'primary' | 'warning' | 'danger' | 'info' | 'secondary' | 'highlight';
  text: string;
  tooltip: string | ReactNode;
  hasIcon?: boolean;
  isClickableTooltip?: boolean;
  icon?: ReactNode;
  // disable buttons if there are at least one isCritical=true warning
  // eslint-disable-next-line react/no-unused-prop-types
  isCritical?: boolean;
  isFluid?: boolean;
  // TODO: Delete isOpen prop
  // eslint-disable-next-line react/no-unused-prop-types
  isOpen?: boolean;
}

const getTagVariant = (variant: 'primary' | 'warning' | 'danger' | 'info' | 'secondary' | 'highlight') => {
  switch (variant) {
    case 'primary':
      return 'green';
    case 'warning':
      return 'orange';
    case 'danger':
      return 'red';
    case 'info':
      return 'blue';
    case 'secondary':
      return 'grey';
    case 'highlight':
      return 'blueWhite';
  }
};

export const WarningTag: FC<WarningTagData> = ({
  variant,
  text,
  tooltip,
  icon,
  hasIcon = true,
  isClickableTooltip = false,
  isFluid = false,
  isOpen,
}) => {
  return (
    <Tooltip
      clickable={isClickableTooltip}
      isOpen={isOpen}
      render={() => (
        <div className={sx(s.tooltipOverlay, { isFluid })}>
          <div className={s.alignItemsCenter}>
            {hasIcon && !icon ? (
              <div className={s.icon}>
                {variant === 'primary' && hasIcon && !icon && <Icon name="Check" color="green" size={18} />}
                {(variant === 'warning' || variant === 'danger') && hasIcon && !icon && (
                  <Icon name="WarningInfo" size={18} color="orange" />
                )}
                {icon}
              </div>
            ) : null}
            {typeof tooltip === 'string' ? <Text lh={18}>{tooltip}</Text> : tooltip}
          </div>
        </div>
      )}
    >
      <Tag label={text} variant={getTagVariant(variant)} />
    </Tooltip>
  );
};
