import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import useEnv from '../../../shared/hooks/useEnv';
import { useWeb3Client } from '../../../shared/hooks/useWeb3Client';
import { QUERYKEYS } from '../../../shared/constants/queryKeys';
import { captureError } from '../../../shared/utils/captureError';
import { notify } from '../../../shared/utils/notifications/notifications';

export const useNativeBalanceQuery = () => {
  const { walletPublicKey } = useEnv();
  const { web3client } = useWeb3Client();

  const queryFn = async () => {
    if (!web3client || !walletPublicKey) {
      return;
    }

    return web3client.connection.getBalance(new PublicKey(walletPublicKey || ''));
  };

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.getNativeSolBalance(walletPublicKey || ''),
    enabled: Boolean(walletPublicKey) && Boolean(web3client),
    queryFn,
    onError: (err: Error) => {
      captureError(err);
      notify({
        message: 'Failed to load native SOL balance',
        type: 'error',
      });
    },
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
    refetchInterval: 1 * 60 * 1000, // 1 minute
  });

  return { data: data || 0, ...rest };
};
