import { ReactElement, SVGProps } from 'react';

export const SvgMet = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#met_svg__a)">
      <g clipPath="url(#met_svg__b)">
        <mask
          id="met_svg__c"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={19}
          height={18}
        >
          <path d="M18.398 0h-18v18h18V0Z" fill="#fff" />
        </mask>
        <g mask="url(#met_svg__c)">
          <mask
            id="met_svg__d"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={-3}
            y={2}
            width={19}
            height={19}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.156 12.52a5.614 5.614 0 0 0 2.752-4.828c0-3.105-2.528-5.622-5.645-5.622a5.647 5.647 0 0 0-5.038 3.084l-7.944 7.91 7.665 7.633 8.21-8.177Z"
              fill="#795E5E"
            />
          </mask>
          <g mask="url(#met_svg__d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m10.168 1.66.003-.004c-.89.017-1.75.256-2.488.723a48.116 48.116 0 0 0-1.622 1.8 37.28 37.28 0 0 0-1.648 2.065c-.195.266-.387.536-.569.817.283-.18.554-.372.822-.566.315-.232.627-.47.934-.71 1.5-1.18 2.907-2.471 4.258-3.81l.31-.316Zm-.665 3.507a115.212 115.212 0 0 0 2.957-3.074 5.96 5.96 0 0 0-1.23-.353c-.891.828-1.766 1.673-2.632 2.526-.996 1.001-1.982 2.015-2.94 3.053-1.39 1.504-2.722 3.06-3.948 4.708a56.256 56.256 0 0 0 4.288-3.533c1.197-1.08 2.36-2.196 3.505-3.327Zm4.675-2.032a136.25 136.25 0 0 1-4.361 4.558c-.89.88-1.786 1.752-2.696 2.61C5.135 12.18 3.086 13.991.912 15.66c1.715-2.215 3.579-4.298 5.509-6.32.82-.858 1.653-1.706 2.491-2.548a138.67 138.67 0 0 1 4.461-4.239c.279.17.548.364.805.582Zm1.33 1.525a6.406 6.406 0 0 0-.59-.795 136.514 136.514 0 0 0-4.58 4.347c-.884.887-1.76 1.78-2.624 2.687-1.883 1.978-3.7 4.018-5.376 6.181 2.22-1.705 4.309-3.557 6.335-5.475a172.91 172.91 0 0 0 2.57-2.492 138.117 138.117 0 0 0 4.265-4.453Zm.469.904c.167.395.29.801.366 1.209-.84.899-1.7 1.78-2.566 2.653a112.599 112.599 0 0 1-3.097 2.957c-1.5 1.374-3.053 2.69-4.696 3.902a55.852 55.852 0 0 1 3.532-4.252 105.999 105.999 0 0 1 3.356-3.508 114.775 114.775 0 0 1 3.105-2.961Zm-.149 4.624c.407-.716.61-1.532.613-2.374-.122.118-.243.236-.363.355-1.36 1.36-2.67 2.777-3.866 4.29-.229.29-.453.583-.674.88-.195.268-.387.537-.569.818.283-.18.554-.372.822-.566.654-.48 1.29-.983 1.91-1.504a48.564 48.564 0 0 0 2.127-1.899Z"
              fill="url(#met_svg__e)"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="met_svg__a">
        <rect x={0.398} width={16} height={16} rx={8} fill="#fff" />
      </clipPath>
      <clipPath id="met_svg__b">
        <path fill="#fff" transform="translate(.398)" d="M0 0h18v18H0z" />
      </clipPath>
      <linearGradient id="met_svg__e" x1={13.421} y1={36.049} x2={31.985} y2={13.838} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF2189" />
        <stop offset={1} stopColor="#FF9D00" />
      </linearGradient>
    </defs>
  </svg>
);
