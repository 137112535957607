import { ReactElement, SVGProps } from 'react';

export const SvgThunderBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.743 1.5 6.41 12.809c-.272.487-.409.73-.41.936a.713.713 0 0 0 .15.46c.109.128.324.128.755.128h5.04l-.799 8.167 6.333-11.309c.273-.487.41-.73.41-.936a.713.713 0 0 0-.148-.46c-.11-.128-.325-.128-.756-.128h-5.04l.798-8.167Z"
      fill="#fff"
    />
  </svg>
);
