import axios from 'axios';

const SOLANA_COMPASS_API_URL = 'https://solanacompass.com/api';

export const fetchPriorityFeeStats = () =>
  axios
    .get(`${SOLANA_COMPASS_API_URL}/fees`, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((res) => res.data);
