import { ReactElement, SVGProps } from 'react';

export const SvgChartCandle = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.5 12V8.5m0 12V19m-1.333-7H5.5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-5.222c0-.43.298-.778.667-.778ZM19.5 15v3m0-14v1m1.333 1H18.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V6.75c0-.414-.299-.75-.667-.75ZM12 6V3.5M12 22v-4M10.667 6h2.666c.368 0 .667.336.667.75V17a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6.75c0-.414.299-.75.667-.75Z"
    />
  </svg>
);
