/**
 * Put this component only once on the top level of the app
 * It contains gradients that we can use for svg
 * It has to be on top of the app and do not be removed from the dom or hidden by css in order to make it work
 * @constructor
 */
export const SvgGradients = () => (
  <svg style={{ position: 'absolute', width: 0, height: 0 }}>
    <defs>
      <linearGradient
        id="goldenStarGradient"
        x1="8.00016"
        y1="2.00098"
        x2="13.7173"
        y2="5.09153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD37F" />
        <stop offset="1" stopColor="#D6971E" />
      </linearGradient>
    </defs>
  </svg>
);
