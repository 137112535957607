import { ReactElement, SVGProps } from 'react';

export const SvgDocumentation = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#98B0C1" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
      <path d="M8.667 14h-6A.667.667 0 0 1 2 13.333V4.667C2 4.298 2.298 4 2.667 4H6.39c.176 0 .346.07.47.195l2.277 2.276a.667.667 0 0 1 .195.472v6.39a.667.667 0 0 1-.666.667Z" />
      <path d="M6.666 4V2.667c0-.369.298-.667.667-.667h3.723c.177 0 .347.07.472.195l2.276 2.276a.667.667 0 0 1 .195.472v6.39a.667.667 0 0 1-.666.667h-4" />
      <path d="M9.333 7.333H6.667A.667.667 0 0 1 6 6.667V4M14 5.333h-2.667a.667.667 0 0 1-.667-.666V2" />
    </g>
  </svg>
);
