import { useQuery } from '@tanstack/react-query';
import { getReferrerForShortUrl } from '@kamino-finance/klend-sdk';
import { useWeb3Client } from './useWeb3Client';
import { QUERYKEYS } from '../constants/queryKeys';
import { getLendingProgramId } from '../utils/lending/getLendingProgramId';

export const useGetReferrerByReferalUrl = (referalUrl?: string) => {
  const { web3client } = useWeb3Client();

  const { data } = useQuery(
    QUERYKEYS.getReferrerByUrl(referalUrl || '-'),
    () => {
      if (!web3client || !referalUrl) {
        return;
      }

      return getReferrerForShortUrl(web3client.connection, referalUrl, getLendingProgramId());
    },
    {
      enabled: Boolean(web3client && referalUrl),
    }
  );

  return {
    data,
  };
};
