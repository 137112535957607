import { ReactElement, SVGProps } from 'react';

export const SvgRings = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg viewBox="0 0 148 147" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M74 6.385a67.114 67.114 0 1 1 0 134.229 67.114 67.114 0 0 1 0-134.23Z"
      stroke="url(#rings_svg__a)"
      strokeWidth={12}
      strokeLinejoin="bevel"
    />
    <defs>
      <linearGradient id="rings_svg__a" x1={351} y1={-89.5} x2={143.552} y2={239.603} gradientUnits="userSpaceOnUse">
        <stop stopColor="#98B0C1" stopOpacity={0.86} />
        <stop offset={1} stopColor="#98B0C1" stopOpacity={0.12} />
      </linearGradient>
    </defs>
  </svg>
);
