import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import s from './DashboardPreviewBanner.module.less';

import { useWalletAddressFromParamsOrEnv } from '../../../hooks/useWalletAddressFromParamsOrEnv';
import paths from '../../../routes/paths';
import { Icon } from '../../../uiKitV2/Icon';
import { notify } from '../../../utils/notifications/notifications';
import { Text } from '../../../uiKitV2/Typography';
import { shorterString } from '../../../utils';
import useWindowSize from '../../../hooks/useWindowSize';
import { TABLET_BP } from '../../../constants/breakpoints';

export const DashboardPreviewBanner: FC = () => {
  const location = useLocation();
  const { owner: previewWallet }: { owner: string } = useParams();
  const { isOwner } = useWalletAddressFromParamsOrEnv();
  const { width } = useWindowSize();
  const isSmallSize = width < TABLET_BP;

  const isRenderBanner = location.pathname.includes(paths.PORTFOLIO) && previewWallet && !isOwner;

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(previewWallet);

      notify({
        type: 'success',
        message: 'Copied!',
      });
    } catch (err) {
      console.log('Copy to clipboard error:', err);
    }
  };

  return (
    <>
      {isRenderBanner && (
        <div className={s.bannerWrapper}>
          <Text color="grey-light">You are viewing the portfolio of</Text>
          <div className={s.wallet} onClick={onCopy}>
            <Text color="blue">{isSmallSize ? shorterString(previewWallet, 4) : previewWallet}</Text>
            <Icon className={s.copyIcon} name="Copy" color="blue" size={16} />
          </div>
        </div>
      )}
    </>
  );
};
