import { useCallback, useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import useEnv from './useEnv';
import { featureFlags } from '../utils';
import { layoutProviderStore } from '../../stores/layoutProviderStore';

export const useVersionedTransactions = () => {
  const { wallet } = useEnv();
  const mixpanel = useMixpanel();

  const { isVersionedTxEnabled, updateProviderParams } = layoutProviderStore;

  const checkIfVersionedTransactionsSupported = useCallback(() => {
    // if wallet is not connected we return true as we do not know
    if (!wallet) {
      return true;
    }
    const isWalletConnect = wallet.adapter.name === 'WalletConnect';

    // WalletConnect still does not support versioned transactions
    if (isWalletConnect) {
      return false;
    }

    return wallet.adapter.supportedTransactionVersions?.has(0) || false;
  }, [wallet]);

  useEffect(() => {
    updateProviderParams({
      isVersionedTxEnabled: featureFlags.isVersionedTransactionsEnabled && checkIfVersionedTransactionsSupported(),
    });
  }, [checkIfVersionedTransactionsSupported, updateProviderParams]);

  const handleVersionedTxChange = (isVersionedTransactionEnabled: boolean) => {
    updateProviderParams({ isVersionedTxEnabled: isVersionedTransactionEnabled });

    mixpanel.track('kamino:liquidity:deposit-form:toggle-version', {
      value: isVersionedTransactionEnabled,
    });
  };

  return { isVersionedTxEnabled, onVersionedTxChange: handleVersionedTxChange, checkIfVersionedTransactionsSupported };
};
