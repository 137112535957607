import { ReactElement, SVGProps } from 'react';

export const SvgVault = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.167 13.167V14.5m6.666-1.333V14.5m0-6.667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm0 0v2m0-2.666h.007m-5.007 1h.007m2.327 0a2.333 2.333 0 1 1-4.667 0 2.333 2.333 0 0 1 4.667 0Zm-3.467 5h5.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108v-3.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.428-.218-.988-.218-2.108-.218H5.7c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874c-.218.427-.218.988-.218 2.108v3.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218Z"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
