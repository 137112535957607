import { RewardInfo } from '@hubbleprotocol/farms-sdk/src/rpc_client/types';
import Decimal from 'decimal.js';
import { FarmStateWithApy } from '../types/farms';

export function calcTotalRewardsApy(farm?: FarmStateWithApy) {
  if (!farm) {
    return 0;
  }

  return farm.rewardInfos.reduce((sum, rewardInfo) => {
    if (rewardInfo.rewardsAvailable.gtn(0) && rewardInfo.apy) {
      sum += rewardInfo.apy;
    }
    return sum;
  }, 0);
}

export function getRewardPerTimeUnitSecond(reward: RewardInfo) {
  const now = new Decimal(new Date().getTime()).div(1000);
  let rewardPerTimeUnitSecond = new Decimal(0);
  for (let i = 0; i < reward.rewardScheduleCurve.points.length - 1; i++) {
    const { tsStart: tsStartThisPoint, rewardPerTimeUnit } = reward.rewardScheduleCurve.points[i];
    const { tsStart: tsStartNextPoint } = reward.rewardScheduleCurve.points[i + 1];

    const thisPeriodStart = new Decimal(tsStartThisPoint.toString());
    const thisPeriodEnd = new Decimal(tsStartNextPoint.toString());
    const rps = new Decimal(rewardPerTimeUnit.toString());

    // Rules:
    // Period is in the past:     If we are after this period, then we don't count it
    // Period is in the future:   If we are before this period, we count it fully
    // Period is in the present:  If we are during this period, we count it partially
    // Period is past locking cutoff: We dismiss it

    if (thisPeriodStart <= now && thisPeriodEnd >= now) {
      // Period is in the present:  If we are during this period, we count the reward based on it
      rewardPerTimeUnitSecond = rps;
      break;
    } else if (thisPeriodStart > now && thisPeriodEnd > now) {
      // Period is in the future: If we are before this period, we count it fully
      rewardPerTimeUnitSecond = rps;
      break;
    }
  }

  const rewardTokenDecimals = reward.token.decimals.toNumber();
  const rewardAmountPerUnitDecimals = new Decimal(10).pow(reward.rewardsPerSecondDecimals.toString());
  const rewardAmountPerUnitLamports = new Decimal(10).pow(rewardTokenDecimals.toString());

  const rpsAdjusted = new Decimal(rewardPerTimeUnitSecond.toString())
    .div(rewardAmountPerUnitDecimals)
    .div(rewardAmountPerUnitLamports);

  return rewardPerTimeUnitSecond ? rpsAdjusted : new Decimal(0);
}
