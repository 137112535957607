import { ObligationTypeTag } from '@kamino-finance/klend-sdk';
import { PublicKey } from '@solana/web3.js';
import { useMemo } from 'react';

import { useMarkets } from './useMarkets';
import useEnv from '../useEnv';
import { buildObligationType } from '../../utils/lending';

export const useMapObligationAddressToType = () => {
  const { markets } = useMarkets();
  const { walletPublicKey } = useEnv();

  const obligationAddressToTypeMap = useMemo(() => {
    const map = new Map<string, ObligationTypeTag>();

    if (!markets.length || !walletPublicKey) {
      return map;
    }

    const walletPubkey = new PublicKey(walletPublicKey);

    for (const market of markets) {
      const { programId, reserves } = market;
      // Vanilla
      const vanillaObligation = buildObligationType({ tag: ObligationTypeTag.Vanilla, programId });
      const vanillaPda = vanillaObligation.toPda(market.getAddress(), walletPubkey);
      map.set(vanillaPda.toString(), ObligationTypeTag.Vanilla);

      for (const reserve of reserves.values()) {
        const collMint = reserve.getLiquidityMint();

        const lendingObligation = buildObligationType({
          tag: ObligationTypeTag.Lending,
          programId,
          tokenMint: collMint,
        });
        const lendingPda = lendingObligation.toPda(market.getAddress(), walletPubkey);
        map.set(lendingPda.toString(), ObligationTypeTag.Lending);

        // eslint-disable-next-line
        for (const reserve of reserves.values()) {
          const debtMint = reserve.getLiquidityMint();

          const multiplyObligation = buildObligationType({
            tag: ObligationTypeTag.Multiply,
            programId,
            tokenMint: collMint,
            debtToken: debtMint,
          });
          const multiplyPda = multiplyObligation.toPda(market.getAddress(), walletPubkey);
          map.set(multiplyPda.toString(), ObligationTypeTag.Multiply);

          const longShortObligation = buildObligationType({
            tag: ObligationTypeTag.Leverage,
            programId,
            tokenMint: collMint,
            debtToken: debtMint,
          });
          const longShortPda = longShortObligation.toPda(market.getAddress(), walletPubkey);
          map.set(longShortPda.toString(), ObligationTypeTag.Leverage);
        }
      }
    }

    return map;
  }, [markets, walletPublicKey]);

  return { obligationAddressToTypeMap };
};
