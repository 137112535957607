import React from 'react';
import s from './BlacklistOverlay.module.less';
import { Card } from '../../uiKitV2/Card';
import { SOCIAL_LINKS } from '../../constants/links';
import { Text } from '../../uiKitV2/Typography';
import { Link } from '../../uiKitV2/Link';

interface BlacklistOverlayProps {
  walletAddress: string;
}

export const BlacklistOverlay = ({ walletAddress }: BlacklistOverlayProps) => {
  return (
    <div className={s.root}>
      <Card className={s.card}>
        <div className={s.title}>
          <Text weight="bold" fs={22} lh={32} textAlign="center">
            Access Prohibited
          </Text>
        </div>
        <div className={s.body}>
          <Text fs={14} lh={18} textAlign="center" color="grey">
            Wallet{' '}
            <Text weight="bold" color="white" tag="span">
              {walletAddress}
            </Text>{' '}
            has been flagged for suspicious activity and is blocked from accessing the site. Please reach out in{' '}
            <Link textProps={{ tag: 'span' }} href={SOCIAL_LINKS.discord} target="_blank">
              Discord
            </Link>{' '}
            if you suspect this is incorrect
          </Text>
        </div>
      </Card>
    </div>
  );
};
