import { getTokenOwnerRecordForRealm } from '@solana/spl-governance';
import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';

import { useWeb3Client } from '../../../../../shared/hooks/useWeb3Client';
import useEnv from '../../../../../shared/hooks/useEnv';
import { communityTokenMint, governanceProgramId, realmPublicKey } from '../../../utils';

export function useTokenOwnerRecord() {
  const { web3client } = useWeb3Client();
  const { walletPublicKey } = useEnv();

  return useQuery({
    enabled: !!walletPublicKey && !!realmPublicKey,
    queryKey: ['get-token-owner-record', { walletPublicKey }],
    queryFn: async () => {
      const connection = web3client?.connection;
      if (!connection || !walletPublicKey || !realmPublicKey) {
        return null;
      }

      try {
        const tokenOwnerRecord = await getTokenOwnerRecordForRealm(
          connection,
          governanceProgramId,
          realmPublicKey,
          communityTokenMint,
          new PublicKey(walletPublicKey)
        );

        return tokenOwnerRecord;
      } catch (err) {
        return null;
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
