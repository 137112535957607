import React, { FC, memo, ReactNode } from 'react';

import { withStyles } from '@bruitt/classnames';
import { Tokens, TokensProps } from '../Tokens';
import { Text, TextProps } from '../Typography/Typography';

import s from './TokensWithText.module.less';
import { ProviderIcon, ProviderIconProps } from '../ProviderIcon';

const sx = withStyles(s);

export interface TokensWithTextProps {
  text: ReactNode;
  tokensProps?: TokensProps;
  providerProps?: ProviderIconProps;
  textProps: TextProps;
  direction?: 'vertical' | 'horizontal';
  gap?: 2 | 4 | 6 | 8 | 10 | 12 | 14 | 16 | 18;
  align?: 'left' | 'center' | 'right';
  reverse?: boolean;
  className?: string;
  children?: React.ReactNode;
  right?: React.ReactNode;
  tokensDisplayCount?: number;
}

export const TokensWithText: FC<TokensWithTextProps> = memo((props) => {
  const {
    text,
    tokensProps,
    providerProps,
    textProps,
    className,
    direction = 'horizontal',
    gap = 6,
    align = 'center',
    reverse = false,
    children = null,
    right,
    tokensDisplayCount,
  } = props;

  return (
    <div className={sx('root', className, { direction, gap, align, reverse })}>
      {tokensProps && <Tokens {...tokensProps} displayCount={tokensDisplayCount} />}
      {providerProps && <ProviderIcon {...providerProps} />}
      {children}
      <Text {...textProps} className={s.text}>
        {text}
      </Text>
      {right && right}
    </div>
  );
});
