import { memoize } from 'lodash';
import { PublicKey } from '@solana/web3.js';
import { JLP_MARKET_JLP_RESERVE_ADDRESS, JLP_RESERVE_ADDRESS } from '../constants/borrowLend/reserve';
import { PublicKeyAddress } from '../types/strategies';
import { MarketMetadata } from './lending/getMarketMetadata';

const JLP_TOKEN_MINT = '27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4';

export function isJlp(address?: PublicKeyAddress) {
  if (!address) {
    return false;
  }
  return address.toString() === JLP_RESERVE_ADDRESS;
}

export const isJlpReserveInJlpMarket = memoize((address?: PublicKeyAddress) => {
  if (!address) {
    return false;
  }
  return address.toString() === JLP_MARKET_JLP_RESERVE_ADDRESS;
});

export const isJlpMarket = memoize((metadata: MarketMetadata) => {
  return metadata.slug === 'jlp';
});

export const isJlpTokenMint = (mint: PublicKey) => {
  return mint.toString() === JLP_TOKEN_MINT;
};
