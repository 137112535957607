import { QueryKey } from '@tanstack/react-query';

// This is a check for balance queryKey
// We have to complicate the check for balances in order to invalidate the entire cache of balances that we have after transactions.
// If you change the logic of a function, you must understand what you are doing. And don’t forget to change or test the getTokensBalances and getTokenBalance keys

export const isBalanceQueryKey = (queryKey: QueryKey, walletPublicKey: string) => {
  const lastElem = queryKey[queryKey.length - 1];
  const hasWallet = queryKey[1] === walletPublicKey;
  const hasBalanceType = lastElem === 'balances' || lastElem === 'balance';

  return hasWallet && hasBalanceType;
};
