import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { TypedJSON } from 'typedjson';
import Decimal from 'decimal.js';
import App from './App';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-58DDRD3',
};

TagManager.initialize(tagManagerArgs);

TypedJSON.mapType(Decimal, {
  deserializer: (json) => (json == null ? json : new Decimal(json)),
});

window.addEventListener('error', (event: ErrorEvent) => {
  if (
    event.message.includes('Failed to fetch dynamically imported module') ||
    event.message.includes('Unable to preload CSS')
  ) {
    console.log('Module loading failed, reloading...');
    if (process.env.NODE_ENV !== 'development') {
      window.location.reload();
    }
  }
});

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  if (
    event.reason instanceof Error &&
    (event.reason.message.includes('Failed to fetch dynamically imported module') ||
      event.reason.message.includes('Unable to preload CSS'))
  ) {
    if (process.env.NODE_ENV !== 'development') {
      window.location.reload();
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
