import { withStyles } from '@bruitt/classnames';
import React, { FC, ReactNode } from 'react';
import s from './Notification.module.less';
import { SvgInfo } from '../Icons';
import { Text } from '../Typography';

const sx = withStyles(s);

export interface NotificationProps {
  className?: string;
  variant: 'info' | 'warning' | 'success' | 'boost' | 'danger' | 'info-green';
  text: string | ReactNode;
  customIcon?: ReactNode;
}

export const Notification: FC<NotificationProps> = ({ className, variant, text, customIcon }) => {
  return (
    <div
      className={sx('root', className, {
        variant,
      })}
    >
      <div>{customIcon || <SvgInfo className={sx('icon')} />}</div>
      <div>
        {typeof text === 'string' ? (
          <Text fs={14} lh={18} color="white" weight="regular">
            {text}
          </Text>
        ) : (
          text
        )}
      </div>
    </div>
  );
};
