import { withStyles } from '@bruitt/classnames';
import { FC, ReactNode } from 'react';
import { Text } from '../Typography';
import { TextProps } from '../Typography/Typography';

import s from './DropdownTrigger.module.less';

const sx = withStyles(s);

export interface DropdownTriggerProps {
  text: string;
  label?: string;
  variant?: 'primary' | 'ghost' | 'secondary';
  size?: 'small' | 'large';
  labelPlacement?: 'left' | 'top';
  justifyContent?: 'left' | 'center' | 'spaceBetween';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  textProps?: TextProps;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  isFullWidth?: boolean;
  textClassName?: string;
}

const EMPTY_TEXT_PROPS: Partial<TextProps> = {};

export const DropdownTrigger: FC<DropdownTriggerProps> = ({
  text,
  label,
  variant = 'primary',
  size = 'small',
  labelPlacement = 'left',
  justifyContent = 'left',
  startIcon,
  endIcon,
  textProps = EMPTY_TEXT_PROPS,
  isDisabled,
  isActive,
  onClick,
  isFullWidth,
  textClassName,
}) => {
  return (
    <div className={sx('root', { labelPlacement, fullWidth: isFullWidth })}>
      <button
        className={sx('btn', { variant, size, justifyContent, active: isActive, fullWidth: isFullWidth })}
        type="button"
        onClick={onClick}
        disabled={isDisabled}
      >
        {label && (
          <Text weight="regular" lh={20} className={sx('label')}>
            {label}
          </Text>
        )}

        <div className={sx('iconContainer')}>
          {startIcon && <div className={sx('startIcon')}>{startIcon}</div>}
          <Text
            lh={20}
            className={sx('key', textClassName)}
            color={variant === 'secondary' ? 'white' : undefined}
            {...textProps}
          >
            {text}
          </Text>
        </div>

        <div className={sx('endIcon')}>{endIcon}</div>
      </button>
    </div>
  );
};
