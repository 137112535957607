import { ReactElement, SVGProps } from 'react';

export const SvgWalletBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={14} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.75 5.167V10.3c0 .653 0 .98.127 1.23.112.22.29.398.51.51.25.127.576.127 1.23.127h6.766c.654 0 .98 0 1.23-.128.22-.111.398-.29.51-.51.127-.249.127-.576.127-1.23V6.743c0-.38 0-.57-.044-.725a1.167 1.167 0 0 0-.806-.807c-.155-.043-.345-.043-.725-.043m-8.925 0V4.7c0-.653 0-.98.127-1.23.112-.22.29-.398.51-.51.25-.127.576-.127 1.23-.127h5.191c.654 0 .98 0 1.23.127.22.112.398.29.51.51.127.25.127.577.127 1.23v.467m-8.925 0h8.925M12.25 7.5h-1.167a1.167 1.167 0 0 0 0 2.333h1.167V7.5Z"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
