import React, { FC, forwardRef, ReactNode, useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { withStyles } from '@bruitt/classnames';
import Decimal from 'decimal.js';
import s from './LargeInput.module.less';
import { Text } from '../Typography';
import { formatNumber, isKTokenSymbol, noop } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownTrigger } from '../DropdownTrigger';
import { useDropdown } from '../Dropdown/useDropdown';
import { Icon } from '../Icon';
import { DropdownItem } from '../DropdownItem';
import { TokenIcon } from '../TokenIcon';
import { TokensWithText } from '../TokensWithText';
import { TokenInfo } from '../../types/token-info';
import { KTokensWithText } from '../../../features/KTokensWithText';
import { TokensPopup } from './components/TokensPopup/TokensPopup';
import { TokenWithPrice } from '../../../features/SidePanel/types/tokenWithPrice';

const sx = withStyles(s);

const { formatUsd: fusd } = formatNumber;

export interface LargeInputProps extends NumericFormatProps {
  variant?: 'primary' | 'secondary';
  label: string;
  topStat?: Decimal;
  bottomLeftStat?: string;
  bottomRightStat?: ReactNode;
  tokensInfos: TokenInfo[] | TokenWithPrice[];
  defaultSelectedTokenMint?: string;
  onInputChange: (value: number) => void;
  onSelectedTokenChange?: (mint: string) => void;
  isTokenPopup?: boolean;
  onMax?: () => void;
  onHalf?: () => void;
  // v3
  topComp?: ReactNode;
}

const DropDownList = ({ tokensInfos, onSelect }: { tokensInfos: TokenInfo[]; onSelect: (option: string) => void }) => {
  return (
    <>
      {tokensInfos.map((tokenInfo) => {
        const { address, symbol } = tokenInfo;
        return (
          <DropdownItem
            title={symbol}
            startIcon={<TokenIcon tokenMint={address} size={24} />}
            onClick={() => onSelect(address)}
          />
        );
      })}
    </>
  );
};

export const LargeInput: FC<LargeInputProps> = forwardRef<HTMLInputElement, LargeInputProps>((props, ref) => {
  const {
    className,
    value,
    disabled,
    label,
    topStat,
    topComp,
    bottomLeftStat,
    bottomRightStat,
    variant = 'primary',
    isTokenPopup,
    tokensInfos,
    defaultSelectedTokenMint,
    onSelectedTokenChange = noop,
    onInputChange,
    onHalf,
    onMax,
    ...rest
  } = props;

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const filled = Boolean(value);
  const { selectedOption, onSelectedOptionChange, isVisible, onVisibleChange } = useDropdown({
    initialSelectedOption: defaultSelectedTokenMint || tokensInfos?.[0]?.address,
  });
  const availableTokens = tokensInfos.filter((token) => token.address !== selectedOption);
  const currentTokenInfo = tokensInfos.filter((token) => token.address === selectedOption)?.[0];
  const { symbol, decimals, address } = currentTokenInfo || {};
  const isKToken = isKTokenSymbol(symbol);
  const TokenComponent = isKToken ? KTokensWithText : TokensWithText;

  const handleSelect = (option: string) => {
    onSelectedOptionChange(option);
    onSelectedTokenChange(option);
    onVisibleChange(false);
    setIsPopupVisible(false);
  };

  const handleValueChange = (values: NumberFormatValues) => {
    onInputChange(values.floatValue || 0);
  };

  const handleTrigerClick = () => {
    setIsPopupVisible(true);
  };

  useEffect(() => {
    onSelectedOptionChange(defaultSelectedTokenMint || tokensInfos?.[0]?.address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedTokenMint]);

  const triggerElement = () => {
    if (!currentTokenInfo) {
      return null;
    }

    if (isTokenPopup) {
      return (
        <DropdownTrigger
          variant="ghost"
          onClick={handleTrigerClick}
          startIcon={<TokenIcon tokenMint={address} size={24} />}
          text={symbol}
          endIcon={<Icon name="ChevronDown" className={s.chevron} size={14} />}
          textProps={{
            fs: 16,
            lh: 22,
            className: s.title,
          }}
          isActive={isVisible}
        />
      );
    }

    if (availableTokens.length) {
      return (
        <Dropdown
          overlay={<DropDownList tokensInfos={availableTokens} onSelect={handleSelect} />}
          overlayClassName={s.dropDownList}
          isVisible={isVisible}
          onVisibleChange={onVisibleChange}
        >
          <DropdownTrigger
            variant="ghost"
            startIcon={<TokenIcon tokenMint={address} size={24} />}
            text={symbol}
            endIcon={<Icon name="ChevronDown" className={s.chevron} size={14} />}
            textProps={{
              fs: 16,
              lh: 22,
              className: s.title,
            }}
            isActive={isVisible}
          />
        </Dropdown>
      );
    }

    return (
      <TokenComponent
        tokensProps={{
          tokensAddresses: [address],
          size: 24,
        }}
        textProps={{
          fs: 16,
          lh: 22,
        }}
        text={symbol}
        align="left"
        gap={6}
      />
    );
  };

  return (
    <label htmlFor="input" className={sx('root', { disabled, filled, variant })}>
      <div className={s.top}>
        <Text fs={12} lh={12} className={sx('label')} color="grey-light">
          {label}
        </Text>
        {topStat && <Text fs={12} lh={12} color="grey-deep" className={s.value}>{`~${fusd(topStat)}`}</Text>}
        {topComp && topComp}
      </div>
      <div
        className={sx('inputContainer', className, {
          disabled,
          filled,
        })}
      >
        {triggerElement()}

        <NumericFormat
          getInputRef={ref}
          placeholder="0"
          className={sx('input', { disabled, filled, readonly: props.readOnly })}
          disabled={disabled}
          value={!value ? '' : value}
          decimalScale={decimals}
          id="input"
          allowNegative={false}
          autoComplete="off"
          onValueChange={handleValueChange}
          {...rest}
        />
      </div>
      {!bottomLeftStat && !bottomRightStat && !onHalf && !onMax ? null : (
        <div className={s.bottom}>
          {bottomLeftStat && (
            <Text fs={12} lh={12} color="grey-deep">
              {bottomLeftStat}
            </Text>
          )}
          {bottomRightStat || (
            <>
              {onHalf && onMax && (
                <div className={s.buttons}>
                  <div className={s.btn} onClick={onHalf}>
                    half
                  </div>
                  <div className={s.btn} onClick={onMax}>
                    max
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {isTokenPopup && (
        <TokensPopup
          selectedToken={selectedOption}
          isVisible={isPopupVisible}
          tokens={tokensInfos as TokenWithPrice[]}
          onSelect={handleSelect}
          onVisibleChange={() => setIsPopupVisible(false)}
        />
      )}
    </label>
  );
});
