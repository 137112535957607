import React from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@bruitt/classnames';

import paths from '../../../routes/paths';

import s from './HeaderBlurCircles.module.less';

const sx = withStyles(s);

const LISTING_PAGES = [paths.MAIN, paths.LENDING.BASE, paths.LENDING.LENDING_MULTIPLY, paths.LENDING.LENDING_LEVERAGE];
export const HeaderBlurCircles = () => {
  const location = useLocation();

  const isListingPage = LISTING_PAGES.includes(location.pathname);

  return (
    <div className={s.root}>
      <div className={sx(s.leftTopCircle, { isListingPage })} />
      <div className={sx(s.rightTopCircle, { isListingPage })} />
    </div>
  );
};
