import { ReactElement, SVGProps } from 'react';

export const SvgUserCheckBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 19c0-2.21 2.686-4 6-4s6 1.79 6 4M21 10l-4 4-2-2M9 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
