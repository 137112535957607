import React, { FC } from 'react';
import s from '../../MainMode.module.less';
import { SkeletonParagraph } from '../../../../../../shared/uiKit/SkeletonParagraph';

export const PortfolioValueSkeleton: FC = () => {
  return (
    <div className={s.balance}>
      <SkeletonParagraph width="70%" height={32} />
      <SkeletonParagraph width="50%" height={14} />
    </div>
  );
};
