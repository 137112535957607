import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { PublicKeyAddress } from '../../types/strategies';
import { FarmStateWithApy } from '../../types/farms';
import { QUERYKEYS } from '../../constants/queryKeys';

const useReserveFarms = () => {
  const queryClient = useQueryClient();

  const getFarmByAddress = useCallback(
    (address: PublicKeyAddress) => {
      return queryClient.getQueryData<FarmStateWithApy>(QUERYKEYS.getFarm(address));
    },
    [queryClient]
  );

  return { getFarmByAddress };
};

export default useReserveFarms;
