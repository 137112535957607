/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useRef, useState } from 'react';
import { Wallet } from '@solana/wallet-adapter-react';
import { WalletName } from '@solana/wallet-adapter-base';
import { withStyles } from '@bruitt/classnames';
import s from './DisclaimerPopup.module.less';

import { DEFAULT_SELECTED_WALLET_ICON, DEFAULT_SELECTED_WALLET_NAME } from './constants';
import { addPredefinedWallets, redirectIfWalletIsNotInstalled } from '../../utils';
import useEnv from '../../hooks/useEnv';
import { Modal } from '../../uiKitV2/Modal';
import { Text } from '../../uiKitV2/Typography';
import { Checkbox } from '../../uiKitV2/Checkbox';
import { Icon } from '../../uiKitV2/Icon';
import { Button } from '../../uiKitV2/Button';
import { Dropdown } from '../../uiKitV2/Dropdown';
import { DropdownItem } from '../../uiKitV2/DropdownItem';
import { DropdownTrigger } from '../../uiKitV2/DropdownTrigger';
import { useDropdown } from '../../uiKitV2/Dropdown/useDropdown';
import { Tooltip } from '../../uiKitV2/Tooltip';
import { TermsContent } from '../v2/Terms';
import { sortByMostPopularWallet } from '../../utils/kamino/sortByMostPopularWallet';
import { useLastSelectedWallet } from '../../../features/SidePanel/components/WalletConnectMode/hooks/useLastSelectedWallet';

const sx = withStyles(s);

interface DisclaimerPopupProps {
  isVisible: boolean;
  onConfirm: (walletName: WalletName<string> | null) => Promise<void>;
  onCancel: () => void;
}

export const DisclaimerPopup: FC<DisclaimerPopupProps> = ({ isVisible, onConfirm, onCancel }) => {
  const [isAgreeWithTerms, setAgreeWithTerms] = useState<boolean>(false);
  const [areTermsScrolledToBottom, setAreTermsScrolledToBottom] = useState<boolean>(false);
  const { wallets, isWalletConnecting } = useEnv();
  const { onVisibleChange, isVisible: isDropdownVisible } = useDropdown({});
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const { setLastSelectedWalletName } = useLastSelectedWallet();

  const walletsWithPredefinedWallets = sortByMostPopularWallet(addPredefinedWallets(wallets));

  const [selectedWalletName, setSelectedWalletName] = useState<WalletName<string> | null>(
    walletsWithPredefinedWallets[0] ? walletsWithPredefinedWallets[0].adapter.name : DEFAULT_SELECTED_WALLET_NAME
  );
  const [selectedWalletIcon, setSelectedWalletIcon] = useState<string>(
    walletsWithPredefinedWallets[0] ? walletsWithPredefinedWallets[0].adapter.icon : DEFAULT_SELECTED_WALLET_ICON
  );

  const handleSelectedWalletChange = (wallet: Wallet) => {
    setSelectedWalletName(wallet.adapter.name);
    setSelectedWalletIcon(wallet.adapter.icon);
  };

  const _onConfirm = async () => {
    await onConfirm(selectedWalletName);
    setLastSelectedWalletName(selectedWalletName);

    if (selectedWalletName) {
      const selectedWallet = walletsWithPredefinedWallets.find((wallet) => wallet.adapter.name === selectedWalletName);

      if (selectedWallet) {
        redirectIfWalletIsNotInstalled(wallets, selectedWallet);
      }
    }
  };

  const handleScroll = () => {
    const div = scrollableDivRef.current;

    if (!div || areTermsScrolledToBottom) {
      return;
    }

    const scrollDiff = div.scrollHeight - div.scrollTop;
    const nearBottom = div.clientHeight - scrollDiff + 10 > 0;

    if (nearBottom) {
      setAreTermsScrolledToBottom(true);
    }
  };

  return (
    <Modal isOpen={isVisible} onClose={onCancel} isFluid>
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.modalHeader}>
            <Text fs={20} lh={26}>
              Connect your Solana wallet
            </Text>
          </div>

          <div>
            <Dropdown
              overlay={
                <div>
                  {walletsWithPredefinedWallets.map((option) => {
                    const walletName = option.adapter.name;
                    const walletIcon = option.adapter.icon;
                    return (
                      <DropdownItem
                        key={walletName}
                        title={walletName}
                        startIcon={<img className={s.walletIcon} src={walletIcon} alt="base64 img" />}
                        onClick={() => {
                          handleSelectedWalletChange(option);
                          onVisibleChange(false);
                        }}
                      />
                    );
                  })}
                </div>
              }
              isVisible={isDropdownVisible}
              placement="bottomRight"
              onVisibleChange={onVisibleChange}
            >
              <DropdownTrigger
                isActive={isDropdownVisible}
                variant="secondary"
                justifyContent="spaceBetween"
                isFullWidth
                text={selectedWalletName || ''}
                startIcon={<img className={s.walletIcon} src={selectedWalletIcon} alt={selectedWalletName || ''} />}
                endIcon={<Icon name="ChevronSmallDown" size={16} />}
              />
            </Dropdown>
          </div>

          <Text color="grey" fs={14} lh={24} weight="regular" className={s.description}>
            By connecting a wallet, you agree to{' '}
            <a href="/terms" target="_blank" className={s.link}>
              Kamino’s Terms and Conditions
            </a>{' '}
            and acknowledge that you’ve read and understand{' '}
            <a className={s.link} href="https://docs.kamino.finance/" target="_blank" rel="noreferrer">
              the protocol documentation and associated risks
            </a>
          </Text>

          <div className={sx(s.collapseTerms)} onScroll={handleScroll} ref={scrollableDivRef}>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You represent and warrant that you are not a person and/or not representing a legal entity which resides
                in, is a citizen of, is located or incorporated in, has registered office in or is otherwise subject to
                the jurisdiction of the United States of America or any Prohibited Localities, and that you are not a
                Restricted Person (each as defined in these Terms). There are no exceptions, and you represent and
                warrant that you are not using a VPN and/or are not otherwise circumventing this prohibition.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You acknowledge that the Site Operator is not authorized or regulated by any financial market regulatory
                authority, including the United Kingdom Financial Conduct Authority (FCA). Nothing on this website or on
                Kamino Finance is intended to constitute marketing or promotion, and the content of this website and
                Kamino Finance is not intended for UK consumers. Consumer protections under UK regulations do not apply,
                and else you agree to waive any protection afforded under FCA regulations. The Site Operator is not
                located in the UK and does not provide services to the UK.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You must be permitted to use the Site and the Kamino Finance Smart Contract System under applicable
                laws, and you must always comply with all applicable laws. Such applicable laws may vary depending on
                where you are domiciled, located, incorporated, operate out of, etc., and only you are responsible for
                ensuring full compliance.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You acknowledge and agree that the Site is provided for informational purposes only and neither the Site
                Operator nor any individual or group of the Kamino Finance project team members, contributors, or
                similar are in control of Solana or the Kamino Finance Smart Contract System, have custody over your
                funds, the ability or duty to transact on your behalf or the power to reverse your transactions.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You acknowledge and agree that the Site does not constitute any financial advice and is only being
                provided 'as is' and 'as available' without any representation, warranties or guarantees whatsoever.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You acknowledge and agree that there is no legal or factual relationship between you and the Site
                Operator and/or any individual or group of the Kamino Finance project team members, contributors, or
                similar relating to Kamino Finance. The Kamino Finance Smart Contract System may be accessed through a
                variety of means and access requires third party software (e.g., wallets) for which you are fully
                responsible.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You acknowledge and agree that blockchain and DeFi, including the Kamino Finance Smart Contract System,
                are of novel and experimental nature and are associated with a variety of risks, which you have
                familiarized yourself with and accepted. The Site and the Kamino Finance Smart Contract System are
                available 'as is' and 'as available' without any representation, warranties or guarantees whatsoever and
                the Site Operator is not, to the maximum extent permitted under applicable law, liable for any damages
                incurred by you in connection with the use of the Site or the Kamino Finance Smart Contract System.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You, to the maximum extent permitted under applicable law, release all present and future claims against
                the Site Operator, and against any individual or group of Kamino Finance project team members,
                contributors or similar related to the use of the Kamino Finance Smart Contract System.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                By entering into these Terms, you waive your potential right to participate in class actions and agree
                that any disputes are resolved pursuant to binding, confidential arbitration, whereby your potential
                right to a jury trial is waived.
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                Your use of the Site and Kamino Finance is not prohibited by applicable law, and at all times compliant
                with applicable law, including but not limited to regulations on anti-money laundering (“AML”),
                anti-corruption, and counter-terrorist financing (“CTF”).
              </Text>
            </div>
            <div className={s.collapseDescription}>
              <div className={s.dot} />
              <Text color="grey-light" lh={24} weight="regular">
                You have not been previously suspended or removed from using our Site or Kamino Finance.
              </Text>
            </div>

            <TermsContent />
          </div>

          <div className={s.terms}>
            <Tooltip
              isFluid
              clickable
              render={() =>
                !areTermsScrolledToBottom
                  ? 'Please read & scroll through all Terms and Conditions before confirming.'
                  : null
              }
            >
              <Checkbox
                checked={isAgreeWithTerms}
                disabled={!areTermsScrolledToBottom}
                color="blue"
                title={<Text tag="span">I have read and I accept the Terms and Conditions</Text>}
                onChange={() => setAgreeWithTerms((prevState) => !prevState)}
              />
            </Tooltip>
          </div>

          <div key="3" className={s.button}>
            <Tooltip isFluid render={() => (!isAgreeWithTerms ? 'Please accept terms first' : null)}>
              <Button
                isLoading={isWalletConnecting}
                disabled={!isAgreeWithTerms || !areTermsScrolledToBottom || isWalletConnecting}
                onClick={() => _onConfirm()}
                isFullWidth
              >
                <Text>Connect Wallet</Text>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Modal>
  );
};
