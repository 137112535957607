import { useEffect } from 'react';
import { reaction } from 'mobx';
import { pointsPageStore } from '../store';
import { useUrlQuery } from '../../../shared/hooks/useUrlQuery';

export const useStore = () => {
  const { setQueryFromObject } = useUrlQuery();

  useEffect(() => {
    const disposer = reaction(
      () => ({
        seasonTab: pointsPageStore.seasonTab,
        tab: pointsPageStore.tab,
        breakdownSortBy: pointsPageStore.breakdownSortBy,
        leaderboardSortBy: pointsPageStore.leaderboardSortBy,
        currentPointBoostsSortBy: pointsPageStore.currentPointBoostsSortBy,
        pointsAllocationSortBy: pointsPageStore.pointsAllocationSortBy,
        page: pointsPageStore.page,
        pageSize: pointsPageStore.pageSize,
      }),
      (params) => {
        setQueryFromObject(params);
      }
    );

    return () => {
      disposer();
    };
  }, [setQueryFromObject]);

  return pointsPageStore;
};
