import { KaminoMarket, KaminoObligation, ObligationStats, Position } from '@kamino-finance/klend-sdk';
import Decimal from 'decimal.js';
import { DEFAULT_LEVERAGE } from '../../constants/multiply/leverage';
import { PublicKeyAddress } from '../../types/strategies';
import { notEmpty } from '../notEmpty';
import { isStablecoin } from '../utils';

type Stats = {
  killBuffer: number;
  liquidationDelta: number;
  maxLtv: number;
  totalUserLtv: number;
};

const INIT_DATA: Omit<ObligationStats, 'positions' | 'potentialElevationGroupUpdate'> &
  Stats & {
    obligationAddress: PublicKeyAddress;
    liquidationThreshold: Decimal;
  } & {
    hasDebt: boolean;
  } = {
  obligationAddress: '',
  liquidationThreshold: new Decimal(0),
  userTotalDeposit: new Decimal(0),
  userTotalBorrow: new Decimal(0),
  userTotalBorrowBorrowFactorAdjusted: new Decimal(0),
  userTotalCollateralDeposit: new Decimal(0),
  borrowLimit: new Decimal(0),
  borrowUtilization: new Decimal(0),
  netAccountValue: new Decimal(0),
  borrowLiquidationLimit: new Decimal(0),
  loanToValue: new Decimal(0),
  leverage: new Decimal(DEFAULT_LEVERAGE),
  // positions: 0,
  killBuffer: 0,
  liquidationDelta: 0,
  maxLtv: 0,
  liquidationLtv: new Decimal(0),
  totalUserLtv: 0,
  hasDebt: false,
};

interface PreCalcDepositsAndBorrowsType {
  market: KaminoMarket;
  depositsInput: Position[];
  borrowsInput: Position[];
  elevationGroup: number;
  simulationPercentage: number;
}

export const preCalcDepositsAndBorrows = ({
  market,
  depositsInput,
  borrowsInput,
  elevationGroup,
  simulationPercentage = 1,
}: PreCalcDepositsAndBorrowsType) => {
  let liquidationThresholdAccum = 0;
  let userTotalDeposit = 0;
  let userTotalBorrowFactorWeighted = 0;
  let minLiquidationBonus = 0;
  let maxLiquidationBonus = 0;

  const deposits: Deposit[] = depositsInput
    .map((deposit) => {
      const reserve = market.getReserveByAddress(deposit.reserveAddress);
      if (!reserve) {
        return null;
      }
      const reserveLiquidationLtv =
        elevationGroup !== 0
          ? market.state.elevationGroups[elevationGroup - 1].liquidationThresholdPct / 100
          : reserve.state.config.liquidationThresholdPct / 100;

      minLiquidationBonus = Math.max(minLiquidationBonus, reserve.stats.minLiquidationBonus);
      maxLiquidationBonus = Math.max(maxLiquidationBonus, reserve.stats.maxLiquidationBonus);

      liquidationThresholdAccum += deposit.marketValueRefreshed.toNumber() * reserveLiquidationLtv;

      if (isStablecoin(reserve.symbol)) {
        userTotalDeposit += deposit.marketValueRefreshed.toNumber();
      } else {
        userTotalDeposit += deposit.marketValueRefreshed.toNumber() * simulationPercentage;
      }

      return [reserve.symbol, deposit.marketValueRefreshed.toNumber(), reserveLiquidationLtv] as Deposit;
    })
    .filter(notEmpty);

  const borrows: Borrow[] = borrowsInput
    .map((borrow) => {
      const reserve = market.getReserveByAddress(borrow.reserveAddress);
      if (!reserve) {
        return null;
      }
      const reserveBorrowFactor = elevationGroup !== 0 ? 1 : reserve.stats.borrowFactor / 100;

      minLiquidationBonus = Math.max(minLiquidationBonus, reserve.stats.minLiquidationBonus);
      maxLiquidationBonus = Math.max(maxLiquidationBonus, reserve.stats.maxLiquidationBonus);

      if (isStablecoin(reserve.symbol)) {
        userTotalBorrowFactorWeighted += borrow.marketValueRefreshed.toNumber() * reserveBorrowFactor;
      } else {
        userTotalBorrowFactorWeighted +=
          borrow.marketValueRefreshed.toNumber() * reserveBorrowFactor * simulationPercentage;
      }

      return [reserve.symbol, borrow.marketValueRefreshed.toNumber(), reserveBorrowFactor] as Borrow;
    })
    .filter(notEmpty);

  const liquidationThreshold = liquidationThresholdAccum / userTotalDeposit;
  const currentLtv = userTotalBorrowFactorWeighted / userTotalDeposit;
  return {
    deposits,
    borrows,
    liquidationThreshold,
    userTotalBorrowFactorWeighted,
    currentLtv,
    maxLiquidationBonus,
    minLiquidationBonus,
  };
};

export const getObligationDepositPositions = (obligation: KaminoObligation) => {
  return Array.from(obligation.deposits.values());
};

export const getObligationBorrowPositions = (obligation: KaminoObligation) => {
  return Array.from(obligation.borrows.values());
};

export const getIsObligationSingleCollateralAndDebt = ({ obligation, deposits, borrows }: ObligationPositions) => {
  const depositsInput = deposits || getObligationDepositPositions(obligation);
  const borrowsInput = borrows || getObligationBorrowPositions(obligation);

  return depositsInput.length === 1 && borrowsInput.length === 1;
};

export const getIsObligationLong = (supplySymbol?: string, debtSymbol?: string): boolean | undefined => {
  // isLong if debt  is a stablecoin & collateral is not a stablecoin
  const isLong = isStablecoin(debtSymbol) && !isStablecoin(supplySymbol);

  // isShort if collateral is a stablecoin & debt is not a stablecoin
  const isShort = isStablecoin(supplySymbol) && !isStablecoin(debtSymbol);

  if (isShort) {
    return false;
  }

  if (isLong) {
    return true;
  }

  return undefined;
};

interface ObligationPositions {
  obligation: KaminoObligation;
  deposits?: Position[];
  borrows?: Position[];
}

export const getObligationFirstPosition = ({ obligation, deposits, borrows }: ObligationPositions) => {
  const depositsInput = deposits || getObligationDepositPositions(obligation);
  const borrowsInput = borrows || getObligationBorrowPositions(obligation);

  return { deposit: depositsInput[0], borrow: borrowsInput[0] };
};

export const getObligationStats = (market: KaminoMarket, obligation?: KaminoObligation | null) => {
  if (!obligation) {
    return INIT_DATA;
  }

  const { refreshedStats: obligationRefreshedStats, obligationAddress } = obligation;
  const { borrowLimit, userTotalDeposit } = obligationRefreshedStats;

  const hasDebt = obligationRefreshedStats.userTotalBorrow.toNumber() > 0;

  const currentLtv = obligation.refreshedStats.loanToValue.toNumber();
  const liquidationLtv = !hasDebt ? new Decimal(1) : obligation.refreshedStats.liquidationLtv;

  const { deposits, borrows, liquidationThreshold } = preCalcDepositsAndBorrows({
    market,
    depositsInput: getObligationDepositPositions(obligation),
    borrowsInput: getObligationBorrowPositions(obligation),
    elevationGroup: obligation.state.elevationGroup,
    simulationPercentage: 1,
  });

  const stats: Stats = {
    killBuffer: calcKillBuffer(currentLtv, liquidationLtv.toNumber()),
    liquidationDelta: calcLiquidationDelta(deposits, borrows, 1),
    maxLtv: borrowLimit.div(userTotalDeposit).toNumber(),
    totalUserLtv: currentLtv,
  };

  return {
    obligationAddress,
    ...obligationRefreshedStats,
    liquidationLtv,
    ...stats,
    liquidationThreshold,
    hasDebt,
  };
};

export const calcKillBuffer = (currentLtv: number, liquidationLtv: number) => {
  return liquidationLtv - currentLtv;
};

export type Deposit = [string, number, number]; // [mint, depositValue, liquidationLtv]
export type Borrow = [string, number, number]; // [mint, borrowValue, borrowFactor]

export function calcLiquidationDelta(deposits: Deposit[], borrows: Borrow[], simulationPct: number): number {
  // 0.50
  // $22.54
  // 22.45 * (1-0.5129) = 10 / 10.935395 = 0.9144617089734756 /  = 1.0935394999999999
  // 10/0.7 = 14.285714285714286
  // 14.285714285714286 / 22.45 = (1-0.6363347120585429) = 0.36366528794145714
  // 4.73%
  // 10.00
  // $10.00
  // 0.71%

  const simulationPercentage = simulationPct * 100 - 100;
  // simulation_percentage: percentage by which to increase volatile token prices (-15 means reduce vol token prices by 15%)

  // Calculate liq LTV adjusted deposits for volatile and stable separately
  let volatileDepositsAdjValue = 0;
  let stableDepositsAdjValue = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [asset, depositValue, liquidationLtvPerc] of deposits) {
    if (!isStablecoin(asset)) {
      volatileDepositsAdjValue += ((depositValue * (100 + simulationPercentage)) / 100) * liquidationLtvPerc;
    } else {
      stableDepositsAdjValue += depositValue * liquidationLtvPerc;
    }
  }

  // Calculate borrow factor adjusted borrows for volatile and stable separately
  let volatileBorrowsAdjValue = 0;
  let stableBorrowsAdjValue = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const [asset, borrowValue, borrowFactor] of borrows) {
    if (!isStablecoin(asset)) {
      volatileBorrowsAdjValue += (borrowValue * borrowFactor * (100 + simulationPercentage)) / 100;
    } else {
      stableBorrowsAdjValue += borrowValue * borrowFactor;
    }
  }

  // Calculate net adj values
  const netStablesAdj = stableDepositsAdjValue - stableBorrowsAdjValue;
  const netVolatilesAdj = volatileDepositsAdjValue - volatileBorrowsAdjValue;

  // Calculate liquidation delta
  if (netStablesAdj + netVolatilesAdj < 0) {
    return 0; // Already at a point of being liquidated
  }

  if (netStablesAdj > 0 && netVolatilesAdj > 0) {
    return 0; // Will never be liquidated
  }

  if (netVolatilesAdj === 0) {
    return 0; // Will never be liquidated
  }

  if (netStablesAdj === 0) {
    return 0; // Will never be liquidated
  }

  const liquidationDelta = 1 - netStablesAdj / -netVolatilesAdj;

  return liquidationDelta;
}

function calculateLiquidationPriceLong(
  totalSuppliedAmount: number,
  totalBorrowedAmount: number,
  liquidationLtv: number,
  borrowFactor: number
): number {
  return (totalBorrowedAmount * borrowFactor) / (totalSuppliedAmount * liquidationLtv);
}

function calculateLiquidationPriceShort(
  totalSuppliedAmount: number,
  totalBorrowedAmount: number,
  liquidationLtv: number,
  borrowFactor: number
): number {
  return (totalSuppliedAmount * liquidationLtv) / (totalBorrowedAmount * borrowFactor);
}

export function calculateLiquidationPrice({
  totalSuppliedAmount,
  totalBorrowedAmount,
  liquidationLtv,
  isShort,
  borrowFactor,
}: {
  totalSuppliedAmount: number;
  totalBorrowedAmount: number;
  liquidationLtv: number;
  isShort?: boolean;
  borrowFactor: number;
}): number {
  if (isShort) {
    return calculateLiquidationPriceShort(totalSuppliedAmount, totalBorrowedAmount, liquidationLtv, borrowFactor);
  }

  return calculateLiquidationPriceLong(totalSuppliedAmount, totalBorrowedAmount, liquidationLtv, borrowFactor);
}
