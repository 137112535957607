import { ReactElement, SVGProps } from 'react';

export const SvgWallet = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 5.333v5.866c0 .747 0 1.12.145 1.406.128.25.332.454.583.582.285.146.659.146 1.405.146h7.734c.746 0 1.12 0 1.405-.146.25-.127.455-.331.583-.582.145-.286.145-.659.145-1.406V7.133c0-.434 0-.651-.05-.829a1.333 1.333 0 0 0-.921-.921c-.178-.05-.395-.05-.829-.05m-10.2 0v-.534c0-.746 0-1.12.145-1.405.128-.25.332-.455.583-.583.285-.145.659-.145 1.405-.145h5.934c.746 0 1.12 0 1.405.145.25.128.455.332.583.583.145.285.145.659.145 1.405v.534m-10.2 0h10.2M14 7.999h-1.333a1.333 1.333 0 1 0 0 2.667H14V7.999Z"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
