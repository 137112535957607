import { FC, useMemo } from 'react';

import Decimal from 'decimal.js';
import { useMixpanel } from 'react-mixpanel-browser';
import { Text } from '../../shared/uiKitV2/Typography';
import { Button } from '../../shared/uiKitV2/Button';
import { Modal } from '../../shared/uiKitV2/Modal';

import s from './PriceImpactWarningModal.module.less';
import { formatNumber, getPriceImpactDangerLevel, PriceImpactDangerLevel, PriceImpactType } from '../../shared/utils';
import { Icon } from '../../shared/uiKitV2/Icon';
import { checkPriceDivergenceThreshold } from '../LeverageForms/utils';
import { MAX_SAFE_SLIPPAGE } from '../../shared/constants/slippage';

const { formatTokenAllDecimals: ftad, formatPercent: fp } = formatNumber;

interface PriceImpactWarningModalProps {
  type: PriceImpactDangerLevel;
  priceImpactType: PriceImpactType;
  priceImpactBps: Decimal;
  theoreticalPriceDivergenceBps: Decimal;
  marketPrice: Decimal;
  theoreticalPrice: Decimal;
  slippage?: Decimal;
  isVisible: boolean;
  onCancel: () => void;
  onOk: () => void;
  isLoading: boolean;
  isOverSidepanel?: boolean;
}

function getButtonText(
  priceImpactType: PriceImpactType,
  priceImpactBps: Decimal,
  dangerLevel: PriceImpactDangerLevel,
  theoreticalPriceDivergenceBps: Decimal,
  slippage: Decimal | undefined
) {
  const isPriceDivergence = checkPriceDivergenceThreshold(priceImpactType, theoreticalPriceDivergenceBps);
  const isDangerLevelAboveSave = dangerLevel > PriceImpactDangerLevel.safe;
  const isSlippageAboveSave = slippage && slippage?.greaterThan(MAX_SAFE_SLIPPAGE);

  if (isPriceDivergence && isDangerLevelAboveSave) {
    return `Continue with ${fp(priceImpactBps.div(100))} price impact and ${fp(
      theoreticalPriceDivergenceBps.div(100)
    )} price divergence`;
  }

  if (isPriceDivergence) {
    return `Continue with ${fp(theoreticalPriceDivergenceBps.div(100))} price divergence`;
  }

  if (isDangerLevelAboveSave) {
    return `Continue with ${fp(priceImpactBps.div(100))} price impact`;
  }

  if (isSlippageAboveSave) {
    return `Proceed With ${fp(slippage?.toNumber(), 2, true)} Slippage`;
  }

  return 'Continue';
}

function getTitleText(
  priceImpactType: PriceImpactType,
  dangerLevel: PriceImpactDangerLevel,
  theoreticalPriceDivergenceBps: Decimal,
  slippage: Decimal | undefined
) {
  const isPriceDivergence = checkPriceDivergenceThreshold(priceImpactType, theoreticalPriceDivergenceBps);
  const isDangerLevelAboveSave = dangerLevel > PriceImpactDangerLevel.safe;
  const isSlippageAboveSave = slippage && slippage?.greaterThan(MAX_SAFE_SLIPPAGE);

  if (isSlippageAboveSave && !(isPriceDivergence || isDangerLevelAboveSave)) {
    return `Slippage Warning!`;
  }

  return 'Price Impact Warning';
}

function getButtonVariant(dangerLevel: PriceImpactDangerLevel) {
  if (dangerLevel === PriceImpactDangerLevel.danger) {
    return 'danger';
  }

  return 'warning';
}

function getIconColor(dangerLevel: PriceImpactDangerLevel) {
  return dangerLevel === PriceImpactDangerLevel.danger ? 'red' : 'yellow';
}

export const PriceImpactWarningModal: FC<PriceImpactWarningModalProps> = ({
  type,
  priceImpactType,
  priceImpactBps,
  theoreticalPriceDivergenceBps,
  marketPrice,
  theoreticalPrice,
  slippage,
  isVisible,
  isLoading,
  onCancel,
  onOk,
  isOverSidepanel,
}) => {
  const mixpanel = useMixpanel();
  const priceImpactDangerLevel = getPriceImpactDangerLevel(priceImpactType, priceImpactBps);

  const handleOkClick = () => {
    mixpanel.track('price_impact:modal:confirm', {
      priceImpactPct: priceImpactBps.div(100).toFixed(),
      marketPrice: marketPrice.toFixed(),
      theoreticalPrice: theoreticalPrice.toString(),
    });
    onOk();
  };

  const handleClose = () => {
    mixpanel.track('price_impact:modal:cancel', {
      priceImpactPct: priceImpactBps.div(100).toFixed(),
      marketPrice: marketPrice.toFixed(),
      theoreticalPrice: theoreticalPrice.toString(),
    });
    onCancel();
  };

  const titleText = useMemo(() => {
    return getTitleText(priceImpactType, priceImpactDangerLevel, theoreticalPriceDivergenceBps, slippage);
  }, [priceImpactType, priceImpactDangerLevel, slippage, theoreticalPriceDivergenceBps]);

  const buttonText = useMemo(() => {
    return getButtonText(
      priceImpactType,
      priceImpactBps,
      priceImpactDangerLevel,
      theoreticalPriceDivergenceBps,
      slippage
    );
  }, [priceImpactType, priceImpactBps, priceImpactDangerLevel, slippage, theoreticalPriceDivergenceBps]);

  return (
    <Modal isOpen={isVisible} onClose={handleClose} isOverSidepanel={isOverSidepanel}>
      <Text fs={22} lh={32} weight="medium" className={s.title}>
        <Icon name="Info" size={24} color={getIconColor(type)} />
        {titleText}
      </Text>

      <div className={s.content}>
        {priceImpactDangerLevel > PriceImpactDangerLevel.safe ? (
          <div className={s.paragraph}>
            The estimated price impact for this transaction is <b>{fp(priceImpactBps.div(100))}</b>. Proceed with
            caution
          </div>
        ) : null}

        {checkPriceDivergenceThreshold(priceImpactType, theoreticalPriceDivergenceBps) ? (
          <div className={s.paragraph}>
            Market price <b>({ftad(marketPrice, 9)})</b> and oracle price <b>({ftad(theoreticalPrice, 9)})</b> are
            different by <b>{fp(theoreticalPriceDivergenceBps.div(100))}</b>.
          </div>
        ) : null}

        {slippage?.greaterThan(MAX_SAFE_SLIPPAGE) ? (
          <div className={s.paragraph}>
            Your maximum slippage setting is {fp(slippage?.toNumber(), 2, true)}. It’s generally recommended to keep
            this below 1%.
          </div>
        ) : null}

        <div className={s.paragraph}>Are you sure you want to proceed?</div>
      </div>

      <div className={s.buttons}>
        <Button
          size="large"
          isFullWidth
          variant={getButtonVariant(type)}
          onClick={handleOkClick}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {buttonText}
        </Button>

        <Button size="large" isFullWidth variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
