import { useQuery } from '@tanstack/react-query';
import { PublicKeyAddress } from '../../../types/strategies';
import useEnv from '../../useEnv';
import { featureFlags } from '../../../utils';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { fetchObligationPnl } from '../../../services/lending/fetchObligationPnl';

interface UseObligationPnlQueryProps {
  marketAddress?: PublicKeyAddress;
  obligationAddress?: PublicKeyAddress;
  enabled?: boolean;
}

export const useObligationPnlQuery = ({
  marketAddress,
  obligationAddress,
  enabled = true,
}: UseObligationPnlQueryProps) => {
  const { walletPublicKey } = useEnv();

  const queryFn = async () => {
    if (!marketAddress || !obligationAddress) {
      return;
    }

    return fetchObligationPnl({ marketAddress, obligationAddress });
  };

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.getObligationPnl(obligationAddress?.toString() || '-'),
    queryFn,
    enabled:
      enabled &&
      featureFlags.isLendingPageEnabled &&
      Boolean(marketAddress) &&
      Boolean(walletPublicKey || obligationAddress),
  });

  return {
    data,
    ...rest,
  };
};
