import twitter from '../../../assets/twitter.svg';
import discord from '../../../assets/discord.svg';
import { SOCIAL_LINKS } from '../../constants/links';

export const FOOTER_LOGOS_V2 = [
  {
    img: discord,
    link: SOCIAL_LINKS.discord,
    alt: 'discord',
  },
  {
    img: twitter,
    link: SOCIAL_LINKS.twitter,
    alt: 'twitter',
  },
];
