import { withStyles } from '@bruitt/classnames';
import { FC } from 'react';
import s from './Divider.module.less';

const sx = withStyles(s);

export interface DividerProps {
  className?: string;
  variant?: 'horizontal' | 'vertical';
}

export const Divider: FC<DividerProps> = ({ className, variant = 'horizontal' }) => {
  return (
    <div
      className={sx('root', className, {
        variant,
      })}
    />
  );
};
