export const LINKS = {
  production: 'https://app.kamino.finance',
  insurance: 'https://app.amulet.org/#/buy-covers',
  kaminoDocs: 'https://docs.kamino.finance/',
  kaminoBlog: 'https://blog.kamino.finance/',
  riskDashboard: 'https://risk.kamino.finance',
  documentation: 'https://docs.kamino.finance',
  github: 'https://github.com/Kamino-Finance',
  riskFramework: 'https://docs.kamino.finance/v/risk',
  litepaper: 'https://docs.kamino.finance/kamino-lend-litepaper',
  buyCover: 'https://solana.amulet.org/#/cover/buy-covers',
};

export const SOCIAL_LINKS = {
  discord: 'https://discord.com/invite/kaminofinance',
  twitter: 'https://twitter.com/KaminoFinance',
  reddit: 'https://www.reddit.com/r/kamino_Finance',
  telegram: 'https://t.me/+-g2MzVQJtrI2ZGFk',
};
