import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CollateralInfoJSON } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/types';
import { CollateralInfos } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import { QUERYKEYS } from '../constants/queryKeys';
import { notify } from '../utils/notifications/notifications';
import { useGlobalConfig } from './useGlobalConfig';
import { useWeb3Client } from './useWeb3Client';

const initialData: CollateralInfoJSON[] = [];
const emptyMint = '11111111111111111111111111111111';

// The select function from reactQuery will trigger on every re-render and return new data.
// To avoid this, we need to add the function to useCallback, or move it outside of the hook.
// As a result, the select function will only be triggered when queryData changes.
// Reference to the official documentation: https://tkdodo.eu/blog/react-query-data-transformations
const filterEmptyMints = (data: CollateralInfos | null | undefined) =>
  (data && data.toJSON().infos.filter((info) => info.mint !== emptyMint)) || initialData;

export const useCollateralInfos = () => {
  const { data: globalConfig } = useGlobalConfig();
  const { web3client } = useWeb3Client();

  const queryFn = async () => {
    if (!web3client || !globalConfig) {
      throw new Error('wallet is not connected');
    }
    return CollateralInfos.fetch(web3client.connection, globalConfig.tokenInfos);
  };

  const { data: queryData, ...rest } = useQuery({
    queryKey: QUERYKEYS.collateralInfos,
    enabled: Boolean(web3client) && Boolean(globalConfig),
    queryFn,
    select: filterEmptyMints,
    cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
    onError: () => {
      notify({
        message: 'Failed to load tokens info',
        type: 'error',
      });
    },
  });

  const data = useMemo(() => {
    return queryData || initialData;
  }, [queryData]);

  const mints = useMemo(() => {
    return data.map((info) => info.mint);
  }, [data]);

  return {
    data,
    mints,
    ...rest,
  };
};
