function buildLendingRoutes(basePath: string) {
  return {
    LENDING_BORROWLEND_VAULTS: `${basePath}`,
    LIMIT_ORDER: `${basePath}/limit-order`,
    LENDING_LUQUIDATE: `${basePath}/liquidate`,
    LENDING_RESERVE: `${basePath}/reserve/:marketAddress/:reserveAddress`,
    LENDING_DASHBOARD: `${basePath}/dashboard/:marketAddress/:obligationAddress:params`,
    LENDING_DASHBOARD_LEVERAGE: `${basePath}/leverage/dashboard/:marketAddress/:obligationAddress`,
    LENDING_OBLIGATION: `${basePath}/obligation/:obligationAddress`,
    LENDING_VAULTS: `${basePath}/vaults`,
    LENDING_VAULT: `${basePath}/vault/:marketAddress/:reserveAddress`,
    LENDING_MULTIPLY: `${basePath}/multiply`,
    LENDING_EARN: `${basePath}/earn`,
    LENDING_EARN_VAULT: `${basePath}/earn/:vaultAddress`,
    LENDING_LEVERAGE: `${basePath}/leverage`,
    LENDING_MULTIPLY_VAULT: `${basePath}/multiply/:marketAddress/:depositReserveAddress/:borrowReserveAddress`,
    LENDING_MULTIPLY_VAULT_SINGLE: `${basePath}/multiply/:marketAddress/:depositReserveAddress`,
    LENDING_LEVERAGE_VAULT: `${basePath}/leverage/:marketAddress/:depositReserveAddress/:borrowReserveAddress`,
    LENDING_LEVERAGE_VAULT_LONG_SHORT: `${basePath}/leverage/:marketAddress/:depositReserveAddress/:borrowReserveAddress/:longOrShort`,
  };
}

const LENDING_BASE_V3 = '/v3/lending';

const LENDING_BASE = '/lending';

const paths = {
  MAIN: '/',
  LIQUIDITY: '/liquidity',
  CREATE: '/create',

  EDIT: '/edit/:strategyAddress',
  PORTFOLIO: '/my-portfolio',
  PORTFOLIO_WITH_OWNER: '/my-portfolio/:owner?',
  ADVANCED_ANALYTICS_FULL: '/liquidity/:strategyAddress/advanced-analytics',
  ADVANCED_ANALYTICS_SHORT: '/liquidity/advanced-analytics',
  // KEEP OLD PAGE FOR EASIER TESTS WHILE REDESIGN IS IN PROGRESS
  // REMOVE WHEN NEW PAGE IS DONE
  PORTFOLIO_V1: '/liquidity/my-portfolio/v1',
  STRATEGY_SHORT: '/liquidity/:strategyAddress',
  STRATEGY_FULL: '/liquidity/:tokenA-:tokenB-:provider/:strategyAddress',
  STRATEGY_SHORT_DEBUG: '/liquidity/:strategyAddress/debug',
  STRATEGY_FULL_DEBUG: '/liquidity/:tokenA-:tokenB-:provider/:strategyAddress/debug',
  TERMS: '/terms',
  LENDING: {
    BASE: LENDING_BASE,
    ...buildLendingRoutes(LENDING_BASE),
  },
  LENDING_V3: {
    BASE: LENDING_BASE_V3,
    ...buildLendingRoutes(LENDING_BASE_V3),
  },
  EARN: '/earn',
  POINTS: '/points',
  REFERRAL: '/referrals',
  GENESIS: '/genesis',
  S2AIRDROP: '/season-2-airdrop',
  STAKE: '/stake',
  GOVERNANACE_AND_STAKING: '/governance-and-staking',
  TRADINGVIEW: '/tradingview',
  PROPOSAL: '/governance-and-staking/proposals/:proposalId',
  LIMIT_ORDER: '/limit-order',
};

export default paths;
