import { ReactElement, SVGProps } from 'react';

export const SvgCards = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 18.688v-4.375c0-.173-.448-.313-1-.313h-3c-.552 0-1 .14-1 .313v4.374c0 .173.448.313 1 .313h3c.552 0 1-.14 1-.313ZM19 9.688V5.311C19 5.14 18.552 5 18 5h-3c-.552 0-1 .14-1 .313v4.375c0 .172.448.312 1 .312h3c.552 0 1-.14 1-.313ZM10 18.688v-4.375C10 14.14 9.552 14 9 14H6c-.552 0-1 .14-1 .313v4.374c0 .173.448.313 1 .313h3c.552 0 1-.14 1-.313ZM10 9.688V5.311C10 5.14 9.552 5 9 5H6c-.552 0-1 .14-1 .313v4.375C5 9.86 5.448 10 6 10h3c.552 0 1-.14 1-.313Z"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);
