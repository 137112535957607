import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { TokenInfo } from '../types/token-info';
import { isNativeSolMintStr } from '../utils/tokens';
import { Token } from '../constants/tokens';
import { QUERYKEYS } from '../constants/queryKeys';
import { getTokenByMintHelper, getTokenHelper, getTokenInfoMaps, getTokenMintHelper, UNKNOWN_TOKEN } from './helpers';
import { DECIMALS_SOL } from '../constants/math';

const getTokenInfoByMintStr = (mint: string, tokensInfoMap?: Record<string, TokenInfo>): TokenInfo => {
  //  edge case for native SOL
  if (isNativeSolMintStr(mint)) {
    return {
      name: 'SOL',
      address: mint,
      symbol: 'SOL',
      decimals: DECIMALS_SOL,
      logoURI: null,
    };
  }
  // TODO: USDR is not returned by the API anymore, use another API or even onchain data?
  if (mint === 'USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2') {
    return {
      name: 'USDR',
      address: mint,
      symbol: 'USDR',
      decimals: 6,
      logoURI: null,
    };
  }
  const info = tokensInfoMap?.[mint];

  if (!info) {
    // throw new Error(`Token mint ${mint} is not found`);
    return { ...UNKNOWN_TOKEN, address: mint };
  }
  return info;
};

function useTokens() {
  const queryClient = useQueryClient();
  const tokensInfoApi = queryClient.getQueryData<TokenInfo[]>(QUERYKEYS.tokensInfosApi);
  const tokensInfoFallback = queryClient.getQueryData<TokenInfo[]>(QUERYKEYS.tokensInfosFallback);
  const tokensInfo = tokensInfoApi || tokensInfoFallback;

  const tokensInfoMap: Record<string, TokenInfo> = useMemo(() => getTokenInfoMaps(tokensInfo), [tokensInfo]);

  const getTokenByMint = useCallback(getTokenByMintHelper(tokensInfo), [tokensInfo]);

  const getTokenMint: (token: Token) => string = useCallback(getTokenMintHelper(tokensInfo), [tokensInfo]);

  const getToken = useCallback(getTokenHelper(tokensInfoMap), [tokensInfoMap]);

  const getTokenByMintString = useCallback(
    (mint: string): TokenInfo => getTokenInfoByMintStr(mint, tokensInfoMap),
    [tokensInfoMap]
  );

  return {
    getTokenByMint,
    getTokenMint,
    getToken,
    tokensInfoMap,
    tokensInfo,
    getTokenByMintString,
  };
}

export default useTokens;
