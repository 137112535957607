import { makeAutoObservable } from 'mobx';
import { getChain, getEnvChain } from '../providers/utils';
import { Chain, ENV } from '../shared/services/web3/types';

export interface NetworkProviderParams {
  env: ENV;
  chain: Chain;
}

export interface NetworkProviderStore extends NetworkProviderParams {
  updateProviderParams: (params: Partial<NetworkProviderParams>) => void;
}

export const getNetworksProviderDefaultValue = (): NetworkProviderParams => ({
  env: getEnvChain(),
  chain: getChain(),
});

const store = makeAutoObservable<NetworkProviderStore>({
  ...getNetworksProviderDefaultValue(),

  // arrow function and 'store' object are used to prevent missing "this" context
  updateProviderParams: (params: Partial<NetworkProviderParams>) => {
    Object.assign(store, params);
  },
});

export { store as networkProviderStore };
