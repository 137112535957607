import { KaminoMarket, KaminoObligation } from '@kamino-finance/klend-sdk';
import { MarketFromResourcesResponse, MarketResponse, ObligationPnlResponse } from '../shared/types/lending';
import { AppSchema } from './types/appSchema';
import { TokenInfo } from '../shared/types/token-info';
import { LeverageVaultTableData } from '../pages/lendingV2/LeverageList/types';

// TODO: fix this, import/no-cycle
// eslint-disable-next-line
import { FormState } from '../features/LeverageForms/types';

// Helper types
export type MarketFromResources = (MarketFromResourcesResponse | MarketResponse)[];

export interface ObligationStats {
  totalBorrowAmount: number;
  totalCollateralAmount: number;
  totalBorrowValue: number;
  totalCollateralValue: number;
  ltv: number;
  netApy: number;
  netValue: number;
  netValueUsd: number;
  netValueCollToken: number;
  leverage: number;
  collateralTokenInfo: TokenInfo;
  debtTokenInfo: TokenInfo;
  borrowTokenPrice: number;
  collateralTokenPrice: number;
  liquidationLtv: number;
  maxLtv: number;
  liquidationPrice: number;
  killBuffer: number;
  priceCollToDebt: number;
  utilization: number;
  liquidStakingApy: number;
  supplyApy: number;
  borrowApy: number;
  borrowFee: number;
}

export interface GetObligationStatsData {
  simulated: ObligationStats;
  current: ObligationStats;
  hasDiffs: boolean;
}

export enum Page {
  leverageList = 'Leverage List',
  leverageV3 = 'Leverage V3',
}

// Constants and their types
// Data worker (internal)
export const WorkerInternalMarkets = 'worker:internal:markets';
export type EventKeyInternalMarkets = typeof WorkerInternalMarkets;

export const WorkerInternalLeverageObligations = 'worker:internal:leverage:obligations';
export type EventKeyInternalLeverageObligations = typeof WorkerInternalLeverageObligations;

// Data worker
export const WorkerDataMarketsFromResources = 'worker:data:marketsFromResources';
export type EventKeyWorkerDataMarketsFromResources = typeof WorkerDataMarketsFromResources;

// export const WorkerDataCreateLongShortVaults = 'worker:data:createLongShortVaults';
// export type EventKeyWorkerDataCreateLongShortVaults = typeof WorkerDataCreateLongShortVaults;

export const WorkerDataPrices = 'worker:data:prices';
export type EventKeyWorkerDataPrices = typeof WorkerDataPrices;

export const WorkerDataStakingYields = 'worker:data:stakingYields';
export type EventKeyWorkerDataStakingYields = typeof WorkerDataStakingYields;

export const WorkerDataCurrentSlot = 'worker:data:currentSlot';
export type EventKeyWorkerDataCurrentSlot = typeof WorkerDataCurrentSlot;

export const WorkerDataTokenInfos = 'worker:data:tokenInfos';
export type EventKeyWorkerDataTokenInfos = typeof WorkerDataTokenInfos;

// Main worker
export const WorkerMainUpdateStoreMarketsInit = 'worker:main:updateStore:marketsInit';
export type EventKeyWorkerMainUpdateStoreMarketsInit = typeof WorkerMainUpdateStoreMarketsInit;

export const WorkerMainUpdateStoreCurrentSlot = 'worker:main:updateStore:currentSlot';
export type EventKeyWorkerMainUpdateStoreCurrentSlot = typeof WorkerMainUpdateStoreCurrentSlot;

export const WorkerMainUpdateStoreLongShortVaults = 'worker:main:updateStore:LongShortVaults';
export type EventKeyWorkerMainUpdateStoreLongShortVaults = typeof WorkerMainUpdateStoreLongShortVaults;

// FormStats worker
export const WorkerFormStatsUpdate = 'worker:formStats:update';
export type EventKeyWorkerFormStatsUpdate = typeof WorkerFormStatsUpdate;

// Leverage worker

export const WorkerFormStatsV3Update = 'worker:formStatsV3:update';
export type EventKeyWorkerFormStatsV3Update = typeof WorkerFormStatsV3Update;

export const WorkerLeveragePnlV3Update = 'worker:levaragePnlV3:update';
export type EventKeyWorkerLeveragePnlV3Update = typeof WorkerLeveragePnlV3Update;

// Ui
export const WorkerUiUpdateWalletAddress = 'worker:ui:update:walletAddress';
export type EventKeyWorkerUiUpdateWalletAddresss = typeof WorkerUiUpdateWalletAddress;

export const WorkerUiUpdatePageInit = 'worker:ui:update:pageInit';
export type EventKeyWorkerUiUpdatePageInit = typeof WorkerUiUpdatePageInit;

export const WorkerUiLeverageVaultPageParams = 'worker:ui:update:leverageVaultPageParams';
export type EventKeyWorkerUiLeverageVaultPageParams = typeof WorkerUiLeverageVaultPageParams;

export const WorkerUiLeverageVault = 'worker:ui:update:leverageVault';
export type EventKeyWorkerUiLeverageVault = typeof WorkerUiLeverageVault;

export const WorkerUiLeverageFormData = 'worker:ui:update:leverageFormData';
export type EventKeyWorkerUiLeverageFormData = typeof WorkerUiLeverageFormData;

export const WorkerUiLeverageBaseQuote = 'worker:ui:update:leverageBaseQuote';
export type EventKeyWorkerUiLeverageBaseQuote = typeof WorkerUiLeverageBaseQuote;

export const WorkerUiLeverageForceUpdate = 'worker:ui:update:leverageForceUpdate';
export type EventKeyWorkerUiLeverageForceUpdate = typeof WorkerUiLeverageForceUpdate;

// Message events and their types
// Workers outgoing messages
export interface MessageInternalMarkets {
  event: EventKeyInternalMarkets;
  data: KaminoMarket[];
}

export interface MessageInternalLeverageObligations {
  event: EventKeyInternalLeverageObligations;
  data: KaminoObligation[];
}

// Workers outgoing messages
export interface MessageDataMarketFromResources {
  event: EventKeyWorkerDataMarketsFromResources;
  data: MarketFromResources;
}

export interface MessageDataPrices {
  event: EventKeyWorkerDataPrices;
  data: AppSchema['prices'];
}

export interface MessageDataStakingYields {
  event: EventKeyWorkerDataStakingYields;
  data: AppSchema['stakingYields'];
}

export interface MessageDataCurrentSlot {
  event: EventKeyWorkerDataCurrentSlot;
  data: number;
}

export interface MessageDataTokenInfos {
  event: EventKeyWorkerDataTokenInfos;
  data: TokenInfo[];
}
// export interface MessageDataCreateLongShortVaults {
//   event: EventKeyWorkerDataCreateLongShortVaults;
//   data: KaminoMarket[];
// }

// Workers outgoing to main (ui-thread)
export interface MessageMainMarketsInit {
  event: EventKeyWorkerMainUpdateStoreMarketsInit;
  data: AppSchema['markets'];
}

export interface MessageMainCurrentSlot {
  event: EventKeyWorkerMainUpdateStoreCurrentSlot;
  data: number;
}

export interface MessageMainLongShortVaultsData {
  longVaults: LeverageVaultTableData[];
  shortVaults: LeverageVaultTableData[];
  maxLtvMap: Record<string, number>;
}

export interface MessageMainLongShortVaults {
  event: EventKeyWorkerMainUpdateStoreLongShortVaults;
  data: MessageMainLongShortVaultsData;
}

export interface MessageFormStats {
  event: EventKeyWorkerFormStatsUpdate;
  data: GetObligationStatsData;
}

export interface MessageFormStatsV3 {
  event: EventKeyWorkerFormStatsV3Update;
  data: Record<string, GetObligationStatsData>;
}

export interface MessageLeveragePnlV3 {
  event: EventKeyWorkerLeveragePnlV3Update;
  data: Record<string, ObligationPnlResponse>;
}

// Main (ui-thread) outgoing to workers
export interface MessageUiWalletAddress {
  event: EventKeyWorkerUiUpdateWalletAddresss;
  data?: string;
}

export interface MessageUiPageInit {
  event: EventKeyWorkerUiUpdatePageInit;
  data: Page;
}

export interface MessageUiLeverageVaultPageParamsData {
  depositAddress: string;
  borrowAddress: string;
}

export interface MessageUiLeverageVaultPageParams {
  event: EventKeyWorkerUiLeverageVaultPageParams;
  data: MessageUiLeverageVaultPageParamsData;
}

export interface MessageUiLeverageVault {
  event: EventKeyWorkerUiLeverageVault;
  data: LeverageVaultTableData;
}

export interface MessageUiLeverageFormState {
  event: EventKeyWorkerUiLeverageFormData;
  data: FormState;
}

export interface MessageUiLeverageBaseQuote {
  event: EventKeyWorkerUiLeverageBaseQuote;
  data: { base: string; quote: string };
}

export interface MessageUiLeverageForceUpdate {
  event: EventKeyWorkerUiLeverageForceUpdate;
  data: undefined;
}

export type Messages =
  | MessageInternalMarkets
  | MessageInternalLeverageObligations
  | MessageDataMarketFromResources
  | MessageDataPrices
  | MessageDataStakingYields
  | MessageDataCurrentSlot
  | MessageMainLongShortVaults
  | MessageDataTokenInfos
  // | MessageDataCreateLongShortVaults
  | MessageMainMarketsInit
  | MessageMainCurrentSlot
  | MessageUiWalletAddress
  | MessageUiPageInit
  | MessageUiLeverageVaultPageParams
  | MessageUiLeverageVault
  | MessageUiLeverageFormState
  | MessageUiLeverageBaseQuote
  | MessageUiLeverageForceUpdate
  | MessageFormStats
  | MessageFormStatsV3
  | MessageLeveragePnlV3;
