import React, { FC } from 'react';
import { Text } from '../../../../../shared/uiKitV2/Typography';

import s from '../tooltip.module.less';
import { Icon } from '../../../../../shared/uiKitV2/Icon';
import { formatNumber, PriceImpactDangerLevel } from '../../../../../shared/utils';

interface MaxPriceImpactTooltipProps {
  priceImpactBps: number;
  dangerLevel: PriceImpactDangerLevel;
}

const { formatPercent: fp } = formatNumber;

export const MaxPriceImpactTooltip: FC<MaxPriceImpactTooltipProps> = ({ priceImpactBps, dangerLevel }) => {
  return (
    <div className={s.root}>
      <div className={s.item}>
        <div className={s.title}>
          <Icon name="WarningInfo" size={18} color={dangerLevel === PriceImpactDangerLevel.danger ? 'red' : 'yellow'} />
          <Text> Max Price Impact Is {fp(priceImpactBps / 100)}</Text>
        </div>

        <div className={s.row}>
          <Text fs={14} lh={18} color="grey" weight="regular">
            If you proceed with this transaction, you may suffer price impact. This could be due to deposit size,
            slippage settings, or oracle/market price differences. Consider breaking your trade into smaller amounts.
          </Text>
        </div>
      </div>
    </div>
  );
};
