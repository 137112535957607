import Decimal from 'decimal.js';
import { LeverageVaultType } from '../types/multipleVaults';

export const PRICE_DIVERGENCE_MULTIPLY_THRESHOLD_BPS = 20; // 0.2%
export const PRICE_DIVERGENCE_LEVERAGE_THRESHOLD_BPS = 50; // 0.5%
export const WARNING_MULTIPLY_BPS = 50; // 0.5%
export const WARNING_LEVERAGE_BPS = 75; // 0.75%
export const DANGER_MULTIPLY_BPS = 100; // 1%
export const DANGER_LEVERAGE_BPS = 100; // 1%
export const WARNING_SWAP_BPS = 50; // 0.5%
export const DANGER_SWAP_BPS = 100; // 1%

export const WARNING_JLP_MULTIPLY_BPS = 100; // 1%
export const DANGER_JLP_MULTIPLY_BPS = 200; // 2%
export const PRICE_DIVERGENCE_JLP_MULTIPLY_THRESHOLD_BPS = 50; // 0.5%

// Make sure the se are numbers
// DO not change to string
export enum PriceImpactDangerLevel {
  safe,
  warning,
  danger,
}

export const PriceImpactDangerThresholds = {
  leverage: [DANGER_LEVERAGE_BPS, WARNING_LEVERAGE_BPS],
  multiply: [DANGER_MULTIPLY_BPS, WARNING_MULTIPLY_BPS],
  swap: [DANGER_SWAP_BPS, WARNING_SWAP_BPS],
  jlpMultiply: [DANGER_JLP_MULTIPLY_BPS, WARNING_JLP_MULTIPLY_BPS],
} as const;

export type PriceImpactType = keyof typeof PriceImpactDangerThresholds;

export const getPriceImpactDangerLevel = (
  type: PriceImpactType,
  priceImpactBps: Decimal.Value
): PriceImpactDangerLevel => {
  const value = new Decimal(priceImpactBps);
  const dangerThresholds = PriceImpactDangerThresholds[type];

  if (value.gte(dangerThresholds[0])) {
    return PriceImpactDangerLevel.danger;
  }
  if (value.gte(dangerThresholds[1])) {
    return PriceImpactDangerLevel.warning;
  }

  return PriceImpactDangerLevel.safe;
};

export const getPriceImpactColor = (leverageType: LeverageVaultType, priceImpactBps: Decimal.Value) => {
  const type = getPriceImpactDangerLevel(leverageType, priceImpactBps);
  if (type === PriceImpactDangerLevel.safe) {
    return 'green';
  }

  if (type === PriceImpactDangerLevel.warning) {
    return 'yellow';
  }

  if (type === PriceImpactDangerLevel.danger) {
    return 'red';
  }

  return 'white';
};
