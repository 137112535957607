import { CSSProperties, FC, SVGProps } from 'react';

import { withStyles } from '@bruitt/classnames';
import * as icons from '../Icons';
import { ColorProps } from '../../types/global/colors';

import s from './Icon.module.less';

export type IconName = keyof typeof icons extends `Svg${infer Icon}` ? Icon : never;

const sx = withStyles(s);

export type IconProps = Omit<SVGProps<SVGSVGElement>, 'color'> &
  ColorProps & {
    name: IconName | undefined | null;
    size?: number;
    className?: string;
    rotationStyle?: CSSProperties;
  };

export const Icon: FC<IconProps> = ({ className, name, size, rotationStyle, color, ...rest }) => {
  if (!name) {
    return null;
  }

  const Component = icons[`Svg${name}`];
  return <Component className={sx(className, { color })} width={size} height={size} style={rotationStyle} {...rest} />;
};
