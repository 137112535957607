import { PublicKey } from '@solana/web3.js';
import { some } from 'lodash';
import { ReserveRewardYield } from '@kamino-finance/klend-sdk';
import { FarmStateWithApy, RewardInfoWithApy } from '../../../../shared/types/farms';

const EMPTY_DATA: RewardInfoWithApy[] = [];

export const getValidFarmRewards = (farm?: FarmStateWithApy) =>
  farm?.rewardInfos.filter(
    (reward) =>
      reward.rewardType === 0 &&
      reward.token.mint &&
      !reward.token.mint.equals(PublicKey.default) &&
      some(reward.rewardScheduleCurve.points, (point) => !point.rewardPerTimeUnit.isZero()) &&
      reward.rewardsAvailable.gtn(0)
  ) || EMPTY_DATA;

export const getValidRewards = (rewards: ReserveRewardYield[]) =>
  rewards.filter(
    (reward) =>
      reward.rewardInfo.rewardType === 0 &&
      reward.rewardInfo.token.mint &&
      !reward.rewardInfo.token.mint.equals(PublicKey.default) &&
      some(reward.rewardInfo.rewardScheduleCurve.points, (point) => !point.rewardPerTimeUnit.isZero()) &&
      reward.rewardInfo.rewardsAvailable.gtn(0)
  ) || EMPTY_DATA;
