import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { useLiquidityPositionsTableData } from './useLiquidityPositionsTableData';
import { StrategyPositionTableData } from '../types';
import { useCacheLiquidityData } from './useCacheLiquidityData';
import { PublicKeyAddress } from '../../../shared/types/strategies';
import { PnLForStrategyResponseWithAddress } from '../../../shared/types/strategyPnl';
import { useUserPositionsPnLQuery } from '../../../shared/hooks/kamino/queries/useUserPositionsPnLQuery';
import useEnv from '../../../shared/hooks/useEnv';
import { useUserFeesAndRewardsQuery } from '../../../shared/hooks/kamino/queries/useUserFeesAndRewardsQuery';

const EMPTY_DATA = {
  totalValue: 0,
  totalFees: 0,
  totalPnLUsd: 0,
  totalPnLSol: 0,
  positionsCount: 0,
};

function calcPositionsTotalValue(data: StrategyPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += position.totalValue;
    return sum;
  }, 0);
}

function calcPositionsTotalFees(
  data: StrategyPositionTableData[],
  getFeesFromCache: (strategyAddress: PublicKeyAddress) => Decimal
): number {
  return data.reduce((sum, position) => {
    sum += Number(getFeesFromCache(position.strategy));
    return sum;
  }, 0);
}

function calcPositionsTotalPnL(
  data: StrategyPositionTableData[],
  field: 'sol' | 'usd',
  getPnLFromCache: (strategyAddress: PublicKeyAddress) => PnLForStrategyResponseWithAddress['totalPnl'] | undefined
): number {
  return data.reduce((sum, position) => {
    sum += Number(getPnLFromCache(position.strategy)?.[field] || 0);
    return sum;
  }, 0);
}

export const useLiquidityPositionsStats = () => {
  const { data: tableData, isLoading } = useLiquidityPositionsTableData();
  const { walletPublicKey } = useEnv();
  const strategiesAddresses = useMemo(() => tableData.map((position) => position.strategy), [tableData]);

  // these hooks fix cache issues
  const { isFetched: isPnlFetched } = useUserPositionsPnLQuery({ strategiesAddresses, walletPublicKey });
  const { isLoading: isFeesAndRewardsLoading, isFetched: isFeesAndRewardsFetched } = useUserFeesAndRewardsQuery({
    strategiesAddresses,
    walletPublicKey,
  });
  const { getFeesFromCache, getPnLFromCache } = useCacheLiquidityData();

  const data = {
    totalValue: calcPositionsTotalValue(tableData),
    totalFees: calcPositionsTotalFees(tableData, getFeesFromCache),
    totalPnLUsd: calcPositionsTotalPnL(tableData, 'usd', getPnLFromCache),
    totalPnLSol: calcPositionsTotalPnL(tableData, 'sol', getPnLFromCache),
    positionsCount: tableData.length,
  };

  return {
    data: isLoading ? EMPTY_DATA : data,
    isLoading: isLoading || isFeesAndRewardsLoading || !isPnlFetched || !isFeesAndRewardsFetched,
  };
};
