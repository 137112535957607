import { PublicKey } from '@solana/web3.js';
import axios from 'axios';

import { PROGRAM_ID, STAGING_PROGRAM_ID } from '@kamino-finance/klend-sdk/';
import {
  MarketFromResourcesResponse,
  MarketResponse,
  ObligationHistoryResponse,
  ObligationInterestFeesResponse,
  ObligationTransactionHistoryResponse,
  ObligationUserTransactionHistoryResponse,
  PoolYieldHistory,
} from '../types/lending';
import { DEV_KAMINO_LENDING_API_V2, KAMINO_LENDING_API_V2 } from './lending/constants';
import { ENV } from './web3/types';
import { API_URL } from '../constants/api';
import { featureFlags } from '../utils';
import { MAIN_MARKET_ADDRESS } from '../constants/borrowLend/markets';

interface FetchMarketsProps {
  programId: PublicKey;
}

const HARDCODE_API_RESPONSE: { [key: string]: MarketFromResourcesResponse[] } = {
  SLendK7ySfcEzyaFqy93gDnD3RtrpXJcnRwb6zFHJSh: [
    {
      lendingMarket: '6WVSwDQXrBZeQVnu6hpnsRZhodaJTZBUaC334SiiBKdb',
      isPrimary: false,
      name: 'Primary market on staging',
      description: 'Staging pool',
      lookupTable: '9FLD8H4MVzmccLZ5Fy8yAk8G3e5zaHj6UvV9z9AEPr4o',
    },
    {
      lendingMarket: '2rijnwAvXohjrv3Kwr62xTxhVQ9F6Aqgki7kWDT5VKSj',
      isPrimary: false,
      name: 'Secondary market on staging',
      description: 'Secondary pool',
      lookupTable: '86wnyzvFA3q5RbRQQsvz9tft6icnNqCdkMfzXvrSimc4',
    },
  ],
  KLend2g3cP87fffoy8q1mQqGKjrxjC8boSyAYavgmjD: [
    {
      lendingMarket: MAIN_MARKET_ADDRESS,
      isPrimary: true,
      name: 'Primary market on mainnet',
      description: 'Main pool',
      lookupTable: '284iwGtA9X9aLy3KsyV8uT2pXLARhYbiSi5SiM2g47M2',
    },
    {
      lendingMarket: 'DxXdAyU3kCjnyggvHmY5nAwg5cRbbmdyX3npfDMjjMek',
      isPrimary: false,
      name: 'Isolated market on mainnet',
      description: 'Isolated pool',
      lookupTable: 'GprZNyWk67655JhX6Rq9KoebQ6WkQYRhATWzkx2P2LNc',
    },
    {
      lendingMarket: 'ByYiZxp8QrdN9qbdtaAiePN8AAr3qvTPppNJDpf5DVJ5',
      isPrimary: false,
      name: 'Altcoins market on mainnet',
      description: 'Meme pool',
      lookupTable: 'x2uEQSaqrZs5UnyXjiNktRhrAy6iNFeSKai9VNYFFuy',
    },
    ...(featureFlags.isEthenaMarketEnabled
      ? [
          {
            lendingMarket: 'BJnbcRHqvppTyGesLzWASGKnmnF1wq9jZu6ExrjT7wvF',
            isPrimary: false,
            name: 'Ethena pool',
            description: 'Ethena Market',
            lookupTable: '5Cx8pxA2LuCPnt8RZG9LKjKc7PnzWwvCx6xJZis7MTwm',
          },
        ]
      : []),
  ],
};

export const fetchMarketsFromResources = ({ programId }: FetchMarketsProps): Promise<MarketFromResourcesResponse[]> =>
  axios
    .get('https://cdn.kamino.finance/kamino_lend_config_v2.json')
    .then((res) => {
      if (programId.equals(PROGRAM_ID)) {
        return res.data[PROGRAM_ID.toString()];
      }
      return res.data[STAGING_PROGRAM_ID.toString()];
    })
    .catch((err) => {
      console.error('Loading market through CDN failed', err);
      return HARDCODE_API_RESPONSE[programId.toString()];
    });

export const fetchMarkets = ({ programId }: FetchMarketsProps): Promise<MarketResponse[]> =>
  axios
    .get(
      `${KAMINO_LENDING_API_V2}${programId.equals(PROGRAM_ID) ? '' : `/?programId=${STAGING_PROGRAM_ID.toString()}`}`
    )
    .then((res) => res.data);

// ==========================================
interface FetchMarketLoanHistoryProps {
  env: ENV;
  marketAddress: string;
  obligationAddress?: PublicKey;
  startDate: string;
  endDate: string;
  useStakeRateForObligation?: boolean;
}

export const fetchMarketLoanHistory = ({
  env,
  marketAddress,
  obligationAddress,
  startDate,
  endDate,
  useStakeRateForObligation,
}: FetchMarketLoanHistoryProps): Promise<ObligationHistoryResponse> => {
  const stagingProgram = localStorage.getItem('kaminoStaging');
  const apiUrl = stagingProgram ? DEV_KAMINO_LENDING_API_V2 : KAMINO_LENDING_API_V2;
  return axios
    .get(`${apiUrl}/${marketAddress}/obligations/${String(obligationAddress)}/metrics/history`, {
      params: {
        env,
        start: startDate,
        end: endDate,
        useStakeRateForObligation,
      },
    })
    .then(({ data }) => data);
};

// ==========================================
interface FetchObligationTransactionsHistoryProps {
  marketAddress: string;
  obligationAddress: string;
  useLogPrices?: boolean;
}

export const fetchObligationTransactionsHistory = ({
  marketAddress,
  obligationAddress,
  useLogPrices = false,
}: FetchObligationTransactionsHistoryProps): Promise<ObligationTransactionHistoryResponse> => {
  const stagingProgram = localStorage.getItem('kaminoStaging');
  const apiUrl = stagingProgram ? DEV_KAMINO_LENDING_API_V2 : KAMINO_LENDING_API_V2;
  return axios
    .get(`${apiUrl}/${marketAddress}/obligations/${obligationAddress}/transactions`, {
      params: { useLogPrices },
    })
    .then(({ data }) => data);
};

// ==========================================
interface FetchUserObligationTransactionsHistoryProps {
  marketAddress: string;
  userAddress: string;
}

export const fetchUserObligationTransactionsHistory = ({
  marketAddress,
  userAddress,
}: FetchUserObligationTransactionsHistoryProps): Promise<ObligationUserTransactionHistoryResponse> =>
  axios.get(`${KAMINO_LENDING_API_V2}/${marketAddress}/users/${userAddress}/transactions`).then(({ data }) => data);

// ==========================================
interface FetchObligationInterestFeesProps {
  marketAddress: string;
  obligationAddress: string;
}

export const fetchObligationInterestFees = ({
  marketAddress,
  obligationAddress,
}: FetchObligationInterestFeesProps): Promise<ObligationInterestFeesResponse> => {
  const stagingProgram = localStorage.getItem('kaminoStaging');
  const apiUrl = stagingProgram ? DEV_KAMINO_LENDING_API_V2 : KAMINO_LENDING_API_V2;
  return axios
    .get(`${apiUrl}/${marketAddress}/obligations/${obligationAddress}/interest-fees`)
    .then(({ data }) => data);
};

// ==========================================

export const fetchPoolYieldHistory = ({ poolAddress }: { poolAddress: string }): Promise<PoolYieldHistory[]> =>
  axios.get(`${API_URL}/yields/${poolAddress}/history`).then(({ data }) => data);
