import { useCallback, useState } from 'react';

interface UseDropdownProps {
  initialSelectedOption?: string;
}

export const useDropdown = ({ initialSelectedOption }: UseDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [isVisible, setVisible] = useState(false);

  const onSelectedOptionChange = useCallback((key: string) => {
    setSelectedOption(key);
  }, []);

  const onVisibleChange = useCallback((isDropdownVisible: boolean) => {
    setVisible(isDropdownVisible);
  }, []);

  return {
    onSelectedOptionChange,
    onVisibleChange,
    isVisible,
    selectedOption: selectedOption || '',
  };
};
