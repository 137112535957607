import { makeAutoObservable } from 'mobx';

export interface IncludeYieldsInAPYParams {
  includeLiquidStakingYieldInAPY: boolean;
  includeMarketMakingYieldInAPY: boolean;
  includeJLPYieldInAPY: boolean;
  includeSUsdEYieldInAPY: boolean;
}

export interface IncludeYieldsInAPYStore extends IncludeYieldsInAPYParams {
  updateIncludeYieldsInAPY: (params: Partial<IncludeYieldsInAPYStore>) => void;
}

export const getIsIncludedInAPYDefaultValue = (): IncludeYieldsInAPYParams => ({
  includeLiquidStakingYieldInAPY: false,
  includeMarketMakingYieldInAPY: false,
  includeJLPYieldInAPY: false,
  includeSUsdEYieldInAPY: false,
});

const store = makeAutoObservable<IncludeYieldsInAPYStore>({
  ...getIsIncludedInAPYDefaultValue(),
  updateIncludeYieldsInAPY: (params: Partial<IncludeYieldsInAPYParams>) => {
    Object.assign(store, params);
  },
});

export { store as includeYieldsInAPYStore };
