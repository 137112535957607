import React, { FC, useMemo, useRef } from 'react';
import { ObligationTypeTag } from '@kamino-finance/klend-sdk';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import s from './ActivitiesList.module.less';
import { Text } from '../../../../../../shared/uiKitV2/Typography';
import { redirectInNewTab } from '../../../../../../shared/utils';
import { TransactionHistoryTableData, TransactionHistoryTableDataUnion } from '../../../../../TransactionHistory/types';
import { useTransactionsHistory } from '../../../../../TransactionHistory/hooks/useTransactionsHistory';
import { useUserObligationsTransactionsHistoryQuery } from '../../../../../../shared/hooks/lending/queries/useObligationTransactionsHistoryQuery';
import { useObligationsTransactionsHistory } from '../../../../../TransactionHistory/hooks/useObligationsTransactionsHistory';
import { getObligationTypeName } from '../../../../../../shared/utils/getObligationTypeName';
import { getObligationTransactionTagColor } from '../../../../../TransactionHistory/utils';
import { Tag } from '../../../../../../shared/uiKitV2/Tag';
import { Tokens } from '../../../../../../shared/uiKitV2/Tokens';
import useTokens from '../../../../../../shared/hooks/useTokens';
import { TokenSymbol } from '../../../../../../shared/uiKitV2/TokenIcon/types';

const TransactionItem: FC<{ transaction: TransactionHistoryTableDataUnion }> = ({ transaction }) => {
  const { timestamp, transactionViewHref, transactions, transactionName, usdValue, lendingMetadata } = transaction;
  const hasLendingMetadata = !!lendingMetadata;
  const tokensMints = transactions.map((txn) => txn.tokenMint);
  const { getToken } = useTokens();

  const tokens = useMemo(
    () => tokensMints.map((mint) => getToken(mint).symbol as TokenSymbol),
    [getToken, tokensMints]
  );

  const tokensText = tokens.join('-');

  const handleButtonClick = () => {
    redirectInNewTab(transactionViewHref);
  };

  return (
    <div className={s.transactionItem} onClick={handleButtonClick}>
      <div className={s.transactionLeft}>
        <div className={s.transactionIcons}>
          <Tokens tokensAddresses={tokensMints} size={24} />
        </div>
        <div className={s.tokenNames}>
          <div className={s.transactionTitle}>
            <Text fs={14} lh={20}>
              {tokensText}
            </Text>
            <Tag
              size="middle"
              variant="grey"
              label={hasLendingMetadata ? getObligationTypeName(lendingMetadata.tag) : 'Liquidity'}
            />
          </div>

          <Text fs={14} lh={20} color="grey">
            {format(Number(timestamp), 'dd-MMM-yyyy hh:mm')}
          </Text>
        </div>
      </div>

      <div className={s.tokenValues}>
        <Text fs={14} lh={20}>
          {usdValue}
        </Text>
        <div>
          <Tag size="middle" variant={getObligationTransactionTagColor(transactionName)} label={transactionName} />
        </div>
      </div>
    </div>
  );
};

export const ActivitiesList: FC = () => {
  const { data: liquidityHistory } = useTransactionsHistory({ sorted: false });
  const { data: userObligationsTransactions } = useUserObligationsTransactionsHistoryQuery();
  const { dataObligationTagMap } = useObligationsTransactionsHistory({
    userObligationsTransactions,
    sorted: false,
  });
  const ref = useRef(null);

  const data = useMemo(() => {
    const historyData: TransactionHistoryTableData[] = [];
    historyData.push(...liquidityHistory);

    const vanillaObl = dataObligationTagMap.get(ObligationTypeTag.Vanilla) || [];
    historyData.push(...vanillaObl);
    const lendingObl = dataObligationTagMap.get(ObligationTypeTag.Lending) || [];
    historyData.push(...lendingObl);

    const multiplyObl = dataObligationTagMap.get(ObligationTypeTag.Multiply) || [];
    historyData.push(...multiplyObl);

    const leveragObl = dataObligationTagMap.get(ObligationTypeTag.Leverage) || [];
    historyData.push(...leveragObl);

    return historyData.sort((a, b) => b.timestamp - a.timestamp) as TransactionHistoryTableDataUnion[];
  }, [liquidityHistory, dataObligationTagMap]);

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => ref.current,
    estimateSize: () => 76,
    overscan: 5,
  });

  return (
    <div className={s.root} ref={ref}>
      <div
        className={s.scrollContainer}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const transaction = data[virtualRow.index];

          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={rowVirtualizer.measureElement}
              className={s.listItem}
              style={{
                transform: `translateY(${virtualRow.start}px)`,
                paddingBottom: 12,
              }}
            >
              <TransactionItem transaction={transaction} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
