import { useMixpanel } from 'react-mixpanel-browser';
import { MENU_ITEMS } from '../../shared/constants/menuItems';
import { MenuItem } from '../../shared/components/MenuItem';

import s from './Menu.module.less';
import { removeLinks } from './utils';

export const Menu = () => {
  // const { data } = useUserPositions();
  const mixpanel = useMixpanel();

  const handleMenuItemClick = (menuItem: typeof MENU_ITEMS[0]) => {
    mixpanel.track(`header:menu:${menuItem.label.toString()}`);
  };

  // useEffect(() => {
  //   if (!data?.length) {
  //     return;
  //   }
  //
  //   const portfolioItem = MENU_ITEMS.find((item) => item.id === 6);
  //
  //   if (portfolioItem) {
  //     portfolioItem.label = `Portfolio (${data?.length || 0})`;
  //   }
  // }, [data.length]);

  return (
    <nav className={s.root}>
      {MENU_ITEMS.filter(removeLinks)
        .filter((i) => !i.isHidden)
        .map((menuItem) => (
          <MenuItem key={menuItem.id} {...menuItem} onClick={() => handleMenuItemClick(menuItem)} />
        ))}
    </nav>
  );
};
