import { useCallback } from 'react';
import Decimal from 'decimal.js';
import { useQueryClient } from '@tanstack/react-query';
import { QUERYKEYS } from '../constants/queryKeys';
import { PriorityFeeStats, PriorityFeeType } from '../types/priorityFeeStats';

import { getPriorityFeeForType as getPriorityFeeForTypeUtil } from '../utils';
import { layoutProviderStore } from '../../stores/layoutProviderStore';

export const usePriorityFee = () => {
  const queryClient = useQueryClient();
  const { priorityFee, priorityFeeType } = layoutProviderStore;

  const priorityFeeStats = queryClient.getQueryData<PriorityFeeStats>(QUERYKEYS.priorityFeeStats);

  const getPriorityFeeForType = useCallback(
    (type: PriorityFeeType): Decimal => {
      return getPriorityFeeForTypeUtil(priorityFeeStats, type);
    },
    [priorityFeeStats]
  );

  return {
    priorityFeeStats,
    getPriorityFeeForType,
    priorityFeeType,
    priorityFee,
  };
};
