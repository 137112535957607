import { StrategyWithAddress } from '@kamino-finance/kliquidity-sdk';
import { useMemo } from 'react';
import { useUserCreatedVaultsQuery } from './useUserCreatedVaultsQuery';
import { useWalletAddressFromParamsOrEnv } from '../../useWalletAddressFromParamsOrEnv';

const EMPTY_DATA: StrategyWithAddress[] = [];

export const useUserDiyVaults = () => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const isDisabled = !walletPublicKey;

  // fetch DIY vaults to show them in Portfolio positions table
  // even if user do not have a position there
  const { data: diyVaults, isLoading: areDiyVaultsLoading } = useUserCreatedVaultsQuery({
    isDisabled,
    walletPublicKey,
  });
  const isLoading = !isDisabled && areDiyVaultsLoading;

  const userDiyVaults = useMemo(
    () =>
      isLoading || !walletPublicKey
        ? EMPTY_DATA
        : diyVaults.filter((strategy) => {
            return strategy.strategy.adminAuthority.toString() === walletPublicKey.toString();
          }),
    [diyVaults, isLoading, walletPublicKey]
  );

  return {
    data: userDiyVaults,
    isLoading,
  };
};
