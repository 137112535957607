export const formatDate = (timestamp: number | string): string => {
  const date = new Date(timestamp);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);

  const time = new Intl.DateTimeFormat('en', {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);

  return `${month} ${day} ${time}`;
};
