import { useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';

import { LendingPositionTableData } from '../types';
import { useLendingMultiplyPositionsTableData } from './useLendingMultiplyPositionsTableData';
import { useObligationsPnlQuery } from '../../../shared/hooks/lending/queries/useObligationsPnlQuery';
import { isLST } from '../../../shared/utils/isLST';
import { ObligationPnlResponse } from '../../../shared/types/lending';

const EMPTY_DATA = {
  totalNetValue: 0,
  totalCollateral: 0,
  totalDebt: 0,
  totalSolPnl: 0,
  totalUsdPnl: 0,
  positionsCount: 0,
};

function calcPositionsTotalNetValue(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += position.netValue;
    return sum;
  }, 0);
}

function calcTotalCollateral(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += Array.from(position.deposits.values()).reduce((s, deposit) => {
      s += Number(deposit.marketValueRefreshed);
      return s;
    }, 0);

    return sum;
  }, 0);
}

function calcTotalDebt(data: LendingPositionTableData[]): number {
  return data.reduce((sum, position) => {
    sum += Array.from(position.borrows.values()).reduce((s, borrow) => {
      s += Number(borrow.marketValueRefreshed);
      return s;
    }, 0);

    return sum;
  }, 0);
}

function calcPositionsTotalPnL(
  data: LendingPositionTableData[],
  getPnl: (addr: PublicKey) => ObligationPnlResponse
): {
  lstSolPnl: number;
  nonLstUsdPnl: number;
} {
  let lstSolPnl = 0;
  let nonLstUsdPnl = 0;

  for (const position of data) {
    if (isLST(position.deposits[0].mintAddress)) {
      lstSolPnl += Number(getPnl(position.address)?.sol || 0);
    } else {
      nonLstUsdPnl += Number(getPnl(position.address)?.usd || 0);
    }
  }

  return {
    lstSolPnl,
    nonLstUsdPnl,
  };
}

export const useLendingMultiplyPositionsStats = () => {
  const { data: tableData, isLoading, obligations } = useLendingMultiplyPositionsTableData();
  const { isReady, pnlMap } = useObligationsPnlQuery({ obligations });

  const data = useMemo(() => {
    const { lstSolPnl, nonLstUsdPnl } = calcPositionsTotalPnL(tableData, (addr) => pnlMap[addr.toString()]);
    return {
      totalNetValue: calcPositionsTotalNetValue(tableData),
      totalCollateral: calcTotalCollateral(tableData),
      totalDebt: calcTotalDebt(tableData),
      totalSolPnl: lstSolPnl,
      totalUsdPnl: nonLstUsdPnl,
      positionsCount: tableData.length,
    };
  }, [pnlMap, tableData]);

  return { data: isLoading ? EMPTY_DATA : data, isLoading, isReady };
};
