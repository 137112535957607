import { ReactElement, SVGProps } from 'react';

export const SvgCircleCheckBig = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      stroke="#30D69A"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m15 10-4 4-2-2" stroke="#30D69A" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
