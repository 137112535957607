import { useQuery } from '@tanstack/react-query';
import { QUERYKEYS } from '../../../constants/queryKeys';
import { captureError } from '../../../utils/captureError';
import { fetchKaminoStatus } from '../../../services/status';
import { KaminoStatus } from '../../../types/resources';

const DEFAULT_STATUS: KaminoStatus = {
  maintenance: {
    api: false,
  },
};

export const useKaminoStatusQuery = () => {
  const queryFn = async (): Promise<KaminoStatus> => {
    return fetchKaminoStatus();
  };

  const { data, ...rest } = useQuery<KaminoStatus>({
    queryKey: QUERYKEYS.getKaminoStatusQuery,
    queryFn,
    refetchInterval: 2 * 60 * 1000, // 2min
    onError: (err) => {
      captureError(err);
    },
  });

  return { data: data || DEFAULT_STATUS, ...rest };
};
