import { withStyles } from '@bruitt/classnames';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../features/Header';
import { MobileMenu } from '../../features/MobileMenu';
import { HeaderBlurCircles } from '../../shared/components/v2/HeaderBlurCircles';
import { useRestrictAccessByIP } from '../../shared/hooks/useRestrictAccessByIP';
import { Footer } from '../../features/Footer';
import s from './AppLayout.module.less';
import { RectrictedAccesssBanner } from '../../shared/components/v2/RectrictedAccesssBanner';
import { EuBanner } from '../../shared/components/v2/EuBanner';
import { UkBanner } from '../../shared/components/v2/UkBanner';
import { EU_COUNTRY_CODES, UK_COUNTRY_CODE } from '../../shared/constants/countries';
import { DashboardPreviewBanner } from '../../shared/components/v2/DashboardPreviewBanner';
import { layoutProviderStore } from '../../stores/layoutProviderStore';
import { BACKGROUND_OVERRIDE } from '../../shared/constants/styling';
import isWithdrawOnlyEnabled from '../../shared/utils/withdrawOnly';
import { WithdrawOnlyBanner } from '../../shared/components/v2/WithdrawOnlyBanner';
import { GeoblockOverlay } from '../../shared/components/GeoblockOverlay/GeoblockOverlay';
import { WalletHasRiskOverlay } from '../../shared/components/WalletHasRiskOverlay/WalletHasRiskOverlay';
import { useWalletRestriction } from '../../shared/hooks/useWalletRestriction';
import { MaintenanceBanner } from '../../shared/components/v2/MaintenanceBanner';
import { useKaminoStatusQuery } from '../../shared/hooks/kamino/queries/useKaminoResourcesQuery copy';
import { SidePanel } from '../../features/SidePanel';
import { useInitWhatsNewIndicators } from '../../shared/components/v2/WhatsNewIndicator/hooks/useInitWhatsNewIndicators';

const sx = withStyles(s);

export const AppLayout: FC<{ isSurgeOptimised?: boolean }> = ({ children, isSurgeOptimised = false }) => {
  const history = useHistory();
  const { isRestricted, isGeoblockChecked, isEnabled, countryCode } = useRestrictAccessByIP();
  const { hasWalletRestriction } = useWalletRestriction();
  const { data: kaminoStatus } = useKaminoStatusQuery();
  useInitWhatsNewIndicators();
  const isApiInMaintenance = kaminoStatus.maintenance.api;

  // Reset layoutProviderStore on path change to clean up transaction settings
  history.listen(() => {
    const { resetProviderParams } = layoutProviderStore;
    resetProviderParams();
  });

  const showRestrictedAccessBanner =
    isRestricted && isGeoblockChecked && isEnabled && countryCode.toUpperCase() !== UK_COUNTRY_CODE;
  const showEuBanner = isGeoblockChecked && EU_COUNTRY_CODES.includes(countryCode) && !isRestricted;
  const showUkBanner = isGeoblockChecked && countryCode && countryCode.toUpperCase() === UK_COUNTRY_CODE;
  const isGeoBlockOverlayEnabled = isRestricted && isGeoblockChecked && history.location.pathname === '/genesis';

  return (
    <div className={sx('root')}>
      {isGeoBlockOverlayEnabled && <GeoblockOverlay />}
      {hasWalletRestriction && <WalletHasRiskOverlay />}
      <HeaderBlurCircles />

      <header className={s.header}>
        {isApiInMaintenance && <MaintenanceBanner />}
        {showRestrictedAccessBanner && <RectrictedAccesssBanner />}
        {showEuBanner && <EuBanner />}
        {isWithdrawOnlyEnabled && <WithdrawOnlyBanner />}
        {showUkBanner && <UkBanner />}
        <Header isSurgeOptimised={isSurgeOptimised} />
        <DashboardPreviewBanner />
      </header>

      <div className={sx('content')} id={BACKGROUND_OVERRIDE}>
        {children}
      </div>

      <footer className={s.footer}>
        <Footer />
      </footer>

      <MobileMenu />
      <SidePanel />
    </div>
  );
};
