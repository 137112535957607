import {
  AddressLookupTableAccount,
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';
import { Instruction } from '@jup-ag/api';
import { notEmpty } from '../notEmpty';
import { PublicKeyAddress } from '../../types/strategies';
import { calculateAndLogTxSize } from '../kamino/calculateAndLogTxSize';

// Type guard to determine if the item is of type Instruction
export function isInstruction(item: Instruction | TransactionInstruction): item is Instruction {
  // Replace this with actual logic to determine if item is an Instruction.
  // For example:
  // return (item as Instruction).someUniqueProperty !== undefined;
  return 'accounts' in item;
}

export function convertJupiterIxnToTransactionIxn(ixn: Instruction): TransactionInstruction {
  const keys = ixn.accounts.map((key) => {
    return { ...key, pubkey: new PublicKey(key.pubkey) };
  });
  return new TransactionInstruction({
    keys,
    data: Buffer.from(ixn.data, 'base64'),
    programId: new PublicKey(ixn.programId),
  });
}

export function convertJupiterIxnsToTransactionIxns(ixns: Instruction[]): TransactionInstruction[] {
  return ixns.map(convertJupiterIxnToTransactionIxn);
}

export const buildVersionedTransaction = async (
  connection: Connection,
  payer: PublicKeyAddress,
  instructions: Array<Instruction | TransactionInstruction>,
  lookupTables: PublicKey[] = [],
  lookupTablesAccounts?: Array<AddressLookupTableAccount | null>
): Promise<VersionedTransaction> => {
  const blockhash = await connection.getLatestBlockhash('confirmed').then((res) => res.blockhash);

  // if lookupTablesAccounts passed via param - do not refetch
  let lutAccounts: Array<AddressLookupTableAccount | null> | undefined = lookupTablesAccounts;

  if (!lutAccounts) {
    lutAccounts = await Promise.all(
      lookupTables.map((address) => {
        return getLookupTableAccount(connection, address);
      })
    );
  }

  const ixns = instructions.map((ixn) => {
    if (isInstruction(ixn)) {
      return convertJupiterIxnToTransactionIxn(ixn);
    }

    return ixn;
  });

  const lookupTableAccounts = lutAccounts.filter(notEmpty);

  await calculateAndLogTxSize(connection, ixns, lookupTables, new PublicKey(payer), lutAccounts);

  const messageV0 = new TransactionMessage({
    payerKey: new PublicKey(payer),
    recentBlockhash: blockhash,
    instructions: ixns,
  }).compileToV0Message(lookupTableAccounts);

  return new VersionedTransaction(messageV0);
};

export const getLookupTableAccount = async (connection: Connection, address: string | PublicKey) => {
  return connection.getAddressLookupTable(new PublicKey(address)).then((res) => res.value);
};

export const decodeSerializedTransaction = (tx: string | undefined) => {
  if (!tx) {
    return undefined;
  }
  return Transaction.from(Buffer.from(tx, 'base64'));
};
