import { FC, useMemo, useState } from 'react';
import c from 'classnames';
import s from './SettingsMode.module.less';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { IconButton } from '../../../../shared/uiKitV2/IconButton';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import useEnv from '../../../../shared/hooks/useEnv';
import { ENABLED_CHAINS } from '../../../../shared/services/web3/client';
import { Checkbox } from '../../../../shared/uiKitV2/Checkbox';
import { notify } from '../../../../shared/utils/notifications/notifications';
import { isUrl } from '../../../../shared/utils';
import { Input } from '../../../../shared/uiKitV2/Input';
import { Button } from '../../../../shared/uiKitV2/Button';
import { Chain, ENV } from '../../../../shared/services/web3/types';
import { saveSelectedRPC } from '../../../../providers/utils';
import { getSavedCustomRpc, removeCustomRPC, saveCustomRPC } from '../../../../providers/customRpc';
import { WEB3_ENV_KEY } from '../../../../providers/constants';

type SwapProps = {
  onBack: () => void;
};
export const SettingsMode: FC<SwapProps> = ({ onBack }) => {
  const { setEnv, chain } = useEnv();
  const customChain = useMemo(() => getSavedCustomRpc(), []);
  const [customRpc, setCustomRpc] = useState(customChain?.endpoint || '');
  const [isCustomRpcChecked, setIsCustomRpcChecked] = useState(customChain?.endpoint === chain.endpoint);

  const onCustomRpcConfirm = () => {
    if (isUrl(customRpc)) {
      const customRpcObj: Chain = {
        name: 'mainnet-beta',
        displayName: 'custom',
        endpoint: customRpc,
        chainID: 101,
      };
      saveCustomRPC(customRpcObj);
      setEnv('mainnet-beta', customRpcObj);
    } else {
      notify({
        message: 'RPC URL not valid',
        description: 'Custom RPC URL address is not valid. Please enter a valid RPC address.',
        type: 'error',
      });
    }
  };

  const onCustomRpcRemove = () => {
    removeCustomRPC();
    const lcEnv = localStorage.getItem(WEB3_ENV_KEY) as ENV;
    const enabledChain = ENABLED_CHAINS.find((currentChain) => currentChain.name === lcEnv);
    const newChain = enabledChain || ENABLED_CHAINS[0];
    handleRpcSelect(newChain);
  };

  const handleRpcSelect = (newChain: Chain) => {
    removeCustomRPC();
    saveSelectedRPC(newChain);
    setEnv(newChain.name, newChain);
  };

  const handleCustomRpcSelect = () => {
    if (!customRpc) {
      setIsCustomRpcChecked(true);
      return;
    }
    onCustomRpcConfirm();
  };

  return (
    <div className={s.content}>
      <div className={s.title}>
        <IconButton onClick={onBack} size="small" variant="secondary" icon={<Icon name="ArrowLeft" color="white" />} />
        <Text fs={22} lh={32}>
          Settings
        </Text>
      </div>
      <div className={s.tabContent}>
        <Text>RPC Endpoint</Text>
        <div>
          {ENABLED_CHAINS.filter((enabledChain) => enabledChain.displayName !== 'custom').map((enabledChain) => (
            <div
              className={c(s.networkBtn)}
              onClick={() => handleRpcSelect(enabledChain)}
              key={enabledChain.displayName}
            >
              <Checkbox
                className={s.checkbox}
                checked={chain.endpoint === enabledChain.endpoint && !isCustomRpcChecked}
              />
              <Text
                className={s.networkLink}
                key={enabledChain.displayName}
                color={chain.displayName === enabledChain.displayName ? 'white' : 'grey'}
              >
                {enabledChain.displayName}
              </Text>
            </div>
          ))}

          <div className={c(s.networkBtn)} onClick={handleCustomRpcSelect} key="custom">
            <Checkbox className={s.checkbox} checked={chain.endpoint === customRpc || isCustomRpcChecked} />
            <Text
              className={s.networkLink}
              key="custom"
              color={chain.endpoint === customRpc || isCustomRpcChecked ? 'white' : 'grey'}
            >
              Custom
            </Text>
          </div>
        </div>
        <div className={s.customRpcWrapper}>
          <Input value={customRpc} onChange={(e) => setCustomRpc(e.target.value)} placeholder="Enter custom RPC" />
          {Boolean(customRpc) && chain.endpoint !== customRpc && (
            <Button onClick={onCustomRpcConfirm} disabled={!isUrl(customRpc)}>
              Confirm
            </Button>
          )}
          {chain.endpoint === customRpc && (
            <Button onClick={onCustomRpcRemove} disabled={!isUrl(customRpc)} variant="secondary">
              <Icon name="Close" />
              Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
