import axios from 'axios';
import { TransactionsHistoryResponse } from '../types/transactionsHistory';
import { captureError } from '../utils/captureError';
import { KAMINO_LIQUIDITY_API, KAMINO_LIQUIDITY_API_DEV } from './liquidity/constants';

const API_URL = !localStorage.getItem('kaminoStaging') ? KAMINO_LIQUIDITY_API : KAMINO_LIQUIDITY_API_DEV;

export interface FetchTransactionHistoryProps {
  walletPublicKey?: string | null;
  env: string;
}

export const fetchTransactionsHistory = ({
  walletPublicKey,
  env,
}: FetchTransactionHistoryProps): Promise<TransactionsHistoryResponse[]> => {
  if (!walletPublicKey) {
    return Promise.reject(new Error('walletPublicKey is required'));
  }

  return axios
    .get(`${API_URL}/v2/shareholders/${walletPublicKey}/transactions`, {
      params: { env },
    })
    .then(({ data }) => data.transactions)
    .catch(captureError);
};
