import { ReactElement, SVGProps } from 'react';

export const SvgGemAlt = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m6.667 2.5-1.333 4L8 14.167 10.667 6.5l-1.333-4m-7 4h11.333m-5.24 7.431L13.76 6.82c.086-.115.13-.172.146-.235a.332.332 0 0 0 0-.17c-.017-.063-.06-.12-.146-.235l-2.6-3.467c-.059-.078-.088-.117-.125-.145a.333.333 0 0 0-.11-.055c-.045-.013-.094-.013-.192-.013H5.267c-.098 0-.147 0-.192.013a.333.333 0 0 0-.11.055c-.037.028-.066.067-.125.145L2.24 6.18c-.086.115-.129.172-.146.235a.333.333 0 0 0 0 .17c.017.063.06.12.146.235l5.333 7.111c.145.193.218.29.306.324.078.03.164.03.242 0 .089-.034.161-.13.306-.324Z"
      stroke="#98B0C1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
