import { formatNumber } from '../utils';
import { SINGLE_TX_WITHDRAW_VALUE_THRESHOLD } from '../../features/LeverageForms/constants';
import {
  DEFAULT_SLIPPAGE_JLP_MULTIPLY_PCT,
  DEFAULT_SLIPPAGE_MULTIPLY_PCT,
  SLIPPAGE_JLP_MULTIPLY_WARNING_PCT,
  SLIPPAGE_MULTIPLY_WARNING_PCT,
} from './slippage';

const { formatTokenAllDecimals: ftad } = formatNumber;

export const WALLET_CONNECT_WARNING =
  'WalletConnect does not support Versioned Transactions which is necessary for kLend Market. You can still try to send transaction but success is not guaranteed.';

export const WITHDRAW_MULTIPLY_ABOVE_THRESHOLD = `You are withdrawing more than $${ftad(
  SINGLE_TX_WITHDRAW_VALUE_THRESHOLD,
  0
)} which may have high price impact. Please split your transaction in smaller sizes.`;

export const MULTIPLY_SLIPPAGE_IS_TOO_HIGH = `Your slippage setting is above ${SLIPPAGE_MULTIPLY_WARNING_PCT}%. Please proceed with caution, or considering adjusting it to ${DEFAULT_SLIPPAGE_MULTIPLY_PCT}% (recommended).`;
export const JLP_MULTIPLY_SLIPPAGE_IS_TOO_HIGH = `Your slippage setting is above ${SLIPPAGE_JLP_MULTIPLY_WARNING_PCT}%. Please proceed with caution, or considering adjusting it to ${DEFAULT_SLIPPAGE_JLP_MULTIPLY_PCT}% (recommended).`;

export const RESTRICTED_WALLET_MESSAGE =
  'Your wallet has been identified to break the Terms & Conditions of the Kamino webapp. Please contact us on discord if you think there has been a mistake.';

export const RESTRICTED_REGION_MESSAGE = 'Kamino Finance is not Available in Your Region';
