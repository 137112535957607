import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CaptureContext } from '@sentry/types';
import { TRANSACTION_CANCELED, USER_REJECTED_REQUEST_MESSAGE } from '../constants/errors';
import { getErrorMessage } from './getErrorMessage';

export const captureError = (err: any, context?: CaptureContext) => {
  const exceptionMsg = getErrorMessage(err);

  if (
    !String(exceptionMsg).toLowerCase().includes(USER_REJECTED_REQUEST_MESSAGE.toLowerCase()) &&
    !String(exceptionMsg).toLowerCase().includes(TRANSACTION_CANCELED.toLowerCase())
  ) {
    Sentry.captureException(err, context);
  }

  return Promise.reject(err);
};
