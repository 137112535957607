import { NotificationInstance as RCNotificationInstance } from 'rc-notification/lib/Notification';
import { FC } from 'react';
import * as React from 'react';
import { withStyles } from '@bruitt/classnames';
import { Card } from '../../../../shared/uiKitV2/Card';
import { Text } from '../../../../shared/uiKitV2/Typography';
import { Icon } from '../../../../shared/uiKitV2/Icon';
import { ArgsProps, IconType } from '../../types';
import s from './Notice.module.less';

import infoIcon from './assets/infoIcon.svg';
import successIcon from './assets/successIcon.svg';
import specialIcon from './assets/specialIcon.svg';

const sx = withStyles(s);

interface NoticeProps {
  args: ArgsProps;
  instance: RCNotificationInstance;
  classNames?: string;
}

type Icons = {
  [key in IconType]?: string;
};

const ICONS_MAP: Icons = {
  info: infoIcon,
  success: successIcon,
  special: specialIcon,
};

export const Notice: FC<NoticeProps> = ({ args, instance, classNames }) => {
  const { icon, type, description, message, btn, onClose, key } = args;

  const handleClose = () => {
    if (!key) {
      return;
    }

    instance.removeNotice(key);
    onClose?.();
  };

  // @ts-ignore
  const headerIcon = icon || ICONS_MAP?.[type];

  return (
    <Card variant="secondary" className={sx(classNames, s.root)}>
      <div className={s.header}>
        <div className={s.left}>
          {headerIcon && <img src={headerIcon} alt="" className={s.icon} />}

          <Text fs={20} lh={26}>
            {message}
          </Text>
        </div>

        <Icon name="Close" size={20} color="grey" className={s.closeIcon} onClick={handleClose} />
      </div>

      {description}
      {btn}
    </Card>
  );
};
