import { useQuery } from '@tanstack/react-query';
import { PublicKey } from '@solana/web3.js';
import { Program } from '@coral-xyz/anchor';
import BN from 'bn.js';

import { useWeb3Client } from '../../../../../shared/hooks/useWeb3Client';
import useEnv from '../../../../../shared/hooks/useEnv';
import { Registrar, computeVsrWeight, voterRecordKey } from '../plugin/VoterStakeRegistry/utils';
import { VsrClient } from '../plugin/VoterStakeRegistry/client';
import { VoterStakeRegistry } from '../plugin/VoterStakeRegistry/idl';
import { useGetRegistrar } from './useVsr';
import { useTokenOwnerRecord } from './useTokenOwnerRecord';
import { voterWeightAddin } from '../../../utils';

// const DEFAULT_RETURN_VALUE = {
//   votes: new BN(0),
// };

export type VoterWeightTokensType = {
  amount: BN;
  mint: PublicKey;
  isLocked: boolean;
};

export type VoterWeightType = {
  selfAmount: {
    votes: BN;
    amountLocked: BN;
    tokens: VoterWeightTokensType[];
    isVsr: boolean;
    deposits: {
      allowClawback: boolean;
      amountDepositedNative: BN;
      amountInitiallyLockedNative: BN;
      isUsed: boolean;
      lockup: {
        endTs: BN;
        startTs: BN;
        kind: string;
        reserved: number[];
      };
      reserved: number[];
      votingMintConfigIdx: number;
    }[];
    // delegate: PublicKey | null;
  };
  // delegateAmount: {
  //   votes: BN;
  // };
};

export function useGetVoterWeight() {
  const { web3client } = useWeb3Client();
  const { walletPublicKey } = useEnv();
  const { data: tokenOwnerRecord } = useTokenOwnerRecord();
  const registrar = useGetRegistrar().data;
  const enabled = walletPublicKey !== undefined && registrar !== undefined;

  return useQuery({
    enabled,
    queryKey: [
      'get-voter-weight',
      {
        tokenOwner: walletPublicKey,
        tokenOwnerRecord: tokenOwnerRecord?.account.governingTokenOwner,
      },
    ],
    queryFn: async (): Promise<VoterWeightType | null> => {
      const connection = web3client?.connection;
      if (!connection || !walletPublicKey || !registrar || !tokenOwnerRecord) {
        return null;
      }

      const vsrClient = VsrClient(connection, voterWeightAddin);

      const weights: VoterWeightType = {
        selfAmount: await getSelfAmount(
          tokenOwnerRecord.account.realm,
          tokenOwnerRecord.account.governingTokenMint,
          tokenOwnerRecord.account.governingTokenOwner,
          // tokenOwnerRecord.governanceDelegate || null,
          vsrClient,
          registrar
        ),
        // remove delegateAmount?
        // delegateAmount: { ...DEFAULT_RETURN_VALUE },
      };

      console.log('fetched vsr voter weights', weights.selfAmount.votes.toNumber());
      console.log('fetched vsr voter weightslockedup', weights.selfAmount.amountLocked.toNumber());
      return weights;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

async function getSelfAmount(
  realm: PublicKey,
  tokenMint: PublicKey,
  authority: PublicKey,
  // delegate: PublicKey | null,
  client: Program<VoterStakeRegistry>,
  registrar: Registrar
) {
  const [voterKey] = voterRecordKey(realm, tokenMint, authority, registrar.programId);

  try {
    const { deposits } = await client.account.voter.fetch(voterKey);

    return {
      votes: computeVsrWeight(deposits, registrar.data.votingMints),
      deposits: deposits.flatMap((d) => ({
        allowClawback: d.allowClawback,
        amountDepositedNative: d.amountDepositedNative,
        amountInitiallyLockedNative: d.amountInitiallyLockedNative,
        isUsed: d.isUsed,
        lockup: {
          endTs: d.lockup.endTs,
          startTs: d.lockup.startTs,
          kind: d.lockup.kind.cliff
            ? 'cliff'
            : d.lockup.kind.constant
            ? 'constant'
            : d.lockup.kind.daily
            ? 'daily'
            : d.lockup.kind.monthly
            ? 'monthly'
            : 'none',
          reserved: d.lockup.reserved,
        },
        reserved: d.reserved,
        votingMintConfigIdx: d.votingMintConfigIdx,
      })),
      tokens: deposits.flatMap((d) =>
        d.amountDepositedNative.gt(new BN(0)) && d.lockup.kind.none
          ? [
              {
                amount: d.amountDepositedNative,
                mint: registrar.data.votingMints[d.votingMintConfigIdx].mint,
                isLocked: d.lockup.kind.none === undefined,
              },
            ]
          : []
      ),
      amountLocked: deposits
        .filter((d) => d.lockup.kind.none === undefined)
        .reduce((curr, next) => curr.add(new BN(next.amountDepositedNative)), new BN(0)),
      isVsr: true,
      // delegate,
    };
  } catch (e) {
    console.log(e);
    return {
      votes: new BN(0),
      amountLocked: new BN(0),
      tokens: [
        {
          amount: new BN(0),
          mint: tokenMint,
          isLocked: false,
        },
      ],
      isVsr: true,
      deposits: [],
    };
  }
}
