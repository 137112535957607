import { ReactElement, SVGProps } from 'react';

export const SvgPeopleBw = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 16.229c2.33.549 4 2.03 4 3.772M3 20c0-2.21 2.686-4 6-4s6 1.79 6 4M15 13a4 4 0 0 0 0-8"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
