import { ComputeBudgetProgram, TransactionInstruction } from '@solana/web3.js';
import { ASSOCIATED_TOKEN_PROGRAM_ID } from '@solana/spl-token';

export function removeBudgetAndAtaIxns(ixns: TransactionInstruction[], mints: string[]): TransactionInstruction[] {
  return ixns.filter((ixn) => {
    const { programId, keys } = ixn;

    if (programId.equals(ComputeBudgetProgram.programId)) {
      return false;
    }

    if (programId.equals(ASSOCIATED_TOKEN_PROGRAM_ID)) {
      const mint = keys[3];

      return !mints.includes(mint.pubkey.toString());
    }

    return true;
  });
}

export function removeBudgetIxns(ixns: TransactionInstruction[]): TransactionInstruction[] {
  return ixns.filter((ixn) => {
    const { programId } = ixn;

    if (programId.equals(ComputeBudgetProgram.programId)) {
      return false;
    }

    return true;
  });
}
