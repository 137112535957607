import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { isMobile } from 'react-device-detect';
import { withStyles } from '@bruitt/classnames';
import { useMixpanel } from 'react-mixpanel-browser';
import { DisclaimerPopup } from '../../DisclaimerPopup';
import { useTermsPopup } from '../../../hooks/useTermsPopup';
import { Button, ButtonProps } from '../../../uiKitV2/Button';
import { SelectWalletModal } from '../../WalletModal';
import paths from '../../../routes/paths';
import s from './ConnectButton.module.less';
import { BaseButtonProps } from '../../../uiKitV2/BaseButton';
import { layoutProviderStore } from '../../../../stores/layoutProviderStore';

const sx = withStyles(s);

interface ConnectButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  variant?: BaseButtonProps['variant'];
  size?: ButtonProps['size'];
  isFullWidth?: boolean;
}

export const ConnectButton: FC<ConnectButtonProps> = ({
  onClick,
  variant = 'secondary',
  size = 'large',
  isFullWidth = false,
}) => {
  const location = useLocation();
  const { areTermsConfirmed, isDisclaimerPopupVisible, onCancel, onConfirm, onDisclaimerPopupVisibleChange } =
    useTermsPopup();
  const { connected, connecting } = useWallet();
  const mixpanel = useMixpanel();
  const [isSelectWalletModalVisible, setIsSelectWalletModalVisible] = useState(false);
  const { updateProviderParams } = layoutProviderStore;

  const handleSelectWalletModalVisibilityChange = useCallback(
    (isVisibleModal: boolean) => setIsSelectWalletModalVisible(isVisibleModal),
    []
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      mixpanel.track('wallet:click');
      if (areTermsConfirmed) {
        if (connected) {
          onClick?.(event);
          return;
        }
        // handleSelectWalletModalVisibilityChange(true);
        updateProviderParams({ isSidePanelRendered: true, isSidePanelOpened: true });
      } else {
        onDisclaimerPopupVisibleChange(true);
      }
    },
    [areTermsConfirmed, connected, mixpanel, onClick, onDisclaimerPopupVisibleChange, updateProviderParams]
  );

  return (
    <>
      <Button
        className={sx('button', { isFullWidth: isMobile ? true : isFullWidth })}
        variant={variant}
        size={size}
        onClick={handleClick}
        isFullWidth={isFullWidth}
        isLoading={connecting}
      >
        {isMobile ? 'Connect' : 'Connect Wallet'}
      </Button>

      <DisclaimerPopup
        isVisible={isDisclaimerPopupVisible && location.pathname !== paths.TERMS}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />

      <SelectWalletModal
        isVisible={isSelectWalletModalVisible}
        onCancel={() => handleSelectWalletModalVisibilityChange(false)}
      />
    </>
  );
};
