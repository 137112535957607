import { ReactElement, SVGProps } from 'react';

export const SvgSparkles = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.353 3.102c.034-.136.309-.136.343 0 .176.703.502 1.674 1.039 2.211.537.537 1.509.863 2.211 1.04.137.034.137.308 0 .342-.702.177-1.674.502-2.211 1.04-.537.536-.863 1.508-1.04 2.21-.033.137-.308.137-.342 0-.177-.702-.502-1.674-1.04-2.21-.536-.538-1.508-.863-2.21-1.04-.137-.034-.137-.308 0-.343.702-.176 1.674-.502 2.21-1.039.538-.537.863-1.508 1.04-2.21Zm1.82 12.167c.035-.136.318-.136.354 0 .157.588.43 1.346.86 1.777.431.43 1.189.703 1.778.86.136.036.136.319 0 .355-.59.157-1.347.43-1.778.86-.43.43-.703 1.188-.86 1.777-.036.136-.319.136-.355 0-.157-.589-.43-1.346-.86-1.777-.431-.43-1.188-.703-1.777-.86-.136-.036-.136-.32 0-.355.589-.157 1.346-.43 1.777-.86.43-.431.703-1.189.86-1.777Zm7.638-8.515c-.03-.137-.29-.137-.32 0-.225 1.003-.706 2.653-1.57 3.516-.863.863-2.513 1.345-3.516 1.57-.137.03-.137.289 0 .32 1.003.224 2.653.706 3.517 1.569.863.863 1.344 2.513 1.569 3.516.03.137.29.137.32 0 .225-1.003.706-2.653 1.57-3.516.863-.863 2.513-1.345 3.516-1.57.137-.03.137-.289 0-.32-1.003-.224-2.653-.706-3.516-1.569-.864-.863-1.345-2.513-1.57-3.516Z"
      fill="#575CFE"
    />
  </svg>
);
