import { useState } from 'react';

import { WalletName } from '@solana/wallet-adapter-base';

import useEnv from './useEnv';
import { useTermsSignature } from './useTermsSignature';
import { featureFlags } from '../utils';
import { TERMS } from '../constants/terms';
import { captureError } from '../utils/captureError';
import { termsStore } from '../../stores/termsStore';

export const useTermsPopup = () => {
  const { disconnectWallet, selectWalletAndConnect } = useEnv();
  const { areTermsConfirmed, updateProviderParams } = termsStore;

  const handleTermsConfirmedChange = (isConfirmed: boolean) => {
    updateProviderParams({ areTermsConfirmed: isConfirmed });
  };

  const [doesTermsSignatureExist, setDoesTermsSignatureExist] = useState<boolean>(
    Boolean(localStorage.getItem('kamino.termsSignature')) || !featureFlags.isTermsSignatureEnabled
  );
  const [isDisclaimerPopupVisible, setIsDisclaimerPopupVisible] = useState<boolean>(false);

  const handleDisclaimerPopupVisibleChange = (isVisible: boolean) => setIsDisclaimerPopupVisible(isVisible);

  const handleMessageSignSuccess = (signature: Uint8Array) => {
    setIsDisclaimerPopupVisible(false);
    localStorage.setItem('kamino.termsSignature', new TextDecoder().decode(signature));
    setDoesTermsSignatureExist(true);
  };

  const handleMessageSignError = (err: any) => {
    captureError(err);
  };
  const { signMessage } = useTermsSignature({ onSuccess: handleMessageSignSuccess, onError: handleMessageSignError });

  const handleDisclaimerConfirm = async (walletName: WalletName<string> | null) => {
    try {
      selectWalletAndConnect(walletName);

      handleTermsConfirmedChange(true);
      localStorage.setItem('kamino.areTermsConfirmed', 'true');
      if (doesTermsSignatureExist) {
        setIsDisclaimerPopupVisible(false);
      } else {
        signMessage(TERMS);
      }
    } catch (e) {
      captureError(e);
      await disconnectWallet();
    }
  };

  const handleOnCancel = () => {
    try {
      setIsDisclaimerPopupVisible(false);
      disconnectWallet().catch(captureError);
    } catch (e) {
      captureError(e);
    }
  };

  return {
    onConfirm: handleDisclaimerConfirm,
    onCancel: handleOnCancel,
    isDisclaimerPopupVisible,
    onDisclaimerPopupVisibleChange: handleDisclaimerPopupVisibleChange,
    areTermsConfirmed,
  };
};
