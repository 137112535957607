import { useEffect, useMemo } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { UpdateStrategy, workerStore } from '../store'; // Adjust the path if necessary
import { getDataWorker, getMainWorker } from './singletonWorker';
import {
  MessageMainLongShortVaultsData,
  Messages,
  MessageUiPageInit,
  MessageUiWalletAddress,
  Page,
  WorkerMainUpdateStoreCurrentSlot,
  WorkerMainUpdateStoreLongShortVaults,
  WorkerMainUpdateStoreMarketsInit,
  WorkerUiUpdatePageInit,
  WorkerUiUpdateWalletAddress,
} from '..';
import debugWallet from '../../shared/utils/debugWallet';
import { Market } from '../types/appSchema';

export const useWorkers = () => {
  const { publicKey } = useWallet();
  const walletPublicKey = useMemo(() => debugWallet || publicKey?.toString(), [debugWallet, publicKey]);

  const { mainWorker, dataWorker } = useMemo(() => {
    const main = getMainWorker();
    const data = getDataWorker();

    // Relay all messages to other workers
    main.onmessage = (event) => {
      updateStore(event.data);
    };

    data.onmessage = (event) => {
      main.postMessage(event.data);
      updateStore(event.data);
    };

    return { mainWorker: main, dataWorker: data };
  }, []);

  useEffect(() => {
    dataWorker.postMessage({ event: WorkerUiUpdateWalletAddress, data: walletPublicKey } as MessageUiWalletAddress);
  }, [walletPublicKey]);

  // TODO: make this apply in page
  useEffect(() => {
    dataWorker.postMessage({ event: WorkerUiUpdatePageInit, data: Page.leverageList } as MessageUiPageInit);
  }, []);

  return { mainWorker, dataWorker };
};

export const updateStore = ({ event, data }: Messages) => {
  switch (event) {
    case WorkerMainUpdateStoreMarketsInit: {
      workerStore.updateProviderParams({ markets: data as Record<string, Market> }, UpdateStrategy.merge);
      break;
    }
    case WorkerMainUpdateStoreCurrentSlot: {
      workerStore.updateProviderParams({ currentSlot: data as number }, UpdateStrategy.merge);
      break;
    }
    case WorkerMainUpdateStoreLongShortVaults: {
      workerStore.updateProviderParams(data as MessageMainLongShortVaultsData, UpdateStrategy.merge);
      break;
    }
  }
};
