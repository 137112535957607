import axios from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { featureFlags } from '../utils/featureFlags';
import { QUERYKEYS } from '../constants/queryKeys';
import { isNil } from '../utils';
import { captureError } from '../utils/captureError';

const BLOCKED_COUNTRIES_US = [
  'US', // United States
];

const BLOCKED_COUNTRIES_UK = [
  'GB', // United Kingdom
];

const BLOCKED_COUNTRIES_OFAC_COMPREHENSIVE = [
  'CU', // Cuba
  'IR', // Iran
  'KP', // North Korea
  'RU', // Russia
  'SY', // Syria
];

const BLOCKED_COUNTRIES_OFAC = [
  'BY', // Belarus
  'CI', // Cote D'Ivoire (Ivory Coast)
  'CU', // Cuba
  'KP', // Democratic People’s Republic of Korea (North Korea)
  'CD', // Democratic Republic of Congo
  'IR', // Iran
  'IQ', // Iraq
  'LY', // Libya
  'ML', // Mali
  'MM', // Myanmar (Burma)
  'NI', // Nicaragua
  'RU', // Russia
  'SO', // Somalia
  'SD', // Sudan
  'SY', // Syria
  'UA', // Crimea and Sevastopol (recognized as part of Ukraine)
  'YE', // Yemen
  'ZW', // Zimbabwe
];

const isRestrictedCountryCode = ({ countryCode, region }: { countryCode: string; region: string }) => {
  let isInTheList = false;
  if (!isInTheList && featureFlags.isGeoblockUsEnabled) {
    isInTheList = BLOCKED_COUNTRIES_US.includes(countryCode.toUpperCase()) || isInTheList;
  }

  if (!isInTheList && featureFlags.isGeoblockUkEnabled) {
    isInTheList = BLOCKED_COUNTRIES_UK.includes(countryCode.toUpperCase()) || isInTheList;
  }

  if (featureFlags.isGeoblockOfacComprehensiveEnabled) {
    isInTheList = BLOCKED_COUNTRIES_OFAC_COMPREHENSIVE.includes(countryCode.toUpperCase()) || isInTheList;
    // block only specific areas of Ukraine instead of whole country
    if (countryCode === 'UA') {
      isInTheList = isDonetskOrLuhanskOrCrimeaOrSevastopol(countryCode, region);
    }
  }

  if (featureFlags.isGeoblockOfacEnabled) {
    isInTheList = BLOCKED_COUNTRIES_OFAC.includes(countryCode.toUpperCase()) || isInTheList;
    // block only specific areas of Ukraine instead of whole country
    if (countryCode === 'UA') {
      isInTheList = isDonetskOrLuhanskOrCrimeaOrSevastopol(countryCode, region);
    }
  }

  return isInTheList;
};

const isDonetskOrLuhanskOrCrimeaOrSevastopol = (countryCode: string, region: string) => {
  if (countryCode.toUpperCase() !== 'UA') {
    return false;
  }

  if (region === '14' || region === '09' || region === '40' || region === '43') {
    return true;
  }

  return false;
};

export const useRestrictAccessByIP = () => {
  const [isRestricted, setIsRestricted] = useState<boolean>(featureFlags.isGeoBlockEnabled);
  // if FF disabled, mark as it is fetched
  // const [isFetched, setIsFetched] = useState(!featureFlags.isGeoBlockEnabled);
  const [countryName, setCountryName] = useState('');
  const [currentCountryCode, setCurrentCountryCode] = useState('');
  const [isGeoblockChecked, setIsGeoblockChecked] = useState(false);
  const isEnabled = featureFlags.isGeoBlockEnabled;

  const url = `https://sentinel.kamino.finance/`;

  const { data, isFetched } = useQuery({
    queryKey: QUERYKEYS.geoBlocking,
    enabled: isEnabled,

    queryFn: async () => {
      const res = await axios.get(url);
      return res.data;
    },
    cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
    onError: (err: Error) => {
      captureError(err);
      setIsRestricted(false);
      setIsGeoblockChecked(true);
    },
    retry: 0,
  });

  useEffect(() => {
    if (data && data.status === 'success') {
      setIsRestricted(isRestrictedCountryCode(data));
      setCountryName(data.country);
      setCurrentCountryCode(data.countryCode);
      setIsGeoblockChecked(true);
    }
  }, [data]);

  return {
    isRestricted,
    isEnabled,
    isFetched: isFetched && !isNil(isRestricted),
    countryName,
    countryCode: currentCountryCode,
    isGeoblockChecked: isFetched && isGeoblockChecked,
  };
};
