import React, { FC, useMemo } from 'react';

import styles from './styles.module.less';

import { ENV } from '../../../services/web3/types';
import { Link } from '../../../uiKitV2/Link';
import { Button } from '../../../uiKitV2/Button';
import { Text } from '../../../uiKitV2/Typography';
import { Transactions } from '../../../../features/Notify/components/Transactions';
import { Spinner } from '../../../uiKitV2/Spinner';
import { NotificationTransaction } from '../../../types/notifications';

interface NotificationDescriptionProps {
  txids?: string[];
  env?: ENV;
  multipleTransactions?: NotificationTransaction[];
  onTransactionRetryClick?: () => void;
  type?: string;
}

export const NotificationDescription: FC<NotificationDescriptionProps> = ({
  type,
  children,
  env,
  txids,
  multipleTransactions,
  onTransactionRetryClick,
}) => {
  const isAnyTransactionFailed = useMemo(
    () =>
      Boolean(
        multipleTransactions?.filter((txid) => {
          return txid.status === 'error';
        }).length
      ),
    [multipleTransactions]
  );
  return (
    <>
      <div className={styles.root}>
        <div className={styles.itemsBlock}>{children}</div>

        {!!txids?.length && (
          <div className={styles.links}>
            {txids.map((txid) => (
              <div key={txid} className={styles.link}>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`https://solscan.io/tx/${txid}${!env || env === 'mainnet-beta' ? '' : `?cluster=${env}`}`}
                >
                  View transaction
                </Link>
              </div>
            ))}
          </div>
        )}

        {multipleTransactions && !!multipleTransactions?.length && (
          <div className={styles.itemsBlock}>
            <Transactions transactions={multipleTransactions} />
          </div>
        )}

        {type === 'loading' ? (
          <div className={styles.itemsBlock}>
            <div className={styles.notificationLoading}>
              <Spinner size={16} /> <Text>Loading...</Text>
            </div>
          </div>
        ) : null}

        {isAnyTransactionFailed && (
          <div className={styles.itemsBlock}>
            <Button isFullWidth onClick={onTransactionRetryClick} variant="secondary" className={styles.resetButton}>
              Try Again
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
