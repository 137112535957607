import Decimal from 'decimal.js';
import { percentageToBPS } from '@kamino-finance/kliquidity-sdk';
import { useMemo } from 'react';
import { usePrices } from '../../../shared/hooks/usePrices';
import useTokens from '../../../shared/hooks/useTokens';
import { minBalances } from '../../../shared/constants/minBalances';
import { PublicKeyAddress } from '../../../shared/types/strategies';
import { useAccessRestrictions } from '../../../shared/hooks/useAccessRestrictions';
import { RESTRICTED_REGION_MESSAGE, RESTRICTED_WALLET_MESSAGE } from '../../../shared/constants/warnings';
import { SwapFormProps } from '../components/SwapForm/types';
import { useJupiterQuoteQuery } from '../../../shared/hooks/queries/useJupiterQuoteQuery';
import { formatNumber, numberToLamportsDecimal } from '../../../shared/utils';
import { LayoutProviderStore, layoutProviderStore } from '../../../stores/layoutProviderStore';
import { useObservableStoreValue } from '../../../stores/useObservableStoreValue';
import { ZERO_DECIMAL } from '../constants/constants';
import { MAX_SAFE_SLIPPAGE } from '../../../shared/constants/slippage';

const { formatPercent: fp } = formatNumber;

export const useSwapForm = ({
  selectedInputTokenMint,
  selectedOutputTokenMint,
  onInputTokenSelect,
  onOutputTokenSelect,
  inputTokenAmount,
  onInputTokenChange,
  onOutputTokenChange,
  balances,
}: SwapFormProps) => {
  const { getPriceByTokenMintDecimal } = usePrices();
  const { getToken } = useTokens();
  const { isIPAccessRestricted, hasWalletRestriction } = useAccessRestrictions();
  const slippage = useObservableStoreValue<LayoutProviderStore, Decimal>(
    layoutProviderStore,
    (store) => store.slippage
  );

  const inputTokenMint = selectedInputTokenMint.toString();
  const outputTokenMint = selectedOutputTokenMint.toString();
  const inputTokenInfo = getToken(inputTokenMint);
  const outputTokenInfo = getToken(outputTokenMint);

  const { data: jupiterQuote, isLoading: isPriceImpactLoading } = useJupiterQuoteQuery({
    amountLamports: numberToLamportsDecimal(inputTokenAmount, inputTokenInfo.decimals),
    inputMint: selectedInputTokenMint,
    outputMint: selectedOutputTokenMint,
    slippageBps: slippage.toNumber() * 100,
    onlyDirectRoutes: false,
    refetchIntervalSeconds: 10,
    isCacheDisabled: true,
  });

  const priceImpact = new Decimal(jupiterQuote?.priceImpactPct || 0);
  const priceImpactBps = new Decimal(percentageToBPS(priceImpact.toNumber() * 100));

  const balanceInputToken = balances[inputTokenMint] || ZERO_DECIMAL;
  const balanceOutputToken = balances[outputTokenMint] || ZERO_DECIMAL;
  const tokenAprice = getPriceByTokenMintDecimal(inputTokenMint);
  const tokenBprice = getPriceByTokenMintDecimal(outputTokenMint);

  const tokenBinOneA = tokenAprice.eq(0) ? new Decimal(0) : tokenAprice.div(tokenBprice);

  const inputValueUsd = new Decimal(inputTokenAmount).mul(tokenAprice);
  const priceImpactUsd = inputValueUsd.mul(priceImpact);

  const onHalfA = () => {
    if (inputTokenInfo.symbol === 'SOL' && balanceInputToken.toNumber() / 2 < minBalances.liquidity.deposit) {
      const minSolInvestable = Math.max(balanceInputToken.toNumber() - minBalances.liquidity.deposit, 0);
      onInputTokenChange(minSolInvestable);
    } else {
      onInputTokenChange(balanceInputToken.toNumber() / 2);
    }
  };

  const onMaxA = () => {
    // if user wants to invest MAX SOl, let 0.1 SOL in their wallet so they can pay the fees
    if (inputTokenInfo.symbol === 'SOL') {
      const minSolInvestable = Math.max(balanceInputToken.toNumber() - minBalances.liquidity.deposit, 0);
      onInputTokenChange(minSolInvestable);
    } else {
      onInputTokenChange(balanceInputToken.toNumber());
    }
  };

  const handleSelectTokenAChange = (mint: PublicKeyAddress) => {
    onInputTokenSelect(mint);
    onInputTokenChange(0);
    onOutputTokenChange(0);
  };

  const handleSelectTokenBChange = (mint: PublicKeyAddress) => {
    onOutputTokenSelect(mint);
    onInputTokenChange(0);
    onOutputTokenChange(0);
  };

  const handleChangeTokens = () => {
    onInputTokenSelect(outputTokenMint);
    onOutputTokenSelect(inputTokenMint);
    onInputTokenChange(0);
    onOutputTokenChange(0);
  };

  const getErrorTooltipMessage = () => {
    const errorMessages = [];
    if (hasWalletRestriction) {
      return [RESTRICTED_WALLET_MESSAGE];
    }

    if (isIPAccessRestricted) {
      return [RESTRICTED_REGION_MESSAGE];
    }

    if (inputTokenAmount > balanceInputToken.toNumber()) {
      errorMessages.push('Insufficient funds');
    }
    return errorMessages;
  };

  const warnings = useMemo(() => {
    const warns = [];
    if (slippage.greaterThan(MAX_SAFE_SLIPPAGE)) {
      warns.push(
        `Your maximum slippage is set to ${fp(
          slippage.toNumber(),
          2,
          true
        )}. It’s generally recommended to keep this below 1%. Consider adjusting slippage below the recommended amount.`
      );
    }
    return warns;
  }, [slippage]);

  return {
    onHalfA,
    onMaxA,
    handleSelectTokenAChange,
    handleSelectTokenBChange,
    handleChangeTokens,
    tokenBinOneA,
    inputValueUsd,
    inputTokenInfo,
    outputTokenInfo,
    inputTokenMint,
    outputTokenMint,
    balanceInputToken,
    balanceOutputToken,
    errorMessages: getErrorTooltipMessage(),
    warnings,
    isSlippageAboveSave: slippage.greaterThan(MAX_SAFE_SLIPPAGE),
    slippage,
    isDisabled: getErrorTooltipMessage().length > 0 || !inputTokenAmount,
    priceImpact: priceImpact.toNumber(),
    priceImpactUsd: priceImpactUsd.toNumber(),
    isPriceImpactLoading,
    priceImpactBps,
  };
};
