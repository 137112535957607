import { useRestrictAccessByIP } from './useRestrictAccessByIP';
import { useWalletRestriction } from './useWalletRestriction';

type Output = {
  isIPAccessRestricted: boolean;
  hasWalletRestriction?: boolean;
};

export const useAccessRestrictions = (): Output => {
  const { isRestricted: isIPAccessRestricted } = useRestrictAccessByIP();
  const { hasWalletRestriction } = useWalletRestriction();

  return {
    isIPAccessRestricted,
    hasWalletRestriction,
  };
};
