import {
  LendingObligation,
  LeverageObligation,
  MultiplyObligation,
  ObligationTypeTag,
  VanillaObligation,
} from '@kamino-finance/klend-sdk/dist/utils/ObligationType';
import { PublicKey } from '@solana/web3.js';
import { PublicKeyAddress } from '../../types/strategies';

export interface VanillaProps {
  tag: ObligationTypeTag.Vanilla;
  programId: PublicKeyAddress;
  tokenMint?: never;
  debtToken?: never;
}

export interface LendingProps {
  tag: ObligationTypeTag.Lending;
  programId: PublicKeyAddress;
  tokenMint: PublicKeyAddress;
  debtToken?: never;
}

export interface MultiplyProps {
  tag: ObligationTypeTag.Multiply;
  programId: PublicKeyAddress;
  tokenMint: PublicKeyAddress;
  debtToken: PublicKeyAddress;
}

export interface LeverageProps {
  tag: ObligationTypeTag.Leverage;
  programId: PublicKeyAddress;
  tokenMint: PublicKeyAddress;
  debtToken: PublicKeyAddress;
}

export type BuildObligationTypeProps = MultiplyProps | LendingProps | VanillaProps | LeverageProps;

export const buildObligationType = (props: BuildObligationTypeProps) => {
  if (props.tag === ObligationTypeTag.Lending) {
    const { tokenMint, programId } = props;
    return new LendingObligation(new PublicKey(tokenMint), new PublicKey(programId));
  }

  if (props.tag === ObligationTypeTag.Multiply) {
    const { tokenMint, programId, debtToken } = props;

    return new MultiplyObligation(new PublicKey(tokenMint), new PublicKey(debtToken), new PublicKey(programId));
  }

  if (props.tag === ObligationTypeTag.Leverage) {
    const { tokenMint, programId, debtToken } = props;

    return new LeverageObligation(new PublicKey(tokenMint), new PublicKey(debtToken), new PublicKey(programId));
  }

  return new VanillaObligation(new PublicKey(props.programId));
};
