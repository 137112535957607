import paths from '../routes/paths';
import { PublicKeyAddress } from '../types/strategies';

export const buildStrategyUrl = (strategyAddress: PublicKeyAddress): string =>
  paths.STRATEGY_SHORT.replace(':strategyAddress', strategyAddress.toString());

export const buildEditStrategyUrl = (strategyAddress: PublicKeyAddress): string =>
  paths.EDIT.replace(':strategyAddress', strategyAddress.toString());

export const buildLoanDashboardUrl = (
  obligationAddress: PublicKeyAddress,
  marketAddress: PublicKeyAddress,
  params?: string
): string =>
  paths.LENDING.LENDING_DASHBOARD.replace(':marketAddress', marketAddress.toString())
    .replace(':obligationAddress', obligationAddress.toString())
    .replace(':params', params || '');

export const buildLendingVaultUrl = (reserveAddress: PublicKeyAddress, marketAddress: string): string =>
  paths.LENDING.LENDING_VAULT.replace(':reserveAddress', reserveAddress.toString()).replace(
    ':marketAddress',
    marketAddress
  );

export const buildAdvancedAnalyticsUrl = (strategyAddress: PublicKeyAddress): string =>
  paths.ADVANCED_ANALYTICS_FULL.replace(':strategyAddress', strategyAddress.toString());

export const buildPortfolioUrlWithOwner = (owner: PublicKeyAddress): string =>
  paths.PORTFOLIO_WITH_OWNER.replace(':owner', owner.toString());

export const buildStakingUrl = () => paths.STAKE;

export const buildProposalUrl = (proposalId: PublicKeyAddress): string =>
  paths.PROPOSAL.replace(':proposalId', proposalId.toString());
