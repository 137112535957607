import { ReactElement, SVGProps } from 'react';

export const SvgQuestion = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.875 6.335a1.499 1.499 0 0 1 2.913.5c0 .999-1.499 1.498-1.499 1.498m.018 2h.007M14.334 8a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
