import { makeAutoObservable } from 'mobx';

const MAX_HISTORY_SIZE = 20;

// stores navigation history to make Back button works
// we cannot use history.goBack() because it may cause security issues
// for example, redirecting from phishing app to the real one will cause back button to navigate users to phishing app
class NavigationStore {
  history: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  addPath(path: string) {
    this.history.push(path);
    if (this.history.length > MAX_HISTORY_SIZE) {
      this.history.shift();
    }
  }

  getPaths() {
    return this.history;
  }
}

const navigationHistoryStore = new NavigationStore();
export default navigationHistoryStore;
