import { ReactElement, SVGProps } from 'react';

export const SvgRiskFramework = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.666 2H3.333a.667.667 0 0 0-.667.667v4.11c0 4.92 4.183 6.79 5.14 7.154.127.048.26.048.387 0 .957-.363 5.14-2.233 5.14-7.154v-4.11A.667.667 0 0 0 12.666 2Z"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.967 10h.066v.067h-.066V10ZM8 5.334v2.667"
      stroke="#98B0C1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
