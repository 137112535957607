import { useMemo } from 'react';
import { StrategyPositionTableData } from '../types';
import { ApyMetrics } from '../../../shared/types/metrics';
import { useUserPositions } from '../../../shared/hooks/kamino/useUserPositions';
import { usePrices } from '../../../shared/hooks/usePrices';
import useTokens from '../../../shared/hooks/useTokens';
import { useWalletAddressFromParamsOrEnv } from '../../../shared/hooks/useWalletAddressFromParamsOrEnv';
import { useMetricsQuery } from '../../../shared/queriesCleanedUp/useMetricsQuery';

const EMPTY_DATA: StrategyPositionTableData[] = [];

export const useLiquidityPositionsTableData = (): {
  data: StrategyPositionTableData[];
  isLoading: boolean;
} => {
  const { walletPublicKey } = useWalletAddressFromParamsOrEnv();
  const { getPriceByTokenMintDecimal } = usePrices();
  const { getToken } = useTokens();
  const { data: userPositions, isLoading: arePositionsLoading } = useUserPositions();
  const { metricsByStrategy, isLoading: areMetricsLoading } = useMetricsQuery();

  const isLoading = arePositionsLoading || areMetricsLoading || !metricsByStrategy;

  const tableData = useMemo(() => {
    if (isLoading || !walletPublicKey) {
      return EMPTY_DATA;
    }

    const data = userPositions.map((position) => {
      const { tokenAMint, tokenBMint, tokenAAmount, tokenBAmount } = position;
      const tokenAInfo = getToken(tokenAMint);
      const tokenBInfo = getToken(tokenBMint);

      const metrics = metricsByStrategy[position.strategy.toString()];

      const deposited = [
        { [tokenAMint.toString()]: position.tokenAAmount.toNumber() },
        { [tokenBMint.toString()]: position.tokenBAmount.toNumber() },
      ];

      const apyInfo: ApyMetrics | null = metrics ? metrics.apy : null;
      const kaminoApyInfo = metrics ? metrics.kaminoApy : null;

      const totalValue = tokenAAmount
        .mul(getPriceByTokenMintDecimal(tokenAMint))
        .add(tokenBAmount.mul(getPriceByTokenMintDecimal(tokenBMint)));

      return {
        ...position,
        apyInfo,
        kaminoApyInfo,
        deposited,
        totalValue: totalValue.toNumber(),
        tokenAInfo,
        tokenBInfo,
      };
    });

    return data.sort((a, b) => b.totalValue - a.totalValue);
  }, [isLoading, walletPublicKey, userPositions, getToken, metricsByStrategy, getPriceByTokenMintDecimal]);

  return { data: tableData || EMPTY_DATA, isLoading };
};
