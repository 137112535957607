import type {
  HolderReadyCallback as RCHolderReadyCallback,
  NoticeContent as RCNoticeContent,
  NotificationInstance as RCNotificationInstance,
} from 'rc-notification/lib/Notification';
import useRCNotification from 'rc-notification/lib/useNotification';
import * as React from 'react';
import { ArgsProps, NotificationInstance } from '../types';
// import type { ArgsProps, NotificationInstance } from '..';
// import type { ConfigConsumerProps } from '../../config-provider';
// import { ConfigConsumer } from '../../config-provider';

export default function createUseNotification(
  getNotificationInstance: (args: ArgsProps, callback: (info: { instance: RCNotificationInstance }) => void) => void,
  getRCNoticeProps: (args: ArgsProps, instance: RCNotificationInstance) => RCNoticeContent
) {
  const useNotification = (): readonly [NotificationInstance, React.ReactElement] => {
    // We can only get content by render
    //   let getPrefixCls: ConfigConsumerProps['getPrefixCls'];

    // We create a proxy to handle delay created instance
    let innerInstance: RCNotificationInstance | null = null;
    const proxy = {
      add: (noticeProps: RCNoticeContent, holderCallback?: RCHolderReadyCallback) => {
        innerInstance?.component.add(noticeProps, holderCallback);
      },
    } as any;

    const [hookNotify, holder] = useRCNotification(proxy);

    function notify(args: ArgsProps) {
      // const { prefixCls: customizePrefixCls } = args;
      // const mergedPrefixCls = getPrefixCls('notification', customizePrefixCls);

      getNotificationInstance(
        {
          ...args,
          // prefixCls: mergedPrefixCls,
        },
        ({ instance }) => {
          innerInstance = instance;
          hookNotify(getRCNoticeProps(args, instance));
        }
      );
    }

    // Fill functions
    const hookApiRef = React.useRef<any>({});

    hookApiRef.current.open = notify;

    ['success', 'info', 'warning', 'error', 'special'].forEach((type) => {
      hookApiRef.current[type] = (args: ArgsProps) =>
        hookApiRef.current.open({
          ...args,
          type,
        });
    });

    return [hookApiRef.current, holder] as const;
  };

  return useNotification;
}
