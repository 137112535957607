import { useCallback, useEffect, useState } from 'react';
import { parseISO, isAfter, isBefore } from 'date-fns';

import { useLocalStorageState } from '../../../../hooks/useLocalStorageState';
import useEnv from '../../../../hooks/useEnv';
import { LayoutProviderStore, layoutProviderStore } from '../../../../../stores/layoutProviderStore';
import { useObservableStoreValue } from '../../../../../stores/useObservableStoreValue';

interface UseWhatsNewIndicatorProps {
  id: string;
  showFrom?: string;
  showUntil?: string;
}

const empty = () => {};

export const useWhatsNewIndicator = ({ id, showFrom, showUntil }: UseWhatsNewIndicatorProps) => {
  const { walletPublicKey } = useEnv();
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const [localState, setLocalState] = useLocalStorageState<boolean | undefined>({
    key: `${walletPublicKey!}:${id}`,
  });

  const areWhatsNewIndicatorsEnabled = useObservableStoreValue<LayoutProviderStore, boolean>(
    layoutProviderStore,
    (store) => store.areWhatsNewIndicatorsEnabled
  );

  const onWrapperClick = useCallback(() => {
    setIsIndicatorVisible(false);
    setLocalState(false);
  }, [localState, setLocalState, setIsIndicatorVisible]);

  useEffect(() => {
    if (!areWhatsNewIndicatorsEnabled || !walletPublicKey || localState === false) {
      return empty;
    }

    const showFromParsed = showFrom ? parseISO(showFrom) : null;
    const showUntilParsed = showUntil ? parseISO(showUntil) : null;
    const current = new Date();
    const hasBothDates = showFromParsed && showUntilParsed;

    if (!hasBothDates) {
      setIsIndicatorVisible(true);
      return empty;
    }

    if (hasBothDates) {
      if (isAfter(current, showFromParsed) && isBefore(current, showUntilParsed)) {
        setIsIndicatorVisible(true);
        return empty;
      }
    }

    if (showFromParsed) {
      if (isAfter(current, showFromParsed)) {
        setIsIndicatorVisible(true);
        return empty;
      }
    }

    if (showUntilParsed) {
      if (isBefore(current, showUntilParsed)) {
        setIsIndicatorVisible(true);
        return empty;
      }
    }
  }, [id, showFrom, showUntil, localState, walletPublicKey, areWhatsNewIndicatorsEnabled]);
  return { isIndicatorVisible, onWrapperClick, areWhatsNewIndicatorsEnabled };
};
