import { ReactElement, SVGProps } from 'react';

export const SvgJlp = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="jlp_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" fill="#D9D9D9" />
    </mask>
    <g mask="url(#jlp_svg__a)">
      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z" fill="#121D28" />
      <path
        d="M21.187 18.444a9.57 9.57 0 0 0-4.492-5.992 9.59 9.59 0 0 0-7.437-.93c2.069.252 4.366 1.028 6.618 2.335 2.253 1.308 4.067 2.916 5.311 4.587Z"
        fill="url(#jlp_svg__b)"
      />
      <path
        d="M18.176 23.025c-1.059-1.757-2.873-3.44-5.108-4.736-2.236-1.298-4.597-2.038-6.648-2.087-1.804-.043-3.158.48-3.714 1.437-.003.005-.008.01-.011.017a9.6 9.6 0 0 0-.132.539c.776-.306 1.675-.477 2.675-.496 2.224-.042 4.712.669 7.009 2.002 2.296 1.332 4.148 3.14 5.213 5.09.478.878.775 1.743.893 2.57a9.33 9.33 0 0 0 .403-.382l.01-.019c.555-.956.34-2.39-.59-3.935Z"
        fill="url(#jlp_svg__c)"
      />
      <path
        d="M11.368 21.213c-3.423-1.986-7.205-2.297-9.024-.911.003.434.037.867.1 1.296a7.54 7.54 0 0 1 1.643-.304c2.033-.153 4.274.413 6.307 1.593 2.034 1.181 3.636 2.846 4.51 4.684.242.504.427 1.033.55 1.577a9.627 9.627 0 0 0 1.177-.555c.305-2.265-1.84-5.393-5.263-7.38Z"
        fill="url(#jlp_svg__d)"
      />
      <path
        d="M19.938 20.085c-1.071-1.755-2.901-3.443-5.152-4.748-2.251-1.306-4.622-2.058-6.68-2.118-1.568-.046-2.783.334-3.413 1.051 2.616-.442 6.066.302 9.41 2.242 3.344 1.94 5.7 4.566 6.61 7.055.312-.902.041-2.144-.775-3.482Z"
        fill="url(#jlp_svg__e)"
      />
      <path d="M7.313 9.422V3.285H8.61v5.067h2.63v1.07H7.313Z" fill="url(#jlp_svg__f)" />
      <path
        d="M12.1 9.422V3.285h2.42c.466 0 .863.089 1.19.267.328.176.577.42.75.734.173.312.26.671.26 1.079 0 .407-.088.767-.264 1.078-.176.312-.43.555-.764.728-.331.174-.733.261-1.204.261h-1.543v-1.04h1.333c.25 0 .455-.043.617-.129a.854.854 0 0 0 .366-.362c.082-.156.123-.335.123-.536 0-.204-.041-.382-.123-.534a.82.82 0 0 0-.366-.356 1.335 1.335 0 0 0-.623-.13h-.875v5.077H12.1Z"
        fill="url(#jlp_svg__g)"
      />
    </g>
    <defs>
      <linearGradient id="jlp_svg__b" x1={14.892} y1={14.946} x2={6.249} y2={29.821} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
      <linearGradient id="jlp_svg__c" x1={14.892} y1={14.945} x2={6.249} y2={29.82} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
      <linearGradient id="jlp_svg__d" x1={14.893} y1={14.947} x2={6.249} y2={29.822} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
      <linearGradient id="jlp_svg__e" x1={14.893} y1={14.946} x2={6.25} y2={29.821} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
      <linearGradient id="jlp_svg__f" x1={15.76} y1={4.68} x2={7.67} y2={9.832} gradientUnits="userSpaceOnUse">
        <stop stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
      <linearGradient id="jlp_svg__g" x1={15.76} y1={4.68} x2={7.67} y2={9.832} gradientUnits="userSpaceOnUse">
        <stop stopColor="#C7F284" />
        <stop offset={1} stopColor="#00BEF0" />
      </linearGradient>
    </defs>
  </svg>
);
