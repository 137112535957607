import { TagProps } from '../../../shared/uiKitV2/Tag';
import { ObligationTransactionType } from '../types';

export const getObligationTransactionTagColor = (name: string): TagProps['variant'] => {
  switch (name) {
    case ObligationTransactionType.supply:
    case ObligationTransactionType.repay:
      return 'green';
    case ObligationTransactionType.withdraw:
    case ObligationTransactionType.borrow:
      return 'orange';
    case ObligationTransactionType.liquidate:
      return 'red';
    default:
      return 'grey';
  }
};
