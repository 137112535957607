export enum MainModeTab {
  balances = 'balances',
  positions = 'positions',
  activity = 'activity',
}
export type MainModeTabType = keyof typeof MainModeTab;

export const MainModeTabs = [
  { key: MainModeTab.balances, label: 'Balances' },
  { key: MainModeTab.positions, label: 'Positions' },
  { key: MainModeTab.activity, label: 'Activity' },
];
