import c from 'classnames';
import { CSSProperties } from 'react';
import { Provider } from '../../constants/kamino';
import { Token } from '../../constants/tokens';
import { getIconClassName } from '../../utils/utils';
import styles from './HubbleIcon.module.less';

interface HubbleIconProps {
  name: Token | Provider | 'USD' | string;
  className?: string;
  size?: number;
}

export const HubbleIcon = ({ name, className, size }: HubbleIconProps) => {
  const nameRegexp = /\$/g;
  const lowerCaseName = name ? name.toLowerCase() : '';
  const style: CSSProperties = {};
  if (size) {
    style.width = size;
    style.height = size;
  }
  return (
    <div style={style} className={c(className, styles.icon, getIconClassName(lowerCaseName.replace(nameRegexp, '')))} />
  );
};
