import { Proposal, ProposalState, VoteTypeKind } from '@solana/spl-governance';
import { PublicKey } from '@solana/web3.js';
import { featureFlags } from '../../shared/utils';

export const governanceProgramId = new PublicKey('GovER5Lthms3bLBqWub97yVrMmEogzX7xNjdXpPPCVZw');

// Paul's
// export const governancePk = new PublicKey('7fkgx9PtqMR3KVE5xGTb25QvPoVEAuvhrWkqeBqEfsx1');
// export const realmPublicKey = new PublicKey('Hdy5ER2cv1hwdoqvxifK13JhUWdQ8HpTVAwPMCQwWhf');

// Frank's 1
// export const governancePk = new PublicKey('9BM1fF9jH4MfAGL2fFfsWEo8KWvL6h7UnnoTayewu5wi');
// export const realmPublicKey = new PublicKey('Eb3uys4sDViV5EjjNATYV21h5EVx9NmvfK8219Q5LM2w');

// Frank's 1d testing one
export const testingGovernancePk = new PublicKey('J4BmnPRV4jQKaefkuuf4JUBPXHjVbz8M1cEDQjFRXs3w');
export const testingRealmPk = new PublicKey('7aK2heSBSZYxX5hcH7e6ckYx2p8hkXrtuCAypNUBYgG4');

// Production
export const productionGovernancePk = new PublicKey('DgG29hESvGqEbWB3uTBFV2nkxXduL5dZECEJeuSctBHw');
export const productionRealmPk = new PublicKey('AtsfUAGWX2MfcxVFBvwtuRfvi4AVrFhefSRXLJfHKMFk');

export const governancePk = featureFlags.isGovernanceProductionEnabled
  ? productionGovernancePk
  : featureFlags.isGovernanceTestingEnabled
  ? testingGovernancePk
  : undefined;
export const realmPublicKey = featureFlags.isGovernanceProductionEnabled
  ? productionRealmPk
  : featureFlags.isGovernanceTestingEnabled
  ? testingRealmPk
  : undefined;

export const communityTokenMint = new PublicKey('KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS');
export const communityTokenMintSupply = 10000000000;
export const voterWeightAddin = new PublicKey('vsr2nfGVNHmSY8uxoBGqq8AQbwz3JwaEaHqGbsTPXqQ');

export const lockupType = 'constant';
export const unlockLockupType = 'cliff';
// adding one day to lockupPeriod when unlocking to avoid difference in front/backend calculation of period
// period have to be same or higher then deposit has that we unlock
export const productionLockupPeriodInDays = 29;
export const productionUnlockLockupPeriodInDays = 30;
export const testingLockupPeriodInDays = 1;
export const testingUnlockLockupPeriodInDays = 2;

export const lockupPeriodInDays = featureFlags.isGovernanceProductionEnabled
  ? productionLockupPeriodInDays
  : testingLockupPeriodInDays;
export const unlockLockupPeriodInDays = featureFlags.isGovernanceProductionEnabled
  ? productionUnlockLockupPeriodInDays
  : testingUnlockLockupPeriodInDays;

export const ProposalLabel: Record<ProposalState, string> = {
  [ProposalState.Draft]: 'Created',
  [ProposalState.SigningOff]: 'Signing Off',
  [ProposalState.Voting]: 'In Progress',
  [ProposalState.Succeeded]: 'Passed',
  [ProposalState.Executing]: 'Executing',
  [ProposalState.Completed]: 'Completed',
  [ProposalState.Cancelled]: 'Cancelled',
  [ProposalState.Defeated]: 'Failed',
  [ProposalState.ExecutingWithErrors]: 'Executing With Errors',
  [ProposalState.Vetoed]: 'Vetoed',
};

export const getVoteWeights = (proposal: Proposal) => {
  if (proposal.voteType.type === VoteTypeKind.SingleChoice) {
    return {
      For: proposal.getYesVoteOption().voteWeight.toNumber() / 1000000,
      Against: proposal.getNoVoteCount().toNumber() / 1000000,
    };
  }

  return proposal.options.reduce<Record<string, number>>((acc, curr) => {
    if (!acc[curr.label]) {
      acc[curr.label] = curr.voteWeight.toNumber() / 1000000;
    }

    return acc;
  }, {});
};

export const nota = '$$_NOTA_$$';
