import React, { FC } from 'react';
import { withStyles } from '@bruitt/classnames';
import { NavLink } from 'react-router-dom';
import { Text } from '../../../../shared/uiKitV2/Typography';

import s from './LinksList.module.less';
import { Color } from '../../../../shared/types/global/colors';

const sx = withStyles(s);

export interface Link {
  id: number;
  label: string;
  href?: string;
  to?: string;
  color?: Color;
  isExternal?: boolean;
}

interface LinksListProps {
  title: string;
  links: Link[];
}

const Item: FC<{ color: Color }> = ({ children, color }) => (
  <Text weight="regular" color={color}>
    {children}
  </Text>
);

export const LinksList: FC<LinksListProps> = (props) => {
  const { title, links } = props;

  return (
    <div className={sx('root')}>
      <Text fs={16} color="grey-deep" lh={22} className={sx('title')}>
        {title}
      </Text>

      <ul className={sx('list')}>
        {links.map(({ href, to, color = 'grey', label, id }) => (
          <li className={sx('item')} key={id}>
            {href && (
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  if (href === '#') {
                    e.preventDefault();
                  }
                }}
              >
                <Item color={color}>{label}</Item>
              </a>
            )}

            {to && (
              <NavLink to={to}>
                <Item color={color}>{label}</Item>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
