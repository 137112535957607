import Decimal from 'decimal.js';
import { useUserAtasQuery } from './useUserAtasQuery';
import { useBalances } from '../../../shared/hooks/useBalances';
import { SOL_MINT } from '../../../shared/constants/mints';

export type AtaBalances = Record<string, Decimal>;

const EMPTY_TOKENS = ['9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E'];

type UseUserAtaBalancesProps = {
  isSolWsolSeparated?: boolean;
};
export const useUserAtaBalances = ({ isSolWsolSeparated }: UseUserAtaBalancesProps) => {
  const { data: atas, isFetched, isLoading } = useUserAtasQuery();
  const { getBalanceByTokenMint } = useBalances();

  const balances: AtaBalances = {};

  atas
    ?.filter((account) => {
      return (
        account.account.data.parsed.info.tokenAmount.uiAmount > 0 &&
        !EMPTY_TOKENS.includes(account.account.data.parsed.info.mint)
      );
    })
    .forEach((account) => {
      balances[account.account.data.parsed.info.mint] = new Decimal(
        account.account.data.parsed.info.tokenAmount.uiAmount
      );
    });

  if (!isSolWsolSeparated) {
    const solBalance = getBalanceByTokenMint(SOL_MINT);
    if (solBalance && !solBalance?.eq(0)) {
      balances[SOL_MINT] = solBalance;
    }
  }

  return { data: balances, isLoading, isFetched };
};
