import { withStyles } from '@bruitt/classnames';
import { FC, ReactNode } from 'react';
import { Text } from '../Typography';
import { TextProps } from '../Typography/Typography';

import s from './Link.module.less';

const sx = withStyles(s);

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  textProps?: TextProps;
  className?: string;
}

export const Link: FC<LinkProps> = ({ startIcon, endIcon, textProps, className, children, ...rest }) => {
  return (
    <a {...rest} className={sx('root', className)}>
      {startIcon}
      <Text {...textProps}>{children}</Text>
      {endIcon}
    </a>
  );
};
