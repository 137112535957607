import { FC } from 'react';
import { useRestrictAccessByIP } from '../../../hooks/useRestrictAccessByIP';
import { Button, ButtonProps } from '../../../uiKitV2/Button';

import s from './FormButton.module.less';
import { Tooltip } from '../../../uiKitV2/Tooltip';
import { Text } from '../../../uiKitV2/Typography';
import { Notification } from '../../../uiKitV2/Notification';
import useEnv from '../../../hooks/useEnv';
import { ConnectButton } from '../ConnectButton';
import { useWalletRestriction } from '../../../hooks/useWalletRestriction';
import { RESTRICTED_WALLET_MESSAGE } from '../../../constants/warnings';

interface FormButtonProps extends ButtonProps {
  tooltipMessages?: string[];
  isRegionIgnored?: boolean;
}

export const FormButton: FC<FormButtonProps> = ({ disabled, isRegionIgnored, tooltipMessages, children, ...rest }) => {
  const { isRestricted, isEnabled, isGeoblockChecked } = useRestrictAccessByIP();
  const { hasWalletRestriction } = useWalletRestriction();
  const { walletPublicKey } = useEnv();

  const isRestrictedRegion = isGeoblockChecked && isRestricted && isEnabled && !isRegionIgnored;

  if (!walletPublicKey) {
    return (
      <div className={s.connectBtn}>
        <ConnectButton variant="primary" isFullWidth />
      </div>
    );
  }

  return (
    <div className={s.root}>
      {hasWalletRestriction && <Notification variant="warning" text={RESTRICTED_WALLET_MESSAGE} />}
      {isRestrictedRegion && (
        <Notification variant="warning" text="You’re not allowed to use Kamino Finance from a restricted territory." />
      )}
      <Tooltip
        isFluid
        hidden={!tooltipMessages?.length}
        render={() => (
          <>
            {tooltipMessages?.map((m, index) => (
              <Text color="grey" key={index}>
                {m}
              </Text>
            ))}
          </>
        )}
      >
        <Button disabled={disabled || isRestrictedRegion || hasWalletRestriction !== false} {...rest}>
          {children}
        </Button>
      </Tooltip>
    </div>
  );
};
