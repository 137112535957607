import { useQuery } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { PublicKey } from '@solana/web3.js';
import { useState } from 'react';
import { QuoteGetRequest } from '@jup-ag/api';
import { QUERYKEYS } from '../../constants/queryKeys';
import { PublicKeyAddress } from '../../types/strategies';
import { JupiterService } from '../../services/jupiter/JupiterService';
import { capSlippage } from '../../utils/jupiter';
import { isValidPubkey, tryAnchorErrorMessageOr } from '../../utils';
import { captureError } from '../../utils/captureError';
import { notify } from '../../utils/notifications/notifications';

export interface UseJupiterQuoteQueryProps {
  amountLamports: Decimal;
  inputMint?: PublicKeyAddress;
  outputMint?: PublicKeyAddress;
  slippageBps: number;
  onlyDirectRoutes?: boolean;
  maxAccounts?: number;
  excludeDexes?: QuoteGetRequest['excludeDexes'];
  refetchIntervalSeconds: number;
  isCacheDisabled?: boolean;
}

export const useJupiterQuoteQuery = ({
  inputMint,
  outputMint,
  amountLamports,
  slippageBps,
  onlyDirectRoutes = false,
  maxAccounts,
  excludeDexes,
  refetchIntervalSeconds,
  isCacheDisabled,
}: UseJupiterQuoteQueryProps) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const queryFn = async () => {
    if (!inputMint || !isValidPubkey(inputMint) || !outputMint || !isValidPubkey(outputMint)) {
      throw new Error('Invalid swap params. Try again');
    }

    return JupiterService.getBestRoute({
      amountLamports,
      inputMint,
      outputMint,
      slippage: capSlippage(slippageBps / 100, true).toNumber(),
      mode: 'ExactIn',
      onlyDirectRoutes,
      maxAccounts,
      asLegacyTransaction: false,
      excludeDexes,
    });
  };

  /**
   * Does not force the query, only enable the flag
   */
  const enable = () => {
    setIsDisabled(false);
  };

  const disable = () => {
    setIsDisabled(true);
  };

  const isEnabled =
    Boolean(amountLamports) &&
    amountLamports.gt(0) &&
    Boolean(inputMint) &&
    isValidPubkey(inputMint) &&
    Boolean(outputMint) &&
    isValidPubkey(outputMint);

  const { data, ...rest } = useQuery({
    queryKey: QUERYKEYS.getJupiterQuote(
      amountLamports,
      inputMint || PublicKey.default,
      outputMint || PublicKey.default,
      slippageBps,
      onlyDirectRoutes,
      maxAccounts
    ),
    queryFn,
    enabled: isEnabled && !isDisabled,
    keepPreviousData: isEnabled && !isCacheDisabled,
    cacheTime: isCacheDisabled ? 0 : refetchIntervalSeconds * 2 * 1000,
    refetchInterval: refetchIntervalSeconds * 1000,
    staleTime: isCacheDisabled ? 0 : refetchIntervalSeconds * 1.5 * 1000,
    onError: (err) => {
      captureError(err);
      notify({
        key: 'priceImpact',
        type: 'error',
        message: 'Error',
        description: tryAnchorErrorMessageOr(
          err,
          'Failed to load jupiter Quote. Please, reload the page and try again.'
        ),
      });
    },
  });

  return {
    data,
    ...rest,
    enable,
    disable,
  };
};
