import { useQuery } from '@tanstack/react-query';
import { GlobalConfig } from '@kamino-finance/kliquidity-sdk/dist/kamino-client/accounts';
import { QUERYKEYS } from '../constants/queryKeys';
// import { notify } from '../../utils/notifications/notifications';
import { notify } from '../utils/notifications/notifications';
import { useWeb3Client } from './useWeb3Client';
import { useKaminoSdk } from './useKaminoSdk';
import { captureError } from '../utils/captureError';

export const useGlobalConfig = () => {
  const { web3client } = useWeb3Client();
  const { kaminoSdk } = useKaminoSdk();

  const queryFn = async () => {
    // safe to assume these are not undefined thanks to `enabled:`
    const globalConfigPubkey = kaminoSdk!.getGlobalConfig();
    const globalConfig = await GlobalConfig.fetch(web3client!.connection, globalConfigPubkey);
    if (!globalConfig) {
      throw new Error(`global config ${globalConfigPubkey.toString()} is not found`);
    }
    return globalConfig;
  };

  const { data, isLoading } = useQuery({
    queryKey: QUERYKEYS.globalConfig,
    enabled: Boolean(kaminoSdk) && Boolean(web3client),
    queryFn,
    onError: (err) => {
      captureError(err);
      notify({
        message: 'Failed to load global config',
        type: 'error',
      });
    },
    cacheTime: 2 * 24 * 60 * 60 * 1000, // 2 days
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
  });

  return {
    data,
    isLoading,
  };
};
