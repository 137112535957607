import { ReactElement, SVGProps } from 'react';

export const SvgWarningShield = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.666 2H3.333a.667.667 0 0 0-.667.667v4.11c0 4.92 4.183 6.79 5.14 7.154.127.048.26.048.387 0 .957-.363 5.14-2.233 5.14-7.154v-4.11A.667.667 0 0 0 12.666 2Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 6 7.333 8.667 6 7.333"
    />
  </svg>
);
