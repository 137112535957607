import React, { FC } from 'react';
import s from '../../TokensPopup.module.less';
import { TokenIcon } from '../../../../../TokenIcon';
import { Text } from '../../../../../Typography';
import { Icon } from '../../../../../Icon';
import { formatNumber, shorterString } from '../../../../../../utils';
import { notify } from '../../../../../../utils/notifications/notifications';
import { TokenWithPrice } from '../../../../../../../features/SidePanel/types/tokenWithPrice';

const { formatTokenAllDecimals: ftad, formatUsd: fusd } = formatNumber;

type TokensItemProps = {
  onSelect: (address: string) => void;
  token: TokenWithPrice;
  isActive: boolean;
};
export const TokenItem: FC<TokensItemProps> = ({ onSelect, token, isActive }) => {
  const handleCopyToClipboard = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!token.address) {
      return;
    }
    await navigator.clipboard.writeText(token.address);

    notify({
      type: 'success',
      message: 'Copied!',
    });
  };

  return (
    <div className={s.tokenItem} onClick={() => onSelect(token.address)} key={token.address}>
      <div className={s.itemLeft}>
        <TokenIcon tokenMint={token.address} size={24} />
        <Text fs={14} lh={18}>
          {token.symbol}
        </Text>
        <div className={s.address} onClick={handleCopyToClipboard}>
          <Text fs={10} lh={10}>
            {shorterString(token.address, 4)}
          </Text>
          <div className={s.copyIcon}>
            <Icon name="Copy" size={10} color="grey" />
          </div>
          <div className={s.copyIconHovered}>
            <Icon name="Copy" size={10} color="blue" />
          </div>
        </div>
      </div>

      <div className={s.itemRight}>
        {Boolean(token.amount.toNumber()) && (
          <div className={s.valuesWrapper}>
            <Text fs={14} lh={18} textAlign="right">
              {ftad(token.amount.toNumber(), token.decimals)}
            </Text>
            <Text fs={10} lh={10} color="grey" textAlign="right">
              {fusd(token.value.toNumber())}
            </Text>
          </div>
        )}
        {isActive && <Icon name="Check" size={16} color="blue" />}
      </div>
    </div>
  );
};
