import React, { FC, useMemo } from 'react';
import Decimal from 'decimal.js';
import { formatNumber } from '../../../utils';
import { Text } from '../../../uiKitV2/Typography';
import { TextProps } from '../../../uiKitV2/Typography/Typography';
import { Color } from '../../../types/global/colors';
import { Tooltip } from '../../../uiKitV2/Tooltip';
import { Icon } from '../../../uiKitV2/Icon';
import s from './PnlValue.module.less';

const { formatToFirstNonNullDecimal } = formatNumber;

interface PnlValueProps {
  value: Decimal.Value;
  post?: string;
  pre?: string;
  textProps?: TextProps;
  isEmptyValue?: boolean;
  isPendingValue?: boolean;
  pendingTootlipText?: string;
}

export const PnlValue: FC<PnlValueProps> = ({
  value,
  isEmptyValue,
  isPendingValue,
  pendingTootlipText,
  post = '',
  pre = '',
  textProps,
}) => {
  const valNumber = new Decimal(isEmptyValue || !value ? 0 : value).toNumber();
  const preSymbol = valNumber > 0 ? '+' : valNumber < 0 ? '-' : '';
  const formattedValue = formatToFirstNonNullDecimal(Math.abs(valNumber));

  const color: Color = useMemo(() => {
    if (valNumber > 0) {
      return 'green-light';
    }

    if (valNumber < 0) {
      return 'red';
    }

    return 'white';
  }, [valNumber]);

  if (isPendingValue) {
    return (
      <span className={s.pendingContainter}>
        <Tooltip isFluid render={() => <>{pendingTootlipText}</>}>
          <Text underline color="grey" {...textProps}>
            <div className={s.pendingWrapper}>
              <Icon name="Clock" size={26} />
              <span>Pending</span>
            </div>
          </Text>
        </Tooltip>
      </span>
    );
  }

  if (isEmptyValue) {
    return (
      <Text color="white" {...textProps}>
        -
      </Text>
    );
  }

  return <Text color={color} {...textProps}>{`${preSymbol}${pre}${formattedValue} ${post}`}</Text>;
};
