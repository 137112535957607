import { KaminoMarket, KaminoReserve, PubkeyHashMap, ReserveStatus } from '@kamino-finance/klend-sdk';
import { PublicKey } from '@solana/web3.js';
import { PublicKeyAddress } from '../../../types/strategies';
import { featureFlags } from '../../../utils';

export const getAllReservesMap = (markets: KaminoMarket[]) => {
  return markets.reduce((sum, market) => {
    sum = new PubkeyHashMap([...sum, ...market.reserves]);
    return sum;
  }, new PubkeyHashMap<PublicKey, KaminoReserve>());
};

export const getActiveReservesByMarketMap = (markets: KaminoMarket[]) => {
  return markets.reduce((sum, market) => {
    sum[market.address] = filterActiveMarkets([market]);
    return sum;
  }, {} as Record<string, KaminoReserve[]>);
};

export const getReserveByAddressFromAllReservesMap = (allReservesMap: PubkeyHashMap<PublicKey, KaminoReserve>) => {
  return (address: PublicKeyAddress) => {
    const reserve = address ? allReservesMap.get(new PublicKey(address.toString())) : undefined;

    if (!reserve) {
      throw new Error(`Reserve ${address.toString()} is not found`);
    }
    return reserve;
  };
};

export const getReservesByMarketAddressFromAllReservesMap = (activeReservesMap: Record<string, KaminoReserve[]>) => {
  return (marketAddress: string) => {
    return activeReservesMap[marketAddress];
  };
};

export const getReserveByTokenMintFromMarket = (
  getMarketAddress: (marketAddress: PublicKeyAddress) => KaminoMarket
) => {
  return (marketAddress: PublicKeyAddress, mint: PublicKeyAddress) => {
    const market = getMarketAddress(marketAddress);
    return Array.from(market.reserves.values()).find((reserve) =>
      reserve.getLiquidityMint().equals(new PublicKey(mint))
    );
  };
};

export const filterActiveMarkets = (markets: KaminoMarket[]) => {
  return markets
    .reduce((sum, items) => {
      sum = sum.concat(
        Array.from(items.reserves.values()).filter((reserve) => {
          if (featureFlags.isHiddenReservesEnabled) {
            return reserve.stats.status === ReserveStatus.Active || reserve.stats.status === ReserveStatus.Hidden;
          }
          return reserve.stats.status === ReserveStatus.Active;
        })
      );
      return sum;
    }, [] as KaminoReserve[])
    .sort((a, b) => a.symbol.localeCompare(b.symbol));
};
