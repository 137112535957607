import { StrategiesFilters } from '@kamino-finance/kliquidity-sdk';
import { isNil } from '../isNil';

/**
 * Turns object of filters {} into array to use in query keys
 * Only supports plain values, like string[], number[] or objects that have a meaningful toString()
 * @param filters
 */
export function convertFiltersToQueryKeys(filters: StrategiesFilters) {
  return Object.entries(filters).reduce((sum, [key, value]) => {
    if (!isNil(value)) {
      sum.push(key);
      sum.push(value.toString());
    }
    return sum;
  }, [] as string[]);
}
